<template>
  <div>
    <KSubheader
      :breadcrumbs="[{
        page: 'Kassabeheer',
        route: '/cash_registers/overview'
      }, {
        page: 'Afsluitingen'
      }, {
        page: 'Overzicht'
      }]"

      title="Afsluitingen"
    />

    <KContainer>

      <KPortlet>
        <KPortletHead>
          <template v-slot:default>Overzicht</template>
        </KPortletHead>
        <KPortletBody>

          <div class="row">
            <div class="col-12">
              <div class="kt-input-icon kt-input-icon--left">
                <input
                  id="generalSearch"
                  type="text"
                  class="form-control"
                  placeholder="Search..."
                  @keyup.enter="$refs['datatable'].search"
                  @keyup="searchDebounced"
                >

                <span class="kt-input-icon__icon kt-input-icon__icon--left">
                  <span>
                    <i>
                      <font-awesome-icon :icon="['far', 'search']" />
                    </i>
                  </span>
                </span>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-12 datatable datatable-wrapper">
              <datatable
                ref="datatable"
                :url="datatable.url"
                :http-fetch="fetch"
                :fields="datatable.fields"
                :filters="datatable.filters"
                :search-fields="datatable.searchFields"
                :on-pagination-data="onPaginationData"
              >
                <!-- Status -->
                <div
                  slot="status"
                  slot-scope="props"
                  class="session-status"
                >
                  <KButton
                    :variant="(props.rowData.open === true ? 'label-caution' : 'label-focus')"
                    class="btn-block btn-sm"
                    tag="span"
                  >
                    {{ (props.rowData.open === true ? 'Open' : 'Gesloten') }}
                  </KButton>
                </div>

                <!-- Cash register -->
                <div
                  slot="cash-register"
                  slot-scope="props"
                >
                  <router-link
                    v-if="(typeof props.rowData.cash_register === 'object')"
                    :to="`/cash_registers/view/${props.rowData.cash_register._meta.guid}`"
                  >
                    {{ props.rowData.cash_register.name }}
                  </router-link>

                  <span v-else>
                    Onbekende kassa
                  </span>
                </div>

                <!-- Opened by user -->
                <div
                  slot="opened-by-user"
                  slot-scope="props"
                >
                  <router-link
                    :to="`/users/view/${props.rowData.opened_by_user.guid}`"
                  >
                    {{ props.rowData.opened_by_user.name }}
                  </router-link>
                </div>

                <!-- Closed by user -->
                <div
                  slot="closed-by-user"
                  slot-scope="props"
                >
                  <router-link
                    v-if="props.rowData.closed_by_user"
                    :to="`/users/view/${props.rowData.closed_by_user.guid}`"
                  >
                    {{ props.rowData.closed_by_user.name }}
                  </router-link>
                </div>

                <!-- Actions -->
                <div
                  slot="actions"
                  slot-scope="props"
                  class="datatable-actions"
                >

                  <router-link
                    v-if="$auth.hasPermission('get_cash_register_openings')"
                    :to="`/cash_registers/closures/view/${props.rowData._meta.guid}`"
                  >
                    <KButton
                      :icon="['fad', 'eye']"
                      tag="a"
                      variant="label-brand"
                    />
                  </router-link>
                </div>
              </datatable>

              <div class="row pagination">
                <div class="col-xs-12 col-md-6">
                  <datatable-pagination
                    ref="pagination"
                    @vuetable-pagination:change-page="onChangePage"
                  />
                </div>

                <div class="col-xs-12 col-md-6">
                  <datatable-pagination-info
                    ref="pagination.info"
                    @vuetable-paginationinfo:change-rowcount="onChangeRowCount"
                  />
                </div>
              </div>

            </div>
          </div>

        </KPortletBody>
      </KPortlet>
    </KContainer>
  </div>
</template>

<script>
// Libraries
import moment from 'moment-timezone';

// Classes
import CashRegister from '@/libs/classes/cash_register';
import CashRegisterOpening from '@/libs/classes/cash_register.opening';

// Mixins
import datatable from '@/mixins/datatable';

export default {
  mixins: [datatable],
  data () {
    return {
      datatable: {
        url: '/cash_register_openings',

        fields: [{
          title: 'Status',
          name: '__slot:status',
          sortField: 'open',
          dataClass: 'w-1/8'
        }, {
          title: 'Kassa',
          name: '__slot:cash-register',
          sortField: 'cash_register',
          dataClass: 'w-1/8'
        }, {
          title: 'Geopend door',
          name: '__slot:opened-by-user',
          sortField: 'opened_by_user.name',
          dataClass: 'w-1/8'
        }, {
          title: 'Geopend op',
          name: 'open_timestamp',
          dataClass: 'w-2/8',
          callback: (value) => moment(value).format('LLL')
        }, {
          title: 'Gesloten door',
          name: '__slot:closed-by-user',
          sortField: 'closed_by_user.name',
          dataClass: 'w-1/8'
        }, {
          title: 'Gesloten op',
          name: 'close_timestamp',
          dataClass: 'w-2/8',
          callback: (value) => {
            if (!value) {
              return null;
            }

            return moment(value).format('LLL');
          }
        }, {
          title: '',
          name: '__slot:actions',
          titleClass: 'text-right',
          dataClass: 'text-right'
        }],

        searchFields: ['opened_by_user.name', 'closed_by_user.name']
      }
    };
  },

  methods: {
    async fetch (apiUrl, httpOptions) {
      const fetch = await this.$refs.datatable.fetch(apiUrl, httpOptions, {
        skipHttpFetch: true
      });

      // Set fetch data
      fetch.data.data = fetch.data.data.map(r => new CashRegisterOpening().mergeResponse(r), []);

      // Get cash registers
      let cashRegisters = await this.$ws.get('v1', '/cash_registers', {
        query: {
          'filter[_meta.guid]': fetch.data.data.map(opening => opening.cash_register, []).filter((value, index, self) => self.indexOf(value) === index).join(',')
        }
      });
      cashRegisters = cashRegisters.map(r => new CashRegister().mergeResponse(r), []);

      if (cashRegisters && cashRegisters.length > 0) {
        fetch.data.data.forEach((row, idx) => {
          const cashRegister = cashRegisters.find(c => c._meta.guid === row.cash_register);
          if (cashRegister) {
            fetch.data.data[idx].cash_register = cashRegister;
          }
        });
      }

      return fetch;
    }
  }
};
</script>
