<template>
  <div class="row form-group">
    <div class="col-12 mt-3 col-lg-6 mt-lg-0">
      <label>Adres</label>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">
            <i><font-awesome-icon :icon="['fad', 'home']" /></i>
          </span>
        </div>
        <input type="text" class="form-control" :placeholder="(placeholder ? 'Straatnaam' : undefined)" v-model="customer.address.line1" :disabled="disabled">
      </div>
    </div>

    <div class="col-12 mt-3 col-lg-6 mt-lg-0">
      <label>Stad</label>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">
            <i><font-awesome-icon :icon="['fad', 'home']" /></i>
          </span>
        </div>
        <input type="text" class="form-control" :placeholder="(placeholder ? 'Amsterdam' : undefined)" v-model="customer.address.city" :disabled="disabled">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    customer: Object,

    disabled: Boolean,
    placeholder: {
      type: Boolean,
      default: true
    }
  }
}
</script>
