<template>
  <KPortlet v-if="isMounted">
    <KPortletHead>
      <template v-slot:default>Prijzen</template>
    </KPortletHead>
    <KPortletBody>

      <pricesForm
        ref="pricesForm"

        :pricelist="pricelistCloned"
        :VATCodes="meta.VATCodes"

        action="EDIT"
      />

    </KPortletBody>
    <div class="kt-portlet__foot">
      <div class="row align-items-center">
        <div class="col-6 offset-lg-10 col-lg-2 kt-align-right">
          <KButton
            variant="warning"
            class="btn-md btn-block btn-tall btn-wide btn-bold btn-upper"
            @click.native.prevent="submitForm"
          >
            Wijzigen
          </KButton>
        </div>
      </div>
    </div>
  </KPortlet>
</template>

<script>
// Libraries
import { cloneDeep } from 'lodash';
import mutationsLib from '@/libs/mutations';

// Classes
import SessionPricelist from '@/libs/classes/session_pricelist';

// Components
import pricesForm from '@/components/products/pricelists/components/prices.form';

export default {
  components: {
    pricesForm
  },
  props: {
    pricelist: {
      type: SessionPricelist,
      default: null
    },

    meta: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      isMounted: false,

      pricelistCloned: cloneDeep(this.pricelist)
    };
  },

  async mounted () {
    this.$nextTick(() => {
      this.isMounted = true;
    });
  },

  methods: {
    async validate () {
      const validationArray = [];

      if (validationArray.length > 0) {
        validationArray.forEach(v => v && v.$touch && v.$touch());
        if (validationArray.filter(v => v && v.$invalid).length !== 0) return false;
      }

      let valid = await this.$refs.pricesForm.validate();
      if (valid === false) return false;

      return true;
    },
    async submitForm () {
      if (await this.validate() === true) {
        // Create mutations
        const mutations = mutationsLib.createArrayByKey('prices', ['product_guid', 'day'], this.pricelist.formatBody(), this.pricelistCloned.formatBody());

        if (mutations.length) {
          await this.pricelist.update(mutations);
          await this.pricelist.populate('update');
          this.pricelistCloned = cloneDeep(this.pricelist);
        }
      }
    }
  }
};
</script>
