<template v-if="equipment">
  <KPortlet class="kt-portlet--height-fluid cs-equipment-block">
    <!-- Second screen header -->
    <KPortletBody
      v-if="secondScreen === true"
      class="kt-portlet__body--fit border-bottom pt-3 pb-3 pl-3 pr-3"
    >
      <div
        class="row"
        style="color: #48465b;"
      >
        <div class="col-6">
          <h2 class="m-0 overflow-ellipsis">{{ equipment.room }}</h2>
        </div>

        <div class="col-6 text-right">
          <h2
            class="m-0 overflow-ellipsis"
            style="font-weight:400;"
          >
            {{ equipment.name }}
          </h2>
        </div>
      </div>

      <div
        class="position-absolute w-100 text-center"
        style="left:0px; top:40px;"
      >
        <KButton
          :variant="button"
          tag="span"
          class="btn-upper state-tag"
        >
          {{ tagText }}
        </KButton>
      </div>
    </KPortletBody>

    <!-- Default header -->
    <KPortletHead v-else>
      <template v-slot:default>
        <div class="row">
          <!-- Add warning icon -->
          <div
            v-if="equipment.hasTimerWarning || equipment.hasTimerAboveMax"
            class="col-auto p-0 pr-3 m-auto cursor-pointer"
            @click.prevent="$router.push(`/equipment/view/${equipment.guid}/maintenance`)"
          >
            <i
              :class="[(equipment.hasTimerAboveMax ? 'kt-font-danger' : 'kt-font-caution')]"
              style="font-size:2rem;"
            >
              <font-awesome-icon
                :icon="['fad', 'exclamation-triangle']"
              />
            </i>
          </div>

          <!-- Equipment details -->
          <div class="col p-0">
            <div class="cs-header">{{ equipment.name }}</div>
            <div class="cs-subheader">{{ equipment.room }}</div>
          </div>
        </div>
      </template>

      <template v-slot:toolbar>
        <KButton
          :variant="button"
          tag="span"
          class="btn-sm btn-upper state-tag"
        >
          {{ tagText }}
        </KButton>
      </template>
    </KPortletHead>

    <!-- Body -->
    <KPortletBody class="kt-portlet__body--fit pt-3">
      <div class="row position-relative">
        <div
          :class="['col-12 m-auto text-center equipment-image p-3 position-relative', {'disabled': equipment.state !== 'available'}]"
        >
          <!-- Image -->
          <img
            v-if="equipmentImage"
            :src="require(`@/assets/images/equipment/${equipmentImage}`)"
            class="img-fluid w-100"
          >
        </div>

        <!-- Circle -->
        <div
          v-if="equipment.state !== 'available'"
          class="col-12 position-absolute text-center p-3"
          style="top: 0; left:0; right:0; bottom:0;"
        >
          <equipment-circle
            ref="circle"
            :equipment="equipment"

            :use-full-time="useFullTime"

            :circle-class="circleClass"
          />
        </div>
      </div>
    </KPortletBody>
  </KPortlet>
</template>

<script>
// Classes
import Equipment from '@/libs/classes/equipment';

// Components
import equipmentCircle from './circle';
import brands from './brands.json';

export default {
  components: {
    equipmentCircle
  },
  props: {
    equipment: {
      type: Equipment,
      default: null
    },

    useFullTime: {
      type: Boolean,
      default: false
    },
    circleClass: {
      type: [String, Array, Object],
      default: 'w-100 h-100'
    },

    secondScreen: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    equipmentImage: {
      get () {
        if (!this.equipment) {
          return null;
        }

        let image = null;

        // Get brand image
        let brand = (this.equipment.brand ? brands.find(row => row.id === this.equipment.brand) : null);
        if (brand) {
          image = (brand.image || image);
        }

        // Get model image
        let model = (this.equipment.model && brand ? brand.models.find(row => row.id === this.equipment.model) : null);
        if (model) {
          image = (model.image || image);
        }

        // Get type image
        let type = (this.equipment.type && model ? model.types.find(row => row.id === this.equipment.type) : null);
        if (type) {
          image = (type.image || image);
        }

        // Check if image exists
        if (image !== null) {
          try {
            require(`@/assets/images/equipment/${image}`);
          } catch (e) {
            console.error(e);
            return null;
          }
        }

        return image;
      }
    },

    tagText: {
      get () {
        if (this.equipment) {
          if (this.equipment.state === 'powerup') return 'Opstarten';
          if (this.equipment.state === 'available') return 'Beschikbaar';
          if (this.equipment.state === 'delay') return 'Voorloop';
          if (this.equipment.state === 'session') return 'In sessie';
          if (this.equipment.state === 'cooldown') return 'nakoeling';
          if (this.equipment.state === 'cleaning') return 'Schoonmaak';

          if (this.equipment.state === 'timeout') return 'Time-out';
        }

        return 'Onbekend';
      }
    },

    button () {
      if (this.equipment) {
        if (this.equipment.state === 'powerup') return 'state-powerup';
        else if (this.equipment.state === 'timeout') return 'state-timeout';
        else if (this.equipment.state === 'available') return 'state-available';
        else if (this.equipment.state === 'delay') return 'state-delay';
        else if (this.equipment.state === 'session') return 'state-session';
        else if (this.equipment.state === 'cooldown') return 'state-cooldown';
        else if (this.equipment.state === 'cleaning') return 'state-cleaning';
      }

      return 'state-unknown';
    }
  },

  methods: {

  }
};
</script>
