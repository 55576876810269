import Vue from 'vue';
import Router from 'vue-router';

import stores from '@/libs/stores';

// Dashboard
import dashboard from '@/components/dashboard/overview';

// Auth
import authLogout from '@/components/auth/logout';

// Stores
import storesOverview from '@/components/stores/overview';
import storesUpdate from '@/components/stores/update';
import storesView from '@/components/stores/view';

// Users
import usersOverview from '@/components/users/overview';

import usersView from '@/components/users/view/index';
import usersViewPersonalInformation from '@/components/users/view/personal-information';
import usersViewPermissions from '@/components/users/view/permissions';
import usersViewAuthentication from '@/components/users/view/authentication';
import usersViewStores from '@/components/users/view/stores';

import usersCreate from '@/components/users/create';

// Customers
import customersOverview from '@/components/customers/overview';
import customersCreate from '@/components/customers/create';

import customersView from '@/components/customers/view/index';
import customersViewPersonalInformation from '@/components/customers/view/personal-information';
import customersViewAuthentication from '@/components/customers/view/authentication/index';
import customersViewSalesorders from '@/components/customers/view/salesorders';

// Product Groups
import productGroupsOverview from '@/components/products/groups/overview';

import productGroupsView from '@/components/products/groups/view/index';
import productGroupsViewProducts from '@/components/products/groups/view/products';
import productGroupsViewSettings from '@/components/products/groups/view/settings';

import productGroupsCreate from '@/components/products/groups/create';

// Products
import productsOverview from '@/components/products/overview';

import productsView from '@/components/products/view/index';
import productsViewSettings from '@/components/products/view/settings';
import productsViewMutations from '@/components/products/view/mutations';

import productsCreate from '@/components/products/create';

// Stock
// Stock count
import productsStockCountOverview from '@/components/products/stock/count/overview';
import productsStockCountCreate from '@/components/products/stock/count/create';
import productsStockCountView from '@/components/products/stock/count/view/index';

// Stock mutations
import productsStockMutationsOverview from '@/components/products/stock/mutations/overview';
import productsStockMutationsCreate from '@/components/products/stock/mutations/create';
import productsStockMutationsView from '@/components/products/stock/mutations/view/index';

// Pricelists
import productPricelistsOverview from '@/components/products/pricelists/overview';

import productPricelistsView from '@/components/products/pricelists/view/index';
import productPricelistsViewSettings from '@/components/products/pricelists/view/settings';
import productPricelistsViewPrices from '@/components/products/pricelists/view/prices';

import productPricelistsCreate from '@/components/products/pricelists/create';

// Credit Products
import productCreditOverview from '@/components/products/credit/overview';
import productCreditView from '@/components/products/credit/view';
import productCreditCreate from '@/components/products/credit/create';

// Bundles
// Bundles (default)
import bundlesOverview from '@/components/bundles/overview';

import bundlesView from '@/components/bundles/view/index';
import bundlesViewSettings from '@/components/bundles/view/settings';
import bundlesViewSessions from '@/components/bundles/view/sessions';
import bundlesViewProducts from '@/components/bundles/view/products';

import bundlesCreate from '@/components/bundles/create';

// Subscriptions
import bundlesSubscriptionsOverview from '@/components/bundles/subscriptions/overview';

// Treatments
import bundlesTreatmentsOverview from '@/components/bundles/treatments/overview';

// Product bundles
import bundlesProductBundlesOverview from '@/components/bundles/product_bundles/overview';

// Cash Registers
import cashRegistersOverview from '@/components/cash_registers/overview';

import cashRegistersView from '@/components/cash_registers/view/index';
import cashRegistersViewSettings from '@/components/cash_registers/view/settings';
import cashRegistersViewSalesorders from '@/components/cash_registers/view/salesorders';
import cashRegistersViewClosures from '@/components/cash_registers/view/closures';

import cashRegistersSalesordersOverview from '@/components/cash_registers/salesorders/overview';

import cashRegistersCreate from '@/components/cash_registers/create';

// Sales orders
import salesOrdersView from '@/components/salesorders/view';

import salesOrdersPayback from '@/components/salesorders/payback';

import cashRegistersSalesordersPaybackOld from '@/components/salesorders/payback';

// Cash register closures
import cashRegistersClosuresOverview from '@/components/cash_registers/closures/overview';
import cashRegistersClosuresView from '@/components/cash_registers/closures/view';

// Sales
// Shop
import salesShop from '@/components/sales/shop/index';

// Shop Cart
import salesShopViewCart from '@/components/sales/shop/view/cart/index';
import salesShopViewCartOverview from '@/components/sales/shop/view/cart/overview';

import salesShopViewCartSearch from '@/components/sales/shop/view/cart/search';

import salesShopViewCartProductsOverview from '@/components/sales/shop/view/cart/products/overview';
import salesShopViewCartProductsGroupOverview from '@/components/sales/shop/view/cart/products/group';
import salesShopViewCartBundlesOverview from '@/components/sales/shop/view/cart/bundles/overview';
import salesShopViewCartSubscriptionsOverview from '@/components/sales/shop/view/cart/subscriptions/overview';
import salesShopViewCartTreatmentsOverview from '@/components/sales/shop/view/cart/treatments/overview';
import salesShopViewCartProductBundlesOverview from '@/components/sales/shop/view/cart/product_bundles/overview';
import salesShopViewCartCreditOverview from '@/components/sales/shop/view/cart/credit/overview';

// Shop Payment
import salesShopViewPayments from '@/components/sales/shop/view/payments/index';
import salesShopViewPaymentsOverview from '@/components/sales/shop/view/payments/overview';

// Shop Finish
import salesShopViewFinish from '@/components/sales/shop/view/finish/index';

import salesShopRedirect from '@/components/sales/shop/redirect';

// Cash registers
import salesCashRegistersOverview from '@/components/sales/cash_registers/overview';
import salesCashRegistersViewActions from '@/components/sales/cash_registers/view/actions';
import salesCashRegistersViewSalesorders from '@/components/sales/cash_registers/view/salesorders';

// Current cash register sales orders
import salesSalesordersRedirect from '@/components/sales/salesorders/redirect';

// Equipment
import equipmentOverview from '@/components/equipment/overview';

import equipmentView from '@/components/equipment/view/index';
import equipmentViewSettings from '@/components/equipment/view/settings';
import equipmentViewPricelists from '@/components/equipment/view/pricelists';
import equipmentViewMaintenance from '@/components/equipment/view/maintenance';

import equipmentCreate from '@/components/equipment/create';

// Equipment Groups
import equipmentGroupsOverview from '@/components/equipment/groups/overview';
import equipmentGroupsView from '@/components/equipment/groups/view';
import equipmentGroupsCreate from '@/components/equipment/groups/create';

// Equipment Sessions
import equipmentSessionsOverview from '@/components/equipment/sessions/overview';

import equipmentSessionsView from '@/components/equipment/sessions/view/';
import equipmentSessionsViewSettings from '@/components/equipment/sessions/view/settings';

import equipmentSessionsCreate from '@/components/equipment/sessions/create';

// Equipment pricelists
import equipmentSessionPricelistsOverview from '@/components/equipment/pricelists/overview';

import equipmentSessionPricelistsView from '@/components/equipment/pricelists/view/index';
import equipmentSessionPricelistsViewSettings from '@/components/equipment/pricelists/view/settings';
import equipmentSessionPricelistsViewPrices from '@/components/equipment/pricelists/view/prices';
import equipmentSessionPricelistsViewEquipment from '@/components/equipment/pricelists/view/equipment';

import equipmentSessionPricelistsCreate from '@/components/equipment/pricelists/create';

// Statistics
// Statistics - Totals
import statisticsTotals from '@/components/statistics/totals/index';

// Statistics - Daily
import statisticsDaily from '@/components/statistics/daily/index';

// Statistics - Monthly
import statisticsMonthly from '@/components/statistics/monthly/index';

// Statistics - Users
import statisticsUsers from '@/components/statistics/users/index';

// Statistics - VAT overview
import statisticsVatOverview from '@/components/statistics/vat_overview/index';

// Devices
import hardwareOverview from '@/components/hardware/overview';
import hardwareCreate from '@/components/hardware/create';
import hardwareView from '@/components/hardware/view';

// Settings
// Settings -> Personal
import settingsPersonal from '@/components/settings/personal/index';
import settingsPersonalInformation from '@/components/settings/personal/information';
import settingsPersonalAuthentication from '@/components/settings/personal/authentication/index';

// Settings -> Store
import settingsStore from '@/components/settings/store/index';
import settingsStoreGeneral from '@/components/settings/store/general';

// Settings -> Store -> Credit Reasons
import settingsCreditReasonsRouterView from '@/components/settings/store/credit_reasons_router-view';
import settingsCreditReasonsOverview from '@/components/settings/store/credit_reasons/overview';
import settingsCreditReasonsView from '@/components/settings/store/credit_reasons/view';
import settingsCreditReasonsCreate from '@/components/settings/store/credit_reasons/create';

// Settings -> Store -> Second Screens
import settingsSecondScreensOverview from '@/components/settings/store/second_screens/overview';
import settingsSecondScreensView from '@/components/settings/store/second_screens/view';
import settingsSecondScreensCreate from '@/components/settings/store/second_screens/create';

// Settings -> Workspace
import settingsWorkspace from '@/components/settings/workspace/index';
import settingsWorkspaceIntegrations from '@/components/settings/workspace/integrations/index';
import settingsWorkspaceIntegrationsMailchimp from '@/components/settings/workspace/integrations/configure/mailchimp';

// Second Screen (TV Page)
import secondScreen from '@/components/second_screen/index';

// Errors
import errorNotFound from '@/components/errors/notfound';

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [{
    path: '/',
    redirect: '/dashboard'
  },
  {
    path: '/dashboard',
    name: '/dashboard',
    component: dashboard,
    meta: {
      scope: [['loggedin']]
    }
  },

  // Auth
  {
    path: '/logout',
    name: 'Logout',
    component: authLogout,
    meta: {
      scope: [['loggedin']]
    }
  },

  // Stores
  {
    path: '/stores',
    redirect: '/stores/overview'
  },
  {
    path: '/stores/overview',
    name: 'storesOverview',
    component: storesOverview,
    meta: {
      scope: [['get_stores']],

      menu: {
        submenu: '',
        item: '/stores/overview'
      }
    }
  },
  {
    path: '/stores/create',
    name: 'storesCreate',
    component: storesUpdate,
    meta: {
      scope: [['create_stores']],

      menu: {
        submenu: '',
        item: '/stores/overview'
      }
    }
  },
  {
    path: '/stores/edit/:guid',
    name: 'storesUpdate',
    component: storesUpdate,
    meta: {
      scope: [['update_stores']],

      menu: {
        submenu: '',
        item: '/stores/overview'
      }
    }
  },
  {
    path: '/stores/view/:guid',
    name: 'storesView',
    component: storesView,
    meta: {
      scope: [['get_stores']],

      menu: {
        submenu: '',
        item: '/stores/overview'
      }
    }
  },

  // Customers
  {
    path: '/customers',
    redirect: '/customers/overview'
  },
  {
    path: '/customers/overview',
    name: 'customersOverview',
    component: customersOverview,
    meta: {
      scope: [['get_customers']],

      menu: {
        submenu: 'customers',
        item: '/customers/overview'
      }
    }
  },
  {
    path: '/customers/create',
    name: '/customers/create',
    component: customersCreate,
    meta: {
      scope: [['create_customers']],

      menu: {
        submenu: 'customers',
        item: '/customers/overview'
      }
    }
  },
  {
    path: '/customers/view/:guid',
    name: 'customersView',
    component: customersView,
    redirect: '/customers/view/:guid/personal_information',
    meta: {
      scope: [['get_customers']],

      menu: {
        submenu: 'customers',
        item: '/customers/overview'
      }
    },
    children: [{
      name: 'customersViewPersonalInformation',
      path: 'personal_information',
      component: customersViewPersonalInformation
    }, {
      name: 'customersViewAuthentication',
      path: 'authentication',
      component: customersViewAuthentication
    }, {
      path: 'salesorders',
      component: customersViewSalesorders
    }]
  },

  // Products
  {
    path: '/products',
    redirect: '/products/overview'
  },
  {
    path: '/products/overview',
    name: 'productsOverview',
    component: productsOverview,
    meta: {
      scope: [['get_products']],

      menu: {
        submenu: 'products',
        item: '/products/overview'
      }
    }
  },
  {
    path: '/products/view/:guid',
    name: 'productsView',
    component: productsView,
    redirect: '/products/view/:guid/settings',
    meta: {
      scope: [['get_products']],

      menu: {
        submenu: 'products',
        item: '/products/overview'
      }
    },
    children: [{
      path: 'settings',
      name: 'productsViewSettings',
      component: productsViewSettings
    }, {
      path: 'mutations',
      name: 'productsViewMutations',
      component: productsViewMutations
    }]
  },
  {
    path: '/products/create',
    name: 'productsUpdate',
    component: productsCreate,
    meta: {
      scope: [['create_products']],

      menu: {
        submenu: 'products',
        item: '/products/overview'
      }
    }
  },

  // Pricelists
  {
    path: '/products/pricelists',
    redirect: '/products/pricelists/overview'
  },
  {
    path: '/products/pricelists/overview',
    name: 'productPricelistsOverview',
    component: productPricelistsOverview,
    meta: {
      scope: [['get_pricelists']],

      menu: {
        submenu: 'products',
        item: '/products/pricelists/overview'
      }
    }
  },
  {
    path: '/products/pricelists/view/:guid',
    name: 'productPricelistsView',
    component: productPricelistsView,
    redirect: '/products/pricelists/view/:guid/settings',
    meta: {
      scope: [['get_pricelists']],

      menu: {
        submenu: 'products',
        item: '/products/pricelists/overview'
      }
    },
    children: [{
      path: 'settings',
      name: 'productPricelistsViewSettings',
      component: productPricelistsViewSettings
    }, {
      path: 'prices',
      name: 'productPricelistsViewPrices',
      component: productPricelistsViewPrices
    }]
  },
  {
    path: '/products/pricelists/create',
    name: 'productPricelistsCreate',
    component: productPricelistsCreate,
    meta: {
      scope: [['create_pricelists']],

      menu: {
        submenu: 'products',
        item: '/products/pricelists/overview'
      }
    }
  },

  // Product Groups
  {
    path: '/products/groups',
    redirect: '/products/groups/overview'
  },
  {
    path: '/products/groups/overview',
    name: 'productGroupsOverview',
    component: productGroupsOverview,
    meta: {
      scope: [['get_product_groups']],

      menu: {
        submenu: 'products',
        item: '/products/groups/overview'
      }
    }
  },
  {
    path: '/products/groups/create',
    name: 'productGroupsCreate',
    component: productGroupsCreate,
    meta: {
      scope: [['create_product_groups']],

      menu: {
        submenu: 'products',
        item: '/products/groups/overview'
      }
    }
  },
  {
    path: '/products/groups/view/:guid',
    name: 'productGroupsView',
    component: productGroupsView,
    redirect: '/products/groups/view/:guid/products',
    meta: {
      scope: [['get_product_groups']],

      menu: {
        submenu: 'products',
        item: '/products/groups/overview'
      }
    },
    children: [{
      path: 'products',
      name: 'productGroupsViewProducts',
      component: productGroupsViewProducts
    }, {
      path: 'settings',
      name: 'productGroupsViewSettings',
      component: productGroupsViewSettings
    }]
  },

  // Credit Products
  {
    path: '/products/credit',
    redirect: '/products/credit/overview'
  },
  {
    path: '/products/credit/overview',
    name: 'productCreditOverview',
    component: productCreditOverview,
    meta: {
      scope: [['get_credit_products']],

      menu: {
        submenu: 'products',
        item: '/products/credit/overview'
      }
    }
  },
  {
    path: '/products/credit/view/:guid',
    name: 'productCreditView',
    component: productCreditView,
    meta: {
      scope: [['get_credit_products']],

      menu: {
        submenu: 'products',
        item: '/products/credit/overview'
      }
    }
  },
  {
    path: '/products/credit/create',
    name: 'productCreditCreate',
    component: productCreditCreate,
    meta: {
      scope: [['create_credit_products']],

      menu: {
        submenu: 'products',
        item: '/products/credit/overview'
      }
    }
  },

  // Stock count
  {
    path: '/products/stock/count',
    redirect: '/products/stock/count/overview'
  },
  {
    path: '/products/stock/count/overview',
    name: 'productsStockCountOverview',
    component: productsStockCountOverview,
    meta: {
      scope: [['get_stock_mutations']],

      menu: {
        submenu: ['products', 'stock'],
        item: '/products/stock/count/overview'
      }
    }
  },
  {
    path: '/products/stock/count/create/:guid?',
    name: 'productsStockCountCreate',
    component: productsStockCountCreate,
    meta: {
      scope: [['create_stock_mutations']],

      menu: {
        submenu: ['products', 'stock'],
        item: '/products/stock/count/overview'
      }
    }
  },
  {
    path: '/products/stock/count/view/:guid',
    name: 'productsStockCountView',
    component: productsStockCountView,
    meta: {
      scope: [['get_stock_mutations']],

      menu: {
        submenu: ['products', 'stock'],
        item: '/products/stock/count/overview'
      }
    }
  },

  // Stock mutations
  {
    path: '/products/stock/mutations',
    redirect: '/products/stock/mutations/overview'
  },
  {
    path: '/products/stock/mutations/overview',
    name: 'productsStockMutationsOverview',
    component: productsStockMutationsOverview,
    meta: {
      scope: [['get_stock_mutations']],

      menu: {
        submenu: ['products', 'stock'],
        item: '/products/stock/mutations/overview'
      }
    }
  },
  {
    path: '/products/stock/mutations/create/:guid?',
    name: 'productsStockMutationsCreate',
    component: productsStockMutationsCreate,
    meta: {
      scope: [['create_stock_mutations']],

      menu: {
        submenu: ['products', 'stock'],
        item: '/products/stock/mutations/overview'
      }
    }
  },
  {
    path: '/products/stock/mutations/view/:guid',
    name: 'productsStockMutationsView',
    component: productsStockMutationsView,
    meta: {
      scope: [['get_stock_mutations']],

      menu: {
        submenu: ['products', 'stock'],
        item: '/products/stock/mutations/overview'
      }
    }
  },

  // Bundles
  // Bundles (default)
  {
    path: '/bundles',
    redirect: '/bundles/overview'
  },
  {
    path: '/bundles/overview',
    name: '/bundles/overview',
    component: bundlesOverview,
    meta: {
      scope: [['get_bundle_products']],

      menu: {
        submenu: 'bundles',
        item: '/bundles/overview'
      }
    }
  },
  {
    path: '/bundles/view/:guid',
    name: '/bundles/view/:guid',
    component: bundlesView,
    redirect: '/bundles/view/:guid/settings',
    meta: {
      scope: [['get_bundle_products']],
      bundle: 'bundle',

      menu: {
        submenu: 'bundles',
        item: '/bundles/overview'
      }
    },
    children: [{
      path: 'settings',
      name: '/bundles/view/:guid/settings',
      component: bundlesViewSettings
    }, {
      path: 'sessions',
      name: '/bundles/view/:guid/sessions',
      component: bundlesViewSessions
    }, {
      path: 'products',
      name: '/bundles/view/:guid/products',
      component: bundlesViewProducts
    }]
  },
  {
    path: '/bundles/create',
    name: '/bundles/create',
    component: bundlesCreate,
    meta: {
      scope: [['create_bundle_products']],
      bundle: 'bundle',

      menu: {
        submenu: 'bundles',
        item: '/bundles/overview'
      }
    }
  },

  // Subscriptions
  {
    path: '/bundles/subscriptions',
    redirect: '/bundles/subscriptions'
  },
  {
    path: '/bundles/subscriptions/overview',
    name: '/bundles/subscriptions/overview',
    component: bundlesSubscriptionsOverview,
    meta: {
      scope: [['get_bundle_products']],

      menu: {
        submenu: 'bundles',
        item: '/bundles/subscriptions/overview'
      }
    }
  },
  {
    path: '/bundles/subscriptions/view/:guid',
    name: '/bundles/subscriptions/view/:guid',
    component: bundlesView,
    redirect: '/bundles/subscriptions/view/:guid/settings',
    meta: {
      scope: [['get_bundle_products']],
      bundle: 'subscription',

      menu: {
        submenu: 'bundles',
        item: '/bundles/subscriptions/overview'
      }
    },
    children: [{
      path: 'settings',
      name: '/bundles/subscriptions/view/:guid/settings',
      component: bundlesViewSettings
    }, {
      path: 'sessions',
      name: '/bundles/subscriptions/view/:guid/sessions',
      component: bundlesViewSessions
    }]
  },
  {
    path: '/bundles/subscriptions/create',
    name: '/bundles/subscriptions/create',
    component: bundlesCreate,
    meta: {
      scope: [['create_bundle_products']],
      bundle: 'subscription',

      menu: {
        submenu: 'bundles',
        item: '/bundles/subscriptions/overview'
      }
    }
  },

  // Treatments
  {
    path: '/bundles/treatments/overview',
    name: '/bundles/treatments/overview',
    component: bundlesTreatmentsOverview,
    meta: {
      scope: [['get_bundle_products']],

      menu: {
        submenu: 'bundles',
        item: '/bundles/treatments/overview'
      }
    }
  },
  {
    path: '/bundles/treatments/view/:guid',
    name: '/bundles/treatments/view/:guid',
    component: bundlesView,
    redirect: '/bundles/treatments/view/:guid/settings',
    meta: {
      scope: [['get_bundle_products']],
      bundle: 'treatment',

      menu: {
        submenu: 'bundles',
        item: '/bundles/treatments/overview'
      }
    },
    children: [{
      path: 'settings',
      name: '/bundles/treatments/view/:guid/settings',
      component: bundlesViewSettings
    }, {
      path: 'sessions',
      name: '/bundles/treatments/view/:guid/sessions',
      component: bundlesViewSessions
    }, {
      path: 'products',
      name: '/bundles/treatments/view/:guid/products',
      component: bundlesViewProducts
    }]
  },
  {
    path: '/bundles/treatments/create',
    name: '/bundles/treatments/create',
    component: bundlesCreate,
    meta: {
      scope: [['create_bundle_products']],
      bundle: 'treatment',

      menu: {
        submenu: 'bundles',
        item: '/bundles/treatments/overview'
      }
    }
  },

  // Product bundles
  {
    path: '/bundles/product',
    redirect: '/bundles/product/overview'
  },
  {
    path: '/bundles/product/overview',
    name: '/bundles/product/overview',
    component: bundlesProductBundlesOverview,
    meta: {
      scope: [['get_bundle_products']],

      menu: {
        submenu: 'bundles',
        item: '/bundles/product/overview'
      }
    }
  },
  {
    path: '/bundles/product/view/:guid',
    name: '/bundles/product/view/:guid',
    component: bundlesView,
    redirect: '/bundles/product/view/:guid/settings',
    meta: {
      scope: [['get_bundle_products']],
      bundle: 'product_bundle',

      menu: {
        submenu: 'bundles',
        item: '/bundles/product/overview'
      }
    },
    children: [{
      path: 'settings',
      name: '/bundles/product/view/:guid/settings',
      component: bundlesViewSettings
    }, {
      path: 'products',
      name: '/bundles/product/view/:guid/products',
      component: bundlesViewProducts
    }]
  },
  {
    path: '/bundles/product/create',
    name: '/bundles/product/create',
    component: bundlesCreate,
    meta: {
      scope: [['create_bundle_products']],
      bundle: 'product_bundle',

      menu: {
        submenu: 'bundles',
        item: '/bundles/product/overview'
      }
    }
  },

  // Salesorders
  {
    path: '/cash_registers/salesorders',
    redirect: '/cash_registers/salesorders/overview'
  },
  {
    path: '/cash_registers/salesorders/overview',
    name: '/cash_registers/salesorders/overview',
    component: cashRegistersSalesordersOverview,
    meta: {
      scope: [['get_salesorders']],

      menu: {
        submenu: 'cash_registers',
        item: '/cash_registers/salesorders/overview'
      }
    }
  },
  {
    path: '/cash_registers/salesorders/view/:guid',
    name: '/cash_registers/salesorders/view/:guid',
    component: salesOrdersView,
    meta: {
      scope: [['get_salesorders']],

      menu: {
        submenu: 'cash_registers',
        item: '/cash_registers/salesorders/overview'
      }
    }
  },
  {
    path: '/cash_registers/salesorders/payback/:guid',
    name: '/cash_registers/salesorders/payback/:guid',
    component: salesOrdersPayback,
    meta: {
      scope: [['get_salesorders']], // TODO: Should be payback_salesorders or something

      menu: {
        submenu: 'cash_registers',
        item: '/cash_registers/salesorders/overview'
      }
    }
  },
  {
    path: '/cash_registers/salesorders/payback_old/:guid',
    name: '/cash_registers/salesorders/payback_old/:guid',
    component: salesOrdersPayback,
    meta: {
      scope: [['get_salesorders']], // TODO: Should be payback_salesorders or something

      menu: {
        submenu: 'cash_registers',
        item: '/cash_registers/salesorders/overview'
      }
    }
  },

  // Sales
  // Shop
  {
    name: 'sales',
    path: '/sales',
    redirect: '/sales/shop'
  },
  {
    path: '/sales/shop',
    name: '/sales/shop',
    component: salesShopRedirect,
    meta: {
      scope: [['shop']],

      menu: {
        submenu: 'sales',
        item: '/sales/shop'
      }
    }
  },
  {
    path: '/sales/shop/:cash_register',
    name: '/sales/shop/:cash_register',
    component: salesShop,
    meta: {
      scope: [['shop']],

      menu: {
        submenu: 'sales',
        item: '/sales/shop'
      }
    },
    children: [{
      path: 'cart',
      name: '/sales/shop/:cash_register/cart',
      component: salesShopViewCart,
      redirect: '/sales/shop/:cash_register/cart/overview',
      meta: {
        shop: {
          action: 'CART'
        }
      },
      children: [
        // Overview of all categories
        {
          path: 'overview',
          name: '/sales/shop/:cash_register/cart/overview',
          component: salesShopViewCartOverview
        },

        // Search
        {
          path: 'search/:search?',
          name: '/sales/shop/:cash_register/cart/search/:search',
          component: salesShopViewCartSearch
        },

        // Products
        {
          path: 'products',
          name: '/sales/shop/:cash_register/cart/products',
          redirect: '/sales/shop/:cash_register/cart/products/overview'
        },
        {
          path: 'products/overview',
          name: '/sales/shop/:cash_register/cart/products/overview',
          component: salesShopViewCartProductsOverview
        },
        {
          path: 'products/:group_guid',
          name: '/sales/shop/:cash_register/cart/products/:group_guid',
          redirect: '/sales/shop/:cash_register/cart/products/:group_guid/overview'
        },
        {
          path: 'products/:group_guid/overview',
          name: '/sales/shop/:cash_register/cart/products/:group_guid/overview',
          component: salesShopViewCartProductsGroupOverview
        },

        // Bundles
        // Bundles (default)
        {
          path: 'bundles',
          name: '/sales/shop/:cash_register/cart/bundles',
          redirect: '/sales/shop/:cash_register/cart/bundles/overview'
        },
        {
          path: 'bundles/overview',
          name: '/sales/shop/:cash_register/cart/bundles/overview',
          component: salesShopViewCartBundlesOverview
        },

        // Subscriptions
        {
          path: 'subscriptions',
          name: '/sales/shop/:cash_register/cart/subscriptions',
          redirect: '/sales/shop/:cash_register/cart/subscriptions/overview'
        },
        {
          path: 'subscriptions/overview',
          name: '/sales/shop/:cash_register/cart/subscriptions/overview',
          component: salesShopViewCartSubscriptionsOverview
        },

        // Treatments
        {
          path: 'treatments',
          name: '/sales/shop/:cash_register/cart/treatments',
          redirect: '/sales/shop/:cash_register/cart/treatments/overview'
        },
        {
          path: 'treatments/overview',
          name: '/sales/shop/:cash_register/cart/treatments/overview',
          component: salesShopViewCartTreatmentsOverview
        },

        // Product bundles
        {
          path: 'product_bundles',
          name: '/sales/shop/:cash_register/cart/product_bundles',
          redirect: '/sales/shop/:cash_register/cart/product_bundles/overview'
        },
        {
          path: 'product_bundles/overview',
          name: '/sales/shop/:cash_register/cart/product_bundles/overview',
          component: salesShopViewCartProductBundlesOverview
        },

        // Credit
        {
          path: 'credit',
          name: '/sales/shop/:cash_register/cart/credit',
          redirect: '/sales/shop/:cash_register/cart/credit/overview'
        },
        {
          path: 'credit/overview',
          name: '/sales/shop/:cash_register/cart/credit/overview',
          component: salesShopViewCartCreditOverview
        }
      ]
    }, {
      path: 'payments',
      name: '/sales/shop/:cash_register/payments',
      component: salesShopViewPayments,
      redirect: '/sales/shop/:cash_register/payments/overview',
      meta: {
        shop: {
          action: 'PAYMENTS'
        }
      },
      children: [
        // Overview of payment methods
        {
          path: 'overview',
          name: '/sales/shop/:cash_register/payments/overview',
          component: salesShopViewPaymentsOverview
        }
      ]
    }, {
      path: 'finish',
      name: '/sales/shop/:cash_register/finish',
      component: salesShopViewFinish,
      meta: {
        shop: {
          action: 'FINISH'
        }
      }
    }]
  },

  // Cash registers
  {
    path: '/sales/cash_registers',
    name: '/sales/cash_registers',
    redirect: '/sales/cash_registers/overview'
  },
  {
    path: '/sales/cash_registers/overview',
    name: '/sales/cash_registers/overview',
    component: salesCashRegistersOverview,
    meta: {
      scope: [['shop']],

      menu: {
        submenu: 'sales',
        item: '/sales/shop'
      }
    }
  },
  {
    path: '/sales/cash_registers/view/:guid/actions/:action?',
    name: 'salesCashRegistersViewActions',
    component: salesCashRegistersViewActions,
    meta: {
      scope: [['shop_open', 'shop_close', 'create_cash_register_openings']],

      menu: {
        submenu: 'sales',
        item: '/sales/shop'
      }
    }
  },
  {
    path: '/sales/cash_registers/view/:cash_register/salesorders',
    name: '/sales/cash_registers/view/:cash_register/salesorders',
    component: salesCashRegistersViewSalesorders,
    meta: {
      scope: [['get_salesorders']],

      menu: {
        submenu: 'sales',
        item: '/sales/salesorders'
      }
    }
  },
  {
    path: '/sales/cash_registers/view/:cash_register/salesorders/:guid',
    name: '/sales/cash_registers/view/:cash_register/salesorders/:guid',
    component: salesOrdersView,
    meta: {
      scope: [['get_salesorders']],

      menu: {
        submenu: 'sales',
        item: '/sales/salesorders'
      }
    }
  },
  // Shop Sales orders redirect
  {
    path: '/sales/salesorders',
    name: '/sales/salesorders',
    component: salesSalesordersRedirect,
    meta: {
      scope: [['get_salesorders']],

      menu: {
        submenu: 'sales',
        item: '/sales/salesorders'
      }
    }
  },

  // Equipment
  {
    path: '/equipment',
    redirect: '/equipment/overview'
  },
  {
    path: '/equipment/overview',
    name: 'equipmentOverview',
    component: equipmentOverview,
    meta: {
      scope: [['get_equipments']],

      menu: {
        submenu: 'equipment',
        item: '/equipment/overview'
      }
    }
  },
  {
    path: '/equipment/view/:guid',
    name: 'equipmentView',
    component: equipmentView,
    redirect: '/equipment/view/:guid/settings',
    meta: {
      scope: [['get_equipments']],

      menu: {
        submenu: 'equipment',
        item: '/equipment/overview'
      }
    },
    children: [{
      path: 'settings',
      name: 'equipmentViewSettings',
      component: equipmentViewSettings
    }, {
      path: 'pricelists',
      name: 'equipmentViewPricelists',
      component: equipmentViewPricelists,
      meta: {
        scope: [['get_pricelists']]
      }
    }, {
      path: 'maintenance',
      name: 'equipmentViewMaintenance',
      component: equipmentViewMaintenance
    }]
  },
  {
    path: '/equipment/create',
    name: 'equipmentCreate',
    component: equipmentCreate,
    meta: {
      scope: [['create_equipments']],

      menu: {
        submenu: 'equipment',
        item: '/equipment/overview'
      }
    }
  },

  // Sessions
  {
    path: '/equipment/sessions',
    redirect: '/equipment/sessions/overview'
  },
  {
    path: '/equipment/sessions/overview',
    name: 'equipmentSessionsOverview',
    component: equipmentSessionsOverview,
    meta: {
      scope: [['get_session_products']],

      menu: {
        submenu: 'equipment',
        item: '/equipment/sessions/overview'
      }
    }
  },
  {
    path: '/equipment/sessions/view/:guid',
    name: 'equipmentSessionsView',
    component: equipmentSessionsView,
    redirect: '/equipment/sessions/view/:guid/information',
    meta: {
      scope: [['get_session_products']],

      menu: {
        submenu: 'equipment',
        item: '/equipment/sessions/overview'
      }
    },
    children: [{
      name: 'equipmentSessionsViewInformation',
      path: 'information',
      component: equipmentSessionsViewSettings
    }]
  },
  {
    path: '/equipment/sessions/create',
    name: 'equipmentSessionsCreate',
    component: equipmentSessionsCreate,
    meta: {
      scope: [['create_session_products']],

      menu: {
        submenu: 'equipment',
        item: '/equipment/sessions/overview'
      }
    }
  },

  // Session pricelists
  {
    path: '/equipment/pricelists',
    redirect: '/equipment/pricelists/overview'
  },
  {
    path: '/equipment/pricelists/overview',
    name: 'equipmentSessionPricelistsOverview',
    component: equipmentSessionPricelistsOverview,
    meta: {
      scope: [['get_pricelists']],

      menu: {
        submenu: 'equipment',
        item: '/equipment/pricelists/overview'
      }
    }
  },
  {
    path: '/equipment/pricelists/view/:guid',
    name: 'equipmentSessionPricelistsView',
    component: equipmentSessionPricelistsView,
    redirect: '/equipment/pricelists/view/:guid/settings',
    meta: {
      scope: [['get_pricelists']],

      menu: {
        submenu: 'equipment',
        item: '/equipment/pricelists/overview'
      }
    },
    children: [{
      path: 'settings',
      name: 'equipmentSessionPricelistsViewSettings',
      component: equipmentSessionPricelistsViewSettings
    }, {
      path: 'prices',
      name: 'equipmentSessionPricelistsViewPrices',
      component: equipmentSessionPricelistsViewPrices
    }, {
      path: 'equipment',
      name: 'equipmentSessionPricelistsViewEquipment',
      component: equipmentSessionPricelistsViewEquipment
    }]
  },
  {
    path: '/equipment/pricelists/create',
    name: 'equipmentSessionPricelistsCreate',
    component: equipmentSessionPricelistsCreate,
    meta: {
      scope: [['create_pricelists']],

      menu: {
        submenu: 'equipment',
        item: '/equipment/pricelists/overview'
      }
    }
  },

  // Equipment groups
  {
    path: '/equipment/groups',
    redirect: '/equipment/groups/overview'
  },
  {
    path: '/equipment/groups/overview',
    name: 'equipmentGroupsOverview',
    component: equipmentGroupsOverview,
    meta: {
      scope: [['get_equipment_groups']],

      menu: {
        submenu: 'equipment',
        item: '/equipment/groups/overview'
      }
    }
  },
  {
    path: '/equipment/groups/view/:guid',
    name: 'equipmentGroupsView',
    component: equipmentGroupsView,
    meta: {
      scope: [['get_equipment_groups']],

      menu: {
        submenu: 'equipment',
        item: '/equipment/groups/overview'
      }
    }
  },
  {
    path: '/equipment/groups/create',
    name: 'equipmentGroupsCreate',
    component: equipmentGroupsCreate,
    meta: {
      scope: [['create_equipment_groups']],

      menu: {
        submenu: 'equipment',
        item: '/equipment/groups/overview'
      }
    }
  },

  // Users
  {
    name: 'users',
    path: '/users',
    redirect: '/users/overview'
  },
  {
    path: '/users/overview',
    name: 'usersOverview',
    component: usersOverview,
    meta: {
      scope: [['get_users']],

      menu: {
        submenu: 'users',
        item: '/users/overview'
      }
    }
  },
  {
    name: '/users/view/:guid',
    path: '/users/view/:guid',
    redirect: '/users/view/:guid/personal_information',
    component: usersView,
    meta: {
      scope: [['get_users']],

      menu: {
        submenu: 'users',
        item: '/users/overview'
      }
    },
    children: [{
      name: '/users/view/:guid/personal_information',
      path: 'personal_information',
      component: usersViewPersonalInformation
    }, {
      name: '/users/view/:guid/permissions',
      path: 'permissions',
      component: usersViewPermissions
    }, {
      name: '/users/view/:guid/authentication',
      path: 'authentication',
      component: usersViewAuthentication
    }, {
      name: '/users/view/:guid/stores',
      path: 'stores',
      component: usersViewStores
    }]
  },
  {
    path: '/users/create',
    name: '/users/create',
    component: usersCreate,
    meta: {
      scope: [['create_users']],

      menu: {
        submenu: 'users',
        item: '/users/overview'
      }
    }
  },

  // Cash Registers
  {
    path: '/cash_registers',
    redirect: '/cash_registers/overview'
  },
  {
    path: '/cash_registers/overview',
    name: 'cashRegistersOverview',
    component: cashRegistersOverview,
    meta: {
      scope: [['get_cash_registers']],

      menu: {
        submenu: 'cash_registers',
        item: '/cash_registers/overview'
      }
    }
  },
  {
    path: '/cash_registers/view/:guid',
    name: 'cashRegistersView',
    component: cashRegistersView,
    redirect: '/cash_registers/view/:guid/settings',
    meta: {
      scope: [['get_cash_registers']],

      menu: {
        submenu: 'cash_registers',
        item: '/cash_registers/overview'
      }
    },
    children: [{
      path: 'settings',
      name: '/cash_registers/view/:guid/settings',
      component: cashRegistersViewSettings
    }, {
      path: 'salesorders',
      name: '/cash_registers/view/:guid/salesorders',
      component: cashRegistersViewSalesorders,

      meta: {
        scope: [['get_salesorders']]
      }
    }, {
      path: 'closures',
      name: '/cash_registers/view/:guid/closures',
      component: cashRegistersViewClosures
    }]
  },
  {
    path: '/cash_registers/create',
    name: 'cashRegistersCreate',
    component: cashRegistersCreate,
    meta: {
      scope: [['create_cash_registers']],

      menu: {
        submenu: 'cash_registers',
        item: '/cash_registers/overview'
      }
    }
  },

  // Cash register closures
  {
    path: '/cash_registers/closures',
    redirect: '/cash_registers/closures/overview'
  },
  {
    path: '/cash_registers/closures/overview',
    name: '/cash_registers/closures/overview',
    component: cashRegistersClosuresOverview,
    meta: {
      scope: [['get_cash_register_openings']],

      menu: {
        submenu: 'cash_registers',
        item: '/cash_registers/closures/overview'
      }
    }
  },
  {
    path: '/cash_registers/closures/view/:guid',
    name: '/cash_registers/closures/view/:guid',
    component: cashRegistersClosuresView,
    meta: {
      scope: [['get_cash_register_openings']],

      menu: {
        submenu: 'cash_registers',
        item: '/cash_registers/closures/overview'
      }
    }
  },

  // Statistics
  {
    path: '/statistics',
    redirect: '/statistics/totals'
  },
  {
    path: '/statistics/totals',
    name: '/statistics/totals',
    component: statisticsTotals,
    meta: {
      scope: [['stats']],

      menu: {
        submenu: 'statistics',
        item: '/statistics/totals'
      }
    }
  },
  {
    path: '/statistics/daily',
    name: '/statistics/daily',
    component: statisticsDaily,
    meta: {
      scope: [['stats']],

      menu: {
        submenu: 'statistics',
        item: '/statistics/daily'
      }
    }
  },
  {
    path: '/statistics/monthly',
    name: '/statistics/monthly',
    component: statisticsMonthly,
    meta: {
      scope: [['stats']],

      menu: {
        submenu: 'statistics',
        item: '/statistics/monthly'
      }
    }
  },
  {
    path: '/statistics/users',
    name: '/statistics/users',
    component: statisticsUsers,
    meta: {
      scope: [['stats']],

      menu: {
        submenu: 'statistics',
        item: '/statistics/users'
      }
    }
  },
  {
    path: '/statistics/vat_overview',
    name: '/statistics/vat_overview',
    component: statisticsVatOverview,
    meta: {
      scope: [['stats']],

      menu: {
        submenu: 'statistics',
        item: '/statistics/vat_overview'
      }
    }
  },

  // Devices
  {
    path: '/hardware',
    redirect: '/hardware/overview'
  },
  {
    path: '/hardware/overview',
    name: 'hardwareOverview',
    component: hardwareOverview,
    meta: {
      scope: [['get_masters']]
    }
  },
  {
    path: '/hardware/create',
    name: 'hardwareCreate',
    component: hardwareCreate,
    meta: {
      scope: [['create_masters']],

      menu: {
        submenu: 'hardware',
        item: '/hardware/overview'
      }
    }
  },
  {
    path: '/hardware/view/:guid',
    name: 'hardwareView',
    component: hardwareView,
    meta: {
      scope: [['get_masters']], // Scope on update, because this is an update page at the moment

      menu: {
        submenu: 'hardware',
        item: '/hardware/overview'
      }
    }
  },

  // Settings
  {
    path: '/settings',
    name: '/settings',
    redirect: (to) => {
      // Redirect to workspace settings if in workspace store
      if (stores.activeStore && stores.activeStore.workspace_manager === true) {
        return '/settings/workspace';
      }
      return '/settings/store';
    }
  },

  // Personal settings
  {
    path: '/settings/personal',
    name: '/settings/personal',
    redirect: '/settings/personal/information',
    component: settingsPersonal,

    children: [{
      name: '/settings/personal/information',
      path: 'information',
      component: settingsPersonalInformation
    }, {
      name: '/settings/personal/authentication',
      path: 'authentication',
      component: settingsPersonalAuthentication
    }]
  },

  // Store settings
  {
    path: '/settings/store',
    name: '/settings/store',
    redirect: '/settings/store/general',
    component: settingsStore,

    children: [{
      name: '/settings/store/general',
      path: 'general',
      component: settingsStoreGeneral,
      meta: {
        scope: [['update_stores']],
        menu: {
          submenu: 'settings',
          item: '/settings'
        }
      }
    },
    {
      name: 'settingsCreditReasonsRouterView',
      path: 'credit-reasons',
      component: settingsCreditReasonsRouterView,
      redirect: '/settings/store/credit-reasons/overview',

      meta: {
        scope: [['update_stores']],
        menu: {
          submenu: 'settings',
          item: '/settings'
        }
      },

      // Children for the creditReasons
      children: [{
        name: 'settingsCreditReasonsOverview',
        path: 'overview',
        component: settingsCreditReasonsOverview
      }, {
        name: 'settingsCreditReasonsView',
        path: 'view/:guid',
        component: settingsCreditReasonsView
      }, {
        name: 'settingsCreditReasonsCreate',
        path: 'create',
        component: settingsCreditReasonsCreate
      }]
    },
    {
      name: '/settings/store/second-screens',
      path: 'second-screens',
      redirect: 'second-screens/overview'
    },
    {
      name: '/settings/store/second-screens/overview',
      path: 'second-screens/overview',
      component: settingsSecondScreensOverview
    },
    {
      name: '/settings/store/second-screens/view/:guid',
      path: 'second-screens/view/:guid',
      component: settingsSecondScreensView
    },
    {
      name: '/settings/store/second-screens/create',
      path: 'second-screens/create',
      component: settingsSecondScreensCreate
    }]
  },

  // Workspace settings
  {
    path: '/settings/workspace',
    name: '/settings/workspace',
    redirect: '/settings/workspace/integrations',
    component: settingsWorkspace,

    children: [{
      name: '/settings/workspace/integrations',
      path: 'integrations',
      component: settingsWorkspaceIntegrations,
      meta: {
        scope: [['workspace_manager']],
        menu: {
          submenu: 'settings',
          item: '/settings/workspace'
        }
      }
    }, {
      name: '/settings/workspace/integrations/mailchimp',
      path: 'integrations/mailchimp',
      component: settingsWorkspaceIntegrationsMailchimp,
      meta: {
        scope: [['workspace_manager']],
        menu: {
          submenu: 'settings',
          item: '/settings/workspace'
        }
      }
    }]
  },

  // Second screen
  {
    path: '/internal/second-screen',
    name: 'secondScreen',
    component: secondScreen,
    meta: {
      hide_layout: true
    }
  },

  // 404 Not Found
  {
    path: '*',
    name: 'PageNotFound',
    component: errorNotFound,
    meta: {
      scope: [['public']]
    }
  }]
});
