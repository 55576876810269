<template>
  <div>

    <div class="row form-group">
      <div class="col-12">
        <label>Naam <sup>Verplicht</sup></label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i><font-awesome-icon :icon="['fad', 'text']" /></i>
            </span>
          </div>
          <input
            ref="nameField"

            v-model.trim="$v.group.name.$model"
            :class="['form-control', {'is-invalid': $v.group.name.$error}]"
            :disabled="isDisabled"

            type="text"
          >
        </div>
      </div>
    </div>

  </div>
</template>

<script>
// Classes
import ProductGroup from '@/libs/classes/product_group';

// Validations
import { required } from 'vuelidate/lib/validators';

export default {
  props: {
    action: {
      type: String,
      default: 'CREATE'
    },

    group: {
      type: ProductGroup,
      default: null
    },

    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    isDisabled () {
      if (this.action === 'VIEW') {
        return true;
      }

      if (this.disabled === true) {
        return true;
      }

      return false;
    }
  },
  validations: {
    group: {
      name: {
        required
      }
    }
  },

  mounted () {
    this.$refs.nameField.focus();
  }
};
</script>
