<template>
  <div>
    <KSubheader
      :breadcrumbs="[{
        page: 'Hardware',
        route: '/hardware/overview'
      }, {
        page: 'Hardware koppelen',
        route: null
      }]"

      title="Hardware"
    />

    <KContainer>

      <KPortlet>
        <KPortletHead>
          Hardware koppelen
        </KPortletHead>
        <KPortletBody>

          <hardwareForm
            ref="hardwareForm"
            :hardware="hardware"

            :disabled="isLoading"
          />

        </KPortletBody>
        <div class="kt-portlet__foot">
          <div class="row align-items-center">
            <div class="offset-6 col-6 offset-lg-10 col-lg-2 kt-align-right">
              <KButton
                variant="primary"
                class="btn-md btn-block btn-tall btn-wide btn-bold btn-upper"
                @click.native.prevent="submitForm"
              >
                Koppelen
              </KButton>
            </div>
          </div>
        </div>
      </KPortlet>

    </KContainer>
  </div>
</template>

<script>
// Classes
import Master from '@/libs/classes/master';

// Components
import hardwareForm from './components/form';

export default {
  components: {
    hardwareForm
  },
  data () {
    return {
      isLoading: false,

      hardware: new Master()
    };
  },

  methods: {
    async validate () {
      const validationArray = [];
      validationArray.push(this.$refs.hardwareForm.$v);

      if (validationArray.length > 0) {
        validationArray.forEach(v => v && v.$touch && v.$touch());
        if (validationArray.filter(v => v && v.$invalid).length !== 0) return false;
      }

      return true;
    },
    async submitForm () {
      let valid = await this.validate();
      if (valid) {
        // Check if CSID starts with CSID or not
        let serial = this.hardware.serial;
        if (serial && serial.substr(0, 4) !== 'CSID') serial = `CSID${serial}`;

        this.$set(this, 'isLoading', true);

        // Connect the device
        try {
          await this.hardware.create(this.hardware.formatBody());
        } catch (e) {
          this.$error.showModal(e);
          this.$set(this, 'isLoading', false);
          return;
        }

        this.$router.push(`/hardware/view/${this.hardware.guid}`);
        this.$set(this, 'isLoading', false);
      }
    }
  }
};
</script>
