<template>
  <div v-if="group !== null && $data._group !== null">

    <KSubheader
      title="Groepen"
      :breadcrumbs="[{
        page: 'Apparatuur',
        route: '/equipment'
      }, {
        page: 'Groepen',
        route: '/equipment/groups/overview'
      }, {
        page: 'Groep wijzigen',
        route: null
      }]"
    >

    </KSubheader>

    <KContainer>

      <KPortlet>
        <KPortletHead>
          Groep wijzigen
        </KPortletHead>
        <KPortletBody>

          <equipmentGroupForm ref="equipmentGroupForm"
            :group="$data._group"
          ></equipmentGroupForm>

        </KPortletBody>
        <div class="kt-portlet__foot">
  				<div class="row align-items-center">
  					<div class="col-6 offset-lg-10 col-lg-2 kt-align-right">
              <KButton variant="warning" class="btn-md btn-block btn-tall btn-wide btn-bold btn-upper" @click.native.prevent="submitForm">Wijzigen</KButton>
  					</div>
  				</div>
  			</div>
      </KPortlet>

    </KContainer>

  </div>
</template>

<script>
// Libraries
import { cloneDeep } from 'lodash';
import mutationsLib from '@/libs/mutations';

// Components
import equipmentGroupForm from './components/form'

// Classes
import EquipmentGroup from '@/libs/classes/equipment_group';

export default {
  data() {
    return {
      group: null,
      _group: null
    }
  },
  components: {
    equipmentGroupForm
  },
  methods: {
    async validate() {
      const validationArray = [];

      validationArray.push(this.$refs.equipmentGroupForm.$v);

      if(validationArray.length > 0) {
        validationArray.forEach(v => v && v.$touch && v.$touch())
        if(validationArray.filter(v => v && v.$invalid).length !== 0) return false;
      }

      return true;
    },

    async submitForm() {
      if(await this.validate() === true) {

        const fields = ['name', 'pre_run_time', 'post_run_time', 'quickstart', 'door_contact', 'clean_after', 'package_only', 'accept_credits'];
        const mutations = mutationsLib.create(fields, this.group.formatBody(), this.$data._group.formatBody());

        if(mutations.length) {
          await this.group.update(mutations);
          this.$set(this.$data, '_group', cloneDeep(this.group));
        }
      }
    },

    async getEquipmentGroup(options = {}) {
      this.$set(this, 'group', null);
      this.$set(this.$data, '_group', null);

      let group = await new EquipmentGroup(this.$route.params.guid).get(options);
      await group.populate('get', options);

      this.$set(this, 'group', group);
      this.$set(this.$data, '_group', cloneDeep(group));
      return this.group;
    }
  },

  async mounted() {
    try {
      await this.getEquipmentGroup();
    } catch(e) {
      console.error('Group not found:', e);
    }
  }
}
</script>
