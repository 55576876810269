<template>
  <div v-if="isMounted && cash_register !== null">

    <KSubheader
      title="Kassa's"
      :breadcrumbs="[{
        page: 'Kassa',
        route: '/sales'
      }, {
        page: 'Kassa\'s',
        route: '/sales/cash_registers'
      }, {
        page: cash_register.name,
        route: null
      }, {
        page: action.breadcrumb,
        route: null
      }]"
    >

    </KSubheader>

    <KContainer>
      <KPortlet>
        <KPortletHead>
          <template v-slot:default>{{ action.title }}</template>
        </KPortletHead>
        <KPortletBody>

          <openRegisterForm ref="openRegisterForm"
            v-if="action.action === 'OPEN'"

            :cash_register="cash_register"
            :cash_register_opening="cash_register_opening"

            :meta="meta"
          ></openRegisterForm>

          <closeRegisterForm ref="closeRegisterForm"
            v-else-if="action.action === 'CLOSE'"

            :cash_register="cash_register"
            :cash_register_opening="cash_register_opening"

            :meta="meta"
          ></closeRegisterForm>

        </KPortletBody>
        <div class="kt-portlet__foot">
  				<div class="row align-items-center">
  					<div class="offset-6 col-6 offset-lg-10 col-lg-2 kt-align-right">
              <KButton variant="primary" class="btn-md btn-block btn-tall btn-wide btn-bold btn-upper" @click.native.prevent="submitForm">
                <span v-if="action.action === 'OPEN'">Open kassa</span>
                <span v-else-if="action.action === 'CLOSE'">Sluit kassa</span>
              </KButton>
  					</div>
  				</div>
  			</div>
      </KPortlet>
    </KContainer>

  </div>
</template>

<script>
// Libraries
import mutationsLib from '@/libs/mutations';

// Classes
import CashRegister from '@/libs/classes/cash_register';
import CashRegisterOpening from '@/libs/classes/cash_register.opening';

// Components
import openRegisterForm from './components/open.form';
import closeRegisterForm from './components/close.form';

export default {
  data() {
    return {
      isMounted: false,

      action: {
        action: null,
        title: null,
        breadcrumb: null
      },

      cash_register: null,
      cash_register_opening: new CashRegisterOpening(),

      meta: null
    }
  },
  components: {
    openRegisterForm,
    closeRegisterForm
  },

  async mounted() {
    try {
      await this.getCashRegister();
    } catch(e) {
      console.error('Failed fetching cash register', e);
    }

    // Check route
    if (this.cash_register.latest_opening.open === false) {
      if (this.$route.params.action !== 'open') {
        this.$router.replace({
          path: `/sales/cash_registers/view/${this.cash_register.guid}/actions/open`,
          query: this.$route.query
        });
      }

      this.cash_register_opening = new CashRegisterOpening();

      this.action.action = 'OPEN';
      this.action.title = 'Kassa openen';
      this.action.breadcrumb = 'Open';
    }

    if (this.cash_register.latest_opening.open === true) {
      if (this.$route.params.action !== 'close') {
        this.$router.push({
          path: `/sales/cash_registers/view/${this.cash_register.guid}/actions/close`,
          query: this.$route.query
        });
      }

      this.cash_register_opening = this.cash_register.latest_opening;

      this.action.action = 'CLOSE';
      this.action.title = 'Kassa sluiten';
      this.action.breadcrumb = 'Sluiten';

      this.meta = await this.cash_register.latest_opening.getExpectedCloseAmounts();
    }

    this.$nextTick(() => {
      this.isMounted = true;
    })
  },

  methods: {
    async getCashRegister (options = {}) {
      this.$set(this, 'cash_register', null);

      let cash_register = await new CashRegister(this.$route.params.guid).get(options);
      await cash_register.populate('get', options);

      this.$set(this, 'cash_register', cash_register);
      return this.cash_register;
    },

    async validate () {
      const validationArray = [];

      if (this.action.action === 'OPEN') validationArray.push(this.$refs.openRegisterForm.$v);
      else if (this.action.action === 'CLOSE') validationArray.push(this.$refs.closeRegisterForm.$v);

      // Execute validations
      if (validationArray.length > 0) {
        validationArray.forEach(v => v && v.$touch && v.$touch())
        if(validationArray.filter(v => v && v.$invalid).length !== 0) return false;
      }

      return true;
    },
    async submitForm() {
      if (await this.validate() === true) {

        if (this.action.action === 'OPEN') {
          this.cash_register_opening.cash_register = this.cash_register.guid;

          let body = this.cash_register_opening.formatBody('post', {
            action: 'OPEN'
          });

          let response = await this.cash_register_opening.create(body, {
            query: {
              persistent: true
            }
          });

          if (typeof this.$route.query.redirect === 'string') {
            if (this.$route.query.redirect === 'shop') {
              return this.$router.push({
                name: '/sales/shop/:cash_register',
                params: {
                  cash_register: this.cash_register.guid,
                  options: {
                    query: {
                      disable_cache: true
                    }
                  }
                }
              });
            } else if (typeof this.$route.query.redirect === 'string') {
              return this.$router.push({
                path: this.$route.query.redirect
              });
            }
          }
        } else if(this.action.action === 'CLOSE') {
          let body = this.cash_register_opening.formatBody('patch', {
            action: 'CLOSE'
          });

          const mutations = mutationsLib.create(Object.keys(body), {}, body);
          await this.cash_register_opening.update(mutations, {
            query: {
              persistent: true
            }
          })
        }

        return this.$router.push({
          name: `/sales/cash_registers/overview`,
          params: {
            options: {
              query: {
                disable_cache: true
              }
            }
          }
        });
      }
    }
  }
}
</script>
