<template>
  <div>
    <h3>Are you sure you want to logout?</h3><hr />
    <button v-on:click="confirmLogout">Yes</button>&nbsp;&nbsp;
    <button v-on:click="cancelLogout">No, go back</button>
  </div>
</template>

<script>
export default {
  data () {
    return {};
  },

  'methods': {
    confirmLogout () {
      this.$auth.logout();
      this.$router.push('/');
    },

    cancelLogout () {
      this.$router.go(-1);
    }
  },

  mounted () {
  }
};
</script>
