<template>
  <b-modal
    ref="modal"

    :no-close-on-esc="(options && options.required === true)"
    :no-close-on-backdrop="(options && options.required === true)"
    :hide-header-close="(options && options.required === true)"
    :hide-footer="($auth.hasPermission([['workspace_manager']]) === false && (options && options.required === true))"

    title="Selecteer een winkel"
    centered
    size="xl"
  >
    <template v-slot:default>
      <div
        v-if="isLoading === true"
        class="row mt-3"
      >
        <div class="col-12 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
          <div class="loading-container">
            <div class="loading-block">
              <div class="blockui ml-auto mr-auto w-100">
                <span>Gegevens ophalen...</span>
                <span class="kt-spinner kt-spinner--v2 kt-spinner--primary" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-else-if="stores && stores.length > 0"
        class="row mb-0"
      >
        <div
          v-for="store in stores"
          :key="`store.${store._meta.guid}`"
          class="col-12 col-lg-6"
        >
          <div
            class="kt-portlet cs-shop-category-block kt-portlet--height-fluid cursor-pointer shadow-25"
            @click.prevent="selectStore(store)"
          >
            <div class="kt-portlet__body">
              <div class="cs-shop-category-block--row">
                <div class="cs-shop-category-block--icon">
                  <i :class="[{'kt-font-success': activeStore && store._meta.guid === activeStore._meta.guid}]">
                    <font-awesome-icon :icon="['fad', 'store']" />
                  </i>
                </div>
                <div class="cs-shop-category-block--content">
                  <h5 class="category-title">{{ store.name }} <sup v-if="activeStore && store._meta.guid === activeStore._meta.guid">Huidige</sup></h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-else
        class="row mb-0"
      >
        <div class="col-12">
          <KAlert
            :icon="['fad', 'store']"
            variant="outline-info"
            class="mb-0"
          >
            <span>Er zijn geen winkels beschikbaar.</span>
            <span v-if="$auth.hasPermission('workspace_manager')">Gelieve een winkel aan te maken in het winkel overzicht op de workspace manager.</span>
          </KAlert>
        </div>
      </div>
    </template>

    <template v-slot:modal-footer>
      <!-- Workspace manager -->
      <KButton
        v-if="$auth.hasPermission([['workspace_manager']])"
        variant="focus"
        class="mr-auto"
        @click.native.prevent="workspaceManager"
      >
        Workspace Manager
      </KButton>

      <!-- Cancel -->
      <KButton
        v-if="options.required !== true"
        variant="secondary"
        @click.native.prevent="close"
      >
        Annuleren
      </KButton>
    </template>
  </b-modal>
</template>

<script>
// Libraries
import storesLib from '@/libs/stores';

// Classes
import Store from '@/libs/classes/store';

// Mixins
import Modal from '@/mixins/modal';

export default {
  mixins: [Modal],
  data () {
    return {
      isLoading: false,

      stores: [],
      activeStore: null,

      options: null
    };
  },

  async mounted () {
    this.$eventhub.on('modals:switchStore:open', this.open);
    this.$eventhub.on('modals:switchStore:close', this.close);
  },

  methods: {
    async open (options = {}) {
      // Set options to null
      this.$set(this, 'options', options);

      // Load stores and active store
      this.loadStores();
      this.activeStore = storesLib.activeStore;

      // Show modal
      Modal.methods.show.call(this);
    },

    close (event, element) {
      if (this.options.required !== true) {
        this.$refs.modal.hide();
      }
    },

    async selectStore (store) {
      // Set active store if changed
      if (store && (!this.activeStore || this.activeStore._meta.guid !== store._meta.guid)) {
        await storesLib.setActiveStore(store._meta.guid);
      }

      return this.$refs.modal.hide();
    },

    async workspaceManager () {
      await storesLib.setActiveStore('workspace_manager');
    },

    async loadStores () {
      this.$set(this, 'isLoading', true);

      const stores = await this.getStores();

      this.$set(this, 'isLoading', false);
      return stores;
    },
    async getStores () {
      this.$set(this, 'stores', []);

      let stores = await this.$ws.get('v1', '/stores/me');
      stores = stores.map(store => {
        return new Store().mergeResponse(store);
      }, []);

      this.$set(this, 'stores', stores);
      return this.stores;
    }
  }
};
</script>
