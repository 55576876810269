<template>
  <div>
    <div class="row form-group">
      <div class="col-12">
        <label>Naam <sup>Verplicht</sup></label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i><font-awesome-icon :icon="['fad', 'text']" /></i>
            </span>
          </div>
          <input
            ref="nameField"

            v-model.trim="$v.cashRegister.name.$model"
            :class="['form-control', {'is-invalid': $v.cashRegister.name.$error}]"
            :disabled="disabled"

            type="text"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Classes
import CashRegister from '@/libs/classes/cash_register';

// Validations
import { required } from 'vuelidate/lib/validators';

export default {
  props: {
    action: {
      type: String,
      default: 'CREATE'
    },

    cashRegister: {
      type: CashRegister,
      default: null
    },

    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: Boolean,
      default: true
    }
  },
  validations: {
    cashRegister: {
      name: {
        required
      }
    }
  }
};
</script>
