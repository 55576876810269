import Abstract from './abstract';

import { merge } from 'lodash';

class ProductPrice extends Abstract {
  constructor (object = {}) {
    super();

    let defaultObject = {
      price_excl_vat: null,
      pricelist: null,
      vat_code: null,
      vat_percentage: null,
      credit_price: null
    };

    merge(this, defaultObject, object);
  }

  // Methods
  formatBody (type = 'get', options = {}) {
    let body = super.formatBody(type, options);

    // Remove unnecessary options
    delete body.pricelist;
    delete body.vat_code;
    delete body.vat_percentage;
    delete body.price_incl_vat;

    if (options.contains_credit_price !== true) delete body.credit_price;

    return body;
  }
};

export default ProductPrice;
