import Abstract from './abstract';

import { merge } from 'lodash';

class ProductGroup extends Abstract {
  constructor (guid = null) {
    super(guid);
    this.uri = '/product_groups';

    let defaultObject = {
      name: null
    };

    merge(this, defaultObject);
  }
};

export default ProductGroup;
