<template>
  <div v-if="isMounted && store !== null">
    <KPortlet>
      <KPortletHead>
          <template v-slot:default>Retour redenen</template>

          <template v-slot:toolbar>
            <router-link v-if="$auth.hasPermission('create_customers') === true" to="create">
              <KButton tag="a" variant="outline-brand" icon="plus">Reden toevoegen</KButton>
            </router-link>
          </template>
        </KPortletHead>
      <KPortletBody>
        <div class="row">
          <div class="col-12 datatable datatable-wrapper">
              <datatable ref="datatable"
                :url="datatable.url"
                :fields="datatable.fields"
                :searchFields="datatable.searchFields"
                :onPaginationData="onPaginationData"
                :transform="transform"
              >
                <div class="datatable-actions" slot="actions" slot-scope="props">

                  <router-link v-if="$auth.hasPermission('update_credit_reasons')"
                    :to="`/settings/credit-reasons/view/${props.rowData.guid}`"
                  >
                    <KButton tag="a"
                      variant="label-brand"
                      :icon="['fad', 'edit']"
                    ></KButton>
                  </router-link>

                  <KButton v-if="$auth.hasPermission('remove_credit_reasons')" tag="a"
                    variant="label-brand"
                    :icon="['fad', 'trash']"
                    disabled
                  ></KButton>

                </div>
              </datatable>

              <div class="row pagination">
                <div class="col-xs-12 col-md-6">
                  <datatable-pagination ref="pagination"
                    v-on:vuetable-pagination:change-page="onChangePage"
                  ></datatable-pagination>
                </div>

                <div class="col-xs-12 col-md-6">
                  <datatable-pagination-info ref="pagination.info"
                    v-on:vuetable-paginationinfo:change-rowcount="onChangeRowCount"
                  ></datatable-pagination-info>
                </div>
              </div>
            </div>
        </div>
      </KPortletBody>
    </KPortlet>

  </div>
</template>

<script>
// Classes
import CreditReason from '@/libs/classes/credit_reasons';

// Mixins
import datatable from '@/mixins/datatable';

export default {
  mixins: [datatable],

  data() {
    return {
      isMounted: false,

      datatable: {
        url: '/credit_reasons',
        fields: [{
          title: 'Naam',
          name: 'name',
          sortField: 'name',
          dataClass: 'w-1/4'
        }, {
          title: 'Omschrijving',
          name: 'description',
          dataClass: 'w-1/4'
        }, {
          name: '__slot:actions',
          title: '',
          titleClass: 'text-right',
          dataClass: 'text-right'
        }],

        searchFields: ['name']
      }
    };
  },

  props: ['store'],

  components: {
  },

  async mounted() {
    this.$nextTick(() => {
      this.isMounted = true;
    })
  },
  methods: {
    transform (data) {
      if (data.data && Array.isArray(data.data)) {
        data.data.forEach((row, idx) => data.data[idx] = new CreditReason().mergeResponse(data.data[idx]));
      }

      return data;
    }
  }
}
</script>
