<template>
  <div>

    <div class="row form-group">
      <div :class="[{'col-12 col-lg-8': pricelist.type === 'products', 'col-8 col-lg-6': pricelist.type === 'session_products'}]">
        <label>Naam <sup>Verplicht</sup></label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i><font-awesome-icon :icon="['fad', 'text']" /></i>
            </span>
          </div>
          <input
            v-model.trim="$v.pricelist.name.$model"
            :class="[{'is-invalid': $v.pricelist.name.$error}]"
            :disabled="disabled"

            type="text"
            class="form-control"
          >
        </div>
      </div>

      <div
        v-if="pricelist.type === 'session_products'"
        class="col-4 col-lg-2"
      >
        <label>BTW <sup>Verplicht</sup></label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i><font-awesome-icon :icon="['fad', 'percentage']" /></i>
            </span>
          </div>
          <select
            v-model.trim="$v.pricelist.vat_code.$model"
            :class="[{'is-invalid': $v.pricelist.vat_code.$error}]"
            :disabled="disabled"

            class="form-control"
          >
            <option
              v-for="(VATCode) in VATCodes"
              :key="`vat_code.${VATCode.code}`"
              :value="VATCode.code"
            >
              {{ `${VATCode.value}%` }}
            </option>
          </select>
        </div>
      </div>

      <div class="col-12 mt-3 col-lg-2 mt-lg-0">
        <div class="row">
          <label class="d-none d-lg-block col-lg-12 text-md-center">{{ (pricelist.type === 'session_products' ? 'Tarievenlijst actief' : 'Prijslijst actief') }}</label>
          <div class="col-3 col-lg-12 text-md-center">
            <span :class="['kt-switch', {'kt-switch--success': (pricelist.active)}]">
              <label>
                <input
                  v-model="pricelist.active"
                  type="checkbox"
                >
                <span />
              </label>
            </span>
          </div>
          <label class="d-block d-lg-none col-9 text-md-center col-form-label">Prijslijst actief</label>
        </div>
      </div>

      <div class="col-12 col-lg-2">
        <div class="row">
          <label class="d-none d-lg-block col-lg-12 text-md-center">Aanbiedingsprijzen</label>
          <div class="col-3 col-lg-12 text-md-center">
            <span :class="['kt-switch', {'kt-switch--success': (pricelist.offer_list)}]">
              <label>
                <input
                  v-model="pricelist.offer_list"
                  type="checkbox"
                >
                <span />
              </label>
            </span>
          </div>
          <label class="d-block d-lg-none col-9 text-md-center col-form-label">Aanbiedingsprijzen</label>
        </div>
      </div>
    </div>

    <KSeparator large />

    <div class="row form-group">
      <div class="col-12 col-md-6">
        <label>Ingangsdatum</label>

        <div class="row">
          <div class="col-3 col-md-3 col-lg-2">
            <span :class="['kt-switch', {'kt-switch--success': start_timestamp_enabled}]">
              <label>
                <input
                  v-model="start_timestamp_enabled"
                  type="checkbox"
                >
                <span />
              </label>
            </span>
          </div>
          <div class="col-9 col-md-9 col-lg-10">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i><font-awesome-icon :icon="['fad', 'calendar']" /></i>
                </span>
              </div>

              <input
                v-if="start_timestamp_enabled === false"
                type="text"
                class="form-control"
                disabled
              >

              <template v-else>
                <datepicker
                  :format="moment($v.pricelist.start_timestamp_formatted.$model).format('DD-MM-YYYY')"
                  v-model.lazy="$v.pricelist.start_timestamp_formatted.$model"

                  class="d-none d-md-block"
                  bootstrap-styling
                  monday-first
                />

                <input
                  v-model.lazy="$v.pricelist.start_timestamp_formatted.$model"
                  :class="['d-block d-md-none form-control', {'is-invalid': $v.pricelist.start_timestamp_formatted.$error}]"
                  :disabled="(disabled || start_timestamp_enabled === false)"

                  type="date"
                >
              </template>

            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6">
        <label>Vervaldatum</label>

        <div class="row">
          <div class="col-3 col-md-3 col-lg-2">
            <span :class="['kt-switch', {'kt-switch--success': end_timestamp_enabled}]">
              <label>
                <input
                  v-model="end_timestamp_enabled"
                  type="checkbox"
                >
                <span />
              </label>
            </span>
          </div>
          <div class="col-9 col-md-9 col-lg-10">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i><font-awesome-icon :icon="['fad', 'calendar']" /></i>
                </span>
              </div>

              <input
                v-if="end_timestamp_enabled === false"

                type="text"
                class="form-control"
                disabled
              >

              <template v-else>
                <datepicker
                  v-model.lazy="$v.pricelist.end_timestamp_formatted.$model"
                  :format="moment($v.pricelist.end_timestamp_formatted.$model).format('DD-MM-YYYY')"

                  class="d-none d-md-block"
                  bootstrap-styling
                  monday-first
                />

                <input
                  v-model.lazy="$v.pricelist.end_timestamp_formatted.$model"
                  :class="[{'is-invalid': $v.pricelist.start_timestamp_formatted.$error}]"
                  :disabled="(disabled || start_timestamp_enabled === false)"

                  type="date"
                  class="d-block d-md-none form-control"
                >
              </template>

            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
// Libraries
import moment from 'moment-timezone';

// Classes
import Pricelist from '@/libs/classes/pricelist';

// Validations
import { required, requiredIf } from 'vuelidate/lib/validators';

// Components
import Datepicker from 'vuejs-datepicker';
import '@/assets/sass/datepicker/datepicker.scss';

export default {
  components: {
    Datepicker
  },
  props: {
    action: {
      type: String,
      default: 'CREATE'
    },

    pricelist: {
      type: Pricelist,
      default: null
    },

    VATCodes: {
      type: Array,
      default: null
    },

    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      start_timestamp_enabled: false,
      end_timestamp_enabled: false,

      moment
    };
  },
  watch: {
    start_timestamp_enabled (newVal, oldVal) {
      if (newVal === true) this.pricelist.start_timestamp = moment().tz('UTC').startOf('day').unix();
      else this.pricelist.start_timestamp = 0;
    },
    end_timestamp_enabled (newVal, oldVal) {
      if (newVal === true) this.pricelist.end_timestamp = moment().tz('UTC').endOf('day').unix();
      else this.pricelist.end_timestamp = 0;
    }
  },
  validations: {
    pricelist: {
      name: {
        required
      },
      start_timestamp_formatted: {
        required: requiredIf(function () {
          return this.start_timestamp_enabled === true;
        })
      },
      end_timestamp_formatted: {
        required: requiredIf(function () {
          return this.end_timestamp_enabled === true;
        })
      },
      vat_code: {
        required: requiredIf(function () {
          return this.pricelist.type === 'session_products';
        })
      }
    }
  }
};
</script>
