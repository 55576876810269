var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2"},[_c('breadcrumbs',{attrs:{"title":"Producten","breadcrumbs":[{
      title: 'Producten',
      route: null
    }]}}),_vm._v(" "),_c('div',{staticClass:"row mt-3"},_vm._l((_vm.groups),function(group,idx){return _c('div',{staticClass:"col-12 col-md-6"},[_c('category-block',{attrs:{"icon":['fad', 'tags']},nativeOn:{"click":function($event){$event.preventDefault();return _vm.navigateTo({
        name: "/sales/shop/:cash_register/cart/products/:group_guid/overview",
        params: {
          group_guid: group._meta.guid
        }
      })}}},[_c('h4',{staticClass:"category-title"},[_vm._v(_vm._s(group.name))])])],1)}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }