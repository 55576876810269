<template>
  <KPortlet class="cs-shop-product-line">
    <KPortletBody>
      <div class="cs-shop-product-line--row">
        <div class="cs-shop-product-line--icon">
          <i class="kt-font-brand"><font-awesome-icon :icon="icon" /></i>
        </div>
        <div class="cs-shop-category-block--content">
          <slot name="default" />
        </div>
        <div class="cs-shop-category-block--price">
          <h4>{{ (price && price !== null ? `&euro; ${$format.currency(price)}` : undefined ) }}</h4>
        </div>
      </div>
    </KPortletBody>
  </KPortlet>
</template>

<script>
export default {
  props: {
    icon: {
      type: [String, Array],
      default: () => ['fad', 'tag']
    },
    price: {
      type: Number,
      default: null
    }
  }
}
</script>
