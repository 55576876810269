<template>
  <b-modal
    v-keydown="{escape: close}"
    ref="modal"

    :no-close-on-esc="(isLoading === true)"
    :no-close-on-backdrop="(isLoading === true)"
    :hide-header-close="(isLoading === true)"

    :title="(options.title ? options.title : 'Weet je het zeker?')"
    centered
    size="lg"
  >
    <template v-slot:default>
      <!-- Optional text -->
      <span
        v-if="options.text"
        v-html="options.text"
      />

      <!-- Default text -->
      <span v-else>Weet je zeker dat je deze <strong>{{ resource.constructor.name }}</strong> wilt verwijderen?</span>
    </template>

    <template v-slot:modal-footer>
      <KButton
        :disabled="isLoading === true"

        variant="secondary"
        @click.native.prevent="close"
      >
        Annuleren
      </KButton>

      <KButton
        :disabled="isLoading === true"

        variant="danger"
        @click.native.prevent="submit"
      >
        Bevestigen
      </KButton>
    </template>
  </b-modal>
</template>

<script>
// Mixins
import Modal from '@/mixins/modal';

export default {
  mixins: [Modal],
  data () {
    return {
      isLoading: false,

      resource: null,
      options: {}
    };
  },

  mounted () {
    // Listeners
    this.$eventhub.on('modals:confirm:open', this.open);
    this.$eventhub.on('modals:confirm:close', this.close);
  },
  beforeDestroy () {
    // Remove listeners
    this.$eventhub.off('modals:confirm:open', this.open);
    this.$eventhub.off('modals:confirm:close', this.close);
  },

  methods: {
    async submit () {
      this.$set(this, 'isLoading', true);

      // Run the options submit
      if (this.options && typeof this.options.submit === 'function') {
        try {
          await this.options.submit();
        } catch (e) {
          console.error(e);
          this.$set(this, 'isLoading', false);
          return;
        }
      } else {
        console.warn('No submit method given');
      }

      this.$set(this, 'isLoading', false);
      return this.close();
    },

    open (options = {}) {
      this.$set(this, 'isLoading', false);
      this.$set(this, 'options', null);

      if (typeof options !== 'object') {
        throw new Error('Options not an object');
      }

      if (!options.resource) {
        throw new Error('Options.resource undefined');
      }

      // Set data
      this.$set(this, 'options', options);
      this.$set(this, 'resource', options.resource);

      this.$refs.modal.show();
    },
    close () {
      this.$refs.modal.hide();
    }
  }
};
</script>
