<template>
  <div class="cs-shop-aside">
    <div class="cs-shop-aside__toggle" />

    <button
      class="cs-shop-aside__mobile-nav-toggler"
      @click.prevent="toggleMobileAside"
    >
      <font-awesome-icon :icon="['fad', 'cash-register']" />
    </button>

    <div class="cs-shop-aside__content">
      <div class="cs-shop-aside__content-head">
        <div class="col-6 col-lg-8 mt-auto mb-auto">
          {{ cashRegister.name }}
        </div>

        <div class="col-6 col-lg-4">
          <KButton
            v-click="changeCashRegister"
            variant="focus"
            class="btn-bold btn-inherit text-center w-100 h-100"
          >
            Kassa's
          </KButton>
        </div>
      </div>
      <div class="cs-shop-aside__content-body">

        <div class="row">
          <div class="col-12">
            <customer
              ref="customer"
              :meta="meta"
              :sales-order="salesorder"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <cart
              ref="cart"
              :meta="meta"
              :salesorder="salesorder"
            />
          </div>
        </div>

        <div class="row">
          <buttons
            ref="buttons"
            :meta="meta"
            :sales-order="salesorder"
          />
        </div>

      </div>

    </div>

    <modal-identify-customer
      ref="modalIdentifyCustomer"
      :sales-order="salesorder"
    />

    <modal-shop-options
      ref="modalShopOptions"
    />

    <modal-stores-salesorders
      ref="modalStoresSalesorders"
    />

    <modal-edit-salesorder-line
      ref="modalEditSalesorderLine"
      :sales_order="salesorder"
    />

    <modal-payment-method
      ref="modalPaymentMethod"
      :salesorder="salesorder"
    />

    <modal-salesorder-settings
      ref="modalSalesorderSettings"
      :salesorder="salesorder"
    />

    <modal-salesorder-discount-overwrite
      ref="modalSalesorderDiscountOverwrite"
    />
  </div>
</template>

<style lang="scss">
@import "@/assets/sass/shop/aside.scss";
</style>

<script>
// CSS
import '@/assets/sass/shop/aside.scss';

// Mixins
import shop from '@/mixins/shop';

// Classes
import CashRegister from '@/libs/classes/cash_register';

// Components
import customer from './customer';
import cart from './cart/index';
import buttons from './buttons';

// Modals
import modalIdentifyCustomer from '@/components/sales/shop/components/modals/identify-customer/index';
import modalShopOptions from '@/components/sales/shop/components/modals/shop-options';
import modalStoresSalesorders from '@/components/sales/shop/components/modals/stored-salesorders';
import modalEditSalesorderLine from '@/components/sales/shop/components/modals/edit-salesorder-line';
import modalPaymentMethod from '@/components/sales/shop/components/modals/payment-method';
import modalSalesorderSettings from '@/components/sales/shop/components/modals/salesorder-settings';
import modalSalesorderDiscountOverwrite from '@/components/sales/shop/components/modals/salesorder-discount-overwrite';

const bodyClassList = ['cs-shop-aside--enabled', 'cs-shop-aside--expanded'];

export default {
  components: {
    customer,
    cart,
    buttons,

    modalIdentifyCustomer,
    modalShopOptions,
    modalStoresSalesorders,
    modalEditSalesorderLine,
    modalPaymentMethod,
    modalSalesorderSettings,
    modalSalesorderDiscountOverwrite
  },
  mixins: [shop],
  props: {
    meta: {
      type: Object,
      default: null
    },
    cashRegister: {
      type: CashRegister,
      default: null
    }
  },
  data () {
    return {
      salesorder: null
    };
  },

  mounted () {
    // Set sales order of shop
    this.$set(this, 'salesorder', this.shop.sales_order);

    // Listeners
    this.$eventhub.on('shop:cart:updated', this.onCartUpdated);

    // Apply body classes
    DOMTokenList.prototype.add.apply(document.body.classList, bodyClassList);
  },
  beforeDestroy () {
    // Remove listeners
    this.$eventhub.off('shop:cart:updated', this.onCartUpdated);

    // Remove body classes again
    DOMTokenList.prototype.remove.apply(document.body.classList, bodyClassList);
    this.toggleMobileAside(false);
  },

  methods: {
    onCartUpdated (salesorder) {
      // First set to null, so it can update properly
      this.$set(this, 'salesorder', null);

      // Set to real salesorder on next tick
      this.$nextTick(() => this.$set(this, 'salesorder', salesorder));
    },

    toggleMobileAside (force) {
      const navExpandedClass = 'cs-shop-aside--mobile-nav-expanded';

      let expand = document.body.classList.contains(navExpandedClass) === false;
      if (typeof force === 'boolean') expand = force;

      if (expand === true) document.body.classList.add(navExpandedClass);
      else document.body.classList.remove(navExpandedClass);
    },

    changeCashRegister () {
      return this.$router.push('/sales/cash_registers/overview');
    }
  }
};
</script>
