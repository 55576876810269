<template>
  <b-modal
    ref="modal"
    size="xl"
    centered
  >
    <template v-slot:modal-header>
      <h5 class="modal-title">
        {{ equipment.name }}
        <span style="font-weight: 300; font-size: 1.1rem;">{{ equipment.room }}</span>
      </h5>
      <button
        type="button"
        aria-label="Close"
        class="close"
        @click.prevent="hide"
      >
        ×
      </button>
    </template>

    <template v-slot:default>

      <!-- Check if sales order is defined -->
      <template v-if="sales_order === null">
        <div class="row">
          <div class="col-12 text-center">
            <div class="blockui ml-auto mr-auto">
              <span>Gegevens ophalen...</span>
              <span class="kt-spinner kt-spinner--v2 kt-spinner--primary" />
            </div>
          </div>
        </div>
      </template>

      <!-- Check if session can be configured -->
      <template v-else-if="canConfigureSession === false">
        <div class="row">
          <div class="col-12">
            <div class="blockui ml-auto mr-auto">
              <span>Wachten op verbinding met apparaat...</span>
              <span class="kt-spinner kt-spinner--v2 kt-spinner--focus" />
            </div>
          </div>
        </div>
      </template>

      <!-- All is right, so continue -->
      <template v-else>
        <div class="row border-bottom pb-3">
          <!-- Status equipment -->
          <div class="col-12 pb-3 col-lg-8 pb-lg-0">
            <equipment-row
              ref="equipmentRow"
              :equipment="equipment"
            />
          </div>

          <!-- Customer in session -->
          <div class="col-12 pt-3 col-lg-4 pt-lg-0 mb-auto mt-auto">
            <customer-row
              v-if="equipment && equipment.inSession === true"
              ref="customerRow"

              :equipment="equipment"
            />
          </div>
        </div>

        <!-- Sales order customer -->
        <div
          v-if="isCustomerKnown === true"
          class="row border-bottom mt-3 pb-3"
        >
          <div class="col-12">
            <salesorder-customer-row
              v-if="isCustomerKnown === true"
              ref="salesorderCustomerRow"

              :equipment="equipment"
              :sales_order="sales_order"
            />
          </div>
        </div>

        <!-- Authentication options row -->
        <authentication-options-row
          v-if="currentConfigureStep.step === 'SELECT_CUSTOMER' || currentConfigureStep.step === 'AUTHENTICATE'"
          ref="authenticationOptionsRow"
        />

        <!-- Step -->
        <div class="row mt-3">
          <div class="col-12">
            <template v-if="currentConfigureStep">
              <!-- Select customer -->
              <template v-if="currentConfigureStep.step === 'SELECT_CUSTOMER'">
                <search-customer-step
                  ref="searchCustomerStep"
                />
              </template>

              <!-- Authentication of customer -->
              <template v-if="currentConfigureStep.step === 'AUTHENTICATE'">
                <authentication-step
                  ref="authenticationStep"
                  :sales_order="sales_order"
                />
              </template>

              <!-- Configure session -->
              <template v-else-if="currentConfigureStep.step === 'CONFIGURE_SESSION'">
                <configure-session-step
                  ref="configureSessionStep"
                  :equipment="equipment"
                  :sales_order="sales_order"
                  :session="session"
                />
              </template>
            </template>

            <template v-else>
              <KAlert
                :icon="['fad', 'exclamation-circle']"
                variant="outline-danger"
              >
                Er is iets misgegaan, probeer het opnieuw.
              </KAlert>
            </template>
          </div>
        </div>
      </template>

    </template>

    <template v-slot:modal-footer>
      <KButton
        variant="secondary"
        @click.native.prevent="hide"
      >
        Annuleren
      </KButton>

      <KButton
        v-if="showPayButton"

        :disabled="($refs['configureSessionStep'] && $refs['configureSessionStep'].isLoading === true)"

        variant="info"
        @click.native.prevent="addSessionToSalesorder"
      >
        Afrekenen
      </KButton>
    </template>
  </b-modal>
</template>

<script>
// Libraries
import shop from '@/libs/shop';

// Classes
import Equipment from '@/libs/classes/equipment';
import SalesOrder from '@/libs/classes/salesorder';
import Customer from '@/libs/classes/customer';

// Mixins
import Modal from '@/mixins/modal';

// Components
import equipmentRow from '@/components/equipment/modals/components/equipment.row';
import customerRow from '@/components/equipment/modals/components/customer.row';

import salesorderCustomerRow from '@/components/equipment/modals/configure-session/components/salesorder-customer.row';
import authenticationOptionsRow from '@/components/sales/shop/components/modals/identify-customer/components/authentication-options.row';

import searchCustomerStep from '@/components/equipment/modals/configure-session/components/search-customer.step';
import authenticationStep from '@/components/sales/shop/components/modals/identify-customer/components/authentication.row';
import configureSessionStep from '@/components/equipment/modals/configure-session/components/configure-session.step';

export default {
  components: {
    // Rows
    equipmentRow,
    customerRow,
    salesorderCustomerRow,
    authenticationOptionsRow,

    // Steps
    searchCustomerStep,
    authenticationStep,
    configureSessionStep
  },
  mixins: [Modal],
  data () {
    return {
      options: null,

      equipment: null,
      sales_order: null,

      session: {
        payment_method: null,
        name: null,
        guid: null,

        // Bundle specific
        bundle_guid: null,
        bundle_session_guid: null,
        session_duration: null
      }
    };
  },
  computed: {
    canConfigureSession () {
      if (!this.equipment) return false;
      if (!this.equipment.master || this.equipment.master.isConnected === false) return false;
      if (!this.equipment.communication) return false;
      if (!this.equipment.state === 'timeout') return false;

      return true;
    },

    isCustomerKnown () {
      if (this.sales_order && this.sales_order !== null && this.sales_order instanceof SalesOrder) {
        if (this.sales_order.customer && this.sales_order.customer !== null && this.sales_order.customer instanceof Customer) {
          return true;
        }
      }

      return false;
    },

    currentConfigureStep () {
      // Check if is customer known
      if (this.isCustomerKnown === false) {
        return {
          step: 'SELECT_CUSTOMER'
        };
      }

      // Check if user is authenticated
      if (this.sales_order.customer.authenticated !== true) {
        return {
          step: 'AUTHENTICATE'
        };
      }

      // Configure session
      return {
        step: 'CONFIGURE_SESSION'
      };
    },

    showPayButton () {
      if (this.canConfigureSession === true && this.currentConfigureStep && this.currentConfigureStep.step === 'CONFIGURE_SESSION') {
        if (this.session) {
          // Payment method SINGLE_SESSION or SESSION_CREDIT
          if (this.session.payment_method === 'SINGLE_SESSION' || this.session.payment_method === 'SESSION_CREDIT') {
            if (typeof this.session.guid === 'string' && this.session.guid.length > 0) {
              return true;
            }
          }

          // Payment method PACKAGE
          if (this.session.payment_method === 'PACKAGE') {
            if (this.session.bundle_guid && this.session.bundle_session_guid && this.session.session_duration) {
              return true;
            }
          }
        }
      }
      return false;
    }
  },

  mounted () {
    // Listeners
    this.$eventhub.on('modals:equipment:configure-session:open', this.open);
    this.$eventhub.on('modals:equipment:configure-session:close', this.hide);

    this.$eventhub.off('shop:cart:updated', this.onCartUpdated);
  },
  beforeDestroy () {
    // Remove listeners
    this.$eventhub.off('modals:equipment:configure-session:open', this.open);
    this.$eventhub.off('modals:equipment:configure-session:close', this.hide);

    this.$eventhub.off('shop:cart:updated', this.onCartUpdated);
  },

  methods: {
    onCartUpdated (salesorder) {
      // First set to null, so it can update properly
      this.$set(this, 'sales_order', null);

      // Set to real sales_order on next tick
      this.$nextTick(() => this.$set(this, 'sales_order', salesorder));
    },

    async open (options = {}) {
      this.$set(this, 'options', options);

      if (typeof this.options === 'object') {
        if (this.options.equipment && this.options.equipment instanceof Equipment) {
          this.$set(this, 'equipment', this.options.equipment);

          // Get sales order
          this.getSalesOrder();

          // Show modal
          Modal.methods.show.call(this);
        }
      }
    },

    async getSalesOrder (options = {}) {
      this.$set(this, 'sales_order', null);

      // Check if cash register is found
      let cashRegister = await shop.getCashRegister();
      if (!cashRegister) {
        shop.removeSession();
        return this.$router.push({
          name: '/sales/cash_registers/overview',
          params: {
            redirect: `/dashboard?equipment.session=${this.equipment.guid}`
          }
        });
      }

      // Check if cash register is open
      if (cashRegister.latest_opening.open === false) {
        return this.$router.replace({
          path: `/sales/cash_registers/view/${cashRegister.guid}/actions`,
          query: {
            redirect: `/dashboard?equipment.session=${this.equipment.guid}`
          }
        });
      }

      // Get sales order
      let salesorder = await shop.getSalesOrder();

      // Check if sales order paid, so we can create a new one
      if (salesorder.paid === true) {
        shop.removeSalesOrder();
        salesorder = await shop.createSalesOrder();
      }

      this.$set(this, 'sales_order', salesorder);
      return salesorder;
    },

    async addSessionToSalesorder (options = {}) {
      if (this.showPayButton === true && this.session.payment_method) {
        // Set loading of configure-session-step
        if (this.$refs['configureSessionStep']) this.$refs['configureSessionStep'].isLoading = true;

        // Create product object
        const product = {
          type: 'session',
          session_name: this.session.name,
          equipment_guid: this.equipment.guid,
          quantity: 1
        };

        // Add details based on payment method
        // Payment method SINGLE_SESSION or SESSION_CREDIT
        if (this.session.payment_method === 'SINGLE_SESSION' || this.session.payment_method === 'SESSION_CREDIT') {
          // Add session guid
          product.session_guid = this.session.guid;

          // Set payment type to credit when payment_method === 'SESSION_CREDIT'
          if (this.session.payment_method === 'SESSION_CREDIT') {
            product.payment_type = 'credit';
          }
        }

        // Payment method PACKAGE
        if (this.session.payment_method === 'PACKAGE') {
          // Add customer guid, bundle guid, bundle session guid and session duration
          product.customer_guid = this.sales_order.customer.guid;
          product.bundle_guid = this.session.bundle_guid;
          product.bundle_session_guid = this.session.bundle_session_guid;
          product.session_duration = this.session.session_duration;

          // Add payment type
          product.payment_type = 'bundle';
        }

        // Add session to cart
        let line = null;
        try {
          line = await shop.addProductToCart(product);
        } catch (e) {
          if (this.$refs['configureSessionStep']) this.$refs['configureSessionStep'].isLoading = false;

          console.error(e);
          this.$error.showModal(e);

          return;
        }

        this.hide();

        // Redirect to credit products when customer has insufficient session credit balance
        if (this.isCustomerKnown === true && this.sales_order.customer && this.sales_order.customer.balance) {
          if (line && line.payment_type === 'credit' && line.total_price_incl > this.sales_order.customer.balance.session_credits) {
            return this.$router.push(`/sales/shop/${shop.cash_register.guid}/cart/credit/overview`);
          }
        }

        return this.$router.push(`/sales/shop/${shop.cash_register.guid}/cart/overview`);
      }
    }
  }
};
</script>
