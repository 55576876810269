<template>
  <KPortlet v-if="isMounted">
    <KPortletHead>
      <template v-slot:default>Instellingen</template>
    </KPortletHead>
    <KPortletBody>

      <informationForm ref="informationForm"
        action="EDIT"

        :pricelist="$data._pricelist"

        :VATCodes="meta.VATCodes"
      ></informationForm>

    </KPortletBody>
    <div class="kt-portlet__foot">
      <div class="row align-items-center">
        <div class="col-6 offset-lg-10 col-lg-2 kt-align-right">
          <KButton variant="warning" class="btn-md btn-block btn-tall btn-wide btn-bold btn-upper" @click.native.prevent="submitForm">Wijzigen</KButton>
        </div>
      </div>
    </div>
  </KPortlet>
</template>

<script>
// Libraries
import { cloneDeep } from 'lodash';
import mutationsLib from '@/libs/mutations';

// Classes
import SessionPricelist from '@/libs/classes/session_pricelist';

// Components
import informationForm from '@/components/products/pricelists/components/information.form';

export default {
  props: {
    pricelist: SessionPricelist,

    meta: Object
  },
  data() {
    return {
      isMounted: false,

      _pricelist: cloneDeep(this.pricelist),
    }
  },
  components: {
    informationForm
  },
  async mounted() {
    this.$nextTick(() => {
      this.isMounted = true;
    })
  },
  methods: {
    async validate() {
      const validationArray = [];
      validationArray.push(this.$refs.informationForm.$v);

      if(validationArray.length > 0) {
        validationArray.forEach(v => v && v.$touch && v.$touch())
        if(validationArray.filter(v => v && v.$invalid).length !== 0) return false;
      }

      return true;
    },
    async submitForm() {
      if(await this.validate() === true) {
        const fields = ['name', 'vat_code', 'active', 'offer_list', 'start_timestamp', 'end_timestamp'];
        const mutations = mutationsLib.create(fields, this.pricelist.formatBody(), this.$data._pricelist.formatBody());

        if(mutations.length) {
          await this.pricelist.update(mutations);
          await this.pricelist.populate('update');
          this.$data._pricelist = cloneDeep(this.pricelist);
        }

      }
    }
  }
}
</script>
