var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('KAsideMenu',[_c('router-link',{attrs:{"to":"/dashboard"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('KAsideMenuItem',{attrs:{"icon":['fad', 'home'],"route":href,"active":_vm.isItemActive(route)}},[_vm._v("\n      Dashboard\n    ")])]}}])}),_vm._v(" "),(_vm.$auth.hasPermission([['shop']]))?_c('KAsideMenuSubmenu',{ref:"submenu.sales",attrs:{"title":'Kassa',"icon":['fad', 'shopping-cart'],"active":_vm.isMenuActive('sales')}},[_c('router-link',{attrs:{"to":"/sales/shop"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('KAsideMenuItem',{attrs:{"route":href,"active":_vm.isItemActive(route),"bullet":""}},[_vm._v("\n        Verkopen\n      ")])]}}],null,false,2931298323)}),_vm._v(" "),_c('router-link',{attrs:{"to":"/sales/salesorders"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('KAsideMenuItem',{attrs:{"route":href,"active":_vm.isItemActive(route),"bullet":""}},[_vm._v("\n        Transacties\n      ")])]}}],null,false,3833510558)})],1):_vm._e(),_vm._v(" "),(_vm.$auth.hasPermission([['get_customers']]))?_c('router-link',{attrs:{"to":"/customers/overview"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('KAsideMenuItem',{attrs:{"icon":['fad', 'users'],"route":href,"active":_vm.isItemActive(route)}},[_vm._v("\n      Klanten\n    ")])]}}],null,false,1731672284)}):_vm._e(),_vm._v(" "),(_vm.$auth.hasPermission([['get_products']]))?_c('KAsideMenuSubmenu',{ref:"submenu.products",attrs:{"title":'Producten',"icon":['fad', 'tags'],"active":_vm.isMenuActive('products')}},[(_vm.$auth.hasPermission([['get_products']]))?_c('router-link',{attrs:{"to":"/products/overview"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('KAsideMenuItem',{attrs:{"route":href,"active":_vm.isItemActive(route),"bullet":""}},[_vm._v("\n        Overzicht\n      ")])]}}],null,false,1285086447)}):_vm._e(),_vm._v(" "),(_vm.$auth.hasPermission([['get_pricelists']]))?_c('router-link',{attrs:{"to":"/products/pricelists/overview"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('KAsideMenuItem',{attrs:{"route":href,"active":_vm.isItemActive(route),"bullet":""}},[_vm._v("\n        Prijslijsten\n      ")])]}}],null,false,3913772892)}):_vm._e(),_vm._v(" "),(_vm.$auth.hasPermission([['get_product_groups']]))?_c('router-link',{attrs:{"to":"/products/groups/overview"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('KAsideMenuItem',{attrs:{"route":href,"active":_vm.isItemActive(route),"bullet":""}},[_vm._v("\n        Groepen\n      ")])]}}],null,false,3645729417)}):_vm._e(),_vm._v(" "),(_vm.$auth.hasPermission([['get_credit_products']]))?_c('router-link',{attrs:{"to":"/products/credit/overview"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('KAsideMenuItem',{attrs:{"route":href,"active":_vm.isItemActive(route),"bullet":""}},[_vm._v("\n        Sessiekrediet\n      ")])]}}],null,false,1407664343)}):_vm._e(),_vm._v(" "),(_vm.$auth.hasPermission([['get_stock_mutations']]))?_c('KAsideMenuSubmenu',{ref:"submenu.stock",attrs:{"title":'Voorraadbeheer',"active":_vm.isMenuActive('stock'),"bullet":""}},[_c('router-link',{attrs:{"to":"/products/stock/count/overview"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('KAsideMenuItem',{attrs:{"route":href,"active":_vm.isItemActive(route),"bullet":""}},[_vm._v("\n          Inventarisatie\n        ")])]}}],null,false,3675713587)}),_vm._v(" "),_c('router-link',{attrs:{"to":"/products/stock/mutations/overview"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('KAsideMenuItem',{attrs:{"route":href,"active":_vm.isItemActive(route),"bullet":""}},[_vm._v("\n          Mutaties\n        ")])]}}],null,false,724569867)})],1):_vm._e()],1):_vm._e(),_vm._v(" "),(_vm.$auth.hasPermission([['get_bundle_products']]))?_c('KAsideMenuSubmenu',{ref:"submenu.bundles",attrs:{"title":'Pakketten',"icon":['fad', 'boxes'],"active":_vm.isMenuActive('bundles')}},[(_vm.$auth.hasPermission([['get_bundle_products']]))?_c('router-link',{attrs:{"to":"/bundles/overview"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('KAsideMenuItem',{attrs:{"route":href,"active":_vm.isItemActive(route),"bullet":""}},[_vm._v("\n        Bundels\n      ")])]}}],null,false,3045184618)}):_vm._e(),_vm._v(" "),(_vm.$auth.hasPermission([['get_bundle_products']]))?_c('router-link',{attrs:{"to":"/bundles/subscriptions/overview"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('KAsideMenuItem',{attrs:{"route":href,"active":_vm.isItemActive(route),"bullet":""}},[_vm._v("\n        Abonnementen\n      ")])]}}],null,false,4012491421)}):_vm._e(),_vm._v(" "),(_vm.$auth.hasPermission([['get_bundle_products']]))?_c('router-link',{attrs:{"to":"/bundles/treatments/overview"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('KAsideMenuItem',{attrs:{"route":href,"active":_vm.isItemActive(route),"bullet":""}},[_vm._v("\n        Behandelingen\n      ")])]}}],null,false,3520117227)}):_vm._e(),_vm._v(" "),(_vm.$auth.hasPermission([['get_bundle_products']]))?_c('router-link',{attrs:{"to":"/bundles/product/overview"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('KAsideMenuItem',{attrs:{"route":href,"active":_vm.isItemActive(route),"bullet":""}},[_vm._v("\n        Product bundels\n      ")])]}}],null,false,1605202305)}):_vm._e()],1):_vm._e(),_vm._v(" "),(_vm.$auth.hasPermission([['get_equipments', 'get_session_products', 'get_pricelists', 'get_equipment_groups']]))?_c('KAsideMenuSubmenu',{ref:"submenu.equipment",attrs:{"title":'Apparatuur',"icon":['fad', 'link'],"active":_vm.isMenuActive('equipment')}},[(_vm.$auth.hasPermission([['get_equipments']]))?_c('router-link',{attrs:{"to":"/equipment/overview"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('KAsideMenuItem',{attrs:{"route":href,"active":_vm.isItemActive(route),"bullet":""}},[_vm._v("\n        Overzicht\n      ")])]}}],null,false,1285086447)}):_vm._e(),_vm._v(" "),(_vm.$auth.hasPermission([['get_session_products']]))?_c('router-link',{attrs:{"to":"/equipment/sessions/overview"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('KAsideMenuItem',{attrs:{"route":href,"active":_vm.isItemActive(route),"bullet":""}},[_vm._v("\n        Sessies\n      ")])]}}],null,false,2912008612)}):_vm._e(),_vm._v(" "),(_vm.$auth.hasPermission([['get_pricelists']]))?_c('router-link',{attrs:{"to":"/equipment/pricelists/overview"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('KAsideMenuItem',{attrs:{"route":href,"active":_vm.isItemActive(route),"bullet":""}},[_vm._v("\n        Tarieven\n      ")])]}}],null,false,3952095387)}):_vm._e(),_vm._v(" "),(_vm.$auth.hasPermission([['get_equipment_groups']]))?_c('router-link',{attrs:{"to":"/equipment/groups/overview"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('KAsideMenuItem',{attrs:{"route":href,"active":_vm.isItemActive(route),"bullet":""}},[_vm._v("\n        Groepen\n      ")])]}}],null,false,3645729417)}):_vm._e()],1):_vm._e(),_vm._v(" "),_c('KAsideMenuSection',{attrs:{"icon":undefined}},[_vm._v("Winkel")]),_vm._v(" "),(_vm.$auth.hasPermission([['stats']]))?_c('KAsideMenuSubmenu',{ref:"submenu.statistics",attrs:{"title":'Statistieken',"icon":['fad', 'analytics'],"active":_vm.isMenuActive('statistics')}},[(_vm.$auth.hasPermission([['stats']]))?_c('router-link',{attrs:{"to":"/statistics/totals"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('KAsideMenuItem',{attrs:{"route":href,"active":_vm.isItemActive(route),"bullet":""}},[_vm._v("\n        Totalen\n      ")])]}}],null,false,1516525348)}):_vm._e(),_vm._v(" "),(_vm.$auth.hasPermission([['stats']]))?_c('router-link',{attrs:{"to":"/statistics/daily"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('KAsideMenuItem',{attrs:{"route":href,"active":_vm.isItemActive(route),"bullet":""}},[_vm._v("\n        Dagelijks\n      ")])]}}],null,false,1543426269)}):_vm._e(),_vm._v(" "),(_vm.$auth.hasPermission([['stats']]))?_c('router-link',{attrs:{"to":"/statistics/monthly"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('KAsideMenuItem',{attrs:{"route":href,"active":_vm.isItemActive(route),"bullet":""}},[_vm._v("\n        Maandelijks\n      ")])]}}],null,false,217951832)}):_vm._e(),_vm._v(" "),(_vm.$auth.hasPermission([['stats']]))?_c('router-link',{attrs:{"to":"/statistics/users"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('KAsideMenuItem',{attrs:{"route":href,"active":_vm.isItemActive(route),"bullet":""}},[_vm._v("\n        Gebruikers\n      ")])]}}],null,false,3358987660)}):_vm._e(),_vm._v(" "),(_vm.$auth.hasPermission([['stats']]))?_c('router-link',{attrs:{"to":"/statistics/vat_overview"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('KAsideMenuItem',{attrs:{"route":href,"active":_vm.isItemActive(route),"bullet":""}},[_vm._v("\n        BTW overzicht\n      ")])]}}],null,false,1785925614)}):_vm._e()],1):_vm._e(),_vm._v(" "),(_vm.$auth.hasPermission([['get_users']]))?_c('router-link',{attrs:{"to":"/users"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('KAsideMenuItem',{attrs:{"route":href,"active":_vm.isItemActive(route),"icon":['fad', 'users']}},[_vm._v("\n      Gebruikers\n    ")])]}}],null,false,4044206186)}):_vm._e(),_vm._v(" "),(_vm.$auth.hasPermission(['get_cash_registers', 'get_salesorders', 'get_cash_register_openings']))?_c('KAsideMenuSubmenu',{ref:"submenu.cash_registers",attrs:{"title":'Kassabeheer',"icon":['fad', 'cash-register'],"active":_vm.isMenuActive('cash_registers')}},[(_vm.$auth.hasPermission([['get_cash_registers']]))?_c('router-link',{attrs:{"to":"/cash_registers/overview"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('KAsideMenuItem',{attrs:{"route":href,"active":_vm.isItemActive(route),"bullet":""}},[_vm._v("\n        Overzicht\n      ")])]}}],null,false,1285086447)}):_vm._e(),_vm._v(" "),(_vm.$auth.hasPermission([['get_salesorders']]))?_c('router-link',{attrs:{"to":"/cash_registers/salesorders/overview"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('KAsideMenuItem',{attrs:{"route":href,"active":_vm.isItemActive(route),"bullet":""}},[_vm._v("\n        Transacties\n      ")])]}}],null,false,3833510558)}):_vm._e(),_vm._v(" "),(_vm.$auth.hasPermission([['get_cash_register_openings']]))?_c('router-link',{attrs:{"to":"/cash_registers/closures/overview"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('KAsideMenuItem',{attrs:{"route":href,"active":_vm.isItemActive(route),"bullet":""}},[_vm._v("\n        Afsluitingen\n      ")])]}}],null,false,1384844598)}):_vm._e()],1):_vm._e(),_vm._v(" "),(_vm.$auth.hasPermission([['get_masters']]))?_c('router-link',{attrs:{"to":"/hardware/overview"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('KAsideMenuItem',{attrs:{"route":href,"active":_vm.isItemActive(route),"icon":['fad', 'microchip']}},[_vm._v("\n      Hardware\n    ")])]}}],null,false,2058409695)}):_vm._e(),_vm._v(" "),(_vm.$auth.hasPermission([['update_stores']]))?_c('router-link',{attrs:{"to":"/settings/store"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('KAsideMenuItem',{attrs:{"icon":['fad', 'cogs'],"route":href,"active":isActive}},[_vm._v("\n      Instellingen\n    ")])]}}],null,false,2812504114)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }