<template>
  <div>

    <template v-if="equipment && equipment.length > 0">
      <div
        v-for="(row) in equipment"
        :key="`equipment.${row.guid}`"
        class="row form-group"
      >
        <div class="col-9 col-form-label">{{ row.name }}</div>
        <div class="col-3 text-right">
          <span :class="['kt-switch', {'kt-switch--success': isDeviceActive(row)}]">
            <label>
              <input
                :checked="isDeviceActive(row)"

                type="checkbox"

                @change="toggleDevice(row, $event)"
              >
              <span />
            </label>
          </span>
        </div>
      </div>
    </template>

    <KAlert
      v-else
      :icon="['fad', 'link']"
      variant="outline-info"
    >
      Geen apparatuur gevonden
    </KAlert>
  </div>
</template>

<script>
// Classes
import SessionPricelist from '@/libs/classes/session_pricelist';

export default {
  props: {
    action: {
      type: String,
      default: 'CREATE'
    },

    pricelist: {
      type: SessionPricelist,
      default: null
    },

    equipment: {
      type: Array,
      default: null
    },

    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    toggleDevice (equipment, event) {
      if (event.target.checked === true) {
        if (this.pricelist.equipment_guids.indexOf(equipment.guid) === -1) {
          this.pricelist.equipment_guids.push(equipment.guid);
        }
      } else {
        this.pricelist.equipment_guids = this.pricelist.equipment_guids.filter(guid => guid !== equipment.guid);
      }
    },
    isDeviceActive (equipment) {
      return (this.pricelist && this.pricelist.equipment_guids && Array.isArray(this.pricelist.equipment_guids) && this.pricelist.equipment_guids.indexOf(equipment.guid) !== -1);
    },

    async validate () {
      const validationArray = [];

      // Execute validations
      if (validationArray.length > 0) {
        validationArray.forEach(v => v && v.$touch && v.$touch());
        if (validationArray.filter(v => v && v.$invalid).length !== 0) return false;
      }

      return true;
    }
  }
};
</script>
