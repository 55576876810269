<template>
  <KModal
    v-keydown="{'escape': close }"
    ref="modal"
    title="Cadeaubon uitgeven"
    centered
    size="lg"
  >
    <template v-slot:default>
      <div class="row form-group mb-0">
        <div class="col-12 col-lg-8">
          <label>Cadeaubon code <sup>Verplicht</sup></label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i><font-awesome-icon :icon="['fad', 'gift-card']" /></i>
              </span>
            </div>
            <input
              ref="giftcardCodeInput"

              :class="['form-control', {'is-invalid': $v.form.giftcard.code.$error}]"
              v-model.trim="$v.form.giftcard.code.$model"

              type="text"
              @change="resetCustomErrors"
            >
          </div>
        </div>

        <div class="col-12 col-lg-4">
          <label>Waarde <sup>Verplicht</sup></label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i><font-awesome-icon :icon="['fad', 'euro-sign']" /></i>
              </span>
            </div>
            <input
              :class="['form-control', {'is-invalid': $v.value_formatted.$error}]"
              v-model.number.lazy="$v.value_formatted.$model"

              type="number"

              @change="resetCustomErrors"
            >
          </div>
        </div>
      </div>
    </template>

    <template v-slot:footer>
      <KButton
        variant="secondary"
        @click.native.prevent="close"
      >
        Annuleren
      </KButton>

      <KButton
        variant="primary"
        @click.native.prevent="addGiftcardToCart"
      >
        Toevoegen
      </KButton>
    </template>
  </KModal>
</template>

<script>
// Libraries
import shop from '@/libs/shop';
import { required } from 'vuelidate/lib/validators';
import { currency } from '@/libs/validators';

// Classes
import SalesOrder from '@/libs/classes/salesorder';

// Variables
const giftcard = {
  code: null,
  value: null
};

export default {
  props: {
    sales_order: {
      type: SalesOrder,
      default: null
    }
  },
  data () {
    return {
      form: {
        giftcard: Object.assign({}, giftcard),
        errors: []
      }
    };
  },
  computed: {
    value_formatted: {
      get () {
        if (this.form.giftcard.value === null) return null;
        return this.$format.currencyToInput(this.form.giftcard.value);
      },
      set (value) {
        if (typeof value === 'string') value = Number(value);
        this.form.giftcard.value = this.$calculate.price(value, true);
      }
    }
  },
  validations: {
    form: {
      giftcard: {
        code: {
          required,
          customError: function (value) {
            return this.form.errors.indexOf('form.giftcard.code') === -1;
          }
        }
      }
    },
    value_formatted: {
      required,
      currency,
      minValue: function (value) {
        return value > 0;
      },
      customError: function (value) {
        return this.form.errors.indexOf('value_formatted') === -1;
      }
    }
  },

  mounted () {
    // Listeners
    this.$eventhub.on('modals:shop:initiate-giftcard:open', this.open);
    this.$eventhub.on('modals:shop:initiate-giftcard:close', this.close);
  },
  beforeDestroy () {
    // Remove listeners
    this.$eventhub.off('modals:shop:initiate-giftcard:open', this.open);
    this.$eventhub.off('modals:shop:initiate-giftcard:close', this.close);
  },

  methods: {
    async validate () {
      // Clear custom errors
      this.resetCustomErrors();

      const validationArray = [];
      validationArray.push(this.$v);

      // Execute validations
      if (validationArray.length > 0) {
        validationArray.forEach(v => v && v.$touch && v.$touch());
        if (validationArray.filter(v => v && v.$invalid).length !== 0) return false;
      }

      // Check if giftcard already exists
      let giftcard = null;
      try {
        giftcard = await this.$ws.get('v1', `/giftcards/check/${this.form.giftcard.code.toLowerCase()}`);
      } catch (e) {
        console.error(e);
        return false;
      }

      if (giftcard.valid === false) {
        this.form.errors.push('form.giftcard.code');
        return false;
      }

      // Check if giftcard exists locally
      if (this.sales_order && this.sales_order.lines && this.sales_order.lines.length > 0) {
        if (this.sales_order.lines.some(l => l.type === 'giftcard' && l.giftcard_code === this.form.giftcard.code.toLowerCase()) === true) {
          this.form.errors.push('form.giftcard.code');
          return false;
        }
      }

      return true;
    },
    async addGiftcardToCart () {
      if (await this.validate() === true) {
        this.$eventhub.emit('shop:cart:set-loading', true);
        try {
          await shop.addProductToCart({
            type: 'giftcard',
            product_guid: `giftcard:${this.form.giftcard.code.toLowerCase()}`,
            giftcard_code: this.form.giftcard.code.toLowerCase(),
            giftcard_amount: this.form.giftcard.value,
            quantity: 1,
            amount_changeable: false,
            requires_customer: false
          });
        } catch (e) {
          console.error(e);
        }
        this.$eventhub.emit('shop:cart:set-loading', false);

        this.$nextTick(() => this.close());
      }
    },

    resetCustomErrors () {
      this.$set(this.form, 'errors', []);
    },

    open () {
      // Empty giftcard
      this.$set(this.form, 'giftcard', Object.assign({}, giftcard));
      this.resetCustomErrors();
      this.$v.$reset();

      this.$refs.modal.show();

      this.$nextTick(() => {
        this.$refs['giftcardCodeInput'].focus();
      });
    },
    close () {
      if (this.$refs.modal) this.$refs.modal.hide();
    }
  }
};
</script>
