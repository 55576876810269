<template>
  <div>

    <KSubheader
      :breadcrumbs="[{
        page: 'Producten',
        route: '/products/overview'
      }, {
        page: 'Voorraadbeheer',
        route: null
      }, {
        page: 'Inventarisatie',
        route: '/products/stock/count/overview'
      }, {
        page: 'Aanmaken',
        route: null
      }]"
      title="Inventarisatie"
    />

    <KContainer>
      <KPortlet>
        <KPortletBody class="kt-portlet__body-fit">
          <KWizard
            ref="wizard"
            :clickable="false"
          >
            <KWizardStep
              title="Inventarisatie"
            >
              <stock-count-form
                ref="stockCountForm"
                :stock_batch="stockbatch"

                :save="save"
              />
            </KWizardStep>

            <KWizardStep
              title="Gemist"
            >
              <missed-products-form
                v-if="isMounted && $refs.wizard && $refs.wizard.currentStep === 1"
                ref="missedProductsForm"

                :stock_batch="stockbatch"

                :save="save"
              />
            </KWizardStep>

            <KWizardStep
              title="Aanpassingen"
            >
              <reconcile-form
                ref="reconcileForm"
                :stock_batch="stockbatch"
              />
            </KWizardStep>
          </KWizard>
        </KPortletBody>
        <div class="kt-portlet__foot">
          <div class="row align-items-center">
            <div class="col-6 col-lg-2">
              <KButton
                v-if="(isMounted && $refs.wizard && $refs.wizard.currentStep !== 0)"
                variant="outline-focus"
                class="btn-md btn-block btn-tall btn-wide btn-bold btn-upper"
                @click.native.prevent="previousStep"
              >
                Vorige
              </KButton>
            </div>
            <div class="col-6 offset-lg-8 col-lg-2 kt-align-right">
              <KButton
                v-if="(isMounted && $refs.wizard && $refs.wizard.currentStep !== ($refs.wizard.steps.length - 1))"
                variant="focus"
                class="btn-md btn-block btn-tall btn-wide btn-bold btn-upper"
                @click.native.prevent="nextStep"
              >
                Volgende
              </KButton>
              <KButton
                v-if="(isMounted && $refs.wizard && $refs.wizard.currentStep === ($refs.wizard.steps.length - 1))"
                variant="success"
                class="btn-md btn-block btn-tall btn-wide btn-bold btn-upper"
                @click.native.prevent="submitForm"
              >
                Aanmaken
              </KButton>
            </div>
          </div>
        </div>
      </KPortlet>
    </KContainer>
  </div>
</template>

<style lang="scss">
@import "@/assets/sass/caesium.wizard.scss";
</style>

<script>
// Libraries
import mutationsLib from '@/libs/mutations';
import { cloneDeep } from 'lodash';

// Classes
import StockBatch from '@/libs/classes/stock_batch';

// Components
import stockCountForm from './components/stock_count.form';
import missedProductsForm from './components/missed.form';
import reconcileForm from './components/reconcile.form';

export default {
  components: {
    stockCountForm,
    missedProductsForm,
    reconcileForm
  },
  data () {
    return {
      isMounted: false,

      stockbatch: null,
      stockbatchCloned: null
    };
  },

  async mounted () {
    // Check if editing a concept
    if (typeof this.$route.params.guid === 'string' && this.$route.params.guid.length > 0) {
      this.stockbatch = await new StockBatch(this.$route.params.guid).get();

      if (this.stockbatch.type !== 'count') return this.$router.push('/products/stock/count/overview');
      if (this.stockbatch.status === 'final') return this.$router.replace(`/products/stock/count/view/${this.stockbatch.guid}`);

      await this.stockbatch.populate('get');
    } else {
      this.stockbatch = new StockBatch().mergeResponse({type: 'count'});
    }

    this.$set(this.$data, 'stockbatchCloned', cloneDeep(this.stockbatch));

    this.$nextTick(() => {
      this.$set(this, 'isMounted', true);
    });
  },

  methods: {
    async validate () {
      const validationArray = [];

      // Stock count
      if (this.$refs.wizard.currentStep === 0) {
        // validationArray.push(this.$refs.stockCountForm.$v);
        if (await this.$refs['stockCountForm'].validate() === false) return false;
      }

      // Missed Products
      if (this.$refs.wizard.currentStep === 1) {
        if (await this.$refs['missedProductsForm'].validate() === false) return false;
      }

      // Reconciled products
      if (this.$refs.wizard.currentStep === 2) {
        validationArray.push(this.$refs.reconcileForm.$v);
      }

      // Execute validations
      if (validationArray.length > 0) {
        validationArray.forEach(v => v && v.$touch && v.$touch());
        if (validationArray.filter(v => v && v.$invalid).length !== 0) return false;
      }

      return true;
    },
    async save () {
      if (await this.validate() === true) {
        // Create stock batch if not already created
        if (!this.stockbatch._meta) {
          await this.stockbatch.create(this.stockbatch.formatBody('post'));
          this.$set(this, 'stockbatchCloned', cloneDeep(this.stockbatch));
          this.$router.replace(`/products/stock/count/create/${this.stockbatch.guid}`);
        } else {
          // Create mutations
          const fields = ['remark'];
          let mutations = mutationsLib.create(fields, this.stockbatchCloned.formatBody('post'), this.stockbatch.formatBody('post'));

          // Create array mutations of field `mutations`
          const arrayMutations = mutationsLib.createArrayByKey('mutations', 'product_guid', this.stockbatchCloned.formatBody('post'), this.stockbatch.formatBody('post'));

          // Combine them together
          mutations = mutations.concat(arrayMutations);

          const stockbatch = await this.stockbatch.update(mutations);

          // First, set stockbatch to null and clone it again, because Vue doesn't update properly
          this.$set(this, 'stockbatch', null);
          this.$nextTick(() => {
            this.$set(this, 'stockbatch', cloneDeep(stockbatch));
            this.$set(this, 'stockbatchCloned', cloneDeep(this.stockbatch));
          });
        }
      }

      return this.stockbatch;
    },
    async submitForm () {
      if (await this.validate() === true) {
        // Save first
        await this.save();

        // Submit
        if (this.stockbatch) {
          await this.stockbatch.submit();
          this.$router.push(`/products/stock/count/view/${this.stockbatch.guid}`);
        }
      }
    },

    async previousStep () {
      if (this.$refs.wizard) {
        // Missed products
        if (this.$refs.wizard.currentStep === 1 && await this.validate() === true) {
          await this.$refs['missedProductsForm'].onNextStep();
        }

        return this.$refs.wizard.previousStep();
      }
    },
    async nextStep () {
      if (this.$refs.wizard) {
        if (await this.validate() === true) {
          // Missed products
          if (this.$refs.wizard.currentStep === 1) {
            await this.$refs['missedProductsForm'].onNextStep();
          }

          this.$refs.wizard.nextStep();
        }
      }
    }
  }
};
</script>
