import Abstract from './abstract';

import moment from 'moment-timezone';
import { merge } from 'lodash';

import { apiWS as ws } from '@/libs/ws';

import Product from './product';

import PricelistPrice from './pricelist.price';

class Pricelist extends Abstract {
  constructor (guid = null) {
    super(guid);
    this.uri = '/pricelists';

    let defaultObject = {
      name: null,
      type: 'products',
      active: false,
      prices: [],
      offer_list: false,
      start_timestamp: 0,
      end_timestamp: 0
    };

    merge(this, defaultObject);
  }

  // Methods
  formatBody (type = 'get', options = {}) {
    let body = super.formatBody(type, options);

    // Format prices
    body.prices = [];
    this.prices.forEach((row, idx) => {
      body.prices.push(this.prices[idx].formatBody({}, 'post', options));
    });

    return body;
  }

  mergeResponse (response = {}) {
    super.mergeResponse(response);

    // Set prices to new response instead of merging
    if (response.prices && Array.isArray(response.prices)) this.prices = response.prices;

    return this;
  }

  async populate (type = 'get', options = {}) {
    // Populate prices
    if (this.prices.length) {
      // Create requests
      let requests = [];
      let productGuids = this.prices.map(p => p.product_guid, []).filter((value, index, self) => self.indexOf(value) === index, []);
      let limit = 100;
      let pages = Math.ceil(productGuids.length / limit);

      // Create promises
      for (let i = 0; i < pages; i++) {
        requests.push(ws.get('v1', '/products', {
          query: {
            'filter[_meta.guid]': productGuids.slice((i * limit), (i * limit + limit)).join(','),
            limit: limit
          }
        }));
      }

      // Wait for promises
      let products = [];
      let responses = await Promise.all(requests);
      responses.forEach((arr) => {
        products = products.concat(arr);
      });

      // Map all products
      products = products.map((product) => {
        return new Product().mergeResponse(product);
      });

      // Connect prices
      this.prices.forEach((row, idx) => {
        let product = products.find(p => p.guid === row.product_guid);
        if (typeof product !== typeof undefined) {
          row.product = new Product().mergeResponse(product);
        }

        this.prices[idx] = new PricelistPrice().mergeResponse(row);
      });
    }

    return this;
  }

  // Getters & Setters
  get start_timestamp_formatted () {
    return moment.unix(this.start_timestamp).tz('UTC').format('YYYY-MM-DD');
  }

  set start_timestamp_formatted(value) {
    let momentValue = moment.tz(value, 'UTC');
    if (momentValue.isValid() === false) return false;
    this.start_timestamp = momentValue.startOf('day').unix();
  }

  get end_timestamp_formatted () {
    return moment.unix(this.end_timestamp).tz('UTC').format('YYYY-MM-DD');
  }

  set end_timestamp_formatted (value) {
    let momentValue = moment.tz(value, 'UTC');
    if (momentValue.isValid() === false) return false;
    this.end_timestamp = momentValue.endOf('day').unix();
  }
};

export default Pricelist;
