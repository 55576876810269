<template>
  <KPortlet class="cs-shop-category-block kt-portlet--height-fluid cursor-pointer" :class="[{'disabled': disabled}]">
    <KPortletBody>
      <div class="cs-shop-category-block--row">
        <div class="cs-shop-category-block--icon">
          <i :class="[iconClassComputed]"><font-awesome-icon :icon="icon" /></i>
        </div>
        <div class="cs-shop-category-block--content">
          <slot name="default"></slot>
        </div>
      </div>
    </KPortletBody>
  </KPortlet>
</template>

<script>
export default {
  props: {
    icon: {
      type: [String, Array],
      default: () => ['fad', 'question']
    },
    iconClass: {
      type: String,
      default: 'kt-font-brand'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    iconClassComputed() {
      if (this.disabled === true) return '';
      else return this.iconClass;
    }
  }
}
</script>
