<template>
  <div>
    <template v-if="pricelists && pricelists.length > 0">
      <div v-for="(row, idx) in pricelists" class="row form-group">
        <div class="col-9 col-form-label">{{ row.name }}</div>
        <div class="col-3 text-right">
          <span class="kt-switch" :class="[{'kt-switch--success': isPricelistActive(row)}]">
            <label>
              <input type="checkbox" v-on:change="togglePricelist(row, $event)" :checked="isPricelistActive(row)" >
              <span></span>
            </label>
          </span>
        </div>
      </div>
    </template>

    <KAlert v-else variant="outline-info" :icon="['fad', 'euro-sign']">Geen tarievenlijsten gevonden</KAlert>
  </div>
</template>

<script>
// classes
import Equipment from '@/libs/classes/equipment';

export default {
  props: {
    action: {
      type: String,
      default: "CREATE"
    },

    equipment: Equipment,

    pricelists: Array,

    disabled: Boolean,
    placeholder: {
      type: Boolean,
      default: true
    }
  },

  methods: {
    togglePricelist (pricelist, event) {
      if (event.target.checked === true) {
        if (this.equipment.pricelists.indexOf(pricelist.guid) === -1) {
          this.equipment.pricelists.push(pricelist.guid);
        }
      } else {
        this.equipment.pricelists = this.equipment.pricelists.filter(guid => guid !== pricelist.guid);
      }
    },
    isPricelistActive (pricelist) {
      return (this.equipment && this.equipment.pricelists && this.equipment.pricelists.indexOf(pricelist.guid) !== -1);
    },

    async validate () {
      const validationArray = [];

      // Execute validations
      if(validationArray.length > 0) {
        validationArray.forEach(v => v && v.$touch && v.$touch())
        if(validationArray.filter(v => v && v.$invalid).length !== 0) return false;
      }

      return true;
    }
  }
}
</script>
