<template>
  <div>
    <!-- Password -->
    <div class="row">
      <div class="col-12">
        <password-portlet
          ref="passwordPortlet"
          :user="user"
        />
      </div>
    </div>

    <!-- Personal PIN -->
    <div class="row">
      <div class="col-12">
        <pin-portlet
          ref="pinPortlet"
          :user="user"
        />
      </div>
    </div>
  </div>
</template>

<script>
// Libraries
import { cloneDeep } from 'lodash';

// Classes
import User from '@/libs/classes/user';

// Components
import passwordPortlet from './password';
import pinPortlet from './pin';

export default {
  components: {
    passwordPortlet,
    pinPortlet
  },
  props: {
    user: {
      type: User,
      default: null
    }
  },
  data () {
    return {
      userCloned: cloneDeep(this.user)
    };
  }
};
</script>
