<template>
  <KModal ref="groupModal" v-keydown="{ 'escape': hide }" :title="(action === 'CREATE' ? 'Groep aanmaken' : 'Groep wijzigen')">

    <groupForm ref="groupForm"
      :action="action"

      :group="group"
    ></groupForm>

    <template v-slot:footer>
      <div class="col-12 col-lg-6">
        <KButton variant="primary" class="btn-md btn-block btn-tall btn-wide btn-bold btn-upper" @click.native.prevent="submitForm">Aanmaken</KButton>
      </div>
    </template>

  </KModal>
</template>

<script>
// Classes
import ProductGroup from '@/libs/classes/product_group';

// Components
import groupForm from './form';

export default {
  props: {
    action: {
      type: String,
      default: 'CREATE'
    },

    group: {
      type: ProductGroup,
      default: () => new ProductGroup()
    }
  },
  components: {
    groupForm
  },
  methods: {
    hide() {
      this.$refs.groupModal.hide();
    },
    show() {
      this.$refs.groupModal.show();
    },

    async validate() {
      const validationArray = [];
      validationArray.push(this.$refs.groupForm.$v);

      // Execute validations
      if(validationArray.length > 0) {
        validationArray.forEach(v => v && v.$touch && v.$touch())
        if(validationArray.filter(v => v && v.$invalid).length !== 0) return false;
      }

      return true;
    },
    async submitForm() {
      let valid = await this.validate();
      if(valid) {

        let response = await this.$ws.post('v1', '/product_groups', {
          body: this.group
        })

        this.$emit('modal:submit', new ProductGroup().mergeResponse(response));
        this.$refs.groupModal.hide();
      }
    }
  }
}
</script>
