<template>
  <div>

    <div class="row mt-5">
      <router-view ref="routerView"
        :props="this.$props"
        :sales_order="sales_order"
      ></router-view>
    </div>

    <modal-giftcard-payment-method ref="modalGiftcardPaymentMethod"
      :sales_order="sales_order"
    ></modal-giftcard-payment-method>

  </div>
</template>

<script>
// Mixins
import shop from '@/mixins/shop';

// Classes
import CashRegister from '@/libs/classes/cash_register';

// Modals
import modalGiftcardPaymentMethod from '@/components/sales/shop/components/modals/giftcard-payment-method';

export default {
  mixins: [shop],
  props: {
    meta: Object,
    cash_register: CashRegister
  },
  data() {
    return {
      sales_order: null
    }
  },
  components: {
    modalGiftcardPaymentMethod
  },

  mounted() {
    // Set sales order of shop
    this.$set(this, 'sales_order', this.shop.sales_order);

    // Check if sales order is fine for payments
    this.checkSalesOrder();

    // Listeners
    this.$eventhub.on('shop:cart:updated', this.onCartUpdated);
  },
  beforeDestroy() {
    // Remove listeners
    this.$eventhub.off('shop:cart:updated', this.onCartUpdated);
  },

  methods: {
    onCartUpdated(sales_order) {
      // First set to null, so it can update properly
      this.$set(this, 'sales_order', null);

      // Set to real sales_order on next tick
      this.$nextTick(() => {
        this.$set(this, 'sales_order', sales_order);
        this.checkSalesOrder();
      });
    },

    checkSalesOrder() {
      if (this.sales_order && this.sales_order.lines && this.sales_order.lines.length > 0) {
        if (this.sales_order.lines.some(l => l.requires_customer === true) === false || (this.sales_order.lines.some(l => l.requires_customer === true) && this.sales_order.customer)) {
          return this.sales_order;
        }
      }
      return this.navigateTo('/sales/shop/:cash_register/cart');
    }
  }
}
</script>
