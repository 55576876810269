<template>
  <KModal
    v-keydown="{'escape': close}"

    ref="modal"
    size="lg"
  >
    <template v-slot:header>
      <!-- Title -->
      <h5 class="modal-title">
        Huidtype calculator
      </h5>

      <!-- Close button -->
      <KButton
        type="button"
        variant="light"
        class="close"
        icon="times"
        @click.native.prevent="close"
      />
    </template>

    <template v-slot:default>
      <!-- Questions -->
      <template v-if="form.questions.length > 0">
        <question
          :key="`question_${form.question}`"
          :ref="`question_${form.question}`"

          :question="form.questions[form.question]"
        />
      </template>

      <!-- No questions available -->
      <template v-else>
        <KAlert variant="outline-focus">
          Geen vragen beschikbaar
        </KAlert>
      </template>
    </template>

    <template v-slot:footer>
      <!-- Previous -->
      <KButton
        v-if="(isMounted && form.questions.length && form.question !== 0)"
        variant="outline-focus mr-auto"
        @click.native.prevent="previousStep"
      >
        Vorige
      </KButton>

      <!-- Cancel -->
      <KButton
        variant="secondary"
        @click.native.prevent="close"
      >
        Annuleren
      </KButton>

      <!-- Next -->
      <KButton
        v-if="(isMounted && form.questions.length && form.question < (form.questions.length - 1))"
        variant="outline-focus"
        @click.native.prevent="nextStep"
      >
        Volgende
      </KButton>

      <!-- Calculate -->
      <KButton
        v-if="(isMounted && form.questions.length && form.question === (form.questions.length - 1))"
        variant="success"
        @click.native.prevent="submitForm"
      >
        Bereken
      </KButton>
    </template>
  </KModal>
</template>

<script>
// Libraries
import questions from './questions.json';

// Components
import question from './question';

export default {
  components: {
    question
  },
  data () {
    return {
      isMounted: false,

      options: null,

      form: {
        questions: [],
        question: 0
      }
    };
  },

  mounted () {
    // Listeners
    this.$eventhub.on('modals:customers:skintype-calculator:open', this.open);
    this.$eventhub.on('modals:customers:skintype-calculator:close', this.close);
  },
  beforeDestroy () {
    // Remove listeners
    this.$eventhub.off('modals:customers:skintype-calculator:open', this.open);
    this.$eventhub.off('modals:customers:skintype-calculator:close', this.close);
  },

  methods: {
    async submitForm () {
      if (await this.validate() === true) {
        const skintype = Math.round(this.form.questions.map(q => q.answer, []).reduce((a, b) => a + b) / this.form.questions.length);
        this.$eventhub.emit('modals:customers:skintype-calculator:submit', Object.assign({}, this.options, {
          skintype: skintype
        }));
        this.close();
      }
    },
    async validate () {
      const validationArray = [];
      validationArray.push(this.$refs[`question_${this.form.question}`].$v);

      // Execute validations
      if (validationArray.length > 0) {
        validationArray.forEach(v => v && v.$touch && v.$touch());
        if (validationArray.filter(v => v && v.$invalid).length !== 0) return false;
      }

      return true;
    },

    async previousStep () {
      if (this.form.question !== 0) {
        this.form.question--;
      }
    },
    async nextStep () {
      if (this.form.question < this.form.questions.length) {
        if (await this.validate() === true) {
          this.form.question++;
        }
      }
    },

    regionQuestions (region = null) {
      if (!region) {
        return null;
      }

      // Get questions of region
      return questions.find(q => {
        if (!q.regions) {
          return false;
        }

        if (q.regions === 'string') {
          q.regions = [q.regions];
        }

        return q.regions.indexOf(region) !== -1;
      });
    },
    isRegionSupported (region = null) {
      if (region) {
        return this.regionQuestions(region) !== undefined;
      }
      return false;
    },

    open (options = {}) {
      this.$set(this, 'isMounted', false);

      // Set options
      this.$set(this, 'options', options);

      if (this.options) {
        // Check if region is supported
        if (!this.options.region || this.isRegionSupported(this.options.region) === false) {
          throw new Error('Region undefined in options');
        }

        // Set questions
        this.$set(this.form, 'question', 0);
        this.$set(this.form, 'questions', this.regionQuestions(this.options.region).questions);
        this.form.questions.forEach((question, idx) => {
          this.$set(this.form.questions[idx], 'answer', null);
        });
      }

      this.$refs['modal'].show();

      this.$nextTick(() => {
        this.$set(this, 'isMounted', true);
      });
    },
    close () {
      this.$refs['modal'].hide();
    }
  }
};
</script>
