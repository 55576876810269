<template>
  <KPortlet>
    <KPortletHead>
      <template v-slot:default>Mailchimp</template>
      <template v-slot:toolbar>
        <template v-if="isLoading === true">
          <div class="loading-block">
            <div class="blockui">
              <span class="kt-spinner kt-spinner--v2 kt-spinner--primary" />
            </div>
          </div>
        </template>

        <!-- Active state -->
        <template v-else>
          <span :class="['kt-switch', {'kt-switch--success': (integrationCloned.active)}]">
            <label class="m-0 mt-2">
              <input
                v-model="integrationCloned.active"
                :disabled="isLoadingForm"

                type="checkbox"

                @change="setIntegrationStatus(integrationCloned.active)"
              >
              <span />
            </label>
          </span>
        </template>
      </template>
    </KPortletHead>

    <!-- Body & footer -->
    <template v-if="!isLoading && integrationCloned.active === true">
      <!-- Changeable settings -->
      <KPortletBody>
        <!-- Loading -->
        <template v-if="isLoadingMetadata">
          <div class="row">
            <div class="col-12 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
              <div class="loading-container">
                <div class="loading-block">
                  <div class="blockui ml-auto mr-auto w-100">
                    <span>Gegevens ophalen...</span>
                    <span class="kt-spinner kt-spinner--v2 kt-spinner--primary" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>

        <!-- Form -->
        <template v-else>
          <div class="col-12">
            <label>Lijst</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i><font-awesome-icon :icon="['fad', 'list']" /></i>
                </span>
              </div>
              <select
                v-model.trim="$v.integrationCloned.mailchimp_list.$model"
                :disabled="isLoadingForm"
                :class="['form-control', {'is-invalid': $v.integrationCloned.mailchimp_list.$error}]"
              >
                <!-- Disabled option -->
                <option
                  :value="null"
                  disabled
                >
                  Selecteer een lijst
                </option>

                <!-- Mailchimp lists -->
                <option
                  v-for="(list) in metadata.lists"
                  :key="`mailchimp.list.${list.id}`"
                  :value="list.id"
                >
                  {{ list.name }}
                </option>
              </select>
            </div>
          </div>
        </template>
      </KPortletBody>
      <div
        v-if="isLoadingMetadata === false"
        class="kt-portlet__foot"
      >
        <div class="row align-items-center">
          <div class="offset-6 col-6 offset-lg-9 col-lg-3 offset-xl-10 col-xl-2 kt-align-right">
            <KButton
              :disabled="isLoadingForm"

              variant="warning"
              class="btn-md btn-block btn-tall btn-wide btn-bold btn-upper"

              @click.native.prevent="submitForm"
            >
              Wijzigen
            </KButton>
          </div>
        </div>
      </div>
    </template>
  </KPortlet>
</template>

<script>
// Libraries
import { cloneDeep } from 'lodash';
import moment from 'moment';
import mutationsLib from '@/libs/mutations';

// Classes
import Integration from '@/libs/classes/integration';

// Validations
import { required } from 'vuelidate/lib/validators';

export default {
  data () {
    return {
      isLoading: true,
      integration: null,
      integrationCloned: null,

      isLoadingMetadata: false,
      metadata: {
        lists: []
      },

      isLoadingForm: false,

      moment
    };
  },
  validations: {
    integrationCloned: {
      mailchimp_list: {
        required
      }
    }
  },

  mounted () {
    this.getMailchimpIntegration();
  },

  methods: {
    async getMailchimpIntegration (options = {}) {
      this.$set(this, 'isLoading', true);

      // Get Mailchimp integration details
      try {
        const response = await this.$ws.get('v1', '/integrations/type/mailchimp', options);

        // Add fields
        if (!response.mailchimp_list) response.mailchimp_list = null;

        this.$set(this, 'integration', new Integration().mergeResponse(response));
        this.$set(this, 'integrationCloned', cloneDeep(this.integration));
      } catch (e) {
        // If 404, redirect to integration
        if (e.code === 404) {
          return this.$router.push('/settings/workspace/integrations');
        }

        return this.$error.showModal(e);
      }

      // Get mailchimp metadata
      this.getMailchimpMetadata();

      this.$set(this, 'isLoading', false);
      return this.integration;
    },

    async getMailchimpMetadata (options = {}) {
      this.$set(this, 'isLoadingMetadata', true);

      // Get mailchimp lists
      const lists = await this.$ws.get('v1', '/integrations/mailchimp/lists', options);
      this.$set(this.metadata, 'lists', lists);

      this.$set(this, 'isLoadingMetadata', false);
      return this.metadata;
    },

    async setIntegrationStatus (status = null) {
      if (typeof status !== 'boolean') {
        throw new Error('Status has to be true or false');
      }

      const mutations = mutationsLib.create(['active'], this.integration.formatBody(), this.integrationCloned.formatBody());
      if (mutations.length) {
        this.$set(this, 'isLoading', true);

        try {
          await this.integration.update(mutations);
          this.$set(this, 'integrationCloned', cloneDeep(this.integration));
        } catch (e) {
          this.$error.showModal(e);
        }

        this.$set(this, 'isLoading', false);
      }
      return this.integration;
    },
    async validate () {
      const validationArray = [this.$v];

      // Execute validations
      if (validationArray.length > 0) {
        validationArray.forEach(v => v && v.$touch && v.$touch());
        if (validationArray.filter(v => v && v.$invalid).length !== 0) return false;
      }

      return true;
    },
    async submitForm () {
      if (await this.validate() === true) {
        const promises = [];

        // Mailchimp list update
        if (this.integration.mailchimp_list !== this.integrationCloned.mailchimp_list) {
          promises.push((async () => {
            await this.$ws.put('v1', `/integrations/mailchimp/${this.integration.guid}/list/${this.integrationCloned.mailchimp_list}`);
            this.integration.mailchimp_list = this.integrationCloned.mailchimp_list;
          })());
        }

        // Other mutations
        const mutations = mutationsLib.create([], this.integration.formatBody(), this.integrationCloned.formatBody());
        if (mutations.length) {
          promises.push((async () => {
            await this.integration.update(mutations);
            this.$set(this, 'integrationCloned', cloneDeep(this.integration));
          })());
        }

        // Wait for promises to complete
        if (promises.length > 0) {
          this.$set(this, 'isLoadingForm', true);

          try {
            await Promise.all(promises);
          } catch (e) {
            this.$error.showModal(e);
          }

          this.$set(this, 'isLoadingForm', false);
        }

        return this.integration;
      }
    }
  }
};
</script>
