<template>
  <div>
    <div
      v-if="authenticated !== true"
      class="show-token"
    >
      <h1 class="code">{{ code || '_ _ _ _' }}</h1>
    </div>

    <div v-if="authenticated === true">
      <KRoot header>
        <KPage>
          <KWrapper>
            <KHeader>
              <div class="col-4 mt-auto mb-auto">
                <h2 class="text-white">
                  {{ (store === null ? 'Caesium' : store.name) }}
                </h2>
              </div>
              <div class="col-4 text-center mt-auto mb-auto">
                <img
                  :src="require('@/assets/images/logo/caesium.standalone.png')"
                  style="height: 100px; margin-top:-15px;"
                >
              </div>
              <div class="col-4 text-right mt-auto mb-auto">
                <h3 class="text-white font-weight-normal">
                  {{ date.format('HH:mm DD MMMM YYYY') }}
                </h3>
              </div>
            </KHeader>

            <KContainer class="mt-3">
              <template v-if="isAuthenticating === true">
                <div class="row">
                  <div class="col-12 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
                    <div class="loading-container">
                      <div class="loading-block">
                        <div class="blockui ml-auto mr-auto w-100">
                          <span>Gegevens ophalen...</span>
                          <span class="kt-spinner kt-spinner--v2 kt-spinner--primary" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>

              <template v-else-if="equipmentSorted && equipmentSorted.length > 0">
                <div class="row">
                  <div
                    v-for="(row, idx) in equipmentOfPage"
                    :key="`equipment.row.${row.guid}.${idx}`"
                    class="col-4"
                  >
                    <equipment-block
                      :key="`equipment.${row.guid}`"
                      :ref="`equipment.${row.guid}`"

                      :equipment="row"
                      :disabled="isBlockDisabled(row)"

                      use-full-time
                      circle-class="w-100 h-100"
                      second-screen
                    />
                  </div>
                </div>
              </template>

              <template v-else>
                <div class="row">
                  <div class="col-12">
                    <KAlert
                      :icon="['fad', 'link']"
                      variant="outline-info"
                    >
                      Nog geen apparatuur toegevoegd
                    </KAlert>
                  </div>
                </div>
              </template>
            </KContainer>

          </KWrapper>
        </KPage>
      </KRoot>
    </div>
  </div>
</template>

<script>
// Libraries
import moment from 'moment-timezone';
import equipment from '@/libs/equipment';
import Logger from '@/libs/logger';

// Components
import equipmentBlock from '@/components/equipment/components/block';

const log = new Logger('Second Screen');

export default {
  components: {
    equipmentBlock
  },
  data () {
    return {
      code: null,
      codeTimeout: null,
      unsubTimeout: null,

      isAuthenticating: false,
      authenticated: false,

      store: null,
      date: moment(),
      dateInterval: null,

      equipment: [],
      masters: [],

      pages: {
        page: 1,
        interval: 10 * 1000, // 10 seconds
        per_page: 6,
        numberOfPages: 1,
        timeout: null
      }
    };
  },
  computed: {
    numberOfPages: {
      get () {
        return Math.ceil(this.equipmentSorted.length / this.pages.per_page);
      }
    },
    equipmentOfPage: {
      get () {
        return this.equipmentSorted.slice((this.pages.page * this.pages.per_page) - this.pages.per_page, (this.pages.page * this.pages.per_page));
      }
    },

    equipmentSorted () {
      if (this.equipmentFiltered && this.equipmentFiltered.length > 0) {
        return this.equipment.slice().sort((a, b) => {
          return a.room.localeCompare(b.room, undefined, {
            numeric: true,
            sensitivity: 'base'
          });
        });
      }
      return [];
    },
    equipmentFiltered () {
      if (this.equipment && this.equipment.length > 0) {
        return this.equipment.slice().filter((row) => {
          return row.connected === true;
        }, []);
      }
      return [];
    }
  },

  async mounted () {
    setTimeout(() => {
      this.logToSentry();
    }, 10 * 1000);
    // Set date interval (Every 5 seconds is more than enough)
    this.dateInterval = setInterval(this.updateDate, 5000);

    // Set authenticated
    this.authenticated = this.$auth.authenticated;

    // Get code when not authenticated
    if (this.$auth.authenticated === false) {
      await this.getCode();
    }

    // When authenticated, configure everything
    if (this.$auth.authenticated === true) {
      await this.authenticate();
    }

    // Add listeners
    this.$eventhub.on('equipment:equipment:updated', this.onEquipmentUpdated);
    this.$eventhub.on('equipment:masters:updated', this.onMastersUpdated);
  },
  beforeDestroy () {
    // Unsubscribe
    this.$eventhub.off('ws:broadcast:public:second_screen:code:' + this.code, this.onBroadcast);
    this.$ws.unsubscribe('public:second_screen:code:' + this.code);

    // Remove listener
    this.$eventhub.off('equipment:equipment:updated', this.onEquipmentUpdated);
    this.$eventhub.off('equipment:masters:updated', this.onMastersUpdated);
  },

  methods: {
    logToSentry () {
      setTimeout(() => {
        this.logToSentry();
      }, 10 * 60 * 1000); // 10 minutes

      this.$sentry.setTag('code', this.code);
      this.$sentry.setTag('authenticated', this.authenticated);
      this.$sentry.setTag('store', this.store);

      log.info('Log to Sentry from Second Screen!, Timestamp: ' + new Date());
    },

    onEquipmentUpdated (equipment) {
      this.$nextTick(() => this.$set(this, 'equipment', equipment));
    },
    onMastersUpdated (masters) {
      // First set to empty array, so it can update properly
      this.$set(this, 'masters', null);

      // Set to real equipment on next tick
      this.$nextTick(() => this.$set(this, 'masters', masters));
    },

    async getCode () {
      if (this.code) {
        const previousCode = this.code;
        this.unsubTimeout = setTimeout(() => {
          // We just wait 20 more seconds just in case it's connected with a code in the last few seconds
          this.$eventhub.off('ws:broadcast:public:second_screen:code:' + previousCode, this.onBroadcast);
          this.$ws.unsubscribe('public:second_screen:code:' + previousCode);
        }, 20 * 60 * 1000);
      }

      const code = await this.$ws.get('v1', '/second_screen/token/request');
      this.code = code.code;

      await this.$ws.subscribe('public:second_screen:code:' + this.code);
      this.$eventhub.on('ws:broadcast:public:second_screen:code:' + this.code, this.onBroadcast);

      this.codeTimeout = setTimeout(() => {
        // Renew code every 5 minutes
        this.getCode();
      }, (5 * 60 * 1000));
    },

    async onBroadcast (message = {}) {
      if (message.action === 'connected') {
        if (this.codeTimeout) {
          clearTimeout(this.codeTimeout);
        }

        // Second param (true) is to remove all other tokens from auth_data
        // We only want this token to be saved
        this.$auth.setAuthData(message.data, true);
        await this.$auth.authenticate();

        this.$eventhub.off('ws:broadcast:public:second_screen:code:' + this.code, this.onBroadcast);
        this.$ws.unsubscribe('public:second_screen:code:' + this.code);
        this.code = null;

        this.authenticated = this.$auth.authenticated;
        log.debug('this authenticated:', this.authenticated);
        if (this.authenticated === true) {
          await this.authenticate();
        }
      }

      if (message.action === 'disconnected') {
        await this.disconnect();
      }
    },

    async authenticate () {
      log.debug('Run authenticate()');

      if (!this.$auth.resource) {
        // this.$auth.logout();
        log.debug('\n\n\n\n\n\n\n\nReload');
        // window.location.reload();
        return;
      }

      // Start authenticating
      this.$set(this, 'isAuthenticating', true);

      // Clear pages
      this.pages.page = 1;
      clearTimeout(this.pages.timeout);

      // Get store & subscribe
      log.debug('setactivestore:', this.$auth.resource);
      await this.$stores.setActiveStore(this.$auth.resource._meta.store);
      await this.subscribe();

      // Set store
      this.store = this.$stores.activeStore;

      // Reload equipment & masters
      this.equipment = [];
      this.masters = [];
      this.equipment = await equipment.getEquipmentInformation();

      log.debug('Going to pingMasters');
      equipment.pingMasters();

      // Set pagination
      this.pages.timeout = setTimeout(this.changePage, this.pages.interval);

      // Finished authenticating
      this.$set(this, 'isAuthenticating', false);
    },
    async subscribe () {
      await this.$ws.subscribe('apps:guid:' + this.$auth.resource._meta.guid);
      this.$eventhub.on('ws:broadcast:apps:guid:' + this.$auth.resource._meta.guid, this.onBroadcast);
    },

    async disconnect () {
      if (this.codeTimeout) {
        clearTimeout(this.codeTimeout);
      }
      if (this.unsubTimeout) {
        clearTimeout(this.unsubTimeout);
      }

      await this.$ws.unsubscribe('apps:guid:' + this.$auth.resource._meta.guid);
      this.$eventhub.off('ws:broadcast:apps:guid:' + this.$auth.resource._meta.guid, this.onBroadcast);

      this.$auth.logout();

      this.code = null;
      await this.getCode();
      this.authenticated = false;
    },

    updateDate () {
      this.date = moment();
    },
    isBlockDisabled (equipment) {
      if (!equipment) return true;
      if (!equipment.master || equipment.master.isConnected === false) return true;
      if (!equipment.communication) return true;

      return false;
    },
    changePage () {
      if (this.numberOfPages > this.pages.page) {
        this.pages.page = this.pages.page + 1;
      } else {
        this.pages.page = 1;
      }

      this.pages.timeout = setTimeout(this.changePage, this.pages.interval);
    }
  }
};
</script>

<style scoped>
div.show-token {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}

div.show-token h1.code {
  font-size: 2000%;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 80px;
}
</style>
