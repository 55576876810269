<template>
  <b-modal
    ref="modal"
    size="lg"
    centered
  >
    <template v-slot:modal-title>
      {{ (options.title ? options.title : 'Transactie') }}
    </template>

    <template v-slot:default>
      <div :class="['row', {'border-bottom pb-3': options.hideDiscount !== true}]">
        <div class="col-12">
          <label>Opmerking</label>
          <textarea
            ref="remark"

            v-model.trim="salesorder.remark"
            :disabled="isLoading"

            class="form-control"
            rows="6"
            cols="6"
          />
        </div>
      </div>

      <!-- Discount -->
      <div
        v-if="options.hideDiscount !== true"
        class="row mt-3"
      >
        <div class="col-12 col-lg-6">
          <label>Korting</label>
          <KButtonGroup class="btn-block">
            <KButton
              :variant="(salesorder.discount_type === null ? 'success' : 'outline-success')"
              @click.native.prevent="setDiscountType(null)"
            >
              Niet actief
            </KButton>

            <KButton
              :variant="(salesorder.discount_type === 'percentile' ? 'success' : 'outline-success')"
              @click.native.prevent="setDiscountType('percentile')"
            >
              Procentueel
            </KButton>
          </KButtonGroup>
        </div>

        <div class="col-12 col-lg-6">
          <div class="input-group label-margin">
            <div class="input-group-prepend">
              <span class="input-group-text input-group-icon">
                <i>
                  <font-awesome-icon
                    :icon="['fad', 'percentage']"
                  />
                </i>
              </span>
            </div>
            <input
              v-model.lazy.number="discountValue"
              :disabled="(salesorder.discount_type === null || isLoading)"

              type="number"
              class="form-control"
            >
          </div>
        </div>
      </div>
    </template>

    <template v-slot:modal-footer>
      <!-- Close -->
      <KButton
        :disabled="isLoading"

        variant="secondary"

        @click.native.prevent="hide"
      >
        Sluiten
      </KButton>

      <!-- Submit -->
      <KButton
        :disabled="isLoading"

        variant="success"

        @click.native.prevent="submit"
      >
        Opslaan
      </KButton>
    </template>
  </b-modal>
</template>

<script>
// Libraries
import { cloneDeep } from 'lodash';

// Classes
import SalesOrder from '@/libs/classes/salesorder';

// Mixins
import Modal from '@/mixins/modal';

export default {
  mixins: [Modal],
  data () {
    return {
      isLoading: false,

      salesorder: null,
      options: null
    };
  },
  computed: {
    discountValue: {
      get () {
        if (!this.salesorder || !this.salesorder.discount_value || this.salesorder.discount_type === null) return null;
        if (this.salesorder.discount_type === 'absolute') return this.$format.currencyToInput(this.salesorder.discount_value);
        if (this.salesorder.discount_type === 'percentile') return this.$format.percentage(this.salesorder.discount_value / 100);
      },
      set (value) {
        if (!this.salesorder || this.salesorder.discount_type === null) value = null;
        else if (this.salesorder.discount_type === 'absolute') value = this.$calculate.price(value, true);
        this.salesorder.discount_value = value;
      }
    }
  },

  mounted () {
    // Listeners
    this.$eventhub.on('modals:shop:salesorder-settings:open', this.show);
    this.$eventhub.on('modals:shop:salesorder-settings:close', this.hide);
  },
  beforeDestroy () {
    // Remove listeners
    this.$eventhub.off('modals:shop:salesorder-settings:open', this.show);
    this.$eventhub.off('modals:shop:salesorder-settings:close', this.hide);
  },

  methods: {
    setDiscountType (type = null) {
      const types = ['percentile'];
      if (types.indexOf(type) !== -1) {
        this.salesorder.discount_type = type;
        return this.salesorder.discount_type;
      }

      this.salesorder.discount_type = null;
      this.salesorder.discount_value = null;
      this.salesorder.discount_overwrite_option = null;
      return this.salesorder.discount_type;
    },

    // General methods (like show & hide are in the modal mixin)
    show (options = {}) {
      // Set options to null again
      this.$set(this, 'options', null);
      this.$set(this, 'isLoading', false);

      // Check for options before showing modal
      if (typeof options === 'object') {
        // Set options to this.options, for future reference
        this.$set(this, 'options', options);

        // Set salesorder, if given
        if (this.options.salesorder && this.options.salesorder instanceof SalesOrder) {
          this.salesorder = cloneDeep(this.options.salesorder);

          // Set discount type if discount_value is not null
          if (this.salesorder.discount_value) {
            this.setDiscountType('percentile');
          }
        }

        // Show modal
        Modal.methods.show.call(this);
      }
    },
    onShown () {
      this.$refs.remark.focus();
    },

    async submit (options = {}) {
      this.$set(this, 'isLoading', true);

      // Check if lines have a discount that's not from salesorder
      if (this.salesorder.discount_value > 0 || (this.salesorder.discount_value === null && this.options.salesorder.discount_value !== null)) {
        if ((!this.salesorder.discount_overwrite_option || options.skipDiscountOverwriteModal !== true)) {
          // Check if lines with their own discount are found
          if (this.salesorder.lines.find(line => line.discount_value > 0 && line.discount_from_salesorder !== true)) {
            // Show modal for overwrite option
            const vm = this;
            this.$eventhub.emit('modals:shop:salesorder-discount-overwrite:open', {
              salesorder: this.salesorder,
              async onSubmit (option = null) {
                vm.salesorder.discount_overwrite_option = option;
                vm.$set(vm.options, 'discountOverwriteModalGiven', true);
                vm.$nextTick(() => vm.submit({
                  skipDiscountOverwriteModal: true
                }));
              }
            });
            this.$set(this, 'isLoading', false);
            return;
          }

          // Set default overwrite_option to force when no salesorder lines with custom discount is found
          this.salesorder.discount_overwrite_option = 'force';
        }
      }

      // Run onSubmit, if given
      if (typeof this.options.onSubmit === 'function') {
        try {
          await this.options.onSubmit(this.salesorder, {
            discountOverwriteModalGiven: (typeof this.options.discountOverwriteModalGiven === 'boolean' ? this.options.discountOverwriteModalGiven : false)
          });
        } catch (e) {
          console.error(e);
          this.$set(this, 'isLoading', false);
          return;
        }
      }

      this.$set(this, 'isLoading', false);
      return this.hide();
    }
  }
};
</script>
