var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isMounted === false || _vm.customer === null)?_c('KContainer',[_c('div',{staticClass:"row mt-3"},[_c('div',{staticClass:"col-12 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4"},[_c('div',{staticClass:"loading-container"},[_c('div',{staticClass:"loading-block"},[_c('div',{staticClass:"blockui ml-auto mr-auto w-100"},[_c('span',[_vm._v("Gegevens ophalen...")]),_vm._v(" "),_c('span',{staticClass:"kt-spinner kt-spinner--v2 kt-spinner--primary"})])])])])])]):_c('div',[_c('KSubheader',{attrs:{"breadcrumbs":[{
      page: 'Klanten',
      route: '/customers/overview'
    }, {
      page: _vm.customer.name,
      route: null
    }],"title":"Klanten"}}),_vm._v(" "),_c('KContainer',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-portlet-menu"},[_c('KPortlet',[_c('KPortletBody',[_c('div',{staticClass:"kt-widget kt-widget--general-1"},[_c('div',{staticClass:"kt-media kt-media--brand kt-media--md kt-media--circle"},[_c('span',{staticClass:"kt-badge kt-badge--username kt-badge--lg kt-badge--dark kt-badge--bold"},[_vm._v(_vm._s(_vm.customer.initials))])]),_vm._v(" "),_c('div',{staticClass:"kt-widget__wrapper"},[_c('div',{staticClass:"kt-widget__label"},[_c('span',{staticClass:"kt-widget__title"},[_vm._v(_vm._s(_vm.customer.name))]),_vm._v(" "),_c('span',{staticClass:"kt-widget__desc"},[_c('div',{class:['row', {'font-weight-bold kt-font-focus cursor-pointer': _vm.canGiveInitialSessionCredit === true}],on:{"click":function($event){$event.preventDefault();return _vm.openInitialSessionCreditModal($event)}}},[_c('div',{staticClass:"col-8"},[_vm._v("Sessiekrediet")]),_vm._v(" "),_c('div',{staticClass:"col-4 d-flex flex-row justify-content-between"},[_c('div',{staticClass:"text-left"},[_vm._v("€")]),_vm._v(" "),_c('div',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.$format.currency(_vm.customer.balance.session_credits)))])])]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-8"},[_vm._v("Op rekening")]),_vm._v(" "),_c('div',{staticClass:"col-4 d-flex flex-row justify-content-between"},[_c('div',{staticClass:"text-left"},[_vm._v("€")]),_vm._v(" "),_c('div',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.$format.currency(_vm.customer.balance.on_account)))])])])])])])])]),_vm._v(" "),_c('div',{staticClass:"kt-portlet__separator"}),_vm._v(" "),_c('KPortletBody',[_c('ul',{staticClass:"kt-nav kt-nav--bolder kt-nav--fit-ver kt-nav--v4",attrs:{"role":"tablist"}},[_c('router-link',{attrs:{"to":"personal_information"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var href = ref.href;
    var route = ref.route;
    var navigate = ref.navigate;
    var isActive = ref.isActive;
    var isExactActive = ref.isExactActive;
return [_c('li',{class:['kt-nav__item', {'active': isActive}]},[_c('a',{staticClass:"kt-nav__link",attrs:{"role":"tab"},on:{"click":function($event){$event.preventDefault();return _vm.$router.push(href)}}},[_c('span',{staticClass:"kt-nav__link-icon"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['fad', 'user']}})],1)]),_vm._v(" "),_c('span',{staticClass:"kt-nav__link-text"},[_vm._v("Persoonlijke informatie")])])])]}}])}),_vm._v(" "),_c('router-link',{attrs:{"to":"authentication"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var href = ref.href;
    var route = ref.route;
    var navigate = ref.navigate;
    var isActive = ref.isActive;
    var isExactActive = ref.isExactActive;
return [_c('li',{class:['kt-nav__item', {'active': isActive}]},[_c('a',{staticClass:"kt-nav__link",attrs:{"role":"tab"},on:{"click":function($event){$event.preventDefault();return _vm.$router.push(href)}}},[_c('span',{staticClass:"kt-nav__link-icon"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['fad', 'fingerprint']}})],1)]),_vm._v(" "),_c('span',{staticClass:"kt-nav__link-text"},[_vm._v("Authenticatie")])])])]}}])}),_vm._v(" "),_c('router-link',{attrs:{"to":"salesorders"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var href = ref.href;
    var route = ref.route;
    var navigate = ref.navigate;
    var isActive = ref.isActive;
    var isExactActive = ref.isExactActive;
return [_c('li',{class:['kt-nav__item', {'active': isActive}]},[_c('a',{staticClass:"kt-nav__link",attrs:{"role":"tab"},on:{"click":function($event){$event.preventDefault();return _vm.$router.push(href)}}},[_c('span',{staticClass:"kt-nav__link-icon"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['fad', 'shopping-cart']}})],1)]),_vm._v(" "),_c('span',{staticClass:"kt-nav__link-text"},[_vm._v("Transacties")])])])]}}])})],1)])],1)],1),_vm._v(" "),_c('div',{staticClass:"col-12 col-sm"},[_c('router-view',{key:_vm.$route.fullPath,attrs:{"customer":_vm.customer},on:{"update:customer":function($event){_vm.customer=$event}}})],1)])]),_vm._v(" "),(_vm.canGiveInitialSessionCredit)?_c('initial-session-credit-modal',{ref:"initialSessionCreditModal"}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }