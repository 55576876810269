<template>
  <div class="row form-group">
    <div class="col-12 col-lg-6">
      <label>Land</label>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">
            <i><font-awesome-icon :icon="['fad', 'globe']" /></i>
          </span>
        </div>
        <select class="form-control" v-model="customer.address.country" :disabled="disabled">
          <option value="NL">Nederland</option>
        </select>
      </div>
    </div>

    <div class="col-12 mt-3 col-lg-3 mt-lg-0">
      <label>Postcode</label>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">
            <i><font-awesome-icon :icon="['fad', 'home']" /></i>
          </span>
        </div>
        <input type="text" class="form-control" :placeholder="(placeholder ? '1234AB' : undefined)" v-model="customer.address.postalCode" :disabled="disabled">
      </div>
    </div>

    <div class="col-12 mt-3 col-lg-3 mt-lg-0">
      <label>Huisnummer</label>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">
            <i><font-awesome-icon :icon="['fad', 'home']" /></i>
          </span>
        </div>
        <input type="text" class="form-control" :placeholder="(placeholder ? '12' : undefined)" v-model="customer.address.houseNumber" :disabled="disabled">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    customer: Object,

    disabled: Boolean,
    placeholder: {
      type: Boolean,
      default: true
    }
  }
}
</script>
