<template>
  <div>
    <KSubheader
      :breadcrumbs="[{
        page: 'Apparatuur',
        route: '/equipment/overview'
      }, {
        page: 'Apparaat verbinden',
        route: null
      }]"

      title="Apparatuur"
    />

    <KContainer>
      <KPortlet v-if="isMounted">
        <KPortletHead>
          Apparaat verbinden
        </KPortletHead>
        <KPortletBody>

          <equipmentForm
            ref="equipmentForm"

            :equipment="equipment"
            :equipment-groups="meta.groups"
            :masters="meta.masters"

            action="CREATE"
          />

        </KPortletBody>
        <div class="kt-portlet__foot">
          <div class="row align-items-center">
            <div class="offset-6 col-6 offset-lg-10 col-lg-2 kt-align-right">
              <KButton
                variant="primary"
                class="btn-md btn-block btn-tall btn-wide btn-bold btn-upper"
                @click.native.prevent="submitForm"
              >
                Toevoegen
              </KButton>
            </div>
          </div>
        </div>
      </KPortlet>

    </KContainer>
  </div>
</template>

<script>
// Classes
import Equipment from '@/libs/classes/equipment';
import EquipmentGroup from '@/libs/classes/equipment_group';
import Master from '@/libs/classes/master';

// Components
import equipmentForm from './components/form';

export default {
  components: {
    equipmentForm
  },
  data () {
    return {
      isMounted: false,
      isLoading: false,

      equipment: new Equipment(),

      meta: {
        groups: [],
        masters: []
      }
    };
  },

  async mounted () {
    try {
      await Promise.all([this.getEquipmentGroups(), this.getHardwareMasters()]);
    } catch (e) {
      console.error('Failed catching all data', e);
    }

    this.$nextTick(() => {
      this.isMounted = true;
    });
  },

  methods: {
    async getEquipmentGroups () {
      this.$set(this.meta, 'groups', []);
      let groups = await this.$ws.get('v1', '/equipment_groups');
      groups = groups.map((group) => {
        return new EquipmentGroup().mergeResponse(group);
      });
      this.$set(this.meta, 'groups', groups);
      return this.meta.groups;
    },

    async getHardwareMasters () {
      this.$set(this.meta, 'masters', []);
      let masters = await this.$ws.get('v1', '/masters');
      masters = masters.map((row) => {
        return new Master().mergeResponse(row);
      });
      this.$set(this.meta, 'masters', masters);
      return this.meta.masters;
    },

    async validate () {
      const validationArray = [];
      validationArray.push(this.$refs.equipmentForm.$v);

      if (validationArray.length > 0) {
        validationArray.forEach(v => v && v.$touch && v.$touch());
        if (validationArray.filter(v => v && v.$invalid).length !== 0) return false;
      }

      return true;
    },
    async submitForm () {
      if (await this.validate()) {
        // Check if CSID starts with CSID or not
        let serial = this.equipment.serial;
        if (serial && serial.substr(0, 4) !== 'CSID') serial = `CSID${serial}`;

        this.$set(this, 'isLoading', true);

        // Connect the equipment to master
        try {
          await this.equipment.create(this.equipment.formatBody());
        } catch (e) {
          this.$error.showModal(e);
          this.$set(this, 'isLoading', false);
          return;
        }

        this.$router.push(`/equipment/view/${this.equipment.guid}`);
        this.$set(this, 'isLoading', false);
      }
    }
  }
};
</script>
