var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isMounted === false)?_c('KContainer',[_c('div',{staticClass:"row mt-3"},[_c('div',{staticClass:"col-12 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4"},[_c('div',{staticClass:"loading-container"},[_c('div',{staticClass:"loading-block"},[_c('div',{staticClass:"blockui ml-auto mr-auto w-100"},[_c('span',[_vm._v("Gegevens ophalen...")]),_vm._v(" "),_c('span',{staticClass:"kt-spinner kt-spinner--v2 kt-spinner--primary"})])])])])])]):_c('div',[(_vm.routeSort === 'sales')?[_c('KSubheader',{attrs:{"breadcrumbs":[{
        page: 'Kassa',
        route: null
      }, {
        page: 'Kassa\'s',
        route: '/sales/cash_registers'
      },{
        page: _vm.sales.cashRegister.name
      }, {
        page: 'Transacties',
        route: ("/sales/cash_registers/view/" + (_vm.sales.cashRegister.guid) + "/salesorders")
      }, {
        page: 'Transactie',
        route: null
      }],"title":"Transacties"}})]:[_c('KSubheader',{attrs:{"breadcrumbs":[{
        page: 'Kassabeheer',
        route: '/cash_registers/overview'
      }, {
        page: 'Transacties',
        route: '/cash_registers/salesorders/overview'
      }, {
        page: 'Transactie'
      }],"title":"Transacties"}})],_vm._v(" "),_c('KContainer',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('information-portlet',{ref:"informationPortlet",staticClass:"kt-portlet--height-fluid",attrs:{"salesorder":_vm.salesorder,"cash-register":_vm.cashRegister}})],1),_vm._v(" "),_c('div',{staticClass:"col-12 col-md-6"},[_c('customer-portlet',{ref:"customerPortlet",staticClass:"kt-portlet--height-fluid",attrs:{"salesorder":_vm.salesorder,"customer":_vm.salesorder.customer}})],1)]),_vm._v(" "),(_vm.salesorder.remark)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('KPortlet',[_c('KPortletHead',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_vm._v("\n              Opmerking\n            ")]},proxy:true}],null,false,3690265144)}),_vm._v(" "),_c('KPortletBody',{staticStyle:{"white-space":"pre-line","word-break":"keep-all"},domProps:{"textContent":_vm._s(_vm.salesorder.remark.trim())}})],1)],1)]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('lines-portlet',{ref:"linesPortlet",attrs:{"salesorder":_vm.salesorder}})],1)]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('payment-transactions-portlet',{ref:"paymentTransactionsPortlet",attrs:{"salesorder":_vm.salesorder}})],1)])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }