<template>
  <div class="row">
    <!-- If authenticated is still null, show loading block -->
    <div v-if="isAuthenticatedKnown !== true" class="col-12">
      <div class="loading-container mt-auto mb-auto">
        <div class="loading-block">
          <div class="blockui ml-auto mr-auto">
            <span>Authenticatie gegevens ophalen...</span>
            <span class="kt-spinner kt-spinner--v2 kt-spinner--focus"></span>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="col-12">
      <div class="row form-group">
        <div class="col-8 offset-lg-4 col-lg-4">
          <label>Klant PIN</label>
          <div class="input-group input-group-md">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i><font-awesome-icon :icon="['fad', 'lock']" /></i>
              </span>
            </div>
            <input type="password" ref="pincodeInput" class="form-control pin-input" minlength="4" maxlength="6" v-model.lazy.trim="$v.form.pincode.$model" :class="[{'is-invalid': $v.form.pincode.$error}]" v-on:keyup.enter="submitForm" :disabled="(form.isLoading)">
            <div class="input-group-append" v-if="form.isLoading">
              <span class="kt-spinner kt-spinner--v2 kt-spinner--focus"></span>
						</div>
          </div>
        </div>

        <div class="col-4 col-lg-2 label-margin">
          <KButton variant="focus" class="btn-pin" :icon="['far', 'sign-in']" :disabled="(form.isLoading)" v-on:click.native.prevent="submitForm" />
        </div>
      </div>
    </div>

  </div>
</template>

<script>
// Validations
import { required } from 'vuelidate/lib/validators'
import { pincode } from '@/libs/validators/index';

// Classes
import SalesOrder from '@/libs/classes/salesorder';

export default {
  props: {
    sales_order: SalesOrder,

    onCustomerAuthenticated: Function
  },
  data () {
    return {
      watchAuthenticationKnown: null,
      form: {
        pincode: null,

        isLoading: false
      }
    }
  },
  computed: {
    isAuthenticatedKnown: {
      get () {
        if (this.sales_order && this.sales_order.customer && this.sales_order.customer.authenticated !== null) {
          return true;
        }
        return false;
      }
    }
  },
  validations: {
    form: {
      pincode: {
        required,
        isPincode: pincode
      }
    }
  },

  mounted () {
    // Check if authenticated is known
    if (this.isAuthenticatedKnown === true) {
      this.onAuthenticationKnown();
    } else {
      // Add watcher
      this.watchAuthenticationKnown = this.$watch('isAuthenticatedKnown', (value) => {
        if (value === true) {
          this.onAuthenticationKnown();
          this.watchAuthenticationKnown();
        }
      })
    }
  },

  methods: {
    async onAuthenticationKnown () {
      // Authenticated
      if (this.sales_order.customer.authenticated === true) {
        // Run onCustomerAuthenticated if set
        if (typeof this.onCustomerAuthenticated === 'function') {
          await this.onCustomerAuthenticated(this.sales_order.customer);
        }
      }

      // Not authenticated
      else if (this.sales_order.customer.authenticated === false) {
        this.$nextTick(() => {
          this.$refs['pincodeInput'].focus();
        });
      }
    },

    async validate() {
      const validationArray = [];

      validationArray.push(this.$v);

      // Execute validations
      if(validationArray.length > 0) {
        validationArray.forEach(v => v && v.$touch && v.$touch())
        if(validationArray.filter(v => v && v.$invalid).length !== 0) return false;
      }

      return true;
    },
    async submitForm () {
      if (await this.validate() === true) {
        // Set loading
        this.$set(this.form, 'isLoading', true);

        // Try to authenticate
        let response = null;
        let err = null;
        try {
          response = await this.$ws.put('v1', `/customers/${this.sales_order.customer.guid}/shop/authenticate`, {
            body: {
              pincode: this.form.pincode
            }
          })
        } catch (e) {
          console.error('Failed pincode request', e);
          err = e;
        }

        if (err) {
          this.$set(this.form, 'isLoading', false);

          this.$nextTick(() => {
            this.form.pincode = null;
            this.$refs['pincodeInput'].focus();
          });
        } else {
          // Set authenticated
          this.sales_order.customer.authenticated = true;

          if (typeof this.onCustomerAuthenticated === 'function') {
            await this.onCustomerAuthenticated(this.sales_order.customer);
          }
        }
      }
    }
  }
}
</script>
