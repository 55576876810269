<template>
  <KContainer>
    <div class="row mt-3">
      <div class="col-12 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
        <div class="loading-container">
          <div class="loading-block">
            <div class="blockui ml-auto mr-auto w-100">
              <span>Gegevens ophalen...</span>
              <span class="kt-spinner kt-spinner--v2 kt-spinner--primary"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </KContainer>
</template>

<script>
// Libraries
import shop from '@/libs/shop';

export default {
  async mounted () {
    // Check if shop session exists
    let cash_register = await shop.getCashRegister();
    if(cash_register) {
      return this.$router.replace(`/sales/shop/${cash_register.guid}`);
    }

    shop.removeSession();
    return this.$router.push('/sales/cash_registers');
  }
}
</script>
