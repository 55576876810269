<template>
  <div class="col-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">

    <breadcrumbs
      title="Bundels"
      :breadcrumbs="[{
        title: 'Bundels',
        route: null
      }]"
    ></breadcrumbs>

    <div v-if="isMounted" class="row mt-3">

      <!-- Bundles -->
      <template v-if="bundle_products.length > 0">
        <div v-for="(product, idx) in bundle_products" class="col-12">
          <product-line :ref="`credit_product.${product.guid}`"
            class="cursor-pointer"

            :icon="['fad', 'boxes']"
            :price="product.price.price_incl_vat"

            v-on:click.prevent.native="addProduct(product)"
          >
            <h5 class="product-title">
              {{ product.name }}

              <span class="user pl-2"><font-awesome-icon class="kt-font-brand" :icon="['far', 'user']" /></span>
            </h5>

            <span class="product-subtitle">
            </span>
          </product-line>
        </div>
      </template>

      <template v-else>
        <div class="col-12">
          <KAlert variant="danger">Geen producten gevonden</KAlert>
        </div>
      </template>

    </div>
  </div>
</template>

<script>
// Mixins
import shopMixin from '@/mixins/shop';

// Classes
import BundleProduct from '@/libs/classes/bundle_product';

export default {
  mixins: [shopMixin],
  data () {
    return {
      isMounted: false,

      bundle_products: []
    }
  },
  async mounted () {
    try {
      await this.getProductBundles();
    } catch(e) {
      console.error('Error fetching bundles', e);
      return;
    }

    this.$nextTick(() => {
      this.isMounted = true;
    })
  },

  methods: {
    async addProduct (product) {
      this.$eventhub.emit('shop:cart:set-loading', true);
      try {
        await this.shop.addProductToCart({
          type: 'bundle',
          bundle_name: product.name,
          bundle_guid: product.guid,
          quantity: 1
        });
      } catch (e) {
        console.error(e);
      }
      this.$eventhub.emit('shop:cart:set-loading', false);
    },

    async getProductBundles (options = {}) {
      this.$set(this, 'bundle_products', []);
      let bundle_products = await this.$ws.get('v1', '/bundle_products', {
        query: {
          'filter[type]': 'bundle',
          'filter[active]': true
        }
      });
      bundle_products = bundle_products.map(bundle => new BundleProduct().mergeResponse(bundle));
      this.$set(this, 'bundle_products', bundle_products);
      return this.bundle_products;
    }
  }
}
</script>
