<template>
  <div
    v-if="isLoading === true"
    class="loading-container"
  >
    <div class="loading-block">
      <div class="blockui ml-auto mr-auto">
        <span>Gegevens ophalen...</span>
        <span class="kt-spinner kt-spinner--v2 kt-spinner--primary" />
      </div>
    </div>
  </div>

  <div v-else-if="!customer">
    <KAlert variant="outline-warning">Klantgegevens niet beschikbaar</KAlert>
  </div>

  <div
    v-else-if="customer"
    class="kt-widget kt-widget--general-1"
  >
    <div class="kt-media kt-media--brand kt-media--lg kt-media--circle">
      <span class="kt-badge kt-badge--username kt-badge--xl kt-badge--dark kt-badge--bold">{{ customer.initials }}</span>
    </div>
    <div class="kt-widget__wrapper">
      <div class="kt-widget__label">
        <router-link
          :to="`/customers/view/${customer.guid}`"
          class="kt-widget__title"
        >
          {{ customer.name }}
        </router-link>
        <span class="kt-widget__desc">
          <div class="row">
            <div class="col-8">Sessiekrediet</div>
            <div class="col-4 d-flex flex-row justify-content-between">
              <div class="text-left">&euro;</div>
              <div class="text-right">{{ $format.currency(customer.balance.session_credits) }}</div>
            </div>
          </div>

          <div class="row">
            <div class="col-8">Op rekening</div>
            <div class="col-4 d-flex flex-row justify-content-between">
              <div class="text-left">&euro;</div>
              <div class="text-right">{{ $format.currency(customer.balance.on_account) }}</div>
            </div>
          </div>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
// Classes
import Equipment from '@/libs/classes/equipment';

export default {
  props: {
    equipment: {
      type: Equipment,
      default: null
    }
  },
  data () {
    return {
      isLoading: false,
      customer: null
    };
  },

  mounted () {
    this.getCustomer();
  },

  methods: {
    async getCustomer (options = {}) {
      this.$set(this, 'customer', null);

      if (this.equipment && this.equipment.communication && this.equipment.communication.session) {
        this.$set(this, 'isLoading', true);

        try {
          const customer = await this.equipment.communication.session.getCustomer(options);
          this.$set(this, 'customer', customer);
        } catch (e) {
          console.error('Failed fetching session customer', e);
        }

        this.$set(this, 'isLoading', false);
      }
      return this.customer;
    }
  }
};
</script>
