<template>
  <div v-if="isMounted">
    <KPortlet>
      <KPortletHead>
        <template v-slot:default>Retour reden "{{ credit_reason.name }}"</template>
      </KPortletHead>
      <KPortletBody>
        <creditReasonForm ref="creditReasonForm" action="EDIT" :credit_reason="$data._credit_reason"></creditReasonForm>
      </KPortletBody>
      <div class="kt-portlet__foot">
        <div class="row align-items-center">
          <div class="offset-6 col-6 offset-lg-10 col-lg-2 kt-align-right">
            <KButton variant="warning" class="btn-md btn-block btn-tall btn-wide btn-bold btn-upper" @click.native.prevent="submitForm">Wijzigen</KButton>
          </div>
        </div>
      </div>
    </KPortlet>

  </div>
</template>

<script>
// Libraries
import mutationsLib from '@/libs/mutations';
import { cloneDeep } from 'lodash';

// Classes
import CreditReason from '@/libs/classes/credit_reasons';

// Components
import creditReasonForm from './components/form';

export default {
  data() {
    return {
      isMounted: false,

      credit_reason: null,
      _credit_reason: null
    };
  },

  components: {
    creditReasonForm
  },

  async mounted () {
    try {
      await this.getCreditReason();
    } catch (e) {
      console.error('credit reason not found:', e);
    }

    this.$nextTick(() => {
      this.isMounted = true;
    })
  },

  methods: {
    async getCreditReason (options = {}) {
      this.$set(this, 'credit_reason', null);
      const creditReason = await new CreditReason(this.$route.params.guid).get(options);
      this.$set(this, 'credit_reason', creditReason);
      this.$set(this.$data, '_credit_reason', cloneDeep(creditReason));
    },

    validate () {
      const validationArray = [];
      validationArray.push(this.$refs.creditReasonForm.$v);

      if(validationArray.length > 0) {
        validationArray.forEach(v => v && v.$touch && v.$touch())
        if(validationArray.filter(v => v && v.$invalid).length !== 0) return false;
      }

      return true;
    },

    async submitForm () {
      if (this.validate() === true) {
        const fields = ['name', 'description'];
        const mutations = mutationsLib.create(fields, this.credit_reason.formatBody(), this.$data._credit_reason.formatBody());

        if (mutations.length) {
          await this.credit_reason.update(mutations);
          this.$set(this.$data, '_credit_reason', cloneDeep(this.credit_reason));
        }
      }
    }
  }
}
</script>
