// Libraries
import { merge } from 'lodash';
import { apiWS as ws } from '@/libs/ws';

// Classes
import Pricelist from './pricelist';
import SessionProduct from './session_product';
import SessionPricelistPrice from './session_pricelist.price';

class SessionPricelist extends Pricelist {
  constructor (guid = null) {
    super(guid);
    this.uri = '/session_pricelists';

    let defaultObject = {
      type: 'session_products',
      vat_code: null,
      equipment_guids: []
    };

    merge(this, defaultObject);
  }

  // Methods
  mergeResponse (response = {}) {
    super.mergeResponse(response);

    // Set equipment_guids to new response instead of merging
    if (response.equipment_guids && Array.isArray(response.equipment_guids)) this.equipment_guids = response.equipment_guids;

    return this;
  }

  async populate (type = 'get', options = {}) {
    // Populate prices
    if (this.prices.length) {
      // Create requests
      let requests = [];
      let productGuids = this.prices.map(p => p.product_guid, []).filter((value, index, self) => self.indexOf(value) === index, []);
      let limit = 100;
      let pages = Math.ceil(productGuids.length / limit);

      // Create promises
      for (let i = 0; i < pages; i++) {
        requests.push(ws.get('v1', '/session_products', {
          query: {
            'filter[_meta.guid]': productGuids.slice((i * limit), (i * limit + limit)).join(','),
            limit: limit
          }
        }));
      }

      // Wait for promises
      let products = [];
      let responses = await Promise.all(requests);
      responses.forEach((arr) => {
        products = products.concat(arr);
      });

      // Map session products
      products = products.map((product) => {
        return new SessionProduct().mergeResponse(product);
      });

      // Get VAT Codes
      let VATCodes = await ws.get('v1', '/vats');
      let VATCode = VATCodes.find(c => c.code === this.vat_code);

      // Match
      this.prices.forEach((row, idx) => {
        let product = products.find(p => p.guid === row.product_guid);
        if (typeof product !== typeof undefined) {
          row.product = product;
          row.product.price.vat_percentage = VATCode.value;
        }

        this.prices[idx] = new SessionPricelistPrice().mergeResponse(row);
      });
    }

    return this;
  }
}

export default SessionPricelist;
