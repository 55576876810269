<template>
  <div class="col-12">
    <label>BTW <sup>Verplicht</sup></label>
    <div class="input-group">
      <div class="input-group-prepend">
        <span class="input-group-text">
          <i><font-awesome-icon :icon="['fad', 'percentage']" /></i>
        </span>
      </div>
      <select
        v-model.trim="$v.product.vat_code.$model"
        :class="['form-control', {'is-invalid': $v.product.vat_code.$error}]"
        :disabled="(vatSorted.length === 0 || isLoading || disabled)"
      >
        <option
          v-for="(VATCode) in vatSorted"
          :key="`vat-select.vat_code.${VATCode.code}`"
          :value="VATCode.code"
        >
          {{ (VATCode.code === 'no_vat' ? 'Geen BTW' : `${VATCode.value}% BTW`) }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
// Classes
import Product from '@/libs/classes/product';
import CreditProduct from '@/libs/classes/credit_product';
import BundleProduct from '@/libs/classes/bundle_product';

// Validations
import { required } from 'vuelidate/lib/validators';

export default {
  props: {
    action: {
      type: String,
      default: 'CREATE'
    },

    product: {
      type: [Product, CreditProduct, BundleProduct],
      default: null
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isLoading: false,

      vatCodes: []
    };
  },
  computed: {
    vatSorted () {
      if (this.vatCodes) {
        return this.vatCodes.slice().sort((a, b) => {
          if (b.code === 'no_vat') return -1;
          return b.value - a.value;
        });
      }
      return [];
    }
  },
  validations: {
    product: {
      vat_code: {
        required
      }
    }
  },

  async mounted () {
    // Get vat codes
    try {
      this.isLoading = true;
      await this.getVATCodes();
      this.isLoading = false;
    } catch (e) {
      console.error(e);
    }

    this.$nextTick(() => {
      // Set default vat code, if not defined yet
      if (this.product && !this.product.vat_code && this.vatCodes.length > 0) {
        let defaultVAT = this.vatCodes.find(vat => vat.default === true);
        this.product.vat_code = (typeof defaultVAT !== typeof undefined ? defaultVAT.code : 'no_vat');
      }
    });
  },

  methods: {
    async getVATCodes () {
      this.$set(this, 'vatCodes', []);

      let vatCodes = await this.$ws.get('v1', '/vats');

      this.$set(this, 'vatCodes', vatCodes);
      return this.vatCodes;
    }
  }
};
</script>
