<template>
  <div
    v-if="isMounted"
    class="row form-group"
  >
    <div class="col-12 col-lg-4">
      <label>Merk</label>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">
            <i>
              <font-awesome-icon :icon="['fad', 'industry']" />
            </i>
          </span>
        </div>
        <v-select
          ref="searchBrand"

          v-model.trim="equipment.brand"
          :options="brands"
          :reduce="row => row.id"
          :disabled="disabled"

          class="form-control"
          label="name"

          @input="onBrandInput"
        />
      </div>
    </div>

    <div class="col-12 col-lg-4">
      <label>Model</label>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">
            <i>
              <font-awesome-icon :icon="['fad', 'industry']" />
            </i>
          </span>
        </div>
        <v-select
          ref="searchModel"

          v-model.trim="equipment.model"
          :options="models"
          :reduce="row => row.id"
          :disabled="(!equipment.brand || disabled)"

          class="form-control"
          label="name"

          @input="onModelInput"
        />
      </div>
    </div>

    <div class="col-12 col-lg-4">
      <label>Type</label>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">
            <i>
              <font-awesome-icon :icon="['fad', 'industry']" />
            </i>
          </span>
        </div>
        <v-select
          ref="searchType"

          v-model.trim="equipment.type"
          :options="types"
          :reduce="row => row.id"
          :disabled="(!equipment.brand || !equipment.model || disabled)"

          class="form-control"
          label="name"

          @input="onTypeInput"
        />
      </div>
    </div>
  </div>
</template>

<script>
// Classes
import Equipment from '@/libs/classes/equipment';

// Components
import brands from './brands.json';
import vSelect from 'vue-select';
import '@/assets/sass/vue-select/vue-select.scss';

export default {
  components: {
    vSelect
  },
  props: {
    equipment: {
      type: Equipment,
      default: null
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isMounted: false,

      input: {
        brand: null,
        model: null,
        type: null
      },

      brands: [],
      models: [],
      types: []
    };
  },

  mounted () {
    // Add brands
    this.brands = brands.map(brand => {
      return {
        id: brand.id,
        name: brand.name
      };
    }, []);

    // Filter models
    this.onBrandInput(this.equipment.brand, {
      clear: false
    });
    this.onModelInput(this.equipment.model, {
      clear: false
    });

    this.$nextTick(() => {
      this.isMounted = true;
    });
  },

  methods: {
    getBrand (brand = null) {
      if (!brand) {
        return null;
      }

      return brands.find(row => row.id === brand);
    },
    getModel (model = null) {
      if (!model) {
        return null;
      }

      const brand = this.getBrand(this.equipment.brand);
      if (!brand) {
        return null;
      }

      return brand.models.find(row => row.id === model);
    },

    onBrandInput (input = null, options = {}) {
      // Check if brand is changed
      if (input !== this.input.brand) {
        // Set brand to current input
        this.input.brand = input;

        // Clear model & type
        if (!options || options.clear !== false) {
          this.equipment.model = null;
          this.equipment.type = null;
          this.$refs.searchModel.clearSelection();
          this.onModelInput(null);
        }

        // Set models
        this.models = [];
        const brand = this.getBrand(input);
        if (brand && brand.models) {
          this.models = brand.models.map(model => {
            return {
              id: model.id,
              name: model.name
            };
          }, []);
        }
      }
    },
    onModelInput (input = null, options = {}) {
      // Check if model is changed
      if (input !== this.input.model) {
        // Set model to current input
        this.input.model = input;

        // Clear type
        if (!options || options.clear !== false) {
          this.equipment.type = null;
          this.$refs.searchType.clearSelection();
          this.onTypeInput(null);
        }

        // Set types
        this.types = [];
        const model = this.getModel(input);
        if (model && model.types) {
          this.types = model.types.map(type => {
            return {
              id: type.id,
              name: type.name
            };
          });
        }
      }
    },
    onTypeInput (input = null, options = {}) {
      // Check if type is changed
      if (input !== this.input.type) {
        // Set type to current input
        this.input.type = input;
      }
    }
  }
};
</script>
