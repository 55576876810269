<template>
  <KPortlet>
    <KPortletHead>
      Transacties
    </KPortletHead>
    <KPortletBody v-if="isMounted === true">

      <div class="row">
        <div class="col-12">
          <div class="kt-input-icon kt-input-icon--left">
            <input
              type="text"
              class="form-control"
              placeholder="Zoeken..."
              @keyup.enter="$refs['datatable'].search"
              @keyup="searchDebounced"
            >
            <span class="kt-input-icon__icon kt-input-icon__icon--left">
              <span>
                <i>
                  <font-awesome-icon
                    :icon="['far', 'search']"
                  />
                </i>
              </span>
            </span>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-12 datatable datatable-wrapper">
          <datatable
            ref="datatable"
            :url="datatable.url"
            :filters="datatable.filters"
            :fields="datatable.fields"
            :search-fields="datatable.searchFields"
            :on-pagination-data="onPaginationData"
          >
            <!-- Actions -->
            <div
              slot="actions"
              slot-scope="props"
              class="datatable-actions"
            >
              <router-link
                v-if="$auth.hasPermission('get_salesorders')"
                :to="`/cash_registers/salesorders/view/${props.rowData._meta.guid}`"
              >
                <KButton
                  :icon="['fad', 'eye']"
                  tag="a"
                  variant="label-brand"
                />
              </router-link>
            </div>
          </datatable>

          <div class="row pagination">
            <div class="col-xs-12 col-md-6">
              <datatable-pagination
                ref="pagination"
                @vuetable-pagination:change-page="onChangePage"
              />
            </div>

            <div class="col-xs-12 col-md-6">
              <datatable-pagination-info
                ref="pagination.info"
                @vuetable-paginationinfo:change-rowcount="onChangeRowCount"
              />
            </div>
          </div>
        </div>
      </div>

    </KPortletBody>
  </KPortlet>
</template>

<script>
// Classes
import Customer from '@/libs/classes/customer';

// Libraries
import moment from 'moment-timezone';

// Mixins
import datatable from '@/mixins/datatable';

export default {
  mixins: [datatable],
  props: {
    customer: {
      type: Customer,
      default: null
    }
  },
  data () {
    return {
      isMounted: false,
      datatable: {
        url: '/salesorders',

        fields: [{
          title: 'Bedrag',
          name: 'total_incl_vat',
          sortField: 'total_incl_vat',
          dataClass: 'w-1/8',
          callback: (value) => `&euro; ${this.$format.currency(value)}`
        }, {
          title: 'Datum',
          name: '_meta.created',
          sortField: '_meta.created',
          dataClass: 'w-2/8',
          callback: (value) => moment(value).format('LLL')
        }, {
          title: '',
          name: '__slot:actions',
          titleClass: 'text-right',
          dataClass: 'text-right'
        }],

        searchFields: [],
        filters: {
          paid: true,
          customer_guid: this.customer.guid
        }
      }
    };
  },

  mounted () {
    this.$nextTick(() => {
      this.isMounted = true;
    });
  }
};
</script>
