<template>
  <!-- Loading -->
  <KContainer v-if="isMounted === false">
    <div class="row mt-3">
      <div class="col-12 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
        <div class="loading-container">
          <div class="loading-block">
            <div class="blockui ml-auto mr-auto w-100">
              <span>Gegevens ophalen...</span>
              <span class="kt-spinner kt-spinner--v2 kt-spinner--primary" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </KContainer>

  <div v-else-if="creditProduct !== null">
    <KSubheader
      :breadcrumbs="[{
        page: 'Producten',
        route: '/products'
      }, {
        page: 'Sessiekrediet',
        route: '/products/credit'
      }, {
        page: creditProduct.name,
        route: null
      }]"

      title="Sessiekrediet"
    />

    <KContainer>
      <KPortlet>
        <KPortletHead>
          <template v-slot:default>Krediet wijzigen</template>
        </KPortletHead>
        <KPortletBody>

          <informationForm
            ref="informationForm"

            :credit_product="creditProductClone"

            :action="($auth.hasPermission([['update_credit_products']]) ? 'EDIT' : 'VIEW')"
            :disabled="isLoading"
          />

        </KPortletBody>
        <div
          v-if="$auth.hasPermission([['update_credit_products']])"
          class="kt-portlet__foot"
        >
          <div class="row align-items-center">
            <div class="offset-6 col-6 offset-lg-10 col-lg-2 kt-align-right">
              <KButton
                :disabled="isLoading"

                variant="warning"
                class="btn-md btn-block btn-tall btn-wide btn-bold btn-upper"

                @click.native.prevent="submitForm"
              >
                Wijzigen
              </KButton>
            </div>
          </div>
        </div>
      </KPortlet>
    </KContainer>

  </div>
</template>

<script>
// Libraries
import mutationsLib from '@/libs/mutations';
import { cloneDeep } from 'lodash';

// Classes
import CreditProduct from '@/libs/classes/credit_product';

// Components
import informationForm from './components/information.form';

export default {
  components: {
    informationForm
  },
  data () {
    return {
      isMounted: false,
      isLoading: false,

      creditProduct: null,
      creditProductClone: null,

      meta: {
        VATCodes: []
      }
    };
  },

  async mounted () {
    try {
      await Promise.all([this.getCreditProduct()]);
      this.isMounted = true;
    } catch (e) {
      console.error('Failed catching all data', e);
      this.$error.showModal(e);
      return;
    }

    this.$nextTick(() => {
      this.isMounted = true;
    });
  },

  methods: {
    async getCreditProduct (options = {}) {
      this.$set(this, 'creditProduct', null);

      let creditProduct = await new CreditProduct(this.$route.params.guid).get(options);
      await creditProduct.populate('get', options);

      this.$set(this, 'creditProduct', creditProduct);
      this.$set(this.$data, 'creditProductClone', cloneDeep(creditProduct));
      return this.credit_product;
    },

    async validate () {
      const validationArray = [];

      if (validationArray.length > 0) {
        validationArray.forEach(v => v && v.$touch && v.$touch());
        if (validationArray.filter(v => v && v.$invalid).length !== 0) return false;
      }

      // Form validation
      if (await this.$refs.informationForm.validate() === false) return false;

      return true;
    },
    async submitForm () {
      if (await this.validate() === true) {
        const fields = ['name', 'article_number', 'EAN_code', 'minimum_permission_group_level', 'credits', 'price.price_excl_vat', 'vat_code', 'amount_changeable'];
        const mutations = mutationsLib.create(fields, this.creditProduct.formatBody(), this.creditProductClone.formatBody());

        if (mutations.length) {
          this.isLoading = true;

          try {
            await this.creditProduct.update(mutations);
            await this.creditProduct.populate('update');

            this.$set(this, 'creditProductClone', cloneDeep(this.creditProduct));
          } catch (e) {
            this.$error.showModal(e);
          }

          this.isLoading = false;
        }
      }
    }
  }
};
</script>
