<template>
  <!-- Loading -->
  <KContainer v-if="isMounted === false || stock_batch === null">
    <div class="row mt-3">
      <div class="col-12 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
        <div class="loading-container">
          <div class="loading-block">
            <div class="blockui ml-auto mr-auto w-100">
              <span>Gegevens ophalen...</span>
              <span class="kt-spinner kt-spinner--v2 kt-spinner--primary" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </KContainer>

  <!-- Finished loading -->
  <div v-else>
    <KSubheader
      :breadcrumbs="[{
        page: 'Producten',
        route: '/products/overview'
      }, {
        page: 'Voorraadbeheer'
      }, {
        page: 'Mutaties',
        route: '/products/stock/mutations'
      }, {
        page: 'Mutatie',
        route: null
      }]"

      title="Mutaties"
    />

    <KContainer>
      <KPortlet>
        <KPortletHead>
          <template v-slot:default>Inventarisatie op {{ moment(stock_batch.finished_timestamp).format('LLL') }}</template>
        </KPortletHead>
        <KPortletBody>

          <div class="row form-group mb-0">
            <div class="col-12 col-lg-6">
              <label>Datum &amp; tijd</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <i><font-awesome-icon :icon="['fad', 'clock']" /></i>
                  </span>
                </div>
                <input
                  :value="moment(stock_batch.finished_timestamp).format('LLL')"
                  type="text"
                  class="form-control"
                  disabled
                >
              </div>
            </div>

            <div class="col-12 col-lg-6">
              <label>Medewerker</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <i><font-awesome-icon :icon="['fad', 'user']" /></i>
                  </span>
                </div>
                <div
                  class="form-control"
                  readonly
                >
                  <router-link
                    v-if="stock_batch.finished_user"
                    :to="(stock_batch.finished_user.guid === $auth.user.guid ? `/settings/personal` : `/users/view/${stock_batch.finished_user.guid}`)"
                    tag="a"
                  >
                    {{ stock_batch.finished_user.name }}
                  </router-link>

                  <template v-else>Onbekend</template>
                </div>
              </div>
            </div>
          </div>

          <div class="row form-group mb-0 mt-3">
            <div class="col-12">
              <label>Opmerking</label>
              <textarea
                :value="stock_batch.remark"
                class="form-control"
                rows="3"
                cols="6"
                disabled
              />
            </div>
          </div>
        </KPortletBody>
      </KPortlet>

      <mutations-portlet
        ref="mutationsPortlet"
        :stock_batch="stock_batch"
      />

    </KContainer>
  </div>
</template>

<script>
// Libraries
import moment from 'moment-timezone';

// Classes
import StockBatch from '@/libs/classes/stock_batch';

// Components
import mutationsPortlet from './components/mutations';

export default {
  components: {
    mutationsPortlet
  },
  data () {
    return {
      isMounted: false,

      stock_batch: null,

      moment
    };
  },

  async mounted () {
    try {
      this.getStockBatch();
    } catch (e) {
      console.error('Failed catching all data', e);
      return;
    }

    this.$nextTick(() => {
      this.isMounted = true;
    });
  },

  methods: {
    async getStockBatch (options = {}) {
      this.$set(this, 'stock_batch', null);

      let stockBatch = await new StockBatch(this.$route.params.guid).get(options);

      if (stockBatch.type !== 'count') this.$router.push('/products/stock/count/overview');
      if (stockBatch.status === 'draft') this.$router.replace(`/products/stock/count/create/${stockBatch.guid}`);

      await stockBatch.populate('get', options);

      this.$set(this, 'stock_batch', stockBatch);
      return this.stock_batch;
    }
  }
};
</script>
