<template>
  <div v-if="hardware !== null">

    <KSubheader
      :breadcrumbs="[{
        page: 'Hardware',
        route: '/hardware/overview'
      }, {
        page: hardware.name,
        route: null
      }]"

      title="Hardware"
    />

    <KContainer>
      <KPortlet>
        <KPortletHead>
          {{ hardware.name }}
        </KPortletHead>
        <KPortletBody>
          <hardwareForm
            ref="hardwareForm"

            :hardware="hardwareCloned"

            :disabled="($auth.hasPermission('update_masters') !== true)"
          />

        </KPortletBody>
        <div
          v-if="$auth.hasPermission('update_masters')"
          class="kt-portlet__foot"
        >
          <div class="row align-items-center">
            <div class="offset-6 col-6 offset-lg-10 col-lg-2 kt-align-right">
              <KButton
                variant="warning"
                class="btn-md btn-block btn-tall btn-wide btn-bold btn-upper"
                @click.native.prevent="submitForm"
              >
                Wijzigen
              </KButton>
            </div>
          </div>
        </div>
      </KPortlet>

    </KContainer>

  </div>
</template>

<script>
// Libraries
import { cloneDeep } from 'lodash';
import mutationsLib from '@/libs/mutations';

// Classes
import Master from '@/libs/classes/master';

// Components
import hardwareForm from './components/form';

export default {
  components: {
    hardwareForm
  },
  data () {
    return {
      hardware: null,
      hardwareCloned: null
    };
  },

  async mounted () {
    try {
      await this.getDevice();
    } catch (e) {
      console.error('Device not found:', e);
    }
  },

  methods: {
    async getDevice (options = {}) {
      this.$set(this, 'hardware', null);
      this.$set(this, 'hardwareCloned', null);

      let hardware = await new Master(this.$route.params.guid).get(options);
      await hardware.populate('get', options);

      this.$set(this, 'hardware', hardware);
      this.$set(this, 'hardwareCloned', cloneDeep(hardware));
      return this.hardware;
    },

    async validate () {
      const validationArray = [];
      validationArray.push(this.$refs.hardwareForm.$v);

      if (validationArray.length > 0) {
        validationArray.forEach(v => v && v.$touch && v.$touch());
        if (validationArray.filter(v => v && v.$invalid).length !== 0) return false;
      }

      return true;
    },
    async submitForm () {
      if (await this.validate() === true) {
        // Set serial
        if (this.hardwareCloned.serial.substr(0, 4) !== 'CSID') this.hardwareCloned.serial = `CSID${this.hardwareCloned.serial}`;

        // Then, update the fields of the device
        const fields = ['name', 'serial', 'eoc_enabled'];
        const mutations = mutationsLib.create(fields, this.hardware.formatBody(), this.hardwareCloned.formatBody());
        if (mutations.length) {
          await this.hardware.update(mutations);
          this.$set(this, 'hardwareCloned', cloneDeep(this.hardware));
        }
      }
    }
  }
};
</script>
