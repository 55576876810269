<template>
  <div>

    <div class="row form-group">
      <div class="col-12">
        <label>Naam <sup>Verplicht</sup></label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i><font-awesome-icon :icon="['fad', 'text']" /></i>
            </span>
          </div>
          <input
            v-model.trim="$v.credit_product.name.$model"
            :class="['form-control', {'is-invalid': $v.credit_product.name.$error}]"
            :disabled="disabled"

            type="text"
          >
        </div>
      </div>
    </div>

    <KSeparator large />

    <div class="row form-group">
      <div class="col-12 col-md-4">
        <label>Artikelnummer</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i><font-awesome-icon :icon="['fad', 'hashtag']" /></i>
            </span>
          </div>
          <input
            v-model="$v.credit_product.article_number.$model"
            :class="['form-control', {'is-invalid': $v.credit_product.article_number.$error}]"
            :disabled="disabled"

            type="text"
          >
        </div>
      </div>

      <div class="col-12 mt-3 col-md-4 mt-md-0">
        <label>Barcode (EAN)</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i><font-awesome-icon :icon="['fad', 'barcode']" /></i>
            </span>
          </div>
          <input
            v-model="$v.credit_product.EAN_code.$model"
            :class="['form-control', {'is-invalid': $v.credit_product.EAN_code.$error}]"
            :disabled="disabled"

            type="text"
          >
        </div>
      </div>

      <permission-groups-select
        ref="permissionGroupsSelect"

        :product="credit_product"
        :disabled="disabled"

        class="col-12 mt-3 col-md-4 mt-md-0"
      />
    </div>

    <KSeparator large />

    <div class="row form-group">
      <div class="col-12 col-md-3">
        <label>Verhoog tegoed met <sup>Verplicht</sup></label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i><font-awesome-icon :icon="['fad', 'credit-card']" /></i>
            </span>
          </div>
          <input
            v-model.number.lazy="$v.credit_product.credits_formatted.$model"
            :class="['form-control', {'is-invalid': $v.credit_product.credits_formatted.$error}]"
            :disabled="disabled"

            type="number"
          >
        </div>
      </div>

      <div class="col-6 mt-3 col-md-3 mt-md-0 offset-md-1">
        <label>Prijs excl BTW <sup>Verplicht</sup></label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i><font-awesome-icon :icon="['fad', 'euro-sign']" /></i>
            </span>
          </div>
          <input
            v-model.number.lazy="$v.credit_product.price_formatted.$model"
            :class="['form-control', {'is-invalid': $v.credit_product.price_formatted.$error}]"
            :disabled="disabled"

            type="number"
          >
        </div>
      </div>

      <vat-select
        ref="vatSelect"

        :product="credit_product"
        :disabled="isDisabled"

        class="col-6 mt-3 col-md-2 mt-md-0"
      />

      <div class="col-12 mt-3 col-md-3 mt-md-0">
        <label>Prijs incl BTW <sup>Verplicht</sup></label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i><font-awesome-icon :icon="['fad', 'euro-sign']" /></i>
            </span>
          </div>
          <input
            v-model.number.lazy="$v.credit_product.price_incl_vat_formatted.$model"
            :class="['form-control', {'is-invalid': $v.credit_product.price_incl_vat_formatted.$error}]"
            :disabled="disabled"

            type="number"
          >
        </div>
      </div>
    </div>

    <KSeparator large />

    <div class="row form-group">
      <div class="col-12 col-md-4 offset-md-4">
        <div class="row">
          <div class="col-3 col-md-12 text-md-center">
            <span :class="['kt-switch', {'kt-switch--success': (credit_product.amount_changeable === true)}]">
              <label>
                <input
                  v-model="credit_product.amount_changeable"

                  :disabled="isDisabled"

                  type="checkbox"
                >
                <span />
              </label>
            </span>
          </div>
          <label class="col-9 col-md-12 text-md-center col-form-label">Aantal aanpasbaar</label>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
// Components
import vatSelect from '@/components/products/components/vat.select';
import permissionGroupsSelect from '@/components/products/components/permission-groups.select';

// Classes
import CreditProduct from '@/libs/classes/credit_product';

// Validations
import { required, minValue } from 'vuelidate/lib/validators';
import { currency } from '@/libs/validators';

export default {
  components: {
    vatSelect,
    permissionGroupsSelect
  },
  props: {
    action: {
      type: String,
      default: 'CREATE'
    },

    credit_product: {
      type: CreditProduct,
      default: null
    },

    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    isDisabled () {
      if (this.action === 'VIEW') {
        return true;
      }

      if (this.disabled === true) {
        return true;
      }

      return false;
    }
  },
  watch: {
    'credit_product.vat_code' (newVal, oldVal) {
      let VATCode = this.$refs.vatSelect.vatCodes.find(c => c.code === newVal);
      if (typeof VATCode !== typeof undefined) {
        this.credit_product.price.vat_percentage = VATCode.value;
      }
    }
  },
  validations: {
    credit_product: {
      name: {
        required
      },
      vat_code: {
        required
      },

      article_number: {

      },
      EAN_code: {

      },

      credits_formatted: {
        required,
        minValue: minValue(0),
        currency
      },
      price_formatted: {
        required,
        minValue: minValue(0),
        currency
      },
      VAT: {
        required
      },
      price_incl_vat_formatted: {
        required,
        minValue: minValue(0),
        currency
      }
    }
  },

  methods: {
    async validate () {
      const validationArray = [];
      validationArray.push(this.$v, this.$refs.vatSelect.$v);

      if (validationArray.length > 0) {
        validationArray.forEach(v => v && v.$touch && v.$touch());
        if (validationArray.filter(v => v && v.$invalid).length !== 0) return false;
      }

      return true;
    }
  }
};
</script>
