<template>
  <div
    v-if="isMounted === false"
    class="row"
  >
    <div class="col-12">
      <div class="loading-container">
        <div class="loading-block">
          <div class="blockui ml-auto mr-auto">
            <span>Gegevens ophalen...</span>
            <span class="kt-spinner kt-spinner--v2 kt-spinner--primary" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-else>
    <div
      v-if="isLoading === true"
      class="loading-container absolute"
    >
      <div class="loading-backdrop">
        <div class="loading-block">
          <div class="blockui">
            <span>Even geduld a.u.b...</span>
            <span class="kt-spinner kt-spinner--v2 kt-spinner--primary" />
          </div>
        </div>
      </div>
    </div>

    <!-- Select payment method -->
    <div :class="['row', {'border-bottom pb-3': session.payment_method}]">
      <div class="col-12 col-md-4">
        <KButton
          :variant="paymentButtonVariant('PACKAGE')"
          :disabled="(bundles.length === 0)"
          class="btn-block btn-lg"
          @click.native.prevent="selectPaymentMethod('PACKAGE')"
        >
          <span class="d-block">
            <font-awesome-icon
              :icon="['fad', 'box']"
              size="2x"
            />
          </span>
          <span class="d-block">Pakket</span>
        </KButton>
      </div>

      <div class="col-12 mt-3 col-md-4 mt-md-0">
        <KButton
          :variant="paymentButtonVariant('SESSION_CREDIT')"
          :disabled="(equipment.group.accept_credits === false || equipment.group.package_only === true)"
          class="btn-block btn-lg"
          @click.native.prevent="selectPaymentMethod('SESSION_CREDIT')"
        >
          <span class="d-block">
            <font-awesome-icon
              :icon="['fad', 'credit-card']"
              size="2x"
            />
          </span>
          <span class="d-block">Sessiekrediet</span>
        </KButton>
      </div>

      <div class="col-12 mt-3 col-md-4 mt-md-0">
        <KButton
          :variant="paymentButtonVariant('SINGLE_SESSION')"
          :disabled="equipment.group.package_only === true"
          class="btn-block btn-lg"
          @click.native.prevent="selectPaymentMethod('SINGLE_SESSION')"
        >
          <span class="d-block">
            <font-awesome-icon
              :icon="['fad', 'repeat-1']"
              size="2x"
            />
          </span>
          <span class="d-block">Losse sessie</span>
        </KButton>
      </div>
    </div>

    <!-- Configure session -->
    <div
      v-if="session.payment_method !== null"
      class="row mt-3"
    >
      <template
        v-if="(session.payment_method === 'SINGLE_SESSION' || session.payment_method === 'SESSION_CREDIT') && (!sessions || sessions.length === 0)"
      >
        <div class="col-12">
          <KAlert
            :icon="['fad', 'exclamation-triangle']"
            variant="outline-caution"
          >
            Geen sessies beschikbaar
          </KAlert>
        </div>
      </template>

      <template v-else>
        <template v-if="session.payment_method === 'PACKAGE'">
          <div class="col-12 col-lg-4">
            <KButton
              v-for="(bundle) in sessionBundles"
              :variant="bundleVariant(bundle.bundle.guid, bundle.guid)"
              :key="`customer.bundle.${bundle.bundle.guid}.${bundle.guid}`"
              :class="['btn-block btn-lg position-relative', {'disabled': bundle.available === false}]"
              style="height:100px;"
              @click.prevent.native="selectBundle(bundle.bundle.guid, bundle.guid)"
            >
              <div
                class="row position-absolute w-100"
                style="left:10px; top: 5px;"
              >
                <div class="col-12">
                  <div class="row border-bottom pb-1">
                    <div class="col-10 text-left overflow-ellipsis">
                      {{ bundle.bundle.name }}
                    </div>
                    <div class="col text-right">
                      <i
                        v-if="bundle.available === false"
                        class="pr-0 kt-font-caution"
                      >
                        <font-awesome-icon :icon="['far', 'exclamation-triangle']" />
                      </i>
                    </div>
                  </div>

                  <div
                    class="row text-left mt-1"
                    style="font-size:12px;"
                  >
                    <div class="col-12">
                      <template v-if="(bundle.session_limit.limit === null || bundle.session_limit.limit === 0)">
                        <strong>Ongelimiteerd</strong> aantal sessies beschikbaar
                      </template>

                      <template v-else>
                        <strong>{{ bundle.session_limit.available }}</strong>/{{ bundle.session_limit.limit }} {{ (bundle.session_limit.limit === 1 ? 'sessie' : 'sessies') }} beschikbaar
                      </template>
                    </div>
                    <div class="col-12">
                      <template v-if="(bundle.bundle.expiry_timestamp === null || bundle.bundle.expiry_timestamp === 0)">
                        Ongelimiteerd geldig
                      </template>

                      <template v-else>
                        Van {{ moment(bundle.bundle.start_timestamp).format('DD-MM-YYYY') }} tot {{ moment(bundle.bundle.expiry_timestamp).format('DD-MM-YYYY') }}
                      </template>
                    </div>
                    <div
                      v-if="(bundle.session_limit.last_session && bundle.session_limit.last_session !== 0)"
                      class="col-12"
                    >
                      Laatst gebruikt op {{ moment(bundle.session_limit.last_session).format('DD-MM-YYYY') }}
                    </div>
                  </div>
                </div>
              </div>
            </KButton>
          </div>
        </template>

        <div
          :class="[{
            'col-12 col-lg-8 offset-lg-2': (session.payment_method === 'SINGLE_SESSION' || session.payment_method === 'SESSION_CREDIT')
          }, {
            'col-12 col-lg-8': (session.payment_method === 'PACKAGE')
          }]"
        >
          <!-- Bundle errors -->
          <div v-if="(session.payment_method === 'PACKAGE' && bundle && bundle.available === false)">
            <KAlert
              v-for="(reason) in bundle.not_available_reasons"
              :key="`bundle.${bundle.bundle.guid}.${bundle.guid}.not_available_reasons.${reason}`"
              :variant="bundleReason(reason).variant"
              :icon="bundleReason(reason).icon"
              class="mb-2"
            >
              {{ bundleReason(reason).text }}
            </KAlert>
          </div>

          <!-- Time picker -->
          <div
            v-else-if="(session.payment_method === 'SINGLE_SESSION' || session.payment_method === 'SESSION_CREDIT') || (session.payment_method === 'PACKAGE' && bundle && bundle.available === true)"
            class="row form-group mt-auto mb-auto"
          >
            <!-- Arrow up -->
            <div class="col-12 text-center">
              <button
                class="btn kt-font-anthracite"
                @click.prevent="sessionPicker('+')"
              >
                <font-awesome-icon
                  :icon="['far', 'chevron-up']"
                  size="5x"
                />
              </button>
            </div>

            <!-- Session details -->
            <div class="col-12 mt-2 mb-2 pt-4 pb-3 border-top border-bottom">
              <div
                v-if="session"
                class="row"
              >
                <div class="col-8 col-lg-8 text-lg-right col-xl-8 text-xl-center offset-xl-2">
                  <h2>{{ currentSession.session_name }}</h2>
                </div>
                <div class="col-4 col-lg-3 offset-lg-1 col-xl-2 offset-xl-0 d-flex flex-row justify-content-between">
                  <div class="text-left">
                    <h2 :class="[{'text-danger': insufficientFunds === true}]">
                      <i v-if="session.payment_method === 'SESSION_CREDIT'"><font-awesome-icon :icon="['fad', 'credit-card']" /></i>
                      <span v-else-if="session.payment_method === 'SINGLE_SESSION'">&euro;</span>
                    </h2>
                  </div>
                  <div class="text-right">
                    <h2
                      v-if="session.payment_method !== 'PACKAGE'"
                      :class="[{'text-danger': insufficientFunds === true}]"
                    >
                      {{ $format.currency((session.payment_method === 'SESSION_CREDIT' ? currentSession.price.credit_price : currentSession.price.price_incl_vat)) }}
                    </h2>
                  </div>
                </div>
              </div>
            </div>

            <!-- Arrow down -->
            <div class="col-12 text-center">
              <button
                class="btn kt-font-anthracite"
                @click.prevent="sessionPicker('-')"
              >
                <font-awesome-icon
                  :icon="['far', 'chevron-down']"
                  size="5x"
                />
              </button>
            </div>
          </div>
        </div>

      </template>

    </div>
  </div>
</template>

<script>
// Libraries
import moment from 'moment-timezone';

// Classes
import Equipment from '@/libs/classes/equipment';
import SalesOrder from '@/libs/classes/salesorder';
import SessionProduct from '@/libs/classes/session_product';

export default {
  props: {
    equipment: {
      type: Equipment,
      default: null
    },
    sales_order: {
      type: SalesOrder,
      default: null
    },

    session: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      isMounted: false,
      sessions: [],
      bundles: [],
      bundle: null,

      isLoading: false,

      moment
    };
  },
  computed: {
    currentSession: {
      get () {
        // Current session when SINGLE_SESSION or SESSION_CREDIT
        if (this.session.payment_method === 'SINGLE_SESSION' || this.session.payment_method === 'SESSION_CREDIT') {
          if (typeof this.session.guid === 'string' && this.session.guid.length > 0) {
            let session = this.sessions.find(s => s.guid === this.session.guid);
            return session;
          }
        }

        // Current session when PACKAGE
        if (this.session.payment_method === 'PACKAGE') {
          // Fool the system
          const session = new SessionProduct().mergeResponse({
            duration: this.session.session_duration,
            default: true
          });

          return session;
        }

        return null;
      }
    },

    insufficientFunds: {
      get () {
        if (this.currentSession) {
          if (this.session.payment_method === 'SESSION_CREDIT') {
            if (this.sales_order && this.sales_order.customer && this.sales_order.customer.balance) {
              if ((this.sales_order.customer.balance.session_credits) >= this.currentSession.price.credit_price) {
                return false;
              }
            }
            return true;
          }
        }
        return false;
      }
    },

    // Bundles
    availableBundles: {
      get () {
        if (this.bundles && this.bundles.length > 0) {
          return this.bundles.filter(b => b.available === true);
        }
        return [];
      }
    },
    sessionBundles: {
      get () {
        // Sort bundles
        let bundles = this.sortBundles();

        return bundles;
      }
    }
  },

  async mounted () {
    // Check for guid in $props.session and clear it
    if (typeof this.session !== 'object') this.session = {};
    this.selectSession(null);

    // Get session products
    try {
      await this.getSessionProducts();

      // Get equipment group if not found
      if (!this.equipment.group) {
        this.equipment.group = await this.equipment.getGroup();
      }

      // Get active bundles of customer
      this.bundles = await this.sales_order.customer.getBundlesForEquipmentGroup(this.equipment.group.guid, {
        query: {
          salesorder_guid: this.sales_order.guid
        }
      });
      this.bundles = this.sortBundles();
    } catch (e) {
      console.error('Error fetching data', e);
      return;
    }

    this.$nextTick(() => {
      // Default payment method
      this.selectPaymentMethod('SINGLE_SESSION');

      // If customer session credit balance is not 0, use session credit
      if (this.equipment.group && this.equipment.group.accept_credits === true) {
        if (this.sales_order && this.sales_order.customer) {
          if (this.sales_order.customer.balance && this.sales_order.customer.balance.session_credits && this.sales_order.customer.balance.session_credits !== 0) {
            this.selectPaymentMethod('SESSION_CREDIT');
          }
        }
      }

      // If customer has packages, select package
      if (this.equipment.group.package_only === true || this.availableBundles.length > 0) {
        this.selectPaymentMethod('PACKAGE');
      }

      this.isMounted = true;
    });
  },

  methods: {
    async getSessionProducts (options = {}) {
      this.$set(this, 'sessions', []);
      let products = await this.equipment.getSessionProducts(options);
      this.$set(this, 'sessions', products);
      return this.sessions;
    },

    selectSession (session) {
      // Clear session details
      this.session.name = null;
      this.session.guid = null;
      this.session.bundle_guid = null;
      this.session.bundle_session_guid = null;
      this.session.session_duration = null;

      if (session) {
        // Get session when SINGLE_SESSION or SESSION_CREDIT
        if (this.session.payment_method === 'SINGLE_SESSION' || this.session.payment_method === 'SESSION_CREDIT') {
          if (typeof session === 'string' && session.length > 0) {
            let sessionObject = this.sessions.find(s => s.guid === session);
            if (typeof sessionObject !== typeof undefined) {
              this.session.name = `${sessionObject.session_name} voor ${this.equipment.name} (${this.equipment.room})`;
              this.session.guid = sessionObject.guid;
            }
          }
          return this.session;
        }

        // Get session when PACKAGE
        if (this.session.payment_method === 'PACKAGE') {
          this.session.bundle_guid = this.bundle.bundle.guid;
          this.session.bundle_session_guid = session.guid;
          this.session.session_duration = session.duration;
          this.session.name = `${session.session_name} voor ${this.equipment.name} (${this.equipment.room})`;

          return this.session;
        }
      }

      return null;
    },
    selectPaymentMethod (paymentMethod) {
      if (paymentMethod === 'SINGLE_SESSION' || paymentMethod === 'SESSION_CREDIT') {
        this.session.payment_method = paymentMethod;

        // Set session
        if (this.sessions && this.sessions.length > 0) {
          if (!this.session.guid) {
            let defaultSession = this.getDefaultSession();
            this.selectSession(defaultSession.guid);
          }
        }
      }

      if (paymentMethod === 'PACKAGE') {
        this.session.payment_method = paymentMethod;

        let defaultBundle = this.getDefaultBundle();
        this.selectBundle(defaultBundle.bundle.guid, defaultBundle.guid);
      }

      return this.session.payment_method;
    },
    selectBundle (bundleGuid, sessionGuid) {
      if (typeof bundleGuid === 'string' && bundleGuid.length > 0) {
        let bundle = this.bundles.find(b => b.bundle.guid === bundleGuid && b.guid === sessionGuid);
        if (typeof bundle !== typeof undefined) {
          // Set bundle
          this.bundle = bundle;

          // Set sessions
          this.bundle.sessions = [];
          for (let duration = (bundle.session_time.from / 60); duration <= (bundle.session_time.to / 60); duration++) {
            this.bundle.sessions.push(new SessionProduct().mergeResponse({
              guid: bundle.guid,
              duration: (duration * 60),
              default: true
            }));
          }

          if (bundle.available === true) {
            // Get default session
            let defaultSession = this.getDefaultSession();
            this.selectSession(defaultSession);
          } else {
            // Remove session details, because bundle is not available
            this.selectSession(null);
          }
        }
      }
      return this.session.bundle_guid;
    },

    // Variants
    paymentButtonVariant (paymentMethod) {
      if (paymentMethod === this.session.payment_method) return 'info';
      return 'outline-info';
    },
    bundleVariant (bundleGuid, sessionGuid) {
      if (this.bundle) {
        if (this.bundle.bundle.guid === bundleGuid && this.bundle.guid === sessionGuid) return 'info';
      }
      return 'outline-info';
    },

    sessionPicker (action = null) {
      if (action === '+' || action === '-') {
        // Get current sessions sorted
        const sessions = this.sortSessions();

        // Action based on payment method
        if (this.session.payment_method === 'SINGLE_SESSION' || this.session.payment_method === 'SESSION_CREDIT') {
          const sessionIdx = this.sessions.indexOf(this.currentSession);
          if (action === '+' && typeof sessions[(sessionIdx + 1)] !== typeof undefined) {
            this.selectSession(sessions[(sessionIdx + 1)].guid);
          } else if (action === '-' && typeof sessions[(sessionIdx - 1)] !== typeof undefined) {
            this.selectSession(sessions[(sessionIdx - 1)].guid);
          }
        }

        if (this.session.payment_method === 'PACKAGE') {
          const sessionIdx = sessions.findIndex(s => s.guid === this.bundle.guid && s.duration === this.session.session_duration);
          if (action === '+' && typeof sessions[(sessionIdx + 1)] !== typeof undefined) {
            this.selectSession(sessions[(sessionIdx + 1)]);
          } else if (action === '-' && typeof sessions[(sessionIdx - 1)] !== typeof undefined) {
            this.selectSession(sessions[(sessionIdx - 1)]);
          }
        }
      }

      return this.session.guid;
    },

    // Sorting
    sortSessions () {
      if (this.session.payment_method === 'SINGLE_SESSION' || this.session.payment_method === 'SESSION_CREDIT') {
        const sessions = this.sessions = this.sessions.sort((a, b) => {
          // If duration is the same, check which one is default
          if (a.duration === b.duration) {
            // If default is same, check by name
            if (a.default === b.default) {
              if (a.name < b.name) return 1;
              else if (a.name > b.name) return -1;
              return 0;
            }

            // Default sessions first
            if (a.default === true && b.default === false) return -1;
            else if (a.default === false && b.default === true) return 1;
            return 0;
          }

          // Check by duration
          if (a.duration > b.duration) return 1;
          else if (a.duration < b.duration) return -1;
          else return 0;
        });
        return sessions;
      } else if (this.session.payment_method === 'PACKAGE') {
        const sessions = this.bundle.sessions.sort((a, b) => {
          if (a > b) return 1;
          else if (a < b) return -1;
          else return 0;
        });
        return sessions;
      }

      return [];
    },
    sortBundles () {
      if (this.bundles && this.bundles.length > 0) {
        const bundles = Object.assign([], this.bundles).sort((a, b) => {
          // If available is the same, check which one is default
          if (a.available === b.available) {
            // Check expiry
            if (a.bundle.expiry_timestamp === 0) return 1;
            if (a.bundle.expiry_timestamp > b.bundle.expiry_timestamp) return 1;
            if (a.bundle.expiry_timestamp < b.bundle.expiry_timestamp) return -1;
            return 0;
          }

          // Check by available
          if (a.available === false && b.available === true) return 1;
          else if (a.available === true && b.available === false) return -1;
          else return 0;
        });

        return bundles;
      }

      return [];
    },

    // Default
    getDefaultSession () {
      if (this.session.payment_method === 'SINGLE_SESSION' || this.session.payment_method === 'SESSION_CREDIT') {
        let sessions = this.sortSessions();
        if (typeof this.equipment.default_session_guid === 'string' && this.equipment.default_session_guid.length > 0) {
          let session = sessions.find(s => s.guid === this.equipment.default_session_guid);
          if (typeof session !== typeof undefined) {
            return session;
          }
        }
        return sessions[0];
      } else if (this.session.payment_method === 'PACKAGE') {
        let sessions = this.sortSessions();
        let bundle = this.bundles.find(b => b.bundle.guid === this.bundle.bundle.guid && b.guid === this.bundle.guid);
        if (typeof bundle !== typeof undefined && bundle.session_time && typeof bundle.session_time.default === 'number') {
          let session = sessions.find(s => s.guid === bundle.guid && s.duration === bundle.session_time.default);
          if (typeof session !== typeof undefined) {
            return session;
          }
        }
        return sessions[0];
      }

      return null;
    },
    getDefaultBundle () {
      if (this.bundles && this.bundles.length > 0) {
        const bundles = this.sortBundles();
        return bundles[0];
      }
      return null;
    },

    bundleReason (reason = null) {
      // Default returnObject
      const returnObject = {
        variant: 'outline-caution',
        icon: ['fad', 'question'],
        text: `Unknown reason (${reason.reason})`
      };

      if (reason && reason.reason) {
        // BUNDLE_EXPIRED
        if (reason.reason === 'BUNDLE_EXPIRED') {
          returnObject.icon = ['fad', 'calendar'];
          returnObject.text = 'Pakket is verlopen';
        }

        // SESSION_LIMIT_EXCEEDED
        if (reason.reason === 'SESSION_LIMIT_EXCEEDED') {
          returnObject.icon = ['fad', 'hashtag'];
          returnObject.text = 'Alle sessies zijn gebruikt';
        }

        // SESSION_LIMIT_INTERVAL
        if (reason.reason === 'SESSION_LIMIT_INTERVAL') {
          returnObject.icon = ['fad', 'clock'];
          returnObject.text = 'Interval tussen sessies';
        }

        // SESSION_TIME_NOT_AVAILABLE
        if (reason.reason === 'SESSION_TIME_NOT_AVAILABLE') {
          returnObject.icon = ['fad', 'calendar'];
          returnObject.text = 'Pakket mag op dit tijdstip niet gebruikt worden';
        }
      }
      return returnObject;
    }
  }
};
</script>
