<template>
  <KPortlet>
    <KPortletHead>
      <template v-slot:default>Producten</template>
    </KPortletHead>
    <KPortletBody>

      <div class="row">
        <div class="col-12">
          <div class="kt-input-icon kt-input-icon--left">
            <input
              type="text"
              class="form-control"
              placeholder="Search..."
              @keyup.enter="$refs['datatable'].search"
              @keyup="searchDebounced"
            >
            <span class="kt-input-icon__icon kt-input-icon__icon--left">
              <span>
                <i>
                  <font-awesome-icon
                    :icon="['far', 'search']"
                  />
                </i>
              </span>
            </span>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-12 datatable datatable-wrapper">
          <datatable
            ref="datatable"
            :url="datatable.url"
            :fields="datatable.fields"
            :search-fields="datatable.searchFields"
            :on-pagination-data="onPaginationData"
            :transform="transform"
          >
            <!-- Actions -->
            <div
              slot="actions"
              slot-scope="props"
              class="datatable-actions"
            >
              <router-link
                v-if="$auth.hasPermission('get_products')"
                :to="`/products/view/${props.rowData.guid}`"
              >
                <KButton
                  :icon="['fad', 'eye']"
                  tag="a"
                  variant="label-brand"
                />
              </router-link>

              <router-link
                v-if="$auth.hasPermission('update_products')"
                :to="`/products/view/${props.rowData.guid}/settings`"
              >
                <KButton
                  :icon="['fad', 'edit']"
                  tag="a"
                  variant="label-brand"
                />
              </router-link>

              <KButton
                v-if="$auth.hasPermission('remove_products')"
                :icon="['fad', 'trash']"

                tag="a"
                variant="label-brand"
                disabled
              />
            </div>
          </datatable>

          <div class="row pagination">
            <div class="col-xs-12 col-md-6">
              <datatable-pagination
                ref="pagination"
                @vuetable-pagination:change-page="onChangePage"
              />
            </div>

            <div class="col-xs-12 col-md-6">
              <datatable-pagination-info
                ref="pagination.info"
                @vuetable-paginationinfo:change-rowcount="onChangeRowCount"
              />
            </div>
          </div>
        </div>
      </div>

    </KPortletBody>
  </KPortlet>
</template>

<script>
// Classes
import Product from '@/libs/classes/product';
import ProductGroup from '@/libs/classes/product_group';

// Mixins
import datatable from '@/mixins/datatable';

export default {
  mixins: [datatable],
  props: {
    group: {
      type: ProductGroup,
      default: null
    }
  },
  data () {
    return {
      datatable: {
        url: `/product_groups/${this.$route.params.guid}/products`,
        fields: [{
          title: 'Naam',
          name: 'name',
          sortField: 'name',
          dataClass: 'w-1/4'
        }, {
          name: '__slot:actions',
          title: '',
          titleClass: 'text-right',
          dataClass: 'text-right'
        }],

        searchFields: ['name']
      }
    };
  },
  methods: {
    transform (data) {
      if (data.data && Array.isArray(data.data)) {
        data.data = data.data.map((row) => {
          return new Product().mergeResponse(row);
        });
      }
      return data;
    }
  }
};
</script>
