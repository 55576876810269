import Abstract from './abstract';

import { merge } from 'lodash';

class EquipmentGroup extends Abstract {
  constructor (guid = null) {
    super(guid);
    this.uri = '/equipment_groups';

    let defaultObject = {
      name: null,

      pre_run_time: 300,
      post_run_time: 180,

      quickstart: false,
      door_contact: false,
      clean_after: false,

      package_only: false,
      accept_credits: true
    };

    merge(this, defaultObject);
  }
}

export default EquipmentGroup;
