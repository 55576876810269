<template>
  <b-modal
    ref="modal"
    size="xl"
    centered

    no-close-on-esc
    no-close-on-backdrop
    hide-header-close
  >
    <template v-slot:modal-title>
      {{ (options.title ? options.title : 'Sessies') }}
    </template>

    <template v-slot:default>
      <!-- Authentication PIN row -->
      <div class="row">
        <!-- Alert -->
        <div class="col-12">
          <KAlert
            :icon="['fad', 'info-circle']"
            variant="outline-info"
            class="mb-0"
          >
            Uw sessie is geblokkeerd wegens inactiviteit.<br>
            Voer hieronder uw persoonlijke PIN in om uw sessie te hervatten.
          </KAlert>
        </div>

        <!-- PIN -->
      </div>

      <!-- Resources row -->
      <div class="row mt-3">
        <div class="col-8 offset-lg-4 col-lg-4">
          <label>Persoonlijke PIN</label>
          <div class="input-group input-group-md">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i><font-awesome-icon :icon="['fad', 'lock']" /></i>
              </span>
            </div>
            <input
              ref="pincodeInput"

              v-model.lazy.trim="$v.pin.pincode.$model"
              :class="['form-control pin-input', {'is-invalid': $v.pin.pincode.$error}]"
              :disabled="pin.isLoading"

              type="password"
              minlength="4"
              maxlength="6"

              @keyup.enter="submitForm"
            >
            <div
              v-if="pin.isLoading"
              class="input-group-append"
            >
              <span class="kt-spinner kt-spinner--v2 kt-spinner--focus" />
            </div>
          </div>
        </div>

        <div class="col-4 col-lg-2 label-margin">
          <KButton
            :icon="['far', 'sign-in']"
            :disabled="pin.isLoading"

            variant="focus"
            class="btn-pin"

            @click.native.prevent="submitForm"
          />
        </div>
      </div>
    </template>

    <template v-slot:modal-footer>
      <KButton
        variant="focus"
        @click.native.prevent="logout"
      >
        Uitloggen
      </KButton>
    </template>
  </b-modal>
</template>

<script>
// Components
import Modal from '@/mixins/modal';

// Validations
import { required } from 'vuelidate/lib/validators';

export default {
  mixins: [Modal],
  data () {
    return {
      options: null,

      pin: {
        isLoading: false,
        pincode: null
      }
    };
  },
  validations: {
    pin: {
      pincode: {
        required
      }
    }
  },

  mounted () {
    if (this.$auth.sessionLocked) {
      this.show({
        title: 'Sessie geblokkeerd'
      });
    }
    // Listeners
    this.$eventhub.on('modals:auth:locked-session:open', this.show);
    this.$eventhub.on('modals:auth:locked-session:close', this.hide);
  },
  beforeDestroy () {
    // Remove listeners
    this.$eventhub.off('modals:auth:locked-session:open', this.show);
    this.$eventhub.off('modals:auth:locked-session:close', this.hide);
  },

  methods: {
    // General methods (like show & hide are in the modal mixin)
    show (options = {}) {
      // Set options to null again
      this.$set(this, 'options', null);
      this.$set(this.pin, 'pincode', null);

      // Check for options before showing modal
      if (typeof options === 'object') {
        // Set options to this.options, for future reference
        this.$set(this, 'options', options);

        // Show modal
        Modal.methods.show.call(this);
      }
    },
    onShown () {
      this.$refs.pincodeInput.focus();
    },

    async validate () {
      const validationArray = [];

      validationArray.push(this.$v);

      // Execute validations
      if (validationArray.length > 0) {
        validationArray.forEach(v => v && v.$touch && v.$touch());
        if (validationArray.filter(v => v && v.$invalid).length !== 0) return false;
      }

      return true;
    },
    async submitForm () {
      if (await this.validate() === true) {
        // Set loading
        this.$set(this.pin, 'isLoading', true);

        // Try to authenticate
        let hasError = false;
        try {
          const response = await this.$ws.post('v1', `/auth/unlock-session`, {
            body: {
              pincode: this.pin.pincode
            },
            unlock_session: true
          });

          if (response.authenticated !== true) {
            throw this.$error.createError('Authentication failed', {
              authenticated: false
            });
          }
        } catch (e) {
          console.error(e);

          if (e.source === 'frontend' && e.authenticated === false) {
            this.$eventhub.emit('modals:error:open', {
              title: 'Authenticatie mislukt',
              error: 'De ingevoerde persoonlijke PIN is onjuist'
            });
          } else {
            this.$error.showModal(e);
          }

          hasError = true;
        }

        // Set loading
        this.$set(this.pin, 'isLoading', false);
        this.$set(this.pin, 'pincode', null);

        if (!hasError) {
          this.$v.pin.pincode.$reset();

          this.$auth.sessionLocked = false;
          this.$ws.removeSessionLockedPromise();
          await this.$auth.authenticate();

          this.hide();
          this.$eventhub.emit('auth:session:unlocked', true);

          // Added onAuthenticated function
          if (this.options && typeof this.options.onAuthenticated === 'function') {
            await this.options.onAuthenticated();
          }
        }
      }
    },

    async logout () {
      this.$auth.logout();
      this.hide();
    }
  }
};
</script>
