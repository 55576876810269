import Vue from 'vue';
import VueI18n from 'vue-i18n';

import Localizations from '@/libs/localization/index';

Vue.use(VueI18n);

export default new VueI18n({
  locale: 'nl',
  messages: Localizations,
  fallbackLocale: 'en'
});
