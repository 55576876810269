// Libraries
import shortid from 'shortid';

import auth from '@/libs/auth';
import stores from '@/libs/stores';
import eventhub from '@/libs/eventhub';
import { ApiError } from '@/libs/error';
import Logger from '@/libs/logger';
import network from '@/libs/network';

const log = new Logger('WS');

// Will contain the default socket to the API server
// Defined by CreateApiWS, which is invoked in main.js
export let apiWS = null;

let customApiUrl = null;

export class CreateApiWS {
  constructor () {
    if (apiWS === null) {
      // Set full hostname of ws
      const wsHostname = (network.secureProtocol ? 'wss:' : 'ws:') + '//' + (customApiUrl || network.apiWS || 'localhost:8001');
      apiWS = new WS(wsHostname);
    }
    return apiWS;
  }
}

export default class WS {
  constructor (hostname, options = {}) {
    this.hostname = hostname;
    this.pendingRequests = {};
    this.options = options;
    this.online = null;
    this.channels = [];
    this.authenticated = false;
    this.deviceSocket = false;

    this.refreshPromise = null;

    this.sessionLockedPromise = null;

    this.sessionLockTimeout = null;
    this.inactiveTimeout = 5 * 60; // Default 5 minutes
  }

  setSessionLockedPromise (promise) {
    this.sessionLockedPromise = promise;
  }

  removeSessionLockedPromise () {
    this.sessionLockedPromise = null;
  }

  setWorkspace (guid, name) {
    this.workspace = guid;
    this.workspaceName = name;
  }

  disconnectAuth () {
    this.write({
      requestId: null,
      type: 'unauthenticate'
    });
    this.authenticated = false;
  }

  initConnection (options = {}) {
    if (this.connection) {
      return;
    }

    this.connection = new Primus(this.hostname, { manual: true });
    // Bind this WS instance the context for this.data
    // Otherwise connection.on will pass Primus instance as context
    this.connection.on('data', this.data.bind(this));

    // Detect state changes and handle events to detect online / offline states
    this.connection.on('open', () => this.handleState(true, 'open'));
    this.connection.on('reconnected', () => this.handleState(true, 'reconnected'));

    this.connection.on('close', () => this.handleState(false, 'close'));
    this.connection.on('timeout', () => this.handleState(false, 'timeout'));
    this.connection.on('destroy', () => this.handleState(false, 'destroy'));
    this.connection.on('end', () => this.handleState(false, 'end'));

    this.connection.on('readyStateChange', (state) => {
      if (state === 'open') this.handleState(true, 'readyStateChange');
      else if (state === 'end') this.handleState(false, 'readyStateChange');
    });
  }

  async handleState (online, event = null) {
    if (this.online !== online) {
      eventhub.emit(`sockets:${this.hostname}:state`, online, this.online);
      this.connection.emit('stateChange', online, this.online);

      // Actions when going (back) online
      if (online === true && this.lastStateEvent && this.handlingOnline !== true) {
        this.handlingOnline = true;
        await auth.authenticate();

        if (this.channels.length) {
          log.debug('Back online with WS, resub to channels:', this.channels);
          this.subscribe(this.channels);
        }
        this.handlingOnline = false;
      }

      // Actions when going offline
      if (online === false) {
        this.authenticated = false;
        // Remove and reject pending requests when going offline
        if (this.pendingRequests && Object.keys(this.pendingRequests).length > 0) {
          Object.keys(this.pendingRequests).forEach((request, idx) => {
            const { reject } = this.pendingRequests[request];
            reject(new ApiError({
              type: 'WS_STATE_OFFLINE'
            }).apierror);
          });
        }
      }

      this.online = online;
      this.lastStateEvent = event;
    }
  }

  async isOnline () {
    return this.online;
  }

  generateRequestId () {
    return shortid.generate();
  }

  async data (message = {}) {
    if (message.type === 'request' && (!message.requestId || !this.pendingRequests[message.requestId])) {
      console.error('Websocket without requestId:', message);
      return;
    }

    if (message.headers) {
      if (message.headers['X-Inactive-Timeout'] || message.headers['X-Inactive-Timeout'] === 0) {
        this.inactiveTimeout = message.headers['X-Inactive-Timeout'];
      }
    }

    if (message.type === 'broadcast') {
      // Sent on eventhub
      eventhub.emit('websocket:broadcast', message);

      // Sent on specific eventhub channel so we don't always have to check the channel on the listener
      eventhub.emit('ws:broadcast:' + message.channel, message);
      return;
    }

    if (message.type === 'authenticate' && message.code === 200) {
      this.authenticated = true;
    }

    if (message.requestId) {
      if (!this.pendingRequests[message.requestId]) {
        console.error('Invalid requestId:', message.requestId);
        return;
      }
      const { resolve, reject } = this.pendingRequests[message.requestId];
      if (message.code === 200) {
        resolve(message.data);
      } else {
        if (message.code === 401) {
          if (message.data.error === 'sessionLocked') {
            // Open locked session modal
            const request = this.pendingRequests[message.requestId];
            const context = this;
            eventhub.emit('modals:auth:locked-session:open', {
              title: 'Sessie geblokkeerd',
              async onAuthenticated () {
                request.resolve(context.request(request.method, request.version, request.endpoint, request.options));
              }
            });
            // Return so we don't reject this request below. Wait till session is unlocked and reply the request
            return;
          }

          if (message.data.error === 'notAuthenticated') {
            if (auth.authData && auth.authData.access_token && auth.authData.refresh_token) {
              // const { method, version, endpoint, options } = this.pendingRequests[message.requestId];
              // TODO: Use refreshToken and repeat request
            }
          }
        }

        reject(new ApiError(message.data).apierror);
      }

      delete this.pendingRequests[message.requestId];
    }
  }

  async subscribe (channels = []) {
    if (Array.isArray(channels) === false) {
      channels = [channels];
    }

    channels.forEach((channel) => {
      if (this.channels.includes(channel) === false) {
        this.channels.push(channel);
      }
    });

    const query = {};
    const activeStore = stores.activeStore;
    if (activeStore) {
      query.store = activeStore._meta.guid;
    }

    await this.write({
      type: 'subscribe',
      channels: channels,
      query: query
    });
  }

  async unsubscribe (channels = []) {
    if (Array.isArray(channels) === false) {
      channels = [channels];
    }

    channels.forEach((channel) => {
      const index = this.channels.indexOf(channel);
      this.channels.splice(index, 1);
    });

    await this.write({
      type: 'unsubscribe',
      channels: channels
    });
  }

  async authenticate () {
    log.debug('\n\n\nRun authenticate');
    if (this.authenticated === true || auth.authenticated !== true) {
      return;
    }

    if (!auth.authData || !auth.authData.access_token) {
      return;
    }

    const requestId = this.generateRequestId();
    log.debug('\n\n\nActually authenticate:', requestId);
    return new Promise(async (resolve, reject) => {
      try {
        await this.write({
          requestId: requestId,
          type: 'authenticate',
          access_token: auth.authData.access_token,
          workspace: this.workspace
        });

        if (!this.pendingRequests) {
          this.pendingRequests = {};
        }
        this.pendingRequests[requestId] = { resolve, reject };
      } catch (e) {
        reject(e);
      }
    }).catch((err) => {
      log.error('Websocket authentication failed:', err);
      return Promise.reject(err);
    });
  }

  async write (payload) {
    this.initConnection();
    if (this.online === null) {
      this.connection.open();
    }

    if (this.deviceSocket !== true && this.authenticated === false && auth.authenticated === true && payload.type !== 'authenticate') {
      // WS is not authenticated, but the frontend is authenticated
      // So let's authenticate our websocket connection
      await this.authenticate();
    }

    this.connection.write(payload);
  }

  async refreshToken () {
    const response = await this.post('v1', '/auth/refresh_token', {
      body: {
        resource_storage: auth.authData.resource_storage,
        resource_guid: auth.authData.resource_guid
      },
      headers: {
        refresh_token: auth.authData.refresh_token
      },
      // Set refresh_token to true in options, so we skip the check if refreshing token is necessary
      refresh_token: true
    });

    if (response && response.access_token) {
      log.debug('Got response from refreshToken:', response);
      auth.setAuthData(response, true);
      this.authenticated = false;
      log.debug('going to authenticate');
      await this.authenticate();
      log.debug('authenticate fn done in refreshToken');
    }
  }

  async request (method = 'get', version = 'v1', endpoint = '/', options = {}) {
    if (this.deviceSocket !== true && !this.workspace && options.workspace_request !== true) {
      const workspace = await this.get('v1', '/workspaces/host/' + window.location.hostname, { workspace_request: true });
      this.setWorkspace(workspace.guid, workspace.name);
      if (!this.workspace) {
        throw new Error('No workspace defined!');
      }
    }

    if (this.sessionLockedPromise && options.unlock_session !== true) {
      await this.sessionLockedPromise;
    }

    log.debug('WS request:', method, endpoint, this.refreshPromise);

    if (this.refreshPromise && options.refresh_token !== true) {
      log.debug('On hold:', method, endpoint, this.refreshPromise);
      await this.refreshPromise;
    }

    if (options.refresh_token !== true && auth.shouldRefresh() === true) {
      log.debug('Should refresh refreshToken:', method, endpoint, this.refreshPromise);
      this.refreshPromise = this.refreshToken();
      await this.refreshPromise;
    }

    log.debug('Gone through:', method, endpoint, this.refreshPromise);

    const url = '/api/' + (this.workspace || 'system') + '/' + version + endpoint;
    if (!options.query) {
      options.query = {};
    }
    if (auth.authData && auth.authData.access_token && options.auth_check_request === true) {
      options.query.access_token = auth.authData.access_token;
    }
    const activeStore = stores.activeStore;
    if (activeStore) {
      options.query.store = activeStore._meta.guid;
    }

    if (this.sessionLockTimeout) {
      clearTimeout(this.sessionLockTimeout);
    }

    let resourceStorage = 'unknown';
    if (auth.resource && auth.resource._meta) {
      resourceStorage = auth.resource._meta.storage;
    }

    if (resourceStorage === 'users' && this.inactiveTimeout > 0) {
      this.sessionLockTimeout = setTimeout(() => {
        eventhub.emit('modals:auth:locked-session:open', {
          title: 'Sessie geblokkeerd'
        });
      }, this.inactiveTimeout * 1000);
    }

    const requestId = this.generateRequestId();
    return new Promise(async (resolve, reject) => {
      try {
        await this.write({
          requestId: requestId,
          type: 'request',
          method: method,
          url: url,
          query: options.query,
          headers: options.headers,
          body: options.body
        });

        if (!this.pendingRequests) {
          this.pendingRequests = {};
        }
        this.pendingRequests[requestId] = { resolve, reject, method, version, endpoint, options };
      } catch (e) {
        reject(e);
      }
    }).catch((err) => {
      log.error('Websocket request failed:', method, url, err, '\nbody:', options.body);
      return Promise.reject(err);
    });
  }

  async get (version = 'v1', endpoint = '/', options = {}) {
    return this.request('get', version, endpoint, options);
  }

  async post (version = 'v1', endpoint = '/', options = {}) {
    return this.request('post', version, endpoint, options);
  }

  async put (version = 'v1', endpoint = '/', options = {}) {
    return this.request('put', version, endpoint, options);
  }

  async patch (version = 'v1', endpoint = '/', options = {}) {
    return this.request('patch', version, endpoint, options);
  }

  async delete (version = 'v1', endpoint = '/', options = {}) {
    return this.request('delete', version, endpoint, options);
  }
}
