<template>
  <KPortlet>
    <KPortletHead>
      Persoonlijke informatie
    </KPortletHead>
    <KPortletBody>
      <form
        class="form-horizontal"
        @submit.prevent="submitForm"
      >
        <!--
          Firstname
          Lastname
          Gender
        -->
        <personalInformationGroup1
          ref="personalInformationGroup1"
          :customer="customerCloned"
          :disabled="isLoading"
        />

        <KSeparator large />

        <!--
          Date of birth
          Citizen service number
          Document number
        -->
        <personalInformationGroup2
          ref="personalInformationGroup2"
          :customer="customerCloned"
          :disabled="isLoading"
        />

        <KSeparator large />

        <!--
          E-mail address
          Telephone number
          Skin type
        -->
        <personalInformationGroup3
          ref="personalInformationGroup3"
          :customer="customerCloned"
          :disabled="isLoading"
        />

        <KSeparator large />

        <!--
          Country
          Postal code
          House number
        -->
        <personalInformationGroup4
          ref="personalInformationGroup4"
          :customer="customerCloned"
          :disabled="isLoading"
        />

        <!--
          Address
          City
        -->
        <personalInformationGroup5
          ref="personalInformationGroup5"
          :customer="customerCloned"
          :disabled="isLoading"
        />

        <div class="row">
          <div class="col-12 text-center">
            <label :class="['kt-checkbox kt-checkbox--bold', {'kt-checkbox--success': customerCloned.marketing_opt_in === true}]">
              <input
                v-model="customerCloned.marketing_opt_in"
                type="checkbox"
              >
              Klant gaat akkoord met ontvangen van marketing-uitingen
              <span />
            </label>
          </div>
        </div>

      </form>
    </KPortletBody>
    <div class="kt-portlet__foot">
      <div class="row align-items-center">
        <div class="offset-6 col-6 offset-lg-9 col-lg-3 kt-align-right">
          <KButton
            :disabled="isLoading"
            variant="warning"
            class="btn-md btn-block btn-tall btn-wide btn-bold btn-upper"
            @click.native.prevent="submitForm"
          >
            Wijzigen
          </KButton>
        </div>
      </div>
    </div>
  </KPortlet>
</template>

<script>
// Libraries
import { cloneDeep } from 'lodash';
import mutationsLib from '@/libs/mutations';

// Classes
import Customer from '@/libs/classes/customer';

// Components
import personalInformationGroup1 from '@/components/customers/components/personal_information/group-1';
import personalInformationGroup2 from '@/components/customers/components/personal_information/group-2';
import personalInformationGroup3 from '@/components/customers/components/personal_information/group-3';
import personalInformationGroup4 from '@/components/customers/components/personal_information/group-4';
import personalInformationGroup5 from '@/components/customers/components/personal_information/group-5';

export default {
  components: {
    personalInformationGroup1,
    personalInformationGroup2,
    personalInformationGroup3,
    personalInformationGroup4,
    personalInformationGroup5
  },
  props: {
    customer: {
      type: Customer,
      default: () => new Customer()
    }
  },
  data () {
    return {
      isLoading: false,
      customerCloned: cloneDeep(this.customer)
    };
  },
  methods: {
    async validate () {
      const validationArray = [];
      validationArray.push(this.$refs.personalInformationGroup1.$v);
      validationArray.push(this.$refs.personalInformationGroup2.$v);
      validationArray.push(this.$refs.personalInformationGroup3.$v);
      validationArray.push(this.$refs.personalInformationGroup4.$v);
      validationArray.push(this.$refs.personalInformationGroup5.$v);

      if (validationArray.length > 0) {
        validationArray.forEach(v => v && v.$touch && v.$touch());
        if (validationArray.filter(v => v && v.$invalid).length !== 0) return false;
      }

      return true;
    },

    async submitForm () {
      if (await this.validate() === true) {
        const fields = ['firstname', 'lastname', 'gender', 'date_of_birth', 'citizenServiceNumber', 'documentNumber', 'email', 'phone', 'skinType', 'marketing_opt_in'];
        fields.push('address.country', 'address.postalCode', 'address.houseNumber', 'address.line1', 'address.city'); // Address

        const mutations = mutationsLib.create(fields, this.customer.formatBody(), this.customerCloned.formatBody());
        if (mutations.length) {
          this.$set(this, 'isLoading', true);
          try {
            await this.customer.update(mutations);
            this.customerCloned = cloneDeep(this.customer);
          } catch (e) {
            console.error(e);
          }
          this.$set(this, 'isLoading', false);
        }
      }
    }
  }
};
</script>
