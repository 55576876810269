<template>
  <div v-if="isMounted">

    <KSubheader
      :breadcrumbs="[{
        page: 'Kassabeheer',
        route: '/cash_registers'
      }, {
        page: 'Kassa aanmaken',
        route: null
      }]"

      title="Kassa aanmaken"
    />

    <KContainer>

      <KPortlet>
        <KPortletHead>
          <template v-slot:default>Kassa aanmaken</template>
        </KPortletHead>
        <KPortletBody>

          <informationForm
            ref="informationForm"

            :cash-register="cashRegister"

            action="CREATE"
          />

        </KPortletBody>
        <div class="kt-portlet__foot">
          <div class="row align-items-center">
            <div class="offset-6 col-6 offset-lg-10 col-lg-2 kt-align-right">
              <KButton
                variant="primary"
                class="btn-md btn-block btn-tall btn-wide btn-bold btn-upper"
                @click.native.prevent="submitForm"
              >
                Aanmaken
              </KButton>
            </div>
          </div>
        </div>
      </KPortlet>

    </KContainer>

  </div>
</template>

<script>
// Classes
import CashRegister from '@/libs/classes/cash_register';

// Components
import informationForm from './components/information.form';

export default {
  components: {
    informationForm
  },
  data () {
    return {
      isMounted: false,

      cashRegister: new CashRegister()
    };
  },

  async mounted () {
    this.$nextTick(() => {
      this.isMounted = true;
    });
  },

  methods: {
    async validate () {
      const validationArray = [];
      validationArray.push(this.$refs.informationForm.$v);

      // Execute validations
      if (validationArray.length > 0) {
        validationArray.forEach(v => v && v.$touch && v.$touch());
        if (validationArray.filter(v => v && v.$invalid).length !== 0) return false;
      }

      return true;
    },
    async submitForm () {
      if (await this.validate() === true) {
        await this.cashRegister.create(this.cashRegister.formatBody());
        this.$router.push(`/cash_registers/view/${this.cashRegister.guid}`);
      }
    }
  }
};
</script>
