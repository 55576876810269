<template>
  <div>
    <KSubheader
      :breadcrumbs="[{
        page: 'Statistieken',
        route: '/statistics'
      }, {
        page: 'Totalen',
        route: '/statistics/totals'
      }]"

      title="Totalen"
    />

    <KContainer>
      <!-- Portlet bar -->
      <KPortlet>
        <KPortletHead>
          <template v-slot:default>
            {{ $stores.activeStore.name }}
          </template>
          <template v-slot:toolbar>
            <KButton
              variant="secondary"
              tag="span"
              class="mr-2 d-print-none"

              @click.native.prevent="printPage"
            >
              <i class="kt-font-focus pr-0">
                <font-awesome-icon :icon="['fas', 'print']" />
              </i>
            </KButton>

            <datepicker
              ref="datepicker"

              @onDatePickerInput="onDatePickerInput"
            />
          </template>
        </KPortletHead>
      </KPortlet>

      <!-- Data -->
      <div class="row">
        <!-- Employees -->
        <div class="col-12">
          <employees-portlet
            ref="employeesPortlet"

            :users="users"
            :user-sales="userSales"
          />
        </div>
      </div>
    </KContainer>
  </div>
</template>

<script>
// Libraries
import moment from 'moment-timezone';

// Classes
import User from '@/libs/classes/user';

// Components
import Datepicker from '@/components/statistics/components/datepicker';
import employeesPortlet from '@/components/statistics/users/employees.portlet';

export default {
  components: {
    Datepicker,

    employeesPortlet
  },
  data () {
    return {
      users: null,
      userSales: null
    };
  },

  mounted () {
    this.$nextTick(() => {
      // Set default date
      const dates = {
        start: moment().startOf('month').toDate(),
        end: moment().endOf('day').toDate()
      };

      // Check if query contains dates
      if (this.$route.query && (this.$route.query.start && this.$route.query.end)) {
        // Check if both dates are valid
        if (moment(this.$route.query.start, 'DD-MM-YYYY', true).isValid() && moment(this.$route.query.end, 'DD-MM-YYYY', true).isValid()) {
          dates.start = moment(this.$route.query.start, 'DD-MM-YYYY', true).toDate();
          dates.end = moment(this.$route.query.end, 'DD-MM-YYYY', true).toDate();
        }
      }

      // Set dates
      this.$refs.datepicker.onDatePickerInput({
        start: dates.start,
        end: dates.end,
        force: true
      });
    });
  },

  methods: {
    onDatePickerInput (dates) {
      if (dates.start && dates.end) {
        // Set query
        const query = {
          start: moment(dates.start).format('DD-MM-YYYY'),
          end: moment(dates.end).format('DD-MM-YYYY')
        };

        if (this.$route.query.start !== query.start || this.$route.query.end !== query.end) {
          this.$router.replace({
            query: {
              start: query.start,
              end: query.end
            }
          });
        }

        // Get new statistics
        this.getStatistics(dates.start, dates.end);
      }
    },

    async getStatistics (from, to) {
      // Convert strict to moment
      from = moment(from, true);
      to = moment(to, true);

      // Check if dates are valid
      if (from.isValid() && to.isValid()) {
        // Set loading to refs
        if (this.$refs.employeesPortlet) this.$refs.employeesPortlet.isLoading = true;

        await Promise.all([
          this.getEmployees(),
          this.getEmployeeSales(from, to)
        ]);

        // Set loading to refs
        if (this.$refs.employeesPortlet) this.$refs.employeesPortlet.isLoading = false;
      }
    },

    async getEmployees () {
      let response = await this.$ws.get('v1', '/users');
      response = response.map(user => {
        return new User().mergeResponse(user);
      });

      this.users = response;
      return this.users;
    },
    async getEmployeeSales (from, to) {
      let response = await this.$ws.get('v1', `/stats/employee-sales/all/${from.startOf('day').valueOf()}/${to.endOf('day').valueOf()}`);
      this.userSales = response;
      return this.userSales;
    },

    printPage () {
      window.print();
    }
  }
};
</script>
