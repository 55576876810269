import { merge } from 'lodash';

export default {
  methods: {
    navigateTo (options = null) {
      let routeOptions = {
        name: null,
        params: this.$route.params
      };

      if (typeof options === 'string') routeOptions.name = options;
      if (typeof options === 'object') merge(routeOptions, options);

      this.$router.push(routeOptions);
    }
  }
};
