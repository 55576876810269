<template>
  <!-- Loading -->
  <KContainer v-if="isMounted === false">
    <div class="row mt-3">
      <div class="col-12 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
        <div class="loading-container">
          <div class="loading-block">
            <div class="blockui ml-auto mr-auto w-100">
              <span>Gegevens ophalen...</span>
              <span class="kt-spinner kt-spinner--v2 kt-spinner--primary" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </KContainer>

  <!-- Finished loading -->
  <div v-else>
    <KSubheader
      :breadcrumbs="[{
        page: 'Kassabeheer',
        route: '/cash_registers/overview'
      }, {
        page: 'Afsluitingen',
        route: '/cash_registers/closures/overview'
      }, {
        page: moment(closureTimestamp).format('LLL')
      }]"

      :title="moment(closureTimestamp).format('LLL')"
    />

    <KContainer>
      <!-- Cash register session information -->
      <KPortlet>
        <KPortletHead>
          <template v-slot:default>Afsluiting {{ moment(closureTimestamp).format('LLL') }}</template>
          <template v-slot:toolbar>
            <KButton
              variant="secondary"
              tag="span"
              class="mr-2 d-print-none"

              @click.native.prevent="printPage"
            >
              <i class="kt-font-focus pr-0">
                <font-awesome-icon :icon="['fas', 'print']" />
              </i>
            </KButton>
          </template>
        </KPortletHead>
        <KPortletBody class="pb-3">
          <div class="row">
            <div class="col-12 col-md-6 col-lg-3">
              <div class="form-group">
                <label class="text-upper">Kassa</label>
                <div>
                  <h5 class="overflow-ellipsis">
                    <router-link
                      v-if="(typeof cashRegisterOpening.cash_register === 'object')"
                      :to="`/cash_registers/view/${cashRegisterOpening.cash_register._meta.guid}`"
                    >
                      {{ cashRegisterOpening.cash_register.name }}
                    </router-link>

                    <span v-else>
                      Onbekende kassa
                    </span>
                  </h5>
                </div>
              </div>
            </div>

            <div class="col-12 col-md-6 col-lg-3">
              <div class="form-group">
                <label class="text-upper">Geopend op</label>
                <div>
                  <h5 class="overflow-ellipsis">
                    <div class="date">{{ moment(cashRegisterOpening.open_timestamp).format('LLL') }}</div>
                    <div class="user">
                      Door
                      <router-link :to="`/users/view/${cashRegisterOpening.opened_by_user.guid}`">
                        {{ cashRegisterOpening.opened_by_user.name }}
                      </router-link>
                    </div>
                  </h5>
                </div>
              </div>
            </div>

            <div class="col-12 col-md-6 col-lg-3">
              <div class="form-group">
                <label class="text-upper">Gesloten op</label>
                <div>
                  <h5 class="overflow-ellipsis">
                    <template v-if="cashRegisterOpening.open === true">
                      <KButton
                        variant="label-focus"
                        tag="span"
                      >
                        Nog niet afgesloten
                      </KButton>
                    </template>

                    <template v-else>
                      <div class="date">{{ moment(cashRegisterOpening.close_timestamp).format('LLL') }}</div>
                      <div class="user">
                        Door
                        <router-link :to="`/users/view/${cashRegisterOpening.closed_by_user.guid}`">
                          {{ cashRegisterOpening.closed_by_user.name }}
                        </router-link>
                      </div>
                    </template>
                  </h5>
                </div>
              </div>
            </div>

            <div class="col-12 col-md-6 col-lg-3">
              <div class="form-group">
                <label class="text-upper">Beginstand kassa</label>
                <div>
                  <h5 class="overflow-ellipsis">
                    &euro; {{ $format.currency(cashRegisterOpening.open_amount_cash) }}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </KPortletBody>
      </KPortlet>

      <!-- Remarks -->
      <div class="row">
        <div class="col-12 col-lg-6">
          <KPortlet>
            <KPortletHead>
              <template v-slot:default>Opmerking openen kassa</template>
            </KPortletHead>
            <KPortletBody>
              <div
                v-if="typeof cashRegisterOpening.open_remark === 'string'"
                style="white-space: pre-line; word-break: keep-all;"
                v-text="cashRegisterOpening.open_remark"
              />
            </KPortletBody>
          </KPortlet>
        </div>

        <div class="col-12 col-lg-6">
          <KPortlet>
            <KPortletHead>
              <template v-slot:default>Opmerking sluiten kassa</template>
            </KPortletHead>
            <KPortletBody>
              <template v-if="cashRegisterOpening.open === true">
                <KButton
                  variant="label-focus"
                  tag="span"
                >
                  Nog niet afgesloten
                </KButton>
              </template>

              <div
                v-else-if="typeof cashRegisterOpening.close_remark === 'string'"
                style="white-space: pre-line; word-break: keep-all;"
                v-text="cashRegisterOpening.close_remark"
              />
            </KPortletBody>
          </KPortlet>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-lg-6">
          <!-- Cash register position -->
          <cash-position-portlet
            ref="cashPositionPortlet"
            :cash-register-opening="cashRegisterOpening"
          />
        </div>

        <div class="col-12 col-lg-6">
          <!-- Payment methods -->
          <payment-methods-portlet
            ref="paymentMethodsPortlet"
            :cash-register-opening="cashRegisterOpening"
          />
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <!-- VAT overview -->
          <vats-revenue-portlet
            ref="VATsRevenuePortlet"
            :cash-register-opening="cashRegisterOpening"
          />
        </div>
      </div>

      <div class="row">
        <div class="col-12 datatable datatable-wrapper">
          <salesorders-portlet
            ref="salesordersPortlet"

            :cash-register-opening="cashRegisterOpening"
          />
        </div>
      </div>

    </KContainer>
  </div>
</template>

<script>
// Libraries
import moment from 'moment-timezone';

// Components
import cashPositionPortlet from '@/components/cash_registers/closures/view/cash_position.portlet';
import paymentMethodsPortlet from '@/components/cash_registers/closures/view/payment_methods.portlet';
import vatsRevenuePortlet from '@/components/cash_registers/closures/view/vats_revenue.portlet';
import salesordersPortlet from '@/components/cash_registers/closures/view/salesorders.portlet';

// Classes
import CashRegister from '@/libs/classes/cash_register';
import CashRegisterOpening from '@/libs/classes/cash_register.opening';

export default {
  components: {
    cashPositionPortlet,
    paymentMethodsPortlet,
    vatsRevenuePortlet,
    salesordersPortlet
  },
  data () {
    return {
      isMounted: false,

      cashRegisterOpening: null,

      moment
    };
  },
  computed: {
    closureTimestamp: {
      get () {
        if (!this.cashRegisterOpening) {
          return null;
        }

        return (this.cashRegisterOpening.open === true ? this.cashRegisterOpening.open_timestamp : this.cashRegisterOpening.close_timestamp);
      }
    }
  },

  async mounted () {
    try {
      await this.getCashRegisterOpening();
    } catch (e) {
      console.error('Cash register session not found:', e);
      return;
    }

    this.$nextTick(() => {
      this.isMounted = true;
    });
  },

  methods: {
    async getCashRegisterOpening (options = {}) {
      this.$set(this, 'cashRegisterOpening', null);

      // Get cash register opening
      const cashRegisterOpening = await new CashRegisterOpening(this.$route.params.guid).get(options);
      await cashRegisterOpening.populate('get', options);

      // Get extra data
      await Promise.all([
        (async () => {
          try {
            cashRegisterOpening.cash_register = await new CashRegister(cashRegisterOpening.cash_register).get();
          } catch (e) {
            console.error('Cash register not found', e);
          }
        })(),
        (async () => {
          cashRegisterOpening.expectedCloseAmounts = await cashRegisterOpening.getExpectedCloseAmounts();
        })(),
        (async () => {
          cashRegisterOpening.VATsRevenue = await cashRegisterOpening.getVATsRevenue();
        })(),
        (async () => {
          cashRegisterOpening.cashRevenue = await cashRegisterOpening.getCashRevenue();
        })()
      ]);

      this.$set(this, 'cashRegisterOpening', cashRegisterOpening);

      return this.cashRegisterOpening;
    },

    printPage () {
      window.print();
    }
  }
};
</script>
