<template>
  <div>
    <KSubheader
      :breadcrumbs="[{
        page: 'Dashboard',
        route: null
      }]"
      title="Dashboard"
    >
      <!-- <KDropdown v-if="isMounted" menu-right>
        <template v-slot:text>
          <span>Sort by:</span>
          <span class="font-weight-bold kt-padding-l-5">Name</span>
        </template>

        <KDropdownItem v-on:click.native.prevent="sortEquipment('name|asc')">Name</KDropdownItem>
        <KDropdownItem v-on:click.native.prevent="sortEquipment('room|asc')">Room</KDropdownItem>
      </KDropdown> -->
    </KSubheader>

    <KContainer>
      <template v-if="isMounted === false">
        <div class="row">
          <div class="col-12 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
            <div class="loading-container">
              <div class="loading-block">
                <div class="blockui ml-auto mr-auto w-100">
                  <span>Gegevens ophalen...</span>
                  <span class="kt-spinner kt-spinner--v2 kt-spinner--primary" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>

      <template v-else>
        <template v-if="equipmentSorted && equipmentSorted.length > 0">
          <div class="row">
            <div
              v-for="(row, idx) in equipmentSorted"
              :key="`equipment.row.${row.guid}.${idx}`"
              class="col-sm-12 col-md-6 col-lg-4 col-xl-3"
            >
              <equipment-block
                :key="`equipment.${row.guid}`"
                :ref="`equipment.${row.guid}`"

                :class="[{'cursor-pointer': (isBlockDisabled(row) === false && $auth.hasPermission('shop'))}]"
                :equipment="row"
                :disabled="isBlockDisabled(row)"

                use-full-time
                circle-class="w-100 h-100"

                @click.native.prevent="onClickBlock(row)"
              />
            </div>
          </div>
        </template>

        <template v-else>
          <div class="row">
            <div class="col-12">
              <KAlert
                :icon="['fad', 'link']"
                variant="outline-info"
              >
                Nog geen apparatuur toegevoegd of verbonden met een Node
              </KAlert>
            </div>
          </div>
        </template>
      </template>

    </KContainer>

    <!-- Modals -->
    <configure-session-modal
      ref="configureSessionModal"
    />

    <view-session-modal
      ref="viewSessionModal"
    />
  </div>
</template>

<script>
// Libraries
import equipment from '@/libs/equipment';

// Classes
import Equipment from '@/libs/classes/equipment';

// Components
import equipmentBlock from '@/components/equipment/components/block';

// Modals
import configureSessionModal from '@/components/equipment/modals/configure-session/index';
import viewSessionModal from '@/components/equipment/modals/view-session/index';

export default {
  components: {
    equipmentBlock,

    configureSessionModal,
    viewSessionModal
  },
  data () {
    return {
      isMounted: false,

      equipment: [],
      masters: []
    };
  },
  computed: {
    equipmentSorted () {
      if (this.equipmentFiltered && this.equipmentFiltered.length) {
        return this.equipmentFiltered.slice().sort((a, b) => {
          return a.room.localeCompare(b.room, undefined, {
            numeric: true,
            sensitivity: 'base'
          });
        });
      }
      return [];
    },
    equipmentFiltered () {
      if (this.equipment && this.equipment.length > 0) {
        return this.equipment.slice().filter((row) => {
          return row.connected === true;
        }, []);
      }
      return [];
    }
  },

  async mounted () {
    this.isMounted = false;
    try {
      this.equipment = await equipment.getEquipment();
    } catch (e) {
      console.error(e);
      return;
    }

    // Listeners
    this.$eventhub.on('equipment:equipment:updated', this.onEquipmentUpdated);
    this.$eventhub.on('equipment:masters:updated', this.onMastersUpdated);

    this.$nextTick(async () => {
      this.isMounted = true;

      // Get equipment information, but show the data already
      equipment.getEquipmentInformation();

      if (this.$route.query) {
        // Check if equipment.session is defined, so we can open the session modal
        if (this.$route.query['equipment.session'] && typeof this.$route.query['equipment.session'] === 'string') {
          // Open configure session modal, if equipment is found
          const equipment = this.equipment.find(e => e.guid === this.$route.query['equipment.session']);
          if (typeof equipment !== typeof undefined) {
            this.$eventhub.emit('modals:equipment:configure-session:open', {
              equipment: equipment
            });
          }

          // Remove query from url
          delete this.$route.query['equipment.session'];
          this.$router.replace({
            query: {
              'equipment.session': undefined
            }
          });
        }
      }
    });
  },
  beforeDestroy () {
    // Remove listeners
    this.$eventhub.off('equipment:equipment:updated', this.onEquipmentUpdated);
    this.$eventhub.off('equipment:masters:updated', this.onMastersUpdated);
  },

  methods: {
    onEquipmentUpdated (equipment) {
      this.$nextTick(() => this.$set(this, 'equipment', equipment));
    },
    onMastersUpdated (masters) {
      // First set to empty array, so it can update properly
      this.$set(this, 'masters', null);

      // Set to real equipment on next tick
      this.$nextTick(() => this.$set(this, 'masters', masters));
    },

    isBlockDisabled (equipment) {
      // Check for equipment
      if (!equipment) return true;
      if (!equipment.master || equipment.master.isConnected === false) return true;
      if (!equipment.communication) return true;

      // Check equipment state
      if (equipment.state === 'unknown' || equipment.state === 'timeout') return true;

      return false;
    },

    async onClickBlock (equipment) {
      if (equipment instanceof Equipment && this.isBlockDisabled(equipment) === false) {
        // When equipment is just available, open the 'configure-session' modal
        if (equipment.state === 'available') {
          // Only when shop can be accessed
          if (this.$auth.hasPermission('shop')) {
            this.$eventhub.emit('modals:equipment:configure-session:open', {
              equipment: equipment
            });
          }
        } else if (equipment.inSession === true) {
          // Open view-session modal
          this.$eventhub.emit('modals:equipment:view-session:open', {
            equipment: equipment
          });
        }
      }
    }
  }
};
</script>
