// Libraries
import { merge } from 'lodash';
import stores from '@/libs/stores';

// Classes
import Abstract from './abstract';
import StoreSettings from './store.settings';

class Store extends Abstract {
  constructor (guid = null) {
    super(guid);
    this.uri = '/stores';

    let defaultObject = {
      name: null,
      settings: new StoreSettings()
    };

    merge(this, defaultObject);
  }

  // Methods
  formatBody (type = 'get', options = {}) {
    let body = super.formatBody(type, options);

    // Format settings
    body.settings = this.settings.formatBody();

    return body;
  }

  async update (mutations = [], options = {}) {
    await super.update(mutations, options);

    // Check if update is active store
    if (stores.activeStore && stores.activeStore.guid === this.guid) {
      stores.activeStore = this;
    }

    return this;
  }
}

export default Store;
