<template>
  <div>
    <!-- Permission information -->
    <div
      v-if="action === 'CREATE'"
      class="row pb-3 border-bottom"
    >
      <div class="col-12">
        <h5 class="overflow-ellipsis">
          Bevoegdheden
        </h5>
        <span>
          Bepaal hieronder het toegangsniveau van de gebruiker
        </span>
      </div>
    </div>

    <!-- Permission groups -->
    <div :class="['row', {'mt-3': action === 'CREATE'}]">
      <div class="col-12 p-0">
        <!-- Loading -->
        <template v-if="isMounted === false">
          <div class="loading-container absolute">
            <div class="loading-backdrop">
              <div class="loading-block">
                <div class="blockui">
                  <span>Please wait...</span>
                  <span class="kt-spinner kt-spinner--v2 kt-spinner--primary" />
                </div>
              </div>
            </div>
          </div>
        </template>

        <!-- Groups -->
        <template v-else>
          <label
            v-for="(group) in groupsSorted"
            :key="`permission_group.${group.guid}`"
            :class="['kt-option cursor-pointer', {'is-invalid': $v.user.permissions.permission_group_guid.$error}]"
          >
            <span class="kt-option__control">
              <span :class="['kt-radio kt-radio--check-bold', {'kt-radio--focus': user.permissions.permission_group_guid === group.guid}]">
                <input
                  v-model="$v.user.permissions.permission_group_guid.$model"
                  :value="group.guid"
                  :disabled="isDisabled"
                  type="radio"
                  name="permission_group"
                >
                <span />
              </span>
            </span>

            <span class="kt-option__label">
              <span class="kt-option__head">
                <span class="kt-option__title">
                  {{ group.name }}
                </span>
              </span>

              <span class="kt-option__body pt-1">
                Description here (after localisation?)
              </span>
            </span>
          </label>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
// Libraries
import { merge } from 'lodash';

// Classes
import User from '@/libs/classes/user';
import PermissionGroup from '@/libs/classes/permission_group';

// Validators
import { required } from 'vuelidate/lib/validators';

export default {
  props: {
    user: {
      type: User,
      default: null
    },

    action: {
      type: String,
      default: 'VIEW'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isMounted: false,

      groups: []
    };
  },
  computed: {
    isDisabled () {
      return (this.disabled === true || this.action === 'VIEW');
    },

    groupsSorted () {
      if (this.groups) {
        return this.groups.slice().sort((a, b) => {
          return b.level - a.level;
        });
      }
      return this.groups;
    }
  },
  watch: {
    'user.permissions.permission_group_guid' (value) {
      if (value) {
        let permissionGroup = this.groups.find(group => group.guid === value);
        if (permissionGroup && permissionGroup.granted_permissions) {
          // Set permissions
          this.user.permissions = merge({
            permission_group_guid: permissionGroup.guid,
            permission_group_name: permissionGroup.name,
            level: permissionGroup.level
          }, permissionGroup.granted_permissions);

          return this.user.permissions;
        }
      }

      this.user.permissions = null;
    }
  },

  validations: {
    user: {
      permissions: {
        permission_group_guid: {
          required
        }
      }
    }
  },

  async mounted () {
    try {
      await this.getWorkspacePermissionGroups();
    } catch (e) {
      console.error(e);
      return;
    }

    this.$nextTick(() => {
      this.isMounted = true;
    });
  },

  methods: {
    async getWorkspacePermissionGroups (options = {}) {
      this.$set(this, 'groups', []);

      let groups = await this.$ws.get('v2', '/workspace_permission_groups', options);
      groups = groups.map(group => {
        return new PermissionGroup().mergeResponse(group);
      }, []);

      this.$set(this, 'groups', groups);
      return [];
    }
  }
};
</script>
