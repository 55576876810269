<template>
  <div v-if="isMounted && store !== null">
    <KPortlet>
      <KPortletHead>
        <template v-slot:default>Tweede schermen</template>

        <template v-slot:toolbar>
          <router-link
            v-if="$auth.hasPermission('create_customers') === true"
            to="create"
          >
            <KButton
              tag="a"
              variant="outline-brand"
              icon="plus"
            >
              Tweede scherm toevoegen
            </KButton>
          </router-link>
        </template>
      </KPortletHead>
      <KPortletBody>
        <div class="row">
          <div class="col-12 datatable datatable-wrapper">
            <datatable
              ref="datatable"
              :url="datatable.url"
              :fields="datatable.fields"
              :search-fields="datatable.searchFields"
              :on-pagination-data="onPaginationData"
              :transform="transform"
            >
              <!-- Actions -->
              <div
                slot="actions"
                slot-scope="props"
                class="datatable-actions"
              >
                <router-link
                  v-if="$auth.hasPermission('update_second_screens')"
                  :to="`/settings/store/second-screens/view/${props.rowData.guid}`"
                >
                  <KButton
                    :icon="['fad', 'edit']"
                    tag="a"
                    variant="label-brand"
                  />
                </router-link>

                <KButton
                  v-if="$auth.hasPermission('remove_second_screens')"
                  :icon="['fad', 'trash']"

                  tag="a"
                  variant="label-brand"

                  @click.native.prevent="openDeleteModal(props.rowData)"
                />

              </div>
            </datatable>

            <div class="row pagination">
              <div class="col-xs-12 col-md-6">
                <datatable-pagination
                  ref="pagination"
                  @vuetable-pagination:change-page="onChangePage"
                />
              </div>

              <div class="col-xs-12 col-md-6">
                <datatable-pagination-info
                  ref="pagination.info"
                  @vuetable-paginationinfo:change-rowcount="onChangeRowCount"
                />
              </div>
            </div>
          </div>
        </div>
      </KPortletBody>
    </KPortlet>

  </div>
</template>

<script>
// Classes
import Store from '@/libs/classes/store';
import SecondScreen from '@/libs/classes/second_screens';

// Mixins
import datatable from '@/mixins/datatable';

export default {
  mixins: [datatable],
  props: {
    store: {
      type: Store,
      default: null
    }
  },
  data () {
    return {
      isMounted: false,

      datatable: {
        url: '/second_screens',
        fields: [{
          title: 'Naam',
          name: 'name',
          sortField: 'name',
          dataClass: 'w-1/4'
        }, {
          name: '__slot:actions',
          title: '',
          titleClass: 'text-right',
          dataClass: 'text-right'
        }],

        searchFields: ['name']
      }
    };
  },

  mounted () {
    this.$nextTick(() => {
      this.isMounted = true;
    });
  },
  methods: {
    transform (data) {
      if (data.data && Array.isArray(data.data)) {
        data.data = data.data.map(row => {
          return new SecondScreen().mergeResponse(row);
        }, []);
      }

      return data;
    },

    openDeleteModal (resource = null) {
      return this.$eventhub.emit('modals:confirm:open', {
        resource: resource,

        title: 'Verwijder tweede scherm',
        text: `Weet je zeker dat je <strong>${resource.name}</strong> wilt verwijderen?`,
        submit: async () => {
          // Remove resource
          await resource.remove();

          // Refresh datatable
          this.$refs.datatable.refresh({
            query: {
              disable_cache: true
            }
          });
        }
      });
    }
  }
};
</script>
