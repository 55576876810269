<template>
  <div>
    <KSubheader
      :breadcrumbs="[{
        page: 'Statistieken',
        route: '/statistics'
      }, {
        page: 'Maandelijks',
        route: '/statistics/monthly'
      }]"

      title="Maandelijks"
    />

    <KContainer>
      <!-- Portlet bar -->
      <KPortlet>
        <KPortletHead>
          <template v-slot:default>
            {{ $stores.activeStore.name }}
          </template>
          <template v-slot:toolbar>
            <KButton
              variant="secondary"
              tag="span"
              class="mr-2 d-print-none"

              @click.native.prevent="printPage"
            >
              <i class="kt-font-focus pr-0">
                <font-awesome-icon :icon="['fas', 'print']" />
              </i>
            </KButton>

            <KButtonGroup>
              <KButton
                variant="secondary"
                class="d-print-none"
                @click.native.prevent="setYear(-1)"
              >
                <i class="pr-0">
                  <font-awesome-icon :icon="['fas', 'chevron-left']" />
                </i>
              </KButton>

              <KButton
                variant="secondary"
                style="width:150px; font-weight:bold;"
                disabled
              >
                {{ moment(year, 'YYYY', true).format('YYYY') }}
              </KButton>

              <KButton
                variant="secondary"
                class="d-print-none"
                @click.native.prevent="setYear(1)"
              >
                <i class="pr-0">
                  <font-awesome-icon :icon="['fas', 'chevron-right']" />
                </i>
              </KButton>
            </KButtonGroup>
          </template>
        </KPortletHead>
      </KPortlet>

      <!-- Data -->
      <div class="row">
        <div class="col-12">
          <totals-portlet
            ref="totalsPortlet"

            :year="year"
            :totals="totals"
          />
        </div>
      </div>
    </KContainer>
  </div>
</template>

<script>
// Libraries
import moment from 'moment-timezone';

// Components
import totalsPortlet from '@/components/statistics/monthly/totals.portlet';

export default {
  components: {
    totalsPortlet
  },
  data () {
    return {
      year: moment().format('YYYY'),
      totals: null,

      moment
    };
  },

  mounted () {
    this.$nextTick(() => {
      // Set default year
      let year = moment().format('YYYY');

      // Check if query contains year
      if (this.$route.query && this.$route.query.year) {
        // Check if year is valid
        if (moment(this.$route.query.year, 'YYYY', true).isValid()) {
          year = this.$route.query.year;
        }
      }

      // Set year
      this.setYear(year);
    });
  },

  methods: {
    setYear (year = null) {
      if (typeof year !== typeof undefined) {
        // Check if actions
        if (year === 1 || year === -1) {
          // Subtract 1 year
          if (year === -1) {
            year = moment(this.year, 'YYYY', true).subtract(1, 'years');
          }

          // Add 1 year
          if (year === 1) {
            // Check if year is not the same as current
            if (this.year !== moment().format('YYYY')) {
              year = moment(this.year, 'YYYY', true).add(1, 'years');
            } else {
              return;
            }
          }
        } else {
          // Parse year
          year = moment(year, 'YYYY', true);
        }

        // Check if year is valid
        if (year.isValid()) {
          this.year = year.format('YYYY');

          // Set query
          if (this.$route.query.year !== this.year) {
            this.$router.replace({
              query: {
                year: this.year
              }
            });
          }

          this.getTotals(this.year);
        }
      }
    },

    async getTotals (year = null) {
      // Set loading to refs
      if (this.$refs.totalsPortlet) this.$refs.totalsPortlet.isLoading = true;

      // Set from and to dates
      const yearObject = {
        from: moment(year, 'YYYY', true).startOf('year'),
        to: moment(year, 'YYYY', true).endOf('year')
      };

      // Fetch totals period daily
      const [monthly] = await Promise.all([
        this.$ws.get('v1', `/stats/totals-period/${yearObject.from.valueOf()}/${yearObject.to.valueOf()}/month`)
      ]);

      // Set data
      this.totals = monthly;

      // Set loading to refs
      if (this.$refs.totalsPortlet) this.$refs.totalsPortlet.isLoading = false;
      return this.totals;
    },

    printPage () {
      window.print();
    }
  }
};
</script>
