import Abstract from './abstract';

import { merge } from 'lodash';

import ProductPrice from './product.price';

class SessionProduct extends Abstract {
  constructor (guid = null) {
    super(guid);
    this.uri = '/session_products';

    let defaultObject = {
      name: null,
      duration: 0,
      default: false,

      price: new ProductPrice()
    };

    merge(this, defaultObject);
  }

  // Getter and setter for the duration in minutes
  get durationMin () {
    return (this.duration / 60);
  }

  set durationMin (value) {
    this.duration = (value * 60);
  }

  get session_name () {
    if (!this.default || this.default === false) return `${this.name} (${this.durationMin} min)`;
    else return `${this.durationMin} ${(this.durationMin === 1 ? 'minuut' : 'minuten')}`;
  }
};

export default SessionProduct;
