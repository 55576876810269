<template>
  <!-- Loading -->
  <KContainer v-if="isLoading === true">
    <div class="row mt-3">
      <div class="col-12 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
        <div class="loading-container">
          <div class="loading-block">
            <div class="blockui ml-auto mr-auto w-100">
              <span>Gegevens ophalen...</span>
              <span class="kt-spinner kt-spinner--v2 kt-spinner--primary" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </KContainer>

  <div v-else-if="group !== null">
    <KSubheader
      :breadcrumbs="[{
        page: 'Producten',
        route: '/products'
      }, {
        page: 'Groepen',
        route: '/products/groups'
      }, {
        page: group.name,
        route: null
      }]"

      title="Groepen"
    />

    <KContainer>
      <div class="row">
        <div class="col-12 col-portlet-menu">
          <KPortlet>
            <KPortletBody>
              <div class="kt-widget kt-widget--general-1">
                <div class="kt-media kt-media--brand kt-media--md kt-media--circle">
                  <span class="kt-badge kt-badge--username kt-badge--lg kt-badge--dark kt-badge--bold">
                    <i><font-awesome-icon :icon="['fad', 'tags']" /></i>
                  </span>
                </div>
                <div class="kt-widget__wrapper">
                  <div class="kt-widget__label">
                    <span class="kt-widget__title">{{ group.name }}</span>
                  </div>
                </div>
              </div>
            </KPortletBody>
            <div class="kt-portlet__separator" />
            <KPortletBody>
              <ul
                class="kt-nav kt-nav--bolder kt-nav--fit-ver kt-nav--v4"
                role="tablist"
              >
                <router-link
                  v-slot="{ href, route, navigate, isActive, isExactActive }"
                  to="products"
                >
                  <li :class="['kt-nav__item', {'active': isActive}]">
                    <a
                      class="kt-nav__link"
                      role="tab"
                      @click.prevent="$router.push(href)"
                    >
                      <span class="kt-nav__link-icon">
                        <i><font-awesome-icon :icon="['fad', 'tag']" /></i>
                      </span>
                      <span class="kt-nav__link-text">Producten</span>
                    </a>
                  </li>
                </router-link>

                <router-link
                  v-slot="{ href, route, navigate, isActive, isExactActive }"
                  to="settings"
                >
                  <li :class="['kt-nav__item', {'active': isActive}]">
                    <a
                      class="kt-nav__link"
                      role="tab"
                      @click.prevent="$router.push(href)"
                    >
                      <span class="kt-nav__link-icon">
                        <i><font-awesome-icon :icon="['fad', 'cogs']" /></i>
                      </span>
                      <span class="kt-nav__link-text">Instellingen</span>
                    </a>
                  </li>
                </router-link>
              </ul>
            </KPortletBody>
          </KPortlet>
        </div>

        <div class="col-12 col-sm">
          <router-view
            :key="$route.fullPath"
            :group.sync="group"
          />
        </div>
      </div>

    </KContainer>

  </div>
</template>

<script>
// Classes
import ProductGroup from '@/libs/classes/product_group';

export default {
  data () {
    return {
      isLoading: false,

      group: null
    };
  },

  async mounted () {
    try {
      this.isLoading = true;
      await this.getProductGroup();
      this.isLoading = false;
    } catch (e) {
      console.error('Group not found:', e);
      this.$error.showModal(e);
    }
  },

  methods: {
    async getProductGroup (options = {}) {
      this.$set(this, 'group', null);

      let group = await new ProductGroup(this.$route.params.guid).get(options);
      await group.populate('get', options);

      this.$set(this, 'group', group);
      return this.group;
    }
  }
};
</script>
