<template>
  <KPortlet>
    <KPortletBody class="kt-portlet__body--fit position-relative">
      <!-- Loading block -->
      <template v-if="isLoading">
        <div class="loading-container absolute">
          <div class="loading-backdrop">
            <div class="loading-block">
              <div class="blockui">
                <span>Please wait...</span>
                <span class="kt-spinner kt-spinner--v2 kt-spinner--primary" />
              </div>
            </div>
          </div>
        </div>
      </template>

      <!-- Table -->
      <div class="table-responsive">
        <table class="table mb-0">
          <thead class="thead-light thead-light--fit">
            <tr>
              <th />
              <th class="table-field-currency overflow-ellipsis">1e kwartaal</th>
              <th class="table-field-currency overflow-ellipsis">2e kwartaal</th>
              <th class="table-field-currency overflow-ellipsis">3e kwartaal</th>
              <th class="table-field-currency overflow-ellipsis">4e kwartaal</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="overflow-ellipsis">Omzet {{ $format.percentage(0.21) }}%</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getQuarterVATKey(1, 21, 'total_price_excl')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getQuarterVATKey(2, 21, 'total_price_excl')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getQuarterVATKey(3, 21, 'total_price_excl')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getQuarterVATKey(4, 21, 'total_price_excl')) }}</td>
            </tr>
            <tr>
              <td class="overflow-ellipsis">Omzet {{ $format.percentage(0.09) }}%</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getQuarterVATKey(1, 9, 'total_price_excl')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getQuarterVATKey(2, 9, 'total_price_excl')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getQuarterVATKey(3, 9, 'total_price_excl')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getQuarterVATKey(4, 9, 'total_price_excl')) }}</td>
            </tr>
            <tr>
              <td class="overflow-ellipsis">Omzet {{ $format.percentage(0) }}%</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getQuarterVATKey(1, 'vat_nl_0', 'total_price_excl')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getQuarterVATKey(2, 'vat_nl_0', 'total_price_excl')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getQuarterVATKey(3, 'vat_nl_0', 'total_price_excl')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getQuarterVATKey(4, 'vat_nl_0', 'total_price_excl')) }}</td>
            </tr>
            <tr>
              <td class="overflow-ellipsis">Omzet BTW vrijgesteld</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getQuarterVATKey(1, 'no_vat', 'total_price_excl')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getQuarterVATKey(2, 'no_vat', 'total_price_excl')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getQuarterVATKey(3, 'no_vat', 'total_price_excl')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getQuarterVATKey(4, 'no_vat', 'total_price_excl')) }}</td>
            </tr>
          </tbody>
          <thead class="thead-light thead-light--fit">
            <tr>
              <th>Totaal omzet</th>
              <th class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getQuarterKey(1, 'total_revenue_excl')) }}</th>
              <th class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getQuarterKey(2, 'total_revenue_excl')) }}</th>
              <th class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getQuarterKey(3, 'total_revenue_excl')) }}</th>
              <th class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getQuarterKey(4, 'total_revenue_excl')) }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="overflow-ellipsis">BTW {{ $format.percentage(0.21) }}%</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getQuarterVATKey(1, 21, 'total_price_vat')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getQuarterVATKey(2, 21, 'total_price_vat')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getQuarterVATKey(3, 21, 'total_price_vat')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getQuarterVATKey(4, 21, 'total_price_vat')) }}</td>
            </tr>
            <tr>
              <td class="overflow-ellipsis">BTW {{ $format.percentage(0.09) }}%</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getQuarterVATKey(1, 9, 'total_price_vat')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getQuarterVATKey(2, 9, 'total_price_vat')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getQuarterVATKey(3, 9, 'total_price_vat')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getQuarterVATKey(4, 9, 'total_price_vat')) }}</td>
            </tr>
          </tbody>
          <tfoot class="thead-light thead-light--fit">
            <tr>
              <th>Totaal BTW</th>
              <th class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getQuarterKey(1, 'total_vat_paid')) }}</th>
              <th class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getQuarterKey(2, 'total_vat_paid')) }}</th>
              <th class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getQuarterKey(3, 'total_vat_paid')) }}</th>
              <th class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getQuarterKey(4, 'total_vat_paid')) }}</th>
            </tr>
          </tfoot>
        </table>
      </div>
    </KPortletBody>
  </KPortlet>
</template>

<script>
// Libraries
import moment from 'moment-timezone';
import objectPath from 'object-path';

export default {
  props: {
    year: {
      type: Number,
      default: null
    },
    vat: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      isLoading: false
    };
  },

  methods: {
    getQuarter (quarter = null) {
      // Check if quarter is given, year is found, quarterly vat is loaded
      if (quarter && this.year && this.vat && this.vat.quarterly && this.vat.quarterly.length) {
        // Format month
        const quarterObject = moment(`${quarter}-${this.year}`, 'Q-YYYY');

        // Check if month exists
        if (quarterObject.isValid()) {
          return this.vat.quarterly.find(m => m.date === quarterObject.format('DD-MM-YYYY'));
        }
      }
      return null;
    },
    getQuarterKey (quarter = null, key) {
      const quarterObject = this.getQuarter(quarter);
      if (quarterObject) {
        return objectPath.get(quarterObject, key);
      }
      return null;
    },
    getQuarterVAT (quarter, vatPercentage) {
      const quarterObject = this.getQuarter(quarter);
      if (quarterObject && quarterObject.vats) {
        return quarterObject.vats.find(vat => (vat.percentage === Number(vatPercentage) || vat.vat_code === vatPercentage));
      }
      return null;
    },
    getQuarterVATKey (quarter, vatPercentage, key) {
      const vatObject = this.getQuarterVAT(quarter, vatPercentage);
      if (vatObject) {
        return objectPath.get(vatObject, key);
      }
      return null;
    }
  }
};
</script>
