<template>
  <!-- Loading -->
  <div
    v-if="isMounted === false"
    class="row"
  >
    <div class="col-12 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
      <div class="loading-container">
        <div class="loading-block">
          <div class="blockui ml-auto mr-auto w-100">
            <span>Gegevens ophalen...</span>
            <span class="kt-spinner kt-spinner--v2 kt-spinner--primary" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Finished loading -->
  <div v-else>
    <KPortlet>
      <KPortletHead>
        <template v-slot:default>Onderhoud</template>
        <template v-slot:toolbar>
          <KButton
            v-if="$auth.hasPermission([['update_equipments']])"

            :icon="['fas', 'plus']"
            variant="outline-primary"

            @click.native.prevent="createHourcounter"
          >
            Urenteller toevoegen
          </KButton>
        </template>
      </KPortletHead>
      <KPortletBody>
        <!-- Current -->
        <div class="row border-bottom">
          <div class="col-12 col-md-6 text-center">
            <div class="form-group">
              <label>Totale uren</label>
              <div>
                <h5 class="overflow-ellipsis">{{ (equipmentCloned.timer_total_seconds ? $format.secondsToTime((equipmentCloned.timer_total_seconds) / 60) : $format.secondsToTime(0)) }} uur</h5>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-6 text-center">
            <div class="form-group">
              <label>Totale starts</label>
              <div>
                <h5 class="overflow-ellipsis">{{ (equipmentCloned.total_starts || 0) }} keer</h5>
              </div>
            </div>
          </div>
        </div>

        <!-- Hour counters -->
        <template v-if="timersSorted && timersSorted.length > 0">
          <div class="kt-widget-1 pt-3">
            <div
              v-for="(timer, idx) in timersSorted"
              :key="`equipment.${equipmentCloned.guid}.timer.${idx}`"

              :class="['kt-widget-1__item', {'border-bottom pb-3': (timersSorted.length - 1) !== idx}]"
            >
              <div class="kt-widget-1__item-info">
                <a
                  class="kt-widget-1__item-title cursor-pointer"
                  @click.prevent="editHourcounter(timer)"
                >
                  <span :class="[{'kt-font-caution': timer.isAboveWarning, 'kt-font-danger': (timer.percentage * 100) >= 100}]">{{ timer.name }}</span>
                </a>
                <div class="kt-widget-1__item-desc">
                  <i>Laatste onderhoud: {{ moment((timer.last_reset || timer.created)).format('LLL') }}</i>
                </div>
              </div>
              <div
                class="kt-widget-1__item-stats cursor-pointer"
                @click.prevent="editHourcounter(timer)"
              >
                <div class="kt-widget-1__item-value">
                  <span class="value">{{ Math.floor(timer.currentHours) }}</span> / <span class="kt-opacity-5">{{ Math.floor(timer.maxHours) }}</span>
                </div>
                <div class="kt-widget-1__item-progress d-none d-md-block">
                  <div
                    v-b-tooltip="`${$format.percentage(timer.percentage)}%`"
                    class="progress"
                    style="height: 15px;"
                  >
                    <div
                      :class="['progress-bar', {'kt-bg-caution': (timer.isAboveWarning), 'kt-bg-danger': (timer.percentage * 100) >= 100}]"
                      :style="[{'width': `${timer.percentage * 100}%`}]"
                      role="progressbar"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>

        <template v-else>
          <KAlert
            :icon="['fad', 'info-circle']"
            variant="outline-focus"
            class="mt-3 mb-0"
          >
            Geen urentellers toegevoegd
          </KAlert>
        </template>
      </KPortletBody>
    </KPortlet>

    <hourcounter-modal
      ref="hourcounterModal"
    />
  </div>
</template>

<style lang="scss">
@import 'bootstrap/scss/bootstrap';
@import 'bootstrap-vue/src/_variables.scss';
@import 'bootstrap-vue/src/components/tooltip/index.scss';
</style>

<script>
// Libraries
import moment from 'moment';
import { cloneDeep } from 'lodash';
import mutationsLib from '@/libs/mutations';

// Classes
import Equipment from '@/libs/classes/equipment';

// Classes
import hourcounterModal from '@/components/equipment/modals/hourcounter/index';

// Directives
import { VBTooltip } from 'bootstrap-vue';

export default {
  components: {
    hourcounterModal
  },
  directives: {
    BTooltip: VBTooltip
  },
  props: {
    equipment: {
      type: Equipment,
      default: null
    },
    meta: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      isMounted: false,

      equipmentCloned: cloneDeep(this.equipment),

      moment
    };
  },
  computed: {
    timersSorted () {
      if (this.equipmentCloned) {
        return this.equipmentCloned.timers.slice().sort((a, b) => {
          return b.hoursLeft - a.hoursLeft;
        });
      }

      return [];
    }
  },

  mounted () {
    this.$nextTick(() => {
      this.isMounted = true;
    });
  },

  methods: {
    createHourcounter () {
      const vm = this;
      this.$eventhub.emit('modals:equipment:hourcounter:open', {
        equipment: vm.equipment,
        async onSubmit (hourcounter) {
          // Add created timestamp
          hourcounter.created = moment().utc().valueOf();

          // Update array
          await vm.equipment.update([{
            action: 'array_push',
            field: 'timers',
            value: hourcounter
          }]);

          vm.$set(vm, 'equipmentCloned', cloneDeep(vm.equipment));
        }
      });
    },
    editHourcounter (hourcounter = null) {
      const vm = this;
      const idx = this.equipmentCloned.timers.indexOf(hourcounter);
      this.$eventhub.emit('modals:equipment:hourcounter:open', {
        equipment: vm.equipment,
        hourcounter: hourcounter,
        async onSubmit (hourcounter) {
          if (idx !== -1) {
            const fields = ['name', 'remark', 'max', 'warning'];
            let mutations = mutationsLib.create(fields, vm.equipmentCloned.timers[idx], hourcounter);

            if (mutations.length > 0) {
              // Change mutations to timers.idx
              mutations = mutations.map((mutation) => {
                mutation.field = `timers.${idx}.${mutation.field}`;
                return mutation;
              });

              // Update
              await vm.equipment.update(mutations);
              vm.$set(vm, 'equipmentCloned', cloneDeep(vm.equipment));
            }
          }
        },
        async onReset (hourcounter) {
          if (idx !== -1) {
            // Reset current to 0
            await vm.equipment.update([{
              action: 'set_field',
              field: `timers.${idx}.current`,
              value: 0
            }, {
              action: 'set_field',
              field: `timers.${idx}.last_reset`,
              value: moment().utc().valueOf()
            }]);
            vm.$set(vm, 'equipmentCloned', cloneDeep(vm.equipment));
          }
        },
        async onRemove (hourcounter) {
          if (idx !== -1) {
            // Remove idx from timers
            await vm.equipment.update([{
              action: 'remove_from_array_by_key_value',
              field: `timers`,
              key: 'name',
              value: vm.equipmentCloned.timers[idx].name
            }]);
            vm.$set(vm, 'equipmentCloned', cloneDeep(vm.equipment));
          }
        }
      });
    }
  }
};
</script>
