<template>
  <KPortlet>
    <KPortletHead>
      <template v-slot:default>Instellingen</template>
    </KPortletHead>
    <KPortletBody>

      <sessionForm ref="sessionForm"
        :session="$data._session"
      ></sessionForm>

    </KPortletBody>
    <div class="kt-portlet__foot">
      <div class="row align-items-center">
        <div class="col-6 offset-lg-10 col-lg-2 kt-align-right">
          <KButton variant="warning" class="btn-md btn-block btn-tall btn-wide btn-bold btn-upper" @click.native.prevent="submitForm">Wijzigen</KButton>
        </div>
      </div>
    </div>
  </KPortlet>
</template>

<script>
// Libraries
import { cloneDeep } from 'lodash';
import mutationsLib from '@/libs/mutations';

// Classes
import SessionProduct from '@/libs/classes/session_product';

// Components
import sessionForm from '../components/form';

export default {
  props: {
    session: {
      type: SessionProduct,
      default: () => new SessionProduct()
    }
  },
  data() {
    return {
      _session: cloneDeep(this.session)
    }
  },
  components: {
    sessionForm
  },
  methods: {
    async validate() {
      const validationArray = [];
      validationArray.push(this.$refs.sessionForm.$v);

      if(validationArray.length > 0) {
        validationArray.forEach(v => v && v.$touch && v.$touch())
        if(validationArray.filter(v => v && v.$invalid).length !== 0) return false;
      }

      return true;
    },

    async submitForm() {
      if(await this.validate() === true) {

        const fields = ['name', 'duration'];
        const mutations = mutationsLib.create(fields, this.session.formatBody(), this.$data._session.formatBody());

        if(mutations.length) {
          await this.session.update(mutations);
          await this.session.populate('update');
          this.$data._session = cloneDeep(this.session);
        }
      }
    }
  }
}
</script>
