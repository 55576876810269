var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('KAsideMenu',[_c('router-link',{attrs:{"to":"/dashboard"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('KAsideMenuItem',{attrs:{"icon":['fad', 'home'],"route":href,"active":_vm.isItemActive(route)}},[_vm._v("\n      Dashboard\n    ")])]}}])}),_vm._v(" "),_c('router-link',{attrs:{"to":"/statistics"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('KAsideMenuItem',{attrs:{"icon":['fad', 'analytics'],"route":href,"active":_vm.isItemActive(route)}},[_vm._v("\n      Statistieken\n    ")])]}}])}),_vm._v(" "),_c('router-link',{attrs:{"to":"/stores/overview"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('KAsideMenuItem',{attrs:{"icon":['fad', 'store'],"route":href,"active":_vm.isItemActive(route)}},[_vm._v("\n      Winkels\n    ")])]}}])}),_vm._v(" "),(_vm.$auth.hasPermission('get_users'))?_c('router-link',{attrs:{"to":"/customers/overview"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('KAsideMenuItem',{attrs:{"icon":['fad', 'users'],"route":href,"active":_vm.isItemActive(route)}},[_vm._v("\n      Klanten\n    ")])]}}],null,false,1731672284)}):_vm._e(),_vm._v(" "),(_vm.$auth.hasPermission('get_products'))?_c('KAsideMenuSubmenu',{attrs:{"title":"Producten","icon":['fad', 'tags'],"active":_vm.isMenuActive('products')}},[(_vm.$auth.hasPermission('get_products'))?_c('router-link',{attrs:{"to":"/products/overview"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('KAsideMenuItem',{attrs:{"route":href,"active":_vm.isItemActive(route),"bullet":""}},[_vm._v("\n        Overzicht\n      ")])]}}],null,false,1285086447)}):_vm._e(),_vm._v(" "),(_vm.$auth.hasPermission('get_pricelists'))?_c('router-link',{attrs:{"to":"/products/pricelists/overview"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('KAsideMenuItem',{attrs:{"route":href,"active":_vm.isItemActive(route),"bullet":""}},[_vm._v("\n        Prijslijsten\n      ")])]}}],null,false,3913772892)}):_vm._e(),_vm._v(" "),(_vm.$auth.hasPermission('get_product_groups'))?_c('router-link',{attrs:{"to":"/products/groups/overview"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('KAsideMenuItem',{attrs:{"route":href,"active":_vm.isItemActive(route),"bullet":""}},[_vm._v("\n        Groepen\n      ")])]}}],null,false,3645729417)}):_vm._e(),_vm._v(" "),(_vm.$auth.hasPermission('get_credit_products'))?_c('router-link',{attrs:{"to":"/products/credit/overview"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('KAsideMenuItem',{attrs:{"route":href,"active":_vm.isItemActive(route),"bullet":""}},[_vm._v("\n        Sessiekrediet\n      ")])]}}],null,false,1407664343)}):_vm._e()],1):_vm._e(),_vm._v(" "),(_vm.$auth.hasPermission('get_users'))?_c('router-link',{attrs:{"to":"/users"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('KAsideMenuItem',{attrs:{"route":href,"active":_vm.isItemActive(route),"icon":['fad', 'users']}},[_vm._v("\n      Gebruikers\n    ")])]}}],null,false,4044206186)}):_vm._e(),_vm._v(" "),_c('router-link',{attrs:{"to":"/settings/workspace"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('KAsideMenuItem',{attrs:{"icon":['fad', 'cogs'],"route":href,"active":isActive}},[_vm._v("\n      Instellingen\n    ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }