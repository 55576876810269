<template>
  <div>
    <KSubheader
      title="Winkels"
      :breadcrumbs="[{
        page: 'Winkels',
        route: null
      }, {
        page: 'Overzicht',
        route: null
      }]"
    >

    </KSubheader>

    <KContainer>

      <KPortlet>
        <KPortletHead>
          <template v-slot:default>Overzicht</template>

          <template v-slot:toolbar>
            <router-link v-if="$auth.hasPermission('create_stores') === true" to="/stores/create">
              <KButton variant="outline-brand" icon="plus">Create store</KButton>
            </router-link>
          </template>
        </KPortletHead>
        <KPortletBody>
          <div class="row">
            <div class="col-12">
              <div class="kt-input-icon kt-input-icon--left">
                <input type="text" class="form-control" placeholder="Search..." @keyup.enter="$refs['datatable'].search" @keyup="searchDebounced" id="generalSearch">
                <span class="kt-input-icon__icon kt-input-icon__icon--left">
                  <span>
                    <i>
                      <font-awesome-icon
                      :icon="['far', 'search']"
                      ></font-awesome-icon>
                    </i>
                  </span>
                </span>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-12 datatable datatable-wrapper">
              <datatable ref="datatable"
                :url="datatable.url"
                :fields="datatable.fields"
                :searchFields="datatable.searchFields"
                :onPaginationData="onPaginationData"
              >

                <div class="datatable-actions" slot="actions" slot-scope="props">

                  <router-link v-if="$auth.hasPermission('get_stores')"
                   :to="`/stores/view/${props.rowData._meta.guid}`"
                  >
                    <KButton tag="a"
                      variant="label-brand"
                      :icon="['fad', 'eye']"
                    ></KButton>
                  </router-link>

                  <router-link v-if="$auth.hasPermission('update_stores')"
                   :to="`/stores/edit/${props.rowData._meta.guid}`"
                  >
                    <KButton tag="a"
                      variant="label-brand"
                      :icon="['fad', 'edit']"
                    ></KButton>
                  </router-link>

                  <KButton v-if="$auth.hasPermission('remove_stores')" tag="a"
                    variant="label-brand"
                    :icon="['fad', 'trash']"
                    disabled
                  ></KButton>

                </div>

              </datatable>

              <div class="row pagination">
                <div class="col-xs-12 col-md-6">
                  <datatable-pagination ref="pagination"
                    v-on:vuetable-pagination:change-page="onChangePage"
                  ></datatable-pagination>
                </div>

                <div class="col-xs-12 col-md-6">
                  <datatable-pagination-info ref="pagination.info"
                  v-on:vuetable-paginationinfo:change-rowcount="onChangeRowCount"
                  ></datatable-pagination-info>
                </div>
              </div>
            </div>
          </div>
        </KPortletBody>
      </KPortlet>

    </KContainer>
  </div>
</template>

<script>
import datatable from "@/mixins/datatable";

export default {
  mixins: [datatable],
  data () {
    return {
      datatable:{
        url: "/stores",

        fields: [{
          name: "name",
          sortField: "username",
          dataClass: 'w-2/8'
        }, {
          title: '',
          name: '__slot:actions',
          titleClass: 'text-right',
          dataClass: 'text-right'
        }],

        searchFields: ["name"]
      }
    };
  }
};
</script>
