<template>
  <KPortlet>
    <KPortletHead>
      Overzicht
    </KPortletHead>
    <KPortletBody class="kt-portlet__body--fit position-relative">
      <!-- Loading block -->
      <template v-if="isLoading">
        <div class="loading-container absolute">
          <div class="loading-backdrop">
            <div class="loading-block">
              <div class="blockui">
                <span>Please wait...</span>
                <span class="kt-spinner kt-spinner--v2 kt-spinner--primary" />
              </div>
            </div>
          </div>
        </div>
      </template>

      <!-- Table -->
      <div class="table-responsive">
        <table class="table mb-0">
          <thead class="thead-light thead-light--fit">
            <tr>
              <th class="overflow-ellipsis">Omschrijving</th>
              <th class="table-field-currency overflow-ellipsis">Aantal</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="overflow-ellipsis">Aantal transacties</td>
              <td class="table-field-currency overflow-ellipsis">{{ (totalsPeriod ? totalsPeriod.total_transactions : 0) }}</td>
            </tr>
            <tr>
              <td class="overflow-ellipsis">Aantal nieuwe klanten</td>
              <td class="table-field-currency overflow-ellipsis">{{ (totalsPeriod ? totalsPeriod.total_new_customers : 0) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </KPortletBody>
    <KPortletBody class="kt-portlet__body--fit position-relative">
      <!-- Loading block -->
      <template v-if="isLoadingOutstanding">
        <div class="loading-container absolute">
          <div class="loading-backdrop">
            <div class="loading-block">
              <div class="blockui">
                <span>Please wait...</span>
                <span class="kt-spinner kt-spinner--v2 kt-spinner--primary" />
              </div>
            </div>
          </div>
        </div>
      </template>

      <!-- Table -->
      <div class="table-responsive">
        <table class="table mb-0">
          <thead class="thead-light thead-light--fit">
            <tr>
              <th />
              <th class="table-field-currency overflow-ellipsis">{{ $route.query.start }}</th>
              <th class="table-field-currency overflow-ellipsis">{{ $route.query.end }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="overflow-ellipsis">Openstaande rekeningen</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency((outstanding && outstanding.from ? outstanding.from.total_on_account : 0)) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency((outstanding && outstanding.to ? outstanding.to.total_on_account : 0)) }}</td>
            </tr>
            <tr>
              <td class="overflow-ellipsis">Openstaand sessiekrediet</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency((outstanding && outstanding.from ? (outstanding.from.total_outstanding_session_credits * -1) : 0)) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency((outstanding && outstanding.to ? (outstanding.to.total_outstanding_session_credits * -1) : 0)) }}</td>
            </tr>
            <tr>
              <td class="overflow-ellipsis">Openstaande cadeaubonnen</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency((outstanding && outstanding.from ? outstanding.from.total_outstanding_giftcards : 0)) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency((outstanding && outstanding.to ? outstanding.to.total_outstanding_giftcards : 0)) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </KPortletBody>
  </KPortlet>
</template>

<script>
export default {
  props: {
    totalsPeriod: {
      type: Object,
      default: null
    },
    outstanding: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      isLoading: false,
      isLoadingOutstanding: false
    };
  }
};
</script>
