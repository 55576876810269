<template>
  <b-modal
    ref="modal"

    title="Korting overschrijven"
    size="lg"
    centered
    hide-footer
  >
    <template v-slot:default>
      <div class="row border-bottom pb-3">
        <div class="col-12">
          <KAlert
            :icon="['fad', 'info-circle']"
            variant="outline-focus"
            class="mb-0"
          >
            Eén of meerdere producten hebben een afwijkende korting.<br>
            Selecteer hieronder op welke manier de korting moet worden toegepast.
          </KAlert>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-12 col-md-4">
          <KButton
            :disabled="isLoading"

            variant="outline-focus"
            class="btn-block"

            @click.native.prevent="selectOverwriteOption('force')"
          >
            Overschrijven
          </KButton>
        </div>

        <div class="col-12 col-md-4">
          <KButton
            :disabled="isLoading"

            variant="outline-focus"
            class="btn-block"

            @click.native.prevent="selectOverwriteOption('keep_original')"
          >
            Behouden
          </KButton>
        </div>

        <div class="col-12 col-md-4">
          <KButton
            :disabled="isLoading"

            variant="outline-focus"
            class="btn-block"

            @click.native.prevent="selectOverwriteOption('best_for_customer')"
          >
            Voordelig voor klant
          </KButton>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
// Libraries
import { cloneDeep } from 'lodash';

// Classes
import Salesorder from '@/libs/classes/salesorder';

// Mixins
import Modal from '@/mixins/modal';

export default {
  mixins: [Modal],

  data () {
    return {
      isLoading: false,

      options: null,
      salesorder: null
    };
  },

  mounted () {
    // Listeners
    this.$eventhub.on('modals:shop:salesorder-discount-overwrite:open', this.open);
    this.$eventhub.on('modals:shop:salesorder-discount-overwrite:close', this.hide);
  },
  beforeDestroy () {
    // Listeners
    this.$eventhub.off('modals:salesorder-discount-overwrite:open', this.open);
    this.$eventhub.off('modals:salesorder-discount-overwrite:close', this.hide);
  },

  methods: {
    open (options = null) {
      // Set loading
      this.$set(this, 'isLoading', false);
      this.$set(this, 'options', null);

      // Set options
      if (typeof options !== typeof undefined) {
        // Check for line
        if (!options.salesorder && options.salesorder instanceof Salesorder === false) {
          throw new Error('No salesorder given');
        }

        // Set options and line
        this.$set(this, 'options', options);
        this.$set(this, 'salesorder', cloneDeep(options.salesorder));

        // Show modal
        Modal.methods.show.call(this);
      }
    },

    async selectOverwriteOption (option = null) {
      this.$set(this, 'isLoading', true);

      const options = ['force', 'keep_original', 'best_for_customer'];
      if (options.indexOf(option) === -1) {
        throw new Error(`Option '${option}' unknown`);
      }

      // Check if onSubmit is given
      if (typeof this.options.onSubmit === 'function') {
        try {
          await this.options.onSubmit(option);
        } catch (e) {
          console.error(e);
          this.$set(this, 'isLoading', false);
          return;
        }
      }

      this.$set(this, 'isLoading', false);
      return this.hide();
    }
  }
};
</script>
