<template>
  <KModal ref="modal" title="Calculator" v-keydown="{ 'escape': close }" centered size="lg">
    <template v-slot:default>
      <div class="row">
        <div class="col-12 col-lg-6 offset-lg-3">

          <div v-for="(coin, idx) in rows.coins" class="row mb-3 form-group">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i><font-awesome-icon :icon="['fad', 'euro-sign']" /></i>
                </span>
              </div>
              <input type="number" class="form-control" v-model.number="form.inputs[`COIN_${coin}`].value" />
              <div class="input-group-append">
                <span class="input-group-text" style="width: 80px;">
                  x {{ $format.currency(form.inputs[`COIN_${coin}`].unit) }}
                </span>
              </div>
            </div>
          </div>

          <KSeparator class="mt-2 mb-3" />

          <div v-for="(bill, idx) in rows.bills" class="row mb-3 form-group">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i><font-awesome-icon :icon="['fad', 'euro-sign']" /></i>
                </span>
              </div>
              <input type="number" class="form-control" v-model.number="form.inputs[`BILL_${bill}`].value" />
              <div class="input-group-append">
                <span class="input-group-text" style="width: 80px;">
                  x {{ $format.currency(form.inputs[`BILL_${bill}`].unit) }}
                </span>
              </div>
            </div>
          </div>

          <KSeparator class="mt-2 mb-3" />

          <div class="row mb-3 form-group">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i><font-awesome-icon :icon="['fad', 'euro-sign']" /></i>
                </span>
              </div>
              <input type="text" class="form-control font-weight-bold" :value="$format.currency(total_value)" disabled />
              <div class="input-group-append">
                <span class="input-group-text d-block text-center" style="width: 80px;">
                  Totaal
                </span>
              </div>
            </div>
          </div>

        </div>
      </div>
    </template>

    <template v-slot:footer>
      <KButton variant="secondary" v-on:click.native.prevent="close">Annuleren</KButton>
      <KButton variant="focus" v-on:click.native.prevent="submitForm">Bevestigen</KButton>
    </template>
  </KModal>
</template>

<script>
export default {
  data () {
    return {
      rows: {
        coins: [5, 10, 20, 50, 100, 200],
        bills: [5, 10, 20, 50, 100, 200, 500]
      },

      form: {
        inputs: {}
      },
      options: {}
    }
  },
  computed: {
    total_value () {
      let value = 0;
      if (this.form && this.form.inputs && Object.keys(this.form.inputs).length > 0) {
        Object.keys(this.form.inputs).forEach((key, idx) => {
          if (this.form.inputs[key] && this.form.inputs[key].unit && this.form.inputs[key].value) {
            if (typeof this.form.inputs[key].value === 'number') {
              value = value + (this.form.inputs[key].unit * this.form.inputs[key].value);
            }
          }
        });
      }

      return value;
    }
  },

  mounted () {
    // Listeners
    this.$eventhub.on('modals:calculator:open', this.open);
    this.$eventhub.on('modals:calculator:close', this.close);

    this.clearInputs();
  },
  beforeDestroy () {
    // Remove listeners
    this.$eventhub.off('modals:calculator:open', this.open);
    this.$eventhub.off('modals:calculator:close', this.close);
  },

  methods: {
    clearInputs () {
      const inputs = {};

      // Add coins
      this.rows.coins.forEach((coin, i) => {
        inputs[`COIN_${coin}`] = {
          unit: this.$calculate.price((coin / 100), true),
          value: null
        }
      });

      // Add bills
      this.rows.bills.forEach((bill, i) => {
        inputs[`BILL_${bill}`] = {
          unit: this.$calculate.price(bill, true),
          value: null
        }
      });

      this.$set(this.form, 'inputs', inputs);
    },
    submitForm () {
      this.$eventhub.emit('modals:calculator:submit', Object.assign({}, this.options, {
        value: this.total_value
      }));
      this.close();
    },

    open (options = {}) {
      this.clearInputs();
      this.$set(this, 'options', options);
      this.$refs.modal.show();
    },
    close () {
      this.$refs.modal.hide();
    }
  }
}
</script>
