<template>
  <KPortlet>
    <KPortletHead>
      <template v-slot:default>Instellingen</template>
    </KPortletHead>
    <KPortletBody>

      <productForm
        ref="productForm"

        :product="productClone"
        :groups="meta.groups"

        :action="($auth.hasPermission([['update_products']]) ? 'EDIT' : 'VIEW')"
        :disabled="isLoading"
      />

    </KPortletBody>
    <div
      v-if="$auth.hasPermission([['update_products']])"
      class="kt-portlet__foot"
    >
      <div class="row align-items-center">
        <div class="col-6 offset-lg-10 col-lg-2 kt-align-right">
          <KButton
            :disabled="isLoading"

            variant="warning"
            class="btn-md btn-block btn-tall btn-wide btn-bold btn-upper"

            @click.native.prevent="submitForm"
          >
            Wijzigen
          </KButton>
        </div>
      </div>
    </div>
  </KPortlet>
</template>

<script>
// Libraries
import { cloneDeep } from 'lodash';
import mutationsLib from '@/libs/mutations';

// Classes
import Product from '@/libs/classes/product';
import ProductGroup from '@/libs/classes/product_group';

// Components
import productForm from '../components/form';

export default {
  components: {
    productForm
  },
  props: {
    product: {
      type: Product,
      default: null
    }
  },
  data () {
    return {
      isMounted: false,
      isLoading: false,

      productClone: cloneDeep(this.product),

      meta: {
        groups: [],
        VATCodes: []
      }
    };
  },
  async mounted () {
    try {
      await Promise.all([this.getProductGroups()]);
    } catch (e) {
      console.error('Failed catching all data', e);
      return;
    }

    this.$nextTick(() => {
      this.isMounted = true;
    });
  },
  methods: {
    async getProductGroups () {
      this.$set(this.meta, 'groups', []);

      let groups = await this.$ws.get('v1', '/product_groups');
      groups = groups.map((group) => {
        return new ProductGroup().mergeResponse(group);
      });

      this.$set(this.meta, 'groups', groups);
      return this.meta.groups;
    },

    async validate () {
      const validationArray = [];

      if (validationArray.length > 0) {
        validationArray.forEach(v => v && v.$touch && v.$touch());
        if (validationArray.filter(v => v && v.$invalid).length !== 0) return false;
      }

      // Validate form
      if (await this.$refs.productForm.validate() === false) return false;

      return true;
    },
    async submitForm () {
      if (await this.validate() === true) {
        const fields = ['name', 'group_guid', 'vat_code', 'cost_price', 'article_number', 'EAN_code', 'minimum_permission_group_level', 'amount_changeable', 'requires_customer'];
        const mutations = mutationsLib.create(fields, this.product.formatBody(), this.productClone.formatBody());

        if (mutations.length) {
          this.isLoading = true;

          try {
            await this.product.update(mutations);

            // Update products
            this.productClone = cloneDeep(this.product);

            // Update product group
            if (mutations.some(m => m.action === 'set_field' && m.field === 'group_guid')) {
              let group = this.meta.groups.find(g => g._meta.guid === this.productClone.group_guid);
              if (typeof group !== typeof undefined) this.$emit('update:productGroup', group);
            }
          } catch (e) {
            this.$error.showModal(e);
          }

          this.isLoading = false;
        }
      }
    }
  }
};
</script>
