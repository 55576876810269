<template>
  <div class="col-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">

    <breadcrumbs
      :breadcrumbs="[{
        title: 'Zoekresultaten'
      }, {
        title: search
      }]"
      title="Zoekresultaten"
    />

    <div class="row mt-3">
      <template v-if="loading">
        <div class="loading-container ml-auto mr-auto">
          <div class="loading-block">
            <div class="blockui">
              <span>Zoeken...</span>
              <span class="kt-spinner kt-spinner--v2 kt-spinner--primary" />
            </div>
          </div>
        </div>
      </template>
      <template v-else-if="results && results.length > 0 && filteredResults.length > 0">
        <div
          v-for="(result) in filteredResults"
          :key="`result.${result._meta.storage}.${result._meta.guid}`"
          class="col-12"
        >
          <product-line
            v-if="isHidden(result) === false"
            :ref="`${result._meta.storage}.${result.guid}`"

            :icon="resultIcon(result)"
            :price="resultPrice(result)"

            class="cursor-pointer"

            @click.native.prevent="addProduct(result)"
          >
            <h5 class="product-title">
              {{ result.name }}

              <span
                v-if="resultRequiresCustomer(result)"
                class="pl-2"
              >
                <font-awesome-icon
                  :icon="['far', 'user']"
                  class="kt-font-brand"
                />
              </span>
            </h5>
            <span class="product-subtitle">
              <span
                v-if="result._meta.storage === 'products'"
                class="stock"
              >
                Voorraad: {{ result.stock }}
              </span>
              <span
                v-if="result._meta.storage === 'credit_products'"
                class="credit"
              >
                Krediet: &euro; {{ $format.currency(result.credits) }}
              </span>
            </span>
          </product-line>
        </div>
      </template>
      <template v-else>
        <div class="col-12">
          <KAlert variant="outline-danger">Geen resultaten</KAlert>
        </div>
      </template>
    </div>

  </div>
</template>

<script>
// Classes
import Product from '@/libs/classes/product';
import CreditProduct from '@/libs/classes/credit_product';
import BundleProduct from '@/libs/classes/bundle_product';
import BundleProductSubscription from '@/libs/classes/bundle_product.subscription';
import BundleProductTreatment from '@/libs/classes/bundle_product.treatment';
import BundleProductProducts from '@/libs/classes/bundle_product.products';

// Mixins
import shopMixin from '@/mixins/shop';

export default {
  mixins: [shopMixin],
  props: {
    search: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      timeout: null,
      loading: false,

      lastSearch: null,
      results: []
    };
  },
  computed: {
    filteredResults: {
      get () {
        return this.results.filter(r => {
          if (this.isHidden(r) === false) return r;
        }, []);
      }
    }
  },
  watch: {
    'search' (value) {
      this.multiSearchProductsDebounced(value);
    }
  },

  mounted () {
    if (this.$route.name === '/sales/shop/:cash_register/cart/search/:search') {
      if (this.$route.params.search && this.$route.params.search.length > 0) {
        this.$eventhub.emit('shop:cart:search:value', this.$route.params.search);
        this.multiSearchProductsDebounced(this.$route.params.search);
      } else {
        this.$eventhub.emit('shop:cart:search:value', null);
        this.$router.push({
          name: '/sales/shop/:cash_register/cart/overview'
        });
      }
    }
  },
  beforeDestroy () {
    // Set search value to null on destroy
    clearTimeout(this.timeout);
    this.$eventhub.emit('shop:cart:search:value', null);
  },

  methods: {
    isHidden (result) {
      // TODO: Should be fixed on the BE, not the FE

      // Hide product without price
      if (result._meta.storage === 'products') {
        if (!result.price || !result.price.pricelist) return true;
      }

      // Hide inactive bundle product
      if (result._meta.storage === 'bundle_products') {
        if (result.active === false) return true;
      }

      return false;
    },

    resultIcon (result) {
      if (result._meta.storage === 'products') return ['fad', 'tag'];
      else if (result._meta.storage === 'credit_products') return ['fad', 'credit-card'];
      else if (result._meta.storage === 'session_products') return ['fad', 'link'];
      else if (result._meta.storage === 'bundle_products') {
        if (result.type === 'bundle') return ['fad', 'boxes'];
        else if (result.type === 'subscription') return ['fad', 'user-clock'];
        else if (result.type === 'treatment') return ['fad', 'spa'];
        else if (result.type === 'product_bundle') return ['fad', 'box-full'];
      }

      return ['far', 'question'];
    },
    resultPrice (result) {
      if (result._meta.storage === 'products') return result.price.price_incl_vat;
      else if (result._meta.storage === 'credit_products') return result.price.price_incl_vat;
      else if (result._meta.storage === 'bundle_products') return result.price.price_incl_vat;

      return 0;
    },
    resultRequiresCustomer (result) {
      if (result._meta.storage === 'products') return (result.requires_customer || false);
      else if (result._meta.storage === 'credit_products') return true;
      else if (result._meta.storage === 'bundle_products') {
        if (result.type === 'bundle') return true;
        else if (result.type === 'subscription') return true;
        else if (result.type === 'treatment') return true;
        else if (result.type === 'product_bundle') return false;
      }

      return false;
    },

    multiSearchProductsDebounced (value) {
      clearTimeout(this.timeout);
      this.$set(this, 'loading', true);
      if (typeof value === 'string' && value.length > 0) {
        this.timeout = setTimeout(async () => {
          if (value !== this.lastSearch) await this.multiSearchProducts(value);
          this.$set(this, 'loading', false);
        }, 600);
      }
    },

    async multiSearchProducts (value) {
      this.$set(this, 'loading', true);
      this.$set(this, 'results', []);

      // Add wildcards before search
      value = this.$format.addWildcardPerWord(value);

      // API call for search
      const products = await this.$ws.get('v1', '/products/search', {
        query: {
          limit: 50,
          q: value,
          price_required: true
        }
      });
      this.$set(this, 'lastSearch', value);

      // Create classes of all results
      if (products && products && products.length > 0) {
        products.forEach((row, idx) => {
          if (row._meta && row._meta.storage) {
            if (row._meta.storage === 'products') products[idx] = new Product().mergeResponse(products[idx]);
            else if (row._meta.storage === 'credit_products') products[idx] = new CreditProduct().mergeResponse(products[idx]);
            else if (row._meta.storage === 'bundle_products') {
              if (row.type === 'bundle') products[idx] = new BundleProduct().mergeResponse(products[idx]);
              else if (row.type === 'subscription') products[idx] = new BundleProductSubscription().mergeResponse(products[idx]);
              else if (row.type === 'treatment') products[idx] = new BundleProductTreatment().mergeResponse(products[idx]);
              else if (row.type === 'product_bundle') products[idx] = new BundleProductProducts().mergeResponse(products[idx]);
            }
          }
        });
      }
      this.$set(this, 'results', products);

      this.$set(this, 'loading', false);
    },

    async addProduct (result) {
      let product = null;

      if (result._meta.storage === 'products') {
        product = {
          type: 'product',
          product_name: result.name,
          product_guid: result.guid,
          quantity: 1,
          amount_changeable: result.amount_changeable,
          requires_customer: result.requires_customer
        };
      } else if (result._meta.storage === 'credit_products') {
        product = {
          type: 'credit_product',
          product_name: result.name,
          product_guid: result.guid,
          quantity: 1,
          amount_changeable: true,
          requires_customer: true
        };
      } else if (result._meta.storage === 'bundle_products') {
        product = {
          type: 'bundle',
          bundle_name: result.name,
          bundle_guid: result.guid,
          quantity: 1
        };
      }

      if (product !== null) {
        this.$eventhub.emit('shop:cart:set-loading', true);
        try {
          await this.shop.addProductToCart(product);
        } catch (e) {
          console.error(e);
        }
        this.$eventhub.emit('shop:cart:set-loading', false);
      }
    }
  }
};
</script>
