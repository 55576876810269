import Abstract from './abstract';

// Libraries
import auth from '@/libs/auth';
import { merge, cloneDeep } from 'lodash';
import { apiWS as ws } from '@/libs/ws';

// Classes
import StockBatchMutations from './stock_batch.mutation';
import StockMutation from './stock_mutation';
import User from './user';

class StockBatch extends Abstract {
  constructor (guid = null) {
    super(guid);
    this.uri = '/stock_batches';

    let defaultObject = {
      type: null,
      mutations: [],
      status: null,
      remark: null
    };

    merge(this, defaultObject);
  }

  // Methods
  formatBody (type = 'get', options = {}) {
    let body = super.formatBody(type, options);

    // Format mutations
    if (this.mutations && this.mutations.length) {
      const mutations = cloneDeep(this.mutations);
      mutations.forEach((row, idx) => {
        mutations[idx] = mutations[idx].formatBody(type, options);
      });
      body.mutations = mutations;
    }

    return body;
  }

  mergeResponse (response = {}) {
    if (response.status === 'draft') {
      if (response.mutations && response.mutations.length) {
        response.mutations.forEach((row, idx) => {
          response.mutations[idx] = new StockBatchMutations().mergeResponse(Object.assign({
            type: response.type
          }, response.mutations[idx]));
        });
      }
    }
    return super.mergeResponse(response);
  }

  async getStockMutations (options = {}) {
    if (this.status === 'final') {
      const mutations = await ws.get('v1', `/stock_mutations/batch/${this.guid}`, options);
      mutations.forEach((row, idx) => {
        mutations[idx] = new StockMutation().mergeResponse(mutations[idx]);
      });
      this.mutations = mutations;
    }

    return this.mutations;
  }

  async populate (type = 'get', options = {}) {
    // Get stock of products of count draft
    if (this.type === 'count' && this.status === 'draft') {
      if (this.mutations.length) {
        const products = await ws.get('v1', '/products', {
          query: {
            format: 'datatable',
            fields: ['stock'],
            'filter[_meta.guid]': this.mutations.map(m => m.product_guid, []).join(',')
          }
        });

        // Set stock values of mutations to the current stock, if found
        if (products && products.data && products.data.length) {
          this.mutations.forEach((row, idx) => {
            if (products.data.some(p => p._meta.guid === this.mutations[idx].product_guid)) {
              this.mutations[idx].stock = products.data.find(p => p._meta.guid === this.mutations[idx].product_guid).stock;
            }
          });
        }
      }
    }

    // Populate functions when state is final
    if (this.status === 'final') {
      // Get stock mutations when the state is final
      await this.getStockMutations(options);

      // Get user that finished stock batch
      if (this.finished_user_guid) {
        // Check if own user
        if (this.finished_user_guid === auth.user.guid) {
          this.finished_user = auth.user;
        } else if (auth.hasPermission([['get_users']])) {
          this.finished_user = await new User(this.finished_user_guid).get(options);
        }
      }
    }

    return this;
  }

  async submit (options = {}) {
    return ws.put('v1', `${this.uri}/${this.guid}/submit`);
  }
}

export default StockBatch;
