// Classes
import Abstract from './abstract';
import Session from './session';

// Libraries
import { merge } from 'lodash';
import { apiWS as ws } from '@/libs/ws';

class Customer extends Abstract {
  constructor (guid = null) {
    super(guid);
    this.uri = '/customers';

    let defaultCustomer = {
      firstname: null,
      lastname: null,
      gender: 'HUMAN',

      date_of_birth: null,

      citizenServiceNumber: null,
      documentNumber: null,

      email: null,
      phone: null,

      skinType: null,

      address: {
        country: null,
        postalCode: null,
        houseNumber: null,

        line1: null,
        city: null
      },

      marketing_opt_in: false,

      fingerprint_keys: [],

      authenticated: null
    };

    merge(this, defaultCustomer);
  }

  // Methods
  formatBody (type = 'get', options = {}) {
    let body = super.formatBody(type, options);

    // Remove authenticated
    delete body.authenticated;

    return body;
  }

  async populate (type = 'get', options = {}) {
    // Get if user is authenticated
    this.authenticated = await this.isAuthenticated(options);

    return this;
  }

  async getLastSession (options = {}) {
    const response = await ws.get('v1', '/sessions', merge({
      query: {
        'filter[customer_guid]': this.guid,
        'filter[paid]': true,
        'filter[used]': true,

        sort: 'started_timestamp|desc',
        limit: 1
      }
    }, options));

    if (response && response.length > 0) {
      const session = new Session().mergeResponse(response[0]);
      session.equipment = await session.getEquipment();
      return session;
    }

    return null;
  }

  async getBundlesForEquipmentGroup (equipmentGroupGuid = null, options = {}) {
    return ws.get('v1', `/bundles/sessions/${this.guid}/${equipmentGroupGuid}`, options);
  }

  async isAuthenticated (options = {}) {
    const response = await ws.get('v1', `/customers/${this.guid}/shop/authenticated`, options);

    // Set authenticated
    if (typeof response.authenticated === 'boolean') {
      this.authenticated = response.authenticated;
    }

    return this.authenticated;
  }

  // Getters & Setters
  get name () {
    return [this.firstname, this.lastname].join(' ');
  }
  get initials () {
    let initials = this.name.match(/\b\w/g) || [];
    return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
  }
}

export default Customer;
