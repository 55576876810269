<template>
  <div class="row form-group">
    <div class="col-12 col-lg-4">
      <label>E-mailadres <sup>Verplicht</sup></label>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">
            <i><font-awesome-icon :icon="['fad', 'at']" /></i>
          </span>
        </div>
        <input
          :placeholder="(placeholder ? 'jan.janssen@email.nl' : undefined)"
          v-model.trim="$v.customer.email.$model"
          :class="['form-control', {'is-invalid': $v.customer.email.$error}]"
          :disabled="disabled"
          type="email"
        >
      </div>
    </div>

    <div class="col-12 mt-3 col-lg-4 mt-lg-0">
      <label>Telefoonnummer</label>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">
            <i>
              <font-awesome-icon :icon="['fad', 'phone-alt']" />
            </i>
          </span>
        </div>
        <input
          v-model="customer.phone"
          :placeholder="(placeholder ? '0612345678' : undefined)"
          :disabled="disabled"
          type="phone"
          class="form-control"
        >
      </div>
    </div>

    <div class="col-12 mt-3 col-lg-4 mt-lg-0">
      <label>Huidtype <sup>Verplicht</sup></label>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">
            <i><font-awesome-icon :icon="['fad', 'allergies']" /></i>
          </span>
        </div>
        <select
          v-model.number="$v.customer.skinType.$model"
          :class="['form-control', {'is-invalid': $v.customer.skinType.$error}]"
          :disabled="disabled"
        >
          <option
            :value="null"
            disabled
          >
            Selecteer huidtype
          </option>

          <option value="1">Type 1</option>
          <option value="2">Type 2</option>
          <option value="3">Type 3</option>
          <option value="4">Type 4</option>
        </select>
        <div
          v-if="hasSkintypeCalculator"
          class="input-group-append"
        >
          <span class="input-group-text">
            <i
              class="kt-font-focus cursor-pointer"
              @click.prevent="$eventhub.emit('modals:customers:skintype-calculator:open', {
                region: $stores.activeStore.settings.region
              })"
            >
              <font-awesome-icon :icon="['fad', 'list-alt']" />
            </i>
          </span>
        </div>
      </div>
    </div>

    <skintype-calculator
      ref="skintypeCalculator"
    />

  </div>
</template>

<script>
// Classes
import Customer from '@/libs/classes/customer';

// Components
import skintypeCalculator from '@/components/customers/components/skintype-calculator/index';

// Validations
import { required, email } from 'vuelidate/lib/validators';

export default {
  components: {
    skintypeCalculator
  },
  props: {
    customer: {
      type: Customer,
      default: null
    },

    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    hasSkintypeCalculator: {
      get () {
        if (this.$stores.activeStore && this.$stores.activeStore.settings) {
          return skintypeCalculator.methods.isRegionSupported(this.$stores.activeStore.settings.region);
        }
      }
    }
  },
  validations: {
    customer: {
      email: {
        required,
        email
      },
      skinType: {
        required
      }
    }
  },

  mounted () {
    // Listeners
    this.$eventhub.on('modals:customers:skintype-calculator:submit', this.onSkintypeCalculator);
  },
  beforeDestroy () {
    // Remove listeners
    this.$eventhub.off('modals:customers:skintype-calculator:submit', this.onSkintypeCalculator);
  },

  methods: {
    onSkintypeCalculator (object = null) {
      if (object && object.skintype) {
        this.$v.customer.skinType.$model = object.skintype;
      }
    }
  }
};
</script>
