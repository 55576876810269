<template>
  <div>
    <div class="row form-group">
      <div class="col-12 col-lg-4">
        <label>Naam <sup>Verplicht</sup></label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i><font-awesome-icon :icon="['fad', 'text']" /></i>
            </span>
          </div>
          <input
            v-model.trim="$v.equipment.name.$model"
            :placeholder="(placeholder ? 'Zonnebank 1' : undefined)"
            :class="['form-control', {'is-invalid': $v.equipment.name.$error}]"
            :disabled="isDisabled"

            type="text"
          >
        </div>
      </div>

      <div class="col-12 mt-3 col-lg-4 mt-lg-0">
        <label>Kamernaam <sup>Verplicht</sup></label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i><font-awesome-icon :icon="['fad', 'text']" /></i>
            </span>
          </div>
          <input
            v-model.trim="$v.equipment.room.$model"
            :placeholder="(placeholder ? 'Kamer 1' : undefined)"
            :class="['form-control', {'is-invalid': $v.equipment.room.$error}]"
            :disabled="isDisabled"

            type="text"
          >
        </div>
      </div>

      <div class="col-12 mt-3 col-lg-4 mt-lg-0">
        <label>Standaard sessie <sup>Verplicht</sup></label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i><font-awesome-icon :icon="['fad', 'stopwatch']" /></i>
            </span>
          </div>
          <v-select
            ref="searchSessions"
            :class="['form-control', {'is-invalid': $v.equipment.default_session_guid.$error}]"
            :filterable="false"
            :options="search.sessions"
            :disabled="isDisabled"
            v-model.trim="equipment.default_session"

            label="search_name"

            @input="sessionsInputChanged"
            @search="fetchSessionsDebounced"
            @search:focus="fetchSessions"
          >
            <template
              slot="option"
              slot-scope="option"
            >
              <span>{{ option.search_name }}</span>
            </template>

            <template
              slot="spinner"
              slot-scope="spinner"
            >
              <div
                v-if="spinner.loading"
                class=" kt-spinner kt-spinner--v2 kt-spinner--primary"
              />
            </template>
          </v-select>
        </div>
      </div>
    </div>

    <KSeparator large />

    <div class="row form-group">
      <div class="col-12 col-lg-4">
        <label>Apparaat Groep</label>

        <!-- Show when equipment groups can be fetched -->
        <template v-if="$auth.hasPermission('get_equipment_groups')">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i><font-awesome-icon :icon="['fad', 'cogs']" /></i>
              </span>
            </div>
            <select
              v-model.trim="$v.equipment.group_guid.$model"
              :class="['form-control', {'is-invalid': $v.equipment.group_guid.$error}]"
              :disabled="isDisabled"
            >
              <option
                :value="null"
                disabled
              >
                Selecteer groep
              </option>
              <option
                v-for="(group) in equipmentGroups"
                :key="`equipment.group.${group.guid}`"
                :value="group._meta.guid"
              >
                {{ group.name }}
              </option>

              <option
                v-if="equipmentGroups && equipmentGroups.length > 0"
                disabled
              >
                ──────────
              </option>
              <option
                value="__action:showEquipmentGroupModal"
              >
                Nieuwe groep aanmaken...
              </option>
            </select>
          </div>
        </template>

        <template v-else>
          <div class="input-group">
            <KButton
              tag="span"
              variant="label-focus"
            >
              Geen permissie
            </KButton>
          </div>
        </template>
      </div>

      <div class="col-12 mt-3 col-lg-4 mt-lg-0">
        <label>CSID</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">
              CSID
            </span>
          </div>
          <input
            v-model.trim="$v.equipment.serial.$model"
            :placeholder="(placeholder ? 'XXXXXXXXXXXXXXXXX' : undefined)"
            :class="['form-control', {'is-invalid': $v.equipment.serial.$error}]"
            :disabled="isDisabled"

            type="text"
          >
        </div>
      </div>

      <div class="col-12 mt-3 col-lg-4 mt-lg-0">
        <label>Caesium Core</label>

        <template v-if="$auth.hasPermission('get_masters')">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i><font-awesome-icon :icon="['fad', 'server']" /></i>
              </span>
            </div>
            <select
              v-model.trim="$v.equipment.master_guid.$model"
              :class="['form-control', {'is-invalid': $v.equipment.master_guid.$error}]"
              :disabled="isDisabled"
            >
              <option
                :value="null"
                disabled
              >
                Selecteer Caesium Core
              </option>

              <option
                v-for="(master) in masters"
                :key="`equipment.master.${master.guid}`"
                :value="master._meta.guid"
              >
                {{ (typeof master.name === 'string' && master.name.length > 0 ? `${master.name} (${master.serial})` : master.serial) }}
              </option>
            </select>
          </div>
        </template>

        <template v-else>
          <div class="input-group">
            <KButton
              tag="span"
              variant="label-focus"
            >
              Geen permissie
            </KButton>
          </div>
        </template>
      </div>
    </div>

    <KSeparator large />

    <model-row
      ref="modelRow"

      :equipment="equipment"
      :disabled="isDisabled"
    />

    <div class="row form-group">
      <div class="col-12 col-lg-4">
        <label>Energieverbruik</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i><font-awesome-icon :icon="['fad', 'bolt']" /></i>
            </span>
          </div>
          <input
            v-model.number="$v.equipment.energy_consumption.$model"
            :placeholder="(placeholder ? '' : undefined)"
            :class="['form-control', {'is-invalid': $v.equipment.energy_consumption.$error}]"
            :disabled="isDisabled"

            type="number"
          >
          <div class="input-group-append">
            <span class="input-group-text">kW</span>
          </div>
        </div>
      </div>

      <div class="col-12 mt-3 col-lg-4 mt-lg-0">
        <label>UV type</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i><font-awesome-icon :icon="['fad', 'sun']" /></i>
            </span>
          </div>
          <input
            v-model.trim="$v.equipment.uv_type.$model"
            :placeholder="(placeholder ? '' : undefined)"
            :class="['form-control', {'is-invalid': $v.equipment.uv_type.$error}]"
            :disabled="isDisabled"

            type="text"
          >
        </div>
      </div>

      <div class="col-12 mt-3 col-lg-4 mt-lg-0">
        <label>Straling</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i><font-awesome-icon :icon="['fad', 'signal-stream']" /></i>
            </span>
          </div>
          <input
            v-model.trim="$v.equipment.radiation.$model"
            :placeholder="(placeholder ? '' : undefined)"
            :class="['form-control', {'is-invalid': $v.equipment.radiation.$error}]"
            :disabled="isDisabled"

            type="text"
          >
        </div>
      </div>
    </div>

    <equipment-group-modal
      ref="equipmentGroupModal"
    />
  </div>
</template>

<script>
// Classes
import Equipment from '@/libs/classes/equipment';
import SessionProduct from '@/libs/classes/session_product';

// Validations
import { required, minValue } from 'vuelidate/lib/validators';
import { CSID } from '@/libs/validators/index';

// Components
import equipmentGroupModal from '@/components/equipment/groups/components/modal';
import modelRow from '@/components/equipment/components/model.row';
import vSelect from 'vue-select';
import '@/assets/sass/vue-select/vue-select.scss';

export default {
  components: {
    equipmentGroupModal,
    modelRow,
    vSelect
  },
  props: {
    action: {
      type: String,
      default: 'VIEW'
    },

    equipment: {
      type: Equipment,
      default: null
    },

    equipmentGroups: {
      type: Array,
      default: null
    },
    masters: {
      type: Array,
      default: null
    },

    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      search: {
        sessionsTimeout: null,
        sessions: []
      },

      modals: {
        group: {
          group: null
        }
      }
    };
  },
  computed: {
    isDisabled () {
      return (this.disabled === true || this.action === 'VIEW');
    }
  },
  watch: {
    'equipment.group_guid' (newValue, oldValue) {
      if (newValue === '__action:showEquipmentGroupModal') {
        this.$refs.equipmentGroupModal.$off('modal:submit');
        this.$refs.equipmentGroupModal.show();
        this.$refs.equipmentGroupModal.$on('modal:submit', (group) => {
          this.equipmentGroups.unshift(group);
          this.equipment.group_guid = group._meta.guid;
        });

        this.$v.equipment.group_guid.$model = oldValue;
        this.$v.equipment.group_guid.$reset();
      }
    },
    'equipment.default_session' (newValue, oldValue) {
      if (newValue instanceof SessionProduct) {
        this.$v.equipment.default_session_guid.$model = newValue._meta.guid;
      } else {
        this.$v.equipment.default_session_guid.$model = null;
      }
      return this.$v.equipment.default_session_guid.$model;
    }
  },
  validations: {
    equipment: {
      name: {
        required
      },
      room: {
        required
      },
      default_session_guid: {
        required
      },

      group_guid: {
        required
      },
      serial: {
        CSID
      },
      master_guid: {
        required
      },

      brand: {

      },
      model: {

      },
      type: {

      },
      energy_consumption: {
        minValue: minValue(0)
      },
      uv_type: {

      },
      radiation: {

      }
    }
  },

  beforeMount () {
    // Set search name of default session
    if (this.equipment && this.equipment.default_session) {
      this.equipment.default_session.search_name = this.equipment.default_session.session_name;
    }
  },

  methods: {
    async searchSessions (search) {
      const options = {
        query: Object.assign({}, {
          q: search,
          limit: 5,
          sort: 'duration|asc'
        })
      };

      let response = await this.$ws.get('v1', '/session_products', options);
      response = response.map(session => {
        session = new SessionProduct().mergeResponse(session);
        session.search_name = session.session_name;
        return session;
      }, []);

      return response;
    },

    async fetchSessions (search) {
      const loading = this.$refs['searchSessions'].toggleLoading;
      loading(true);
      clearTimeout(this.search.sessionsTimeout);
      this.$set(this.search, 'sessions', []);
      let data = await this.searchSessions(search);
      this.$set(this.search, 'sessions', data);
      loading(false);
    },

    fetchSessionsDebounced (search) {
      const loading = this.$refs['searchSessions'].toggleLoading;
      clearTimeout(this.search.sessionsTimeout);
      loading(true);
      this.search.sessionsTimeout = setTimeout(async () => {
        await this.fetchSessions(search);
      }, 600);
    },

    sessionsInputChanged (input) {
      // Stop timeout and loading when input has changed
      this.$nextTick(() => {
        clearTimeout(this.search.sessionsTimeout);
        const loading = this.$refs['searchSessions'].toggleLoading;
        loading(false);
      });
    }
  }
};
</script>
