<template>
  <div class="mutation-row row">
    <div class="col-12 col-lg-11">
      <div class="row form-group">
        <div class="col-10 col-lg-12 col-xl-6">
          <label :class="[{'d-xl-none': firstRow === false}]">Naam</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i><font-awesome-icon :icon="['fad', 'tag']" /></i>
              </span>
            </div>
            <input type="text" class="form-control" :value="mutation.product_name" disabled="disabled" />
          </div>
        </div>

        <div class="col-2 label-margin d-lg-none">
          <KButton v-if="type === 'MUTATION'" variant="light" class="btn-block" v-on:click.native.prevent="removeMutation(mutation)">
            <font-awesome-icon icon="trash" />
          </KButton>

          <KButton v-else-if="type === 'MISSED'" variant="focus" class="btn-block btn-bold" v-on:click.native.prevent="mutation.quantity = 0">
            0
          </KButton>
        </div>

        <div class="col-4 mt-3 col-lg-4 col-xl-2 mt-xl-0">
          <label :class="[{'d-xl-none': firstRow === false}]">Voorraad</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i><font-awesome-icon :icon="['fad', 'inventory']" /></i>
              </span>
            </div>
            <input type="number" class="form-control" :value="mutation.stock" disabled />
          </div>
        </div>

        <div class="col-4 mt-3 col-lg-4 col-xl-2 mt-xl-0">
          <label :class="[{'d-xl-none': firstRow === false}]">Aantal</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i><font-awesome-icon :icon="['fad', 'hashtag']" /></i>
              </span>
            </div>
            <input type="number" ref="input.quantity" class="form-control" v-model.number.lazy="$v.mutation.quantity.$model" v-on:change="onChangeQuantity" :class="[{'is-invalid': $v.mutation.quantity.$error}]" />
          </div>
        </div>

        <div class="col-4 mt-3 col-lg-4 col-xl-2 mt-xl-0">
          <label :class="[{'d-xl-none': firstRow === false}]">Verschil</label>
          <div class="input-group" :class="[{'input-group-warning': (this.mutation.quantity - this.mutation.stock) !== 0}]">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i><font-awesome-icon :icon="['fad', 'hashtag']" /></i>
              </span>
            </div>
            <input type="number" class="form-control" :value="(typeof this.mutation.quantity === 'number' ? (this.mutation.quantity - this.mutation.stock) : null)" disabled />
          </div>
        </div>
      </div>
    </div>

    <div class="d-none d-lg-block d-xl-none col-1 mt-5">
      <KButton v-if="type === 'MUTATION'" variant="light" class="btn-block label-margin" v-on:click.native.prevent="removeMutation(mutation)">
        <font-awesome-icon icon="trash" />
      </KButton>

      <KButton v-else-if="type === 'MISSED'" variant="focus" class="btn-block btn-bold" :class="[{'label-margin': firstRow === true}]" v-on:click.native.prevent="mutation.quantity = 0">
        Naar 0
      </KButton>
    </div>

    <div class="d-none d-xl-block col-1">
      <KButton v-if="type === 'MUTATION'" variant="light" class="btn-block" :class="[{'label-margin': firstRow === true}]" v-on:click.native.prevent="removeMutation(mutation)">
        <font-awesome-icon icon="trash" />
      </KButton>

      <KButton v-else-if="type === 'MISSED'" variant="focus" class="btn-block btn-bold" :class="[{'label-margin': firstRow === true}]" v-on:click.native.prevent="mutation.quantity = 0">
        Naar 0
      </KButton>
    </div>
  </div>
</template>

<script>
// Classes
import StockBatchMutation from '@/libs/classes/stock_batch.mutation';

// Validators
import { requiredIf, minValue } from 'vuelidate/lib/validators';

export default {
  props: {
    type: {
      type: String,
      default: 'MUTATION'
    },
    firstRow: Boolean,
    lastRow: Boolean,

    mutation: StockBatchMutation,

    removeMutation: Function,
    save: Function,
  },
  validations: {
    mutation: {
      quantity: {
        required: requiredIf(function(value) {
          return this.type === 'MUTATION';
        }),
        minValue: minValue(0)
      }
    }
  },

  methods: {
    async onChangeQuantity () {
      if (this.type === 'MUTATION') await this.save();
    },

    focus (input = 'quantity') {
      if (typeof this.$refs[`input.${input}`] !== typeof undefined) {
        this.$refs[`input.${input}`].focus();
      }
    }
  }
}
</script>
