// Libraries
import { merge } from 'lodash';
import auth from '@/libs/auth';

// Classes
import Abstract from './abstract';

class Resource extends Abstract {
  constructor (guid = null) {
    super(guid);

    const defaultObject = {
      permissions: null
    };
    merge(this, defaultObject);
  }

  // Methods
  async update (mutations = [], options = {}) {
    await super.update(mutations, options);

    // Check if update is current user
    if (auth.resource && auth.resource.guid === this.guid) {
      auth.resource = this;
    }

    return this;
  }

  async remove (options = {}) {
    // Check if resource is current auth
    // Because if it is, throw an error
    if (this.guid === auth.resource.guid) {
      throw new Error('Removing current resource is not allowd');
    }

    return super.remove(options);
  }

  async getPermissions (options = {}) {
    throw new Error('Get permissions failed: Resource undefined');
  }

  hasPermission (permissionsArray, options = {}) {
    // If only string is given, convert to array
    if (typeof permissionsArray === 'string') permissionsArray = [permissionsArray];

    // Return true if no permissions given
    if (!permissionsArray || permissionsArray.length === 0) {
      return true;
    }

    // Return false if no permissions found in current resource
    if (!this.permissions || this.permissions.length === 0) {
      return false;
    }

    return (permissionsArray || []).every((permissions) => {
      // If only string is given, convert to array
      if (typeof permissions === 'string') permissions = [permissions];

      return (permissions || []).some((permission) => {
        // If this permission is explicit granted, we allow it over the wildcard
        if (this.permissions[permission] === true) {
          return true;
        }

        // If this permission is explicit disallowed, we prioritize it over the wildcard
        if (this.permissions[permission] === false) {
          return false;
        }

        // If the permission is not defined, we check for the wildcard
        // Second check is that * shouldn't work for admin permissions, those should be given explicitly
        if (this.permissions['*'] === true && permission.includes('admin') === false) {
          return true;
        }

        // Do you even have permissions bro?
        return false;
      });
    });
  }
};

export default Resource;
