<template>
  <KModal ref="groupModal" size="xl" centered v-keydown="{'escape': hide}" :title="(action === 'CREATE' ? 'Groep aanmaken' : 'Groep wijzigen')">

    <groupForm ref="groupForm"
      :action="action"

      :group="group"
    ></groupForm>

    <template v-slot:footer>
      <div class="col-12 col-lg-6">
        <KButton variant="primary" class="btn-md btn-block btn-tall btn-wide btn-bold btn-upper" @click.native.prevent="submitForm">Aanmaken</KButton>
      </div>
    </template>

  </KModal>
</template>

<script>
// Libraries
import { cloneDeep } from 'lodash';

// Classes
import EquipmentGroup from '@/libs/classes/equipment_group';

// Components
import groupForm from './form';

export default {
  props: {
    action: {
      type: String,
      default: 'CREATE'
    },

    group: {
      type: EquipmentGroup,
      default: () => new EquipmentGroup()
    }
  },
  data () {
    return {
      _group: null
    }
  },
  components: {
    groupForm
  },
  methods: {
    hide() {
      this.$refs.groupModal.hide();
    },
    show() {
      if (this.group && this.group instanceof EquipmentGroup) {
        if (typeof this.group.guid === 'string' && this.group.guid.length > 0) {
          this.$set(this.$data, '_group', cloneDeep(this.group));
        }
      }

      this.$refs.groupModal.show();
    },

    async validate() {
      const validationArray = [];
      validationArray.push(this.$refs.groupForm.$v);

      // Execute validations
      if(validationArray.length > 0) {
        validationArray.forEach(v => v && v.$touch && v.$touch())
        if(validationArray.filter(v => v && v.$invalid).length !== 0) return false;
      }

      return true;
    },
    async submitForm() {
      let valid = await this.validate();
      if(valid) {

        if (typeof this.group.guid === 'string' && this.group.guid.length > 0) {
          const fields = ['name', 'pre_run_time', 'post_run_time', 'quickstart', 'door_contact', 'clean_after', 'package_only', 'accept_credits'];
          const mutations = mutationsLib.create(fields, this.group.formatBody(), this.$data._group.formatBody());

          if (mutations.length) {
            await this.group.update(mutations);
            this.$set(this.$data, '_group', cloneDeep(this.group));
            this.$emit('modal:submit', this.group);
          }
        } else {
          await this.group.create(this.group.formatBody());
          this.$emit('modal:submit', this.group);
        }

        return this.$refs.groupModal.hide();
      }
    }
  }
}
</script>
