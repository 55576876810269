<template>
  <div class="cs-shop-breadcrumbs">
    <div class="cs-shop-breadcrumbs__title">{{ title }}</div>
    <div class="cs-shop-breadcrumbs__breadcrumbs" v-if="breadcrumbs && breadcrumbs.length">
      <a class="cs-shop-breadcrumbs__breadcrumbs-home" v-on:click.prevent="navigateTo('/sales/shop/:cash_register/cart/overview')">
        <i>
          <font-awesome-icon :icon="['fad', 'home']" />
        </i>
      </a>

      <template v-for="(breadcrumb, idx) in breadcrumbs">
        <span class="cs-shop-breadcrumbs__breadcrumbs-separator"></span>
        <a class="cs-shop-breadcrumbs__breadcrumbs-link" v-on:click.prevent="navigateTo(breadcrumb.route)">{{ breadcrumb.title }}</a>
      </template>

    </div>
  </div>
</template>

<style lang="scss">
@import '@/assets/sass/shop/breadcrumbs.scss';
</style>

<script>
// Mixins
import shopMethodsMixin from '@/mixins/shop.methods';

export default {
  mixins: [shopMethodsMixin],
  props: {
    title: String,
    breadcrumbs: Array
  }
}
</script>
