<template>
  <div class="row form-group">
    <div class="col-12 col-lg-4">
      <label>Geboortedatum <sup>Verplicht</sup></label>
      <birthday-input-group
        v-model="$v.customer.date_of_birth.$model"
        :is-invalid="$v.customer.date_of_birth.$error"
        :disabled="disabled"
      />
    </div>

    <div class="col-12 mt-3 col-lg-4 mt-lg-0">
      <label>Burgerservicenummer</label>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">
            <i><font-awesome-icon :icon="['fad', 'text']" /></i>
          </span>
        </div>
        <input
          :disabled="disabled"
          v-model="customer.citizenServiceNumber"

          type="text"
          class="form-control"
        >
      </div>
    </div>

    <div class="col-12 mt-3 col-lg-4 mt-lg-0">
      <label>Document nummer</label>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">
            <i><font-awesome-icon :icon="['fad', 'id-card']" /></i>
          </span>
        </div>
        <input
          v-model="customer.documentNumber"
          :disabled="disabled"

          type="text"
          class="form-control"
        >
      </div>
    </div>
  </div>
</template>

<script>
// Libraries
import { required } from 'vuelidate/lib/validators';
import { date } from '@/libs/validators';

// Classes
import Customer from '@/libs/classes/customer';

// Components
import birthdayInputGroup from '@/components/customers/components/birthdayInputGroup';

export default {
  components: {
    birthdayInputGroup
  },
  props: {
    customer: {
      type: Customer,
      default: null
    },

    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: Boolean,
      default: true
    }
  },
  validations: {
    customer: {
      date_of_birth: {
        required,
        date
      }
    }
  }
};
</script>
