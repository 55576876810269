<template>
  <div class="datatable-table">

    <template v-if="loading">
      <div class="loading-container">
        <div class="loading-backdrop" />
        <div class="loading-block">
          <div class="blockui">
            <span>Please wait...</span>
            <span class="kt-spinner kt-spinner--v2 kt-spinner--primary" />
          </div>
        </div>
      </div>
    </template>

    <vuetable
      ref="vuetable"
      :api-url="url"
      :load-on-start="loadOnStart"
      :http-fetch="fetch"
      :css="computedCss"
      :fields="fields"
      :no-data-template="noDataTemplate"
      :multi-sort="multiSort"

      pagination-path=""

      @vuetable:loading="onLoading"
      @vuetable:loaded="onLoaded"
      @vuetable:pagination-data="onPaginationDataWrapper"
      @vuetable:cell-clicked="onCellClicked"
      @vuetable:row-clicked="onRowClicked"
    >
      <!-- Parse normal slots -->
      <slot
        v-for="(_, name) in $slots"
        :slot="name"
      />

      <!-- Parse scoped slots -->
      <template
        v-for="(_, name) in $scopedSlots"
        slot-scope="props"
        :slot="name"
      >
        <slot
          :name="name"
          v-bind="props"
        />
      </template>

    </vuetable>

  </div>
</template>

<script>
// Libraries
import VNodeToHTML from '@/util/VNodeToHTML';
import { merge } from 'lodash';

// Components
import Vuetable from 'vuetable-2/src/components/Vuetable';

export default {
  components: {
    Vuetable
  },
  props: {
    url: {
      type: String,
      default: null
    },
    loadOnStart: {
      type: Boolean,
      default: true
    },
    fields: {
      type: Array,
      default: () => []
    },
    filters: {
      type: Object,
      default: () => {}
    },
    css: {
      type: Object,
      default: () => {}
    },
    searchFields: {
      type: Array,
      default: () => []
    },
    multiSort: {
      type: Boolean,
      default: false
    },
    transform: {
      type: Function,
      default: undefined
    },

    onPaginationData: {
      type: Function,
      default: () => {}
    },
    onCellClicked: {
      type: Function,
      default: () => {}
    },
    onRowClicked: {
      type: Function,
      default: () => {}
    },
    httpFetch: {
      type: Function,
      default: null
    }
  },
  data () {
    return {
      initiated: false,

      perPage: 10,

      searchData: {
        value: null,
        timeout: null,

        query: null,
        fields: [],
        filters: {}
      },
      refreshOptions: null,
      noDataTemplate: '',
      loading: false,

      defaultCss: {
        tableClass: 'table',
        ascendingIcon: 'arrow-up',
        descendingIcon: 'arrow-down',
        ascendingClass: 'sort sort-asc',
        descendingClass: 'sort sort-desc',
        renderIcon: (classes, options) => {
          let HTML = document.createElement('i');

          // Check if sort-icon
          if (classes.indexOf('sort-icon') !== -1) {
            // Add sort-icon to class list
            HTML.classList.add('sort-icon');
          }

          // Create icon
          let icon = classes.filter(c => typeof c !== typeof undefined && c !== 'sort-icon');
          if (icon.length > 0) {
            // Add default icon library for font-awesome
            icon.unshift('fas');

            // Create icon
            let faIcon = this.$createElement('font-awesome-icon', {
              props: {
                icon: icon
              }
            });

            // Check if icon exists
            if (typeof faIcon.tag !== typeof undefined) HTML.innerHTML += VNodeToHTML(faIcon).outerHTML;
          }

          return HTML.outerHTML;
        }
      }
    };
  },
  computed: {
    computedCss () {
      return merge(this.defaultCss, this.css);
    },
    computedSearchFields () {
      return this.searchData.fields.concat(this.searchFields);
    },
    computedFilters () {
      return merge(this.searchData.filters, this.filters);
    }
  },
  methods: {
    refresh (options = {}) {
      this.refreshOptions = options;
      return this.$refs['vuetable'].refresh();
    },
    reload (options = {}) {
      this.refreshOptions = options;
      return this.$refs['vuetable'].reload();
    },

    onLoading () {
      this.loading = true;
      this.noDataTemplate = 'Loading data...';
    },
    onLoaded () {
      if (this.initiated === false) this.initiated = true;
      this.loading = false;
      this.noDataTemplate = 'Geen data beschikbaar';
    },
    onChangePage (page) {
      this.$refs['vuetable'].changePage(page);
    },
    onPaginationDataWrapper (paginationData) {
      this.onPaginationData(paginationData);
    },
    onChangeRowCount (count) {
      if (this.perPage !== count) {
        this.perPage = count;
        this.$nextTick(() => this.$refs.vuetable.refresh());
      }
    },

    searchDebounced (value) {
      clearTimeout(this.searchData.timeout);
      this.searchData.timeout = setTimeout(() => this.search(value), 600);
    },
    search (value) {
      clearTimeout(this.searchData.timeout);

      // Spot event
      if (typeof value.target !== typeof undefined) value = value.target.value;

      // Add wildcard
      value = this.$format.addWildcardPerWord(value);

      // Only search when the value is changed
      if (value !== this.searchData.query) {
        this.searchData.query = value;
        this.$nextTick(() => this.$refs.vuetable.refresh());
      }
    },

    async fetch (apiUrl, httpOptions, options = {}) {
      // Check if http-fetch prop is defined, because this overrules the fetch function
      if (options.skipHttpFetch !== true && this.httpFetch !== null && typeof this.httpFetch === 'function') {
        return this.httpFetch(apiUrl, httpOptions);
      } else {
        // Refresh options
        if (typeof this.refreshOptions === 'object' && this.refreshOptions !== null) {
          if (typeof this.refreshOptions.query === 'object') merge(httpOptions.params, this.refreshOptions.query);
          this.refreshOptions = null;
        }

        // Setup search
        if (typeof this.searchData.query === 'string' && this.searchData.query.length > 0) {
          httpOptions.params.q = this.searchData.query;
          httpOptions.params.search_fields = this.computedSearchFields.join(',');
        }

        // Setup filters
        if (typeof this.computedFilters === 'object' && Object.keys(this.computedFilters).length > 0) {
          Object.keys(this.computedFilters).forEach((filter, idx) => {
            httpOptions.params[`filter[${filter}]`] = this.computedFilters[filter];
          });
        }

        // Setup per page
        httpOptions.params.per_page = this.perPage;

        const data = await this.$ws.get('v1', apiUrl, {
          query: Object.assign({}, httpOptions.params, {
            format: 'datatable'
          })
        });

        return {data};
      }
    }
  }
};
</script>
