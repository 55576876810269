// Libraries
import numeral from 'numeral';
import calculate from '@/libs/calculate';
import stores from '@/libs/stores';

// TODO: Currently in main.js we load only nl-nl locale
// This should be a setting, or auto detected based on users locale (navigator.language?)

export default class Format {
  // Format to currency (not for inputs)
  static currency (input) {
    // Calculate price
    input = calculate.price(input);

    // Round input when e (scientific notation) is found, because this can cause nasty issues
    if (String(input).indexOf('e') >= 0) {
      input = Math.round(input);
    }

    // Format with numeral
    return numeral(input).format('0,0.00');
  }

  // Format currency for input (so just a number)
  static currencyToInput (input) {
    return calculate.price(input).toFixed(2);
  }

  static inputToCurrency (input) {
    if (typeof input === 'string' && input.length === 0) return input;
    input = input.replace(',', '.'); // TODO: Maybe want this locale based?
    return Number(input).toFixed(2);
  }

  static percentage (input) {
    if (typeof input === 'string') input = Number(input);
    return (input * 100).toFixed(1);
  }

  static time (input) {
    return numeral(input).format('00');
  }

  static secondsToTime (seconds) {
    seconds = Number(seconds);

    // Calculate minutes and the remaining seconds
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = (seconds - (minutes * 60));

    // Format to HH:mm
    return `${this.time(minutes)}:${this.time(remainingSeconds)}`;
  }

  static secondsToMinutes (seconds) {
    return (seconds / 60);
  }

  // Add wildcard before each word
  static addWildcardPerWord (value) {
    if (typeof value === 'string' && value.length > 0) {
      // Add wildcard before each word
      // First, split the words
      value = value.split(' ');

      // Map the words and add a *, if necessary
      value = value.map(word => {
        if (word.substr(0, 1) !== '*') {
          word = `*${word}*`;
        }
        return word;
      });

      // Join the wordt again
      value = value.join(' ');
    }
    return value;
  }

  // Salesorder change
  static cashChange (change) {
    // TODO: Do this based on the correct region
    // Calculate change based on region
    // In many european countries, 0,01 and 0,02 aren't used anymore, so round to 0,05 or 0,10
    const roundRegions = ['NL', 'BE'];
    if (stores.activeStore && stores.activeStore.settings && roundRegions.indexOf(stores.activeStore.settings.region) !== -1) {
      change = calculate.price(change, false);
      change = (Math.round(change * 20) / 20);
      change = calculate.price(change, true);
      return change;
    }

    return change;
  }

  // Capitalize first letter
  static capitalizeFirstLetter (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  // Power usage
  static power (input) {
    return numeral(input).format('0,0.0');
  }
}
