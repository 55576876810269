<template>
  <div class="col-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">

    <breadcrumbs
      title="Betaalmethodes"
    ></breadcrumbs>

    <div class="row mt-3">

      <div class="col-12 col-md-6">
        <category-block :icon="['fad', 'money-bill-wave']" iconClass="kt-font-focus"
          v-on:click.prevent.native="$eventhub.emit('modals:shop:payment-method:open', {
            method: 'cash'
          })"
        >
          <h4 class="category-title">Contant</h4>
        </category-block>
      </div>

      <div class="col-12 col-md-6">
        <category-block :icon="['fad', 'credit-card']" iconClass="kt-font-focus"
          v-on:click.prevent.native="pay('card')"
        >
          <h4 class="category-title">PIN</h4>
        </category-block>
      </div>

      <div class="col-12 col-md-6">
        <category-block :icon="['fad', 'gift-card']" iconClass="kt-font-focus"
          v-on:click.native.prevent="$eventhub.emit('modals:shop:giftcard-payment-method:open')"
        >
          <h4 class="category-title">Cadeaukaart</h4>
        </category-block>
      </div>

      <div class="col-12 col-md-6">
        <category-block :icon="['fad', 'money-check-edit-alt']" iconClass="kt-font-focus"
          :disabled="canPayOnAccount === false"
          v-on:click.prevent.native="pay('on_account')"
        >
          <h4 class="category-title">
            <span>Op rekening</span>
            <span class="float-right" :class="[{'kt-font-focus': canPayOnAccount === true}]">
              <font-awesome-icon :icon="['fad', 'lock']" />
            </span>
          </h4>
        </category-block>
      </div>

    </div>

  </div>
</template>

<script>
// Mixins
import shopMixin from '@/mixins/shop';

// Classes
import SalesOrder from '@/libs/classes/salesorder';

export default {
  mixins: [shopMixin],
  props: {
    sales_order: SalesOrder
  },
  data() {
    return {
      paymentMethods: [
        {
          type: 'cash'
        }, {
          type: 'card'
        }, {
          type: 'giftcard'
        }, {
          type: 'on_account'
        }
      ]
    }
  },

  computed: {
    canPayOnAccount() {
      if (this.sales_order) {
        if (!this.sales_order.customer) {
          return false;
        }

        if (this.sales_order.lines && this.sales_order.lines.length && this.sales_order.lines.some(l => l.type === 'on_account_topup') === true) {
          return false;
        }
      }
      return true;
    }
  },

  methods: {
    async pay (payment_method) {
      if (this.paymentMethods.some(m => m.type === payment_method) === false) return ;
      if (payment_method === 'on_account' && this.canPayOnAccount === false) return ;

      let amount = (this.sales_order.total_incl_vat - this.sales_order.total_paid);
      if (amount > 0) {
        // When payment method is on account, check if customer is authenticated
        if (payment_method === 'on_account') {
          // Re-check authenticated
          this.sales_order.customer.authenticated = await this.sales_order.customer.isAuthenticated();

          // Open modal if not authenticated
          if (this.sales_order.customer.authenticated === false) {
            this.$eventhub.emit('modals:shop:identify-customer:open', {
              method: 'IDENTIFY',
              onCustomerAuthenticated: async (customer = null) => {
                await this.addPayment(payment_method, amount);
              }
            });
          } else {
            await this.addPayment(payment_method, amount);
          }
        } else {
          await this.addPayment(payment_method, amount);
        }
      }
    },
    async addPayment (payment_method, amount) {
      this.$eventhub.emit('shop:cart:set-loading', true);
      try {
        await this.shop.addTransaction(payment_method, amount);
      } catch (e) {
        console.error(e);
      }
      this.$eventhub.emit('shop:cart:set-loading', false);
    }
  }
}
</script>
