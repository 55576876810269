<template>
  <KPortlet>
    <KPortletHead>
      <template v-slot:default>Producten</template>
    </KPortletHead>
    <KPortletBody>
      <div class="row">
        <div class="col-12 datatable datatable-wrapper">
          <datatable
            ref="datatable"
            :url="datatable.url"
            :load-on-start="datatable.loadOnStart"
            :fields="datatable.fields"
            :search-fields="datatable.searchFields"
            :on-pagination-data="onPaginationData"
            :transform="transform"
          >
            <div
              slot="product-type"
              slot-scope="props"
              class="product-type"
            >
              <!-- Label -->
              <KButton
                :variant="props.rowData.label.variant"
                :icon="props.rowData.label.icon"
                class="btn-block btn-sm"
                tag="span"
              >
                {{ props.rowData.label.text }}
              </KButton>
            </div>

            <div
              slot="actions"
              slot-scope="props"
              class="datatable-actions"
            >
              <router-link
                v-if="props.rowData.viewLink !== null"
                :to="props.rowData.viewLink"
              >
                <KButton
                  :icon="['fad', 'eye']"
                  tag="a"
                  variant="label-brand"
                />
              </router-link>
            </div>
          </datatable>

          <div class="row pagination">
            <div class="col-xs-12 col-md-6">
              <datatable-pagination
                ref="pagination"
                @vuetable-pagination:change-page="onChangePage"
              />
            </div>

            <div class="col-xs-12 col-md-6">
              <datatable-pagination-info
                ref="pagination.info"
                @vuetable-paginationinfo:change-rowcount="onChangeRowCount"
              />
            </div>
          </div>

        </div>
      </div>
    </KPortletBody>
  </KPortlet>
</template>

<script>
// Libraries
import moment from 'moment-timezone';

// Classes
import SalesOrder from '@/libs/classes/salesorder';
import SalesOrderLine from '@/libs/classes/salesorder.line';

// Mixins
import datatable from '@/mixins/datatable';

export default {
  mixins: [datatable],
  props: {
    salesorder: {
      type: SalesOrder,
      default: null
    }
  },
  data () {
    return {
      datatable: {
        url: null,
        loadOnStart: false,

        fields: [{
          title: 'Type',
          name: '__slot:product-type',
          sortField: 'type',
          titleClass: 'text-center',
          dataClass: 'w-1/10'
        }, {
          title: 'Beschrijving',
          name: 'name',
          sortField: 'product_name',
          dataClass: 'w-1/8'
        }, {
          title: 'Aantal',
          name: 'quantity',
          sortField: 'quantity',
          titleClass: 'text-center',
          dataClass: 'w-1/8 text-center'
        }, {
          title: 'Prijs per product',
          name: 'product_price_excl_vat',
          sortField: 'product_price_excl_vat',
          titleClass: 'text-center',
          dataClass: 'w-1/8 text-center',
          callback: (value) => `&euro; ${this.$format.currency(value)}`
        }, {
          title: 'Subtotaal',
          name: 'total_price_excl',
          sortField: 'total_price_excl',
          titleClass: 'text-center',
          dataClass: 'w-1/8 text-center',
          callback: (value) => `&euro; ${this.$format.currency(value)}`
        }, {
          title: 'BTW',
          name: '',
          titleClass: 'text-center',
          dataClass: 'w-1/8 text-center',
          callback: (line) => `&euro; ${this.$format.currency(line.total_price_incl - line.total_price_excl)}`
        }, {
          title: 'Bedrag',
          name: 'total_price_incl',
          sortField: 'total_price_incl',
          titleClass: 'text-center',
          dataClass: 'w-1/8 text-center',
          callback: (value) => `&euro; ${this.$format.currency(value)}`
        }, {
          title: 'Korting',
          name: 'total_discount',
          sortField: 'total_discount',
          dataClass: 'w-1/8',
          callback: (value) => `&euro; ${this.$format.currency(value)}`
        }, {
          title: '',
          name: '__slot:actions',
          titleClass: 'text-right',
          dataClass: 'text-right'
        }],

        searchFields: ['product_name', 'product_type']
      }
    };
  },

  mounted () {
    this.$set(this.datatable, 'url', `/salesorders/${this.salesorder.guid}/lines`);
  },

  methods: {
    transform (data) {
      if (data.data && Array.isArray(data.data)) {
        data.data.forEach((row, idx) => {
          data.data[idx] = new SalesOrderLine().mergeResponse(data.data[idx]);

          // Add data
          data.data[idx].viewLink = this.viewLink(data.data[idx]);
          data.data[idx].label = this.productTypeLabel(data.data[idx].type);
        });
      }

      return data;
    },
    viewLink (line) {
      if (line && line.type) {
        if (line.type === 'product') return `/products/view/${line.product_guid}`;
        else if (line.type === 'credit_product') return `/products/credit/view/${line.product_guid}`;
      }

      return null;
    },
    productTypeLabel (type) {
      const label = {
        text: 'Onbekend',
        variant: 'label-warning',
        icon: ['fad', 'question']
      };

      switch (type) {
        case 'product':
          label.text = 'Product';
          label.variant = 'label-primary';
          label.icon = ['fad', 'tag'];
          break;

        case 'giftcard':
          label.text = 'Cadeaubon';
          label.variant = 'label-focus';
          label.icon = ['fad', 'gift-card'];
          break;

        case 'credit_product':
          label.text = 'Sessiekrediet';
          label.variant = 'label-brand';
          label.icon = ['fad', 'credit-card'];
          break;

        case 'on_account_topup':
          label.text = 'Aflossing';
          label.variant = 'label-danger';
          label.icon = ['fad', 'money-check-edit-alt'];
          break;

        case 'session':
          label.text = 'Sessie';
          label.variant = 'label-primary';
          label.icon = ['fad', 'link'];
          break;

        case 'bundle':
          label.text = 'Pakket';
          label.variant = 'label-primary';
          label.icon = ['fad', 'box'];
          break;
      }

      return label;
    }
  }
};
</script>
