<template>
  <div>

    <KSubheader
      title="Groepen"
      :breadcrumbs="[{
        page: 'Apparatuur',
        route: '/equipment'
      }, {
        page: 'Groepen',
        route: '/equipment/groups/overview'
      }, {
        page: 'Groep aanmaken',
        route: null
      }]"
    >

    </KSubheader>

    <KContainer>

      <KPortlet>
        <KPortletHead>
          Groep aanmaken
        </KPortletHead>
        <KPortletBody>

          <equipmentGroupForm ref="equipmentGroupForm"
            :group="group"
          ></equipmentGroupForm>

        </KPortletBody>
        <div class="kt-portlet__foot">
  				<div class="row align-items-center">
  					<div class="col-6 offset-lg-10 col-lg-2 kt-align-right">
              <KButton variant="success" class="btn-md btn-block btn-tall btn-wide btn-bold btn-upper" @click.native.prevent="submitForm">Aanmaken</KButton>
  					</div>
  				</div>
  			</div>
      </KPortlet>

    </KContainer>

  </div>
</template>

<script>
// Components
import equipmentGroupForm from './components/form'

// Classes
import EquipmentGroup from '@/libs/classes/equipment_group';

export default {
  data() {
    return {
      group: new EquipmentGroup()
    }
  },
  components: {
    equipmentGroupForm
  },
  methods: {
    async validate() {
      const validationArray = [];

      validationArray.push(this.$refs.equipmentGroupForm.$v);

      if(validationArray.length > 0) {
        validationArray.forEach(v => v && v.$touch && v.$touch())
        if(validationArray.filter(v => v && v.$invalid).length !== 0) return false;
      }

      return true;
    },
    async submitForm() {
      if(await this.validate() === true) {
        await this.group.create(this.group.formatBody());
        this.$router.push(`/equipment/groups/view/${this.group.guid}`);
      }
    }
  }
}
</script>
