<template>
  <KAsideMenu>
    <router-link
      v-slot="{ href, route, navigate, isActive, isExactActive }"
      to="/dashboard"
    >
      <KAsideMenuItem
        :icon="['fad', 'home']"
        :route="href"
        :active="isItemActive(route)"
      >
        Dashboard
      </KAsideMenuItem>
    </router-link>

    <router-link
      v-slot="{ href, route, navigate, isActive, isExactActive }"
      to="/statistics"
    >
      <KAsideMenuItem
        :icon="['fad', 'analytics']"
        :route="href"
        :active="isItemActive(route)"
      >
        Statistieken
      </KAsideMenuItem>
    </router-link>

    <router-link
      v-slot="{ href, route, navigate, isActive, isExactActive }"
      to="/stores/overview"
    >
      <KAsideMenuItem
        :icon="['fad', 'store']"
        :route="href"
        :active="isItemActive(route)"
      >
        Winkels
      </KAsideMenuItem>
    </router-link>

    <router-link
      v-slot="{ href, route, navigate, isActive, isExactActive }"
      v-if="$auth.hasPermission('get_users')"
      to="/customers/overview"
    >
      <KAsideMenuItem
        :icon="['fad', 'users']"
        :route="href"
        :active="isItemActive(route)"
      >
        Klanten
      </KAsideMenuItem>
    </router-link>

    <KAsideMenuSubmenu
      v-if="$auth.hasPermission('get_products')"

      :title="`Producten`"
      :icon="['fad', 'tags']"
      :active="isMenuActive('products')"
    >

      <router-link
        v-slot="{ href, route, navigate, isActive, isExactActive }"
        v-if="$auth.hasPermission('get_products')"
        to="/products/overview"
      >
        <KAsideMenuItem
          :route="href"
          :active="isItemActive(route)"
          bullet
        >
          Overzicht
        </KAsideMenuItem>
      </router-link>

      <router-link
        v-slot="{ href, route, navigate, isActive, isExactActive }"
        v-if="$auth.hasPermission('get_pricelists')"
        to="/products/pricelists/overview"
      >
        <KAsideMenuItem
          :route="href"
          :active="isItemActive(route)"
          bullet
        >
          Prijslijsten
        </KAsideMenuItem>
      </router-link>

      <router-link
        v-slot="{ href, route, navigate, isActive, isExactActive }"
        v-if="$auth.hasPermission('get_product_groups')"
        to="/products/groups/overview"
      >
        <KAsideMenuItem
          :route="href"
          :active="isItemActive(route)"
          bullet
        >
          Groepen
        </KAsideMenuItem>
      </router-link>

      <router-link
        v-slot="{ href, route, navigate, isActive, isExactActive }"
        v-if="$auth.hasPermission('get_credit_products')"
        to="/products/credit/overview"
      >
        <KAsideMenuItem
          :route="href"
          :active="isItemActive(route)"
          bullet
        >
          Sessiekrediet
        </KAsideMenuItem>
      </router-link>

    </KAsideMenuSubmenu>

    <router-link
      v-slot="{ href, route, navigate, isActive, isExactActive }"
      v-if="$auth.hasPermission('get_users')"
      to="/users"
    >
      <KAsideMenuItem
        :route="href"
        :active="isItemActive(route)"
        :icon="['fad', 'users']"
      >
        Gebruikers
      </KAsideMenuItem>
    </router-link>

    <router-link
      v-slot="{ href, route, navigate, isActive, isExactActive }"
      to="/settings/workspace"
    >
      <KAsideMenuItem
        :icon="['fad', 'cogs']"
        :route="href"
        :active="isActive"
      >
        Instellingen
      </KAsideMenuItem>
    </router-link>

  </KAsideMenu>
</template>

<script>
export default {
  props: {
    isMenuActive: {
      type: Function,
      default: () => null
    },
    isItemActive: {
      type: Function,
      default: () => null
    }
  }
};
</script>
