<template>
  <KPortlet>
    <KPortletHead>
      <template v-slot:default>Instellingen</template>
    </KPortletHead>
    <KPortletBody>

      <groupForm
        ref="groupForm"

        :group="groupClone"
        :disabled="isLoading"

        :action="($auth.hasPermission([['update_product_groups']]) ? 'EDIT' : 'VIEW')"
      />

    </KPortletBody>
    <div
      v-if="$auth.hasPermission([['update_product_groups']])"
      class="kt-portlet__foot"
    >
      <div class="row align-items-center">
        <div class="col-6 offset-lg-10 col-lg-2 kt-align-right">
          <KButton
            :disabled="isLoading"

            variant="warning"
            class="btn-md btn-block btn-tall btn-wide btn-bold btn-upper"

            @click.native.prevent="submitForm"
          >
            Wijzigen
          </KButton>
        </div>
      </div>
    </div>
  </KPortlet>
</template>

<script>
// Libraries
import { cloneDeep } from 'lodash';
import mutationsLib from '@/libs/mutations';

// Classes
import ProductGroup from '@/libs/classes/product_group';

// Components
import groupForm from '../components/form';

export default {
  components: {
    groupForm
  },
  props: {
    group: {
      type: ProductGroup,
      default: null
    }
  },
  data () {
    return {
      isMounted: false,
      isLoading: false,

      groupClone: cloneDeep(this.group)
    };
  },

  async mounted () {
    this.$nextTick(() => {
      this.isMounted = true;
    });
  },

  methods: {
    async validate () {
      const validationArray = [];
      validationArray.push(this.$refs.groupForm.$v);

      if (validationArray.length > 0) {
        validationArray.forEach(v => v && v.$touch && v.$touch());
        if (validationArray.filter(v => v && v.$invalid).length !== 0) return false;
      }

      return true;
    },
    async submitForm () {
      if (await this.validate() === true) {
        const fields = ['name'];
        const mutations = mutationsLib.create(fields, this.group.formatBody(), this.groupClone.formatBody());

        if (mutations.length) {
          this.isLoading = true;

          try {
            await this.group.update(mutations);
            // Update products
            this.groupClone = cloneDeep(this.group);
          } catch (e) {
            this.$error.showModal(e);
          }

          this.isLoading = false;
        }
      }
    }
  }
};
</script>
