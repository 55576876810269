// Classes
import Abstract from './abstract';

// Libraries
import { merge } from 'lodash';

class BundleProductProduct extends Abstract { 
  constructor (guid = null) {
    super(guid);

    let defaultObject = {
      product_guid: null,
      quantity: 0
    };

    merge(this, defaultObject);
  }

  // Methods
  formatBody (type = 'get', options = {}) {
    let body = super.formatBody(type, options);

    // Remove product from body
    delete body.product;

    return body;
  }

  // Getters
  get VUID () {
    return `bundle_product.product.${this.product_guid}`;
  }
}

export default BundleProductProduct;
