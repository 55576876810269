<template>
  <div v-if="isMounted" class="col-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">

    <breadcrumbs
      title="Producten"
      :breadcrumbs="[{
        title: 'Producten',
        route: '/sales/shop/:cash_register/cart/products'
      }, {
        title: group.name
      }]"
    ></breadcrumbs>

    <div class="row mt-3">

      <!-- Product lines -->
      <template v-if="products.length > 0">
        <div v-for="(product, idx) in products" class="col-12">
          <product-line :ref="`product.${product.guid}`"
            class="cursor-pointer"

            :icon="['fad', 'tag']"
            :price="product.price.price_incl_vat"

            v-on:click.prevent.native="addProduct(product)"

          >
            <h5 class="product-title">
              {{ product.name }}

              <span class="user pl-2" v-if="product.requires_customer"><font-awesome-icon class="kt-font-brand" :icon="['far', 'user']" /></span>
            </h5>
            <span class="product-subtitle">
              <span class="stock">Voorraad: {{ product.stock }}</span>
            </span>
          </product-line>
        </div>
      </template>

      <!-- No products error -->
      <template v-else>
        <div class="col-12">
          <KAlert variant="danger">Geen producten gevonden</KAlert>
        </div>
      </template>

    </div>

  </div>
</template>

<script>
// Mixins
import shopMixin from '@/mixins/shop';

// Classes
import Product from '@/libs/classes/product';
import ProductGroup from '@/libs/classes/product_group';

export default {
  mixins: [shopMixin],
  data() {
    return {
      isMounted: false,

      group: null,
      products: []
    }
  },

  async mounted() {
    try {
      await this.getProductGroup();
      await this.getProducts();
    } catch(e) {
      console.error('Failed to catch products for group:', this.$route.params.group_guid, e);
      return ;
    }

    this.$nextTick(() => {
      this.isMounted = true;
    })
  },

  methods: {
    async addProduct (product) {
      this.$eventhub.emit('shop:cart:set-loading', true);
      try {
        await this.shop.addProductToCart({
          type: 'product',
          product_name: product.name,
          product_guid: product.guid,
          quantity: 1,
          amount_changeable: product.amount_changeable,
          requires_customer: product.requires_customer
        })
      } catch (e) {
        console.error(e);
      }
      this.$eventhub.emit('shop:cart:set-loading', false);
    },

    async getProductGroup() {
      this.$set(this, 'group', null);
      let group = await new ProductGroup(this.$route.params.group_guid).get();
      this.$set(this, 'group', group);
      return this.group;
    },

    async getProducts() {
      this.$set(this, 'products', []);
      let products = await this.$ws.get('v1', `/product_groups/${this.$route.params.group_guid}/products`, {
        query: {
          price_required: true
        }
      });
      products.forEach((product, idx) => products[idx] = new Product().mergeResponse(products[idx]));
      this.$set(this, 'products', products);
      return this.products;
    }
  }
}
</script>
