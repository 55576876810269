<template>
  <div>

    <!-- Pincode portlet -->
    <pincode-portlet ref="pincodePortlet"
      :customer="customer"
    ></pincode-portlet>

    <!-- Fingerprint portlet -->
    <fingerprint-portlet ref="fingerprintPortlet"
      :customer="customer"
    ></fingerprint-portlet>

  </div>
</template>

<script>
// Libraries
import { cloneDeep } from 'lodash';
import mutationsLib from '@/libs/mutations';

// Classes
import Customer from '@/libs/classes/customer';

// Components
import pincodePortlet from '@/components/customers/view/authentication/pincode.portlet';
import fingerprintPortlet from '@/components/customers/view/authentication/fingerprint.portlet';

export default {
  props: {
    customer: Customer
  },
  components: {
    pincodePortlet,
    fingerprintPortlet
  }
}
</script>
