<template>
  <KPortlet>
    <KPortletHead>
      Vingerafdruk authenticatie
    </KPortletHead>
    <KPortletBody>

      <!-- Fingerprint -->
      <authentication-fingerprint ref="authenticationFingerprint"
        action="EDIT"
        
        :customer="customer"
      ></authentication-fingerprint>

    </KPortletBody>
  </KPortlet>
</template>

<script>
// Libraries
import equipment from '@/libs/equipment';

// Classes
import Customer from '@/libs/classes/customer';

// Components
import authenticationFingerprint from '@/components/customers/components/authentication/fingerprint';

export default {
  props: {
    customer: Customer
  },
  data () {
    return {
      master: null
    }
  },
  components: {
    authenticationFingerprint
  },

  mounted () {
    if (equipment && equipment.masters && equipment.masters.length > 0) {
      this.$set(this, 'master', equipment.masters[0]);
    }
  },

  methods: {
    startEnrollment () {
      if (this.master) {
        this.master.socket.device('auth:fingerprint:enroll', {
          customer_guid: this.customer.guid
        });
      }
    }
  }
}
</script>
