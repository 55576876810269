<template>
  <KPortlet>
    <KPortletHead>
      <template v-slot:default>Apparatuur</template>
    </KPortletHead>
    <KPortletBody
      v-if="isMounted"
      class="pb-0"
    >

      <equipment-form
        ref="equipmentForm"

        :pricelist="pricelistCloned"

        :equipment="equipment"

        action="EDIT"
      />

    </KPortletBody>
    <div class="kt-portlet__foot">
      <div class="row align-items-center">
        <div class="col-6 offset-lg-10 col-lg-2 kt-align-right">
          <KButton
            variant="warning"
            class="btn-md btn-block btn-tall btn-wide btn-bold btn-upper"
            @click.native.prevent="submitForm"
          >
            Wijzigen
          </KButton>
        </div>
      </div>
    </div>
  </KPortlet>
</template>

<script>
// Libraries
import { cloneDeep } from 'lodash';
import mutationsLib from '@/libs/mutations';

// Classes
import SessionPricelist from '@/libs/classes/session_pricelist';
import Equipment from '@/libs/classes/equipment';

// Components
import equipmentForm from '../components/equipment.form.vue';

export default {
  components: {
    equipmentForm
  },
  props: {
    pricelist: {
      type: SessionPricelist,
      default: null
    },

    meta: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      isMounted: false,
      equipment: [],

      pricelistCloned: cloneDeep(this.pricelist)
    };
  },
  async mounted () {
    try {
      await this.getEquipment();
    } catch (e) {
      console.error('Equipment not found', e);
      return;
    }

    this.$nextTick(() => {
      this.isMounted = true;
    });
  },
  methods: {
    async getEquipment (options = {}) {
      this.$set(this, 'equipment', null);
      let equipment = await this.$ws.get('v1', '/equipments', options);
      this.$set(this, 'equipment', equipment.map(eq => new Equipment().mergeResponse(eq), []));
      return this.equipment;
    },

    async validate () {
      const validationArray = [];

      if (validationArray.length > 0) {
        validationArray.forEach(v => v && v.$touch && v.$touch());
        if (validationArray.filter(v => v && v.$invalid).length !== 0) return false;
      }

      let valid = await this.$refs.equipmentForm.validate();
      if (valid === false) return false;

      return true;
    },
    async submitForm () {
      if (await this.validate() === true) {
        const fields = ['equipment_guids'];
        const mutations = mutationsLib.create(fields, this.pricelist.formatBody(), this.pricelistCloned.formatBody());

        if (mutations.length) {
          await this.pricelist.update(mutations);
          await this.pricelist.populate('update');

          this.$set(this, 'pricelistCloned', cloneDeep(this.pricelist));
        }
      }
    }
  }
};
</script>
