// Calculate prices (ex VAT, inc VAT etc.)

// The backend saves all prices * the below integer, so it doesn't have to save floats
export const backendDivider = 100000;

export default class Calculate {
  // Calculate price from backend, so divide by divider
  static price (input, fromInput = false) {
    if (fromInput === true) return Math.round(Number(input * backendDivider));
    return Number(input / backendDivider);
  }

  // Calculate VAT of price
  static VAT (input, vatPercentage, inputIncludesVAT = false) {
    let VAT = 0;

    // Input includes VAT
    if (inputIncludesVAT === true) {
      VAT = (input / (100 + vatPercentage) * vatPercentage);
    }

    // Input doesn't include VAT
    if (inputIncludesVAT === false) {
      VAT = (input * (vatPercentage / 100));
    }
    return VAT;
  }

  // Calculate price without VAT
  static priceWithoutVAT (input, vatPercentage) {
    if (typeof input === 'string') input = Number(input);
    return Math.round(input - this.VAT(input, vatPercentage, true));
  }

  // Calculate price including VAT
  static priceWithVAT (input, vatPercentage) {
    if (typeof input === 'string') input = Number(input);
    return Math.round(input + this.VAT(input, vatPercentage, false));
  }

  // Compare prices
  static comparePrice (price1, price2) {
    price1 = Math.round(price1 / (backendDivider / 100)) * (backendDivider / 100);
    price2 = Math.round(price2 / (backendDivider / 100)) * (backendDivider / 100);
    return (price1 - price2);
  }
}
