<template>
  <KContainer v-if="isMounted === false">
    <div class="row mt-3">
      <div class="col-12 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
        <div class="loading-container">
          <div class="loading-block">
            <div class="blockui ml-auto mr-auto w-100">
              <span>Gegevens ophalen...</span>
              <span class="kt-spinner kt-spinner--v2 kt-spinner--primary" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </KContainer>

  <div v-else>

    <KSubheader
      :breadcrumbs="[{
        page: 'Apparatuur',
        route: '/equipment'
      }, {
        page: equipment.name,
        route: null
      }]"

      title="Apparatuur"
    />

    <KContainer>
      <div class="row">
        <div class="col-12 col-portlet-menu">
          <KPortlet>
            <KPortletBody>
              <div class="kt-widget kt-widget--general-1">
                <div class="kt-media kt-media--brand kt-media--md kt-media--circle">
                  <span class="kt-badge kt-badge--username kt-badge--lg kt-badge--dark kt-badge--bold">
                    <i><font-awesome-icon :icon="['fad', 'link']" /></i>
                  </span>
                </div>
                <div class="kt-widget__wrapper">
                  <div class="kt-widget__label">
                    <span class="kt-widget__title">{{ equipment.name }}</span>
                    <router-link
                      v-if="equipment.group"
                      :to="`/equipment/groups/view/${equipment.group.guid}`"
                      tag="a"
                      class="kt-widget__desc"
                    >
                      {{ equipment.group.name }}
                    </router-link>
                  </div>
                </div>
              </div>
            </KPortletBody>

            <div class="kt-portlet__separator" />

            <KPortletBody>
              <ul
                class="kt-nav kt-nav--bolder kt-nav--fit-ver kt-nav--v4"
                role="tablist"
              >
                <router-link
                  v-slot="{ href, route, navigate, isActive, isExactActive }"
                  to="settings"
                >
                  <li :class="['kt-nav__item', {'active': isActive}]">
                    <a
                      class="kt-nav__link"
                      role="tab"
                      @click.prevent="$router.push(href)"
                    >
                      <span class="kt-nav__link-icon">
                        <i>
                          <font-awesome-icon :icon="['fad', 'cogs']" />
                        </i>
                      </span>
                      <span class="kt-nav__link-text">Instellingen</span>
                    </a>
                  </li>
                </router-link>

                <router-link
                  v-slot="{ href, route, navigate, isActive, isExactActive }"
                  v-if="$auth.hasPermission('get_pricelists')"
                  to="pricelists"
                >
                  <li :class="['kt-nav__item', {'active': isActive}]">
                    <a
                      class="kt-nav__link"
                      role="tab"
                      @click.prevent="$router.push(href)"
                    >
                      <span class="kt-nav__link-icon">
                        <i>
                          <font-awesome-icon :icon="['fad', 'euro-sign']" />
                        </i>
                      </span>
                      <span class="kt-nav__link-text">Tarievenlijsten</span>
                    </a>
                  </li>
                </router-link>

                <router-link
                  v-slot="{ href, route, navigate, isActive, isExactActive }"
                  to="maintenance"
                >
                  <li :class="['kt-nav__item', {'active': isActive}]">
                    <a
                      class="kt-nav__link"
                      role="tab"
                      @click.prevent="$router.push(href)"
                    >
                      <span class="kt-nav__link-icon">
                        <i>
                          <font-awesome-icon :icon="['fad', 'tools']" />
                        </i>
                      </span>
                      <span class="kt-nav__link-text">Onderhoud</span>
                    </a>
                  </li>
                </router-link>
              </ul>
            </KPortletBody>
          </KPortlet>
        </div>

        <div class="col-12 col-sm">
          <router-view
            :key="$route.fullPath"
            :equipment.sync="equipment"

            :meta="meta"
          />
        </div>
      </div>

    </KContainer>

  </div>
</template>

<script>
// Classes
import Equipment from '@/libs/classes/equipment';

export default {
  data () {
    return {
      isMounted: false,

      equipment: null,

      meta: {
        VATCodes: []
      }
    };
  },

  async mounted () {
    try {
      await this.getEquipment();
    } catch (e) {
      console.error('Equipment not found:', e);
      return;
    }

    this.$nextTick(() => {
      this.isMounted = true;
    });
  },

  methods: {
    async getEquipment (options = {}) {
      this.$set(this, 'equipment', null);

      let equipment = await new Equipment(this.$route.params.guid).get(options);
      await equipment.populate('get', options);

      this.$set(this, 'equipment', equipment);
      return this.equipment;
    }
  }
};
</script>
