<template>
  <div>
    <template v-if="store">
      <h3>Stores &raquo; View &raquo; {{ store.name }}</h3>
      <router-link :to="'/stores/edit/' + store._meta.guid" tag="a">Update store</router-link><hr />
      <strong>Name:</strong><br />
      {{ store.name }}<br />
      <hr />
      <h5>Users with access to this store</h5>
      <div v-if="users" v-for="user in users" :key="user._meta.guid">
        {{ user.username }}
      </div>
    </template>
  </div>
</template>

<script>
export default {
  data () {
    return {
      'store': null,
      'users': null
    };
  },

  'methods': {
    async getStore () {
      this.store = await this.$ws.get('v1', '/stores/' + this.$route.params.guid);
      this.users = await this.$ws.get('v1', '/stores/' + this.$route.params.guid + '/users');
    }
  },

  async mounted () {
    try {
      await this.getStore();
    } catch (e) {
      console.error('Store not found:', e);
    }
  }
};
</script>
