<template>
  <div v-if="isMounted" class="col-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">

    <breadcrumbs
      title="Sessiekrediet"
      :breadcrumbs="[{
        title: 'Sessiekrediet',
        route: null
      }]"
    ></breadcrumbs>

    <div class="row mt-3">

      <!-- Session credit lines -->
      <template v-if="credit_products.length > 0">
        <div v-for="(product, idx) in credit_products" class="col-12">
          <product-line :ref="`credit_product.${product.guid}`"
            class="cursor-pointer"

            :icon="['fad', 'credit-card']"
            :price="product.price.price_incl_vat"

            v-on:click.prevent.native="addProduct(product)"
          >
            <h5 class="product-title">
              {{ product.name }}

              <span class="user pl-2"><font-awesome-icon class="kt-font-brand" :icon="['far', 'user']" /></span>
            </h5>

            <span class="product-subtitle">
              <span class="credit">Krediet: &euro; {{ $format.currency(product.credits) }}</span>
            </span>
          </product-line>
        </div>
      </template>

      <template v-else>
        <div class="col-12">
          <KAlert variant="danger">Geen producten gevonden</KAlert>
        </div>
      </template>

    </div>
  </div>
</template>

<script>
// Mixins
import shopMixin from '@/mixins/shop';

// Classes
import CreditProduct from '@/libs/classes/credit_product';

export default {
  mixins: [shopMixin],
  data() {
    return {
      isMounted: false,

      credit_products: []
    }
  },
  async mounted() {
    try {
      await this.getCreditProducts();
    } catch(e) {
      console.error('Error fetching products groups', e);
      return;
    }

    this.$nextTick(() => {
      this.isMounted = true;
    })
  },

  methods: {
    async addProduct (product) {
      this.$eventhub.emit('shop:cart:set-loading', true);
      try {
       await this.shop.addProductToCart({
          type: 'credit_product',
          product_name: product.name,
          product_guid: product.guid,
          quantity: 1,
          amount_changeable: true,
          requires_customer: true
        })
      } catch (e) {
        console.error(e);
      }
      this.$eventhub.emit('shop:cart:set-loading', false);
    },

    async getCreditProducts (options = {}) {
      this.$set(this, 'credit_products', []);
      let credit_products = await this.$ws.get('v1', '/credit_products');
      credit_products.forEach((row, idx) => credit_products[idx] = new CreditProduct().mergeResponse(credit_products[idx]));
      this.$set(this, 'credit_products', credit_products);
      return this.credit_products;
    }
  }
}
</script>
