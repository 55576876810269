// Components
import { BModal } from 'bootstrap-vue';

export default {
  data () {
    return {
      _modalReference: 'modal'
    };
  },
  components: {
    BModal
  },
  computed: {
    modal: {
      get () {
        return this.$refs[(this.$data._modalReference)];
      }
    }
  },

  mounted () {
    if (!this.modal) {
      console.error('Modal error: No modal found with reference name \'modal\'', this);
    }

    // Add listeners
    this.modal.$on('shown', this.onShown);
  },
  beforeDestroy () {
    if (this.modal) {
      // Remove listeners
      this.modal.$off('shown', this.onShown);
    }
  },

  methods: {
    show () {
      return this.modal.show();
    },
    hide () {
      return this.modal.hide();
    },

    onShown () {
      // No defaults here
    }
  }
};
