<template>
  <div>

    <div class="row form-group">
      <div class="col-12">
        <label>Naam <sup>Verplicht</sup></label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i><font-awesome-icon :icon="['fad', 'text']" /></i>
            </span>
          </div>
          <input type="text" class="form-control" v-model.trim="$v.credit_reason.name.$model" :class="[{'is-invalid': $v.credit_reason.name.$error}]" :disabled="disabled" />
        </div>
      </div>
    </div>

    <div class="row form-group">
      <div class="col-12">
        <label>Omschrijving</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i><font-awesome-icon :icon="['fad', 'text']" /></i>
            </span>
          </div>
          <input type="text" class="form-control" v-model.trim="$v.credit_reason.description.$model" :class="[{'is-invalid': $v.credit_reason.description.$error}]" :disabled="disabled" />
        </div>
      </div>
    </div>

  </div>
</template>

<script>
// Classes
import CreditReason from '@/libs/classes/credit_reasons';

// Validations
import { required } from 'vuelidate/lib/validators'

export default {
  props: {
    action: {
      type: String,
      default: 'CREATE'
    },

    credit_reason: CreditReason,

    disabled: Boolean,
    placeholder: {
      type: Boolean,
      default: true
    }
  },
  validations: {
    credit_reason: {
      name: {
        required
      },
      description: {}
    }
  }
}
</script>
