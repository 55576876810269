<template>
  <div class="kt-footer kt-grid__item kt-grid kt-grid--desktop kt-grid--ver-desktop">
    <div class="kt-container  kt-container--fluid ">
      <div class="kt-footer__copyright">
        &copy; {{ year }} | <a href="https://caesium.io/" target="_blank" class="ml-1 kt-link">Caesium</a>
      </div>
      <div class="kt-footer__menu">
        <!-- Power usage -->
        <span v-if="powerUsage !== null">
          <i><font-awesome-icon :icon="['far', 'bolt']" /></i>
          <span class="pl-1">{{ $format.power(powerUsage) }} kWh</span>
        </span>
        <!-- <a href="#" target="_blank" class="kt-footer__menu-link kt-link">Caesium</a> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      powerUsage: null,

      year: new Date().getFullYear()
    };
  },

  async mounted () {
    // Listeners
    this.$eventhub.on('equipment:equipment:updated', this.onEquipmentUpdated);
  },
  beforeDestroy () {
    // Remove listeners
    this.$eventhub.off('equipment:equipment:updated', this.onEquipmentUpdated);
  },

  methods: {
    onEquipmentUpdated (object) {
      if (typeof object === 'object') {
        // Check if there is equipment with powerUsage more than 0
        let equipmentWithPower = object.filter(e => e.energy_consumption > 0);
        if (equipmentWithPower.length === 0) {
          this.powerUsage = null;
          return this.powerUsage;
        }

        this.powerUsage = equipmentWithPower.filter(e => e.state === 'session', []).map(e => e.energy_consumption, []).reduce((a, b) => a + b, 0);
      }
    }
  }
};
</script>
