<template>
  <KPortlet>
    <KPortletHead>
      Wachtwoord
    </KPortletHead>
    <KPortletBody>
      <div class="row border-bottom pb-3">
        <div class="col-12">
          <label>Huidig wachtwoord <sup>Verplicht</sup></label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i><font-awesome-icon :icon="['fas', 'key']" /></i>
              </span>
            </div>
            <input
              v-model.trim="$v.resource.currentPassword.$model"
              :class="[{'is-invalid': $v.resource.currentPassword.$error}]"
              :disabled="isLoading"

              name="password"
              type="password"
              class="form-control"

              @input="resource.errors.splice(resource.errors.indexOf('resource.currentPassword'), 1)"
            >
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-12 col-md-6">
          <label>Nieuw wachtwoord <sup>Verplicht</sup></label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i><font-awesome-icon :icon="['fas', 'key']" /></i>
              </span>
            </div>
            <input
              v-model.trim="$v.resource.password.$model"
              :class="[{'is-invalid': $v.resource.password.$error}]"
              :disabled="isLoading"

              name="passwordNew"
              type="password"
              class="form-control"
            >
          </div>
          <span class="form-text text-muted">
            Minimaal 8 tekens
          </span>
        </div>

        <div class="col-12 col-md-6">
          <label>Nieuw wachtwoord <sup>Controle</sup></label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i><font-awesome-icon :icon="['fas', 'key']" /></i>
              </span>
            </div>
            <input
              v-model.trim="$v.resource.passwordConfirm.$model"
              :class="[{'is-invalid': $v.resource.passwordConfirm.$error}]"
              :disabled="isLoading"

              name="passwordConfirm"
              type="password"
              class="form-control"
            >
          </div>
        </div>
      </div>
    </KPortletBody>
    <div class="kt-portlet__foot">
      <div class="row align-items-center">
        <div class="offset-6 col-6 offset-lg-10 col-lg-2 kt-align-right">
          <KButton
            :disabled="isLoading"
            variant="warning"
            class="btn-md btn-block btn-tall btn-wide btn-bold btn-upper"
            @click.native.prevent="submitForm"
          >
            Wijzigen
          </KButton>
        </div>
      </div>
    </div>
  </KPortlet>
</template>

<script>
// Libraries
import { cloneDeep } from 'lodash';

// Classes
import User from '@/libs/classes/user';

// Validations
import { required } from 'vuelidate/lib/validators';
import { password } from '@/libs/validators/index';

export default {
  props: {
    user: {
      type: User,
      default: null
    }
  },
  data () {
    return {
      userClone: cloneDeep(this.user),

      isLoading: false,

      resource: {
        errors: [],

        currentPassword: null,

        password: null,
        passwordConfirm: null
      }
    };
  },
  validations: {
    resource: {
      currentPassword: {
        required,
        hasError: function (value) {
          return this.resource.errors.indexOf('resource.currentPassword') === -1;
        }
      },

      password: {
        required,
        isPassword: password
      },
      passwordConfirm: {
        required,
        sameAsPassword: function (value) {
          return (value === this.resource.password);
        }
      }
    }
  },

  methods: {
    async validate () {
      const validationArray = [];
      validationArray.push(this.$v);

      if (validationArray.length > 0) {
        validationArray.forEach(v => v && v.$touch && v.$touch());
        if (validationArray.filter(v => v && v.$invalid).length !== 0) return false;
      }

      return true;
    },

    async submitForm () {
      if (await this.validate() === true) {
        // Set isLoading
        this.$set(this, 'isLoading', true);

        try {
          await this.$ws.patch('v1', `/users/${this.user.guid}/password`, {
            body: {
              current_password: this.resource.currentPassword,
              new_password: this.resource.password
            }
          });
        } catch (e) {
          if (e.info === 'currentPasswordDoesNotMatch') {
            this.resource.errors.push('resource.currentPassword');
          }

          this.$set(this, 'isLoading', false);

          console.error(e);
          return;
        }

        this.$set(this, 'isLoading', false);

        // Reset password fields
        this.resource.currentPassword = null;

        this.resource.password = null;
        this.resource.passwordConfirm = null;

        this.$v.resource.$reset();
      }
    }
  }
};
</script>
