<template>
  <div class="row form-group">
    <div class="col-6 col-lg-4 offset-lg-2 col-xl-4 offset-xl-2">
      <label>PIN <sup>Verplicht</sup></label>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">
            <i>
              <font-awesome-icon :icon="['fad', 'lock']" />
            </i>
          </span>
        </div>
        <input
          v-model.trim="$v.resource.pincode.$model"
          :class="[{'is-invalid': $v.resource.pincode.$error}]"
          :disabled="disabled"

          type="password"
          class="form-control pin-input"
          minlength="4"
          maxlength="6"
        >
      </div>
    </div>

    <div class="col-6 col-lg-4 col-xl-4">
      <label>PIN <sup>Controle</sup></label>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">
            <i>
              <font-awesome-icon :icon="['fad', 'lock']" />
            </i>
          </span>
        </div>
        <input
          v-model.trim="$v.pincode_confirm.$model"
          :class="[{'is-invalid': $v.pincode_confirm.$error}]"
          :disabled="disabled"

          type="password"
          class="form-control pin-input"
          minlength="4"
          maxlength="6"
        >
      </div>
    </div>
  </div>
</template>

<script>
// Classes
import Customer from '@/libs/classes/customer';
import User from '@/libs/classes/user';

// Validations
import { required } from 'vuelidate/lib/validators';
import { pincode } from '@/libs/validators/index';

export default {
  props: {
    resource: {
      type: [Customer, User],
      default: null
    },

    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      pincode_confirm: null
    };
  },
  watch: {
    'resource.pincode' (value) {
      // Reset pincode_confirm when pincode is changed
      this.pincode_confirm = null;
      this.$v.pincode_confirm.$reset();
    }
  },

  validations: {
    resource: {
      pincode: {
        required,
        isPincode: pincode
      }
    },
    pincode_confirm: {
      required,
      isPincode: pincode,
      sameAsPincode: function (value) {
        return (value === this.resource.pincode);
      }
    }
  }
};
</script>
