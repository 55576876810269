<template>
  <div v-if="tablePagination !== null && tablePagination.total !== 0"
    class="pagination-links"
  >

    <KButton
      tag="a"
      class="d-none d-sm-inline-flex btn-page-first"
      :variant="`label-${buttons.color}`"

      :icon="['fad', 'chevron-double-left']"
      :class="[buttons.size, {'disabled': isOnFirstPage}]"
      @click.native.prevent="loadPage(1)"
    ></KButton>

    <KButton
      tag="a"
      class="btn-page-prev"
      :variant="`label-${buttons.color}`"

      :icon="['fad', 'chevron-left']"
      :class="[buttons.size, {'disabled': isOnFirstPage}]"
      @click.native.prevent="loadPage('prev')"
    ></KButton>

    <template v-if="notEnoughPages">
      <KButton v-for="n in totalPage"
        tag="a"
        :variant="`page-${buttons.color}`"

        v-bind:key="`pagination.page.${n}`"
        :class="[buttons.size, {'active': isCurrentPage(n)}]"
        @click.native.prevent="loadPage(n)"
      >{{ n }}</KButton>
    </template>
    <template v-else>
      <KButton v-for="n in windowSize"
        tag="a"
        :variant="`page-${buttons.color}`"

        v-bind:key="`pagination.page.${n}`"
        :class="[buttons.size, {'active': isCurrentPage(windowStart+n-1)}]"
        @click.native.prevent="loadPage(windowStart+n-1)"
      >{{ windowStart+n-1 }}</KButton>
    </template>

    <KButton
      tag="a"
      class="btn-page-next"
      :variant="`label-${buttons.color}`"

      :icon="['fad', 'chevron-right']"
      :class="[buttons.size, {'disabled': isOnLastPage}]"
      @click.native.prevent="loadPage('next')"
    ></KButton>

    <KButton
      tag="a"
      class="d-none d-sm-inline-flex btn-page-last"
      :variant="`label-${buttons.color}`"

      :icon="['fad', 'chevron-double-right']"
      :class="[buttons.size, {'disabled': isOnLastPage}]"
      @click.native.prevent="loadPage(totalPage)"
    ></KButton>

  </div>
</template>

<script>
import VuetablePaginationMixin from 'vuetable-2/src/components/VuetablePaginationMixin'
export default {
  mixins: [VuetablePaginationMixin],
  data() {
    return {
      buttons: {
        color: "brand",
        size: "btn-sm"
      }
    }
  }
}
</script>
