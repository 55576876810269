// Classes
import BundleProduct from './bundle_product';

// Libraries
import { merge } from 'lodash';

class BundleProductSubscription extends BundleProduct {
  constructor (guid = null) {
    super(guid);
    this.type = 'subscription';

    let defaultObject = {
      bundle_limit: {
        limited: true,
        type: 'months',
        value: 1
      }
    };

    merge(this, defaultObject);
  }

  // Methods
  formatBody (type = 'get', options = {}) {
    let body = super.formatBody(type, options);

    // Empty products
    body.products = [];

    return body;
  }
};

export default BundleProductSubscription;
