<template>
  <div>

    <div class="row border-bottom pb-3">
      <div class="col-12 col-lg-6">
        <label>CSID <sup>Verplicht</sup></label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">
              CSID
            </span>
          </div>
          <input
            v-model.trim="$v.hardware.serial.$model"
            :class="['form-control', {'is-invalid': $v.hardware.serial.$error}]"
            :disabled="disabled"

            type="text"
          >
        </div>
      </div>

      <div class="col-12 col-lg-6">
        <label>Naam <sup>Verplicht</sup></label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i><font-awesome-icon :icon="['fad', 'text']" /></i>
            </span>
          </div>
          <input
            v-model.trim="$v.hardware.name.$model"
            :class="['form-control', {'is-invalid': $v.hardware.name.$error}]"
            :disabled="disabled"

            type="text"
          >
        </div>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-12 col-md-6">
        <div class="row">
          <label class="d-none d-lg-block col-lg-12">
            End of Chain

            <i v-b-tooltip="`Inschakelen indien de caesium Core het begin- of eindpunt is in de communicatielijn met caesium nodes`">
              <font-awesome-icon :icon="['fas', 'info-circle']" />
            </i>
          </label>
          <div class="col-3 col-lg-12">
            <span :class="['kt-switch', {'kt-switch--success': $v.hardware.eoc_enabled.$model}]">
              <label>
                <input
                  v-model="$v.hardware.eoc_enabled.$model"
                  :disabled="disabled"

                  type="checkbox"
                >
                <span />
              </label>
            </span>
          </div>
          <label class="d-block d-lg-none col-9 text-md-center col-form-label">
            End of Chain

            <i v-b-tooltip="`Inschakelen indien de caesium Core het begin- of eindpunt is in de communicatielijn met caesium nodes`">
              <font-awesome-icon :icon="['fas', 'info-circle']" />
            </i>
          </label>
        </div>
      </div>
    </div>

  </div>
</template>

<style lang="scss">
@import 'bootstrap/scss/bootstrap';
@import 'bootstrap-vue/src/_variables.scss';
@import 'bootstrap-vue/src/components/tooltip/index.scss';
</style>

<script>
// Classes
import Master from '@/libs/classes/master';

// Validations
import { required } from 'vuelidate/lib/validators';
import { CSID } from '@/libs/validators/index';

// Directives
import { VBTooltip } from 'bootstrap-vue';

export default {
  directives: {
    BTooltip: VBTooltip
  },
  props: {
    hardware: {
      type: Master,
      default: null
    },

    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: Boolean,
      default: true
    }
  },
  validations: {
    hardware: {
      serial: {
        required,
        CSID
      },
      name: {
        required
      },
      eoc_enabled: {}
    }
  }
};
</script>
