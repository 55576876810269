<template>
  <div class="kt-subheader kt-grid__item cs-store-subheader">
    <div class="kt-container kt-container--fluid">
      <div class="col-12 mt-2">
        <div class="kt-wizard-v3">
          <div class="kt-wizard-v3__nav p-0" style="border-bottom:none;">
            <div class="kt-wizard-v3__nav-line" style="margin:0 15%;"></div>
            <div class="kt-wizard-v3__nav-items">
              <div data-ktwizard-type="step" :data-ktwizard-state="wizardSteps.cart" v-on:click.prevent="clickOnStep('CART')" class="kt-wizard-v3__nav-item" :class="[{'cursor-pointer': wizardSteps.cart === 'done'}]">
                <span>1</span>
                <i><font-awesome-icon :icon="['far', 'check']" /></i>
                <div class="kt-wizard-v3__nav-label">Winkelwagen</div>
              </div>

              <div data-ktwizard-type="step" :data-ktwizard-state="wizardSteps.payments" class="kt-wizard-v3__nav-item">
                <span>2</span>
                <i><font-awesome-icon :icon="['far', 'check']" /></i>
                <div class="kt-wizard-v3__nav-label">Betaalmethodes</div>
              </div>

              <div data-ktwizard-type="step" :data-ktwizard-state="wizardSteps.finish" class="kt-wizard-v3__nav-item">
                <span>3</span>
                <i><font-awesome-icon :icon="['far', 'check']" /></i>
                <div class="kt-wizard-v3__nav-label">Afronding</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/sass/shop/subheader.scss";
</style>

<script>
// Mixins
import shop from '@/mixins/shop';

const bodyClassList = ['kt-subheader--fixed', 'kt-subheader--enabled', 'kt-subheader--solid'];

export default {
  mixins: [shop],
  props: {
    meta: Object
  },
  mounted() {
    // Apply body classes
    DOMTokenList.prototype.add.apply(document.body.classList, bodyClassList);
  },
  beforeDestroy() {
    // Remove body classes again
    DOMTokenList.prototype.remove.apply(document.body.classList, bodyClassList.concat('kt-aside--minimize-hover'));
  },
  computed: {
    wizardSteps() {
      let steps = {
        cart: 'pending',
        payments: 'pending',
        finish: 'pending'
      }

      if (this.meta && this.meta.shop) {
        if (this.meta.shop.action === 'CART') {
          steps.cart = 'current';
        }

        if (this.meta.shop.action === 'PAYMENTS') {
          steps.cart = 'done';
          steps.payments = 'current';
        }

        if (this.meta.shop.action === 'FINISH') {
          steps.cart = 'done';
          steps.payments = 'done';
          steps.finish = 'done';
        }
      }

      return steps;
    }
  },
  methods: {
    clickOnStep(step = null) {
      if (step === 'CART' && this.wizardSteps.cart === 'done') {
        if (this.wizardSteps.finish === 'done') {
          this.shop.removeSalesOrder();
        }

        return this.navigateTo('/sales/shop/:cash_register/cart');
      }

      return null;
    }
  }
}
</script>
