<template>
  <div
    v-if="isLoading === true"
    class="row border-bottom pb-3 pt-3"
  >
    <div class="col-12">
      <div class="loading-container ml-auto mr-auto">
        <div class="loading-block">
          <div class="blockui ml-auto mr-auto">
            <span>Wachtrij ophalen...</span>
            <span class="kt-spinner kt-spinner--v2 kt-spinner--focus" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    v-else-if="sessionQueue && sessionQueue.length > 0"
    class="row border-bottom"
  >
    <div class="col-12">
      <div
        v-for="session in sessionQueue"
        :key="`session.${session._meta.guid}`"
        class="row p-3"
      >
        <div class="col-12 col-lg-6">
          <div class="kt-widget kt-widget--general-1">
            <div class="kt-media kt-media--focus kt-media--lg kt-media--circle">
              <span class="kt-badge kt-badge--username kt-badge--lg kt-badge--dark kt-badge--bold">{{ session.customer.initials }}</span>
            </div>
            <div class="kt-widget__wrapper">
              <div class="kt-widget__label">
                <router-link
                  :to="`/customers/view/${session.customer.guid}`"
                  class="kt-widget__title"
                >
                  {{ session.customer.name }}
                </router-link>
                <span class="kt-widget__desc">
                  Sessie duur: {{ (session.time / 60) }} minuten
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 mt-3 text-center col-lg-6 mt-lg-auto mb-lg-auto text-lg-right">
          <KButton
            :icon="['fad', 'clock']"
            tag="span"
            variant="label-brand"
            class="btn-md"
          >
            Start om {{ moment(session.start_timestamp).format("HH:mm") }}
          </KButton>

          <KButton
            :icon="['fad', 'clock']"
            tag="span"
            variant="label-focus"
            class="btn-md"
          >
            Eindigt om {{ moment(session.end_timestamp).format("HH:mm") }}
          </KButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Libraries
import moment from 'moment-timezone';

// Classes
import Equipment from '@/libs/classes/equipment';
import Customer from '@/libs/classes/customer';

export default {
  props: {
    equipment: {
      type: Equipment,
      default: null
    }
  },
  data () {
    return {
      isLoading: false,
      sessionQueue: [],

      moment
    };
  },
  watch: {
    'equipment.communication.session' (newValue, oldValue) {
      // If the session of the equipment changes, reload the session queue
      if (!newValue || !oldValue || newValue.id !== oldValue.id) {
        this.getSessionQueue();
      }
    },
    'equipment.state' (newValue, oldValue) {
      // If state has changed, and still in session, recalculate queue time
      if (this.equipment.inSession === true) {
        this.sessionQueue = this.calculateQueueTime(this.sessionQueue);
      }
    }
  },

  mounted () {
    // Get session queue
    this.getSessionQueue();
  },

  methods: {
    async getSessionQueue (options = {}) {
      this.$set(this, 'sessionQueue', []);

      if (this.equipment) {
        this.$set(this, 'isLoading', true);

        // Try fetching queue
        try {
          let queue = await this.equipment.getSessionQueue(options);

          // Check if queue has items
          if (queue && queue.length > 0) {
            // Limit to 5 rows
            queue = queue.slice(0, 5);

            // Get customers of sessions
            let customers = await this.$ws.get('v1', '/customers', {
              query: {
                'filter[_meta.guid]': queue.map(session => session.customer_guid, []).join(',')
              }
            });
            customers = customers.map(customer => new Customer().mergeResponse(customer), []);

            // Actions for queue
            queue.forEach((session, idx) => {
              // Links customer to session
              if (session && session.customer_guid) {
                queue[idx].customer = customers.find(c => c.guid === session.customer_guid);
              }
            });

            // Calculate queue time
            queue = this.calculateQueueTime(queue);
          }

          this.$set(this, 'sessionQueue', queue);
        } catch (e) {
          console.error('Failed fetching session queue', e);
        }

        this.$set(this, 'isLoading', false);
      }

      return this.sessionQueue;
    },

    calculateQueueTime (queue = []) {
      if (queue && queue.length > 0) {
        if (this.equipment && this.equipment.inSession === true && this.equipment.remainingSessionTime) {
          queue.forEach((session, idx) => {
            let lastEndTimestamp = this.equipment.remainingSessionTime.end_timestamp;
            let beforeQueue = queue.filter(q => idx > queue.indexOf(q), []);
            if (beforeQueue.length > 0) lastEndTimestamp = beforeQueue[(beforeQueue.length - 1)].end_timestamp;

            queue[idx].start_timestamp = lastEndTimestamp;
            queue[idx].end_timestamp = ((queue[idx].start_timestamp / 1000) + queue[idx].delay + queue[idx].time + queue[idx].cooldown) * 1000;
          });
        }
      }
      return queue;
    }
  }
};
</script>
