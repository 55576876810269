<template>
  <KPortlet>
    <KPortletHead>
      <template v-slot:default>Transactie</template>
    </KPortletHead>
    <KPortletBody>

      <div class="row form-group mb-2">
        <label class="col-12 col-lg-3 col-form-label">Transactie start</label>
        <div class="col-12 col-lg-9">
          <div class="input-group">
            <div class="input-group-prepend">
              <span
                class="input-group-text"
                style="width:45px;"
              >
                <i>
                  <font-awesome-icon :icon="['fad', 'calendar']" />
                </i>
              </span>
            </div>
            <div
              type="text"
              class="form-control"
              readonly
            >
              {{ moment(salesorder._meta.created).format('LLL') }}
            </div>
          </div>
        </div>
      </div>

      <div class="row form-group mb-2">
        <label class="col-12 col-lg-3 col-form-label">Transactie afgerond</label>
        <div class="col-12 col-lg-9">
          <div class="input-group">
            <div class="input-group-prepend">
              <span
                class="input-group-text"
                style="width:45px;"
              >
                <i>
                  <font-awesome-icon :icon="['fad', 'calendar']" />
                </i>
              </span>
            </div>
            <div
              type="text"
              class="form-control"
              readonly
            >
              {{ (salesorder.finished_timestamp ? moment(salesorder.finished_timestamp).format('LLL') : 'Nog niet afgerond') }}
            </div>
          </div>
        </div>
      </div>

      <KSeparator class="mt-3 mb-3" />

      <div class="row form-group mb-2">
        <label class="col-12 col-lg-3 col-form-label">Kassa</label>
        <div class="col-12 col-lg-9">
          <div class="input-group">
            <div class="input-group-prepend">
              <span
                class="input-group-text"
                style="width:45px;"
              >
                <i>
                  <font-awesome-icon :icon="['fad', 'cash-register']" />
                </i>
              </span>
            </div>
            <div
              type="text"
              class="form-control"
              readonly
            >
              <router-link
                v-if="cashRegister"
                :to="`/cash_registers/view/${cashRegister.guid}`"
                tag="a"
              >
                {{ cashRegister.name }}
              </router-link>

              <span v-else>
                Onbekende kassa
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="row form-group mb-2">
        <label class="col-12 col-lg-3 col-form-label">Medewerker</label>
        <div class="col-12 col-lg-9">
          <div class="input-group">
            <div class="input-group-prepend">
              <span
                class="input-group-text"
                style="width:45px;"
              >
                <i>
                  <font-awesome-icon :icon="['fad', 'user']" />
                </i>
              </span>
            </div>
            <div
              type="text"
              class="form-control"
              readonly
            >
              <template v-if="salesorder.finished_user_guid">
                <router-link
                  v-if="$auth.hasPermission([['get_users']])"
                  :to="`/users/view/${salesorder.finished_user_guid}`"
                  tag="a"
                >
                  {{ salesorder.finished_username }}
                </router-link>

                <template v-else>
                  {{ salesorder.finished_username }}
                </template>
              </template>
              <template v-else>Onbekend</template>
            </div>
          </div>
        </div>
      </div>

      <KSeparator class="mt-3 mb-3" />

      <div class="row form-group mb-2">
        <label class="col-12 col-lg-3 col-form-label">Subtotaal</label>
        <div class="col-12 col-lg-9">
          <div class="input-group">
            <div class="input-group-prepend">
              <span
                class="input-group-text"
                style="width:45px;"
              >
                <i>
                  <font-awesome-icon :icon="['fad', 'euro-sign']" />
                </i>
              </span>
            </div>
            <input
              :value="$format.currency(salesorder.total_excl_vat)"
              type="text"
              class="form-control"
              disabled
            >
          </div>
        </div>
      </div>

      <div class="row form-group mb-2">
        <label class="col-12 col-lg-3 col-form-label">BTW</label>
        <div class="col-12 col-lg-9">
          <div class="input-group">
            <div class="input-group-prepend">
              <span
                class="input-group-text"
                style="width:45px;"
              >
                <i>
                  <font-awesome-icon :icon="['fad', 'euro-sign']" />
                </i>
              </span>
            </div>
            <input
              :value="$format.currency(salesorder.total_incl_vat - salesorder.total_excl_vat)"
              type="text"
              class="form-control"
              disabled
            >
          </div>
        </div>
      </div>

      <div class="row form-group mb-2">
        <label class="col-12 col-lg-3 col-form-label">Totaal</label>
        <div class="col-12 col-lg-9">
          <div class="input-group">
            <div class="input-group-prepend">
              <span
                class="input-group-text"
                style="width:45px;"
              >
                <i>
                  <font-awesome-icon :icon="['fad', 'euro-sign']" />
                </i>
              </span>
            </div>
            <input
              :value="$format.currency(salesorder.total_incl_vat)"
              type="text"
              class="form-control"
              disabled
            >
          </div>
        </div>
      </div>

      <template v-if="!salesorder.original_salesorder_guid">
        <KSeparator class="mt-3 mb-3" />
        <div class="row">
          <div class="offset-3 col-6">
            <router-link
              :to="`/cash_registers/salesorders/payback/${salesorder._meta.guid}`"
              tag="a"
            >
              <KButton
                variant="danger"
                class="btn-block"
              >
                Terugbetaling
              </KButton>
            </router-link>
          </div>
        </div>
      </template>

    </KPortletBody>
  </KPortlet>
</template>

<script>
// Libraries
import moment from 'moment-timezone';

// Classes
import SalesOrder from '@/libs/classes/salesorder';
import CashRegister from '@/libs/classes/cash_register';

export default {
  props: {
    salesorder: {
      type: SalesOrder,
      default: null
    },
    cashRegister: {
      type: CashRegister,
      default: null
    }
  },
  data () {
    return {
      moment
    };
  }
};
</script>
