<template>
  <router-view :key="$route.fullPath"></router-view>
</template>

<script>
export default {
  data() {
    return {
    };
  },

  components: {
  },

  async mounted() {
  },
  methods: {
  }
}
</script>
