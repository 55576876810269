<template>
  <b-modal
    ref="modal"

    :title="(options && options.title ? options.title : 'Urenteller')"

    size="lg"
    centered
  >
    <template v-slot:default>
      <!-- Form -->
      <div class="row form-group">
        <div class="col-12">
          <label>Naam <sup>Verplicht</sup></label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i><font-awesome-icon :icon="['fad', 'text']" /></i>
              </span>
            </div>
            <input
              v-model.trim="$v.hourcounter.name.$model"
              :class="['form-control', {'is-invalid': $v.hourcounter.name.$error}]"
              :disabled="isDisabled"

              type="text"
            >
          </div>
        </div>
      </div>

      <div class="row form-group">
        <div class="col-12">
          <label>Opmerking</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i><font-awesome-icon :icon="['fad', 'text']" /></i>
              </span>
            </div>
            <input
              v-model.trim="hourcounter.remark"
              :disabled="isDisabled"

              type="text"
              class="form-control"
            >
          </div>
        </div>
      </div>

      <div class="row form-group">
        <div class="col-12 col-lg-6">
          <label>Huidig aantal uren <sup v-if="action !== 'EDIT'">Verplicht</sup></label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i><font-awesome-icon :icon="['fad', 'hashtag']" /></i>
              </span>
            </div>

            <!-- Input changeable -->
            <input
              v-if="(action === 'CREATE')"

              v-model.number="$v.hourcounter.currentHours.$model"
              :class="['form-control', {'is-invalid': $v.hourcounter.currentHours.$error}]"
              :disabled="(isDisabled || action === 'EDIT')"

              type="text"
            >

            <!-- Input read-only -->
            <input
              v-else

              :value="Math.round(hourcounter.currentHours)"
              class="form-control"
              disabled

              type="text"
            >
          </div>
        </div>

        <div class="col-12 mt-3 col-lg-6 mt-lg-0">
          <label>Maximaal aantal uren <sup>Verplicht</sup></label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i><font-awesome-icon :icon="['fad', 'hashtag']" /></i>
              </span>
            </div>
            <input
              v-model.number="$v.hourcounter.maxHours.$model"
              :class="['form-control', {'is-invalid': $v.hourcounter.maxHours.$error}]"
              :disabled="isDisabled"

              placeholder="100"

              type="text"
            >
          </div>
        </div>
      </div>

      <div class="row form-group mb-0">
        <div class="col-12 col-lg-6">
          <label>Waarschuwing</label>
          <div class="input-group">
            <KButtonGroup class="btn-block">
              <KButton
                :variant="(hasWarning === true ? 'primary' : 'outline-primary')"
                :disabled="isDisabled"

                @click.native.prevent="hasWarning = true"
              >
                Aan
              </KButton>

              <KButton
                :variant="(hasWarning === false ? 'primary' : 'outline-primary')"
                :disabled="isDisabled"

                @click.native.prevent="hasWarning = false"
              >
                Uit
              </KButton>
            </KButtonGroup>
          </div>
        </div>

        <div class="col-12 mt-3 col-lg-6 mt-lg-0">
          <label>Waarschuwingspercentage</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i><font-awesome-icon :icon="['fad', 'percentage']" /></i>
              </span>
            </div>
            <input
              v-model.lazy.number="warningPercentage"
              :disabled="(hasWarning === false || isDisabled)"
              type="text"
              class="form-control"
            >
          </div>
        </div>
      </div>

      <!-- Buttons -->
      <div
        v-if="action === 'EDIT'"
        class="row border-top mt-3 pt-3"
      >
        <div class="col-12 text-center">
          <KButton
            :icon="['fas', 'redo-alt']"
            :disabled="isDisabled"

            variant="focus"

            @click.native.prevent="reset"
          >
            <span>Reset uren</span>
          </KButton>
        </div>
      </div>
    </template>

    <template v-slot:modal-footer>
      <KButton
        v-if="action === 'EDIT'"
        :disabled="isDisabled"

        variant="light"
        class="mr-auto"

        @click.native.prevent="remove"
      >
        <font-awesome-icon icon="trash" />
      </KButton>

      <KButton
        :disabled="isLoading"

        variant="secondary"
        @click.native.prevent="hide"
      >
        Sluiten
      </KButton>

      <KButton
        v-if="(action === 'CREATE' || action === 'EDIT')"
        :disabled="isDisabled"

        :variant="(action === 'CREATE' ? 'primary' : 'warning')"
        @click.native.prevent="submit"
      >
        {{ (action === 'CREATE' ? 'Aanmaken' : 'Wijzigen') }}
      </KButton>
    </template>
  </b-modal>
</template>

<script>
// Classes
import Equipment from '@/libs/classes/equipment';
import EquipmentHourcounter from '@/libs/classes/equipment.hourcounter';

// Mixins
import Modal from '@/mixins/modal';

// Validations
import { required, requiredIf, minValue } from 'vuelidate/lib/validators';

export default {
  mixins: [Modal],
  data () {
    return {
      isLoading: false,

      action: 'CREATE',
      options: null,
      hasWarning: true,

      hourcounter: null,
      equipment: null
    };
  },
  computed: {
    isDisabled () {
      if (this.action === 'VIEW') {
        return true;
      }

      if (this.isLoading === true) {
        return true;
      }

      return false;
    },
    warningPercentage: {
      get () {
        return this.$format.percentage(this.hourcounter.warning / 100);
      },
      set (value) {
        value = Number(value);
        if (typeof value !== 'number' || isNaN(value)) return;

        // Set value to 100 if above 100
        if (value > 100) value = 100;

        // Set value to 0 if below 0
        if (value < 0) value = 0;

        this.hourcounter.warning = value;
      }
    }
  },
  validations: {
    hourcounter: {
      name: {
        required,
        unique: function (value) {
          if (this.action === 'EDIT' && this.hourcounter.name === this.options.hourcounter.name) return true;
          return this.equipment.timers.filter((timer) => timer.name === value, []).length === 0;
        }
      },

      currentHours: {
        required,
        minValue: minValue(0)
      },
      maxHours: {
        required,
        minValue: minValue(1)
      },
      warning: {
        required: requiredIf(function () {
          return this.hasWarning;
        })
      }
    }
  },

  mounted () {
    // Listeners
    this.$eventhub.on('modals:equipment:hourcounter:open', this.open);
    this.$eventhub.on('modals:equipment:hourcounter:close', this.hide);
  },
  beforeDestroy () {
    // Remove listeners
    this.$eventhub.off('modals:equipment:hourcounter:open', this.open);
    this.$eventhub.off('modals:equipment:hourcounter:close', this.hide);
  },

  methods: {
    async open (options = {}) {
      // Restore defaults
      this.$v.$reset();
      this.$set(this, 'isLoading', false);
      this.$set(this, 'options', options);
      this.$set(this, 'action', 'CREATE');
      this.$set(this, 'hasWarning', true);
      this.$set(this, 'hourcounter', new EquipmentHourcounter().merge({
        warning: 90
      }));

      if (typeof this.options !== 'object') {
        throw new Error('Options object not set');
      }

      // Set equipment
      if (!this.options.equipment || this.options.equipment instanceof Equipment === false) {
        throw new Error('Equipment not found');
      }
      this.$set(this, 'equipment', this.options.equipment);

      // Set hourcounter
      if (this.options.hourcounter) {
        this.$set(this, 'action', (this.$auth.hasPermission([['update_equipments']]) ? 'EDIT' : 'VIEW'));
        this.$set(this, 'hourcounter', this.hourcounter.merge(this.options.hourcounter));
        this.$set(this, 'hasWarning', (this.options.hourcounter.warning !== null && this.options.hourcounter.warning > 0));
      }

      // Show modal
      Modal.methods.show.call(this);
    },

    async validate () {
      const validationArray = [this.$v];

      // Execute validations
      if (validationArray.length > 0) {
        validationArray.forEach(v => v && v.$touch && v.$touch());
        if (validationArray.filter(v => v && v.$invalid).length !== 0) return false;
      }

      return true;
    },
    async submit (options = {}) {
      if (await this.validate() === true) {
        // Set loading
        this.$set(this, 'isLoading', true);

        // Set hourcounter to null, if hasWarning is false
        if (this.hasWarning === false) {
          this.hourcounter.warning = null;
        }

        // Run onSubmit, if given
        if (typeof this.options.onSubmit === 'function') {
          try {
            await this.options.onSubmit(this.hourcounter);
          } catch (e) {
            this.$error.showModal(e);
            this.$set(this, 'isLoading', false);
            return;
          }
        }

        this.$set(this, 'isLoading', false);
        return this.hide();
      }
    },
    async reset (options = {}) {
      // Set loading
      this.$set(this, 'isLoading', true);

      // Run onReset, if given
      if (typeof this.options.onReset === 'function') {
        try {
          await this.options.onReset(this.hourcounter);
        } catch (e) {
          this.$error.showModal(e);
          this.$set(this, 'isLoading', false);
          return;
        }
      }

      this.$set(this, 'isLoading', false);
      return this.hide();
    },
    async remove (options = {}) {
      // Set loading
      this.$set(this, 'isLoading', true);

      const vm = this;
      return this.$eventhub.emit('modals:confirm:open', {
        resource: vm.hourcounter,

        title: 'Verwijder urenteller',
        text: `Weet je zeker dat je de urenteller wilt verwijderen?`,
        submit: async () => {
          // Run onReset, if given
          if (typeof vm.options.onRemove === 'function') {
            try {
              await vm.options.onRemove(vm.hourcounter);
            } catch (e) {
              vm.$error.showModal(e);
              vm.$set(this, 'isLoading', false);
              return;
            }
          }

          vm.$set(this, 'isLoading', false);
          return vm.hide();
        }
      });
    }
  }
};
</script>
