<template>
  <div>
    <!-- Stores information -->
    <div
      v-if="action === 'CREATE'"
      class="row pb-3 border-bottom"
    >
      <div class="col-12">
        <h5 class="overflow-ellipsis">
          Winkels
        </h5>
        <span>
          Bepaal hieronder tot welke winkels de gebruiker toegang heeft
        </span>
      </div>
    </div>

    <!-- Stores -->
    <div :class="['row', {'mt-3': action === 'CREATE'}]">
      <div class="col-12 p-0">
        <!-- Loading -->
        <template v-if="isMounted === false">
          <div class="loading-container absolute">
            <div class="loading-backdrop">
              <div class="loading-block">
                <div class="blockui">
                  <span>Please wait...</span>
                  <span class="kt-spinner kt-spinner--v2 kt-spinner--primary" />
                </div>
              </div>
            </div>
          </div>
        </template>

        <!-- Stores -->
        <template v-else>
          <label
            v-for="(store) in stores"
            :key="`store.${store.guid}`"
            :class="['kt-option cursor-pointer', {'is-invalid': $v.user.stores.$error}]"
          >
            <span class="kt-option__control">
              <span :class="['kt-checkbox kt-checkbox--check-bold', {'kt-checkbox--focus': ($v.user.stores.$model.indexOf(store.guid) !== -1 || hasAccessToAllStores === true)}]">
                <input
                  v-if="hasAccessToAllStores === false"
                  :key="`store.${store.guid}.accessPerStore`"

                  v-model="$v.user.stores.$model"
                  :value="store.guid"
                  :disabled="isDisabled"

                  type="checkbox"
                >

                <input
                  v-else
                  :key="`store.${store.guid}.hasAccessToAllStores`"

                  type="checkbox"
                  checked
                  disabled
                >
                <span />
              </span>
            </span>

            <span class="kt-option__label">
              <span class="kt-option__head">
                <span class="kt-option__title">
                  {{ store.name }}
                </span>
              </span>
              <span class="kt-option__body pt-1">
                Location details here (TODO: after CAES-431)
              </span>
            </span>
          </label>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
// Classes
import User from '@/libs/classes/user';
import Store from '@/libs/classes/store';

export default {
  props: {
    user: {
      type: User,
      default: null
    },

    action: {
      type: String,
      default: 'VIEW'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isMounted: false,

      stores: []
    };
  },
  computed: {
    isDisabled () {
      return (this.disabled === true || this.action === 'VIEW');
    },
    hasAccessToAllStores () {
      // Check permission group of user
      if (this.user) {
        // Check if permission group has 'access_all_stores'
        if (this.user.hasPermission('access_all_stores') === true) {
          return true;
        }

        // Check if permission group has 'workspace_manager'
        if (this.user.hasPermission('workspace_manager') === true) {
          return true;
        }

        // Check if permission group has 'get_stores'
        if (this.user.hasPermission('get_stores') === true) {
          return true;
        }
      }
      return false;
    }
  },

  validations: {
    user: {
      stores: {}
    }
  },

  async mounted () {
    try {
      await this.getStores();
    } catch (e) {
      console.error(e);
      return;
    }

    this.$nextTick(() => {
      this.isMounted = true;
    });
  },

  methods: {
    async getStores (options = {}) {
      this.$set(this, 'stores', []);

      let stores = await this.$ws.get('v1', '/stores/me', options);
      stores = stores.map(store => {
        return new Store().mergeResponse(store);
      });

      this.$set(this, 'stores', stores);
      return [];
    }
  }
};
</script>
