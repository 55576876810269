<template>
  <KModal ref="modal" title="Cadeaubon" centered size="lg" scrollable v-keydown="{'escape': close }">
    <template v-slot:default>
      <!-- Search giftcard code -->
      <div class="row form-group mb-0">
        <div class="col-12">
          <label>Cadeaubon code <sup>Verplicht</sup></label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i><font-awesome-icon :icon="['fad', 'gift-card']" /></i>
              </span>
            </div>
            <input type="text" ref="giftcardCodeInput" class="form-control" v-model.trim="$v.form.giftcard.code.$model" :class="[{'is-invalid': $v.form.giftcard.code.$error}]" v-on:keyup.enter="getGiftcard" v-on:keyup="getGiftcardDebounced" :disabled="form.search.loading === true" />
            <div class="input-group-append">
              <span v-show="form.search.loading === true" class="kt-spinner kt-spinner--v2 kt-spinner--primary"></span>
						</div>
          </div>
        </div>
      </div>

      <template v-if="giftcard !== null">

        <KSeparator class="kt-separator--space-sm" />

        <!-- Show data of giftcard -->
        <div class="row">
          <div class="col-12 col-lg-4">
            <div class="text-block text-center">
              <h5 class="title font-weight-normal">Saldo</h5>
              <h3 class="value">
                &euro; {{ $format.currency(giftcard.amount) }}
              </h3>
            </div>
          </div>

          <div class="col-12 mt-3 col-lg-4 mt-lg-0">
            <div class="text-block text-center">
              <h5 class="title font-weight-normal">Initieel saldo</h5>
              <h3 class="value">
                &euro; {{ $format.currency(giftcard.start_amount) }}
              </h3>
            </div>
          </div>

          <div class="col-12 mt-3 col-lg-4 mt-lg-0">
            <div class="text-block text-center">
              <h5 class="title font-weight-normal">Uitgiftedatum</h5>
              <h3 class="value">
                {{ moment(giftcard._meta.created).format('LL') }}
              </h3>
            </div>
          </div>
        </div>

        <KSeparator class="kt-separator--space-sm" />

        <!-- Payment method amount -->
        <div class="row">
          <div class="col-12 offset-lg-1 col-lg-10">

            <!-- Input -->
            <div class="row form-group mb-0">
              <div class="col-12">
                <label>Bedrag</label>
                <div class="input-group cs-shop-payment-method-input">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i><font-awesome-icon :icon="['fad', 'euro-sign']" /></i>
                    </span>
                  </div>
                  <input type="number" ref="paymentValueInput" class="form-control" v-model.number.lazy="$v.payment_value_formatted.$model" :class="[{'is-invalid': $v.payment_value_formatted.$error}]" />
                </div>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-3">
                <KButton variant="focus" class="btn-block btn-lg" :disabled="maxValue < 500" v-on:click.native.prevent="setPaymentValue(5)">&euro; 5,00</KButton>
              </div>
              <div class="col-3">
                <KButton variant="focus" class="btn-block btn-lg" :disabled="maxValue < 1000" v-on:click.native.prevent="setPaymentValue(10)">&euro; 10,00</KButton>
              </div>
              <div class="col-3">
                <KButton variant="focus" class="btn-block btn-lg" :disabled="maxValue < 2000" v-on:click.native.prevent="setPaymentValue(20)">&euro; 20,00</KButton>
              </div>
              <div class="col-3">
                <KButton variant="focus" class="btn-block btn-lg" :disabled="maxValue < 5000" v-on:click.native.prevent="setPaymentValue(50)">&euro; 50,00</KButton>
              </div>
            </div>

          </div>
        </div>
      </template>
    </template>

    <template v-slot:footer>
      <KButton variant="secondary" v-on:click.native.prevent="close">Annuleren</KButton>
      <KButton variant="focus" v-if="giftcard !== null && giftcard.amount > 0" v-on:click.native.prevent="addPayment">In rekening brengen</KButton>
    </template>
  </KModal>
</template>

<script>
// Libraries
import shop from '@/libs/shop';
import moment from 'moment';
import { required } from 'vuelidate/lib/validators';
import { currency } from '@/libs/validators';

// Classes
import SalesOrder from '@/libs/classes/salesorder';

export default {
  props: {
    sales_order: SalesOrder
  },
  data () {
    return {
      form: {
        giftcard: {
          code: null
        },
        payment: {
          value: null
        },
        errors: [],

        search: {
          timeout: null,
          loading: false
        }
      },

      giftcard: null,

      moment
    }
  },
  computed: {
    maxValue () {
      let max = null;

      if (this.giftcard && this.giftcard !== null) {
        if (this.sales_order && this.sales_order.outstanding_balance < this.giftcard.amount) max = this.sales_order.outstanding_balance;
        else max = this.giftcard.amount;
      }

      return max;
    },

    payment_value_formatted: {
      get () {
        if (this.form.payment.value === null) return null;
        return this.$format.currencyToInput(this.form.payment.value);
      },
      set (value) {
        if (typeof value === 'string') value = Number(value);
        this.form.payment.value = this.$calculate.price(value, true);
      }
    }
  },
  validations: {
    form: {
      giftcard: {
        code: {
          required,
          customError: function (value) {
            return this.form.errors.indexOf('form.giftcard.code') === -1;
          }
        }
      }
    },
    payment_value_formatted: {
      required,
      currency,
      minValue: function (value) {
        return value > 0;
      },
      maxValue: function (value) {
        if (this.giftcard && this.giftcard !== null) {
          return this.$calculate.price(value, true) <= this.maxValue;
        }

        return true;
      }
    }
  },

  mounted () {
    // Listeners
    this.$eventhub.on('modals:shop:giftcard-payment-method:open', this.open);
    this.$eventhub.on('modals:shop:giftcard-payment-method:close', this.close);
  },
  beforeDestroy () {
    // Remove listeners
    this.$eventhub.off('modals:shop:giftcard-payment-method:open', this.open);
    this.$eventhub.off('modals:shop:giftcard-payment-method:close', this.close);
  },

  methods: {
    async validate () {
      // Clear custom errors
      this.resetCustomErrors();

      const validationArray = [];
      validationArray.push(this.$v);

      // Execute validations
      if(validationArray.length > 0) {
        validationArray.forEach(v => v && v.$touch && v.$touch())
        if(validationArray.filter(v => v && v.$invalid).length !== 0) return false;
      }

      return true;
    },
    async addPayment () {
      if (await this.validate() === true) {
        await shop.addTransaction('giftcard', this.form.payment.value, {
          giftcard_guid: this.giftcard._meta.guid
        });

        this.$nextTick(() => this.close());
      }
    },

    getGiftcardDebounced (event) {
      this.resetCustomErrors();
      clearTimeout(this.form.search.timeout);
      if (event instanceof KeyboardEvent && event.code !== 'Enter') this.form.search.timeout = setTimeout(() => this.getGiftcard(), 600);
    },
    async getGiftcard () {
      clearTimeout(this.form.search.timeout);
      this.resetPayment();

      if (typeof this.form.giftcard.code === 'string' && this.form.giftcard.code.length > 0) {
        this.$set(this.form.search, 'loading', true);

        let giftcard = null;
        try {
          giftcard = await this.$ws.get('v1', `/giftcards/${this.form.giftcard.code.toLowerCase()}`)
        } catch (e) {
          console.error(e);
        }

        this.$set(this.form.search, 'loading', false);

        if (giftcard !== null) {
          if (this.sales_order.outstanding_balance < giftcard.amount) {
            this.form.payment.value = this.sales_order.outstanding_balance;
          } else {
            this.form.payment.value = giftcard.amount;
          }

          this.$nextTick(() => {
            this.$refs['paymentValueInput'].focus();
          })

          return this.$set(this, 'giftcard', giftcard);
        } else {
          this.form.errors.push('form.giftcard.code');
        }
      }

      return this.$set(this, 'giftcard', null);
    },

    setPaymentValue (value) {
      if (value <= this.maxValue) this.$set(this.form.payment, 'value', this.$calculate.price(value, true));
    },
    resetCustomErrors () {
      this.$set(this.form, 'errors', []);
    },
    resetPayment () {
      this.$set(this.form.payment, 'value', null);
    },

    open () {
      this.$set(this.form.giftcard, 'code', null);
      this.$set(this, 'giftcard', null);
      this.resetPayment();
      this.resetCustomErrors();
      this.$v.$reset();

      this.$refs.modal.show();

      this.$nextTick(() => {
        this.$refs['giftcardCodeInput'].focus();
      })
    },
    close () {
      if (this.$refs.modal !== typeof undefined) this.$refs.modal.hide();
    }
  }
}
</script>
