<template>
  <KPortlet
    v-if="salesorder"
    class="position-relative"
  >
    <!-- Loading container -->
    <template v-if="isLoading">
      <div class="loading-container absolute">
        <div class="loading-backdrop">
          <div class="loading-block">
            <div class="blockui">
              <span>Please wait...</span>
              <span class="kt-spinner kt-spinner--v2 kt-spinner--primary" />
            </div>
          </div>
        </div>
      </div>
    </template>

    <!-- Cart -->
    <KPortletHead>
      <template v-slot:default>Winkelwagen</template>
      <template v-slot:toolbar>
        <KButton
          :icon="['far', 'cog']"
          :variant="((salesorder.remark || salesorder.discount_value > 0) ? 'success' : 'focus')"
          class="btn-sm"

          @click.native.prevent="openSalesorderSettings"
        />
      </template>
    </KPortletHead>
    <KPortletBody
      v-if="salesorder && salesorder.lines"
      style="font-size:16px;"
    >
      <salesorder-line
        v-for="line in salesorder.lines"
        :key="`salesorder.${salesorder.guid}.line.${line.guid}`"

        :line="line"
        :show-actions="showProductActions"
      />

      <!-- <template v-for="(line) in salesorder.lines">
        <div
          :key="`salesorder.line.${line.guid}`"
          class="row"
        >
          <div class="col-1 m-auto text-center">
            {{ `${line.quantity}x` }}
          </div>
          <div class="col-6 m-auto">
            {{ line.name }}
          </div>
          <div
            :class="[{'col-3': showProductActions === true, 'offset-1 col-4': showProductActions === false}]"
            class="text-right m-auto d-flex flex-row justify-content-between"
          >
            <div class="text-left">
              <i v-if="(line.type === 'session' && line.payment_type === 'credit')">
                <font-awesome-icon :icon="['fad', 'credit-card']" />
              </i>
              <i v-else-if="(line.type === 'session' && line.payment_type === 'bundle')">
                <font-awesome-icon :icon="['fad', 'box']" />
              </i>
              <span v-else>&euro;</span>
            </div>
            <div class="text-right">
              <template
                v-if="(line.type === 'session' && line.payment_type === 'bundle')"
              />

              <template
                v-else
              >
                {{ $format.currency(line.total_price_incl) }}
              </template>
            </div>
          </div>

          <template v-if="showProductActions === true">
            <div class="col-1 m-auto">
              <i>
                <font-awesome-icon
                  :icon="['fad', 'edit']"
                  class="cursor-pointer"
                  @click.prevent="editSalesorderLine(line)"
                />
              </i>
            </div>
            <div class="col-1 m-auto">
              <i>
                <font-awesome-icon
                  :icon="['fad', 'times']"
                  class="cursor-pointer"
                  @click.prevent="removeProduct(line.guid)"
                />
              </i>
            </div>
          </template>
        </div>

        <div
          v-if="(line.discount_type && line.discount_value > 0)"
          :key="`salesorder.line.${line.guid}.discount_row`"
          class="row"
        >
          <div class="offset-1 col">
            <small>
              <i v-if="line.discount_type === 'absolute'">&euro; {{ $format.currency(line.discount_value) }} korting</i>
              <i v-else-if="line.discount_type === 'percentile'">{{ $format.percentage((line.discount_value / 100)) }}% korting</i>
            </small>
          </div>
        </div>

        <KSeparator
          :key="`salesorder.line.${line.guid}.separator`"
          class="kt-separator--space-sm"
        />
      </template> -->
    </KPortletBody>
    <KPortletBody
      class="pt-0"
      style="font-size:16px;"
    >
      <div class="row">
        <div class="col-8">
          Subtotaal
        </div>
        <div class="col-4 d-flex flex-row justify-content-between">
          <div class="text-left">&euro;</div>
          <div class="text-right">{{ $format.currency(salesorder.total_excl_vat) }}</div>
        </div>
      </div>

      <div class="row">
        <div class="col-8">
          BTW
        </div>
        <div class="col-4 d-flex flex-row justify-content-between">
          <div class="text-left">&euro;</div>
          <div class="text-right">{{ $format.currency(salesorder.total_incl_vat - salesorder.total_excl_vat) }}</div>
        </div>
      </div>

      <KSeparator class="mt-2 mb-2" />

      <div class="row">
        <div class="col-8 font-weight-bold">
          Totaal
        </div>
        <div class="col-4 d-flex flex-row justify-content-between">
          <div class="text-left">&euro;</div>
          <div class="text-right">{{ $format.currency(salesorder.total_incl_vat) }}</div>
        </div>
      </div>

      <!-- Payments -->
      <template v-if="(meta && meta.shop && meta.shop.action === 'PAYMENTS') || (salesorder && salesorder.transactions && salesorder.transactions.length)">
        <KSeparator class="mt-5 mb-3" />

        <!-- Transactions -->
        <template v-if="visibleSalesorderTransactions.length">
          <div
            v-for="(transaction) in visibleSalesorderTransactions"
            :key="`salesorder.transaction.${transaction.guid}`"
            class="row"
          >
            <div class="col-7 m-auto">
              {{ transaction.payment_method }}
            </div>
            <div
              :class="[{'col-3 m-auto': showPaymentMethodActions === true, 'offset-1 col-4': showPaymentMethodActions === false}]"
              class="text-right d-flex flex-row justify-content-between"
            >
              <div class="text-left">
                <i v-if="(transaction.payment_method_type === 'session_credits')"><font-awesome-icon :icon="['fad', 'credit-card']" /></i>
                <i v-else-if="(transaction.payment_method_type === 'bundle')"><font-awesome-icon :icon="['fad', 'box']" /></i>
                <span v-else>&euro;</span>
              </div>
              <div
                v-if="(transaction.payment_method_type !== 'bundle')"
                class="text-right"
              >
                {{ $format.currency(transaction.amount * -1) }}
              </div>
            </div>
            <template v-if="showPaymentMethodActions === true">
              <div class="col-1 m-auto">
                <i>
                  <font-awesome-icon
                    v-if="canEditTransaction(transaction)"
                    :icon="['fad', 'edit']"
                    class="cursor-pointer"
                    @click.prevent="editTransaction(transaction)"
                  />
                </i>
              </div>
              <div class="col-1 m-auto">
                <i>
                  <font-awesome-icon
                    v-if="canRemoveTransaction(transaction)"
                    :icon="['fad', 'times']"
                    class="cursor-pointer"
                    @click.prevent="removeTransaction(transaction.guid)"
                  />
                </i>
              </div>
            </template>
          </div>

          <KSeparator class="mt-3 mb-2" />

        </template>

        <div
          v-if="salesorder.change !== 0"
          class="row"
        >
          <div class="col-8">
            Wisselgeld
          </div>
          <div class="col-4 d-flex flex-row justify-content-between">
            <div class="text-left">&euro;</div>
            <div class="text-right">{{ $format.currency($format.cashChange(salesorder.change)) }}</div>
          </div>
        </div>

        <div class="row">
          <div class="col-8 font-weight-bold">
            Nog te betalen
          </div>
          <div class="col-4 d-flex flex-row justify-content-between">
            <div class="text-left">&euro;</div>
            <div class="text-right">{{ $format.currency(salesorder.outstanding_balance) }}</div>
          </div>
        </div>
      </template>

    </KPortletBody>
  </KPortlet>
</template>

<script>
// Libraries
import mutationsLib from '@/libs/mutations';

// Mixins
import shop from '@/mixins/shop';

// Classes
import Salesorder from '@/libs/classes/salesorder';

// Components
import salesorderLine from './line';

export default {
  components: {
    salesorderLine
  },
  mixins: [shop],
  props: {
    meta: {
      type: Object,
      default: null
    },
    salesorder: {
      type: Salesorder,
      default: null
    }
  },
  data () {
    return {
      isLoading: false
    };
  },
  computed: {
    showProductActions () {
      if (this.meta && this.meta.shop) {
        if (this.meta.shop.action === 'PAYMENTS') return false;
        if (this.meta.shop.action === 'FINISH') return false;
      }

      if (this.salesorder && this.salesorder.paid === true) return false;

      return true;
    },
    showPaymentMethodActions () {
      if (this.meta && this.meta.shop) {
        if (this.meta.shop.action === 'FINISH') return false;
      }

      if (this.salesorder && this.salesorder.paid === true) return false;

      return true;
    },

    visibleSalesorderTransactions () {
      if (this.salesorder && this.salesorder.transactions) {
        return this.salesorder.transactions.filter(t => t.from_salesorder_line !== true && t.change !== true, []);
      }
      return [];
    }
  },

  mounted () {
    // Listeners
    this.$eventhub.on('shop:cart:set-loading', this.setCartLoading);
  },
  beforeDestroy () {
    // Remove listeners
    this.$eventhub.off('shop:cart:set-loading', this.setCartLoading);
  },
  methods: {
    setCartLoading (state = true) {
      this.$set(this, 'isLoading', state);
    },

    canEditTransaction (transaction) {
      const changeableTransactions = ['cash', 'card', 'on_account'];
      if (transaction && changeableTransactions.indexOf(transaction.payment_method_type) !== -1) {
        return true;
      }
      return false;
    },
    canRemoveTransaction (transaction) {
      if (transaction && transaction.payment_method_type) {
        if (transaction.payment_method_type === 'session_credits') return false;
        if (transaction.payment_method_type === 'bundle') return false;
        return true;
      }

      return false;
    },
    openSalesorderSettings () {
      const vm = this;
      this.$eventhub.emit('modals:shop:salesorder-settings:open', {
        salesorder: vm.salesorder,
        async onSubmit (salesorder = null, options = {}) {
          const fields = ['remark', 'discount_value', 'discount_overwrite_option'];
          const mutations = mutationsLib.create(fields, vm.shop.sales_order.formatBody(), salesorder.formatBody());

          // Check if discount overwrite options modal is given
          if (options.discountOverwriteModalGiven === true) {
            if (!mutations.find(m => m.field === 'discount_overwrite_option')) {
              mutations.push({
                action: 'set_field',
                field: 'discount_overwrite_option',
                value: salesorder.discount_overwrite_option
              });
            }
          }

          // Check if mutations are found
          if (mutations.length > 0) {
            let updateOptions = {};
            let salesorderOptions = {};

            // Check if discount_value or discount_overwrite_option is changed
            if (mutations.find(m => (m.field === 'discount_value' || m.field === 'discount_overwrite_option'))) {
              // Set updateOptions with persistent true
              updateOptions = Object.assign(updateOptions, {
                query: {
                  persistent: true
                }
              });

              // Set salesorder options so that the lines have disable cache
              salesorderOptions = Object.assign(salesorderOptions, {
                lines: {
                  query: {
                    disable_cache: true
                  }
                }
              });
            }

            // Update and refresh data
            vm.$eventhub.emit('shop:cart:set-loading', true);
            try {
              await vm.shop.sales_order.update(mutations, updateOptions);
              await vm.shop.getSalesOrder(salesorderOptions);
            } catch (e) {
              console.error(e);
            }
            vm.$eventhub.emit('shop:cart:set-loading', false);
          }
        }
      });
    },

    async editTransaction (transaction = null, options = {}) {
      if (this.showPaymentMethodActions === false) return;
      this.$eventhub.emit('modals:shop:payment-method:open', {
        method: transaction.payment_method_type,
        transaction: transaction
      });
    },
    async removeTransaction (guid = null, options = {}) {
      if (this.showPaymentMethodActions === false) return;
      this.setCartLoading(true);
      try { await this.shop.removeTransaction(guid, options); } catch (e) { console.error(e); }
      this.setCartLoading(false);
    }
  }
};
</script>
