<template>
  <div>
    <modal-switch-store ref="modalSwitchStore" />
    <modal-calculator ref="modalCalculator" />
    <modal-confirm ref="modalConfirm" />
    <modal-error ref="modalError" />
    <modal-locked-session ref="modalLockedSession" />
  </div>
</template>

<script>
// Modals
import modalSwitchStore from '@/components/modals/switch-store';
import modalCalculator from '@/components/modals/calculator';
import modalConfirm from '@/components/modals/confirm.modal';
import modalError from '@/components/modals/error.modal';
import modalLockedSession from '@/components/modals/lockedSession.modal';

export default {
  components: {
    modalSwitchStore,
    modalCalculator,
    modalConfirm,
    modalError,
    modalLockedSession
  },

  mounted () {

  },

  methods: {

  }
};
</script>
