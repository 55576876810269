<template>
  <div v-if="isMounted">

    <KSubheader
      title="Prijslijsten"
      :breadcrumbs="[{
        page: 'Producten',
        route: '/products'
      }, {
        page: 'Prijslijsten',
        route: '/products/pricelists'
      }, {
        page: pricelist.name,
        route: null
      }]"
    >

    </KSubheader>

    <KContainer>
      <div class="row">
        <div class="col-12 col-portlet-menu">
          <KPortlet>
            <KPortletBody>
              <div class="kt-widget kt-widget--general-1">
                <div class="kt-media kt-media--brand kt-media--md kt-media--circle">
                  <span class="kt-badge kt-badge--username kt-badge--lg kt-badge--dark kt-badge--bold">
                    <i><font-awesome-icon :icon="['fad', 'tags']" /></i>
                  </span>
                </div>
                <div class="kt-widget__wrapper">
                  <div class="kt-widget__label">
                    <a href="#" class="kt-widget__title">{{ pricelist.name }}</a>
                  </div>
                </div>
              </div>
            </KPortletBody>
            <div class="kt-portlet__separator"></div>
            <KPortletBody>
              <ul class="kt-nav kt-nav--bolder kt-nav--fit-ver kt-nav--v4" role="tablist">
                <router-link to="settings" v-slot="{ href, route, navigate, isActive, isExactActive }">
                  <li class="kt-nav__item" :class="[{'active': isActive}]">
                    <a class="kt-nav__link" role="tab" @click.prevent="$router.push(href)">
                      <span class="kt-nav__link-icon">
                        <i><font-awesome-icon :icon="['fad', 'cogs']" /></i>
                      </span>
                      <span class="kt-nav__link-text">Instellingen</span>
                    </a>
                  </li>
                </router-link>

                <router-link to="prices" v-slot="{ href, route, navigate, isActive, isExactActive }">
                  <li class="kt-nav__item" :class="[{'active': isActive}]">
                    <a class="kt-nav__link" role="tab" @click.prevent="$router.push(href)">
                      <span class="kt-nav__link-icon">
                        <i><font-awesome-icon :icon="['fad', 'euro-sign']" /></i>
                      </span>
                      <span class="kt-nav__link-text">Prijzen</span>
                    </a>
                  </li>
                </router-link>
              </ul>
            </KPortletBody>
          </KPortlet>
        </div>

        <div class="col-12 col-sm">
          <router-view :key="$route.fullPath"
            :pricelist.sync="pricelist"
          ></router-view>
        </div>
      </div>

    </KContainer>

  </div>
</template>

<script>
// Classes
import Pricelist from '@/libs/classes/pricelist';
import PricelistPrice from '@/libs/classes/pricelist.price';
import Product from '@/libs/classes/product';

export default {
  data () {
    return {
      isMounted: false,

      pricelist: null
    };
  },

  methods: {
    async getPricelist (options = {}) {
      this.$set(this, 'pricelist', null);
      let pricelist = await new Pricelist(this.$route.params.guid).get(options);
      await pricelist.populate('get', options);
      this.$set(this, 'pricelist', pricelist);
      return this.pricelist;
    }
  },

  async mounted () {
    try {
      await this.getPricelist();
    } catch (e) {
      console.error('Pricelist not found:', e);
      return;
    }

    this.$nextTick(() => {
      this.isMounted = true;
    })
  }
};
</script>
