import Abstract from './abstract';

// Libraries
import { merge } from 'lodash';
import { apiWS as ws } from '@/libs/ws';

// Classes
import Customer from './customer';
import SalesOrderLine from './salesorder.line';
import PaymentTransaction from './payment_transaction';

class SalesOrder extends Abstract {
  constructor (guid = null) {
    super(guid);
    this.uri = '/salesorders';

    let defaultObject = {
      customer_guid: null,

      discount_type: null,
      discount_value: null,
      remark: null,

      lines: [],
      transactions: []
    };

    merge(this, defaultObject);
  }

  // Getters & Setters
  get total_paid () {
    let paid = 0;

    if (this.transactions && this.transactions.length > 0) {
      paid = this.transactions.filter(t => t.from_salesorder_line !== true && t.change !== true, []).map(transaction => {
        return transaction.amount;
      }, []).reduce((a, b) => a + b, 0);
    }

    return paid;
  }

  get total_discount () {
    let discount = 0;

    // Discount from lines
    if (this.lines && this.lines.length > 0) {
      discount += this.lines.filter(line => line.has_discount === true, []).map(line => line.total_discount, []).reduce((a, b) => a + b, 0);
    }

    // Discount from salesorder
    // TODO: Add discount from salesorder

    return discount;
  }

  get outstanding_balance () {
    let outstanding = (this.total_incl_vat - this.total_paid);
    if (outstanding < 0) return 0;
    return outstanding;
  }

  get change () {
    let outstanding = (this.total_incl_vat - this.total_paid);
    if (outstanding < 0) return (outstanding * -1);
    return 0;
  }

  // Methods
  mergeResponse (response = {}) {
    super.mergeResponse(response);

    // Set customer to a Customer class
    if (typeof this.customer === 'object' && this.customer !== null) {
      this.customer = new Customer().mergeResponse(this.customer);
    }

    return this;
  }

  async populate (type = 'get', options = {}) {
    // Get salesorder lines and transactions
    await Promise.all([
      this.getSalesOrderLines(options),
      this.getTransactions(options)
    ]);

    return this;
  }

  // Sales order
  async finish (options = {}) {
    const response = await ws.put('v1', `${this.uri}/${this.guid}/finish`, options);
    return response;
  }

  async createCreditOrder (options = {}) {
    const response = await ws.put('v1', `${this.uri}/${this.guid}/credit`, options);
    return response;
  }

  // Custoemr
  async setCustomer (customer_guid = null, options = {}) {
    // Set customer guid with patch
    const mutations = [{
      action: 'set_field',
      field: 'customer_guid',
      value: customer_guid
    }];

    // Update
    await this.update(mutations, options);

    return this;
  }

  // Sales order lines
  async getSalesOrderLines (options = {}) {
    // Line options
    let lineOptions = Object.assign({}, options);
    if (lineOptions) {
      if (lineOptions.lines) {
        lineOptions = Object.assign(lineOptions, lineOptions.lines);
        delete lineOptions.lines;
      }
    }

    // Get lines
    const lines = await ws.get('v1', `${this.uri}/${this.guid}/lines`, lineOptions);
    this.lines = lines.map((row) => {
      return new SalesOrderLine().mergeResponse(row);
    });
    return this.lines;
  }

  async addSalesOrderLine (body = {}, options = {}) {
    // Copy body
    options = Object.assign({}, options);
    options.body = body;
    let response = await ws.put('v1', `${this.uri}/${this.guid}/lines`, merge({
      query: {
        persistent: true
      }
    }, options));
    response = new SalesOrderLine().mergeResponse(response);
    return response;
  }

  async removeSalesOrderLine (guid = null, options = {}) {
    // Set quantity to 0 to remove the sales order line
    options.body = [{
      action: 'set_field',
      field: 'quantity',
      value: 0
    }];

    const response = await ws.patch('v1', `${this.uri}/${this.guid}/lines/${guid}`, merge({
      query: {
        persistent: true
      }
    }, options));

    this.lines = response.map((row) => {
      return new SalesOrderLine().mergeResponse(row);
    });

    return this.lines;
  }

  async clearSalesOrderLines (options = {}) {
    if (this.lines && this.lines.length) {
      const removeLines = [];
      this.lines.forEach((row, idx) => {
        removeLines.push(this.removeSalesOrderLine(this.lines[idx].guid));
      });
      await Promise.all(removeLines);
      this.lines = [];
    }
    return this.lines;
  }

  // Transactions
  async getTransactions (options = {}) {
    // Transaction options
    let transactionOptions = Object.assign({}, options);
    if (transactionOptions) {
      if (transactionOptions.transactions) {
        transactionOptions = Object.assign(transactionOptions, transactionOptions.transactions);
        delete transactionOptions.litransactionsnes;
      }
    }

    // Get transactions
    let transactions = await ws.get('v1', `${this.uri}/${this.guid}/transactions`, transactionOptions);
    transactions = transactions.map((row) => {
      return new PaymentTransaction().mergeResponse(row);
    });
    this.transactions = transactions;
    return this.transactions;
  }

  async addTransaction (body = {}, options = {}) {
    const transaction = new PaymentTransaction().mergeResponse(body);
    await transaction.create(transaction.formatBody(), options);
    return transaction;
  }

  async removeTransaction (guid = null, options = {}) {
    const transaction = await new PaymentTransaction(guid).remove(options);
    return transaction;
  }

  async clearTransactions (options = {}) {
    if (this.transactions && this.transactions.length) {
      const removeTransactions = [];
      this.transactions.forEach((row, idx) => {
        removeTransactions.push(this.transactions[idx].remove());
      });
      await Promise.all(removeTransactions);
      this.transactions = [];
    }
    return this.transactions;
  }
};

export default SalesOrder;
