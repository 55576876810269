import Abstract from './abstract';

import { merge } from 'lodash';

class PaymentTransaction extends Abstract {
  constructor (guid = null) {
    super(guid);
    this.uri = `/payment_transactions`;

    let defaultObject = {
      type: null
    };

    merge(this, defaultObject);
  }

  // Getters & Setters
  get payment_method () {
    if (this.payment_method_type === 'cash') return `Contant`;
    else if (this.payment_method_type === 'card') return `PIN`;
    else if (this.payment_method_type === 'on_account') return `Op rekening`;
    else if (this.payment_method_type === 'giftcard') return `Cadeaubon`;
    else if (this.payment_method_type === 'session_credits') return `Sessiekrediet`;
    else if (this.payment_method_type === 'bundle') {
      if (this.bundle_name) return this.bundle_name;
      else return 'Pakket';
    }

    return this.payment_method_type;
  }
}

export default PaymentTransaction;
