import Vue from 'vue';

let hasListener = false;
const directives = [];
const keydownEvent = (element, binding, event) => {
  const lastAddedDirectiveElement = directives[directives.length - 1];
  if (lastAddedDirectiveElement !== element) {
    // The directive was added to another element after this element
    // So we ignore the execution of this elements function in favor of the deeper directive
    // Note: It is possible that when you have a "escape" on level A, and an "enter" on level 2
    // That the escape does nothing. As you've added a directive on level 2, which doesn't have an "escape" event
    return;
  }

  const eventFunction = binding.value[event.key.toLowerCase()];
  if (eventFunction !== undefined) {
    eventFunction(event, element);
  }
};

Vue.directive('keydown', {
  bind (element, binding) {
    // Push element as last item in the directives array
    // So we know that this was the latest added, and thus the only one we want to execute
    directives.push(element);

    if (hasListener === false) {
      window.addEventListener('keydown', keydownEvent.bind(this, element, binding));
      hasListener = true;
    }
  },

  unbind (element) {
    // Remove element from directive list, so another directive could be on the deepest level
    directives.splice(directives.indexOf(element), 1);
    if (directives.length === 0) {
      // If we don't have any directives registerd anymore
      // We remove the listener and set the hasListener to false
      // So the next directive added will add a listener again
      hasListener = false;
      window.removeEventListener('keydown', keydownEvent);
    }
  }
});
