<template>
  <b-modal
    ref="modal"

    title="Voorlooptijd wijzigen"

    centered
  >
    <template v-slot:default>
      <template v-if="isEquipmentCommunicating">
        <div class="row">
          <div class="col-12 col-md-4">
            <KButton
              variant="focus"
              class="btn-block"

              @click.native.prevent="extendPrerunTime(5 * 60)"
            >
              5 minuten
            </KButton>
          </div>

          <div class="col-12 col-md-4">
            <KButton
              variant="focus"
              class="btn-block"

              @click.native.prevent="extendPrerunTime(10 * 60)"
            >
              10 minuten
            </KButton>
          </div>

          <div class="col-12 col-md-4">
            <KButton
              variant="focus"
              class="btn-block"

              @click.native.prevent="extendPrerunTime(15 * 60)"
            >
              15 minuten
            </KButton>
          </div>
        </div>
      </template>

      <template v-else>
        <div class="row">
          <div class="col-12">
            <div class="blockui ml-auto mr-auto">
              <span>Wachten op verbinding met apparaat...</span>
              <span class="kt-spinner kt-spinner--v2 kt-spinner--focus" />
            </div>
          </div>
        </div>
      </template>
    </template>

    <template v-slot:modal-footer>
      <KButton
        variant="secondary"
        @click.native.prevent="hide"
      >
        Sluiten
      </KButton>
    </template>
  </b-modal>
</template>

<script>
// Classes
import Equipment from '@/libs/classes/equipment';

// Mixins
import Modal from '@/mixins/modal';

export default {
  mixins: [Modal],
  data () {
    return {
      options: null,

      equipment: null
    };
  },
  computed: {
    isEquipmentCommunicating () {
      // Check for equipment
      if (!this.equipment) return false;
      if (!this.equipment.master || this.equipment.master.isConnected === false) return false;
      if (!this.equipment.communication) return false;

      // Check equipment state
      if (this.equipment.state === 'unknown' || this.equipment.state === 'timeout') return false;

      return true;
    }
  },
  watch: {
    'equipment.state' (state) {
      if (state !== 'delay') {
        this.hide();
      }
    }
  },

  mounted () {
    // Listeners
    this.$eventhub.on('modals:equipment:edit-prerun-time:open', this.show);
    this.$eventhub.on('modals:equipment:edit-prerun-time:close', this.hide);
  },
  beforeDestroy () {
    // Remove listeners
    this.$eventhub.off('modals:equipment:edit-prerun-time:open', this.show);
    this.$eventhub.off('modals:equipment:edit-prerun-time:close', this.hide);
  },

  methods: {
    async extendPrerunTime (seconds = 600) {
      if (this.equipment) {
        await this.equipment.write('slaves:session:extend-prerun-time', {
          prerun_time: seconds
        });
      }
    },

    async show (options = {}) {
      // Clear options
      this.$set(this, 'options', null);

      if (typeof this.options === 'object') {
        this.$set(this, 'options', options);

        if (this.options.equipment && this.options.equipment instanceof Equipment) {
          this.$set(this, 'equipment', this.options.equipment);

          // Show modal
          Modal.methods.show.call(this);
        }
      }
    }
  }
};
</script>
