<template>
  <div>
    <KSubheader
      title="Groepen"
      :breadcrumbs="[{
        page: 'Producten',
        route: '/products'
      }, {
        page: 'Groepen',
        route: '/products/groups'
      }, {
        page: 'Groep toevoegen',
        route: null
      }]"
    >

    </KSubheader>

    <KContainer>
      <KPortlet>
        <KPortletHead>
          <template v-slot:default>Product toevoegen</template>
        </KPortletHead>
        <KPortletBody>

          <groupForm ref="groupForm"
            action="CREATE"

            :group="group"
          ></groupForm>

        </KPortletBody>
        <div class="kt-portlet__foot">
  				<div class="row align-items-center">
  					<div class="offset-6 col-6 offset-lg-10 col-lg-2 kt-align-right">
              <KButton variant="primary" class="btn-md btn-block btn-tall btn-wide btn-bold btn-upper" @click.native.prevent="submitForm">Toevoegen</KButton>
  					</div>
  				</div>
  			</div>
      </KPortlet>
    </KContainer>

  </div>
</template>

<script>
// Classes
import ProductGroup from '@/libs/classes/product_group';

// Components
import groupForm from './components/form';

export default {
  data() {
    return {
      group: new ProductGroup()
    }
  },
  components: {
    groupForm
  },
  methods: {
    async validate() {
      const validationArray = [];
      validationArray.push(this.$refs.groupForm.$v);

      // Execute validations
      if(validationArray.length > 0) {
        validationArray.forEach(v => v && v.$touch && v.$touch())
        if(validationArray.filter(v => v && v.$invalid).length !== 0) return false;
      }

      return true;
    },
    async submitForm() {
      if(await this.validate() === true) {
        await this.group.create(this.group.formatBody());
        this.$router.push(`/products/groups/view/${this.group.guid}`);
      }
    }
  }
}
</script>
