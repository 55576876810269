<template>
  <div v-if="tablePagination !== null && tablePagination.total !== 0"
    class="pagination-toolbar"
  >

    <select class="form-control pagination-select pagination-select-sm pagination-select--brand" :value="tablePagination.per_page" v-on:change="onChangeRowCount">
      <option value="10">10</option>
      <option value="20">20</option>
      <option value="50">50</option>
      <option value="100">100</option>
    </select>

    <div class="pagination-desc">
      <span v-if="tablePagination.total === 0">No data available</span>
      <span v-else>Showing {{ tablePagination.from }} - {{ tablePagination.to }} of {{ tablePagination.total }}</span>
    </div>
  </div>
</template>

<script>
import VuetablePaginationInfoMixin from 'vuetable-2/src/components/VuetablePaginationInfoMixin'
export default {
  mixins: [VuetablePaginationInfoMixin],
  methods: {
    onChangeRowCount(event) {
      let value = event.target.value;
      if(Number(value) !== NaN) value = Number(value);
      this.$emit("vuetable-paginationinfo:change-rowcount", value);
    }
  }
}
</script>
