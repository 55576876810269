// Classes
import PricelistPrice from './pricelist.price';
import SessionProduct from './session_product';

// Libraries
import { merge } from 'lodash';
import format from '@/libs/format';
import calculate from '@/libs/calculate';

class SessionPricelistPrice extends PricelistPrice {
  constructor (guid = null) {
    super(guid);

    this.type = 'session_product';

    let defaultObject = {
      product: new SessionProduct(),
      credit_price: null,
      day: null
    };

    merge(this, defaultObject);
  }

  // Methods
  formatBody (type = 'get', options = {}) {
    let body = super.formatBody(type, options);

    body.credit_price = this.credit_price;
    body.day = this.day;

    return body;
  }

  // Getters & Setters
  get VUID () {
    return `${super.VUID}.DAY_${this.day}`;
  }

  get price_formatted () {
    if (this.price === null) return null;
    return format.currencyToInput(this.price);
  }
  set price_formatted (value) {
    if (typeof value === 'string') value = Number(value);

    value = calculate.price(value, true);

    let afterHook = false;
    if ((this.credit_price === null || this.credit_price === this.price_incl_vat)) afterHook = true;

    this.price = value;

    if (afterHook === true) this.credit_price = this.price_incl_vat;
  }

  get credit_price_formatted () {
    if (this.type === 'product') return null;
    if (this.credit_price === null) return null;
    return format.currencyToInput(this.credit_price);
  }
  set credit_price_formatted (value) {
    if (this.type === 'product') return null;
    this.credit_price = calculate.price(value, true);
  }
}

export default SessionPricelistPrice;
