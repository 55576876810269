<template>
  <div>

    <div class="row form-group">
      <div class="col-12">
        <label>Naam <sup>Verplicht</sup></label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i><font-awesome-icon :icon="['fad', 'text']" /></i>
            </span>
          </div>
          <input type="text" class="form-control" :placeholder="(placeholder ? 'Zonnebanken' : undefined)" v-model.trim="$v.group.name.$model" :class="[{'is-invalid': $v.group.name.$error}]" :disabled="disabled">
        </div>
      </div>
    </div>

    <KSeparator large />

    <div class="row form-group">
      <div class="col-12 col-lg-6">
        <label>Voorlooptijd</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i><font-awesome-icon :icon="['fad', 'stopwatch']" /></i>
            </span>
          </div>
          <input type="number" class="form-control" v-model.number="pre_run_time_minutes" :class="[{'is-invalid': $v.group.pre_run_time.$error}]" :disabled="disabled">
          <div class="input-group-append">
            <span class="input-group-text">{{ (pre_run_time_minutes === 1 ? 'minuut' : 'minuten') }}</span>
          </div>
        </div>
      </div>

      <div class="col-12 col-lg-6">
        <label>Nalooptijd</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i><font-awesome-icon :icon="['fad', 'stopwatch']" /></i>
            </span>
          </div>
          <input type="number" class="form-control" v-model.number="post_run_time_minutes" :class="[{'is-invalid': $v.group.post_run_time.$error}]" :disabled="disabled">
          <div class="input-group-append">
            <span class="input-group-text">{{ (post_run_time_minutes === 1 ? 'minuut' : 'minuten') }}</span>
          </div>
        </div>
      </div>
    </div>

    <KSeparator large />

    <div class="row form-group">
      <div class="col-12 col-md-2 offset-md-1">
        <div class="row">
          <div class="col-3 col-md-12 text-md-center">
            <span class="kt-switch" :class="[{'kt-switch--success': group.quickstart}]">
  						<label>
  							<input type="checkbox" v-model="group.quickstart">
  							<span></span>
  						</label>
  					</span>
  				</div>
  				<label class="col-9 col-md-12 text-md-center col-form-label">Quickstart</label>
  			</div>
      </div>

      <div class="col-12 col-md-2 offset-md-2">
        <div class="row">
          <div class="col-3 col-md-12 text-md-center">
            <span class="kt-switch" :class="[{'kt-switch--success': group.door_contact}]">
  						<label>
  							<input type="checkbox" v-model="group.door_contact">
  							<span></span>
  						</label>
  					</span>
  				</div>
  				<label class="col-9 col-md-12 text-md-center col-form-label">Deurcontact</label>
  			</div>
      </div>

      <div class="col-12 col-md-2 offset-md-2">
        <div class="row">
          <div class="col-3 col-md-12 text-md-center">
  					<span class="kt-switch" :class="[{'kt-switch--success': group.clean_after}]">
  						<label>
  							<input type="checkbox" v-model="group.clean_after">
  							<span></span>
  						</label>
  					</span>
  				</div>
  				<label class="col-9 col-md-12 text-md-center col-form-label">Schoonmaken vereist</label>
  			</div>
      </div>
    </div>

    <KSeparator large />

    <div class="row form-group">
      <div class="col-12 col-md-2 offset-md-3">
        <div class="row">
          <div class="col-3 col-md-12 text-md-center">
            <span class="kt-switch" :class="[{'kt-switch--success': (group.package_only !== true && group.accept_credits)}]">
  						<label>
  							<input v-if="group.package_only !== true" type="checkbox" v-model="group.accept_credits">
                <input v-else type="checkbox" disabled>
  							<span></span>
  						</label>
  					</span>
  				</div>
  				<label class="col-9 col-md-12 text-md-center col-form-label">Kan gekocht worden met tegoed</label>
  			</div>
      </div>

      <div class="col-12 col-md-2 offset-md-2">
        <div class="row">
          <div class="col-3 col-md-12 text-md-center">
            <span class="kt-switch" :class="[{'kt-switch--success': group.package_only}]">
  						<label>
  							<input type="checkbox" v-model="group.package_only">
  							<span></span>
  						</label>
  					</span>
  				</div>
  				<label class="col-9 col-md-12 text-md-center col-form-label">Kan <strong>alleen</strong> gekocht worden als onderdeel van een pakket</label>
  			</div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, minValue } from 'vuelidate/lib/validators'

export default {
  props: {
    group: Object,

    disabled: Boolean,
    placeholder: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    pre_run_time_minutes: {
      get() {
        return (this.group.pre_run_time / 60);
      },
      set(value) {
        this.$v.group.pre_run_time.$touch();
        this.group.pre_run_time = (value * 60);
      }
    },
    post_run_time_minutes: {
      get() {
        return (this.group.post_run_time / 60);
      },
      set(value) {
        this.$v.group.post_run_time.$touch();
        this.group.post_run_time = (value * 60);
      }
    }
  },
  validations: {
    group: {
      name: {
        required
      },
      pre_run_time: {
        required,
        minValue: minValue(0)
      },
      post_run_time: {
        required,
        minValue: minValue(0)
      }
    }
  }
}
</script>
