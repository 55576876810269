export default class network {
  static get secureProtocol () {
    if (window.location.protocol === 'http:') {
      return false;
    }
    return true;
  }

  static get hostname () {
    let hostname = `${window.location.hostname}`;
    if (hostname.includes('dev.caesium.io')) {
      return 'dev.caesium.io';
    } else if (hostname.includes('caesium.io')) {
      return 'caesium.io';
    }
    return hostname;
  }

  static get api () {
    if (network.hostname.includes('caesium.io')) {
      return `api.${network.hostname}`;
    }
    return `${network.hostname}:8000`;
  }

  static get apiWS () {
    if (network.hostname.includes('caesium.io')) {
      return `ws.${network.hostname}`;
    }
    return `${network.hostname}:8001`;
  }
}
