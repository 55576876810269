import Vue from 'vue';
import moment from 'moment-timezone';
import numeral from 'numeral';
import 'numeral/locales/nl-nl';
import Vuelidate from 'vuelidate';
import KeenVue from 'keen-vue';

import App from './App';
import router from './router';

// Styling (Custom)
import '@/assets/sass/caesium.scss';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fad } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome';

import i18n from '@/libs/i18n';
import error from '@/libs/error';
import auth from '@/libs/auth';
import eventhub from '@/libs/eventhub';
import { CreateApiWS } from '@/libs/ws';
import stores from '@/libs/stores';
import format from '@/libs/format';
import calculate from '@/libs/calculate';

import Logger from '@/libs/logger';

import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';

Sentry.init({
  dsn: 'https://244efeba66344287987bd6547de857fb@sentry.vedicium.io/12',
  integrations: [new VueIntegration({Vue, attachProps: true, logErrors: true})],
});

const ws = new CreateApiWS();

Vue.prototype.$error = error;
Vue.prototype.$auth = auth;
Vue.prototype.$eventhub = eventhub;
Vue.prototype.$ws = ws;
Vue.prototype.$stores = stores;
Vue.prototype.$format = format;
Vue.prototype.$calculate = calculate;
Vue.prototype.$sentry = Sentry;

library.add(fas);
library.add(far);
library.add(fad);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('font-awesome-layers', FontAwesomeLayers);

// Register some global components
Vue.use(Vuelidate);
Vue.use(KeenVue);

moment.locale('nl');
numeral.locale('nl-nl');

router.beforeEach(async (to, from, next) => {
  let scopes = [];

  // First, get permissions of matched routes (because of parents / children)
  if (to.matched && to.matched.length > 0) {
    to.matched.forEach((route) => {
      if (route && route.meta && route.meta.scope) {
        if (typeof route.meta.scope === 'string') route.meta.scope = [route.meta.scope];
        scopes = scopes.concat(route.meta.scope);
      }
    });
  }

  // Add scope of current route
  if (to.meta && to.meta.scope) {
    if (typeof to.meta.scope === 'string') to.meta.scope = [to.meta.scope];
    scopes = scopes.concat(to.meta.scope);
  }

  // Filter duplicated
  scopes = scopes.filter((value, index, self) => self.indexOf(value) === index, []);

  if (scopes && scopes.length) {
    if (auth.hasPermission(scopes) === true) {
      return next();
    } else {
      if (auth.resource) {
        return next({
          name: 'PageNotFound',
          params: [to.path],
          replace: true
        });
      } else {
        // If user is not signed in, we don't redirect to the user page.
        // As it will show the signin page first
        return next();
      }
    }
  }
  next();
});

auth.authenticated = false;

// Set environment
Vue.prototype.$environment = 'development';
if (window.location.hostname.includes('dev.caesium.io')) {
  Vue.prototype.$environment = 'development';
} else if (window.location.hostname.includes('caesium.io')) {
  Vue.prototype.$environment = 'production';
}

const init = async () => {
  let workspaceFound = false;
  ws.setWorkspace('system');
  try {
    const workspace = await ws.get('v1', '/workspaces/host/' + window.location.hostname, { workspace_request: true });
    ws.setWorkspace(workspace.guid, workspace.name);
    workspaceFound = true;
  } catch (e) {
    // TODO: Redirect to Caesium Online homepage?
    console.error('Workspace not found!');
    workspaceFound = false;
    ws.setWorkspace(null);
  }

  if (workspaceFound !== true) {
    return;
  }

  /* eslint-disable no-new */
  new Vue({
    el: '#app',
    router: router,
    i18n: i18n,
    components: { App },
    template: '<App/>'
  });
};

Vue.config.productionTip = false;

init();
