<template>
  <div>

    <div class="row mb-3 border-bottom pb-3">
      <div class="col-12 col-lg-2">
        <KButton variant="focus" class="btn-block" :icon="['fad', 'sync']" v-on:click.native.prevent="syncTimesOfDay(days.selected)">Sync</KButton>
      </div>

      <div class="col-12 mt-3 col-lg-10 mt-md-0">
        <KButtonGroup class="d-none d-lg-inline-flex btn-block">
          <KButton :variant="dayButtonClass(1)" v-click="() => days.selected = 1">Maandag</KButton>
          <KButton :variant="dayButtonClass(2)" v-click="() => days.selected = 2">Dinsdag</KButton>
          <KButton :variant="dayButtonClass(3)" v-click="() => days.selected = 3">Woensdag</KButton>
          <KButton :variant="dayButtonClass(4)" v-click="() => days.selected = 4">Donderdag</KButton>
          <KButton :variant="dayButtonClass(5)" v-click="() => days.selected = 5">Vrijdag</KButton>
          <KButton :variant="dayButtonClass(6)" v-click="() => days.selected = 6">Zaterdag</KButton>
          <KButton :variant="dayButtonClass(0)" v-click="() => days.selected = 0">Zondag</KButton>
        </KButtonGroup>

        <KButtonGroup class="d-lg-none btn-block">
          <KButton :variant="dayButtonClass(1)" v-click="() => days.selected = 1">Ma</KButton>
          <KButton :variant="dayButtonClass(2)" v-click="() => days.selected = 2">Di</KButton>
          <KButton :variant="dayButtonClass(3)" v-click="() => days.selected = 3">Wo</KButton>
          <KButton :variant="dayButtonClass(4)" v-click="() => days.selected = 4">Do</KButton>
          <KButton :variant="dayButtonClass(5)" v-click="() => days.selected = 5">Vr</KButton>
          <KButton :variant="dayButtonClass(6)" v-click="() => days.selected = 6">Za</KButton>
          <KButton :variant="dayButtonClass(0)" v-click="() => days.selected = 0">Zo</KButton>
        </KButtonGroup>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-lg-8 offset-lg-2">
        <div class="row">
          <div class="col-12" :class="[{'border-bottom pb-3': dayButtonAction(days.selected) === 'PERIOD'}]">
            <KButtonGroup class="btn-block">
              <KButton :variant="(dayButtonAction(days.selected) === 'DAY' ? 'primary' : 'outline-primary')" v-on:click.prevent.native="setDay(days.selected, 'DAY')">Gehele dag</KButton>
              <KButton :variant="(dayButtonAction(days.selected) === 'PERIOD' ? 'primary' : 'outline-primary')" v-on:click.prevent.native="setDay(days.selected, 'PERIOD')">Periodes</KButton>
              <KButton :variant="(dayButtonAction(days.selected) === 'NONE' ? 'primary' : 'outline-primary')" v-on:click.prevent.native="setDay(days.selected, 'NONE')">Niet actief</KButton>
            </KButtonGroup>
          </div>
        </div>

        <!-- When period is selected -->
        <div v-if="dayButtonAction(days.selected) === 'PERIOD'" class="row">
          <!-- Show periods -->
          <div class="col-12 col-lg-8 offset-lg-2">
            <!-- Period row -->
            <div v-for="(time, idx) in availableTimesOfDay(days.selected)" class="row mt-3 border-bottom">
              <div class="col">
                <div class="row form-group">
                  <label class="col-12">Vanaf</label>
                  <div class="col">
                    <select class="form-control font-weight-bold" :value="moment().startOf('day').seconds(time.start).format('H')" v-on:change.prevent="changePeriod(time, 'start', 'h', $event.target.value)">
                      <option v-for="hour in 24" :value="(hour - 1)">{{ ((hour - 1) < 10 ? `0${(hour - 1)}` : (hour - 1)) }}</option>
                    </select>
                  </div>
                  <span style="font-size: 24px; font-weight: 700;">:</span>
                  <div class="col">
                    <select class="form-control font-weight-bold" :value="moment().startOf('day').seconds(time.start).format('m')" v-on:change.prevent="changePeriod(time, 'start', 'm', $event.target.value)">
                      <option v-for="minute in 60" :value="(minute - 1)">{{ ((minute - 1) < 10 ? `0${(minute - 1)}` : (minute - 1)) }}</option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="col">
                <div class="row form-group">
                  <label class="col-12">Tot</label>
                  <div class="col">
                    <select class="form-control font-weight-bold" :value="moment().startOf('day').seconds(time.end).format('H')" v-on:change.prevent="changePeriod(time, 'end', 'h', $event.target.value)">
                      <option v-for="hour in 24" :value="(hour - 1)">{{ ((hour - 1) < 10 ? `0${(hour - 1)}` : (hour - 1)) }}</option>
                    </select>
                  </div>
                  <span style="font-size: 24px; font-weight: 700;">:</span>
                  <div class="col">
                    <select class="form-control font-weight-bold" :value="moment().startOf('day').seconds(time.end).format('m')" v-on:change.prevent="changePeriod(time, 'end', 'm', $event.target.value)">
                      <option v-for="minute in 60" :value="(minute - 1)">{{ ((minute - 1) < 10 ? `0${(minute - 1)}` : (minute - 1)) }}</option>
                    </select>
                  </div>
                </div>
              </div>

              <div v-if="availableTimesOfDay(days.selected).length > 1" class="col-2 col-lg-1">
                <KButton variant="light" class="btn-block label-margin" :icon="'trash'" v-on:click.native.prevent="removePeriod(days.selected, time)" />
              </div>
            </div>

            <!-- Add button -->
            <div v-if="availableTimesOfDay(days.selected).length < 2" class="row mt-3">
              <div class="col-12 col-lg-6 offset-lg-3">
                <KButton variant="primary" :icon="['far', 'plus']" class="btn-block" v-on:click.native.prevent="addPeriod(days.selected)">Toevoegen</KButton>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
// Library
import moment from 'moment-timezone';
import { cloneDeep } from 'lodash';

// Classes
import BundleProductSession from '@/libs/classes/bundle_product.session';

export default {
  props: {
    session: BundleProductSession
  },
  data () {
    return {
      days: {
        selected: 1
      },

      moment
    }
  },

  methods: {
    syncTimesOfDay (day = 0) {
      let timesOfDay = Object.assign([], this.session.available_times.filter(d => d.day === day));
      if (timesOfDay.length > 0) {
        let availableTimes = [];
        for (let d = 0; d <= 6; d++) {
          timesOfDay.forEach((row) => {
            availableTimes.push({
              day: d,
              start: row.start,
              end: row.end
            });
          });
        }

        this.$set(this.session, 'available_times', availableTimes);
      } else {
        this.session.available_times = [];
      }
    },

    setDay (day, action = null) {
      if (day >= 0 && day <= 6) {

        let availableTimes = this.session.available_times;
        if (action === 'DAY') {
          // Remove day from available times
          availableTimes = availableTimes.filter(d => d.day !== day);

          // Add whole day to available times
          availableTimes.push({
            day: day,
            start: 0,
            end: 86400
          });

          // Set available times
          this.session.available_times = availableTimes;
        } else if (action === 'PERIOD') {
          // Remove day from available times
          availableTimes = availableTimes.filter(d => d.day !== day);

          // Add period of day to available times
          availableTimes.push({
            day: day,
            start: (8 * 60 * 60),
            end: (12 * 60 * 60)
          });

          this.session.available_times = availableTimes;
        } else if (action === 'NONE') {
          this.session.available_times = this.session.available_times.filter(d => d.day !== day);
        }
      }
    },

    dayButtonAction (day = 0) {
      // Check if day is found
      let availableTimesDay = this.session.available_times.filter(d => d.day === day);
      if (availableTimesDay.length === 0) return 'NONE';

      // Check if all day
      let findWholeDay = availableTimesDay.find(d => d.day === day && d.start === 0 && d.end === 86400);
      if (typeof findWholeDay !== typeof undefined) return 'DAY';

      // Check if period of day
      let periodDay = availableTimesDay.filter(d => d.day === day && typeof d.start === 'number' && typeof d.end === 'number');
      if (periodDay.length > 0) return 'PERIOD';

      return null;
    },

    dayButtonClass (day = 0) {
      // Check if day selected
      if (this.days.selected === day) return 'primary';

      // Check if day found in available_times
      if (this.session.available_times.filter(d => d.day === day).length !== 0) return 'label-primary';

      return 'outline-primary';
    },

    availableTimesOfDay (day = 0) {
      return this.session.available_times.filter(d => d.day === day);;
    },

    changePeriod (time, target, unity, value) {
      // Get current index of time period
      let timeIndex = this.session.available_times.findIndex(d => d.day === time.day && d.start === time.start && d.end === time.end);
      if (timeIndex === -1) {
        throw new Error('Period not found');
      }

      // Check target
      if (target !== 'start' && target !== 'end') {
        throw new Error(`Target '${target}' does not exist`);
      }

      // Check unity
      if (unity !== 'h' && unity !== 'm') {
        throw new Error(`Unity '${unity}' does not exist`);
      }

      // Check value
      value = Number(value);
      if (isNaN(value)) {
        throw new Error(`Value is not a number`);
      }

      // Check if unity is correct
      if (unity === 'h' && (value < 0 || value > 23)) throw new Error('Hour value can\'t be lower than 0 or higher than 23');
      else if (unity === 'm' && (value < 0 || value > 59)) throw new Error('Minute value can\'t be lower than 0 or higher than 59');

      // Create variables to calculate seconds
      const startOfDay = moment().startOf('day');
      let period = cloneDeep(startOfDay);

      // Set period based on target
      period = period.seconds(this.session.available_times[timeIndex][(target)]);

      // Set unity of period based on unity
      if (unity === 'h') period.set({hour: value});
      else if (unity === 'm') period.set({minute: value});

      // Get period difference in seconds and set the right variable
      let newValue = period.diff(startOfDay, 'seconds');
      this.session.available_times[timeIndex][(target)] = newValue;

      // Change end time when start is higher
      if (target === 'start' && this.session.available_times[timeIndex].start > this.session.available_times[timeIndex].end) this.session.available_times[timeIndex].end = this.session.available_times[timeIndex].start;
      if (target === 'end' && this.session.available_times[timeIndex].end < this.session.available_times[timeIndex].start) this.session.available_times[timeIndex].start = this.session.available_times[timeIndex].end;
    },

    addPeriod (day = 0) {
      if (this.dayButtonAction(day) === 'PERIOD') {
        let periods = this.availableTimesOfDay(day);
        if (periods.length < 2) {
          const lastPeriod = periods[(periods.length - 1)];
          const newPeriod = {
            day: day,
            start: lastPeriod.end,
            end: lastPeriod.end
          };

          this.session.available_times.push(newPeriod);
        }
      }
    },
    removePeriod (day = 0, time) {
      if (this.dayButtonAction(day) === 'PERIOD') {
        let periods = this.availableTimesOfDay(day);
        if (periods.length > 1) {
          let timeIndex = this.session.available_times.findIndex(d => d.day === time.day && d.start === time.start && d.end === time.end);
          this.session.available_times.splice(timeIndex, 1);
        }
      }
    }
  }
}
</script>
