import Abstract from './abstract';

class CreditReasons extends Abstract {
  constructor (guid = null) {
    super(guid);
    this.uri = '/credit_reasons';
  }
};

export default CreditReasons;
