import Vue from 'vue';

const listeners = {};
const directives = [];
const mouseEvent = (element, binding, event) => {
  // TODO: Add bubbling to top support
  // If you have two divs, and you click on the deepest div and the click is on the highest div
  // Then it won't click
  // As the clicked div doesn't have a binding

  if (!event.target[binding.name]) {
    return;
  }
  const { clickFunction, parameters } = event.target[binding.name];
  const paramsCopy = [].concat(parameters);

  if (!clickFunction) {
    return;
  }

  paramsCopy.push(event);

  if (clickFunction) {
    clickFunction.apply(null, paramsCopy);
  }
};

const bind = (eventName, element, binding) => {
  directives.push(element);

  let clickFunction = binding.value;
  let parameters = [];
  if (Array.isArray(binding.value) === true) {
    clickFunction = binding.value[0];
    parameters = [].concat(binding.value);
    parameters.shift();
  }

  if (clickFunction) {
    element[binding.name] = { clickFunction, parameters };
    element.key = Math.random();
  }

  if (listeners[eventName] !== true) {
    window.addEventListener(eventName, mouseEvent.bind(this, element, binding));
    listeners[eventName] = true;
  }
};

const unbind = (eventName, element) => {
  // Remove element from directive list, so another directive could be on the deepest level
  directives.splice(directives.indexOf(element), 1);
  if (directives.length === 0) {
    // If we don't have any directives registerd anymore
    // We remove the listener and set the listeners[eventName] to false
    // So the next directive added will add a listener again for this eventName
    listeners[eventName] = false;
    window.removeEventListener(eventName, mouseEvent);
  }
};

Vue.directive('click', {
  bind (element, binding) {
    bind('mouseup', element, binding);
  },

  update (element, binding) {
    bind('mouseup', element, binding);
  },

  unbind (element) {
    unbind('mouseup', element);
  }
});

Vue.directive('clickUp', {
  bind (element, binding) {
    bind('mouseup', element, binding);
  },

  update (element, binding) {
    bind('mouseup', element, binding);
  },

  unbind (element) {
    unbind('mouseup', element);
  }
});

Vue.directive('dblclick', {
  bind (element, binding) {
    bind('dblclick', element, binding);
  },

  update (element, binding) {
    bind('dblclick', element, binding);
  },

  unbind (element) {
    unbind('dblclick', element);
  }
});
