<template>
  <div>
    <!-- First row -->
    <div class="row form-group mb-0">
      <div class="col-12 mt-3 col-md-12 mt-md-0 offset-lg-3 col-lg-6">
        <label>Sessie geblokkeerd na</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i><font-awesome-icon :icon="['fad', 'clock']" /></i>
            </span>
          </div>
          <select
            v-model.number="user.settings.session_lock_timeout"
            :disabled="isDisabled"

            class="form-control"
          >
            <option :value="0">Nooit</option>
            <option :value="(1 * 60)">1 minuut</option>
            <option :value="(2 * 60)">2 minuten</option>
            <option :value="(5 * 60)">5 minuten</option>
            <option :value="(10 * 60)">10 minuten</option>
            <option :value="(15 * 60)">15 minuten</option>
            <option :value="(30 * 60)">30 minuten</option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Classes
import User from '@/libs/classes/user';

export default {
  props: {
    user: {
      type: User,
      default: null
    },

    action: {
      type: String,
      default: 'VIEW'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isDisabled () {
      return (this.disabled === true || this.action === 'VIEW');
    }
  }
};
</script>
