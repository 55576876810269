<template>
  <div>
    <!-- First row -->
    <div class="row form-group border-bottom pb-3">
      <div class="col-12 mt-3 col-md-6 mt-md-0">
        <label>Gebruikersnaam <sup>Verplicht</sup></label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i><font-awesome-icon :icon="['fad', 'user']" /></i>
            </span>
          </div>
          <input
            v-model.trim="$v.user.username.$model"
            :class="['form-control', {'is-invalid': $v.user.username.$error}]"
            :disabled="(isDisabled || personal === true)"
            type="text"
            class="form-control"
          >
        </div>
      </div>

      <div class="col-12 mt-3 col-md-6 mt-md-0">
        <label>E-mailadres <sup>Verplicht</sup></label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i><font-awesome-icon :icon="['fad', 'at']" /></i>
            </span>
          </div>
          <input
            v-model.trim="$v.user.email.$model"
            :class="['form-control', {'is-invalid': $v.user.email.$error}]"
            :disabled="isDisabled"
            type="text"
            class="form-control"
          >
        </div>
      </div>
    </div>

    <!-- Second row -->
    <div class="row form-group border-bottom pb-3">
      <div class="col-12 col-md-4">
        <label>Voornaam <sup>Verplicht</sup></label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i><font-awesome-icon :icon="['fad', 'text']" /></i>
            </span>
          </div>
          <input
            v-model.trim="$v.user.firstname.$model"
            :class="['form-control', {'is-invalid': $v.user.firstname.$error}]"
            :disabled="isDisabled"
            type="text"
            class="form-control"
          >
        </div>
      </div>

      <div class="col-12 mt-3 col-md-4 mt-md-0">
        <label>Achternaam <sup>Verplicht</sup></label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i><font-awesome-icon :icon="['fad', 'text']" /></i>
            </span>
          </div>
          <input
            v-model.trim="$v.user.lastname.$model"
            :class="['form-control', {'is-invalid': $v.user.lastname.$error}]"
            :disabled="isDisabled"
            type="text"
            class="form-control"
          >
        </div>
      </div>

      <div class="col-12 mt-3 col-md-4 mt-md-0">
        <label>Geslacht <sup>Verplicht</sup></label>
        <div class="input-group">
          <KButtonGroup class="btn-block">
            <KButton
              v-click="() => user.gender = 'MAN'"
              :variant="(user.gender === 'MAN' ? 'brand' : 'outline-secondary')"
              :disabled="isDisabled"
            >
              Man
            </KButton>

            <KButton
              v-click="() => user.gender = 'WOMAN'"
              :variant="(user.gender === 'WOMAN' ? 'brand' : 'outline-secondary')"
              :disabled="isDisabled"
            >
              Vrouw
            </KButton>

            <KButton
              v-click="() => user.gender = null"
              :variant="(user.gender === null ? 'brand' : 'outline-secondary')"
              :disabled="isDisabled"
            >
              Mens
            </KButton>
          </KButtonGroup>
        </div>
      </div>
    </div>

    <!-- Third row -->
    <div class="row form-group border-bottom pb-3">
      <div class="col-12 col-md-4">
        <label>Geboortedatum <sup>Verplicht</sup></label>
        <birthday-input-group
          v-model="$v.user.date_of_birth.$model"
          :is-invalid="$v.user.date_of_birth.$error"
          :disabled="disabled"
        />
      </div>

      <div class="col-12 mt-3 col-md-4 mt-md-0">
        <label>Telefoonnummer</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i><font-awesome-icon :icon="['fad', 'phone']" /></i>
            </span>
          </div>
          <input
            v-model.trim="$v.user.phone.$model"
            :class="['form-control', {'is-invalid': $v.user.phone.$error}]"
            :disabled="isDisabled"
            type="text"
            pattern="\d{4}-\d{2}-\d{2}"
          >
        </div>
      </div>

      <div class="col-12 mt-3 col-md-4 mt-md-0">
        <label>Werknemer ID</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i><font-awesome-icon :icon="['fad', 'id-card']" /></i>
            </span>
          </div>
          <input
            v-model.trim="$v.user.employee_id.$model"
            :class="['form-control', {'is-invalid': $v.user.employee_id.$error}]"
            :disabled="isDisabled"
            type="text"
            pattern="\d{4}-\d{2}-\d{2}"
          >
        </div>
      </div>
    </div>

    <!-- Fourth row -->
    <div class="row form-group border-bottom pb-3">
      <div class="col-12 col-md-4">
        <label>Land</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i><font-awesome-icon :icon="['fad', 'globe']" /></i>
            </span>
          </div>
          <select
            v-model="user.address.country"
            :disabled="disabled"
            class="form-control"
          >
            <option value="NL">Nederland</option>
            <option value="BE">België</option>
          </select>
        </div>
      </div>

      <div class="col-12 mt-3 col-md-4 mt-md-0">
        <label>Postcode</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i><font-awesome-icon :icon="['fad', 'home']" /></i>
            </span>
          </div>
          <input
            v-model="user.address.postalcode"
            :disabled="isDisabled"
            type="text"
            class="form-control"
          >
        </div>
      </div>

      <div class="col-12 mt-3 col-md-4 mt-md-0">
        <label>Huisnummer</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i><font-awesome-icon :icon="['fad', 'home']" /></i>
            </span>
          </div>
          <input
            v-model="user.address.housenumber"
            :disabled="isDisabled"
            type="text"
            class="form-control"
          >
        </div>
      </div>
    </div>

    <div class="row form-group mb-0">
      <div class="col-12 mt-3 col-md-6 mt-md-0">
        <label>Adres</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i><font-awesome-icon :icon="['fad', 'home']" /></i>
            </span>
          </div>
          <input
            v-model="user.address.line1"
            :disabled="isDisabled"
            type="text"
            class="form-control"
          >
        </div>
      </div>

      <div class="col-12 mt-3 col-md-6 mt-md-0">
        <label>Stad</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i><font-awesome-icon :icon="['fad', 'home']" /></i>
            </span>
          </div>
          <input
            v-model="user.address.city"
            :disabled="isDisabled"
            type="text"
            class="form-control"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Classes
import User from '@/libs/classes/user';

// Components
import birthdayInputGroup from '@/components/customers/components/birthdayInputGroup';

// Validators
import { required, requiredIf, email } from 'vuelidate/lib/validators';
import { date } from '@/libs/validators/index';

export default {
  components: {
    birthdayInputGroup
  },
  props: {
    user: {
      type: User,
      default: null
    },

    action: {
      type: String,
      default: 'VIEW'
    },
    disabled: {
      type: Boolean,
      default: false
    },

    personal: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isDisabled () {
      return (this.disabled === true || this.action === 'VIEW');
    }
  },

  validations: {
    user: {
      username: {
        required: requiredIf(function (value) {
          return this.personal === false;
        })
      },
      email: {
        required,
        email
      },

      firstname: {
        required
      },
      lastname: {
        required
      },

      date_of_birth: {
        required,
        date
      },
      phone: {},
      employee_id: {}
    }
  }
};
</script>
