<template>
  <div>

    <div class="row form-group">
      <div class="col-12 offset-sm-0 col-xl-6 offset-xl-3">

        <div :class="['row form-group mb-3', {'border-bottom pb-3': session.session_time.flexible !== null}]">
          <div class="col-12">
            <KButtonGroup class="btn-block">
              <KButton
                :variant="($v.session.session_time.flexible.$model === false ? 'primary' : 'outline-primary')"
                :class="[{'btn-outline-danger': $v.session.session_time.flexible.$error}]"
                @click.native.prevent="$v.session.session_time.flexible.$model = false"
              >
                Vaste tijd
              </KButton>

              <KButton
                :variant="($v.session.session_time.flexible.$model === true ? 'primary' : 'outline-primary')"
                :class="[{'btn-outline-danger': $v.session.session_time.flexible.$error}]"
                @click.native.prevent="$v.session.session_time.flexible.$model = true"
              >
                Variabele tijd
              </KButton>
            </KButtonGroup>
          </div>
        </div>

        <div
          v-if="session.session_time.flexible !== null"
          class="row form-group"
        >
          <div class="col-12">
            <label>{{ (session.session_time.flexible === true ? 'Standaard tijd' : 'Sessietijd') }}</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i><font-awesome-icon :icon="['fad', 'stopwatch']" /></i>
                </span>
              </div>
              <input
                v-model.lazy.number="$v.session.default_time_minutes.$model"
                :class="['form-control', {'is-invalid': $v.session.default_time_minutes.$error}]"

                type="number"
              >
              <div class="input-group-append">
                <span class="input-group-text">minuten</span>
              </div>
            </div>
          </div>
        </div>

        <!-- Only show when time is flexible -->
        <div
          v-if="session.session_time.flexible === true"
          class="row form-group mb-0"
        >
          <div class="col-12 col-lg-6">
            <label>Minimale tijd</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i><font-awesome-icon :icon="['fad', 'stopwatch']" /></i>
                </span>
              </div>
              <input
                v-model.lazy.number="$v.session.from_time_minutes.$model"
                :class="['form-control', {'is-invalid': $v.session.from_time_minutes.$error}]"

                type="number"
              >
              <div class="input-group-append">
                <span class="input-group-text">minuten</span>
              </div>
            </div>
          </div>

          <div class="col-12 mt-3 col-lg-6 mt-lg-0">
            <label>Maximale tijd</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i><font-awesome-icon :icon="['fad', 'stopwatch']" /></i>
                </span>
              </div>
              <input
                v-model.lazy.number="$v.session.to_time_minutes.$model"
                :class="['form-control', {'is-invalid': $v.session.to_time_minutes.$error}]"

                type="number"
              >
              <div class="input-group-append">
                <span class="input-group-text">minuten</span>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
// Classes
import BundleProductSession from '@/libs/classes/bundle_product.session';

// Validations
import { required, requiredIf, minValue, maxValue } from 'vuelidate/lib/validators';

export default {
  props: {
    session: {
      type: BundleProductSession,
      default: null
    }
  },
  watch: {
    'session.session_time.flexible' (value, oldVal) {
      // Reset validation
      if (oldVal === null) {
        this.$v.session.default_time_minutes.$reset();
      }

      this.$v.session.from_time_minutes.$reset();
      this.$v.session.to_time_minutes.$reset();
    },
    'session.session_time.default' (value) {
      this.$nextTick(() => {
        if (this.$v.session.default_time_minutes.$error === false) {
          // Set from time, if necessary
          if (this.session.session_time.from === null || this.session.session_time.from === 0) {
            // Substract 5 minutes from value and check if less than 0
            // If less than 0, just set from value to the default value
            let fromValue = value - (5 * 60);
            if (fromValue <= 0) fromValue = value;
            this.session.session_time.from = fromValue;
          } else if (value < this.session.session_time.from) {
            // When value was already filled, just set to default value
            this.session.session_time.from = value;
          }

          // Set to time, if necessary
          if (this.session.session_time.to === null || this.session.session_time.to === 0) {
            // Substract 5 minutes from value and check if less than 0
            // If more or same as 60 minutes, just set from value to the default value
            let toValue = value + (5 * 60);
            if (toValue > (60 * 60)) toValue = value;
            this.session.session_time.to = toValue;
          } else if (value > this.session.session_time.to) {
            // When value was already filled, just set to default value
            this.session.session_time.to = value;
          }
        }
      });
    }
  },
  validations: {
    session: {
      session_time: {
        flexible: {
          required,
          value: function (value) {
            return (typeof value === 'boolean');
          }
        }
      },
      default_time_minutes: {
        required,
        minValue: minValue(1),
        maxValue: maxValue(60)
      },
      from_time_minutes: {
        required: requiredIf(function (value) {
          return (this.session.session_time.flexible === true);
        }),
        minValue: function (value) {
          // Is required
          if (this.session.session_time.flexible === false) return true;

          // Check for value
          if (value === null || value <= 0) return false;

          // Check if value is lower than or same as the default time
          return (value <= this.session.default_time_minutes);
        }
      },
      to_time_minutes: {
        required: requiredIf(function (value) {
          return (this.session.session_time.flexible === true);
        }),
        minValue: function (value) {
          // Is required
          if (this.session.session_time.flexible === false) return true;

          // Check for value
          if (value === null || value > 60) return false;

          // Check if value is higher than the default time
          return (value >= this.session.default_time_minutes);
        }
      }
    }
  }
};
</script>
