<template>
  <div>
    <KSubheader
      :breadcrumbs="[{
        page: 'Kassabeheer',
        route: '/cash_registers/overview'
      }, {
        page: 'Transacties'
      }, {
        page: 'Overzicht'
      }]"

      title="Transacties"
    />

    <KContainer>

      <KPortlet>
        <KPortletHead>
          <template v-slot:default>Overzicht</template>
        </KPortletHead>
        <KPortletBody>

          <div class="row">
            <div class="col-12">
              <div class="kt-input-icon kt-input-icon--left">
                <input
                  id="generalSearch"
                  type="text"
                  class="form-control"
                  placeholder="Search..."
                  @keyup.enter="$refs['datatable'].search"
                  @keyup="searchDebounced"
                >

                <span class="kt-input-icon__icon kt-input-icon__icon--left">
                  <span>
                    <i>
                      <font-awesome-icon :icon="['far', 'search']" />
                    </i>
                  </span>
                </span>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-12 datatable datatable-wrapper">
              <datatable
                ref="datatable"
                :url="datatable.url"
                :fields="datatable.fields"
                :filters="datatable.filters"
                :search-fields="datatable.searchFields"
                :on-pagination-data="onPaginationData"
                :transform="transform"
              >
                <!-- Customer -->
                <div
                  slot="customer"
                  slot-scope="props"
                >
                  <template v-if="props.rowData.customer">
                    <router-link
                      v-if="$auth.hasPermission('get_customers')"
                      :to="`/customers/view/${props.rowData.customer.guid}`"
                      tag="a"
                    >
                      {{ props.rowData.customer.name }}
                    </router-link>

                    <template v-else>{{ props.rowData.customer.name }}</template>
                  </template>

                  <template v-else>Geen klant</template>
                </div>

                <!-- Actions -->
                <div
                  slot="actions"
                  slot-scope="props"
                  class="datatable-actions"
                >
                  <router-link
                    v-if="$auth.hasPermission('get_salesorders')"
                    :to="`/cash_registers/salesorders/view/${props.rowData._meta.guid}`"
                  >
                    <KButton
                      :icon="['fad', 'eye']"
                      tag="a"
                      variant="label-brand"
                    />
                  </router-link>
                </div>
              </datatable>

              <div class="row pagination">
                <div class="col-xs-12 col-md-6">
                  <datatable-pagination
                    ref="pagination"
                    @vuetable-pagination:change-page="onChangePage"
                  />
                </div>

                <div class="col-xs-12 col-md-6">
                  <datatable-pagination-info
                    ref="pagination.info"
                    @vuetable-paginationinfo:change-rowcount="onChangeRowCount"
                  />
                </div>
              </div>

            </div>
          </div>

        </KPortletBody>
      </KPortlet>
    </KContainer>
  </div>
</template>

<script>
// Libraries
import moment from 'moment-timezone';

// Classes
import SalesOrder from '@/libs/classes/salesorder';

// Mixins
import datatable from '@/mixins/datatable';

export default {
  mixins: [datatable],
  data () {
    return {
      datatable: {
        url: '/salesorders',

        fields: [{
          title: 'Klant',
          name: '__slot:customer',
          sortField: 'customer.firstname',
          dataClass: 'w-2/8'
        }, {
          title: 'Bedrag',
          name: 'total_incl_vat',
          sortField: 'total_incl_vat',
          dataClass: 'w-1/8',
          callback: (value) => `&euro; ${this.$format.currency(value)}`
        }, {
          title: 'Datum',
          name: 'finished_timestamp',
          sortField: 'finished_timestamp',
          dataClass: 'w-2/8',
          callback: (value) => moment(value).format('LLL')
        }, {
          title: 'Gekochte producten',
          name: 'summary_lines',
          dataClass: 'w-2/8'
        }, {
          title: 'Betaalmethodes',
          name: 'summary_payment_methods',
          dataClass: 'w-2/8'
        }, {
          title: '',
          name: '__slot:actions',
          titleClass: 'text-right',
          dataClass: 'text-right'
        }],

        searchFields: ['customer.firstname', 'customer.lastname', 'total_incl_vat'],
        filters: {
          paid: true
        }
      }
    };
  },

  methods: {
    transform (data) {
      if (data.data && Array.isArray(data.data)) {
        data.data.forEach((row, idx) => {
          data.data[idx] = new SalesOrder().mergeResponse(data.data[idx]);
        });
      }

      return data;
    }
  }
};
</script>
