var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isMounted === false)?_c('KContainer',[_c('div',{staticClass:"row mt-3"},[_c('div',{staticClass:"col-12 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4"},[_c('div',{staticClass:"loading-container"},[_c('div',{staticClass:"loading-block"},[_c('div',{staticClass:"blockui ml-auto mr-auto w-100"},[_c('span',[_vm._v("Gegevens ophalen...")]),_vm._v(" "),_c('span',{staticClass:"kt-spinner kt-spinner--v2 kt-spinner--primary"})])])])])])]):(_vm.creditProduct !== null)?_c('div',[_c('KSubheader',{attrs:{"breadcrumbs":[{
      page: 'Producten',
      route: '/products'
    }, {
      page: 'Sessiekrediet',
      route: '/products/credit'
    }, {
      page: _vm.creditProduct.name,
      route: null
    }],"title":"Sessiekrediet"}}),_vm._v(" "),_c('KContainer',[_c('KPortlet',[_c('KPortletHead',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_vm._v("Krediet wijzigen")]},proxy:true}])}),_vm._v(" "),_c('KPortletBody',[_c('informationForm',{ref:"informationForm",attrs:{"credit_product":_vm.creditProductClone,"action":(_vm.$auth.hasPermission([['update_credit_products']]) ? 'EDIT' : 'VIEW'),"disabled":_vm.isLoading}})],1),_vm._v(" "),(_vm.$auth.hasPermission([['update_credit_products']]))?_c('div',{staticClass:"kt-portlet__foot"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"offset-6 col-6 offset-lg-10 col-lg-2 kt-align-right"},[_c('KButton',{staticClass:"btn-md btn-block btn-tall btn-wide btn-bold btn-upper",attrs:{"disabled":_vm.isLoading,"variant":"warning"},nativeOn:{"click":function($event){$event.preventDefault();return _vm.submitForm($event)}}},[_vm._v("\n              Wijzigen\n            ")])],1)])]):_vm._e()],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }