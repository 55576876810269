<template>
  <div>

    <KSubheader
      :breadcrumbs="[{
        page: 'Kassa',
        route: '/sales'
      }, {
        page: 'Kassa\'s',
        route: null
      }, {
        page: 'Overzicht',
        route: null
      }]"

      title="Kassa's"
    />

    <KContainer v-if="isMounted">

      <div class="row">
        <template v-if="cashRegisters.length === 0">
          <div class="col-12">
            <KAlert
              :icon="['fad', 'exclamation-triangle']"
              variant="outline-caution"
            >
              Geen kassa's gevonden.
              <router-link
                v-if="$auth.hasPermission([['create_cash_registers']])"
                to="/cash_registers/create"
              >
                Maak er één aan.
              </router-link>
            </KAlert>
          </div>
        </template>

        <div
          v-for="(cashRegister) in cashRegisters"
          :key="`cash_register.${cashRegister.guid}`"
          class="cash_register cursor-pointer col-12 col-md-4 col-xl-3"
        >
          <KPortlet class="kt-portlet--height-fluid">
            <div @click.prevent="selectCashRegister(cashRegister)">
              <KPortletBody class="pb-0">

                <div class="kt-widget kt-widget--general-1">
                  <div class="kt-media">
                    <i>
                      <font-awesome-icon
                        :icon="['fad', 'cash-register']"
                        :class="[{'kt-font-brand': (shop.cash_register && shop.cash_register.guid === cashRegister.guid)}]"
                        style="font-size:3rem;"
                      />
                    </i>
                  </div>

                  <div class="kt-widget__wrapper">
                    <div class="kt-widget__label">
                      <span class="kt-widget__title">
                        {{ cashRegister.name }}
                        <sup v-if="shop.cash_register && shop.cash_register.guid === cashRegister.guid">Huidige</sup>
                      </span>
                    </div>
                  </div>
                  <div class="kt-widget__toolbar">
                    <KButton
                      v-if="cashRegister.latest_opening.open === true"
                      tag="span"
                      variant="label-brand"
                      class="btn-upper"
                    >
                      Open
                    </KButton>
                    <KButton
                      v-else
                      tag="span"
                      variant="label-danger"
                      class="btn-upper"
                    >
                      Gesloten
                    </KButton>
                  </div>
                </div>
              </KPortletBody>

              <KSeparator class="kt-separator--space-sm" />

              <KPortletBody class="pt-0 pb-0">
                <div class="row">
                  <div class="col-12">
                    <template v-if="cashRegister.latest_opening.open">
                      <div class="row">
                        <div
                          class="col-6"
                          style="font-weight: 500;"
                        >
                          Geopend op
                        </div>
                        <div class="col-6 text-right">{{ moment(cashRegister.latest_opening.open_timestamp).format('LLL') }}</div>
                      </div>

                      <div class="row">
                        <div
                          class="col-6"
                          style="font-weight: 500;"
                        >
                          Geopend door
                        </div>
                        <div class="col-6 text-right">{{ cashRegister.latest_opening.opened_by_user.name }}</div>
                      </div>
                    </template>

                    <template v-else>
                      <div class="row">
                        <div
                          class="col-6"
                          style="font-weight: 500;"
                        >
                          Gesloten op
                        </div>
                        <div class="col-6 text-right">{{ (cashRegister.latest_opening.close_timestamp !== null ? moment(cashRegister.latest_opening.close_timestamp).format('LLL') : '') }}</div>
                      </div>

                      <div class="row">
                        <div
                          class="col-6"
                          style="font-weight: 500;"
                        >
                          Gesloten door
                        </div>
                        <div class="col-6 text-right">{{ (typeof cashRegister.latest_opening.closed_by_user.name !== typeof undefined ? cashRegister.latest_opening.closed_by_user.name : '') }}</div>
                      </div>
                    </template>
                  </div>
                </div>

              </KPortletBody>
            </div>

            <KSeparator class="kt-separator--space-sm" />

            <KPortletBody class="pt-0 pb-3">
              <div class="row">
                <div class="col-8 offset-2 col-lg-4 offset-lg-4">
                  <template v-if="cashRegister.latest_opening.open">
                    <KButton
                      variant="danger"
                      class="btn-block"
                      @click.native.prevent="$router.push(`/sales/cash_registers/view/${cashRegister.guid}/actions/close`)"
                    >
                      Sluiten
                    </KButton>
                  </template>

                  <template v-else>
                    <KButton
                      variant="primary"
                      class="btn-block"
                      @click.native.prevent="$router.push(`/sales/cash_registers/view/${cashRegister.guid}/actions/open`)"
                    >
                      Openen
                    </KButton>
                  </template>
                </div>
              </div>
            </KPortletBody>
          </KPortlet>
        </div>

      </div>

    </KContainer>

  </div>
</template>

<script>
// Libraries
import { merge } from 'lodash';

import shop from '@/libs/shop';
import moment from 'moment-timezone';

// Classes
import CashRegister from '@/libs/classes/cash_register';

export default {
  data () {
    return {
      isMounted: false,

      cashRegisters: [],

      shop,
      moment
    };
  },

  async mounted () {
    // Get cash registers
    const options = (typeof this.$route.params.options === 'object' ? this.$route.params.options : {});
    try {
      await this.getCashRegisters(options);
    } catch (e) {
      console.error('Error fetching registers', e);
      return;
    }

    this.$nextTick(() => {
      this.isMounted = true;
    });
  },

  methods: {
    selectCashRegister (cashRegister) {
      if (cashRegister instanceof CashRegister) {
        if (!this.shop.cash_register || this.shop.cash_register.guid !== cashRegister.guid) {
          shop.removeSession();
          shop.setCashRegister(cashRegister.guid);
          shop.cash_register = cashRegister;
        }

        // Check if redirect is present
        if (this.$route.params) {
          if (this.$route.params.redirect) {
            const redirect = {
              params: {
                cash_register: cashRegister.guid
              }
            };

            if (typeof this.$route.params.redirect === 'string') {
              redirect.path = this.$route.params.redirect;
            }

            if (typeof this.$route.params.redirect === 'object') {
              merge(redirect, this.$route.params.redirect);
            }

            return this.$router.push(redirect);
          }
        }

        return this.$router.push('/sales/shop');
      }
    },

    async getCashRegisters (options = {}) {
      this.$set(this, 'cashRegisters', []);

      let cashRegisters = await this.$ws.get('v1', '/cash_registers', options);
      cashRegisters = cashRegisters.map((row) => {
        return new CashRegister().mergeResponse(row);
      });
      await Promise.all(cashRegisters.map(r => r.populate('get', options)));

      this.$set(this, 'cashRegisters', cashRegisters);
      return this.cashRegisters;
    }
  }
};
</script>
