// Libraries
import shop from '@/libs/shop';

// Components
import breadcrumbs from '@/components/sales/shop/view/components/breadcrumbs';
import categoryBlock from '@/components/sales/shop/view/components/category.block';
import productLine from '@/components/sales/shop/view/components/product.line';

// Mixins
import shopMethodsMixin from './shop.methods';

export default {
  mixins: [shopMethodsMixin],
  data () {
    return {
      shop
    };
  },
  components: {
    breadcrumbs,
    categoryBlock,
    productLine
  }
};
