<template>
  <KPortlet>
    <KPortletHead>
      <template v-slot:default>Afsluitingen</template>
    </KPortletHead>
    <KPortletBody>
      <div class="row">
        <div class="col-12 datatable datatable-wrapper">
          <datatable
            ref="datatable"
            :url="datatable.url"
            :transform="transform"
            :fields="datatable.fields"
            :filters="datatable.filters"
            :search-fields="datatable.searchFields"
            :on-pagination-data="onPaginationData"
          >
            <!-- Status -->
            <div
              slot="status"
              slot-scope="props"
              class="session-status"
            >
              <KButton
                :variant="(props.rowData.open === true ? 'label-caution' : 'label-focus')"
                class="btn-block btn-sm"
                tag="span"
              >
                {{ (props.rowData.open === true ? 'Open' : 'Gesloten') }}
              </KButton>
            </div>

            <!-- Opened by user -->
            <div
              slot="opened-by-user"
              slot-scope="props"
            >
              <router-link
                :to="`/users/view/${props.rowData.opened_by_user.guid}`"
              >
                {{ props.rowData.opened_by_user.name }}
              </router-link>
            </div>

            <!-- Closed by user -->
            <div
              slot="closed-by-user"
              slot-scope="props"
            >
              <router-link
                v-if="props.rowData.closed_by_user"
                :to="`/users/view/${props.rowData.closed_by_user.guid}`"
              >
                {{ props.rowData.closed_by_user.name }}
              </router-link>
            </div>

            <!-- Actions -->
            <div
              slot="actions"
              slot-scope="props"
              class="datatable-actions"
            >

              <router-link
                v-if="$auth.hasPermission('get_salesorders')"
                :to="`/cash_registers/closures/view/${props.rowData._meta.guid}`"
              >
                <KButton
                  :icon="['fad', 'eye']"
                  tag="a"
                  variant="label-brand"
                />
              </router-link>
            </div>
          </datatable>

          <div class="row pagination">
            <div class="col-xs-12 col-md-6">
              <datatable-pagination
                ref="pagination"
                @vuetable-pagination:change-page="onChangePage"
              />
            </div>

            <div class="col-xs-12 col-md-6">
              <datatable-pagination-info
                ref="pagination.info"
                @vuetable-paginationinfo:change-rowcount="onChangeRowCount"
              />
            </div>
          </div>

        </div>
      </div>
    </KPortletBody>
  </KPortlet>
</template>

<script>
// Libraries
import moment from 'moment-timezone';

// Classes
import CashRegister from '@/libs/classes/cash_register';
import CashRegisterOpening from '@/libs/classes/cash_register.opening';

// Mixins
import datatable from '@/mixins/datatable';

export default {
  mixins: [datatable],
  props: {
    cashRegister: {
      type: CashRegister,
      default: null
    }
  },
  data () {
    return {
      datatable: {
        url: '/cash_register_openings',

        fields: [{
          title: 'Status',
          name: '__slot:status',
          sortField: 'open',
          dataClass: 'w-1/8'
        }, {
          title: 'Geopend door',
          name: '__slot:opened-by-user',
          sortField: 'opened_by_user.name',
          dataClass: 'w-1/8'
        }, {
          title: 'Geopend op',
          name: 'open_timestamp',
          dataClass: 'w-2/8',
          callback: (value) => moment(value).format('LLL')
        }, {
          title: 'Gesloten door',
          name: '__slot:closed-by-user',
          sortField: 'closed_by_user.name',
          dataClass: 'w-1/8'
        }, {
          title: 'Gesloten op',
          name: 'close_timestamp',
          dataClass: 'w-2/8',
          callback: (value) => {
            if (!value) {
              return null;
            }

            return moment(value).format('LLL');
          }
        }, {
          title: '',
          name: '__slot:actions',
          titleClass: 'text-right',
          dataClass: 'text-right'
        }],

        filters: {
          cash_register: this.cashRegister.guid
        }
      }
    };
  },

  methods: {
    transform (data) {
      if (data.data && Array.isArray(data.data)) {
        data.data = data.data.map(r => {
          return new CashRegisterOpening().mergeResponse(r);
        }, []);
      }

      return data;
    }
  }
};
</script>
