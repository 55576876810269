<template>
  <div>

    <template
      v-if="action !== 'VIEW'"
    >
      <div class="row form-group">
        <div class="col-12">
          <label>Producten</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i><font-awesome-icon :icon="['fad', 'search']" /></i>
              </span>
            </div>
            <v-select
              ref="searchProducts"

              v-model="search.product"
              :options="search.products"
              :filterable="false"
              :disabled="isDisabled"

              label="name"
              class="form-control"

              @input="searchInputChanged"
              @search="fetchOptionsDebounced"
              @search:focus="fetchOptions"
            >
              <template
                slot="option"
                slot-scope="option"
              >
                <span>{{ option.name }}</span>
              </template>

              <template
                slot="spinner"
                slot-scope="spinner"
              >
                <div
                  v-if="spinner.loading"
                  class=" kt-spinner kt-spinner--v2 kt-spinner--primary"
                />
              </template>
            </v-select>
          </div>
        </div>
      </div>

      <KSeparator
        v-if="bundleProduct.products.length > 0"
        large
      />
    </template>

    <div class="products">
      <product-line
        v-for="(product, idx) in bundleProduct.products"
        :ref="product.VUID"
        :key="product.VUID"
        :product="product"
        :first-row="idx === 0"
        :last-row="idx === (bundleProduct.products.length - 1)"

        :action="action"
        :disabled="isDisabled"

        :remove-product="removeProduct"
      />
    </div>

  </div>
</template>

<script>
// Classes
import Product from '@/libs/classes/product';
import BundleProduct from '@/libs/classes/bundle_product';
import BundleProductProduct from '@/libs/classes/bundle_product.product';

// Components
import productLine from '@/components/bundles/components/product.line';

import vSelect from 'vue-select';
import '@/assets/sass/vue-select/vue-select.scss';

export default {
  components: {
    productLine,

    vSelect
  },
  props: {
    action: {
      type: String,
      default: 'CREATE'
    },

    bundleProduct: {
      type: BundleProduct,
      default: null
    },

    disabled: {
      type: Boolean,
      default: null
    },
    placeholder: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      search: {
        timeout: null,
        product: null,
        products: []
      }
    };
  },
  computed: {
    isDisabled () {
      if (this.action === 'VIEW') {
        return true;
      }

      if (this.disabled === true) {
        return true;
      }

      return false;
    }
  },
  watch: {
    'search.product' (product) {
      if (product !== null) {
        const line = new BundleProductProduct().mergeResponse({
          product_guid: product.guid,
          quantity: null,
          product: product
        });

        this.bundleProduct.products.unshift(line);
        this.search.product = null;

        this.$nextTick(() => {
          if (typeof this.$refs[line.VUID] !== typeof undefined) {
            this.$refs[line.VUID][0].focus();
          }
        });
      }
    }
  },

  methods: {
    async validate () {
      const validationArray = [];

      // Validate prices
      this.bundleProduct.products.forEach((row, idx) => {
        if (typeof this.$refs[row.VUID] !== typeof undefined) {
          if (Array.isArray(this.$refs[row.VUID])) validationArray.push(this.$refs[row.VUID][0].$v);
          else validationArray.push(this.$refs[row.VUID][0].$v);
        }
      });

      // Execute validations
      if (validationArray.length > 0) {
        validationArray.forEach(v => v && v.$touch && v.$touch());
        if (validationArray.filter(v => v && v.$invalid).length !== 0) return false;
      }

      return true;
    },

    async searchProducts (search) {
      // Add wildcard
      search = this.$format.addWildcardPerWord(search);

      // Configure options
      const options = {
        query: Object.assign({}, {
          q: search,
          limit: 5,
          ignore_guids: this.bundleProduct.products.map(p => p.product_guid, []).join(',')
        })
      };

      let response = await this.$ws.get('v1', '/products', options);
      response = response.map(p => new Product().mergeResponse(p), []);
      return response;
    },

    async fetchOptions (search) {
      const loading = this.$refs['searchProducts'].toggleLoading;
      loading(true);
      clearTimeout(this.search.timeout);
      this.$set(this.search, 'products', []);
      let data = await this.searchProducts(search);
      this.search.products = data;
      loading(false);
    },

    fetchOptionsDebounced (search) {
      const loading = this.$refs['searchProducts'].toggleLoading;
      clearTimeout(this.search.timeout);
      loading(true);
      this.search.timeout = setTimeout(async () => {
        await this.fetchOptions(search);
      }, 600);
    },

    searchInputChanged (input) {
      // Stop timeout and loading when input has changed
      this.$nextTick(() => {
        clearTimeout(this.search.timeout);
        const loading = this.$refs['searchProducts'].toggleLoading;
        loading(false);
      });
    },

    removeProduct (product) {
      this.bundleProduct.products = this.bundleProduct.products.filter(p => p.product_guid !== product.product_guid);
    }
  }
};
</script>
