var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isMounted && _vm.cash_register !== null)?_c('div',[_c('KSubheader',{attrs:{"title":"Kassa's","breadcrumbs":[{
      page: 'Kassa',
      route: '/sales'
    }, {
      page: 'Kassa\'s',
      route: '/sales/cash_registers'
    }, {
      page: _vm.cash_register.name,
      route: null
    }, {
      page: _vm.action.breadcrumb,
      route: null
    }]}}),_vm._v(" "),_c('KContainer',[_c('KPortlet',[_c('KPortletHead',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_vm._v(_vm._s(_vm.action.title))]},proxy:true}],null,false,3152822539)}),_vm._v(" "),_c('KPortletBody',[(_vm.action.action === 'OPEN')?_c('openRegisterForm',{ref:"openRegisterForm",attrs:{"cash_register":_vm.cash_register,"cash_register_opening":_vm.cash_register_opening,"meta":_vm.meta}}):(_vm.action.action === 'CLOSE')?_c('closeRegisterForm',{ref:"closeRegisterForm",attrs:{"cash_register":_vm.cash_register,"cash_register_opening":_vm.cash_register_opening,"meta":_vm.meta}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"kt-portlet__foot"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"offset-6 col-6 offset-lg-10 col-lg-2 kt-align-right"},[_c('KButton',{staticClass:"btn-md btn-block btn-tall btn-wide btn-bold btn-upper",attrs:{"variant":"primary"},nativeOn:{"click":function($event){$event.preventDefault();return _vm.submitForm($event)}}},[(_vm.action.action === 'OPEN')?_c('span',[_vm._v("Open kassa")]):(_vm.action.action === 'CLOSE')?_c('span',[_vm._v("Sluit kassa")]):_vm._e()])],1)])])],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }