<template>
  <div>

    <div class="row form-group mb-0">
      <div class="col-12">
        <label>Producten</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i><font-awesome-icon :icon="['fad', 'search']" /></i>
            </span>
          </div>
          <v-select
            ref="searchProducts"

            v-model="$v.search.product.$model"
            :class="[{'is-invalid': $v.search.product.$error}]"
            :options="search.products"
            :filterable="false"
            :min-input-length="1"

            label="name"
            class="form-control"

            @search="fetchOptions"
          >
            <template
              slot="option"
              slot-scope="option"
            >
              <span>{{ option.name }}</span>
            </template>

            <template
              slot="spinner"
              slot-scope="spinner"
            >
              <div
                v-if="spinner.loading"
                class=" kt-spinner kt-spinner--v2 kt-spinner--primary"
              />
            </template>
          </v-select>
        </div>
      </div>
    </div>

    <template v-if="stock_batch && stock_batch.mutations && stock_batch.mutations.length">
      <KSeparator class="mt-3 mb-3" />

      <div class="mutations">
        <mutation-line
          v-for="(mutation, idx) in stock_batch.mutations"
          :ref="mutation.VUID"
          :key="mutation.VUID"
          :mutation="stock_batch.mutations[idx]"
          :first-row="idx === 0"
          :last-row="idx === (stock_batch.mutations.length - 1)"

          :remove-mutation="removeMutation"
          :save="save"
        />
      </div>
    </template>

  </div>
</template>

<style lang="scss">
@import '@/assets/sass/vue-select/vue-select.scss';
</style>

<script>
// Classes
import StockBatch from '@/libs/classes/stock_batch';
import StockBatchMutation from '@/libs/classes/stock_batch.mutation';
import Product from '@/libs/classes/product';

// Validators
import { requiredIf } from 'vuelidate/lib/validators';

// Components
import mutationLine from './mutation.line';
import vSelect from 'vue-select';

export default {
  components: {
    mutationLine,

    vSelect
  },
  props: {
    stock_batch: {
      type: StockBatch,
      default: null
    },

    save: {
      type: Function,
      default: null
    }
  },
  data () {
    return {
      search: {
        product: null,
        products: [],

        timeout: null
      }
    };
  },
  validations: {
    search: {
      product: {
        required: requiredIf(function (value) {
          return (this.stock_batch && this.stock_batch.mutations && this.stock_batch.mutations.length > 0) === false;
        })
      }
    }
  },

  watch: {
    'search.product' (product) {
      if (product && product !== null && product instanceof Product) {
        // Create & add mutation
        const mutation = new StockBatchMutation().mergeResponse({
          type: this.stock_batch.type,
          product_name: product.name,
          product_guid: product.guid,
          stock: (product.stock || 0),
          quantity: null
        });
        this.stock_batch.mutations.push(mutation);

        // Focus on mutation quantity
        this.$nextTick(() => {
          if (this.$refs[mutation.VUID]) this.$refs[mutation.VUID][0].focus();
        })

        this.search.product = null;
      }
    }
  },

  methods: {
    async validate () {
      const validationArray = [];

      this.stock_batch.mutations.forEach((row, idx) => {
        if (this.$refs[row.VUID]) validationArray.push(this.$refs[row.VUID][0].$v);
      });

      // Execute validations
      if (validationArray.length > 0) {
        validationArray.forEach(v => v && v.$touch && v.$touch());
        if (validationArray.filter(v => v && v.$invalid).length !== 0) return false;
      }

      return true;
    },

    async removeMutation (mutation) {
      if (mutation instanceof StockBatchMutation) {
        this.stock_batch.mutations = this.stock_batch.mutations.filter(m => m.VUID !== mutation.VUID);
        await this.save();
      }
    },

    async searchProducts (search) {
      // Add wildcard
      search = this.$format.addWildcardPerWord(search);

      // Configure options
      const options = {
        query: Object.assign({}, {
          q: search,
          limit: 5
        })
      };

      // Ignore guid's
      if (this.stock_batch.mutations && this.stock_batch.mutations.length) {
        options.query.ignore_guids = this.stock_batch.mutations.map(m => m.product_guid, []).join(',');
      }

      // Request
      const response = await this.$ws.get('v1', '/products', options);

      // Set data
      const data = [];
      if (response !== null) {
        if (response && Array.isArray(response)) {
          response.forEach((row, idx) => {
            response[idx] = new Product().mergeResponse(response[idx]);
            data.push(response[idx]);
          });
        }
      }

      return data;
    },
    fetchOptions (search, loading) {
      clearTimeout(this.search.timeout);
      this.$set(this.search, 'products', []);

      if (typeof search === 'string' && search.length > 0) {
        loading(true);

        this.search.timeout = setTimeout(async () => {
          loading(true);
          try {
            let data = await this.searchProducts(search);
            this.search.products = data;
          } catch (e) {
            console.error(e);
          }
          loading(false);
        }, 600);
      }
    }
  }
};
</script>
