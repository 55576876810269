import Abstract from './abstract';

import { merge } from 'lodash';
import { apiWS as ws } from '@/libs/ws';

import mutationsLib from '@/libs/mutations';

class Device extends Abstract {
  constructor (guid = null) {
    super(guid);
    this.uri = '/devices';

    let defaultObject = {
      name: null,
      serial: null
    };

    merge(this, defaultObject);
  }

  async connect (body = {}, options = {}) {
    // Connect
    // Set _meta and guid after connect, because a merge will overrule all changes
    let response = await ws.put('v1', `${this.uri}/${this.serial}/connect`, options);
    this._meta = response._meta;
    this.guid = response._meta.guid;

    // Update
    const fields = ['name'];
    const mutations = mutationsLib.create(fields, {}, this);
    response = await this.update(mutations);

    return this;
  }

  async disconnect (body = {}, options = {}) {
    // Disconnect
    // Set _meta and guid after disconnect, because a merge will overrule all changes
    let response = await ws.put('v1', `${this.uri}/${this.serial}/disconnect`, options);
    this._meta = response._meta;
    this.guid = response._meta.guid;

    // Update
    const fields = ['name'];
    const mutations = fields.map((field) => {
      return {
        action: 'remove_field',
        field: field
      };
    }, []);
    response = await this.update(mutations);

    return this;
  }

  async create (body = {}, options = {}) {
    throw new Error('Create function not available for devices');
  }
};

export default Device;
