var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('KSubheader',{attrs:{"breadcrumbs":[ {
      page: 'Workspace',
      route: null
    }, {
      page: 'Instellingen',
      route: null
    }],"title":"Instellingen"}}),_vm._v(" "),_c('KContainer',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-portlet-menu"},[_c('KPortlet',[_c('KPortletBody',[_c('ul',{staticClass:"kt-nav kt-nav--bolder kt-nav--fit-ver kt-nav--v4",attrs:{"role":"tablist"}},[_c('router-link',{attrs:{"to":"/settings/workspace/integrations"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var href = ref.href;
    var route = ref.route;
    var navigate = ref.navigate;
    var isActive = ref.isActive;
    var isExactActive = ref.isExactActive;
return [_c('li',{class:['kt-nav__item', {'active': isActive}]},[_c('a',{staticClass:"kt-nav__link",attrs:{"role":"tab"},on:{"click":function($event){$event.preventDefault();return _vm.$router.push(href)}}},[_c('span',{staticClass:"kt-nav__link-icon"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['fad', 'network-wired']}})],1)]),_vm._v(" "),_c('span',{staticClass:"kt-nav__link-text"},[_vm._v("Integraties")])])])]}}])})],1)])],1)],1),_vm._v(" "),_c('div',{staticClass:"col-12 col-sm"},[_c('router-view',{key:_vm.$route.fullPath})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }