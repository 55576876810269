<template>
  <div>
    <KSubheader
      title="Prijslijsten"
      :breadcrumbs="[{
        page: 'Producten',
        route: '/products'
      }, {
        page: 'Prijslijsten',
        route: '/products/pricelists'
      }, {
        page: 'Prijslijst aanmaken',
        route: null
      }]"
    >

    </KSubheader>

    <KContainer>
      <KPortlet>

        <KPortletBody class="kt-portlet__body-fit">
          <KWizard ref="wizard"
            :clickable="false"
          >
            <KWizardStep
              title="Informatie"
            >

              <informationForm ref="informationForm"
                action="CREATE"

                :pricelist="pricelist"
              ></informationForm>

            </KWizardStep>

            <KWizardStep
              title="Prijzen"
            >

              <pricesForm ref="pricesForm"
                action="CREATE"

                :pricelist="pricelist"
              ></pricesForm>

            </KWizardStep>
          </KWizard>
        </KPortletBody>
        <div class="kt-portlet__foot">
  				<div class="row align-items-center">
  					<div class="col-6 col-lg-2">
              <KButton v-if="(isMounted && $refs.wizard && $refs.wizard.currentStep !== 0)" variant="outline-focus" class="btn-md btn-block btn-tall btn-wide btn-bold btn-upper" @click.native.prevent="previousStep">Vorige</KButton>
  					</div>
  					<div class="col-6 offset-lg-8 col-lg-2 kt-align-right">
  						<KButton v-if="(isMounted && $refs.wizard && $refs.wizard.currentStep !== ($refs.wizard.steps.length - 1))" variant="focus" class="btn-md btn-block btn-tall btn-wide btn-bold btn-upper" @click.native.prevent="nextStep">Volgende</KButton>
              <KButton v-if="(isMounted && $refs.wizard && $refs.wizard.currentStep === ($refs.wizard.steps.length - 1))" variant="success" class="btn-md btn-block btn-tall btn-wide btn-bold btn-upper" @click.native.prevent="submitForm">Aanmaken</KButton>
  					</div>
  				</div>
  			</div>
      </KPortlet>
    </KContainer>
  </div>
</template>

<script>
// Import CSS for KWizard
import "@/assets/sass/caesium.wizard.scss";

// Libraries
import moment from 'moment-timezone';
import { cloneDeep } from 'lodash';

// Classes
import Pricelist from '@/libs/classes/pricelist';

// Components
import informationForm from './components/information.form';
import pricesForm from './components/prices.form';

export default {
  data() {
    return {
      isMounted: false,

      pricelist: new Pricelist().mergeResponse({
        type: 'products',
        active: true
      })
    }
  },
  components: {
    informationForm,
    pricesForm
  },
  mounted() {
    this.$nextTick(() => {
      this.isMounted = true;
    })
  },
  methods: {
    async previousStep() {
      if(this.$refs.wizard) {
        return this.$refs.wizard.previousStep();
      }
    },
    async nextStep() {
      if(this.$refs.wizard) {
        let valid = await this.validate();
        if(valid) this.$refs.wizard.nextStep();
      }
    },

    async validate() {
      const validationArray = [];

      // Information
      if(this.$refs.wizard.currentStep === 0) {
        validationArray.push(this.$refs.informationForm.$v);
      }

      if(this.$refs.wizard.currentStep === 1) {
        validationArray.push(this.$refs.pricesForm.$v);

        let valid = await this.$refs.pricesForm.validate();
        if(valid === false) return valid;
      }

      // Execute validations
      if(validationArray.length > 0) {
        validationArray.forEach(v => v && v.$touch && v.$touch())
        if(validationArray.filter(v => v && v.$invalid).length !== 0) return false;
      }

      return true;
    },

    async submitForm () {
      if (await this.validate() === true) {
        // Clone pricelist, because it has to populate first for the Frontend
        let pricelist = cloneDeep(this.pricelist);
        await pricelist.create(pricelist.formatBody());
        this.$router.push(`/products/pricelists/view/${pricelist.guid}`);
      }
    }
  }
};
</script>
