<template>
  <KPortlet>
    <KPortletHead>
      <template v-slot:default>Winkel instellingen</template>
    </KPortletHead>
    <KPortletBody>

      <div class="row form-group">
        <label class="col-12 col-md-2 col-form-label">Timezone</label>
        <div class="col-12 col-md-10">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i><font-awesome-icon :icon="['fad', 'globe']" /></i>
              </span>
            </div>
            <v-select
              ref="searchTimezones"

              v-model="$data._store.settings.timezone"
              :options="meta.timezones"
              :reduce="timezone => timezone.value"
              :min-input-length="1"

              class="form-control"
              label="timezone"
            >
              <template
                slot="option"
                slot-scope="option"
              >
                <span>{{ option.timezone }}</span>
              </template>
            </v-select>
          </div>
        </div>
      </div>

      <div class="row form-group">
        <label class="col-12 col-md-2 col-form-label">Regio</label>
        <div class="col-12 col-md-10">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i><font-awesome-icon :icon="['fad', 'map-marked-alt']" /></i>
              </span>
            </div>
            <v-select
              ref="searchRegion"

              v-model="$data._store.settings.region"
              :options="meta.regions"
              :reduce="region => region.code"
              :min-input-length="1"

              label="country"
              class="form-control"
            >
              <template
                slot="option"
                slot-scope="option"
              >
                <span>{{ option.country }}</span>
              </template>
            </v-select>
          </div>
        </div>
      </div>

    </KPortletBody>
    <div class="kt-portlet__foot">
      <div class="row align-items-center">
        <div class="offset-6 col-6 offset-lg-10 col-lg-2 kt-align-right">
          <KButton
            variant="warning"
            class="btn-md btn-block btn-tall btn-wide btn-bold btn-upper"
            @click.native.prevent="submitForm"
          >
            Wijzigen
          </KButton>
        </div>
      </div>
    </div>
  </KPortlet>
</template>

<script>
// Libraries
import moment from 'moment-timezone';
import iso3166 from 'iso-3166-2';
import mutationsLib from '@/libs/mutations';
import { cloneDeep } from 'lodash';

// Classes
import Store from '@/libs/classes/store';

// Components
import vSelect from 'vue-select';
import '@/assets/sass/vue-select/vue-select.scss';

export default {
  components: {
    vSelect
  },
  props: {
    store: {
      type: Store,
      default: null
    }
  },
  data () {
    let data = {
      _store: cloneDeep(this.store),

      meta: {
        timezones: [],
        regions: []
      }
    };

    // Get timezones
    moment.tz.names().forEach((timezone, idx) => {
      data.meta.timezones.push({
        timezone: timezone.split('_').join(' '),
        value: timezone
      });
    });

    // Get iso3166 regions
    Object.keys(iso3166.data).forEach((code, idx) => {
      data.meta.regions.push({
        country: iso3166.data[code].name,
        code: code
      });
    });

    return data;
  },
  methods: {
    async submitForm () {
      const fields = ['settings.timezone', 'settings.region'];
      const mutations = mutationsLib.create(fields, this.store.formatBody(), this.$data._store.formatBody());

      if (mutations.length) {
        await this.store.update(mutations);
        await this.store.populate('update');
        this.$set(this.$data, '_store', cloneDeep(this.store));
      }
    }
  }
};
</script>
