<template>
  <div class="col-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">

    <breadcrumbs
      title="Overzicht"
    />

    <div class="row mt-3">

      <!-- On account repayment -->
      <div
        v-if="salesorder && salesorder.customer && salesorder.customer.balance && salesorder.customer.balance.on_account < 0"
        class="col-12"
      >
        <category-block
          :icon="['fad', 'money-check-edit-alt']"

          icon-class="kt-font-danger"

          @click.prevent.native="$eventhub.emit('modals:shop:repay-on-account:open')"
        >
          <h4 class="category-title">Openstaand bedrag aflossen</h4>
          <span class="category-subtitle">{{ salesorder.customer.name }} heeft een openstaand bedrag van &euro; {{ $format.currency(salesorder.customer.balance.on_account * -1) }}</span>
        </category-block>
      </div>

      <!-- Categories -->
      <div class="col-12 col-md-6">
        <category-block
          :icon="['fad', 'tags']"

          @click.prevent.native="navigateTo('/sales/shop/:cash_register/cart/products/overview')"
        >
          <h4 class="category-title">Producten</h4>
          <span class="category-subtitle">Kies hier uit alle producten, ingedeeld op productcategorie.</span>
        </category-block>
      </div>

      <div class="col-12 col-md-6">
        <category-block
          :icon="['fad', 'link']"

          @click.prevent.native="$router.push('/dashboard')"
        >
          <h4 class="category-title">Sessies</h4>
          <span class="category-subtitle">Kies hier eerst uit alle apparatuur, en stel vervolgens de sessie samen.</span>
        </category-block>
      </div>

      <div class="col-12 col-md-6">
        <category-block
          :icon="['fad', 'boxes']"

          @click.prevent.native="navigateTo('/sales/shop/:cash_register/cart/bundles/overview')"
        >
          <h4 class="category-title">Bundels</h4>
          <span class="category-subtitle">Kies hier uit bundels welke bestaan uit sessies en eventueel bijbehorende producten.</span>
        </category-block>
      </div>

      <div class="col-12 col-md-6">
        <category-block
          :icon="['fad', 'user-clock']"

          @click.prevent.native="navigateTo('/sales/shop/:cash_register/cart/subscriptions/overview')"
        >
          <h4 class="category-title">Abonnementen</h4>
          <span class="category-subtitle">Kies hier uit abonnementen met een bepaalde geldigheid.</span>
        </category-block>
      </div>

      <div class="col-12 col-md-6">
        <category-block
          :icon="['fad', 'spa']"

          @click.prevent.native="navigateTo('/sales/shop/:cash_register/cart/treatments/overview')"
        >
          <h4 class="category-title">Behandelingen</h4>
          <span class="category-subtitle">Kies hier uit behandelingen.</span>
        </category-block>
      </div>

      <div class="col-12 col-md-6">
        <category-block
          :icon="['fad', 'box-full']"

          @click.prevent.native="navigateTo('/sales/shop/:cash_register/cart/product_bundles/overview')"
        >
          <h4 class="category-title">Product bundels</h4>
          <span class="category-subtitle">Kies hier uit een bundel met producten.</span>
        </category-block>
      </div>

      <div class="col-12 col-md-6">
        <category-block
          :icon="['fad', 'gift-card']"

          @click.prevent.native="$eventhub.emit('modals:shop:initiate-giftcard:open')"
        >
          <h4 class="category-title">Cadeaukaarten</h4>
          <span class="category-subtitle">Maak hier een cadeaukaart aan, met een unieke code en waarde.</span>
        </category-block>
      </div>

      <div class="col-12 col-md-6">
        <category-block
          :icon="['fad', 'credit-card']"

          @click.prevent.native="navigateTo('/sales/shop/:cash_register/cart/credit/overview')"
        >
          <h4 class="category-title">Sessiekrediet</h4>
          <span class="category-subtitle">Kies hier uit voorgedefinieerde sessiekredieten.</span>
        </category-block>
      </div>

    </div>
  </div>
</template>

<script>
// Mixins
import shopMixin from '@/mixins/shop';

export default {
  mixins: [shopMixin],
  data () {
    return {
      salesorder: null
    };
  },

  mounted () {
    // Set sales order of shop
    this.$set(this, 'salesorder', this.shop.salesorder);

    // Listeners
    this.$eventhub.on('shop:cart:updated', this.onCartUpdated);
  },
  beforeDestroy () {
    // Remove listeners
    this.$eventhub.off('shop:cart:updated', this.onCartUpdated);
  },

  methods: {
    onCartUpdated (salesorder) {
      // First set to null, so it can update properly
      this.$set(this, 'salesorder', null);

      // Set to real salesorder on next tick
      this.$nextTick(() => {
        this.$set(this, 'salesorder', salesorder);
      });
    }
  }
};
</script>
