<template>
  <div>

    <!-- Default information -->
    <div class="row form-group">
      <div class="col-12 col-lg-10">
        <label>Naam <sup>Verplicht</sup></label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i><font-awesome-icon :icon="['fad', 'text']" /></i>
            </span>
          </div>
          <input
            v-model.trim="$v.bundleProduct.name.$model"
            :class="['form-control', {'is-invalid': $v.bundleProduct.name.$error}]"
            :disabled="isDisabled"

            type="text"
          >
        </div>
      </div>

      <div class="col-12 mt-3 col-lg-2 mt-lg-0">
        <div class="row">
          <label class="d-none d-lg-block col-lg-12 text-md-center">Bundel actief</label>
          <div class="col-3 col-lg-12 text-md-center">
            <span :class="['kt-switch', {'kt-switch--success': bundleProduct.active}]">
              <label>
                <input
                  v-model="bundleProduct.active"
                  :disabled="isDisabled"

                  type="checkbox"
                >
                <span />
              </label>
            </span>
          </div>
          <label class="d-block d-lg-none col-9 text-md-center col-form-label">Bundel actief</label>
        </div>
      </div>
    </div>

    <KSeparator large />

    <!-- Duration -->
    <template v-if="bundleProduct.type !== 'product_bundle'">
      <div class="row form-group">
        <div class="col-12 col-md-6 col-lg-3">
          <label>Geldigheid</label>
          <KButtonGroup class="btn-block">
            <!-- Unlimited -->
            <KButton
              v-if="(bundleProduct.type === 'bundle' || bundleProduct.type === 'treatment')"

              :variant="(bundleProduct.bundle_limit.limited === false ? 'primary' : 'outline-primary')"
              :disabled="isDisabled"

              @click.prevent.native="() => bundleProduct.bundle_limit.limited = false"
            >
              Ongelimiteerd
            </KButton>

            <!-- Limited -->
            <KButton
              :variant="((bundleProduct.bundle_limit.limited === true || bundleProduct.type === 'subscription') ? 'primary' : 'outline-primary')"
              :disabled="isDisabled"

              @click.prevent.native="() => bundleProduct.bundle_limit.limited = true"
            >
              Gelimiteerd
            </KButton>
          </KButtonGroup>
        </div>

        <div class="col-12 mt-3 col-md-6 mt-md-0 col-lg-3">
          <label>Betaling</label>
          <KButtonGroup class="btn-block">
            <KButton
              :disabled="isDisabled"

              variant="primary"
            >
              Eenmalig
            </KButton>
            <KButton
              v-if="bundleProduct.bundle_limit.limited === true"
              variant="outline-primary"
              disabled
            >
              Terugkerend
            </KButton>
          </KButtonGroup>
        </div>

        <div class="col-12 mt-3 col-md-6 mt-md-0 col-lg-3">
          <label>Waarde</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i><font-awesome-icon :icon="['fad', 'hashtag']" /></i>
              </span>
            </div>
            <input
              v-if="bundleProduct.bundle_limit.limited === false"

              key="bundle_limit.value.UNLIMITED"

              type="text"
              class="form-control"
              value="Ongelimiteerd"
              disabled
            >

            <input
              v-else

              key="bundle_limit.value.LIMITED"

              v-model.number="$v.bundleProduct.bundle_limit.value.$model"
              :class="['form-control', {'is-invalid': $v.bundleProduct.bundle_limit.value.$error}]"
              :disabled="isDisabled"

              type="number"
            >
          </div>
        </div>

        <div class="col-12 mt-3 col-md-6 mt-md-0 col-lg-3">
          <label>Periode</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i><font-awesome-icon :icon="['fad', 'calendar']" /></i>
              </span>
            </div>
            <select
              v-model="bundleProduct.bundle_limit.type"
              :disabled="(isDisabled || bundleProduct.bundle_limit.limited === false)"

              class="form-control"
            >
              <option value="days">{{ (bundleProduct.bundle_limit.value === 1 ? `Dag` : `Dagen`) }}</option>
              <option value="weeks">{{ (bundleProduct.bundle_limit.value === 1 ? `Week` : `Weken`) }}</option>
              <option value="months">{{ (bundleProduct.bundle_limit.value === 1 ? `Maand` : `Maanden`) }}</option>
              <option value="years">{{ (bundleProduct.bundle_limit.value === 1 ? `Jaar` : `Jaren`) }}</option>
            </select>
          </div>
        </div>
      </div>

      <KSeparator large />
    </template>

    <!-- Barcode & article number -->
    <div class="row form-group">
      <div class="col-12 col-lg-4">
        <label>Artikelnummer</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i><font-awesome-icon :icon="['fad', 'hashtag']" /></i>
            </span>
          </div>
          <input
            v-model="$v.bundleProduct.article_number.$model"
            :class="['form-control', {'is-invalid': $v.bundleProduct.article_number.$error}]"
            :disabled="isDisabled"

            type="text"
          >
        </div>
      </div>

      <div class="col-12 mt-3 col-lg-4 mt-lg-0">
        <label>Barcode (EAN)</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i><font-awesome-icon :icon="['fad', 'barcode']" /></i>
            </span>
          </div>
          <input
            v-model="$v.bundleProduct.EAN_code.$model"
            :class="['form-control', {'is-invalid': $v.bundleProduct.EAN_code.$error}]"
            :disabled="isDisabled"

            type="text"
          >
        </div>
      </div>

      <permission-groups-select
        ref="permissionGroupsSelect"

        :product="bundleProduct"
        :disabled="isDisabled"

        :action="action"

        class="col-12 mt-3 col-lg-4 mt-lg-0"
      />
    </div>

    <KSeparator large />

    <!-- Pricing -->
    <div class="row form-group">
      <div class="col-12 col-lg-3">
        <label>Inkoopprijs</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i><font-awesome-icon :icon="['fad', 'euro-sign']" /></i>
            </span>
          </div>
          <input
            v-model.number.lazy="$v.bundleProduct.cost_price_formatted.$model"
            :class="['form-control', {'is-invalid': $v.bundleProduct.cost_price_formatted.$error}]"
            :disabled="isDisabled"

            type="number"
          >
        </div>
      </div>

      <div class="col-12 col-lg-3">
        <label>Prijs excl BTW <sup>Verplicht</sup></label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i><font-awesome-icon :icon="['fad', 'euro-sign']" /></i>
            </span>
          </div>
          <input
            v-model.number.lazy="$v.bundleProduct.price_excl_vat_formatted.$model"
            :class="['form-control', {'is-invalid': $v.bundleProduct.price_excl_vat_formatted.$error}]"
            :disabled="isDisabled"

            type="number"
          >
        </div>
      </div>

      <vat-select
        ref="vatSelect"

        :product="bundleProduct"
        :disabled="isDisabled"

        class="col-12 col-lg-3"
      />

      <div class="col-12 col-lg-3">
        <label>Prijs incl BTW <sup>Verplicht</sup></label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i><font-awesome-icon :icon="['fad', 'euro-sign']" /></i>
            </span>
          </div>
          <input
            v-model.number.lazy="$v.bundleProduct.price_incl_vat_formatted.$model"
            :class="['form-control', {'is-invalid': $v.bundleProduct.price_incl_vat_formatted.$error}]"
            :disabled="isDisabled"

            type="number"
          >
        </div>
      </div>
    </div>

    <KSeparator large />

    <!-- Requires customer & amount changeable -->
    <div class="row form-group mb-0">
      <div class="col-12 col-md-3 offset-md-2">
        <div class="row">
          <div class="col-3 col-md-12 text-md-center">
            <span :class="['kt-switch', {'kt-switch--success': (bundleProduct.amount_changeable === true)}]">
              <label>
                <input
                  v-model="bundleProduct.amount_changeable"
                  :disabled="isDisabled"

                  type="checkbox"
                >
                <span />
              </label>
            </span>
          </div>
          <label class="col-9 col-md-12 text-md-center col-form-label">Aantal aanpasbaar</label>
        </div>
      </div>

      <div class="col-12 col-md-3 offset-md-2">
        <div class="row">
          <div class="col-3 col-md-12 text-md-center">
            <span :class="['kt-switch', {'kt-switch--success': (bundleProduct.requires_customer === true)}]">
              <label>
                <input
                  v-model="bundleProduct.requires_customer"
                  :disabled="(isDisabled || bundleProduct.type !== 'product_bundle')"

                  type="checkbox"
                >
                <span />
              </label>
            </span>
          </div>
          <label class="col-9 col-md-12 text-md-center col-form-label">Kan alleen gekocht worden wanneer klant bekend is</label>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
// Components
import vatSelect from '@/components/products/components/vat.select';
import permissionGroupsSelect from '@/components/products/components/permission-groups.select';

// Classes
import BundleProduct from '@/libs/classes/bundle_product';
import BundleProductProducts from '@/libs/classes/bundle_product.products';
import BundleProductSubscription from '@/libs/classes/bundle_product.subscription';
import BundleProductTreatment from '@/libs/classes/bundle_product.treatment';

// Validations
import { required, requiredIf, minValue } from 'vuelidate/lib/validators';

export default {
  components: {
    vatSelect,
    permissionGroupsSelect
  },
  props: {
    action: {
      type: String,
      default: 'CREATE'
    },

    bundleProduct: {
      type: [BundleProduct, BundleProductSubscription, BundleProductTreatment, BundleProductProducts],
      default: null
    },

    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    isDisabled () {
      if (this.action === 'VIEW') {
        return true;
      }

      if (this.disabled === true) {
        return true;
      }

      return false;
    }
  },
  watch: {
    'bundleProduct.price.vat_code' (newVal, oldVal) {
      let VATCode = this.$refs.vatSelect.vatCodes.find(c => c.code === newVal);
      if (typeof VATCode !== typeof undefined) {
        this.bundleProduct.price.vat_percentage = VATCode.value;
      }
    }
  },
  validations: {
    bundleProduct: {
      name: {
        required
      },

      bundle_limit: {
        value: {
          required: requiredIf(function (value) {
            // Required when type subscription
            if (this.bundleProduct.type === 'subscription') {
              return true;
            }

            // Required when bundle and limited is true
            if (this.bundleProduct.type === 'bundle' && this.bundleProduct.bundle_limit.limited === true) {
              return true;
            }

            return false;
          })
        }
      },

      article_number: {

      },
      EAN_code: {

      },

      cost_price_formatted: {

      },

      price: {
        vat_code: {
          required
        }
      },

      price_excl_vat_formatted: {
        required,
        minValue: minValue(0)
      },
      price_incl_vat_formatted: {
        required,
        minValue: minValue(0)
      }
    }
  },

  methods: {
    async validate () {
      const validationArray = [];
      validationArray.push(this.$v, this.$refs.vatSelect.$v);

      // Execute validations
      if (validationArray.length > 0) {
        validationArray.forEach(v => v && v.$touch && v.$touch());
        if (validationArray.filter(v => v && v.$invalid).length !== 0) return false;
      }

      return true;
    }
  }
};
</script>
