<template>
  <div>

    <KSubheader
      :breadcrumbs="[{
        page: 'Producten',
        route: '/products/overview'
      }, {
        page: 'Voorraadbeheer',
        route: null
      }, {
        page: 'Mutaties',
        route: null
      }, {
        page: 'Overzicht',
        route: null
      }]"

      title="Mutaties"
    />

    <KContainer>

      <KPortlet>
        <KPortletHead>
          <template v-slot:default>Overzicht</template>

          <template v-slot:toolbar>
            <router-link
              v-if="$auth.hasPermission('create_stock_mutations') === true"
              to="/products/stock/mutations/create"
            >
              <KButton
                variant="outline-brand"
                icon="plus"
              >
                Nieuwe mutatie
              </KButton>
            </router-link>
          </template>
        </KPortletHead>
        <KPortletBody>

          <div class="row">
            <div class="col-12">
              <div class="kt-input-icon kt-input-icon--left">
                <input
                  id="generalSearch"
                  type="text"
                  class="form-control"
                  placeholder="Zoeken..."
                  @keyup.enter="$refs['datatable'].search"
                  @keyup="searchDebounced"
                >
                <span class="kt-input-icon__icon kt-input-icon__icon--left">
                  <span>
                    <i>
                      <font-awesome-icon
                        :icon="['far', 'search']"
                      />
                    </i>
                  </span>
                </span>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-12 datatable datatable-wrapper">
              <datatable
                ref="datatable"
                :url="datatable.url"
                :http-fetch="fetch"
                :fields="datatable.fields"
                :search-fields="datatable.searchFields"
                :on-pagination-data="onPaginationData"
                :filters="datatable.filters"
              >
                <div
                  slot="status"
                  slot-scope="props"
                  class="datatable-status"
                >
                  <!-- Concept -->
                  <KButton
                    v-if="props.rowData.status === 'draft'"
                    tag="span"
                    variant="label-info"
                    class="btn-block"
                  >
                    Concept
                  </KButton>

                  <!-- Final -->
                  <KButton
                    v-else-if="props.rowData.status === 'final'"
                    tag="span"
                    variant="label-success"
                    class="btn-block"
                  >
                    Doorgevoerd
                  </KButton>

                  <!-- Unknown -->
                  <KButton
                    v-else
                    tag="span"
                    variant="label-warning"
                    class="btn-block"
                  >
                    Onbekend
                  </KButton>
                </div>

                <!-- User -->
                <div
                  slot="user"
                  slot-scope="props"
                >
                  <template v-if="props.rowData.finished_user">
                    <router-link
                      :to="(props.rowData.finished_user.guid === $auth.user.guid ? `/settings/personal` : `/users/view/${props.rowData.finished_user.guid}`)"
                    >
                      {{ props.rowData.finished_user.name }}
                    </router-link>
                  </template>

                  <template v-else>
                    Onbekend
                  </template>
                </div>

                <!-- Actions -->
                <div
                  slot="actions"
                  slot-scope="props"
                  class="datatable-actions"
                >
                  <!-- Actions when final -->
                  <template v-if="props.rowData.status === 'final'">
                    <router-link
                      v-if="$auth.hasPermission('get_stock_mutations')"
                      :to="`/products/stock/mutations/view/${props.rowData.guid}`"
                    >
                      <KButton
                        :icon="['fad', 'eye']"
                        tag="a"
                        variant="label-brand"
                      />
                    </router-link>
                  </template>

                  <!-- Actions when draft -->
                  <template v-if="props.rowData.status === 'draft'">
                    <router-link
                      v-if="$auth.hasPermission('create_stock_mutations')"
                      :to="`/products/stock/mutations/create/${props.rowData.guid}`"
                    >
                      <KButton
                        :icon="['fad', 'edit']"
                        tag="a"
                        variant="label-focus"
                      />
                    </router-link>

                    <KButton
                      v-if="$auth.hasPermission('remove_stock_mutations')"
                      :icon="['fad', 'trash']"
                      tag="a"
                      disabled
                      variant="label-focus"
                    />
                  </template>
                </div>
              </datatable>

              <div class="row pagination">
                <div class="col-xs-12 col-md-6">
                  <datatable-pagination
                    ref="pagination"
                    @vuetable-pagination:change-page="onChangePage"
                  />
                </div>

                <div class="col-xs-12 col-md-6">
                  <datatable-pagination-info
                    ref="pagination.info"
                    @vuetable-paginationinfo:change-rowcount="onChangeRowCount"
                  />
                </div>
              </div>
            </div>
          </div>

        </KPortletBody>
      </KPortlet>

    </KContainer>

  </div>
</template>

<script>
// Libraries
import moment from 'moment';

// Classes
import StockBatch from '@/libs/classes/stock_batch';
import User from '@/libs/classes/user';

// Mixins
import datatable from '@/mixins/datatable';

export default {
  mixins: [datatable],
  data () {
    return {
      datatable: {
        url: '/stock_batches',

        fields: [{
          title: 'Status',
          name: '__slot:status',
          sortField: 'status',
          titleClass: 'text-center',
          dataClass: 'w-1/16'
        }, {
          title: 'Datum & tijd',
          name: '',
          sortField: '_meta.updated',
          dataClass: 'w-1/8',
          callback: (row) => {
            if (row.status === 'draft') return moment(row._meta.created).format('LLL');
            return moment(row.finished_timestamp).format('LLL');
          }
        }, {
          title: 'Medewerker',
          name: '__slot:user',
          dataClass: 'w-2/8'
        }, {
          title: 'Opmerking',
          name: 'remark',
          sortField: 'remark',
          dataClass: 'w-3/8'
        }, {
          title: '',
          name: '__slot:actions',
          titleClass: 'text-right',
          dataClass: 'text-right'
        }],

        searchFields: ['remark'],
        filters: {
          type: 'manual'
        }
      }
    };
  },
  methods: {
    async fetch (apiUrl, httpOptions) {
      const fetch = await this.$refs['datatable'].fetch(apiUrl, httpOptions, {skipHttpFetch: true});

      // Set fetch data
      fetch.data.data = fetch.data.data.map(r => new StockBatch().mergeResponse(r), []);

      // Get users
      let users = [];
      if (this.$auth.hasPermission('get_users') === true) {
        let userGuids = [];

        // Get user guid based on status
        userGuids = userGuids.concat(fetch.data.data.filter(r => r.status === 'final', []).map(r => r.finished_user_guid, []));
        userGuids = userGuids.concat(fetch.data.data.filter(r => r.status === 'draft', []).filter(r => r._meta.created_by.resource.storage === 'users', []).map(r => r._meta.created_by.resource.guid, []));
        userGuids = userGuids.filter((value, index, self) => self.indexOf(value) === index, []);

        users = await this.$ws.get('v1', '/users', {
          query: {
            'filter[_meta.guid]': userGuids.join(',')
          }
        });
      } else {
        // Set own user to array if you can't get all users
        users = [this.$auth.user];
      }

      // Link users
      if (users && users.length) {
        fetch.data.data.forEach((row, idx) => {
          // Find user, based on status
          let user = null;
          if (row.status === 'final') user = users.find(u => u._meta.guid === fetch.data.data[idx].finished_user_guid);
          else if (row.status === 'draft') user = users.find(u => u._meta.guid === fetch.data.data[idx]._meta.created_by.resource.guid);

          // Check if user found
          if (typeof user !== typeof undefined && user !== null) fetch.data.data[idx].finished_user = new User().mergeResponse(user);
        });
      }

      return fetch;
    }
  }
};
</script>
