<template>
  <div class="input-group">
    <div class="input-group-prepend">
      <span class="input-group-text">
        <i><font-awesome-icon :icon="['fad', 'calendar']" /></i>
      </span>
    </div>

    <!-- Date -->
    <select
      v-model.number="birthday.date"
      :class="['form-control', {'is-invalid no-icon': isInvalid}]"
      :disabled="disabled"
    >
      <!-- Default -->
      <option
        :value="null"
        disabled
      >
        Dag
      </option>

      <!-- Options -->
      <option
        v-for="day in 31"
        :key="`birthday.day.${day}`"
        :value="day"
      >
        {{ day }}
      </option>
    </select>

    <!-- Month -->
    <select
      v-model.number="birthday.month"
      :class="['form-control', {'is-invalid no-icon': isInvalid}]"
      :disabled="disabled"
    >
      <option
        :value="null"
        disabled
      >
        Maand
      </option>

      <!-- Options -->
      <option
        v-for="month in 12"
        :key="`birthday.month.${month}`"
        :value="month"
      >
        {{ month }}
      </option>
    </select>

    <!-- Year -->
    <select
      v-model.number="birthday.year"
      :class="['form-control', {'is-invalid no-icon': isInvalid}]"
      :disabled="disabled"
    >
      <option
        :value="null"
        disabled
      >
        Jaar
      </option>

      <!-- Options -->
      <option
        v-for="year in years"
        :key="`birthday.year.${year}`"
        :value="year"
      >
        {{ year }}
      </option>
    </select>
  </div>
</template>

<script>
// Libraries
import moment from 'moment-timezone';

export default {
  props: {
    value: {
      type: String,
      default: null
    },
    isInvalid: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      error: false,
      birthday: {
        date: null,
        month: null,
        year: null
      }
    };
  },
  computed: {
    years: {
      get () {
        const years = [];

        const currentYear = new Date().getFullYear();
        for (let year = currentYear; year >= 1900; year--) {
          years.push(year);
        }

        return years;
      }
    }
  },
  watch: {
    birthday: {
      deep: true,
      handler (birthday) {
        if (!birthday.date || !birthday.month || !birthday.year) {
          return;
        }

        const date = moment(`${birthday.year}-${birthday.month}-${birthday.date}`, 'YYYY-M-D', true);
        if (date.isValid() === false) {
          this.$emit('input', null);
          return;
        }

        this.$emit('input', date.format('YYYY-MM-DD'));
      }
    }
  },

  mounted () {
    this.formatValue(this.value);
  },

  methods: {
    formatValue (value = null) {
      if (!value) {
        return;
      }

      const date = moment(value, 'YYYY-M-D', true);
      if (date.isValid() === false) {
        return;
      }

      // Set birthday data
      this.birthday.date = date.date();
      this.birthday.month = (date.month() + 1);
      this.birthday.year = date.year();
      return this.birthday;
    }
  }
};
</script>
