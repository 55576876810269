<template>
  <div>

    <div class="row form-group">
      <div class="col-12 col-lg-4">
        <label>Naam <sup>Verplicht</sup></label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i><font-awesome-icon :icon="['fad', 'text']" /></i>
            </span>
          </div>
          <input
            v-model.trim="$v.product.name.$model"
            :class="['form-control', {'is-invalid': $v.product.name.$error}]"
            :disabled="isDisabled"

            type="text"
          >
        </div>
      </div>

      <div class="col-12 mt-3 col-lg-4 mt-lg-0">
        <label>Groep <sup>Verplicht</sup></label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i><font-awesome-icon :icon="['fad', 'tags']" /></i>
            </span>
          </div>
          <select
            v-model.trim="$v.product.group_guid.$model"
            :class="['form-control', {'is-invalid': $v.product.group_guid.$error}]"
            :disabled="isDisabled"
          >
            <option
              :value="null"
              disabled
            >
              Selecteer groep...
            </option>

            <!-- Groups -->
            <option
              v-for="(group) in groups"
              :key="`product.group.${group._meta.guid}`"
              :value="group._meta.guid"
            >
              {{ group.name }}
            </option>

            <option disabled>──────────</option>
            <option value="__action:showProductGroupModal">Nieuwe groep aanmaken...</option>
          </select>
        </div>
      </div>

      <vat-select
        ref="vatSelect"

        :product="product"
        :disabled="isDisabled"

        class="col-12 mt-3 col-lg-4 mt-lg-0"
      />
    </div>

    <KSeparator large />

    <div class="row form-group">
      <div class="col-12 col-lg-6">
        <label>Inkoopprijs</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i><font-awesome-icon :icon="['fad', 'euro-sign']" /></i>
            </span>
          </div>
          <input
            v-model.number.lazy="$v.product.cost_priceFormat.$model"
            :class="['form-control', {'is-invalid': $v.product.cost_priceFormat.$error}]"
            :disabled="isDisabled"

            type="number"
          >
        </div>
      </div>

      <div class="col-12 mt-3 col-lg-6 mt-lg-0">
        <label>Voorraad</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i><font-awesome-icon :icon="['fad', 'inventory']" /></i>
            </span>
          </div>

          <!-- When creating, stock can be changes -->
          <input
            v-if="action === 'CREATE'"

            v-model.number="$v.product.stock.$model"
            :class="['form-control', {'is-invalid': $v.product.stock.$error}]"
            :disabled="(isDisabled || action === 'EDIT')"

            type="number"
          >

          <!-- Else, just show stock -->
          <input
            v-else

            :value="product.stock"

            type="number"
            class="form-control"
            disabled
          >
        </div>
      </div>
    </div>

    <div class="row form-group">
      <div class="col-12 mt-3 col-lg-4 mt-lg-0">
        <label>Artikelnummer</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i><font-awesome-icon :icon="['fad', 'hashtag']" /></i>
            </span>
          </div>
          <input
            v-model="$v.product.article_number.$model"
            :class="['form-control', {'is-invalid': $v.product.article_number.$error}]"
            :disabled="isDisabled"

            type="text"
          >
        </div>
      </div>

      <div class="col-12 mt-3 col-lg-4 mt-lg-0">
        <label>Barcode (EAN)</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i><font-awesome-icon :icon="['fad', 'barcode']" /></i>
            </span>
          </div>
          <input
            v-model="$v.product.EAN_code.$model"
            :class="['form-control', {'is-invalid': $v.product.EAN_code.$error}]"
            :disabled="isDisabled"

            type="text"
          >
        </div>
      </div>

      <permission-group-select
        ref="permissionGroupSelect"

        :product="product"

        :disabled="disabled"
        :action="action"
      />
    </div>

    <KSeparator large />

    <div class="row form-group">
      <div class="col-12 col-md-3 offset-md-2">
        <div class="row">
          <div class="col-3 col-md-12 text-md-center">
            <span :class="['kt-switch', {'kt-switch--success': (product.amount_changeable === true)}]">
              <label>
                <input
                  v-model="product.amount_changeable"

                  :disabled="isDisabled"

                  type="checkbox"
                >
                <span />
              </label>
            </span>
          </div>
          <label class="col-9 col-md-12 text-md-center col-form-label">Aantal aanpasbaar</label>
        </div>
      </div>

      <div class="col-12 col-md-3 offset-md-2">
        <div class="row">
          <div class="col-3 col-md-12 text-md-center">
            <span :class="['kt-switch', {'kt-switch--success': (product.requires_customer === true)}]">
              <label>
                <input
                  v-model="product.requires_customer"

                  :disabled="isDisabled"

                  type="checkbox"
                >
                <span />
              </label>
            </span>
          </div>
          <label class="col-9 col-md-12 text-md-center col-form-label">Kan alleen gekocht worden wanneer klant bekend is</label>
        </div>
      </div>
    </div>

    <productGroupModal
      ref="productGroupModal"
      :group="modals.group.group"
    />

  </div>
</template>

<script>
// Components
import vatSelect from '@/components/products/components/vat.select';
import permissionGroupSelect from '@/components/products/components/permission-groups.select';
import productGroupModal from '@/components/products/groups/components/modal';

// Classes
import Product from '@/libs/classes/product';
import ProductGroup from '@/libs/classes/product_group';

// Validations
import { required, minValue } from 'vuelidate/lib/validators';
import { currency } from '@/libs/validators';

export default {
  components: {
    vatSelect,
    permissionGroupSelect,

    productGroupModal
  },
  props: {
    action: {
      type: String,
      default: 'CREATE'
    },

    product: {
      type: Product,
      default: null
    },

    groups: {
      type: Array,
      default: null
    },

    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      modals: {
        group: {
          group: null
        }
      }
    };
  },
  computed: {
    isDisabled () {
      if (this.disabled === true) {
        return true;
      }

      if (this.action === 'VIEW') {
        return true;
      }

      return false;
    },

    vatSorted () {
      if (this.vatCodes) {
        return this.vatCodes.slice().sort((a, b) => {
          if (b.code === 'no_vat') return -1;
          return b.value - a.value;
        });
      }
      return [];
    }
  },
  watch: {
    'product.group_guid' (newValue, oldValue) {
      if (newValue === '__action:showProductGroupModal') {
        this.modals.group.group = new ProductGroup();
        this.$refs.productGroupModal.$off('modal:submit');
        this.$refs.productGroupModal.show();
        this.$refs.productGroupModal.$on('modal:submit', (group) => {
          this.groups.unshift(group);
          this.product.group_guid = group._meta.guid;
        });

        this.$v.product.group_guid.$model = oldValue;
        this.$v.product.group_guid.$reset();
      }
    }
  },
  validations () {
    let validations = {
      product: {
        name: {
          required
        },
        group_guid: {
          required
        },
        vat_code: {
          required
        },

        cost_priceFormat: {
          currency
        },

        article_number: {

        },
        EAN_code: {

        }
      }
    };

    if (this.action === 'CREATE') {
      validations.product.stock = {
        minValue: minValue(0)
      };
    }

    return validations;
  },

  methods: {
    async validate () {
      const validationArray = [];
      validationArray.push(this.$v, this.$refs.vatSelect.$v);

      if (validationArray.length > 0) {
        validationArray.forEach(v => v && v.$touch && v.$touch());
        if (validationArray.filter(v => v && v.$invalid).length !== 0) return false;
      }

      return true;
    }
  }
};
</script>
