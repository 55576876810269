// Libraries
import { merge } from 'lodash';

// Classes
import Abstract from './abstract';
import Resource from './resource';

class PermissionGroup extends Abstract {
  constructor (guid = null) {
    super(guid);
    this.uri = '/workspace_permission_groups';

    let defaultObject = {
      name: null,
      granted_permissions: {}
    };

    merge(this, defaultObject);
  }

  // Methods
  hasPermission (permissionsArray, options = {}) {
    // Create resource
    const resource = new Resource().mergeResponse({
      permissions: this.granted_permissions
    });

    return resource.hasPermission(permissionsArray, options);
  }
};

export default PermissionGroup;
