<template>
  <!-- Loading -->
  <KContainer v-if="isMounted === false">
    <div class="row mt-3">
      <div class="col-12 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
        <div class="loading-container">
          <div class="loading-block">
            <div class="blockui ml-auto mr-auto w-100">
              <span>Gegevens ophalen...</span>
              <span class="kt-spinner kt-spinner--v2 kt-spinner--primary" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </KContainer>

  <!-- Finished loading -->
  <div
    v-else
    class="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor cs-store"
  >
    <div class="kt-grit__item">
      <subheader
        ref="subheader"
        :meta="meta"
      />
    </div>

    <div class="kt-grid__item">
      <KContainer>
        <router-view
          ref="routerView"
          :meta="meta"
          :cash_register="cashRegister"
        />
      </KContainer>
    </div>

    <div class="kt-grid__item">
      <shop-aside
        ref="aside"
        :meta="meta"
        :cash-register="cashRegister"
      />
    </div>

  </div>
</template>

<style lang="scss">
@import '@/assets/sass/shop/custom.scss';
</style>

<script>
// Libraries
import shopMixin from '@/mixins/shop';
import shopAsideConfig from '@/assets/sass/shop/aside.config.scss';

// Components
import subheader from './components/subheader';
import shopAside from './components/aside/index';

// Classes
import SalesOrder from '@/libs/classes/salesorder';

export default {
  components: {
    subheader,
    shopAside
  },
  mixins: [shopMixin],
  data () {
    return {
      isMounted: false,
      isMinimized: false,

      meta: {
        shop: {
          action: null
        }
      },

      cashRegister: null
    };
  },
  watch: {
    '$route' (route) {
      this.getRouteAction(route);
    }
  },

  async mounted () {
    const options = (typeof this.$route.params.options === 'object' ? this.$route.params.options : {});

    // Set cash register
    let cashRegister = await this.shop.getCashRegister(options);
    if (cashRegister === null) {
      this.shop.removeSession();
      return this.$router.push('/sales/shop');
    }
    this.$set(this, 'cashRegister', cashRegister);

    if (this.cashRegister.latest_opening === null) {
      try {
        this.cashRegister.latest_opening = await this.cashRegister.getLatestOpening(options);
      } catch (e) {
        console.error('Failed to fetch cash register opening', e);
        this.shop.removeSession();
        return this.$router.push('/sales/shop');
      }
    }

    if (this.cashRegister.latest_opening.open === false) {
      return this.$router.replace({
        path: `/sales/cash_registers/view/${this.cashRegister.guid}/actions`,
        query: {
          redirect: 'shop'
        }
      });
    }

    // Set session
    let session = this.shop.getSession();
    if (typeof session.sales_order === 'string' && session.sales_order.length) {
      // Get sales order
      try {
        // Create sales order with _meta, so the shop library doesn't create a new one
        this.shop.sales_order = new SalesOrder(session.sales_order);
        await this.shop.getSalesOrder();
      } catch (e) {
        this.shop.removeSalesOrder();
        console.error('Error fetching sales order', e);
      }
    }

    // Set customer, if given
    let customerGuid = this.$route.query.customer;
    if (typeof customerGuid === 'string' && customerGuid.length) {
      // Don't set the customer when the sales order has already been paid
      if (!this.shop.sales_order || this.shop.sales_order.paid !== true) {
        try {
          await this.shop.setCustomer(customerGuid);
        } catch (e) {
          // Show error
          console.error(e);

          // Try to remove current customer when the `setCustomer` function failed
          try {
            await this.shop.removeCustomer();
          } catch (e) {
            console.error(e);
          }
        }
      }

      const query = Object.assign({}, this.$route.query);
      delete query.customer;

      this.$router.replace({
        query: query
      });
    }

    // Set route

    // Check if sales order has been paid
    // Because then, we need to redirect to `/finish`
    if (this.shop.sales_order && this.shop.sales_order.paid === true && this.$route.name !== '/sales/shop/:cash_register/finish') {
      this.$router.replace({
        name: `/sales/shop/:cash_register/finish`
      });
    }

    // Else, just go to cart
    if (this.$route.name === '/sales/shop/:cash_register') {
      this.$router.replace({
        name: '/sales/shop/:cash_register/cart',
        params: this.$route.params
      });
    }

    this.$nextTick(() => {
      // Get route action
      this.getRouteAction(this.$route);

      // Check if has to minimize and add listener
      this.hasToMinimizeAside();
      window.addEventListener('resize', this.hasToMinimizeAside);

      this.isMounted = true;
    });
  },
  beforeDestroy () {
    // Remove resize listener
    window.removeEventListener('resize', this.hasToMinimizeAside);

    // Remove body classes again
    this.$eventhub.emit('caesium:menu:unminimize');
    DOMTokenList.prototype.remove.apply(document.body.classList, ['kt-aside--minimize-hover']);
  },

  methods: {
    getRouteAction (route) {
      let meta = Object.assign({}, route.meta);

      if (route.matched && route.matched.length > 0) {
        route.matched.forEach((route, idx) => {
          meta = Object.assign(meta, route.meta);
        });
      }

      if (meta.shop) {
        this.$set(this.meta, 'shop', meta.shop);
      }

      return meta;
    },

    hasToMinimizeAside () {
      let minimizeOnWidth = parseInt(shopAsideConfig['cs-shop-aside-config-width-minimizeAside'], 10);
      let clientWidth = Math.max(document.body.scrollWidth, document.documentElement.scrollWidth, document.body.offsetWidth, document.documentElement.offsetWidth, document.documentElement.clientWidth);

      if (clientWidth < minimizeOnWidth) {
        this.$eventhub.emit('caesium:menu:minimize');

        if (this.isMinimized === false) this.$eventhub.emit('caesium:menu:closeSubmenus');

        this.isMinimized = true;
      } else {
        this.$eventhub.emit('caesium:menu:unminimize');

        this.isMinimized = false;
      }
    }
  }
};
</script>
