<template>
  <div v-if="isMounted">

    <KSubheader
      :breadcrumbs="[{
        page: 'Apparatuur',
        route: '/equipment'
      }, {
        page: 'Tarieven',
        route: '/equipment/pricelists'
      }, {
        page: pricelist.name,
        route: null
      }]"
      title="Tarieven"
    />

    <KContainer>
      <div class="row">
        <div class="col-12 col-portlet-menu">
          <KPortlet>
            <KPortletBody>
              <div class="kt-widget kt-widget--general-1">
                <div class="kt-media kt-media--brand kt-media--md kt-media--circle">
                  <span class="kt-badge kt-badge--username kt-badge--lg kt-badge--dark kt-badge--bold">
                    <i><font-awesome-icon :icon="['fad', 'tags']" /></i>
                  </span>
                </div>
                <div class="kt-widget__wrapper">
                  <div class="kt-widget__label">
                    <a
                      href="#"
                      class="kt-widget__title"
                    >
                      {{ pricelist.name }}
                    </a>
                  </div>
                </div>
              </div>
            </KPortletBody>
            <div class="kt-portlet__separator" />
            <KPortletBody>
              <ul
                class="kt-nav kt-nav--bolder kt-nav--fit-ver kt-nav--v4"
                role="tablist"
              >
                <router-link
                  v-slot="{ href, route, navigate, isActive, isExactActive }"
                  to="settings"
                >
                  <li :class="['kt-nav__item', {'active': isActive}]">
                    <a
                      class="kt-nav__link"
                      role="tab"
                      @click.prevent="$router.push(href)"
                    >
                      <span class="kt-nav__link-icon">
                        <i>
                          <font-awesome-icon :icon="['fad', 'cogs']" />
                        </i>
                      </span>
                      <span class="kt-nav__link-text">Instellingen</span>
                    </a>
                  </li>
                </router-link>

                <router-link
                  v-slot="{ href, route, navigate, isActive, isExactActive }"
                  to="prices"
                >
                  <li :class="['kt-nav__item', {'active': isActive}]">
                    <a
                      class="kt-nav__link"
                      role="tab"
                      @click.prevent="$router.push(href)"
                    >
                      <span class="kt-nav__link-icon">
                        <i>
                          <font-awesome-icon :icon="['fad', 'euro-sign']" />
                        </i>
                      </span>
                      <span class="kt-nav__link-text">Prijzen</span>
                    </a>
                  </li>
                </router-link>
                <router-link
                  v-slot="{ href, route, navigate, isActive, isExactActive }"
                  to="equipment"
                >
                  <li :class="['kt-nav__item', {'active': isActive}]">
                    <a
                      class="kt-nav__link"
                      role="tab"
                      @click.prevent="$router.push(href)"
                    >
                      <span class="kt-nav__link-icon">
                        <i>
                          <font-awesome-icon :icon="['fad', 'link']" />
                        </i>
                      </span>
                      <span class="kt-nav__link-text">Apparatuur</span>
                    </a>
                  </li>
                </router-link>
              </ul>
            </KPortletBody>
          </KPortlet>
        </div>

        <div class="col-12 col-sm">
          <router-view
            :key="$route.fullPath"
            :pricelist.sync="pricelist"

            :meta="meta"
          />
        </div>
      </div>

    </KContainer>

  </div>
</template>

<script>
// Classes
import SessionPricelist from '@/libs/classes/session_pricelist';

export default {
  data () {
    return {
      isMounted: false,

      pricelist: null,

      meta: {
        VATCodes: []
      }
    };
  },

  async mounted () {
    try {
      await Promise.all([this.getSessionPricelist(), this.getVATCodes()]);
    } catch (e) {
      console.error('Session pricelist not found:', e);
      return;
    }

    this.$nextTick(() => {
      this.isMounted = true;
    });
  },

  methods: {
    async getSessionPricelist (options = {}) {
      this.$set(this, 'pricelist', null);
      let pricelist = await new SessionPricelist(this.$route.params.guid).get(options);
      await pricelist.populate('get', options);
      this.$set(this, 'pricelist', pricelist);
      return this.pricelist;
    },

    async getVATCodes () {
      this.$set(this.meta, 'VATCodes', []);
      let VATCodes = await this.$ws.get('v1', '/vats');
      this.$set(this.meta, 'VATCodes', VATCodes);
      return this.meta.VATCodes;
    }
  }
};
</script>
