<template>
  <div>
    <!-- No sessions added -->
    <KAlert
      v-if="!bundleProduct.sessions || bundleProduct.sessions.length === 0"
      variant="outline-info"
      class="text-center mb-0"
    >
      Er zijn nog geen sessies toegevoegd
    </KAlert>

    <div
      v-else
      class="sessions"
    >
      <session-line
        v-for="(session, idx) in bundleProduct.sessions"
        :ref="session.VUID"
        :key="session.VUID"
        :session="session"
        :first-row="(idx === 0)"

        :action="action"
        :disabled="isDisabled"

        :edit-session="editSession"
        :remove-session="removeSession"
      />
    </div>

    <!-- Only show on create, when editing the button is in the KPortlet of that page -->
    <template v-if="action === 'CREATE'">
      <KSeparator class="kt-separator--space-md" />

      <div class="row form-group">
        <div class="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
          <KButton
            :icon="['far', 'plus']"

            variant="primary"
            class="ml-auto mr-auto btn-block"

            @click.native.prevent="onClickAddSession"
          >
            Sessie toevoegen
          </KButton>
        </div>
      </div>
    </template>

    <modal-configure-session
      ref="modalConfigureSession"
    />

  </div>
</template>

<script>
// Classes
import BundleProduct from '@/libs/classes/bundle_product';

// Components
import modalConfigureSession from '@/components/bundles/modals/configure-session/index';
import sessionLine from '@/components/bundles/components/session.line';

export default {
  components: {
    modalConfigureSession,
    sessionLine
  },
  props: {
    action: {
      type: String,
      default: 'CREATE'
    },

    bundleProduct: {
      type: BundleProduct,
      default: null
    },

    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    isDisabled () {
      if (this.action === 'VIEW') {
        return true;
      }

      if (this.disabled === true) {
        return true;
      }

      return false;
    }
  },

  mounted () {
    // Listeners
    this.$eventhub.on('modals:bundles:configure-session:submit', this.onConfigureSessionSubmit);
  },
  beforeDestroy () {
    // Remove listeners
    this.$eventhub.off('modals:bundles:configure-session:submit', this.onConfigureSessionSubmit);
  },

  methods: {
    onClickAddSession (event) {
      this.$eventhub.emit('modals:bundles:configure-session:open');
    },

    onConfigureSessionSubmit (object = {}) {
      if (object && object.session) {
        // Check if existing session
        if (object.options && object.options.session) {
          let sessionsArray = Object.assign([], this.bundleProduct.sessions);
          let sessionIdx = sessionsArray.findIndex(s => s.guid === object.options.session.guid);
          if (sessionIdx !== -1) {
            sessionsArray[sessionIdx] = object.session;
            this.$set(this.bundleProduct, 'sessions', sessionsArray);
          }
        } else {
          this.bundleProduct.sessions.push(object.session);
        }
      }
    },

    editSession (session = null) {
      if (session) {
        this.$eventhub.emit('modals:bundles:configure-session:open', {
          session: session
        });
      }
    },
    removeSession (session = null) {
      if (session) {
        this.bundleProduct.sessions = this.bundleProduct.sessions.filter(s => s.guid !== session.guid);
      }
    }
  }
};
</script>
