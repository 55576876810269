<template>
  <KModal
    v-keydown="{'escape': close}"
    ref="modal"
    title="Klant identificeren"
    size="xl"
    centered
  >
    <template v-slot:default>
      <!-- Authentication options -->
      <authentication-options-row
        ref="authenticationOptionsRow"
        :on-customer-selected="onCustomerSelected"
      />

      <!-- Only show if customer is not known or options.method === 'SEARCH' -->
      <template v-if="(isCustomerKnown === false || options.method === 'SEARCH')">
        <!-- Search customer (table & search bar) -->
        <search-customer-row
          ref="searchCustomerRow"
          :on-customer-selected="onCustomerSelected"
        />
      </template>

      <!-- Only show if customer is known and options.method === 'IDENTIFY' -->
      <template v-if="(isCustomerKnown === true && options.method === 'IDENTIFY')">
        <!-- Authentication -->
        <authentication-row
          ref="authenticationRow"

          :on-customer-authenticated="onCustomerAuthenticated"
          :sales_order="salesOrder"

          class="mt-3"
        />
      </template>

    </template>

    <template v-slot:footer>
      <KButton
        variant="secondary"
        @click.native.prevent="close"
      >
        Annuleren
      </KButton>
    </template>
  </KModal>
</template>

<script>
// Classes
import Customer from '@/libs/classes/customer';
import SalesOrder from '@/libs/classes/salesorder';

// Components
import authenticationOptionsRow from '@/components/sales/shop/components/modals/identify-customer/components/authentication-options.row';
import searchCustomerRow from '@/components/sales/shop/components/modals/identify-customer/components/search-customer.row';
import authenticationRow from '@/components/sales/shop/components/modals/identify-customer/components/authentication.row';

export default {
  components: {
    authenticationOptionsRow,
    searchCustomerRow,
    authenticationRow
  },
  props: {
    salesOrder: {
      type: SalesOrder,
      default: null
    }
  },
  data () {
    return {
      onCustomerSelectedRan: false,
      onCustomerAuthenticatedRan: false,

      options: {}
    };
  },
  computed: {
    isCustomerKnown () {
      if (this.salesOrder && this.salesOrder !== null && this.salesOrder instanceof SalesOrder) {
        if (this.salesOrder.customer && this.salesOrder.customer !== null && this.salesOrder.customer instanceof Customer) {
          return true;
        }
      }

      return false;
    }
  },

  mounted () {
    // Listeners
    this.$eventhub.on('modals:shop:identify-customer:open', this.open);
    this.$eventhub.on('modals:shop:identify-customer:close', this.close);
  },
  beforeDestroy () {
    // Remove listeners
    this.$eventhub.off('modals:shop:identify-customer:open', this.open);
    this.$eventhub.off('modals:shop:identify-customer:close', this.close);
  },

  methods: {
    open (options = {}) {
      // Remove options
      this.$set(this, 'onCustomerSelectedRan', false);
      this.$set(this, 'onCustomerAuthenticatedRan', false);
      this.$set(this, 'options', {});

      // Set options, if defined
      if (typeof options === 'object') {
        this.$set(this, 'options', options);
      }

      // Open modal
      this.$refs.modal.show();
    },
    close () {
      this.$refs.modal.hide();
    },

    async onCustomerSelected (customer = null) {
      if (this.options.method !== 'IDENTIFY') {
        // Run onCustomerSelected if set
        if (typeof this.options.onCustomerSelected === 'function') {
          await this.options.onCustomerSelected(customer);
        }

        this.close();
      }
    },
    async onCustomerAuthenticated (customer = null) {
      if (this.onCustomerAuthenticatedRan === false) {
        this.onCustomerAuthenticatedRan = true;

        // Run onCustomerAuthenticated if set
        if (typeof this.options.onCustomerAuthenticated === 'function') {
          await this.options.onCustomerAuthenticated(customer);
        }
      }

      this.close();
    }
  }
};
</script>
