<template>
  <div class="row form-group">
    <div class="col-12 col-lg-4">
      <label>Voornaam <sup>Verplicht</sup></label>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">
            <i><font-awesome-icon :icon="['fad', 'text']" /></i>
          </span>
        </div>
        <input
          v-model.trim="$v.customer.firstname.$model"
          :placeholder="(placeholder ? 'Jan' : undefined)"
          :class="['form-control', {'is-invalid': $v.customer.firstname.$error}]"
          :disabled="disabled"

          type="text"
        >
      </div>
    </div>

    <div class="col-12 mt-3 col-lg-4 mt-lg-0">
      <label>Achternaam <sup>Verplicht</sup></label>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">
            <i><font-awesome-icon :icon="['fad', 'text']" /></i>
          </span>
        </div>
        <input
          v-model.trim="$v.customer.lastname.$model"
          :placeholder="(placeholder ? 'Janssen' : undefined)"
          :class="['form-control', {'is-invalid': $v.customer.lastname.$error}]"
          :disabled="disabled"

          type="text"
        >
      </div>
    </div>

    <div class="col-12 mt-3 col-lg-4 mt-lg-0">
      <label>Geslacht <sup>Verplicht</sup></label>
      <div class="input-group">
        <KButtonGroup class="btn-block">
          <KButton
            v-click="() => customer.gender = 'MAN'"
            :variant="(customer.gender === 'MAN' ? 'brand' : 'outline-secondary')"
            :disabled="disabled"
          >
            Man
          </KButton>
          <KButton
            v-click="() => customer.gender = 'WOMAN'"
            :variant="(customer.gender === 'WOMAN' ? 'brand' : 'outline-secondary')"
            :disabled="disabled"
          >
            Vrouw
          </KButton>
          <KButton
            v-click="() => customer.gender = 'HUMAN'"
            :variant="(customer.gender === 'HUMAN' ? 'brand' : 'outline-secondary')"
            :disabled="disabled"
          >
            Mens
          </KButton>
        </KButtonGroup>
      </div>
    </div>

  </div>
</template>

<script>
// Components
import Customer from '@/libs/classes/customer';

// Validators
import { required } from 'vuelidate/lib/validators';

export default {
  props: {
    customer: {
      type: Customer,
      default: null
    },

    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: Boolean,
      default: true
    }
  },
  validations: {
    customer: {
      firstname: {
        required
      },
      lastname: {
        required
      }
    }
  }
};
</script>

<style scoped>
.form-control.form-group--error {
  border: 1px solid red;
}
</style>
