<template>
  <div>
    <KSubheader
      :breadcrumbs="[{
        page: 'Statistieken',
        route: '/statistics'
      }, {
        page: 'Totalen',
        route: '/statistics/totals'
      }]"

      title="Totalen"
    />

    <KContainer>
      <!-- Portlet bar -->
      <KPortlet>
        <KPortletHead>
          <template v-slot:default>
            {{ $stores.activeStore.name }}
          </template>
          <template v-slot:toolbar>
            <KButton
              variant="secondary"
              tag="span"
              class="mr-2 d-print-none"

              @click.native.prevent="printPage"
            >
              <i class="kt-font-focus pr-0">
                <font-awesome-icon :icon="['fas', 'print']" />
              </i>
            </KButton>

            <datepicker
              ref="datepicker"

              @onDatePickerInput="onDatePickerInput"
            />
          </template>
        </KPortletHead>
      </KPortlet>

      <!-- Data -->
      <div class="row">
        <!-- Totals -->
        <div class="col-12 col-md-6">
          <totals-portlet
            ref="totalsPortlet"

            :totals-period="totalsPeriod"
          />
        </div>

        <!-- Payment methods -->
        <div class="col-12 col-md-6">
          <payment-methods-portlet
            ref="paymentMethodsPortlet"

            :totals-period="totalsPeriod"
          />
        </div>

        <!-- Overview -->
        <div class="col-12">
          <overview-portlet
            ref="overviewPortlet"

            :totals-period="totalsPeriod"
            :outstanding="outstanding"
          />
        </div>

        <!-- VAT -->
        <div class="col-12">
          <vat-portlet
            ref="vatPortlet"

            :vat="vat"
          />
        </div>
      </div>
    </KContainer>
  </div>
</template>

<script>
// Libraries
import moment from 'moment-timezone';

// Components
import Datepicker from '@/components/statistics/components/datepicker';
import totalsPortlet from '@/components/statistics/totals/totals.portlet';
import paymentMethodsPortlet from '@/components/statistics/totals/payment_methods.portlet';
import overviewPortlet from '@/components/statistics/totals/overview.portlet';
import vatPortlet from '@/components/statistics/totals/vat.portlet';

export default {
  components: {
    Datepicker,

    totalsPortlet,
    paymentMethodsPortlet,
    overviewPortlet,
    vatPortlet
  },
  data () {
    return {
      totalsPeriod: null,
      outstanding: null,
      vat: null
    };
  },

  mounted () {
    this.$nextTick(() => {
      // Set default date
      const dates = {
        start: moment().startOf('month').toDate(),
        end: moment().endOf('day').toDate()
      };

      // Check if query contains dates
      if (this.$route.query && (this.$route.query.start && this.$route.query.end)) {
        // Check if both dates are valid
        if (moment(this.$route.query.start, 'DD-MM-YYYY', true).isValid() && moment(this.$route.query.end, 'DD-MM-YYYY', true).isValid()) {
          dates.start = moment(this.$route.query.start, 'DD-MM-YYYY', true).toDate();
          dates.end = moment(this.$route.query.end, 'DD-MM-YYYY', true).toDate();
        }
      }

      // Set dates
      this.$refs.datepicker.onDatePickerInput({
        start: dates.start,
        end: dates.end,
        force: true
      });
    });
  },

  methods: {
    onDatePickerInput (dates) {
      if (dates.start && dates.end) {
        // Set query
        const query = {
          start: moment(dates.start).format('DD-MM-YYYY'),
          end: moment(dates.end).format('DD-MM-YYYY')
        };

        if (this.$route.query.start !== query.start || this.$route.query.end !== query.end) {
          this.$router.replace({
            query: {
              start: query.start,
              end: query.end
            }
          });
        }

        // Get new statistics
        this.getStatistics(dates.start, dates.end);
      }
    },

    async getStatistics (from, to) {
      // Convert strict to moment
      from = moment(from, true);
      to = moment(to, true);

      // Check if dates are valid
      if (from.isValid() && to.isValid()) {
        await Promise.all([
          this.getTotalsPeriod(from, to),
          this.getOutstanding(from, to),
          this.getVAT(from, to)
        ]);
      }
    },

    async getTotalsPeriod (from, to) {
      // Set loading to refs
      if (this.$refs.totalsPortlet) this.$refs.totalsPortlet.isLoading = true;
      if (this.$refs.paymentMethodsPortlet) this.$refs.paymentMethodsPortlet.isLoading = true;
      if (this.$refs.overviewPortlet) this.$refs.overviewPortlet.isLoading = true;

      const response = await this.$ws.get('v1', `/stats/totals-period/${from.startOf('day').valueOf()}/${to.endOf('day').valueOf()}`);
      if (response && response[0]) {
        this.totalsPeriod = response[0];
      } else {
        this.totalsPeriod = null;
      }

      // Set loading to refs
      if (this.$refs.totalsPortlet) this.$refs.totalsPortlet.isLoading = false;
      if (this.$refs.paymentMethodsPortlet) this.$refs.paymentMethodsPortlet.isLoading = false;
      if (this.$refs.overviewPortlet) this.$refs.overviewPortlet.isLoading = false;

      return this.totalsPeriod;
    },
    async getOutstanding (from, to) {
      // Set loading to refs
      if (this.$refs.overviewPortlet) this.$refs.overviewPortlet.isLoadingOutstanding = true;

      // We've to get both dates
      const [fromResponse, toResponse] = await Promise.all([
        this.$ws.get('v1', `/stats/currently-outstanding/0/${from.endOf('day').valueOf()}`),
        this.$ws.get('v1', `/stats/currently-outstanding/0/${to.endOf('day').valueOf()}`)
      ]);

      // Set data
      this.outstanding = {
        from: fromResponse,
        to: toResponse
      };

      // Set loading to refs
      if (this.$refs.overviewPortlet) this.$refs.overviewPortlet.isLoadingOutstanding = false;

      return this.outstanding;
    },
    async getVAT (from, to) {
      // Set loading to refs
      if (this.$refs.vatPortlet) this.$refs.vatPortlet.isLoading = true;

      const response = await this.$ws.get('v1', `/stats/vat/${from.startOf('day').valueOf()}/${to.endOf('day').valueOf()}/year`);
      if (response && response[0]) {
        this.vat = response[0];
      } else {
        this.vat = null;
      }

      // Set loading to refs
      if (this.$refs.vatPortlet) this.$refs.vatPortlet.isLoading = false;

      return response;
    },

    printPage () {
      window.print();
    }
  }
};
</script>
