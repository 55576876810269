// Libraries
import { merge } from 'lodash';
import WS, { apiWS } from '@/libs/ws';
import eventhub from '@/libs/eventhub';
import { ApiError } from '@/libs/error';
import Logger from '@/libs/logger';

const log = new Logger('ws.device.js');

export default class WSDevice extends WS {
  // Static
  static sockets = [];

  static createSocket (device = null) {
    if (!device.local_ip) {
      throw new Error(`Device ${device.serial} has no local ip`);
    }

    let socketConnection = WSDevice.sockets.find(s => s.master && s.master.guid === device.guid);
    if (typeof socketConnection === typeof undefined) {
      const wsHost = device.local_ip.replace(/\./g, '-');
      socketConnection = new WSDevice('wss://' + wsHost + '.dns.caesium.io:8011', device);
      socketConnection.initConnection();
      WSDevice.sockets.push(socketConnection);
    }
    return socketConnection;
  }

  static disconnectSockets () {
    // End all socket connections
    WSDevice.sockets.forEach((socket) => {
      socket.online = null;
      if (socket.connection) {
        socket.connection.end();
      }
    });

    // Clear sockets
    WSDevice.sockets = [];

    return WSDevice.sockets;
  }

  // Class
  constructor (hostname, device = {}, options = {}) {
    super(hostname, options);

    this.authPromise = null;
    this.master = device;
  }

  // Methods
  initConnection (options = {}) {
    this.deviceSocket = true;
    super.initConnection(merge({
      manual: true
    }, options));
  }

  handleState (online, event = null) {
    if (this.online !== online) {
      super.handleState(online, event);

      // Clear authorization when device is offline
      if (online === false) {
        this.device_authverified = false;
        this.authPromise = null;
      }

      eventhub.emit(`sockets:masters:state`, {
        master_guid: this.master.guid,
        state: online
      });
    }
  }

  async isOnline () {
    if (this.master && this.online === null) {
      await this.getDeviceToken();
    }

    return super.isOnline();
  }

  async getDeviceToken () {
    if (this.authPromise) {
      return this.authPromise;
    }

    this.authPromise = new Promise(async (resolve, reject) => {
      try {
        // 1) Request token from server
        const { token } = await apiWS.post('v1', '/devices/' + this.master.guid + '/token/request');
        // 2) Send token to master to verify
        let verified = null;
        try {
          verified = await this.device('auth', { token });
        } catch (e) {
          verified = null;
        }

        if (!verified || verified.verified !== true) {
          throw new Error('Could not authorize with device');
        }
        this.device_authverified = true;
        resolve();
      } catch (e) {
        reject(e);
      }
    });
    return this.authPromise;
  }

  async deviceCheckAuthorized () {
    if (!this.device_authverified) {
      try {
        await this.getDeviceToken();
      } catch (e) {
        console.error(e);
        throw e;
      }
    }
    return true;
  }

  async device (action = '', payload = {}) {
    if (this.online === false) {
      throw new ApiError({
        type: 'DEVICE_STATE_OFFLINE'
      }).apierror;
    }

    if (action !== 'auth') {
      await this.deviceCheckAuthorized();
    }

    const requestId = this.generateRequestId();
    return new Promise(async (resolve, reject) => {
      try {
        log.debug('Send action to device:', action, payload);
        await this.write({ requestId, action, payload });

        if (!this.pendingRequests) {
          this.pendingRequests = {};
        }
        this.pendingRequests[requestId] = { resolve, reject };
      } catch (e) {
        reject(e);
      }
    }).catch(async (err) => {
      if (err.error === 'NotAuthorized') {
        await this.getDeviceToken();
        return this.device(action, payload);
      }
      log.error('Websocket device request failed:', action, err, '\npayload:', payload);
      throw err;
    }).finally(() => {
      delete this.pendingRequests[requestId];
    });
  }
}
