import { merge } from 'lodash';

import { apiWS as ws } from '@/libs/ws';

class Abstract {
  constructor (guid = null) {
    let defaultObject = {
      _meta: null,
      uri: null,
      guid: guid
    };

    merge(this, defaultObject);
  }

  // Methods
  formatBody (type = 'get', options = {}) {
    let body = JSON.parse(JSON.stringify(this));
    if (typeof body._meta !== typeof undefined) delete body._meta;
    if (typeof body.uri !== typeof undefined) delete body.uri;
    if (typeof body.guid !== typeof undefined) delete body.guid;

    return body;
  }

  mergeResponse (response = {}) {
    // Don't merge uri
    delete response.uri;

    merge(this, response);
    if (this._meta && this._meta.guid) this.guid = this._meta.guid;
    return this;
  }

  async get (options = {}) {
    let response = await ws.get('v1', `${this.uri}/${this.guid}`, options);
    this.mergeResponse(response);
    return this;
  }

  async getAll (options = {}) {
    const documents = await ws.get('v1', `${this.uri}`, options);
    return documents.map((document) => {
      const instance = new this.constructor(document._meta.guid);
      instance.mergeResponse(document);
      return instance;
    });
  }

  async create (body = {}, options = {}) {
    options.body = body;
    let response = await ws.post('v1', this.uri, options);
    this.mergeResponse(response);

    return this;
  }

  async update (mutations = [], options = {}) {
    options.body = mutations;
    let response = await ws.patch('v1', `${this.uri}/${this.guid}`, options);
    this.mergeResponse(response);

    return this;
  }

  async remove (options = {}) {
    let response = await ws.delete('v1', `${this.uri}/${this.guid}`, options);
    this.mergeResponse(response);

    return this;
  }

  async populate (type = 'get', options = {}) {
    return this;
  }
};

export default Abstract;
