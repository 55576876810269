<template>
  <div>

    <template v-if="pricelist.type === 'session_products'">
      <div class="row form-group">
        <div class="col-12 col-md-2">
          <KButton
            v-click="() => syncProductsOfDay()"

            :icon="['fad', 'sync']"

            variant="focus"
            class="btn-block"
          >
            Sync
          </KButton>
        </div>

        <div class="col-12 mt-3 col-md-10 mt-md-0">
          <KButtonGroup class="d-none d-md-inline-flex btn-block">
            <KButton
              v-click="() => days.selected = 1"
              :variant="(days.selected === 1 ? 'primary' : 'outline-primary')"
            >
              Maandag
            </KButton>

            <KButton
              v-click="() => days.selected = 2"
              :variant="(days.selected === 2 ? 'primary' : 'outline-primary')"
            >
              Dinsdag
            </KButton>

            <KButton
              v-click="() => days.selected = 3"
              :variant="(days.selected === 3 ? 'primary' : 'outline-primary')"
            >
              Woensdag
            </KButton>

            <KButton
              v-click="() => days.selected = 4"
              :variant="(days.selected === 4 ? 'primary' : 'outline-primary')"
            >
              Donderdag
            </KButton>

            <KButton
              v-click="() => days.selected = 5"
              :variant="(days.selected === 5 ? 'primary' : 'outline-primary')"
            >
              Vrijdag
            </KButton>

            <KButton
              v-click="() => days.selected = 6"
              :variant="(days.selected === 6 ? 'primary' : 'outline-primary')"
            >
              Zaterdag
            </KButton>

            <KButton
              v-click="() => days.selected = 0"
              :variant="(days.selected === 0 ? 'primary' : 'outline-primary')"
            >
              Zondag
            </KButton>
          </KButtonGroup>

          <KButtonGroup class="d-md-none btn-block">
            <KButton
              v-click="() => days.selected = 1"
              :variant="(days.selected === 1 ? 'primary' : 'outline-primary')"
            >
              Ma
            </KButton>

            <KButton
              v-click="() => days.selected = 2"
              :variant="(days.selected === 2 ? 'primary' : 'outline-primary')"
            >
              Di
            </KButton>

            <KButton
              v-click="() => days.selected = 3"
              :variant="(days.selected === 3 ? 'primary' : 'outline-primary')"
            >
              Wo
            </KButton>

            <KButton
              v-click="() => days.selected = 4"
              :variant="(days.selected === 4 ? 'primary' : 'outline-primary')"
            >
              Do
            </KButton>

            <KButton
              v-click="() => days.selected = 5"
              :variant="(days.selected === 5 ? 'primary' : 'outline-primary')"
            >
              Vr
            </KButton>

            <KButton
              v-click="() => days.selected = 6"
              :variant="(days.selected === 6 ? 'primary' : 'outline-primary')"
            >
              Za
            </KButton>

            <KButton
              v-click="() => days.selected = 0"
              :variant="(days.selected === 0 ? 'primary' : 'outline-primary')"
            >
              Zo
            </KButton>
          </KButtonGroup>
        </div>
      </div>

      <KSeparator large />
    </template>

    <div class="row form-group">
      <div class="col-12">
        <label>Producten</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i><font-awesome-icon :icon="['fad', 'search']" /></i>
            </span>
          </div>
          <v-select
            ref="searchProducts"

            v-model="search.product"
            :options="search.products"
            :filterable="false"

            class="form-control"
            label="name"

            @search="fetchOptionsDebounced"
            @search:focus="fetchOptions"
          >
            <template
              slot="option"
              slot-scope="option"
            >
              <span>{{ option.name }}</span>
            </template>

            <template
              slot="spinner"
              slot-scope="spinner"
            >
              <div
                v-if="spinner.loading"
                class=" kt-spinner kt-spinner--v2 kt-spinner--primary"
              />
            </template>
          </v-select>
        </div>
      </div>
    </div>

    <KSeparator
      v-if="prices.length > 0"
      large
    />

    <div class="products">
      <product-price
        v-for="(price, idx) in sortPrices"

        :ref="price.VUID"
        :key="price.VUID"
        :price="price"
        :first-row="idx === 0"
        :last-row="idx === (pricelist.prices.length - 1)"

        :remove-price="removePrice"
      />
    </div>

  </div>
</template>

<script>
// Libraries
import { cloneDeep, merge } from 'lodash';

// Classes
import Pricelist from '@/libs/classes/pricelist';
import SessionPricelist from '@/libs/classes/session_pricelist';

import PricelistPrice from '@/libs/classes/pricelist.price';
import SessionPricelistPrice from '@/libs/classes/session_pricelist.price';

import Product from '@/libs/classes/product';
import SessionProduct from '@/libs/classes/session_product';

// Components
import productPrice from './product.price';

import vSelect from 'vue-select';
import '@/assets/sass/vue-select/vue-select.scss';

export default {
  components: {
    productPrice,

    vSelect
  },
  props: {
    action: {
      type: String,
      default: 'CREATE'
    },

    pricelist: {
      type: [Pricelist, SessionPricelist],
      default: null
    },

    VATCodes: {
      type: Array,
      default: null
    },

    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: Boolean,
      default: true
    }
  },
  data () {
    const returnObject = {
      search: {
        product: null,
        products: [],

        timeout: null
      }
    };

    if (this.pricelist.type === 'session_products') {
      returnObject.days = {
        selected: 1
      };
    }

    return returnObject;
  },
  computed: {
    sortPrices () {
      if (this.pricelist.type === 'products') return this.pricelist.prices;
      if (this.pricelist.type === 'session_products') {
        return this.prices.slice().sort((a, b) => {
          return a.product.duration - b.product.duration;
        });
      }

      return [];
    },

    prices () {
      if (this.pricelist.type === 'products') return this.pricelist.prices;
      if (this.pricelist.type === 'session_products') return this.pricelist.prices.filter(p => p.day === this.days.selected);

      return [];
    }
  },
  watch: {
    'search.product' (product, oldProduct) {
      if (product !== null) {
        if ((this.pricelist.type === 'products' && product instanceof Product) || (this.pricelist.type === 'session_products' && product instanceof SessionProduct)) {
          let type = null;
          if (this.pricelist.type === 'products') type = 'product';
          else if (this.pricelist.type === 'session_products') type = 'session_product';

          let price = {
            type: type,
            product_name: product.name,
            product_guid: product._meta.guid,
            product: product,
            price: null
          };

          if (price.type === 'session_product') {
            price.day = this.days.selected;
            if (this.VATCodes.some(c => c.code === this.pricelist.vat_code) === true) price.product.price.vat_percentage = this.VATCodes.find(c => c.code === this.pricelist.vat_code).value;
          }

          if (type === 'product') price = new PricelistPrice().mergeResponse(price);
          else if (type === 'session_product') price = new SessionPricelistPrice().mergeResponse(price);

          this.pricelist.prices.unshift(price);

          this.search.product = null;
          this.$nextTick(() => {
            if (typeof this.$refs[price.VUID] !== typeof undefined) {
              this.$refs[price.VUID][0].focus();
            }
          });
        }
      }
    }
  },
  methods: {
    async syncProductsOfDay (day = this.days.selected) {
      let valid = await this.validate();
      if (valid) {
        const productsOfDay = this.pricelist.prices.filter(p => p.day === this.days.selected);
        const prices = [];

        productsOfDay.forEach((row, idx) => {
          for (let day = 0; day <= 6; day++) {
            prices.push(merge(cloneDeep(row), {
              day: day
            }));
          }
        });

        this.$set(this.pricelist, 'prices', prices);
      }
    },

    async validate () {
      const validationArray = [];

      // Validate prices
      this.prices.forEach((row, idx) => {
        if (typeof this.$refs[row.VUID] !== typeof undefined) {
          if (Array.isArray(this.$refs[row.VUID])) validationArray.push(this.$refs[row.VUID][0].$v);
          else validationArray.push(this.$refs[row.VUID][0].$v);
        }
      });

      // Execute validations
      if (validationArray.length > 0) {
        validationArray.forEach(v => v && v.$touch && v.$touch());
        if (validationArray.filter(v => v && v.$invalid).length !== 0) return false;
      }

      return true;
    },

    removePrice (guid) {
      if (this.pricelist.type === 'products') this.pricelist.prices = this.pricelist.prices.filter(p => p.product._meta.guid !== guid);
      else if (this.pricelist.type === 'session_products') this.pricelist.prices = this.pricelist.prices.filter(p => (p.product._meta.guid === guid && p.day === this.days.selected) === false);
    },

    async searchProducts (search) {
      // Add wildcard
      search = this.$format.addWildcardPerWord(search);

      // Configure options
      const options = {
        query: Object.assign({}, {
          q: search,
          limit: 5,
          ignore_guids: this.pricelist.prices.map(p => p.product && p.product._meta && p.product._meta.guid, []).join(',')
        })
      };

      // Request
      let response = null;
      if (this.pricelist.type === 'products') response = await this.$ws.get('v1', '/products', options);
      else if (this.pricelist.type === 'session_products') {
        response = await this.$ws.get('v1', '/session_products', merge(options, {
          query: {
            sort: 'name|asc'
          }
        }));
      }

      // Set data
      const data = [];
      if (response !== null) {
        if (response && Array.isArray(response)) {
          response.forEach((row, idx) => {
            if (this.pricelist.type === 'products') {
              response[idx] = new Product().mergeResponse(response[idx]);

              if (this.pricelist.prices.some(p => p.product && p.product._meta && p.product._meta.guid === response[idx]._meta.guid) === false) {
                data.push(response[idx]);
              }
            } else if (this.pricelist.type === 'session_products') {
              response[idx] = new SessionProduct().mergeResponse(response[idx]);

              if (this.pricelist.prices.some(p => p.product && p.product._meta && p.product._meta.guid === response[idx]._meta.guid && p.day === this.days.selected) === false) {
                data.push(response[idx]);
              }
            }
          });
        }
      }

      return data;
    },

    async fetchOptions (search) {
      const loading = this.$refs['searchProducts'].toggleLoading;
      loading(true);
      clearTimeout(this.search.timeout);
      this.$set(this.search, 'products', []);
      let data = await this.searchProducts(search);
      this.search.products = data;
      loading(false);
    },

    fetchOptionsDebounced (search) {
      const loading = this.$refs['searchProducts'].toggleLoading;
      clearTimeout(this.search.timeout);
      loading(true);
      this.search.timeout = setTimeout(async () => {
        await this.fetchOptions(search);
      }, 600);
    }
  }
};
</script>
