<template>
  <b-dropdown
    ref="dropdown"
    :menu-class="menuClass"

    right
    lazy
    no-caret
  >
    <template v-slot:button-content>
      <i class="kt-font-focus pr-0 pr-md-2">
        <font-awesome-icon :icon="['fas', 'calendar-alt']" />
      </i>

      <span :class="[{'d-none d-md-inline': mobileIconOnly === true}]">
        <template v-if="moment(datePicker.range.start).isSame(datePicker.range.end)">{{ moment(datePicker.range.start).format("DD MMMM YYYY") }}</template>
        <template v-else>{{ moment(datePicker.range.start).format("DD MMMM YYYY") }} - {{ moment(datePicker.range.end).format("DD MMMM YYYY") }}</template>
      </span>
    </template>

    <template v-slot:default>
      <div class="row ml-0 mr-0">
        <!-- Datepicker -->
        <div class="col-12 col-md-8 border-right">
          <date-picker
            ref="rangeDatePicker"

            :value="datePicker.range"
            :min-date="datePicker.minDate"
            :max-date="datePicker.maxDate"
            :theme="datePicker.theme"
            :columns="$screens({ default: 1, lg: 2 })"
            :input-props="{
              class: 'no-border'
            }"

            mode="range"
            is-inline
            is-expanded
            style="border:none; margin-top:auto; margin-bottom:auto;"

            @input="onDatePickerInput"
          />
        </div>

        <!-- Quick buttons -->
        <div class="col-12 col-md-4 pt-3 pb-3">
          <!-- Today button -->
          <KButton
            variant="outline-focus"
            class="btn-block"
            @click.native.prevent="selectCustomDate('today')"
          >
            Vandaag
          </KButton>

          <!-- Yesterday button -->
          <KButton
            variant="outline-focus"
            class="btn-block"
            @click.native.prevent="selectCustomDate('yesterday')"
          >
            Gisteren
          </KButton>

          <!-- This week button -->
          <KButton
            variant="outline-focus"
            class="btn-block"
            @click.native.prevent="selectCustomDate('this_week')"
          >
            Deze week
          </KButton>

          <!-- Last week button -->
          <KButton
            variant="outline-focus"
            class="btn-block"
            @click.native.prevent="selectCustomDate('last_week')"
          >
            Vorige week
          </KButton>

          <!-- This month button -->
          <KButton
            variant="outline-focus"
            class="btn-block"
            @click.native.prevent="selectCustomDate('this_month')"
          >
            Deze maand
          </KButton>

          <!-- Last month button -->
          <KButton
            variant="outline-focus"
            class="btn-block"
            @click.native.prevent="selectCustomDate('last_month')"
          >
            Vorige maand
          </KButton>
        </div>
      </div>
    </template>
  </b-dropdown>
</template>

<script>
// Libraries
import moment from 'moment-timezone';

// Components
import { BDropdown } from 'bootstrap-vue';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';

export default {
  components: {
    BDropdown,
    DatePicker
  },
  props: {
    mobileIconOnly: {
      type: Boolean,
      default: false
    },
    menuClass: {
      type: [String, Array],
      default: 'dropdown-menu-xxl dropdown-menu-fit dropdown-menu-datepicker'
    }
  },

  data () {
    return {
      moment,

      datePicker: {
        minDate: moment().set('year', 2018).startOf('year').toDate(),
        maxDate: new Date(),
        range: {
          start: moment().startOf('month').toDate(),
          end: moment().startOf('day').toDate()
        },
        theme: {
          // Calendar container
          container: {
            light: ''
          },
          // Navigation popover container
          navPopoverContainer: {
            light: 'vc-rounded-lg vc-text-sm vc-font-semibold vc-text-white vc-border vc-p-1 vc-shadow kt-bg-brand'
          },
          navTitle: {
            light: 'vc-text-{color}-100 vc-font-bold vc-leading-snug vc-px-2 vc-py-1 vc-rounded vc-border-2 vc-border-transparent hover:vc-bg-pink focus:vc-border-pink'
          },
          navArrows: {
            light: 'vc-leading-snug vc-rounded vc-border-2 vc-border-transparent hover:vc-bg-pink focus:vc-border-pink'
          },
          navCell: {
            light: 'vc-w-12 vc-font-semibold vc-cursor-pointer vc-text-center vc-leading-snug vc-py-1 vc-rounded vc-border-2 vc-border-transparent hover:vc-bg-pink hover:vc-shadow-inner hover:vc-text-white focus:vc-border-pink'
          },
          navCellActive: {
            light: ''
          },
          // Background when picking
          bgLow: {
            light: 'bg-low__light'
          },
          // Background between dates
          bgAccentLow: {
            light: 'bg-accent-low__light'
          },
          // Background selected dates (start & end)
          bgAccentHigh: {
            light: 'bg-accent-high__light'
          },
          // Styling of selected dates
          contentAccent: {
            light: 'font-weight-bold vc-text-white'
          }
        }
      }
    };
  },

  methods: {
    onDatePickerInput (dates) {
      if (dates && dates.start instanceof Date && dates.end instanceof Date) {
        // Check if start and/or end dates changed
        if (dates.force === true || ((this.datePicker.range.start instanceof Date === false || dates.start.getTime() !== this.datePicker.range.start.getTime()) || (this.datePicker.range.end instanceof Date === false || dates.end.getTime() !== this.datePicker.range.end.getTime()))) {
          // Set dates
          this.datePicker.range.start = dates.start;
          this.datePicker.range.end = dates.end;

          // Emit
          this.$emit('onDatePickerInput', this.datePicker.range);

          // Re-draw dropdown
          if (this.$refs.dropdown) this.$refs.dropdown.hide();
        }
      }
    },

    selectCustomDate (action) {
      let start = null;
      let end = null;

      // Set start & end, based on value
      switch (action) {
        case 'today':
          start = moment();
          end = moment();
          break;
        case 'yesterday':
          start = moment().subtract(1, 'days');
          end = moment().subtract(1, 'days');
          break;
        case 'this_week':
          start = moment().startOf('isoWeek');
          end = moment();
          break;
        case 'last_week':
          start = moment().subtract(1, 'weeks').startOf('isoWeek');
          end = moment().subtract(1, 'weeks').endOf('isoWeek');
          break;
        case 'this_month':
          start = moment().startOf('month');
          end = moment();
          break;
        case 'last_month':
          start = moment().subtract(1, 'months').startOf('month');
          end = moment().subtract(1, 'months').endOf('month');
          break;
      }

      // Set datepicker via datepicker input
      if (start !== null && end !== null) {
        this.onDatePickerInput({
          start: start.toDate(),
          end: end.toDate()
        });
      }

      return {
        action: action,
        start: this.datePicker.range.start,
        end: this.datePicker.range.end
      };
    }
  }
};
</script>
