<template>
  <b-modal
    ref="modal"
    size="xl"
    centered
  >
    <template v-slot:modal-header>
      <h5 class="modal-title">
        {{ equipment.name }}

        <span style="font-weight: 300; font-size: 1.1rem;">
          {{ equipment.room }}
        </span>
      </h5>
      <button
        type="button"
        aria-label="Close"
        class="close"
        @click.prevent="hide"
      >
        ×
      </button>
    </template>

    <template v-slot:default>
      <!-- Equipment & Customer row -->
      <div class="row border-bottom pb-3">
        <!-- Status equipment -->
        <div class="col-12 pb-3 col-lg-8 pb-lg-0">
          <equipment-row
            ref="equipmentRow"
            :equipment="equipment"
          />
        </div>

        <!-- Customer in session -->
        <div class="col-12 pt-3 col-lg-4 pt-lg-0 mb-auto mt-auto">
          <customer-row
            v-if="equipment && equipment.inSession === true"
            ref="customerRow"
            :equipment="equipment"
          />
        </div>
      </div>

      <!-- Session queue -->
      <session-queue
        ref="sessionQueue"
        :equipment="equipment"
      />

      <!-- Session summary & actions -->
      <template v-if="equipment.inSession === true">
        <session-summary
          ref="sessionSummary"
          :equipment="equipment"
        />

        <session-actions
          ref="sessionActions"
          :equipment="equipment"
        />
      </template>

      <!-- Modal -->
      <edit-prerun-time-modal
        ref="editPrerunTimeModal"
      />
    </template>

    <template v-slot:modal-footer>
      <KButton
        variant="focus"
        class="mr-auto"
        @click.native.prevent="configureSessionButton"
      >
        Sessie reserveren
      </KButton>

      <KButton
        variant="secondary"
        @click.native.prevent="hide"
      >
        Sluiten
      </KButton>
    </template>
  </b-modal>
</template>

<script>
// Classes
import Equipment from '@/libs/classes/equipment';

// Mixins
import Modal from '@/mixins/modal';

// Components
import equipmentRow from '@/components/equipment/modals/components/equipment.row';
import customerRow from '@/components/equipment/modals/components/customer.row';

import sessionQueue from '@/components/equipment/modals/view-session/components/session-queue';
import sessionSummary from '@/components/equipment/modals/view-session/components/session-summary';
import sessionActions from '@/components/equipment/modals/view-session/components/session-actions';

import editPrerunTimeModal from '@/components/equipment/modals/edit-prerun-time/index';

export default {
  components: {
    // Rows
    equipmentRow,
    customerRow,

    sessionQueue,
    sessionSummary,
    sessionActions,

    // Modals
    editPrerunTimeModal
  },
  mixins: [Modal],
  data () {
    return {
      options: null,

      equipment: null
    };
  },

  mounted () {
    // Listeners
    this.$eventhub.on('modals:equipment:view-session:open', this.open);
    this.$eventhub.on('modals:equipment:view-session:close', this.hide);
  },
  beforeDestroy () {
    // Remove listeners
    this.$eventhub.off('modals:equipment:view-session:open', this.open);
    this.$eventhub.off('modals:equipment:view-session:close', this.hide);
  },

  methods: {
    async open (options = {}) {
      this.$set(this, 'options', options);

      if (typeof this.options === 'object') {
        if (this.options.equipment && this.options.equipment instanceof Equipment) {
          this.$set(this, 'equipment', this.options.equipment);

          // Show modal
          Modal.methods.show.call(this);
        }
      }
    },

    configureSessionButton () {
      this.hide();

      this.$eventhub.emit('modals:equipment:configure-session:open', {
        equipment: this.equipment
      });
    }
  }
};
</script>
