// Classes
import Abstract from './abstract';
import BundleProductSession from './bundle_product.session';
import BundleProductProduct from './bundle_product.product';

import Product from './product';
import EquipmentGroup from './equipment_group';

// Libraries
import { merge } from 'lodash';
import { apiWS as ws } from '@/libs/ws';
import format from '@/libs/format';
import calculate from '@/libs/calculate';

class BundleProduct extends Abstract {
  constructor (guid = null) {
    super(guid);
    this.uri = '/bundle_products';

    let defaultObject = {
      name: null,
      type: 'bundle',
      active: true,

      bundle_limit: {
        limited: false,
        type: 'months',
        value: 1
      },

      article_number: null,
      EAN_code: null,

      cost_price: null,
      price: {
        price: null,
        vat_code: null,
        vat_percentage: null
      },

      requires_customer: true,
      amount_changeable: true,

      minimum_permission_group_level: 0,

      products: [],
      sessions: []
    };

    merge(this, defaultObject);
  }

  // Methods
  mergeResponse (response = {}) {
    super.mergeResponse(response);

    // Set price to price_excl_vat
    if (this.price && typeof this.price.price_excl_vat === 'number') this.price.price = this.price.price_excl_vat;

    // Set sessions to new response instead of merging
    if (response.sessions && Array.isArray(response.sessions)) this.sessions = response.sessions;

    // Set products to new response instead of merging
    if (response.products && Array.isArray(response.products)) this.products = response.products;

    // Set bundle limit
    this.bundle_limit.limited = true;
    if (this.bundle_limit.value === 0) {
      this.bundle_limit.limited = false;
    }

    return this;
  }

  formatBody (type = 'get', options = {}) {
    let body = super.formatBody(type, options);

    // Remove vat_percentage
    delete body.price.vat_percentage;

    // Format sessions
    if (this.sessions && this.sessions.length > 0) {
      body.sessions = this.sessions.map(session => {
        return session.formatBody(type, {});
      }, []);
    }

    // Format products
    if (this.products && this.products.length > 0) {
      body.products = this.products.map(product => {
        return product.formatBody(type, {});
      }, []);
    }

    // Remove limited from bundle limit
    if (body.bundle_limit.limited === false) {
      body.bundle_limit.value = 0;
    }
    delete body.bundle_limit.limited;

    return body;
  }

  async populate (type = 'get', options = {}) {
    // Populate sessions
    if (this.sessions.length) {
      let equipment_groups = await ws.get('v1', '/equipment_groups', {
        query: {
          'filter[_meta.guid]': this.sessions.map(p => p.equipment_group_guids, []).join(',')
        }
      })
      equipment_groups = equipment_groups.map((group) => new EquipmentGroup().mergeResponse(group));

      this.sessions.forEach((session, idx) => {
        this.sessions[idx] = new BundleProductSession().mergeResponse(session);

        // Gather all equipment groups
        session.equipment_group_guids.forEach((group_guid) => {
          let group = equipment_groups.find(p => p.guid === group_guid);
          if (typeof group !== typeof undefined) {
            this.sessions[idx].equipment_groups.push(group);
          }
        });

        // Random guid
        this.sessions[idx].guid = Math.random();
      });
    }

    // Populate products
    if (this.products.length) {
      let products = await ws.get('v1', '/products', {
        query: {
          'filter[_meta.guid]': this.products.map(p => p.product_guid, []).join(',')
        }
      });
      products = products.map((product) => new Product().mergeResponse(product));

      this.products.forEach((row, idx) => {
        let product = products.find(p => p.guid === row.product_guid);
        if (typeof product !== typeof undefined) {
          row.product = new Product().mergeResponse(product);
        }
        this.products[idx] = new BundleProductProduct().mergeResponse(row);
      });
    }

    return this;
  }

  // Getters & setters
  get cost_price_formatted () {
    if (this.cost_price === null) return null;
    return format.currencyToInput(this.cost_price);
  }
  set cost_price_formatted (value) {
    if (typeof value === 'string') value = Number(value);
    this.cost_price = calculate.price(value, true);
  }

  get price_excl_vat_formatted () {
    if (!this.price || typeof this.price.price !== 'number') return null;
    return format.currencyToInput(this.price.price);
  }
  set price_excl_vat_formatted (value) {
    if (typeof value === 'string') value = Number(value);
    this.price.price = calculate.price(value, true);
  }

  get vat_code () {
    return this.price.vat_code;
  }
  set vat_code (value) {
    this.price.vat_code = value;
  }
  get VAT () {
    if (typeof this.price.vat_percentage === typeof undefined || this.price.vat_percentage === null) return 0;
    return Number(this.price.vat_percentage);
  }

  get price_incl_vat () {
    if (!this.price || typeof this.price.price !== 'number') return null;
    return calculate.priceWithVAT(this.price.price, this.VAT);
  }
  set price_incl_vat (value) {
    this.price.price = calculate.priceWithoutVAT(value, this.VAT);
  }
  get price_incl_vat_formatted () {
    if (!this.price || typeof this.price.price !== 'number') return null;
    return format.currencyToInput(this.price_incl_vat);
  }
  set price_incl_vat_formatted (value) {
    if (typeof value === 'string') value = Number(value);
    this.price_incl_vat = calculate.price(value, true);
  }
}

export default BundleProduct;
