<template>
  <div>
    <KSubheader
      :breadcrumbs="[{
        page: 'Klanten',
        route: '/customers/overview'
      }, {
        page: 'Klant aanmaken',
        route: null
      }]"

      title="Klanten"
    />

    <KContainer>
      <KPortlet>
        <KPortletBody class="kt-portlet__body-fit">
          <KWizard
            ref="wizard"
            :clickable="false"
          >
            <KWizardStep
              title="Persoonlijke informatie"
            >
              <!--
                Firstname
                Lastname
                Gender
              -->
              <personalInformationGroup1
                ref="personalInformationGroup1"
                :customer="customer"
              />

              <KSeparator large />

              <!--
                Date of birth
                Citizen service number
                Document number
              -->
              <personalInformationGroup2
                ref="personalInformationGroup2"
                :customer="customer"
              />

              <KSeparator large />

              <!--
                E-mail address
                Telephone number
                Skin type
              -->
              <personalInformationGroup3
                ref="personalInformationGroup3"
                :customer="customer"

                class="mb-0"
              />

            </KWizardStep>

            <KWizardStep
              title="Adres gegevens"
            >
              <!--
                Country
                Postal code
                House number
              -->
              <personalInformationGroup4
                ref="personalInformationGroup4"
                :customer="customer"
              />

              <!--
                Address
                City
              -->
              <personalInformationGroup5
                ref="personalInformationGroup5"
                :customer="customer"
              />

            </KWizardStep>

            <KWizardStep
              title="Authenticatie"
            >
              <!-- Pincode -->
              <authentication-pincode
                ref="authenticationPincode"
                :resource="customer"
              />

              <KSeparator large />

              <!-- Fingerprint -->
              <authentication-fingerprint
                ref="authenticationFingerprint"

                :customer="customer"

                action="CREATE"
              />
            </KWizardStep>

            <KWizardStep
              title="Samenvatting"
            >
              <!-- Summary of all groups -->
              <personalInformationGroup1
                :placeholder="false"
                :customer="customer"

                disabled
              />

              <KSeparator large />

              <personalInformationGroup2
                :placeholder="false"
                :customer="customer"

                disabled
              />

              <KSeparator large />

              <personalInformationGroup3
                :placeholder="false"
                :customer="customer"

                disabled
              />

              <KSeparator large />

              <personalInformationGroup4
                :placeholder="false"
                :customer="customer"

                disabled
              />

              <personalInformationGroup5
                :placeholder="false"
                :customer="customer"

                disabled
              />

              <!-- Initial session credit -->
              <template v-if="$auth.hasPermission([['shop', 'create_payment_transactions']])">
                <KSeparator class="mt-3 mb-3" />

                <div class="row">
                  <div class="col-12 col-md-6 offset-md-3 col-lg-4 offset-lg-4 text-center">
                    <label>Initieel sessiekrediet</label>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">
                          <i>
                            <font-awesome-icon :icon="['fad', 'credit-card']" />
                          </i>
                        </span>
                      </div>
                      <input
                        ref="sessionCreditInput"

                        :value="$format.currency(initialSessionCredit || 0)"

                        disabled
                        type="text"
                        class="form-control"
                      >
                      <div
                        class="input-group-append"
                        @click.prevent="openInitialSessionCreditModal"
                      >
                        <span class="input-group-text">
                          <i class="kt-font-focus">
                            <font-awesome-icon :icon="['fad', 'edit']" />
                          </i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </template>

              <!-- Verify checkbox -->
              <template>
                <KSeparator class="mt-3 mb-3" />

                <div class="row">
                  <div class="col-12 text-center">
                    <label :class="['kt-checkbox kt-checkbox--bold', {'kt-checkbox--success': customer.marketing_opt_in === true}]">
                      <input
                        v-model="customer.marketing_opt_in"
                        type="checkbox"
                      >
                      Klant gaat akkoord met ontvangen van marketing-uitingen
                      <span />
                    </label>
                  </div>
                </div>

                <div class="row mt-3">
                  <div class="col-12 text-center">
                    <label :class="['kt-checkbox kt-checkbox--bold', {'kt-checkbox--success': customer._acceptsTerms === true, 'kt-checkbox--danger': $v.customer._acceptsTerms.$error}]">
                      <input
                        v-model="$v.customer._acceptsTerms.$model"
                        type="checkbox"
                      >
                      Klant gaat nadrukkelijk akkoord met de algemene voorwaarden
                      <span />
                    </label>
                  </div>
                </div>
              </template>

            </KWizardStep>

          </KWizard>
        </KPortletBody>
        <div class="kt-portlet__foot">
          <div class="row align-items-center">
            <div class="col-6 col-lg-2">
              <KButton
                v-if="(isMounted && $refs.wizard && $refs.wizard.currentStep !== 0)"

                :disabled="isLoading"

                variant="outline-focus"
                class="btn-md btn-block btn-tall btn-wide btn-bold btn-upper"

                @click.native.prevent="previousStep"
              >
                Vorige
              </KButton>
            </div>
            <div class="col-6 offset-lg-8 col-lg-2 kt-align-right">
              <!-- Next button -->
              <KButton
                v-if="(isMounted && $refs.wizard && $refs.wizard.currentStep !== ($refs.wizard.steps.length - 1))"

                :disabled="isLoading"

                variant="focus"
                class="btn-md btn-block btn-tall btn-wide btn-bold btn-upper"

                @click.native.prevent="nextStep"
              >
                Volgende
              </KButton>

              <!-- Previous button -->
              <KButton
                v-if="(isMounted && $refs.wizard && $refs.wizard.currentStep === ($refs.wizard.steps.length - 1))"

                :disabled="isLoading"

                variant="success"
                class="btn-md btn-block btn-tall btn-wide btn-bold btn-upper"

                @click.native.prevent="submitForm"
              >
                Aanmaken
              </KButton>
            </div>
          </div>
        </div>
      </KPortlet>
    </KContainer>

    <initial-session-credit-modal
      ref="initialSessionCreditModal"
    />
  </div>
</template>

<script>
// Import CSS for KWizard
import '@/assets/sass/caesium.wizard.scss';

// Libraries
import moment from 'moment-timezone';

// Customer class
import Customer from '@/libs/classes/customer';
import PaymentTransaction from '@/libs/classes/payment_transaction';

// Validations
import { required } from 'vuelidate/lib/validators';

// Components
import personalInformationGroup1 from '@/components/customers/components/personal_information/group-1';
import personalInformationGroup2 from '@/components/customers/components/personal_information/group-2';
import personalInformationGroup3 from '@/components/customers/components/personal_information/group-3';
import personalInformationGroup4 from '@/components/customers/components/personal_information/group-4';
import personalInformationGroup5 from '@/components/customers/components/personal_information/group-5';

import authenticationPincode from '@/components/authentication/pincode';
import authenticationFingerprint from '@/components/customers/components/authentication/fingerprint';

import initialSessionCreditModal from '@/components/customers/modals/initialSessionCredit.modal';

export default {
  components: {
    personalInformationGroup1,
    personalInformationGroup2,
    personalInformationGroup3,
    personalInformationGroup4,
    personalInformationGroup5,

    authenticationPincode,
    authenticationFingerprint,

    initialSessionCreditModal
  },
  data () {
    return {
      isMounted: false,
      isLoading: false,

      customer: new Customer().mergeResponse({
        // Set default country
        address: {
          country: 'NL'
        },

        // Add create-only variables
        pincode: null,

        // Add accept terms
        _acceptsTerms: false
      }),

      initialSessionCredit: null,

      params: {}
    };
  },
  validations: {
    customer: {
      _acceptsTerms: {
        required,
        checked: value => value === true
      }
    }
  },

  mounted () {
    // Route params
    this.$set(this, 'params', this.$route.params);

    this.$nextTick(() => {
      this.isMounted = true;
    });
  },

  methods: {
    async validate () {
      const validationArray = [];

      // Personal information
      if (this.$refs.wizard.currentStep === 0) {
        validationArray.push(this.$refs.personalInformationGroup1.$v);
        validationArray.push(this.$refs.personalInformationGroup2.$v);
        validationArray.push(this.$refs.personalInformationGroup3.$v);
      }

      // Address
      if (this.$refs.wizard.currentStep === 1) {
        validationArray.push(this.$refs.personalInformationGroup4.$v);
        validationArray.push(this.$refs.personalInformationGroup5.$v);
      }

      // Authentication
      if (this.$refs.wizard.currentStep === 2) {
        validationArray.push(this.$refs.authenticationPincode.$v);
      }

      // Summary
      if (this.$refs.wizard.currentStep === 3) {
        validationArray.push(this.$v);
      }

      // Execute validations
      if (validationArray.length > 0) {
        validationArray.forEach(v => v && v.$touch && v.$touch());
        if (validationArray.filter(v => v && v.$invalid).length !== 0) return false;
      }

      return true;
    },

    openInitialSessionCreditModal () {
      const vm = this;
      this.$eventhub.emit('modals:customers:initialSessionCredit:open', {
        value: vm.initialSessionCredit,
        async onSubmit (value) {
          // Set initialSessionCredit
          vm.initialSessionCredit = value;
        }
      });
    },

    async submitForm () {
      let valid = await this.validate();
      if (valid) {
        // Set loading
        this.$set(this, 'isLoading', true);

        // Copy customer to new variable
        let customerBody = this.customer.formatBody();

        // Delete _acceptsTerms, because this is frontend only and doesn't have to be saved
        delete customerBody._acceptsTerms;

        // Create user
        let hasError = false;
        try {
          await this.customer.create(customerBody);

          // Create initial session credit (if necessary)
          if (this.initialSessionCredit && typeof this.initialSessionCredit === 'number' && this.initialSessionCredit > 0) {
            const transaction = new PaymentTransaction().mergeResponse({
              payment_method_type: 'session_credits',
              sub_type: 'initial_session_credit',
              amount: (this.initialSessionCredit * -1),
              customer_guid: this.customer.guid,
              finished_timestamp: moment().utc().valueOf(),
              pending: false,
              topup: true
            });

            await transaction.create(transaction.formatBody());
            await this.customer.update([{
              action: 'set_field',
              field: 'received_init_session_credits',
              value: true
            }]);
          }
        } catch (e) {
          console.error('Failed creating user', e);
          hasError = true;

          // Get error and open modal
          this.$error.showModal(e);
        }

        // Set loading
        this.$set(this, 'isLoading', false);

        if (!hasError) {
          // Check if has to redirect to shop
          if (typeof this.params.redirect === 'string') {
            return this.$router.push({
              path: this.params.redirect,
              query: {
                customer: this.customer.guid
              }
            });
          }

          return this.$router.push(`/customers/view/${this.customer.guid}`);
        }
      }
    },

    async previousStep () {
      if (this.$refs.wizard) {
        return this.$refs.wizard.previousStep();
      }
    },
    async nextStep () {
      if (this.$refs.wizard) {
        let valid = await this.validate();
        if (valid) this.$refs.wizard.nextStep();
      }
    }
  }
};
</script>

<style>
/* Remove padding from bottom of form */
.kt-wizard-v3 .kt-form {
  padding-bottom: 0;
}
</style>
