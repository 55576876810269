import Abstract from './abstract';

import { merge } from 'lodash';

class StoreSettings extends Abstract {
  constructor (object = {}) {
    super();

    let defaultObject = {
      share_new_documents: null,
      timezone: null,
      region: null
    };

    merge(this, defaultObject, object);
  }
}

export default StoreSettings;
