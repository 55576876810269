<template>
  <KPortlet>
    <KPortletHead>
      <template v-slot:default>Instellingen</template>
    </KPortletHead>
    <KPortletBody>
      <information-form
        ref="informationForm"

        :cash-register="cashRegisterClone"

        action="EDIT"
      />
    </KPortletBody>
    <div class="kt-portlet__foot">
      <div class="row align-items-center">
        <div class="offset-6 col-6 offset-lg-10 col-lg-2 kt-align-right">
          <KButton
            variant="warning"
            class="btn-md btn-block btn-tall btn-wide btn-bold btn-upper"
            @click.native.prevent="submitForm"
          >
            Wijzigen
          </KButton>
        </div>
      </div>
    </div>
  </KPortlet>
</template>

<script>
// Libraries
import { cloneDeep } from 'lodash';
import mutationsLib from '@/libs/mutations';

// Classes
import CashRegister from '@/libs/classes/cash_register';

// Components
import informationForm from '@/components/cash_registers/components/information.form';

export default {
  components: {
    informationForm
  },
  props: {
    cashRegister: {
      type: CashRegister,
      default: null
    }
  },
  data () {
    return {
      cashRegisterClone: cloneDeep(this.cashRegister)
    };
  },

  methods: {
    async validate () {
      const validationArray = [];
      validationArray.push(this.$refs.informationForm.$v);

      if (validationArray.length > 0) {
        validationArray.forEach(v => v && v.$touch && v.$touch());
        if (validationArray.filter(v => v && v.$invalid).length !== 0) return false;
      }

      return true;
    },

    async submitForm () {
      if (await this.validate() === true) {
        const fields = ['name'];
        const mutations = mutationsLib.create(fields, this.cashRegister.formatBody(), this.cashRegisterClone.formatBody());

        if (mutations.length) {
          await this.cashRegister.update(mutations);
          this.$set(this, 'cashRegisterClone', cloneDeep(this.cashRegister));
        }
      }
    }
  }
};
</script>
