<template>
  <KPortlet>
    <KPortletHead>
      <template v-slot:default>{{ year }}</template>
    </KPortletHead>
    <KPortletBody class="kt-portlet__body--fit position-relative">
      <!-- Loading block -->
      <template v-if="isLoading">
        <div class="loading-container absolute">
          <div class="loading-backdrop">
            <div class="loading-block">
              <div class="blockui">
                <span>Please wait...</span>
                <span class="kt-spinner kt-spinner--v2 kt-spinner--primary" />
              </div>
            </div>
          </div>
        </div>
      </template>

      <!-- Table -->
      <div class="table-responsive">
        <table class="table mb-0">
          <thead class="thead-light thead-light--fit">
            <tr>
              <th />
              <th class="table-field-currency overflow-ellipsis">januari</th>
              <th class="table-field-currency overflow-ellipsis">februari</th>
              <th class="table-field-currency overflow-ellipsis">maart</th>
              <th class="table-field-currency overflow-ellipsis">april</th>
              <th class="table-field-currency overflow-ellipsis">mei</th>
              <th class="table-field-currency overflow-ellipsis">juni</th>
              <th class="table-field-currency overflow-ellipsis">juli</th>
              <th class="table-field-currency overflow-ellipsis">augustus</th>
              <th class="table-field-currency overflow-ellipsis">september</th>
              <th class="table-field-currency overflow-ellipsis">oktober</th>
              <th class="table-field-currency overflow-ellipsis">november</th>
              <th class="table-field-currency overflow-ellipsis">december</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="overflow-ellipsis">Omzet {{ $format.percentage(0.21) }}%</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthVATKey(1, 21, 'total_price_excl')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthVATKey(2, 21, 'total_price_excl')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthVATKey(3, 21, 'total_price_excl')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthVATKey(4, 21, 'total_price_excl')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthVATKey(5, 21, 'total_price_excl')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthVATKey(6, 21, 'total_price_excl')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthVATKey(7, 21, 'total_price_excl')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthVATKey(8, 21, 'total_price_excl')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthVATKey(9, 21, 'total_price_excl')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthVATKey(10, 21, 'total_price_excl')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthVATKey(11, 21, 'total_price_excl')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthVATKey(12, 21, 'total_price_excl')) }}</td>
            </tr>
            <tr>
              <td class="overflow-ellipsis">Omzet {{ $format.percentage(0.09) }}%</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthVATKey(1, 9, 'total_price_excl')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthVATKey(2, 9, 'total_price_excl')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthVATKey(3, 9, 'total_price_excl')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthVATKey(4, 9, 'total_price_excl')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthVATKey(5, 9, 'total_price_excl')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthVATKey(6, 9, 'total_price_excl')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthVATKey(7, 9, 'total_price_excl')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthVATKey(8, 9, 'total_price_excl')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthVATKey(9, 9, 'total_price_excl')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthVATKey(10, 9, 'total_price_excl')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthVATKey(11, 9, 'total_price_excl')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthVATKey(12, 9, 'total_price_excl')) }}</td>
            </tr>
            <tr>
              <td class="overflow-ellipsis">Omzet {{ $format.percentage(0) }}%</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthVATKey(1, 'vat_nl_0', 'total_price_excl')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthVATKey(2, 'vat_nl_0', 'total_price_excl')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthVATKey(3, 'vat_nl_0', 'total_price_excl')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthVATKey(4, 'vat_nl_0', 'total_price_excl')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthVATKey(5, 'vat_nl_0', 'total_price_excl')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthVATKey(6, 'vat_nl_0', 'total_price_excl')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthVATKey(7, 'vat_nl_0', 'total_price_excl')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthVATKey(8, 'vat_nl_0', 'total_price_excl')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthVATKey(9, 'vat_nl_0', 'total_price_excl')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthVATKey(10, 'vat_nl_0', 'total_price_excl')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthVATKey(11, 'vat_nl_0', 'total_price_excl')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthVATKey(12, 'vat_nl_0', 'total_price_excl')) }}</td>
            </tr>
            <tr>
              <td class="overflow-ellipsis">Omzet BTW vrijgesteld</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthVATKey(1, 'no_vat', 'total_price_excl')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthVATKey(2, 'no_vat', 'total_price_excl')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthVATKey(3, 'no_vat', 'total_price_excl')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthVATKey(4, 'no_vat', 'total_price_excl')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthVATKey(5, 'no_vat', 'total_price_excl')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthVATKey(6, 'no_vat', 'total_price_excl')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthVATKey(7, 'no_vat', 'total_price_excl')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthVATKey(8, 'no_vat', 'total_price_excl')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthVATKey(9, 'no_vat', 'total_price_excl')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthVATKey(10, 'no_vat', 'total_price_excl')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthVATKey(11, 'no_vat', 'total_price_excl')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthVATKey(12, 'no_vat', 'total_price_excl')) }}</td>
            </tr>
          </tbody>
          <thead class="thead-light thead-light--fit">
            <tr>
              <th>Totaal omzet</th>
              <th class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthKey(1, 'total_revenue_excl')) }}</th>
              <th class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthKey(2, 'total_revenue_excl')) }}</th>
              <th class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthKey(3, 'total_revenue_excl')) }}</th>
              <th class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthKey(4, 'total_revenue_excl')) }}</th>
              <th class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthKey(5, 'total_revenue_excl')) }}</th>
              <th class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthKey(6, 'total_revenue_excl')) }}</th>
              <th class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthKey(7, 'total_revenue_excl')) }}</th>
              <th class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthKey(8, 'total_revenue_excl')) }}</th>
              <th class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthKey(9, 'total_revenue_excl')) }}</th>
              <th class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthKey(10, 'total_revenue_excl')) }}</th>
              <th class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthKey(11, 'total_revenue_excl')) }}</th>
              <th class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthKey(12, 'total_revenue_excl')) }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="overflow-ellipsis">BTW {{ $format.percentage(0.21) }}%</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthVATKey(1, 21, 'total_price_vat')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthVATKey(2, 21, 'total_price_vat')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthVATKey(3, 21, 'total_price_vat')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthVATKey(4, 21, 'total_price_vat')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthVATKey(5, 21, 'total_price_vat')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthVATKey(6, 21, 'total_price_vat')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthVATKey(7, 21, 'total_price_vat')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthVATKey(8, 21, 'total_price_vat')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthVATKey(9, 21, 'total_price_vat')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthVATKey(10, 21, 'total_price_vat')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthVATKey(11, 21, 'total_price_vat')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthVATKey(12, 21, 'total_price_vat')) }}</td>
            </tr>
            <tr>
              <td class="overflow-ellipsis">BTW {{ $format.percentage(0.09) }}%</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthVATKey(1, 9, 'total_price_vat')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthVATKey(2, 9, 'total_price_vat')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthVATKey(3, 9, 'total_price_vat')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthVATKey(4, 9, 'total_price_vat')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthVATKey(5, 9, 'total_price_vat')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthVATKey(6, 9, 'total_price_vat')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthVATKey(7, 9, 'total_price_vat')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthVATKey(8, 9, 'total_price_vat')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthVATKey(9, 9, 'total_price_vat')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthVATKey(10, 9, 'total_price_vat')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthVATKey(11, 9, 'total_price_vat')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthVATKey(12, 9, 'total_price_vat')) }}</td>
            </tr>
          </tbody>
          <tfoot class="thead-light thead-light--fit">
            <tr>
              <th>Totaal BTW</th>
              <th class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthKey(1, 'total_vat_paid')) }}</th>
              <th class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthKey(2, 'total_vat_paid')) }}</th>
              <th class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthKey(3, 'total_vat_paid')) }}</th>
              <th class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthKey(4, 'total_vat_paid')) }}</th>
              <th class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthKey(5, 'total_vat_paid')) }}</th>
              <th class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthKey(6, 'total_vat_paid')) }}</th>
              <th class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthKey(7, 'total_vat_paid')) }}</th>
              <th class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthKey(8, 'total_vat_paid')) }}</th>
              <th class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthKey(9, 'total_vat_paid')) }}</th>
              <th class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthKey(10, 'total_vat_paid')) }}</th>
              <th class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthKey(11, 'total_vat_paid')) }}</th>
              <th class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getMonthKey(12, 'total_vat_paid')) }}</th>
            </tr>
          </tfoot>
        </table>
      </div>
    </KPortletBody>
  </KPortlet>
</template>

<script>
// Libraries
import moment from 'moment-timezone';
import objectPath from 'object-path';

export default {
  props: {
    year: {
      type: Number,
      default: null
    },
    vat: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      isLoading: false
    };
  },

  methods: {
    getMonth (month = null) {
      // Check if month is given, year is found, monthly vat is loaded
      if (month && this.year && this.vat && this.vat.monthly && this.vat.monthly.length) {
        // Format month
        const monthObject = moment(`01-${month}-${this.year}`, 'DD-MM-YYYY');

        // Check if month exists
        if (monthObject.isValid()) {
          return this.vat.monthly.find(m => m.date === monthObject.format('DD-MM-YYYY'));
        }
      }
      return null;
    },
    getMonthKey (month = null, key) {
      const monthObject = this.getMonth(month);
      if (monthObject) {
        return objectPath.get(monthObject, key);
      }
      return null;
    },
    getMonthVAT (month, vatPercentage) {
      const monthObject = this.getMonth(month);
      if (monthObject && monthObject.vats) {
        return monthObject.vats.find(vat => (vat.percentage === Number(vatPercentage) || vat.vat_code === vatPercentage));
      }
      return null;
    },
    getMonthVATKey (month, vatPercentage, key) {
      const vatObject = this.getMonthVAT(month, vatPercentage);
      if (vatObject) {
        return objectPath.get(vatObject, key);
      }
      return null;
    }
  }
};
</script>
