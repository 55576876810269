import Abstract from './abstract';

import { merge } from 'lodash';
import format from '@/libs/format';
import calculate from '@/libs/calculate';

import { apiWS as ws } from '@/libs/ws';

import ProductPrice from './product.price';

class CreditProduct extends Abstract {
  constructor (guid = null) {
    super(guid);
    this.uri = '/credit_products';

    let defaultObject = {
      name: null,
      vat_code: null,

      article_number: null,
      EAN_code: null,

      price: new ProductPrice(),
      credits: null,

      amount_changeable: true,

      minimum_permission_group_level: 0
    };

    merge(this, defaultObject);
  }

  // Methods
  async populate (type = 'get', options = {}) {

    // Get VAT percentage
    let VATCodes = await ws.get('v1', '/vats');
    let VATCode = VATCodes.find(c => c.code === this.vat_code);
    if (typeof VATCode !== typeof undefined) {
      this.price.vat_percentage = VATCode.value;
    }

    return this;
  }

  formatBody (type = 'get', options = {}) {
    let body = super.formatBody(type, options);

    // Format ProductPrice
    body.price = this.price.formatBody();

    return body;
  }

  // Getters
  get credits_formatted () {
    if (this.credits === null) return null;
    return format.currencyToInput(this.credits);
  }
  set credits_formatted (value) {
    value = calculate.price(value, true);

    let afterHook = false;
    if (this.price_incl_vat === null || this.price_incl_vat === this.credits) afterHook = true;

    this.credits = value;

    if (afterHook === true) this.price_incl_vat = this.credits;
  }

  get price_formatted () {
    if (this.price.price_excl_vat === null) return null;
    return format.currencyToInput(this.price.price_excl_vat);
  }
  set price_formatted (value) {
    this.price.price_excl_vat = calculate.price(value, true);
  }

  get VAT () {
    if (typeof this.price.vat_percentage === typeof undefined || this.price.vat_percentage === null) return 0;
    return Number(this.price.vat_percentage);
  }

  get price_incl_vat () {
    if (this.price.price_excl_vat === null) return null;
    return calculate.priceWithVAT(this.price.price_excl_vat, this.VAT);
  }
  set price_incl_vat (value) {
    this.price.price_excl_vat = calculate.priceWithoutVAT(value, this.VAT);
  }
  get price_incl_vat_formatted () {
    if (this.price_incl_vat === null) return null;
    return format.currencyToInput(this.price_incl_vat);
  }
  set price_incl_vat_formatted (value) {
    if (typeof value === 'string') value = Number(value);
    this.price_incl_vat = calculate.price(value, true);
  }
}

export default CreditProduct;
