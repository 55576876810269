// Libraries
import { merge } from 'lodash';

import eventhub from '@/libs/eventhub';
import { apiWS as ws } from '@/libs/ws';
import store from '@/libs/stores';
import Logger from '@/libs/logger';

// Classes
import Equipment from '@/libs/classes/equipment';
import Master from '@/libs/classes/master';

const log = new Logger('Equipment.js');

export default class equipment {
  static async getEquipment (options = {}) {
    let equipment = await ws.get('v1', '/equipments', merge({
      query: {
        sort: 'name|asc'
      }
    }, options));
    equipment = equipment.map((row) => new Equipment().mergeResponse(row), []);

    // Set equipment
    this.equipment = equipment;
    eventhub.emit('equipment:equipment:updated', equipment);

    // Get masters
    await this.getMasters({
      query: {
        'filter[_meta.guid]': equipment.map((row) => row.master_guid, []).join(',')
      }
    });

    return equipment;
  }

  static async getMasters (options = {}) {
    // Get masters
    let masters = await ws.get('v1', '/masters', options);

    for (let idx = 0; idx < masters.length; idx++) {
      masters[idx] = new Master().mergeResponse(masters[idx]);

      // Configure master
      try {
        await masters[idx].populate('get', options);
        masters[idx].socket = await masters[idx].getSocket();

        // Listeners
        masters[idx].socket._onMasterData = this.onMasterData.bind(this, masters[idx]);
        masters[idx].socket.connection.on('data', masters[idx].socket._onMasterData);

        masters[idx].socket._onMasterStateChange = this.onMasterStateChange.bind(this, masters[idx]);
        masters[idx].socket.connection.on('stateChange', masters[idx].socket._onMasterStateChange);
      } catch (e) {
        console.error('Master error', e);
      }
    }

    // Remove listeners
    if (this.masters && this.masters.length > 0) {
      for (let idx = 0; idx < this.masters.length; idx++) {
        if (this.masters[idx].socket) {
          // Remove listeners
          this.masters[idx].socket.connection.off('data', this.masters[idx].socket._onMasterData);
          this.masters[idx].socket.connection.off('stateChange', this.masters[idx].socket._onMasterStateChange);
        }
      }
    }

    // Set masters
    this.masters = masters;
    eventhub.emit('equipment:masters:updated', masters);

    // Set masters to equipment, if equipment is defined
    if (this.equipment && this.equipment.length) {
      this.equipment.forEach((row, idx) => {
        const master = masters.find(m => m.guid === row.master_guid);
        if (typeof master !== typeof undefined) {
          this.equipment[idx].master = master;
        }
      });
      eventhub.emit('equipment:equipment:updated', equipment);
    }

    // Listen to api WS
    if (!this._onWSBroadcast) {
      this._onWSBroadcast = eventhub.on('websocket:broadcast', this.onWebsocketBroadcast.bind(this));
    }

    return masters;
  }

  static removeMastersAndEquipment () {
    this.masters = [];
    this.equipment = [];
  }

  static async onWebsocketBroadcast (message = null) {
    if (message && typeof message === 'object' && store.activeStore && store.activeStore._meta) {
      // Check if on general channel of current workspace and store
      if (message.channel === `store:${store.activeStore._meta.guid}:general`) {
        // Action: master_updated
        if (message.action === 'master_updated') {
          // Check for data
          if (message.data) {
            const master = new Master().mergeResponse(message.data);

            // Check if master exists
            const masterIdx = this.masters.findIndex(m => m.guid === master.guid);
            if (masterIdx !== -1) {
              // Set peripherals
              this.masters[masterIdx].peripherals = master.peripherals;

              // Check for change of local ip
              if (this.masters[masterIdx].local_ip !== master.local_ip) {
                this.masters[masterIdx].local_ip = master.local_ip;
                this.masters[masterIdx].getSocket();
              }

              // Send update
              eventhub.emit('equipment:masters:updated', this.masters);
            }
          }
        }
      }
    }
  }

  static async onMasterData (master = null, message = {}) {
    // Handled on message type
    if (message.type) {
      if (message.type === 'push') {
        if (message.url === '/v1/slaves') {
          // Get equipment
          if (!message.data || !message.data.id) {
            return;
          }

          if (!this.equipment || !this.equipment.length) {
            return;
          }

          let equipmentIdx = this.equipment.findIndex(e => e.serial === message.data.id);
          if (equipmentIdx !== -1) {
            this.equipment[equipmentIdx].setCommunication(message.data);
            eventhub.emit('equipment:equipment:updated', this.equipment);
          }
        }
      }
    }
  }

  static async onMasterStateChange (master = null, state = null, oldState = null) {
    if ((master instanceof Master) === false) {
      return;
    }

    if (typeof state !== 'boolean') {
      return;
    }

    // Offline to online
    if (oldState === false && state === true) {
      // Check if equipment is initiated
      if (this.equipment && this.equipment.length) {
        // Get states of slaves again
        let response = await master.getSlaves();
        this.equipment.forEach((row, idx) => {
          const information = response.find(r => r.id === this.equipment[idx].serial);
          if (typeof information !== typeof undefined) {
            this.equipment[idx].setCommunication(information);
          }
        });
        eventhub.emit('equipment:equipment:updated', this.equipment);
      }
    }
  }

  static async getEquipmentInformation (options = {}) {
    if (!this.equipment || !this.equipment.length) {
      await this.getEquipment(options);
    }

    const promises = [];
    for (const master of this.masters) {
      promises.push(new Promise(async (resolve, reject) => {
        try {
          let response = await master.getSlaves(options);
          if (response && response.length) {
            this.equipment.forEach((row, idx) => {
              const information = response.find(r => r.id === this.equipment[idx].serial);
              if (typeof information !== typeof undefined) {
                this.equipment[idx].setCommunication(information);
              }
            });

            eventhub.emit('equipment:equipment:updated', this.equipment);
          }

          resolve();
        } catch (e) {
          reject(e);
        }
      }));
    };

    const errors = [];
    await Promise.all(promises.map(p => p.catch(e => errors.push(e))));

    return this.equipment;
  }

  static async pingMasters (options = {}) {
    if (!this.masters || !this.masters.length) {
      await this.getMasters(options);
    }

    log.debug('pingMasters:', this.masters);

    const promises = [];
    for (const master of this.masters) {
      if (master.socket) {
        log.debug('Has socket for master:', master);
        promises.push(new Promise(async (resolve, reject) => {
          try {
            log.debug('Send ping to master:', master);
            const response = await master.socket.device('ping');
            resolve(response);
          } catch (e) {
            reject(e);
          }
        }));
      }
    }

    const errors = [];
    let response = await Promise.all(promises.map(p => p.catch(e => errors.push(e))));
    if (errors.length) throw new Error(errors);

    return response;
  }
};
