<template>
  <div class="col-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">

    <breadcrumbs
      title="Producten"
      :breadcrumbs="[{
        title: 'Producten',
        route: null
      }]"
    ></breadcrumbs>

    <div class="row mt-3">

      <div v-for="(group, idx) in groups" class="col-12 col-md-6">
        <category-block :icon="['fad', 'tags']" v-on:click.prevent.native="navigateTo({
          name: `/sales/shop/:cash_register/cart/products/:group_guid/overview`,
          params: {
            group_guid: group._meta.guid
          }
        })">
          <h4 class="category-title">{{ group.name }}</h4>
        </category-block>
      </div>

    </div>

  </div>
</template>

<script>
// Mixins
import shopMixin from '@/mixins/shop';

// Classes
import ProductGroup from '@/libs/classes/product_group';

export default {
  mixins: [shopMixin],
  data() {
    return {
      isMounted: false,

      groups: []
    }
  },
  async mounted() {
    try {
      await this.getProductGroups();
    } catch(e) {
      console.error('Error fetching products groups', e);
      return;
    }

    this.$nextTick(() => {
      this.isMounted = true;
    })
  },

  methods: {
    async getProductGroups() {
      this.$set(this, 'groups', []);
      let groups = await this.$ws.get('v1', '/product_groups');
      groups.forEach((row, idx) => groups[idx] = new ProductGroup().mergeResponse(groups[idx]));
      this.$set(this, 'groups', groups);
      return this.groups;
    }
  }
}
</script>
