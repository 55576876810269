// Libraries
import moment from 'moment-timezone';
import eventhub from '@/libs/eventhub';
import stores from '@/libs/stores';
import { apiWS as ws } from '@/libs/ws';
import Logger from '@/libs/logger';

// Classes
import Resource from '@/libs/classes/resource';
import User from '@/libs/classes/user';
// import equipment from '@/libs/equipment';

const log = new Logger('Auth.js');

export default class auth {
  static setAuthData (data = {}, removeOtherTokens = false) {
    auth.authData = {
      access_token: data.access_token,
      refresh_token: data.refresh_token,
      expiry_timestamp: data.expiry_timestamp,
      resource_storage: data.resource_storage,
      resource_guid: data.resource_guid
    };
    let authDataArray = [];
    if (removeOtherTokens === true) {
      try {
        authDataArray = JSON.parse(window.localStorage.getItem('auth_data')) || [];
      } catch (e) {}

      // Remove all old items for this resource_guid
      // So we avoid duplicate auths for one resource_guid
      authDataArray = authDataArray.filter((authDataItem) => {
        return authDataItem.resource_guid !== auth.authData.resource_guid;
      });
    }

    const authDataExists = authDataArray.find((authDataItem) => {
      return authDataItem.access_token === auth.authData.access_token;
    });
    if (!authDataExists) {
      authDataArray.push(auth.authData);
      window.localStorage.setItem('auth_data', JSON.stringify(authDataArray));
    }
    window.sessionStorage.setItem('active_auth', auth.authData.access_token);
  }

  static getCurrentAuthData () {
    const activeAuthToken = window.sessionStorage.getItem('active_auth');
    return auth.getAuthDataForToken(activeAuthToken);
  }

  static getAuthDataArray () {
    let authDataArray = [];
    try {
      authDataArray = JSON.parse(window.localStorage.getItem('auth_data')) || [];
    } catch (e) {}

    return authDataArray;
  }

  static getAuthDataForToken (accessToken) {
    const authDataArray = auth.getAuthDataArray();
    return authDataArray.find((authData) => {
      return authData.access_token === accessToken;
    });
  }

  static async authenticate () {
    if (!auth.authData || !auth.authData.access_token) {
      // If there is no authData set, we check if we have an active_auth set, connected to auth_data
      // If not, return false. If yes, set that as the auth data
      let activeAuthData = auth.getCurrentAuthData();
      if (!activeAuthData || !activeAuthData.access_token) {
        const authDataArray = auth.getAuthDataArray();
        if (!authDataArray || !authDataArray.length) {
          return false;
        }
        if (authDataArray.length === 1) {
          activeAuthData = auth.getAuthDataForToken(authDataArray[0].access_token);
        }
        if (authDataArray.length > 1) {
          // TODO: Show modal with all the sessions
          return;
        }
      }
      auth.setAuthData(activeAuthData);
    }

    auth.authenticated = true;
    auth.resource = await auth.getResourceData();

    if (auth.authData && auth.authData.resource_storage === 'users') {
      auth.resource = new User().mergeResponse(auth.resource);
      await auth.resource.getPermissions();

      // Set user as a copy of resource
      auth.user = auth.resource;
    }

    eventhub.emit('auth:authenticated', true);
  }

  static async getResourceData () {
    if (!auth.authData || !auth.authData.access_token || !auth.authData.resource_guid) {
      return;
    }

    let resource = null;
    try {
      resource = await ws.get('v1', '/' + auth.authData.resource_storage + '/' + auth.authData.resource_guid, {
        auth_check_request: true
      });
      resource = new Resource().mergeResponse(resource);
    } catch (e) {
      if (e.code === 404) {
        // Could not get resource, just logout
        auth.logout();
      }
    }

    return resource;
  }

  static switchActiveAuth (accessToken) {
    const authData = auth.getAuthDataForToken(accessToken);
    auth.setAuthData(authData);
    if (!authData) {
      throw new Error('No auth data found for this token');
    }
    window.sessionStorage.setItem('active_auth', accessToken);
    return auth.authenticate();
  }

  static shouldRefresh () {
    if (!auth.authData || !auth.authData.refresh_token) {
      return false;
    }
    const currentTime = moment.utc().valueOf();
    const expiryMinus10Minutes = moment(auth.authData.expiry_timestamp).subtract(10, 'minutes');
    if (currentTime >= expiryMinus10Minutes) {
      log.debug('Should refresh!:', auth.authData.expiry_timestamp);
    }
    return currentTime >= expiryMinus10Minutes;
  }

  // Username / Password signin
  static async signin (username, password) {
    const signinResult = await ws.post('v1', '/auth/signin', {
      body: { username, password }
    });

    if (!signinResult || !signinResult.access_token) {
      throw new Error('invalidAPIResponse');
    }

    auth.setAuthData(signinResult);

    await auth.authenticate();
  }

  static logout () {
    // Remove auth resources
    delete auth.resource;
    delete auth.user;
    auth.authenticated = false;

    // Remove store
    stores.removeActiveStore();

    // Remove all device sockets
    const WSDevice = require('@/libs/ws.device').default;
    WSDevice.disconnectSockets();
    const equipment = require('@/libs/equipment').default;
    equipment.removeMastersAndEquipment();

    // Empty local storage
    window.sessionStorage.removeItem('active_auth');
    eventhub.emit('auth:authenticated', false);
    eventhub.emit('auth:signout');

    // Disconnect auth of WebSocket
    ws.disconnectAuth();

    // Set auth data array
    let authDataArray = [];
    try {
      authDataArray = JSON.parse(window.localStorage.getItem('auth_data')) || [];
    } catch (e) {}

    authDataArray = authDataArray.filter((authDataItem) => {
      return authDataItem.access_token !== auth.authData.access_token;
    });
    window.localStorage.setItem('auth_data', JSON.stringify(authDataArray));
    auth.authData = null;
  }

  // hasPermission(s) functions
  static hasPermission (permissionsArray, options = {}) {
    // If only string is given, convert to array
    if (typeof permissionsArray === 'string') permissionsArray = [permissionsArray];

    // Return true if no permissions given
    if (!permissionsArray || permissionsArray.length === 0) {
      return true;
    }

    return (permissionsArray || []).every((permissions) => {
      // If only string is given, convert to array
      if (typeof permissions === 'string') permissions = [permissions];

      // Check every permission
      return (permissions || []).some((permission) => {
        // Return true if scope is public
        if (permission === 'public') {
          return true;
        }

        // Check if logged in
        if (permission === 'loggedin' && auth.resource) {
          return true;
        }

        // Check if resource is found
        if (!auth.resource) {
          return false;
        }

        // Check permissions from resource
        return auth.resource.hasPermission(permission, options);
      });
    });
  }
}
