// Libraries
import eventhub from '@/libs/eventhub';
import { apiWS as ws } from '@/libs/ws';
import shop from '@/libs/shop';

// Classes
import Store from '@/libs/classes/store';

export default class stores {
  static async setActiveStore (storeGuid) {
    // Clear shop session, if store is different
    let shopSession = shop.getSession();
    if (shopSession && shopSession.store !== storeGuid) {
      shop.removeSession();
    }

    // Set active store to workspace manager
    if (storeGuid === 'workspace_manager') {
      window.sessionStorage.setItem('activeStore', 'workspace_manager');
      eventhub.emit('stores:switched', 'workspace_manager');
      this.activeStore = {
        name: 'Workspace Manager',
        workspace_manager: true,
        _meta: {
          guid: 'workspace_manager'
        }
      };
      return;
    }

    // Set active store to given guid
    try {
      stores.activeStore = await new Store(storeGuid).get();
    } catch (e) {
      console.error('Error loading activeStore:', e);
      stores.activeStore = null;
      window.sessionStorage.removeItem('activeStore');
    }
    if (stores.activeStore) {
      window.sessionStorage.setItem('activeStore', stores.activeStore._meta.guid);
      eventhub.emit('stores:switched', stores.activeStore);
    } else {
      // It did have an activeStore, but no access anymore
      // So should open the switchStore modal
      eventhub.emit('modals:switchStore:open', {
        required: true
      });
    }
  }

  static async getActiveStore () {
    if (!stores.activeStore) {
      const activeStoreFromSessionStorage = window.sessionStorage.getItem('activeStore');
      if (activeStoreFromSessionStorage && activeStoreFromSessionStorage !== 'null') {
        if (!stores.getStorePromise) {
          // Make sure we only execute the setActiveStore once
          // So we won't execute the API call multiple times
          stores.getStorePromise = stores.setActiveStore(activeStoreFromSessionStorage);
        }
        await stores.getStorePromise;
        stores.getStorePromise = null;
      } else {
        // When loading the page, no activeStore was found
        // Show the switchStore modal. In a timeout, because the component is still loading
        // Don't show modal when sessionLockedPromise is set
        if (!ws.sessionLockedPromise) {
          setTimeout(() => {
            eventhub.emit('modals:switchStore:open', {
              required: true
            });
          }, 100);
        }
      }
    }
    return stores.activeStore;
  }

  static removeActiveStore () {
    stores.activeStore = null;
    window.sessionStorage.removeItem('activeStore');
  }

  static checkStoreForUpdate (doc = {}) {
    if (stores.activeStore._meta.guid === 'workspace_manager') {
      return true;
    }
    return (doc._meta.store === stores.activeStore._meta.guid);
  }
}
