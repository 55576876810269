<template>
  <!-- Loading -->
  <div
    v-if="isMounted === false"
    class="row"
  >
    <div class="col-12 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
      <div class="loading-container">
        <div class="loading-block">
          <div class="blockui ml-auto mr-auto w-100">
            <span>Gegevens ophalen...</span>
            <span class="kt-spinner kt-spinner--v2 kt-spinner--primary" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Finished loading -->
  <KPortlet v-else>
    <KPortletHead>
      <template v-slot:default>Instellingen</template>
    </KPortletHead>
    <KPortletBody>

      <information-form
        ref="informationForm"
        :equipment="equipmentCloned"

        :masters="masters"
        :equipment-groups="groups"
        :disabled="isLoading"

        :action="($auth.hasPermission([['update_equipments']]) ? 'EDIT' : 'VIEW')"
      />

    </KPortletBody>
    <div
      v-if="$auth.hasPermission([['update_equipments']])"
      class="kt-portlet__foot"
    >
      <div class="row align-items-center">
        <div class="col-6 offset-lg-9 col-lg-3 kt-align-right">
          <KButton
            :disabled="isLoading"

            variant="warning"
            class="btn-md btn-block btn-tall btn-wide btn-bold btn-upper"

            @click.native.prevent="submitForm"
          >
            Wijzigen
          </KButton>
        </div>
      </div>
    </div>
  </KPortlet>
</template>

<script>
// Libraries
import { cloneDeep } from 'lodash';
import mutationsLib from '@/libs/mutations';

// Classes
import Device from '@/libs/classes/device';
import Master from '@/libs/classes/master';
import Equipment from '@/libs/classes/equipment';
import EquipmentGroup from '@/libs/classes/equipment_group';

// Components
import informationForm from '@/components/equipment/components/form';

export default {
  components: {
    informationForm
  },
  props: {
    equipment: {
      type: Equipment,
      default: null
    },

    meta: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      isMounted: false,
      isLoading: false,

      equipmentCloned: cloneDeep(this.equipment),

      groups: [],
      masters: []
    };
  },

  async mounted () {
    try {
      const promises = [];

      if (this.$auth.hasPermission('get_equipment_groups')) {
        promises.push(this.getEquipmentGroups());
      }

      if (this.$auth.hasPermission('get_masters')) {
        promises.push(this.getHardwareMasters());
      }

      await Promise.all(promises);
    } catch (e) {
      console.error('Failed fetching information', e);
      return;
    }

    this.$nextTick(() => {
      this.isMounted = true;
    });
  },

  methods: {
    async getEquipmentGroups (options = {}) {
      this.$set(this, 'groups', []);

      let groups = await this.$ws.get('v1', '/equipment_groups');
      groups = groups.map((group) => {
        return new EquipmentGroup().mergeResponse(group);
      }, []);

      this.$set(this, 'groups', groups);
      return this.meta.groups;
    },

    async getHardwareMasters () {
      this.$set(this, 'masters', []);

      let masters = await this.$ws.get('v1', '/masters');
      masters = masters.map((row) => {
        return new Device().mergeResponse(row);
      }, []);

      this.$set(this, 'masters', masters);
      return this.meta.masters;
    },

    async validate () {
      const validationArray = [];
      validationArray.push(this.$refs.informationForm.$v);

      if (validationArray.length > 0) {
        validationArray.forEach(v => v && v.$touch && v.$touch());
        if (validationArray.filter(v => v && v.$invalid).length !== 0) return false;
      }

      return true;
    },
    async submitForm () {
      if (await this.validate() === true) {
        this.$set(this, 'isLoading', true);

        // Set serial
        if (this.equipmentCloned.serial && this.equipmentCloned.serial.substr(0, 4) !== 'CSID') this.equipmentCloned.serial = `CSID${this.equipmentCloned.serial}`;

        // If master guid is changed, notify the old and new master
        if (this.equipmentCloned.master_guid !== this.equipment.master_guid) {
          // Notify new master
          try {
            const master = await new Master(this.equipmentCloned.master_guid).get();
            master.connectSlave(this.equipmentCloned.serial);
          } catch (e) {}

          // Notify old master
          try {
            const master = await new Master(this.equipment.master_guid).get();
            master.disconnectSlave(this.equipment.serial);
          } catch (e) {}
        }

        // Serial changed, so disconnect and connect new serial
        // if (this.equipmentCloned.serial !== this.equipment.serial) {
        //   try {
        //     // First, connect new slave, because we want to make sure it's a valid CSID
        //     await this.equipmentCloned.connect(this.equipmentCloned.formatBody());
        //
        //     // Then, disconnect the old slave
        //     await this.equipment.disconnect();
        //   } catch (e) {
        //     console.error(e);
        //     this.$error.showModal(e);
        //     this.$set(this, 'isLoading', false);
        //     return;
        //   }
        //
        //   this.equipment = cloneDeep(this.equipmentCloned);
        //
        //   // Then, change route
        //   this.$router.replace({
        //     params: {
        //       guid: this.equipmentCloned.guid
        //     }
        //   });
        // }

        // Update the fields of the equipment
        const fields = ['name', 'room', 'default_session_guid', 'group_guid', 'serial', 'master_guid', 'brand', 'model', 'type', 'energy_consumption', 'uv_type', 'radiation'];
        const mutations = mutationsLib.create(fields, this.equipment.formatBody(), this.equipmentCloned.formatBody());

        if (mutations.length) {
          try {
            await this.equipment.update(mutations);
            await this.equipment.populate('update');
          } catch (e) {
            console.error(e);
            this.$error.showModal(e);
            this.$set(this, 'isLoading', false);
            return;
          }

          // Set search name of default session
          if (this.equipment && this.equipment.default_session) {
            this.equipment.default_session.search_name = this.equipment.default_session.session_name;
          }

          this.$set(this, 'equipmentCloned', cloneDeep(this.equipment));
        }

        this.$set(this, 'isLoading', false);
      }
    }
  }
};
</script>
