<template>
  <div class="product-price-row row">
    <div class="col-12 col-lg-11">

      <div class="row form-group">

        <!-- Only show when it's a session -->
        <div v-if="price.type === 'session_product'" class="col-10 col-lg-6 col-xl-4">
          <label :class="[{'d-xl-none': firstRow === false}]">Sessie</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i><font-awesome-icon :icon="['fad', 'stopwatch']" /></i>
              </span>
            </div>
            <input type="text" class="form-control" :value="`${price.product.session_name}`" disabled="disabled" />
          </div>
        </div>

        <div v-if="price.type === 'product'" class="col-10 col-lg-6 col-xl-4">
          <label :class="[{'d-xl-none': firstRow === false}]">Naam</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i><font-awesome-icon :icon="['fad', 'tag']" /></i>
              </span>
            </div>
            <input type="text" class="form-control" :value="price.product.name" disabled="disabled" />
          </div>
        </div>

        <div class="col-2 label-margin d-lg-none">
          <KButton variant="light" class="btn-block">
            <font-awesome-icon icon="trash" />
          </KButton>
        </div>

        <!-- Only show when it's a product -->
        <div v-if="price.type === 'product'" class="col-6 mt-3 col-lg-6 mt-lg-0 col-xl-2 mt-xl-0">
          <label :class="[{'d-xl-none': firstRow === false}]">Inkoopprijs</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i><font-awesome-icon :icon="['fad', 'euro-sign']" /></i>
              </span>
            </div>
            <input type="number" class="form-control" :value="price.product.cost_priceFormat" disabled="disabled" />
          </div>
        </div>

        <div :class="[{'col-6 mt-3 col-lg-4 col-xl-2 mt-xl-0': price.type === 'product'}, {'col-6 mt-3 col-lg-6 mt-lg-0 col-xl-2 mt-xl-0': price.type === 'session_product'}]">
          <label :class="[{'d-xl-none': firstRow === false}]">Prijs excl BTW</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i><font-awesome-icon :icon="['fad', 'euro-sign']" /></i>
              </span>
            </div>
            <input type="number" ref="input.price" class="form-control" v-model.number.lazy="$v.price.price_formatted.$model" :class="[{'is-invalid': $v.price.price_formatted.$error}]" />
          </div>
        </div>

        <div class="col-6 mt-3 col-lg-4 mt-lg-3 col-xl-2 mt-xl-0">
          <label :class="[{'d-xl-none': firstRow === false}]">BTW</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i><font-awesome-icon :icon="['fad', 'percentage']" /></i>
              </span>
            </div>
            <input type="text" class="form-control" :value="`${price.VAT}%`" disabled="disabled" />
          </div>
        </div>

        <div class="col-6 mt-3 col-lg-4 mt-lg-3 col-xl-2 mt-xl-0">
          <label :class="[{'d-xl-none': firstRow === false}]">Prijs incl BTW</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i><font-awesome-icon :icon="['fad', 'euro-sign']" /></i>
              </span>
            </div>
            <input type="number" class="form-control" v-model.number.lazy="$v.price.price_incl_vat_formatted.$model" :class="[{'is-invalid': $v.price.price_incl_vat_formatted.$error}]" />
          </div>
        </div>

        <div v-if="price.type === 'session_product'" class="col-6 mt-3 col-lg-4 mt-lg-3 col-xl-2 mt-xl-0">
          <label :class="[{'d-xl-none': firstRow === false}]">Tegoed</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i><font-awesome-icon :icon="['fad', 'credit-card']" /></i>
              </span>
            </div>
            <input type="number" class="form-control" v-model.number.lazy="$v.price.credit_price_formatted.$model" :class="[{'is-invalid': $v.price.credit_price_formatted.$error}]" />
          </div>
        </div>

      </div>

    </div>


    <div class="d-none d-lg-block d-xl-none col-1 mt-5">
      <KButton variant="light" class="btn-block label-margin" @click.native.prevent="removePrice(price.product._meta.guid)">
        <font-awesome-icon icon="trash" />
      </KButton>
    </div>

    <div class="d-none d-xl-block col-1">
      <KButton variant="light" class="btn-block" :class="[{'label-margin': firstRow === true}]" @click.native.prevent="removePrice(price.product._meta.guid)">
        <font-awesome-icon icon="trash" />
      </KButton>
    </div>

    <KSeparator v-if="lastRow === false" class="col-12 d-xl-none" large />
  </div>
</template>

<script>
// Classes
import PricelistPrice from '@/libs/classes/pricelist.price';
import SessionPricelistPrice from '@/libs/classes/session_pricelist.price';

// Validators
import { required, requiredIf, minValue } from 'vuelidate/lib/validators';
import { currency } from '@/libs/validators';

export default {
  props: {
    firstRow: Boolean,
    lastRow: Boolean,
    price: [PricelistPrice, SessionPricelistPrice],

    removePrice: Function
  },
  validations: {
    price: {
      price_formatted: {
        required,
        currency,
        minValue: minValue(0)
      },
      price_incl_vat_formatted: {
        required,
        currency,
        minValue: minValue(0)
      },
      margin: {

      },
      credit_price_formatted: {
        required: requiredIf(function() {
          return this.price.type === 'session_product'
        })
      }
    }
  },
  methods: {
    focus(input = 'price') {
      if(typeof this.$refs[`input.${input}`] !== typeof undefined) {
        this.$refs[`input.${input}`].focus();
      }
    }
  }
}
</script>
