<template v-if="equipment">
  <div class="row">
    <div class="col-4 col-lg-3 col-xl-2 mt-auto mb-auto">
      <equipment-circle
        ref="circle"
        :equipment="equipment"
        style="width: 100%; height: auto; text-align:center;"
        class="mt-auto mb-auto"

        use-full-time
        circle-class="w-75 h-75"
      />
    </div>

    <div class="col-8 col-lg-9 col-xl-10 mt-auto mb-auto">
      <h3>{{ stateText }}</h3>
    </div>
  </div>
</template>

<script>
// Classes
import Equipment from '@/libs/classes/equipment';

// Components
import equipmentCircle from '@/components/equipment/components/circle';

export default {
  components: {
    equipmentCircle
  },
  props: {
    equipment: {
      type: Equipment,
      default: null
    }
  },

  computed: {
    stateText: {
      get () {
        if (this.equipment) {
          if (this.equipment.state === 'powerup') return 'Het apparaat is aan het opstarten';
          if (this.equipment.state === 'available') return 'Het apparaat is klaar om te starten';
          if (this.equipment.state === 'delay') return 'De voorlooptijd is actief';
          if (this.equipment.state === 'session') return 'De sessie is actief';
          if (this.equipment.state === 'cooldown') return 'De nakoeling is actief';
          if (this.equipment.state === 'cleaning') return 'Schoonmaken vereist';

          if (this.equipment.state === 'timeout') return 'De communicatie is verbroken';
        }

        return 'Onbekende status van apparaat';
      }
    }
  }
};
</script>
