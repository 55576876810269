<template>
  <div class="row border-bottom pt-3 pb-3">
    <div
      v-if="(!equipment.communication || !equipment.communication.session)"
      class="col-12"
    >
      <KAlert
        :icon="['fad', 'exclamation-triangle']"
        variant="outline-warning"
      >
        Sessie informatie niet gevonden
      </KAlert>
    </div>

    <div
      v-else
      class="col-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2"
    >
      <div class="row">
        <div class="col-4 text-center">
          <h5 class="font-weight-normal">Voorlooptijd</h5>
          <h3>{{ $format.secondsToMinutes(equipment.communication.session.delay) }} {{ ($format.secondsToMinutes(equipment.communication.session.delay) === 1 ? 'minuut' : 'minuten') }}</h3>
          <span
            v-if="equipment.state === 'delay'"
            class="text-equipment-delay cursor-pointer"
            @click.prevent="$eventhub.emit('modals:equipment:edit-prerun-time:open', {
              equipment: equipment
            })"
          >
            Wijzigen
          </span>
        </div>

        <div class="col-4 text-center">
          <h5 class="font-weight-normal">Sessie</h5>
          <h3>{{ $format.secondsToMinutes(equipment.communication.session.time) }} {{ ($format.secondsToMinutes(equipment.communication.session.time) === 1 ? 'minuut' : 'minuten') }}</h3>
        </div>

        <div class="col-4 text-center">
          <h5 class="font-weight-normal">Nalooptijd</h5>
          <h3>{{ $format.secondsToMinutes(equipment.communication.session.cooldown) }} {{ ($format.secondsToMinutes(equipment.communication.session.cooldown) === 1 ? 'minuut' : 'minuten') }}</h3>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-4 text-center">
          <h5 class="font-weight-normal">Quickstart</h5>
          <div class="row">
            <div class="col-12 text-center">
              <span :class="['kt-switch', {'kt-switch--success': equipment.communication.session.options.external_start}]">
                <label>
                  <input
                    :checked="equipment.communication.session.options.external_start"

                    type="checkbox"
                    disabled
                  >
                  <span />
                </label>
              </span>
            </div>
          </div>
        </div>

        <div class="col-4 text-center">
          <h5 class="font-weight-normal">Deurcontact</h5>
          <div class="row">
            <div class="col-12 text-center">
              <span :class="['kt-switch', {'kt-switch--success': equipment.communication.session.options.door_contact}]">
                <label>
                  <input
                    :checked="equipment.communication.session.options.door_contact"

                    type="checkbox"
                    disabled
                  >
                  <span />
                </label>
              </span>
            </div>
          </div>
        </div>

        <div class="col-4 text-center">
          <h5 class="font-weight-normal">Schoonmaken</h5>
          <div class="row">
            <div class="col-12 text-center">
              <span :class="['kt-switch', {'kt-switch--success': equipment.communication.session.options.cleaning_required}]">
                <label>
                  <input
                    :checked="equipment.communication.session.options.cleaning_required"

                    type="checkbox"
                    disabled
                  >
                  <span />
                </label>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Classes
import Equipment from '@/libs/classes/equipment';

export default {
  props: {
    equipment: {
      type: Equipment,
      default: null
    }
  }
};
</script>
