import Abstract from './abstract';

import { merge } from 'lodash';

import { apiWS as ws } from '@/libs/ws';

import CashRegisterOpening from './cash_register.opening';

class CashRegister extends Abstract {
  constructor (guid = null) {
    super(guid);
    this.uri = '/cash_registers';

    let defaultObject = {
      name: null,
      open: false,

      latest_opening: null
    };

    merge(this, defaultObject);
  }

  // Methods
  async populate (type = 'get', options = {}) {
    this.latest_opening = await this.getLatestOpening(options);

    return this;
  }

  async getLatestOpening (options = {}) {
    let opening = await ws.get('v1', `/cash_registers/${this.guid}/latest`, options);
    this.latest_opening = new CashRegisterOpening().mergeResponse(opening);

    return this.latest_opening;
  }
};

export default CashRegister;
