<template>
  <div v-if="isLoaded === false">
    <!-- Modals -->
    <modals></modals>
  </div>
  <KRoot
    v-else-if="isLoaded === true && (authenticated === true || $route.meta.hide_layout === true)"
    :class="[{'cs-dev': this.$environment === 'development'}]"
    aside
    header
  >
    <template v-if="$route.meta.hide_layout === true">
      <router-view />
    </template>

    <template v-if="$route.meta.hide_layout !== true && activeStore">
      <KHeaderMobile
        :logo="require('@/assets/images/logo/caesium.white.full.svg')"
        href="#"
      />

      <KPage v-if="activeStore">
        <!-- Aside menu -->
        <partialAside

        />

        <!-- Page wrapper -->
        <KWrapper>
          <!-- Header -->
          <partialHeader

          />

          <!-- View -->
          <router-view
            class="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor pt-0"
          />

          <!-- Footer -->
          <partial-footer
            ref="partialFooter"
          />
        </KWrapper>
      </KPage>
    </template>

    <!-- Modals -->
    <modals></modals>
  </KRoot>

  <!-- Sign in page -->
  <auth-signin
    v-else-if="isLoaded === true && authenticated !== true"
  />
</template>

<script>
// Libraries
import stores from '@/libs/stores';
import equipment from '@/libs/equipment';

// Directives
import '@/directives/keydown';
import '@/directives/click';

// Components
import partialAside from './components/partials/aside/';
import partialHeader from './components/partials/header/';
import partialFooter from '@/components/partials/footer';

import authSignin from '@/components/auth/signin';
import modals from '@/components/modals/index';

export default {
  components: {
    partialAside,
    partialHeader,
    partialFooter,

    authSignin,
    modals
  },

  data () {
    return {
      isLoaded: false,
      authenticated: false,

      activeStore: null
    };
  },

  async mounted () {
    this.$eventhub.on('auth:signout', () => {
      this.isLoaded = true;
    });

    this.addListeners();
    await this.$auth.authenticate();

    if (this.$route.meta && this.$route.meta.hide_layout === true) {
      this.isLoaded = true;
      return;
    }

    this.authenticated = this.$auth.authenticated;

    this.isLoaded = true;

    if (this.authenticated === true) {
      await this.getActiveStore();

      // Equipment
      try {
        await equipment.pingMasters();
      } catch (e) {
        console.error('Ping masters error', e);
      }
    }
  },
  beforeDestroy () {
    this.$eventhub.removeListener('auth:authenticated');
    this.$eventhub.removeListener('auth:signout');
  },

  methods: {
    addListeners () {
      this.$eventhub.on('auth:authenticated', async (authenticated) => {
        if (this.authenticated !== true && authenticated === true) {
          const hasPermission = this.$auth.hasPermission(this.$route.meta.scope);
          if (hasPermission === false) {
            // Hide all content because the reload isn't instant
            this.isLoaded = false;
            window.location.reload();
            return;
          }

          // Only check for active store when layout is not hidden (so Caesium Cloud only)
          // Solution is used for the second screen, because it keeps refreshing when listening on store change
          if (this.$route.meta.hide_layout !== true) {
            await this.getActiveStore();
          }
        }

        this.authenticated = authenticated;
      });
    },

    async getActiveStore () {
      this.activeStore = await stores.getActiveStore();
      this.$eventhub.on('stores:switched', async () => {
        this.activeStore = await stores.getActiveStore();
        this.$router.go();
      });
    }
  }
};
</script>

<style scoped>
.router-link-active {
  font-weight: bold;
}
</style>
