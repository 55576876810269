import * as Sentry from '@sentry/browser';

import auth from '@/libs/auth';

export default class Logger {
  constructor (name) {
    return {
      debug: (...logs) => {
        Logger.handle(false, 'debug', name, logs);
      },

      info: (...logs) => {
        Logger.handle(true, 'info', name, logs);
      },

      warn: (...logs) => {
        Logger.handle(true, 'warn', name, logs);
      },

      error: (...logs) => {
        Logger.handle(true, 'error', name, logs);
      }
    };
  }

  static handle (sendToSentry, type, name, logs) {
    let consoleType = type;
    if (type === 'debug') {
      consoleType = 'log';
    }
    console[consoleType]('[' + type.toUpperCase() + '] - ' + name + ': ', ...logs);

    const sentryFormat = logs.map((log) => {
      if (typeof log === 'object') {
        let stringified = ' {stringify_err} ';
        try {
          stringified = JSON.stringify(log);
        } catch (e) {}
      }
      return log;
    }).join(' ');
    const message = '[' + type.toUpperCase() + '] - ' + name + ': ' + sentryFormat;
    if (sendToSentry === true) {
      if (auth.resource) {
        Sentry.configureScope((scope) => {
          scope.setUser(auth.resource);
        });
      }
      Sentry.captureMessage(message);
    } else {
      Sentry.addBreadcrumb({
        category: name,
        message: sentryFormat,
        level: Sentry.Severity.Debug
      });
    }
  }
}