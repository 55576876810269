import Abstract from './abstract';

// Libraries
import { merge } from 'lodash';

class StockMutation extends Abstract {
  constructor (guid = null) {
    super(guid);
    this.uri = '/stock_mutations';

    let defaultObject = {
      type: null,
      stock_batch_guid: null,
      product: null,
      user_input: null,
      previous_stock: null,
      quantity_mutation: null
    };

    merge(this, defaultObject);
  }

  // Getters & Setters
  get VUID () {
    return `stock_mutation.${this.product._meta.guid}`;
  }
}

export default StockMutation;
