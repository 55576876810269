<template>
  <div class="col-12">
    <div class="row">
      <div class="col-4">
        <KButton
          :disabled="(isLoading)"

          variant="metal"
          class="btn-block"

          @click.native.prevent="$eventhub.emit('modals:shop:shop-options:open')"
        >
          Opties
        </KButton>
      </div>

      <div class="col-8">
        <template v-if="meta.shop.action === 'CART'">
          <KButton
            key="shop.buttons.navigate.payment_methods"

            :disabled="(canGoToPaymentMethods === false || isLoading)"

            variant="focus"
            class="btn-block"

            @click.native.prevent="goToPaymentMethods"
          >
            Doorgaan
          </KButton>
        </template>

        <template v-else-if="meta.shop.action === 'PAYMENTS'">
          <KButton
            v-if="salesOrder"
            key="shop.buttons.navigate.finish"

            :disabled="(salesOrder.outstanding_balance > 0 || isLoading)"

            variant="focus"
            class="btn-block"

            @click.prevent.native="finishSalesOrder()"
          >
            Afronden
          </KButton>
        </template>

        <template v-else-if="meta.shop.action === 'FINISH'">
          <KButton
            v-if="salesOrder"
            key="shop.buttons.navigate.clear"

            :disabled="(salesOrder.paid === false || isLoading)"

            variant="primary"
            class="btn-block"

            @click.prevent.native="startNewSalesOrder()"
          >
            Nieuwe verkoop
          </KButton>
        </template>
      </div>

    </div>
  </div>
</template>

<script>
// Mixins
import shop from '@/mixins/shop';

// Classes
import SalesOrder from '@/libs/classes/salesorder';

export default {
  mixins: [shop],
  props: {
    meta: {
      type: Object,
      default: null
    },
    salesOrder: {
      type: SalesOrder,
      default: null
    }
  },
  data () {
    return {
      isLoading: false
    };
  },
  computed: {
    canGoToPaymentMethods () {
      if (!this.salesOrder || !this.salesOrder.lines || !this.salesOrder.lines.length) {
        return false;
      }

      return true;
    }
  },
  methods: {
    setLoading (loading = null) {
      if (typeof loading === 'boolean') {
        this.$set(this, 'isLoading', loading);
        this.$eventhub.emit('shop:cart:set-loading', loading);
      }
      return this.isLoading;
    },

    goToPaymentMethods () {
      if (this.canGoToPaymentMethods === false) return;

      // Check if customer is required
      if (this.salesOrder.lines.some(l => l.requires_customer === true) && !this.salesOrder.customer) {
        const vm = this;
        this.$eventhub.emit('modals:shop:identify-customer:open', {
          method: 'SEARCH',
          onCustomerSelected: function (customer) {
            // Run function again
            vm.$nextTick(() => {
              vm.goToPaymentMethods();
            });
          }
        });
        return;
      }

      return this.navigateTo('/sales/shop/:cash_register/payments/overview');
    },
    async finishSalesOrder () {
      this.setLoading(true);

      let hasError = false;
      try {
        if (!this.salesOrder) {
          throw new Error('Sales order not found');
        }

        if (this.salesOrder.outstanding_balance > 0) {
          throw new Error('Outstanding balance more than 0');
        }

        await this.shop.finishSalesOrder();
      } catch (e) {
        console.error(e);

        // Show authentication modal when customerAuthenticationRequired error occurs
        if (e.info === 'customerAuthenticationRequired') {
          const vm = this;
          this.shop.sales_order.customer.authenticated = false;
          this.$eventhub.emit('modals:shop:identify-customer:open', {
            method: 'IDENTIFY',
            onCustomerAuthenticated: function (customer) {
              // Run function again
              vm.finishSalesOrder();
            }
          });
        } else if (e.info === 'requiresRemark') {
          const vm = this;
          this.$eventhub.emit('modals:shop:salesorder-settings:open', {
            salesorder: this.shop.sales_order,
            hideDiscount: true,
            async onSubmit (salesorder) {
              // Save remark
              await vm.shop.setRemark(salesorder.remark);

              // Run function again
              vm.$nextTick(() => vm.finishSalesOrder());
            }
          });
        } else {
          // Get error and open modal
          this.$error.showModal(e);
        }

        hasError = true;
      }

      this.setLoading(false);

      if (!hasError) {
        return this.navigateTo('/sales/shop/:cash_register/finish');
      }
    },
    startNewSalesOrder () {
      this.shop.removeSalesOrder();
      return this.navigateTo('/sales/shop');
    }
  }
};
</script>
