// Classes
import BundleProduct from './bundle_product';

// Libraries
import { merge } from 'lodash';

class BundleProductProducts extends BundleProduct {
  constructor (guid = null) {
    super(guid);
    this.type = 'product_bundle';

    let defaultObject = {
      bundle_limit: {
        type: 'months',
        value: 0
      },

      requires_customer: false
    };

    merge(this, defaultObject);
  }

  // Methods
  formatBody (type = 'get', options = {}) {
    let body = super.formatBody(type, options);

    // Empty the sessions
    body.sessions = [];

    // Set bundle limit value to 0, because this is always 0 for a product bundle
    body.bundle_limit = {
      type: 'months',
      value: 0
    };

    return body;
  }
}

export default BundleProductProducts;
