<template>
  <KPortlet>
    <KPortletHead>
      Kaspositie
    </KPortletHead>
    <KPortletBody class="kt-portlet__body--fit">
      <table class="table mb-0">
        <thead class="thead-light thead-light--fit">
          <tr>
            <th>Omschrijving</th>
            <th class="text-right">Bedrag</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Beginstand kassa</td>
            <td class="text-right">
              &euro; {{ $format.currency(cashRegisterOpening.open_amount_cash) }}
            </td>
          </tr>
          <tr>
            <td>Inkomsten</td>
            <td class="text-right">
              &euro; {{ $format.currency(cashRegisterOpening.cashRevenue.incoming) }}
            </td>
          </tr>
          <tr>
            <td>Uitgaven</td>
            <td class="text-right">
              &euro; {{ $format.currency(cashRegisterOpening.cashRevenue.outgoing * -1) }}
            </td>
          </tr>
        </tbody>
        <thead class="thead-light thead-light--fit">
          <tr>
            <th>Resultaat</th>
            <th class="text-right">&euro; {{ $format.currency(subtotal) }}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Afstorting</td>
            <td class="text-right">
              &euro; {{ $format.currency(cashRegisterOpening.cash_amount_in) }}
            </td>
          </tr>
        </tbody>
        <tfoot class="thead-light thead-light--fit">
          <tr>
            <th>Wisselgeld volgende dag</th>
            <th class="text-right">&euro; {{ $format.currency(total) }}</th>
          </tr>
        </tfoot>
      </table>
    </KPortletBody>
  </KPortlet>
</template>

<script>
// Classes
import CashRegisterOpening from '@/libs/classes/cash_register.opening';

export default {
  props: {
    cashRegisterOpening: {
      type: CashRegisterOpening,
      default: null
    }
  },
  computed: {
    subtotal: {
      get () {
        let amount = 0;

        // Start with cash register open amount
        amount = this.cashRegisterOpening.open_amount_cash;

        // Add incoming
        amount = amount + this.cashRegisterOpening.cashRevenue.incoming;

        // Subtract outgoing
        amount = amount - this.cashRegisterOpening.cashRevenue.outgoing;

        return amount;
      }
    },
    total: {
      get () {
        let amount = 0;

        // Start with the subtotal
        amount = this.subtotal;

        // Add deposit
        amount = amount + this.cashRegisterOpening.cash_amount_in;

        return amount;
      }
    }
  }
};
</script>
