// Libraries
import { merge } from 'lodash';

// Classes
import Abstract from './abstract';

class Integration extends Abstract {
  constructor (guid = null) {
    super(guid);
    this.uri = '/integrations';

    let defaultObject = {
      name: null,
      granted_permissions: {}
    };

    merge(this, defaultObject);
  }
};

export default Integration;
