// Libraries
import { merge } from 'lodash';

export default class EquipmentHourcounter {
  constructor () {
    let defaultObject = {
      name: null,
      remark: null,
      current: 0,
      max: null,
      warning: null,

      created: null,
      last_reset: null
    };

    merge(this, defaultObject);
  }

  // Methods
  merge (response = {}) {
    merge(this, response);
    return this;
  }

  // Getters & setters
  get currentHours () {
    const value = Number(this.current);
    if (this.current === null || isNaN(value)) return null;
    return (value / 60 / 60);
  }
  set currentHours (value) {
    value = Number(value);
    if (isNaN(value)) return;
    this.current = (value * 60 * 60);
  }

  get maxHours () {
    const value = Number(this.max);
    if (this.max === null || isNaN(value)) return null;
    return (value / 60 / 60);
  }
  set maxHours (value) {
    value = Number(value);
    if (isNaN(value)) return;
    this.max = (value * 60 * 60);
  }

  get percentage () {
    return (this.current / this.max);
  }

  get hoursLeft () {
    return (this.current - this.max);
  }

  get isAboveWarning () {
    return (this.warning && (this.percentage * 100) >= this.warning);
  }
};
