<template>
  <div class="row form-group">
    <!-- Loading -->
    <div v-if="isMounted === false" class="col-12 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
      <div class="loading-container">
        <div class="loading-block">
          <div class="blockui ml-auto mr-auto w-100">
            <span>Gegevens ophalen...</span>
            <span class="kt-spinner kt-spinner--v2 kt-spinner--primary"></span>
          </div>
        </div>
      </div>
    </div>

    <!-- Error when no master connected -->
    <div v-else-if="!masters || masters.length === 0" class="col-12">
      <KAlert variant="outline-focus" :icon="['fad', 'microchip']">Er is nog geen hardware verbonden met uw huidige winkel.</KAlert>
    </div>

    <!-- Error when no masters online -->
    <div v-else-if="onlineMasters.length === 0" class="col-12">
      <KAlert variant="outline-focus" :icon="['fad', 'wifi-slash']">
        U bent niet verbonden met een Caesium Core
      </KAlert>
    </div>

    <!-- Error when no masters have a fingerprint scanner connected -->
    <div v-else-if="mastersWithFingerprint.length === 0" class="col-12">
      <KAlert variant="outline-focus" :icon="['fad', 'fingerprint']">
        Geen van uw aangesloten Caesium Core's heeft een vingerafdruk scanner gekoppeld
      </KAlert>
    </div>

    <!-- Fingerprint scan information -->
    <div v-else class="col-12">
      <!-- <div class="text-center">Master <strong>{{ master.name }}</strong></div> -->

      <div class="row">
        <div class="col-12 col-lg-6 col-xl-4 offset-xl-2">
          <fingerprint-section
            :action="action"

            :master="master"
            :customer="customer"
            :fingerprint="customer.fingerprint_keys[0]"
            :disabled="disabled"
          ></fingerprint-section>
        </div>

        <div class="col-12 mt-3 col-lg-6 mt-lg-0 col-xl-4">
          <fingerprint-section
            :action="action"

            :master="master"
            :customer="customer"
            :fingerprint="customer.fingerprint_keys[1]"
            :disabled="disabled"
          ></fingerprint-section>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
// Libraries
import equipment from '@/libs/equipment';

// Classes
import Customer from '@/libs/classes/customer';

// Components
import fingerprintSection from '@/components/customers/components/authentication/fingerprint.section';

export default {
  props: {
    customer: Customer,

    action: String,

    disabled: Boolean,
    placeholder: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      isMounted: false,

      master: null,
      masters: null
    }
  },
  components: {
    fingerprintSection
  },
  computed: {
    onlineMasters: {
      get () {
        if (this.masters && this.masters.length > 0) {
          return this.masters.filter(m => m.socket && m.socket.online === true);
        }
        return [];
      }
    },
    mastersWithFingerprint: {
      get () {
        return this.onlineMasters.filter(m => m.peripherals && m.peripherals.fingerprint && m.peripherals.fingerprint.connected === true);
      }
    }
  },

  async mounted () {
    // Add masters
    this.masters = await equipment.getMasters();

    // Set master
    if (this.mastersWithFingerprint.length > 0) {
      this.$set(this, 'master', this.mastersWithFingerprint[0]);
    }

    // Listeners
    this.$eventhub.on('equipment:masters:updated', this.onMastersUpdated);

    this.$nextTick(() => {
      this.isMounted = true;
    })
  },
  beforeDestroy () {
    // Remove Listeners
    this.$eventhub.off('equipment:masters:updated', this.onMastersUpdated);
  },

  methods: {
    onMastersUpdated (masters) {
      if (typeof masters === 'object' && Array.isArray(masters)) {
        this.$set(this, 'masters', null);

        // Set master
        this.$nextTick(() => {
          this.$set(this, 'masters', masters);

          if (this.mastersWithFingerprint.length > 0) {
            this.$set(this, 'master', this.mastersWithFingerprint[0]);
          } else {
            this.$set(this, 'master', null);
          }
        });
      }
    }
  }
}
</script>
