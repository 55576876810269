<template>
  <KPortlet>
    <KPortletHead>
      Totalen
    </KPortletHead>
    <KPortletBody class="kt-portlet__body--fit position-relative">
      <!-- Loading block -->
      <template v-if="isLoading">
        <div class="loading-container absolute">
          <div class="loading-backdrop">
            <div class="loading-block">
              <div class="blockui">
                <span>Please wait...</span>
                <span class="kt-spinner kt-spinner--v2 kt-spinner--primary" />
              </div>
            </div>
          </div>
        </div>
      </template>

      <!-- Table -->
      <div class="table-responsive">
        <table class="table mb-0">
          <thead class="thead-light thead-light--fit">
            <tr>
              <th class="overflow-ellipsis">Omschrijving</th>
              <th class="table-field-currency overflow-ellipsis">Bedrag</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="overflow-ellipsis">Omzet producten</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency((totalsPeriod ? totalsPeriod.revenue.products.excl_vat : 0)) }}</td>
            </tr>
            <tr>
              <td class="overflow-ellipsis">Omzet pakketten</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency((totalsPeriod ? (totalsPeriod.revenue.bundles.excl_vat + totalsPeriod.revenue.subscriptions.excl_vat + totalsPeriod.revenue.treatments.excl_vat + totalsPeriod.revenue.product_bundles.excl_vat) : 0)) }}</td>
            </tr>
            <tr>
              <td class="overflow-ellipsis">Omzet losse sessies</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency((totalsPeriod ? totalsPeriod.revenue.sessions.excl_vat : 0)) }}</td>
            </tr>
            <tr>
              <td class="overflow-ellipsis">Omzet sessiekrediet</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency((totalsPeriod ? totalsPeriod.revenue.credit_products.excl_vat : 0)) }}</td>
            </tr>
          </tbody>
          <thead class="thead-light thead-light--fit">
            <tr>
              <th class="overflow-ellipsis">Totale omzet</th>
              <th class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(totalRevenue) }}</th>
            </tr>
            <tr>
              <th class="overflow-ellipsis">Totale omzet incl. BTW</th>
              <th class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(totalRevenueInclVAT) }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="overflow-ellipsis">Verkochte cadeaubonnen</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency((totalsPeriod ? totalsPeriod.revenue.giftcards.excl_vat : 0)) }}</td>
            </tr>
            <tr>
              <td class="overflow-ellipsis">Totaal korting</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency((totalsPeriod ? totalsPeriod.discount_given : 0)) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </KPortletBody>
  </KPortlet>
</template>

<script>
export default {
  props: {
    totalsPeriod: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      isLoading: false,

      totalRevenueKeys: ['products', 'bundles', 'subscriptions', 'treatments', 'product_bundles', 'sessions', 'credit_products']
    };
  },
  computed: {
    totalRevenue: {
      get () {
        let value = 0;

        // Calculate total revenue based on keys
        if (this.totalsPeriod && this.totalsPeriod.revenue) {
          this.totalRevenueKeys.forEach((key) => {
            if (this.totalsPeriod.revenue[key]) {
              value = value + this.totalsPeriod.revenue[key].excl_vat;
            }
          });
        }

        return value;
      }
    },
    totalRevenueInclVAT: {
      get () {
        let value = 0;

        // Calculate total revenue based on keys
        if (this.totalsPeriod && this.totalsPeriod.revenue) {
          this.totalRevenueKeys.forEach((key) => {
            if (this.totalsPeriod.revenue[key]) {
              value = value + this.totalsPeriod.revenue[key].incl_vat;
            }
          });
        }

        return value;
      }
    }
  }
};
</script>
