<template>
  <div>
    <div class="row border-bottom pb-3">
      <div class="col-12">
        <table class="table mb-0">
          <thead class="thead-light">
            <tr>
              <th style="width:20%;">Betalingswijze</th>
              <th style="width:15%">Verwacht</th>
              <th style="width:20%">Geteld</th>
              <th style="width:20%">Verschil</th>
            </tr>
          </thead>
          <tbody>
            <tr class="input-height">
              <td class="align-middle">Contant</td>
              <td class="align-middle">&euro; {{ (meta && meta.cash ? $format.currency(meta.cash) : $format.currency(0)) }}</td>
              <td class="align-middle">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i>
                        <font-awesome-icon :icon="['fad', 'euro-sign']" />
                      </i>
                    </span>
                  </div>
                  <input
                    v-model.number.lazy="$v.cash_register_opening.close_amount_cash_counted_formatted.$model"
                    :class="['form-control', {'is-invalid': $v.cash_register_opening.close_amount_cash_counted_formatted.$error}]"
                    type="number"
                  >
                  <div class="input-group-prepend">
                    <span
                      class="input-group-text cursor-pointer"
                      @click.prevent="$eventhub.emit('modals:calculator:open', {
                        input: 'close_amount_cash_counted'
                      })"
                    >
                      <i class="kt-font-focus">
                        <font-awesome-icon :icon="['fad', 'calculator']" />
                      </i>
                    </span>
                  </div>
                </div>
              </td>
              <td class="align-middle">&euro; {{ $format.currency(cash_amount_difference) }}</td>
            </tr>
            <tr class="input-height">
              <td class="align-middle">PIN</td>
              <td class="align-middle">&euro; {{ (meta && meta.card ? $format.currency(meta.card) : $format.currency(0)) }}</td>
              <td class="align-middle">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i><font-awesome-icon :icon="['fad', 'euro-sign']" /></i>
                    </span>
                  </div>
                  <input
                    v-model.number.lazy="$v.cash_register_opening.close_amount_card_counted_formatted.$model"
                    :class="['form-control', {'is-invalid': $v.cash_register_opening.close_amount_card_counted_formatted.$error}]"
                    type="number"
                  >
                </div>
              </td>
              <td class="align-middle">&euro; {{ $format.currency(card_amount_difference) }}</td>
            </tr>
            <tr class="input-height">
              <td class="align-middle">Op rekening</td>
              <td class="align-middle">&euro; {{ (meta && meta.on_account ? $format.currency(meta.on_account) : $format.currency(0)) }}</td>
              <td />
              <td />
            </tr>
            <tr class="input-height">
              <td class="align-middle">Cadeaubon</td>
              <td class="align-middle">&euro; {{ (meta && meta.giftcard ? $format.currency(meta.giftcard) : $format.currency(0)) }}</td>
              <td />
              <td />
            </tr>
          </tbody>
          <tfoot class="thead-light">
            <tr>
              <th class="align-middle">Totaal</th>
              <th class="align-middle">&euro; {{ $format.currency(total_expected) }}</th>
              <th class="align-middle">&euro; {{ $format.currency(total_counted) }}</th>
              <th class="align-middle">&euro; {{ $format.currency(total_difference) }}</th>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>

    <div class="row mt-3">
      <!-- <div class="col-12 offset-lg-3 col-lg-6 border-bottom">
        <table class="table mb-0">
          <thead class="thead-light">
            <tr>
              <th style="width:50%;">Afstorting</th>
              <th style="width:50%;">In kas na afstorting</th>
            </tr>
          </thead>
          <tbody>
            <tr class="input-height">
              <td class="align-middle">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i><font-awesome-icon :icon="['fad', 'euro-sign']" /></i>
                    </span>
                  </div>
                  <input
                    :class="['form-control', {'is-invalid': $v.cash_register_opening.cash_amount_out_formatted.$error}]"
                    v-model.number.lazy="$v.cash_register_opening.cash_amount_out_formatted.$model"
                    type="number"
                  >
                  <div class="input-group-prepend">
                    <span
                      class="input-group-text cursor-pointer"
                      @click.prevent="$eventhub.emit('modals:calculator:open', {
                        input: 'cash_amount_out'
                      })"
                    >
                      <i class="kt-font-focus"><font-awesome-icon :icon="['fad', 'calculator']" /></i>
                    </span>
                  </div>
                </div>
              </td>
              <td class="align-middle">
                &euro; {{ cash_register_opening.cash_amount_out_formatted }}
              </td>
            </tr>
          </tbody>
        </table>
      </div> -->

      <div class="col-12 mt-3 offset-lg-3 col-lg-6">
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label>Afstorting</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <i><font-awesome-icon :icon="['fad', 'euro-sign']" /></i>
                  </span>
                </div>
                <input
                  :class="['form-control', {'is-invalid': $v.cash_register_opening.cash_amount_out_formatted.$error}]"
                  v-model.number.lazy="$v.cash_register_opening.cash_amount_out_formatted.$model"
                  type="number"
                >
                <div class="input-group-prepend">
                  <span
                    class="input-group-text cursor-pointer"
                    @click.prevent="$eventhub.emit('modals:calculator:open', {
                      input: 'cash_amount_out'
                    })"
                  >
                    <i class="kt-font-focus"><font-awesome-icon :icon="['fad', 'calculator']" /></i>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="col-6">
            <label>In kas na afstorting</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i><font-awesome-icon :icon="['fad', 'euro-sign']" /></i>
                </span>
              </div>
              <input
                :value="$format.currency(cash_register_opening.close_amount_cash_counted - cash_register_opening.cash_amount_out)"
                class="form-control"
                type="text"
                disabled
              >
            </div>
          </div>
        </div>

        <div class="form-group">
          <label>Opmerking <sup v-if="cash_amount_difference !== 0 || card_amount_difference !== 0">Verplicht</sup></label>
          <textarea
            :class="['form-control', {'is-invalid': $v.cash_register_opening.close_remark.$error}]"
            v-model.trim="$v.cash_register_opening.close_remark.$model"
            rows="3"
          />
        </div>
      </div>
    </div>

  </div>
</template>

<script>
// Classes
import CashRegister from '@/libs/classes/cash_register';
import CashRegisterOpening from '@/libs/classes/cash_register.opening';

// Validations
import { required, requiredIf, minValue } from 'vuelidate/lib/validators';
import { currency } from '@/libs/validators';

export default {
  props: {
    cash_register: {
      type: CashRegister,
      default: null
    },
    cash_register_opening: {
      type: CashRegisterOpening,
      default: null
    },
    meta: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    total_expected: {
      get () {
        let total = 0;
        // Calculate total
        if (this.meta.cash) total = total + this.meta.cash;
        if (this.meta.card) total = total + this.meta.card;
        if (this.meta.on_account) total = total + this.meta.on_account;
        if (this.meta.giftcard) total = total + this.meta.giftcard;
        return total;
      }
    },
    total_counted: {
      get () {
        let total = 0;
        total = total + this.cash_register_opening.close_amount_cash_counted;
        total = total + this.cash_register_opening.close_amount_card_counted;
        return total;
      }
    },

    cash_amount_difference: {
      get () {
        let difference = 0;
        if (this.meta.cash && this.meta.cash !== null) difference = this.meta.cash;
        if (this.cash_register_opening.close_amount_cash_counted && this.cash_register_opening.close_amount_cash_counted !== null) difference = difference - this.cash_register_opening.close_amount_cash_counted;
        return difference;
      }
    },
    card_amount_difference: {
      get () {
        let difference = 0;
        if (this.meta.card && this.meta.card !== null) difference = this.meta.card;
        if (this.cash_register_opening.close_amount_card_counted && this.cash_register_opening.close_amount_card_counted !== null) difference = difference - this.cash_register_opening.close_amount_card_counted;
        return difference;
      }
    },
    total_difference: {
      get () {
        return this.cash_amount_difference + this.card_amount_difference;
      }
    }
  },
  validations: {
    cash_register_opening: {
      close_amount_cash_counted_formatted: {
        required,
        currency,
        minValue: minValue(0)
      },
      cash_amount_out_formatted: {
        currency
      },
      close_amount_card_counted_formatted: {
        required,
        currency,
        minValue: minValue(0)
      },
      close_remark: {
        requiredIf: requiredIf(function (value) {
          return (this.cash_amount_difference !== 0 || this.card_amount_difference !== 0);
        })
      }
    }
  },
  mounted () {
    // Listeners
    this.$eventhub.on('modals:calculator:submit', this.onCalculatorSubmit);
  },
  beforeDestroy () {
    // Remove listeners
    this.$eventhub.off('modals:calculator:submit', this.onCalculatorSubmit);
  },

  methods: {
    onCalculatorSubmit (options = {}) {
      if (options && options.input && options.value) {
        if (options.input === 'close_amount_cash_counted') this.cash_register_opening.close_amount_cash_counted = options.value;
        else if (options.input === 'cash_amount_out') this.cash_register_opening.cash_amount_out = options.value;
      }
    }
  }
};
</script>
