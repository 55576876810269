<template>
  <div>
    <KSubheader
      :breadcrumbs="[{
        page: 'Statistieken',
        route: '/statistics'
      }, {
        page: 'Dagelijks',
        route: '/statistics/daily'
      }]"

      title="Dagelijks"
    />

    <KContainer>
      <!-- Portlet bar -->
      <KPortlet>
        <KPortletHead>
          <template v-slot:default>
            {{ $stores.activeStore.name }}
          </template>
          <template v-slot:toolbar>
            <KButton
              variant="secondary"
              tag="span"
              class="mr-2 d-print-none"

              @click.native.prevent="printPage"
            >
              <i class="kt-font-focus pr-0">
                <font-awesome-icon :icon="['fas', 'print']" />
              </i>
            </KButton>

            <KButtonGroup>
              <KButton
                variant="secondary"
                class="d-print-none"
                @click.native.prevent="setMonth(-1)"
              >
                <i class="pr-0">
                  <font-awesome-icon :icon="['fas', 'chevron-left']" />
                </i>
              </KButton>

              <KButton
                variant="secondary"
                style="width:150px; font-weight:bold;"
                disabled
              >
                {{ moment(month, 'MM-YYYY', true).format('MMMM YYYY') }}
              </KButton>

              <KButton
                variant="secondary"
                class="d-print-none"
                @click.native.prevent="setMonth(1)"
              >
                <i class="pr-0">
                  <font-awesome-icon :icon="['fas', 'chevron-right']" />
                </i>
              </KButton>
            </KButtonGroup>
          </template>
        </KPortletHead>
      </KPortlet>

      <!-- Data -->
      <div class="row">
        <div class="col-12">
          <totals-portlet
            ref="totalsPortlet"

            :month="month"
            :totals="totals"
          />
        </div>
      </div>
    </KContainer>
  </div>
</template>

<script>
// Libraries
import moment from 'moment-timezone';

// Components
import totalsPortlet from '@/components/statistics/daily/totals.portlet';

export default {
  components: {
    totalsPortlet
  },
  data () {
    return {
      month: moment().format('MM-YYYY'),
      totals: null,

      moment
    };
  },

  mounted () {
    this.$nextTick(() => {
      // Set default month
      let month = moment().format('MM-YYYY');

      // Check if query contains month
      if (this.$route.query && this.$route.query.month) {
        // Check if month is valid
        if (moment(this.$route.query.month, 'MM-YYYY', true).isValid()) {
          month = this.$route.query.month;
        }
      }

      // Set month
      this.setMonth(month);
    });
  },

  methods: {
    setMonth (month = null) {
      if (typeof month !== typeof undefined) {
        // Check if actions
        if (month === 1 || month === -1) {
          // Subtract 1 month
          if (month === -1) {
            month = moment(this.month, 'MM-YYYY', true).subtract(1, 'months');
          }

          // Add 1 month
          if (month === 1) {
            // Check if month is not the same as current
            if (this.month !== moment().format('MM-YYYY')) {
              month = moment(this.month, 'MM-YYYY', true).add(1, 'months');
            } else {
              return;
            }
          }
        } else {
          // Parse month
          month = moment(month, 'MM-YYYY', true);
        }

        // Check if month is valid
        if (month.isValid()) {
          this.month = month.format('MM-YYYY');

          // Set query
          if (this.$route.query.month !== this.month) {
            this.$router.replace({
              query: {
                month: this.month
              }
            });
          }

          this.getTotals(this.month);
        }
      }
    },

    async getTotals (month = null) {
      // Set loading to refs
      if (this.$refs.totalsPortlet) this.$refs.totalsPortlet.isLoading = true;

      // Set from and to dates
      const monthObject = {
        from: moment(month, 'MM-YYYY', true).startOf('month'),
        to: moment(month, 'MM-YYYY', true).endOf('month')
      };

      // Fetch totals period daily
      const [daily] = await Promise.all([
        this.$ws.get('v1', `/stats/totals-period/${monthObject.from.valueOf()}/${monthObject.to.valueOf()}/day`)
      ]);

      // Set data
      this.totals = daily;

      // Set loading to refs
      if (this.$refs.totalsPortlet) this.$refs.totalsPortlet.isLoading = false;
      return this.totals;
    },

    printPage () {
      window.print();
    }
  }
};
</script>
