<template>
  <KHeader>

    <KHeaderMenu>
      <li class="kt-menu__item">
        <KButton
          v-click="[openSwitchStoreModal]"
          variant="dark"
          style="min-width:150px;"
        >
          {{ $stores.activeStore.name }}
        </KButton>
      </li>
    </KHeaderMenu>

    <KHeaderTopbar>

      <KHeaderTopbarItem
        ref="headerDropdown"
        variant="user"
        dropdown-small
      >
        <template v-slot:wrapper>
          <div class="kt-header__topbar-user kt-rounded-">
            <span class="kt-header__topbar-username kt-hidden-mobile">{{ $auth.user.name }}</span>
            <span class="kt-badge kt-badge--username kt-badge--lg kt-badge--dark kt-badge--bold">{{ $auth.user.initials }}</span>
          </div>
        </template>

        <template v-slot:default>
          <ul class="kt-nav mt-2 mb-2">
            <li class="kt-nav__item">
              <a
                class="kt-nav__link"
                @click.prevent="changeRoute({
                  name: '/settings/personal'
                })"
              >
                <span class="kt-nav__link-icon">
                  <i>
                    <font-awesome-icon :icon="['fas', 'cog']" />
                  </i>
                </span>
                <span class="kt-nav__link-text">Persoonlijke instellingen</span>
              </a>
            </li>

            <li class="kt-nav__separator kt-nav__separator--fit" />

            <li class="kt-nav__custom">
              <KButton
                variant="brand"
                class="btn-upper btn-block btn-sm btn-bold"
                elevate
                @click.prevent.native="logout"
              >
                Uitloggen
              </KButton>
            </li>
          </ul>

        </template>
      </KHeaderTopbarItem>

    </KHeaderTopbar>

  </KHeader>
</template>

<script>
export default {
  methods: {
    changeRoute (route) {
      if (this.$refs.headerDropdown) this.$refs.headerDropdown.toggleMenu(false);
      return this.$router.push(route);
    },

    openSwitchStoreModal () {
      this.$eventhub.emit('modals:switchStore:open');
    },

    async logout () {
      await this.$auth.logout();
    }
  }
};
</script>
