<template>
  <div class="row border-bottom pb-3">
    <div class="col-4 text-center">
      <div class="cs-shop-icon-block disabled">
        <div class="icon">
          <i><font-awesome-icon :icon="['fad', 'signal-stream']" /></i>
        </div>

        <div class="text">
          NFC
        </div>
      </div>
    </div>

    <div class="col-4 text-center">
      <div class="cs-shop-icon-block" v-on:click.prevent="identifyUser('fingerprint')" :class="[{'disabled': (!identification.fingerprint.master || identification.fingerprint.master === null)}]">
        <div class="icon">
          <i class="position-relative">
            <!-- Fingerprint icon -->
            <font-awesome-icon :icon="['fad', 'fingerprint']" :style="{'opacity': (identification.fingerprint.active === true ? 0.25 : 1)}" />

            <!-- States -->
            <template v-if="identification.fingerprint.active === true">
              <i v-if="identification.fingerprint.state === 'LOADING'" class="position-absolute kt-font-focus" style="font-size:20px; bottom:0; right:0;">
                <font-awesome-icon :icon="['far', 'circle-notch']" spin />
              </i>

              <i v-else-if="identification.fingerprint.state === 'SCANNING'" class="position-absolute kt-font-caution" style="font-size:20px; bottom:0; right:0;">
                <font-awesome-icon :icon="['far', 'circle-notch']" spin />
              </i>

              <i v-else-if="identification.fingerprint.state === 'MATCH'" class="position-absolute kt-font-success" style="font-size:20px; bottom:0; right:0;">
                <font-awesome-icon :icon="['far', 'circle-notch']" spin />
              </i>

              <i v-else-if="identification.fingerprint.state === 'NO_MATCH'" class="position-absolute kt-font-danger" style="font-size:20px; bottom:0; right:0;">
                <font-awesome-icon :icon="['far', 'times']" />
              </i>
            </template>
          </i>
        </div>

        <div class="text">
          Vingerafdruk
        </div>
      </div>
    </div>

    <div class="col-4 text-center">
      <div class="cs-shop-icon-block" v-on:click.prevent="$router.push('/customers/create')">
        <div class="icon">
          <i><font-awesome-icon :icon="['fad', 'user-plus']" /></i>
        </div>

        <div class="text">
          Toevoegen
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Libraries
import shop from '@/libs/shop';
import equipment from '@/libs/equipment';

export default {
  props: {
    onCustomerSelected: Function
  },
  data () {
    return {
      isMounted: false,
      listeners: [],
      identification: {
        fingerprint: {
          master: null,

          active: false,
          state: null,
          timeout: null
        }
      }
    }
  },

  async mounted () {
    // Get masters
    this.masters = equipment.masters;
    if (!equipment.masters || equipment.masters.length === 0) {
      // Get equipment, and the masters
      await equipment.getEquipment();
      this.masters = equipment.masters;
    }

    this.getMasterIdentificationPeripherals();

    // Listeners
    this.$eventhub.on('equipment:masters:updated', this.onMastersUpdated);
  },
  beforeDestroy () {
    // Remove listeners
    this.$eventhub.off('equipment:masters:updated', this.onMastersUpdated);

    // Remove master listeners
    this.listeners.forEach((master, idx) => {
      if (master && master.socket && master.socket.connection) {
        master.socket.connection.off('data', this.onMasterData);
      }
    });
  },

  methods: {
    onMastersUpdated (masters) {
      if (typeof masters === 'object' && Array.isArray(masters)) {
        this.$set(this, 'masters', null);

        this.$nextTick(() => {
          this.$set(this, 'masters', masters);
          this.getMasterIdentificationPeripherals();
        });
      }
    },
    getMasterIdentificationPeripherals () {
      let onlineMasters = this.masters.filter(m => m.socket && m.socket.online === true);
      if (onlineMasters.length > 0) {

        // Master with fingerprint identification
        let mastersWithFingerprint = onlineMasters.filter(m => m.peripherals && m.peripherals.fingerprint && m.peripherals.fingerprint.connected === true);
        if (mastersWithFingerprint.length > 0) {
          this.$set(this.identification.fingerprint, 'master', mastersWithFingerprint[0]);
        } else {
          this.$set(this.identification.fingerprint, 'master', null);
        }

      }
    },

    addListeners (master) {
      if (master && master.socket && master.socket.connection) {
        master.socket.connection.on('data', this.onMasterData);
        this.listeners.push(master);
      }
    },
    removeListener (master) {
      if (master && master.socket && master.socket.connection) {
        master.socket.connection.off('data', this.onMasterData);
        this.listeners = this.listeners.filter(m => m.guid !== master.guid);
      }
    },
    async onMasterData (message) {
      if (message && message.type) {
        // Fingerprint
        if (message.type === 'auth' && message.method === 'fingerprint') {

          // Actions
          if (message.action === 'place_finger') {
            this.identification.fingerprint.state = 'SCANNING';
          }

          // Action: complete
          if (message.action === 'complete') {
            // Fingerprint matches
            if (message.match === true) {
              this.identification.fingerprint.state = 'MATCH';
            } else {
              this.identification.fingerprint.state = 'NO_MATCH';
              this.identification.fingerprint.timeout = setTimeout(() => {
                this.identification.fingerprint.state = null;
                this.identification.fingerprint.active = false;
              }, 2000);
            }
          }

          // Action: Authenticated
          if (message.action === 'authenticated') {
            this.identification.fingerprint.state = 'MATCH';

            // Set shop customer_guid
            if (message.customer_guid) {
              await this.selectCustomer(message.customer_guid);
            } else {
              // Set no match when customer not found
              this.identification.fingerprint.state = 'NO_MATCH';
              this.identification.fingerprint.timeout = setTimeout(() => {
                this.identification.fingerprint.state = null;
                this.identification.fingerprint.active = false;
              }, 2000);
            }
          }

          // Action: Not authenticated or error
          if (message.action === 'not_authenticated' || message.action === 'error') {
            this.identification.fingerprint.state = 'NO_MATCH';
            this.identification.fingerprint.timeout = setTimeout(() => {
              this.identification.fingerprint.state = null;
              this.identification.fingerprint.active = false;
            }, 2000);
          }
        }
      }
    },

    async identifyUser (identify = null) {
      if (identify) {

        // Fingerprint identify
        if (identify === 'fingerprint') {
          if (this.identification.fingerprint.master) {
            // Remove old listener
            this.removeListener(this.identification.fingerprint.master);
            clearTimeout(this.identification.fingerprint.timeout);

            // Request to master to identify
            await this.identification.fingerprint.master.socket.device('auth:fingerprint:identify');
            this.addListeners(this.identification.fingerprint.master);
            this.identification.fingerprint.active = true;
            this.identification.fingerprint.state = 'LOADING';
          }
        }

      }
    },

    async selectCustomer (customer_guid = null) {
      if (customer_guid) {
        const { customer } = await shop.setCustomer(customer_guid);

        // Execute onCustomerSelected function
        if (typeof this.onCustomerSelected === 'function') {
          await this.onCustomerSelected(customer);
        }
      }
    }
  }
}
</script>
