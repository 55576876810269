<template>
  <b-modal
    ref="modal"
    size="lg"
    centered
  >
    <template v-slot:modal-title>
      Initieel sessiekrediet
    </template>

    <template v-slot:default>
      <div class="row border-bottom pb-3">
        <div class="col-12">
          <KAlert
            :icon="['fad', 'exclamation-circle']"
            variant="outline-info"
            class="mb-0"
          >
            Initieel sessiekrediet kan éénmalig worden toegekend aan een klant zonder verdere consequenties.
          </KAlert>
        </div>
      </div>

      <form @submit.prevent="submitForm">
        <div class="row mt-3">
          <div class="col-12 col-md-6 offset-md-3">
            <label>Initieel sessiekrediet</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i>
                    <font-awesome-icon :icon="['fad', 'credit-card']" />
                  </i>
                </span>
              </div>
              <input
                ref="sessionCreditInput"

                v-model.lazy="$v.valueFormatted.$model"
                :class="['form-control', {'is-invalid': $v.valueFormatted.$error}]"
                :disabled="isLoading"

                type="number"
              >
            </div>
          </div>
        </div>
      </form>
    </template>

    <template v-slot:modal-footer>
      <KButton
        :disabled="isLoading"

        variant="secondary"
        @click.native.prevent="hide"
      >
        Sluiten
      </KButton>

      <KButton
        :disabled="isLoading"

        variant="success"
        @click.native.prevent="submitForm"
      >
        Toevoegen
      </KButton>
    </template>
  </b-modal>
</template>

<script>
// Components
import Modal from '@/mixins/modal';

// Validations
import { required } from 'vuelidate/lib/validators';
import { currency } from '@/libs/validators/index';

export default {
  mixins: [Modal],
  data () {
    return {
      isLoading: false,

      options: null,

      value: null
    };
  },
  computed: {
    valueFormatted: {
      get () {
        if (this.value === null) return null;
        return this.$format.currencyToInput(this.value);
      },
      set (value) {
        this.value = this.$calculate.price(value, true);
      }
    }
  },
  validations: {
    valueFormatted: {
      required,
      currency
    }
  },

  mounted () {
    // Listeners
    this.$eventhub.on('modals:customers:initialSessionCredit:open', this.show);
    this.$eventhub.on('modals:customers:initialSessionCredit:close', this.hide);
  },
  beforeDestroy () {
    // Remove listeners
    this.$eventhub.off('modals:customers:initialSessionCredit:open', this.show);
    this.$eventhub.off('modals:customers:initialSessionCredit:close', this.hide);
  },

  methods: {
    async validate () {
      const validationArray = [];
      validationArray.push(this.$v);

      if (validationArray.length > 0) {
        validationArray.forEach(v => v && v.$touch && v.$touch());
        if (validationArray.filter(v => v && v.$invalid).length !== 0) return false;
      }

      return true;
    },
    async submitForm () {
      if (await this.validate() === true) {
        this.$set(this, 'isLoading', true);

        let hasError = false;
        try {
          // Run onSubmit function, if given
          if (this.options && typeof this.options.onSubmit === 'function') {
            await this.options.onSubmit(this.value);
          }
        } catch (e) {
          console.error('Failed submitting initial session credit', e);
          this.$error.showModal(e);
        }

        this.$set(this, 'isLoading', false);
        if (!hasError) {
          return this.hide();
        }
      }
    },

    // General methods (like show & hide are in the modal mixin)
    show (options = {}) {
      // Set options to null again
      this.$set(this, 'value', null);
      this.$set(this, 'options', null);

      // Check for options before showing modal
      if (typeof options === 'object') {
        // Set options to this.options, for future reference
        this.$set(this, 'options', options);

        // Set value if given
        if (this.options && typeof this.options.value === 'number') {
          this.value = this.options.value;
        }

        // Show modal
        Modal.methods.show.call(this);
      }
    },

    // When modal is shown
    onShown () {
      this.$refs.sessionCreditInput.focus();
    }
  }
};
</script>
