<template>
  <div
    v-if="line"
    class="row"
  >
    <div class="col-12">
      <div
        :key="`salesorder.line.${line.guid}`"
        class="row"
      >
        <div class="col-1 m-auto text-center">
          {{ `${line.quantity}x` }}
        </div>
        <div class="col-6 m-auto">
          {{ line.name }}
        </div>
        <div
          :class="[{'col-3': showActions === true, 'offset-1 col-4': showActions === false}]"
          class="text-right m-auto d-flex flex-row justify-content-between"
        >
          <div class="text-left">
            <i v-if="(line.type === 'session' && line.payment_type === 'credit')">
              <font-awesome-icon :icon="['fad', 'credit-card']" />
            </i>
            <i v-else-if="(line.type === 'session' && line.payment_type === 'bundle')">
              <font-awesome-icon :icon="['fad', 'box']" />
            </i>
            <span v-else>&euro;</span>
          </div>
          <div class="text-right">
            <template
              v-if="(line.type === 'session' && line.payment_type === 'bundle')"
            />

            <template
              v-else
            >
              {{ $format.currency(line.total_price_incl) }}
            </template>
          </div>
        </div>

        <template v-if="showActions === true">
          <div class="col-1 m-auto">
            <i v-if="canEditLine">
              <font-awesome-icon
                :icon="['fad', 'edit']"
                class="cursor-pointer"
                @click.prevent="editSalesorderLine(line)"
              />
            </i>
          </div>
          <div class="col-1 m-auto">
            <i>
              <font-awesome-icon
                :icon="['fad', 'times']"
                class="cursor-pointer"
                @click.prevent="removeProduct(line.guid)"
              />
            </i>
          </div>
        </template>
      </div>

      <div
        v-if="showDiscount && (line.discount_type && line.discount_value > 0)"
        :key="`salesorder.line.${line.guid}.discount_row`"
        class="row"
      >
        <div class="offset-1 col">
          <small>
            <i v-if="line.discount_type === 'absolute'">&euro; {{ $format.currency(line.discount_value) }} korting</i>
            <i v-else-if="line.discount_type === 'percentile'">{{ $format.percentage((line.discount_value / 100)) }}% korting</i>
          </small>
        </div>
      </div>

      <KSeparator
        :key="`salesorder.line.${line.guid}.separator`"
        class="kt-separator--space-sm"
      />
    </div>
  </div>
</template>

<script>
// Libraries
import shop from '@/libs/shop';

// Classes
import SalesorderLine from '@/libs/classes/salesorder.line';

export default {
  props: {
    line: {
      type: SalesorderLine,
      default: null
    },
    showActions: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    canEditLine () {
      if (this.line) {
        if (this.line.type === 'session') {
          // Hide when session is going to be paid by a bundle
          if (this.line.payment_type === 'bundle') return false;
        }
      }
      return true;
    },

    showDiscount () {
      if (this.line) {
        if (this.line.type === 'session') {
          // Hide when session is going to be paid by a bundle
          if (this.line.payment_type === 'bundle') return false;
        }
      }
      return true;
    }
  },

  methods: {
    async editSalesorderLine (line = null, options = {}) {
      if (this.showActions === false) return;
      this.$eventhub.emit('modals:shop:edit-salesorder-line:open', {
        line: line
      });
    },
    async removeProduct (guid = null, options = {}) {
      if (this.showActions === false) return;
      this.$eventhub.emit('shop:cart:set-loading', true);
      try {
        await shop.removeProductFromCart(guid, options);
      } catch (e) {
        console.error(e);
      }
      this.$eventhub.emit('shop:cart:set-loading', false);
    }
  }
};
</script>
