<template>
  <KPortlet>
    <KPortletHead>
      BTW overzicht
    </KPortletHead>
    <KPortletBody class="kt-portlet__body--fit">
      <table class="table mb-0">
        <thead class="thead-light thead-light--fit">
          <tr>
            <th>BTW tarief</th>
            <th class="table-field-currency">Omzet</th>
            <th class="table-field-currency">BTW bedrag</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(vat_revenue) in VATsRevenueSorted"
            :key="`vat_revenue.row.${vat_revenue.vat_code}`"
          >
            <td>{{ $format.percentage((vat_revenue.vat_percentage) / 100) }}%</td>
            <td class="table-field-currency">&euro; {{ $format.currency(vat_revenue.total_price_excl) }}</td>
            <td class="table-field-currency">&euro; {{ $format.currency(vat_revenue.total_price_vat) }}</td>
          </tr>
        </tbody>
        <tfoot class="thead-light thead-light--fit">
          <tr>
            <th>Resultaat</th>
            <th class="table-field-currency">&euro; {{ $format.currency(cashRegisterOpening.VATsRevenue.total_revenue_excl) }}</th>
            <th class="table-field-currency">&euro; {{ $format.currency(cashRegisterOpening.VATsRevenue.total_vat_paid) }}</th>
          </tr>
        </tfoot>
      </table>
    </KPortletBody>
  </KPortlet>
</template>

<script>
// Classes
import CashRegisterOpening from '@/libs/classes/cash_register.opening';

export default {
  props: {
    cashRegisterOpening: {
      type: CashRegisterOpening,
      default: null
    }
  },
  computed: {
    VATsRevenueSorted: {
      get () {
        if (this.cashRegisterOpening && this.cashRegisterOpening.VATsRevenue && this.cashRegisterOpening.VATsRevenue.per_vat) {
          return this.cashRegisterOpening.VATsRevenue.per_vat.slice().sort((a, b) => {
            return b.vat_percentage - a.vat_percentage;
          });
        }

        return [];
      }
    }
  }
};
</script>
