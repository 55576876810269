<template>
  <b-modal
    ref="modal"

    title="Wijzig product"
    centered
    size="xl"
  >
    <template v-slot:default>
      <!-- Edit salesorder quantity -->
      <div class="row form-group mb-0 border-bottom pb-3">
        <div class="col-12 col-md-6 col-lg-2">
          <label>Aantal</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i><font-awesome-icon :icon="['fad', 'hashtag']" /></i>
              </span>
            </div>
            <input
              v-model.number="$v.line.quantity.$model"
              :class="['form-control', {'is-invalid': $v.line.quantity.$error}]"
              :disabled="(canChangeAmount !== true || isLoading)"

              type="number"
            >
          </div>
        </div>

        <div class="col-12 mt-3 col-md-6 mt-md-0 col-lg-4">
          <label>Beschrijving</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i><font-awesome-icon :icon="descriptionIcon" /></i>
              </span>
            </div>
            <input
              :value="line.name"

              type="text"
              class="form-control"
              disabled
            >
          </div>
        </div>

        <div class="col-12 mt-3 col-md-6 mt-md-3 col-lg-3 mt-lg-0">
          <label>Prijs per stuk</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i>
                  <font-awesome-icon :icon="paymentTypeIcon" />
                </i>
              </span>
            </div>
            <input
              :value="$format.currency(line.product_price_incl_vat)"

              type="text"
              class="form-control"
              disabled
            >
          </div>
        </div>

        <div class="col-12 mt-3 col-md-6 mt-md-3 col-lg-3 mt-lg-0">
          <label>Totaal</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i><font-awesome-icon :icon="paymentTypeIcon" /></i>
              </span>
            </div>
            <input
              :value="$format.currency(totalPrice)"

              type="text"
              class="form-control"
              disabled
            >
          </div>
        </div>
      </div>

      <!-- Discount -->
      <div class="row mt-3">
        <div class="col-12 offset-sm-0 col-lg-6 offset-xl-2 col-xl-4">
          <label>Korting</label>
          <KButtonGroup class="btn-block">
            <KButton
              :variant="(line.discount_type === null ? 'success' : 'outline-success')"
              @click.native.prevent="setDiscountType(null)"
            >
              Niet actief
            </KButton>

            <KButton
              :variant="(line.discount_type === 'absolute' ? 'success' : 'outline-success')"
              @click.native.prevent="setDiscountType('absolute')"
            >
              Absoluut
            </KButton>

            <KButton
              :variant="(line.discount_type === 'percentile' ? 'success' : 'outline-success')"
              @click.native.prevent="setDiscountType('percentile')"
            >
              Procentueel
            </KButton>
          </KButtonGroup>
        </div>

        <div class="col-12 col-lg-6 col-xl-3">
          <div class="input-group label-margin">
            <div class="input-group-prepend">
              <span class="input-group-text input-group-icon">
                <i>
                  <!-- Percentage icon -->
                  <font-awesome-icon
                    v-if="line.discount_type === 'percentile'"
                    :icon="['fad', 'percentage']"
                  />

                  <!-- Euro icon -->
                  <font-awesome-icon
                    v-else
                    :icon="['fad', 'euro-sign']"
                  />
                </i>
              </span>
            </div>
            <input
              v-model.lazy.number="discountValue"
              :disabled="(line.discount_type === null || isLoading)"

              type="number"
              class="form-control"
            >
          </div>
        </div>
      </div>
    </template>

    <template v-slot:modal-footer>
      <KButton
        :disabled="isLoading"

        variant="secondary"

        @click.native.prevent="close"
      >
        Annuleren
      </KButton>

      <KButton
        :disabled="isLoading"

        variant="focus"

        @click.native.prevent="editSalesorderLine"
      >
        Wijzigen
      </KButton>
    </template>
  </b-modal>
</template>

<script>
// Libraries
import shop from '@/libs/shop';
import { cloneDeep } from 'lodash';
import { required, minValue } from 'vuelidate/lib/validators';
import mutationsLib from '@/libs/mutations';

// Classes
import SalesOrder from '@/libs/classes/salesorder';
import SalesOrderLine from '@/libs/classes/salesorder.line';

// Mixins
import Modal from '@/mixins/modal';

export default {
  mixins: [Modal],
  props: {
    sales_order: {
      type: SalesOrder,
      default: null
    }
  },
  data () {
    return {
      isLoading: false,

      options: null,
      line: null
    };
  },
  computed: {
    canChangeAmount () {
      if (this.line) {
        if (this.line.type === 'session') return false;
        if (this.line.type === 'giftcard') return false;
      }
      return (this.line.amount_changeable === true);
    },
    paymentTypeIcon () {
      if (this.line) {
        if (this.line.type === 'session') {
          if (this.line.payment_type === 'bundle') return ['fad', 'box'];
          if (this.line.payment_type === 'credit') return ['fad', 'credit-card'];
        }
      }
      return ['fad', 'euro-sign'];
    },

    discountValue: {
      get () {
        if (!this.line || !this.line.discount_value || this.line.discount_type === null) return null;
        if (this.line.discount_type === 'absolute') return this.$format.currencyToInput(this.line.discount_value);
        if (this.line.discount_type === 'percentile') return this.$format.percentage(this.line.discount_value / 100);
      },
      set (value) {
        if (!this.line || this.line.discount_type === null) value = null;
        else if (this.line.discount_type === 'absolute') value = this.$calculate.price(value, true);
        this.line.discount_value = value;
      }
    },

    totalPrice () {
      if (this.line) {
        let value = this.line.quantity * this.line.product_price_excl_vat;

        // Remove discount, if set
        if (this.line.discount_type) {
          // Absolute discount
          if (this.line.discount_type === 'absolute') {
            value = value - this.$calculate.priceWithoutVAT(this.line.discount_value, (this.line.vat_percentage || 0));
          }

          // Percentile discount
          if (this.line.discount_type === 'percentile') {
            value = (value * (1 - (this.line.discount_value / 100)));
          }
        }

        // Add VAT
        if (this.line.vat_percentage) {
          value = this.$calculate.priceWithVAT(value, this.line.vat_percentage);
        }

        return (value > 0 ? value : 0);
      }
      return 0;
    },

    descriptionIcon () {
      if (this.line !== null) {
        if (this.line.type === 'product') return ['fad', 'tag'];
        if (this.line.type === 'giftcard') return ['fad', 'gift-card'];
        if (this.line.type === 'credit_product') return ['fad', 'credit-card'];
        if (this.line.type === 'session') return ['fad', 'link'];
        if (this.line.type === 'bundle') {
          if (this.line.sub_type === 'subscription') return ['fad', 'user-clock'];
          if (this.line.sub_type === 'treatment') return ['fad', 'spa'];
          if (this.line.sub_type === 'product_bundle') return ['fad', 'box-full'];
          return ['fad', 'box'];
        }
      };

      return ['fad', 'question'];
    }
  },
  validations: {
    line: {
      quantity: {
        required,
        minValue: minValue(0)
      }
    }
  },

  mounted () {
    // Listeners
    this.$eventhub.on('modals:shop:edit-salesorder-line:open', this.open);
    this.$eventhub.on('modals:shop:edit-salesorder-line:close', this.close);
  },
  beforeDestroy () {
    // Remove listeners
    this.$eventhub.off('modals:shop:edit-salesorder-line:open', this.open);
    this.$eventhub.off('modals:shop:edit-salesorder-line:close', this.close);
  },

  methods: {
    setDiscountType (type = null) {
      const types = ['absolute', 'percentile'];
      if (types.indexOf(type) !== -1) {
        // Check if types have changed
        if (this.line.discount_type !== type) {
          // Convert value from absolute to percentage
          if (this.line.discount_type === 'absolute' && type === 'percentile') {
            this.line.discount_value = this.$calculate.price(this.line.discount_value, false);
          }

          // Convert value from percentage to absolute
          if (this.line.discount_type === 'percentile' && type === 'absolute') {
            this.line.discount_value = this.$calculate.price(this.line.discount_value, true);
          }
        }

        this.line.discount_type = type;
        return this.line.discount_type;
      }

      this.line.discount_type = null;
      this.line.discount_value = null;
      return this.line.discount_type;
    },

    async validate () {
      const validationArray = [];
      validationArray.push(this.$v);

      // Execute validations
      if (validationArray.length > 0) {
        validationArray.forEach(v => v && v.$touch && v.$touch());
        if (validationArray.filter(v => v && v.$invalid).length !== 0) return false;
      }

      return true;
    },
    async editSalesorderLine () {
      if (await this.validate() === true) {
        const fields = ['quantity', 'discount_type', 'discount_value'];
        const mutations = mutationsLib.create(fields, this.options.line.formatBody(), this.line.formatBody());

        if (mutations.length > 0) {
          const options = {};
          options.body = mutations;
          options.query = {
            persistent: true
          };

          // Set loading
          this.$set(this, 'isLoading', true);
          this.$eventhub.emit('shop:cart:set-loading', true);

          try {
            await this.$ws.patch('v1', `/salesorders/${this.sales_order.guid}/lines/${this.line.guid}`, options);
            await shop.getSalesOrder({
              query: {
                disable_cache: true
              }
            });
          } catch (e) {
            console.error(e);
            this.$error.showModal(e);
            this.$set(this, 'isLoading', false);
            return;
          }

          // Set loading
          this.$set(this, 'isLoading', false);
          this.$eventhub.emit('shop:cart:set-loading', false);
        }

        this.$nextTick(() => {
          this.close();
        });
      }
    },

    open (options = null) {
      // Set loading
      this.$set(this, 'isLoading', false);
      this.$set(this, 'options', null);

      // Set options
      if (typeof options !== typeof undefined) {
        // Check for line
        if (!options.line && options.line instanceof SalesOrderLine === false) {
          throw new Error('No salesorder line given');
        }

        // Set options and line
        this.$set(this, 'options', options);
        this.$set(this, 'line', cloneDeep(options.line));

        // Show modal
        Modal.methods.show.call(this);
      }
    },
    close () {
      if (typeof this.$refs.modal !== typeof undefined) this.$refs.modal.hide();
    }
  }
};
</script>
