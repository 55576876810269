<template>
  <div class="row pt-3 pb-3">
    <div class="col-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
      <template v-if="isEquipmentCommunicating">
        <div class="row">
          <div class="col-6 col-md-4 text-center">
            <span
              v-if="(equipment.state === 'delay' || equipment.state === 'session')"
              class="text-danger cursor-pointer"
              @click.prevent="cancelSession"
            >
              Sessie annuleren
            </span>
          </div>

          <div class="col-6 col-md-4 offset-md-4 text-center">
            <span
              v-if="equipment.state === 'delay'"
              class="text-equipment-delay cursor-pointer"
              @click.prevent="skipCurrentState"
            >
              Voorlooptijd overslaan
            </span>
            <span
              v-else-if="equipment.state === 'cooldown'"
              class="text-equipment-cooldown cursor-pointer"
              @click.prevent="skipCurrentState"
            >
              Nalooptijd overslaan
            </span>
            <span
              v-else-if="equipment.state === 'cleaning'"
              class="text-equipment-cleaning cursor-pointer"
              @click.prevent="skipCurrentState"
            >
              Schoonmaken overslaan
            </span>
          </div>
        </div>
      </template>

      <template v-else>
        <div class="row">
          <div class="col-12">
            <div class="blockui ml-auto mr-auto">
              <span>Wachten op verbinding met apparaat...</span>
              <span class="kt-spinner kt-spinner--v2 kt-spinner--focus" />
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
// Classes
import Equipment from '@/libs/classes/equipment';

export default {
  props: {
    equipment: {
      type: Equipment,
      default: null
    }
  },
  computed: {
    isEquipmentCommunicating () {
      // Check for equipment
      if (!this.equipment) return false;
      if (!this.equipment.master || this.equipment.master.isConnected === false) return false;
      if (!this.equipment.communication) return false;

      // Check equipment state
      if (this.equipment.state === 'unknown' || this.equipment.state === 'timeout') return false;

      return true;
    }
  },

  methods: {
    async cancelSession () {
      if (this.equipment) {
        return this.equipment.write('slaves:session:stop');
      }
    },

    async skipCurrentState () {
      if (this.equipment) {
        return this.equipment.write('slaves:session:skip-state');
      }
    }
  }
};
</script>
