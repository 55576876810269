<template>
  <KPortlet>
    <KPortletHead>
      <template v-slot:default>
        {{ $format.capitalizeFirstLetter(moment(year, 'YYYY', true).format('YYYY')) }}
      </template>
    </KPortletHead>
    <KPortletBody class="kt-portlet__body--fit position-relative">
      <!-- Loading block -->
      <template v-if="isLoading">
        <div class="loading-container absolute">
          <div class="loading-backdrop">
            <div class="loading-block">
              <div class="blockui">
                <span>Please wait...</span>
                <span class="kt-spinner kt-spinner--v2 kt-spinner--primary" />
              </div>
            </div>
          </div>
        </div>
      </template>

      <!-- Table -->
      <div class="table-responsive">
        <table class="table mb-0">
          <thead class="thead-light thead-light--fit">
            <tr>
              <th class="overflow-ellipsis">Datum</th>
              <th class="table-field-currency overflow-ellipsis">Transacties</th>
              <th class="table-field-currency overflow-ellipsis">Contant</th>
              <th class="table-field-currency overflow-ellipsis">PIN</th>
              <th class="table-field-currency overflow-ellipsis">Cadeaubon</th>
              <th class="table-field-currency overflow-ellipsis">Op rekening</th>
              <th class="table-field-currency overflow-ellipsis">BTW</th>
              <th class="table-field-currency overflow-ellipsis">Omzet</th>
              <th class="table-field-currency overflow-ellipsis">Sessiekrediet</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="month in monthsOfYear"
              :key="`month.row.${month.moment.format('MM-YYYY')}`"
            >
              <td class="overflow-ellipsis">{{ $format.capitalizeFirstLetter(month.moment.format('MMMM YYYY')) }}</td>
              <td class="table-field-currency overflow-ellipsis">{{ (objectPath.get(month.totals, 'total_transactions') || 0) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(objectPath.get(month.totals, 'payment_methods.cash.sum')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(objectPath.get(month.totals, 'payment_methods.card.sum')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(objectPath.get(month.totals, 'payment_methods.giftcards.sum')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(objectPath.get(month.totals, 'payment_methods.on_account.sum')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(objectPath.get(month.totals, 'revenue.vat')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(objectPath.get(month.totals, 'revenue.incl_vat')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(objectPath.get(month.totals, 'payment_methods.session_credits.sum')) }}</td>
            </tr>
          </tbody>
          <tfoot class="thead-light thead-light--fit">
            <tr>
              <th class="overflow-ellipsis">Totaal</th>
              <th class="table-field-currency overflow-ellipsis">{{ getTotal('total_transactions') }}</th>
              <th class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getTotal('payment_methods.cash.sum')) }}</th>
              <th class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getTotal('payment_methods.card.sum')) }}</th>
              <th class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getTotal('payment_methods.giftcards.sum')) }}</th>
              <th class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getTotal('payment_methods.on_account.sum')) }}</th>
              <th class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getTotal('revenue.vat')) }}</th>
              <th class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getTotal('revenue.incl_vat')) }}</th>
              <th class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getTotal('payment_methods.session_credits.sum')) }}</th>
            </tr>
          </tfoot>
        </table>
      </div>
    </KPortletBody>
  </KPortlet>
</template>

<script>
// Libraries
import moment from 'moment-timezone';
import objectPath from 'object-path';

export default {
  props: {
    year: {
      type: String,
      default: null
    },
    totals: {
      type: Array,
      default: null
    }
  },
  data () {
    return {
      isLoading: false,

      objectPath,
      moment
    };
  },
  computed: {
    monthsOfYear: {
      get () {
        let months = [];

        // Check if month is given
        if (this.year) {
          const year = moment(this.year, 'YYYY', true);
          const currentYear = moment();

          // Calculate months in year
          let monthsInYear = 12;
          if (year.format('YYYY') === currentYear.format('YYYY')) {
            monthsInYear = (currentYear.month() + 1);
          }

          // Push all months to months Array
          for (let month = 1; month <= monthsInYear; month++) {
            let monthObject = {
              moment: moment(this.year, 'YYYY').set({month: (month - 1)}),
              totals: null
            };

            if (this.totals) {
              monthObject.totals = this.getMonth(monthObject.moment.format('MM-YYYY'));
            }

            months.push(monthObject);
          }
        }
        return months;
      }
    }
  },

  methods: {
    getMonth (month = null) {
      // Check if month is given & totals are loaded
      if (month && this.totals) {
        // Format month
        const monthObject = moment(month, 'MM-YYYY', true);

        // Check if month exists
        if (monthObject.isValid()) {
          return this.totals.find(m => m.interval === monthObject.format('[01]-MM-YYYY [24:00]'));
        }
      }
      return null;
    },
    getTotal (key) {
      const months = this.monthsOfYear;
      if (months.length > 0) {
        return months.filter(month => typeof objectPath.get(month.totals, key) !== typeof undefined).map(months => objectPath.get(months.totals, key), []).reduce((a, b) => a + b, 0);
      }
      return 0;
    }
  }
};
</script>
