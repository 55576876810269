<template>
  <div v-if="stock_batch">
    <template v-if="reconciledMutations && reconciledMutations.length">
      <div class="mutations reconciled-mutations">
        <div
          v-for="(mutation, idx) in reconciledMutations"

          :ref="mutation.VUID"
          :key="mutation.VUID"

          class="mutation-row row"
        >
          <div class="col-12">
            <div class="row form-group">
              <div class="col-12 col-lg-6 col-xl-8">
                <label :class="[{'d-xl-none': idx !== 0}]">Naam</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i><font-awesome-icon :icon="['fad', 'tag']" /></i>
                    </span>
                  </div>
                  <input
                    :value="mutation.product_name"
                    type="text"
                    class="form-control"
                    disabled="disabled"
                  >
                </div>
              </div>

              <div class="col-6 mt-3 col-lg-3 mt-lg-0 col-xl-2">
                <label :class="[{'d-xl-none': idx !== 0}]">Voorraad</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i><font-awesome-icon :icon="['fad', 'inventory']" /></i>
                    </span>
                  </div>
                  <input
                    value="mutation.stock"
                    type="number"
                    class="form-control"
                    disabled
                  >
                </div>
              </div>

              <div class="col-6 mt-3 col-lg-3 mt-lg-0 col-xl-2">
                <label :class="[{'d-xl-none': idx !== 0}]">Aanpassing</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i><font-awesome-icon :icon="['fad', 'hashtag']" /></i>
                    </span>
                  </div>
                  <input
                    ref="input.quantity"
                    :value="mutationDifference(mutation)"
                    type="text"
                    class="form-control"
                    disabled
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <!-- No changes -->
    <KAlert
      v-else
      :icon="['fad', 'check']"
      variant="outline-success"
    >
      Er worden geen voorraadaanpassingen doorgevoerd
    </KAlert>

    <KSeparator class="mt-3 mb-3" />

    <div class="row form-group mb-0">
      <div class="col-12">
        <label>Opmerking <sup v-if="reconciledMutations.length > 0">Verplicht</sup></label>
        <textarea
          v-model.trim="$v.stock_batch.remark.$model"
          :class="['form-control', {'is-invalid': $v.stock_batch.remark.$error}]"
          rows="5"
        />
      </div>
    </div>

  </div>
</template>

<script>
// Classes
import StockBatch from '@/libs/classes/stock_batch';

// Validators
import { requiredIf } from 'vuelidate/lib/validators';

// Components
import mutationLine from './mutation.line';

export default {
  components: {
    mutationLine
  },
  props: {
    stock_batch: {
      type: StockBatch,
      default: null
    }
  },
  computed: {
    reconciledMutations () {
      return this.stock_batch.mutations.filter(m => m.quantity !== m.stock);
    }
  },
  validations: {
    stock_batch: {
      remark: {
        required: requiredIf(function (value) {
          return this.reconciledMutations.length > 0;
        })
      }
    }
  },

  methods: {
    mutationDifference (mutation) {
      const difference = (mutation.quantity - mutation.stock);
      return `${difference > 0 ? '+' : ''}${difference}`;
    }
  }
};
</script>
