<template>
  <KContainer v-if="isMounted === false">
    <div class="row mt-3">
      <div class="col-12 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
        <div class="loading-container">
          <div class="loading-block">
            <div class="blockui ml-auto mr-auto w-100">
              <span>Gegevens ophalen...</span>
              <span class="kt-spinner kt-spinner--v2 kt-spinner--primary"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </KContainer>

  <div v-else>
    <KSubheader
      title="Persoonlijke instellingen"
      :breadcrumbs="[{
        page: 'Instellingen'
      }, {
        page: 'Persoonlijk'
      }]"
    />

    <KContainer>
      <div class="row">
        <div class="col-12 col-portlet-menu">
          <KPortlet>
            <KPortletBody>
              <div class="kt-widget kt-widget--general-1">
                <div class="kt-media kt-media--brand kt-media--md kt-media--circle">
                  <span class="kt-badge kt-badge--username kt-badge--lg kt-badge--dark kt-badge--bold">{{ $auth.user.initials }}</span>
                </div>
                <div class="kt-widget__wrapper">
                  <div
                    class="kt-widget__label"
                    style="width:0px;"
                  >
                    <span class="kt-widget__title overflow-ellipsis">{{ $auth.user.name }}</span>
                  </div>
                </div>
              </div>
            </KPortletBody>
            <div class="kt-portlet__separator" />
            <KPortletBody>
              <ul
                class="kt-nav kt-nav--bolder kt-nav--fit-ver kt-nav--v4"
                role="tablist"
              >
                <!-- Information -->
                <router-link
                  v-slot="{href, route, navigate, isActive}"
                  to="information"
                >
                  <li :class="['kt-nav__item', {'active': isActive}]">
                    <a
                      class="kt-nav__link"
                      role="tab"
                      @click.prevent="$router.push(href)"
                    >
                      <span class="kt-nav__link-icon">
                        <i>
                          <font-awesome-icon :icon="['fas', 'info-circle']" />
                        </i>
                      </span>
                      <span class="kt-nav__link-text">Informatie</span>
                    </a>
                  </li>
                </router-link>

                <!-- Authenticaton -->
                <router-link
                  v-slot="{href, route, navigate, isActive}"
                  to="authentication"
                >
                  <li :class="['kt-nav__item', {'active': isActive}]">
                    <a
                      class="kt-nav__link"
                      role="tab"
                      @click.prevent="$router.push(href)"
                    >
                      <span class="kt-nav__link-icon">
                        <i>
                          <font-awesome-icon :icon="['fas', 'fingerprint']" />
                        </i>
                      </span>
                      <span class="kt-nav__link-text">Authenticate</span>
                    </a>
                  </li>
                </router-link>
              </ul>
            </KPortletBody>
          </KPortlet>
        </div>

        <!-- Router view -->
        <div class="col-12 col-sm">
          <router-view
            :key="$route.fullPath"
            :user.sync="user"
          />
        </div>
      </div>
    </KContainer>
  </div>
</template>

<script>
// Classes
import User from '@/libs/classes/user';

export default {
  data () {
    return {
      isMounted: false,

      user: null
    };
  },

  async mounted () {
    try {
      await this.getUser();
    } catch (e) {
      console.error('User not found', e);
      return;
    }

    this.$nextTick(() => {
      this.isMounted = true;
    });
  },

  methods: {
    async getUser (options = {}) {
      this.$set(this, 'user', null);

      const user = await new User(this.$auth.user.guid).get(options);
      await user.populate('get', options);

      this.$set(this, 'user', user);
      return this.user;
    }
  }
};
</script>
