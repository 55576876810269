<template>
  <KPortlet>
    <KPortletHead>
      <template v-slot:default>Financiële transacties</template>
    </KPortletHead>
    <KPortletBody>
      <div class="row">
        <div class="col-12 datatable datatable-wrapper">
          <datatable
            ref="datatable"
            :url="datatable.url"
            :load-on-start="datatable.loadOnStart"
            :fields="datatable.fields"
            :search-fields="datatable.searchFields"
            :on-pagination-data="onPaginationData"
            :transform="transform"
          >
            <div
              slot="payment-method-type"
              slot-scope="props"
              class="payment-method-type"
            >
              <KButton
                :variant="props.rowData.label.variant"
                :icon="props.rowData.label.icon"
                class="btn-block btn-sm"
                tag="span"
              >
                {{ props.rowData.label.text }}
              </KButton>
            </div>
          </datatable>

          <div class="row pagination">
            <div class="col-xs-12 col-md-6">
              <datatable-pagination
                ref="pagination"
                @vuetable-pagination:change-page="onChangePage"
              />
            </div>

            <div class="col-xs-12 col-md-6">
              <datatable-pagination-info
                ref="pagination.info"
                @vuetable-paginationinfo:change-rowcount="onChangeRowCount"
              />
            </div>
          </div>

        </div>
      </div>
    </KPortletBody>
  </KPortlet>
</template>

<script>
// Libraries
import moment from 'moment-timezone';

// Classes
import SalesOrder from '@/libs/classes/salesorder';
import PaymentTransaction from '@/libs/classes/payment_transaction';

// Mixins
import datatable from '@/mixins/datatable';

export default {
  mixins: [datatable],
  props: {
    salesorder: {
      type: SalesOrder,
      default: null
    }
  },
  data () {
    return {
      datatable: {
        url: null,
        loadOnStart: false,

        fields: [{
          title: 'Type',
          name: '__slot:payment-method-type',
          sortField: 'payment_method_type',
          titleClass: 'text-center',
          dataClass: 'w-1/10'
        },
        {
          title: 'Bedrag',
          name: '',
          sortField: 'amount',
          titleClass: 'text-center',
          dataClass: 'w-1/8 text-center',
          callback: (paymentTransaction) => {
            let value = paymentTransaction.amount;
            if (paymentTransaction.payment_method_type === 'session_credits') value = value * -1;
            return `&euro; ${this.$format.currency(value)}`;
          }
        },
        {
          title: 'Datum',
          name: '_meta.created',
          sortField: '_meta.created',
          dataClass: 'w-6/8',
          callback: (value) => moment(value).format('LLL')
        }],

        searchFields: ['product_name', 'product_type']
      }
    };
  },

  mounted () {
    this.$set(this.datatable, 'url', `/salesorders/${this.salesorder.guid}/transactions`);
  },

  methods: {
    transform (data) {
      if (data.data && Array.isArray(data.data)) {
        data.data.forEach((row, idx) => {
          data.data[idx] = new PaymentTransaction().mergeResponse(data.data[idx]);

          // Set data
          data.data[idx].label = this.paymentMethodTypeLabel(data.data[idx]);
        });
      }

      return data;
    },
    paymentMethodTypeLabel (paymentTransaction) {
      const label = {
        text: paymentTransaction.payment_method,
        variant: 'label-warning',
        icon: ['fad', 'question']
      };

      switch (paymentTransaction.payment_method_type) {
        case 'cash':
          label.variant = 'label-focus';
          label.icon = ['fad', 'money-bill-wave'];
          break;

        case 'card':
          label.variant = 'label-focus';
          label.icon = ['fad', 'credit-card'];
          break;

        case 'on_account':
          label.variant = 'label-danger';
          label.icon = ['fad', 'money-check-edit-alt'];
          break;

        case 'giftcard':
          label.variant = 'label-focus';
          label.icon = ['fad', 'gift-card'];
          break;

        case 'session_credits':
          label.variant = 'label-focus';
          label.icon = ['fad', 'credit-card'];
          break;

        case 'bundle':
          label.variant = 'label-focus';
          label.icon = ['fad', 'box'];
          break;
      }

      return label;
    }
  }
};
</script>
