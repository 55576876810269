<template>
  <div v-if="isMounted">
    <KSubheader
      title="Producten retourneren"
      :breadcrumbs="[{
        page: 'Kassabeheer',
        route: '/cash_registers/overview'
      }, {
        page: 'Transacties',
        route: '/cash_registers/salesorders/overview'
      }, {
        page: 'Transactie',
        route: '/cash_registers/salesorders/view/' + salesorder._meta.guid
      }, {
        page: 'Retourneren'
      }]"
    >

    </KSubheader>

    <KContainer>
      <KPortlet>
        <KPortletBody class="kt-portlet__body-fit">
          <KWizard ref="wizard" :clickable="false">
            <KWizardStep title="Te retourneren producten">
              <div v-for="line in salesorder.lines" :key="line._meta.guid">
                <input type="checkbox" :id="'check-' + line._meta.guid" v-model="productsToReturn" :value="line._meta.guid" />
                <label :for="'check-' + line._meta.guid">
                  [{{ line.sub_type }}] {{ line.name }} - {{ line.quantity }}x - &euro;{{ line.total_price_incl }}
                </label>
                <div v-if="productsToReturn.includes(line._meta.guid) === true">
                  Quantity: <input type="number" v-model.number="returnLines[line._meta.guid].quantity" @keyup="updateAmount(line)" /><br />
                  Amount: &euro;<input type="text" v-model="returnLines[line._meta.guid].amount" /><br />
                  Reden voor retour:
                  <select v-model="returnLines[line._meta.guid].return_reason_guid">
                    <option :value="null">-- Reden voor retour (optioneel)</option>
                    <option v-for="creditReason in creditReasons" :key="creditReason._meta.guid" :value="creditReason._meta.guid">
                      {{ creditReason.name }}
                    </option>
                  </select>
                </div>
              </div>
            </KWizardStep>

            <KWizardStep title="Betaalmethode">
              Products to return:
              {{ productsToReturn.map((lineGuid) => {
                return salesOrderLineMapper[lineGuid].name;
              }).join(', ') }}
              <hr />

              <h3>Betaalmethodes</h3>

              <div v-for="(paybackPayment, index) in paybackPayments" :key="index">
                {{ paybackPayment.method }} - &euro; {{ $format.currency(paybackPayment.amount) }}
                <template v-if="paybackPayment.method === 'giftcard'">
                  Giftcard code: {{ paybackPayment.giftcard_code }}
                </template>
              </div>

              <hr />

              <!--
                TODO: When selecting session_credits or on_account,
                there should be a nice way to authenticate the customer
                Like when starting a session
               -->

              <select v-model="newPayment.method">
                <option :value="null">-- Kies een betaalmethode</option>
                <option value="card">Pin / Kaart</option>
                <option value="cash">Contant</option>
                <option value="session_credits">Sessiekrediet</option>
                <option value="on_account">Op rekening</option>
                <option value="giftcard">Cadeaubon</option>
              </select><br />
              Amount: &euro;<input type="text" v-model="newPayment.amount" />
              <div v-if="newPayment.method === 'giftcard'">
                Cadeaubon code: <input type="text" v-model="newPayment.giftcard_code" />
              </div>

              <br /><br />
              <KButton variant="focus" class="btn-md btn-bold btn-upper" @click.native.prevent="addPaybackPayment">Voeg terugbetaling toe</KButton>

            </KWizardStep>

            <KWizardStep title="Terug te betalen bedrag">
              <h4>Terug te betalen</h4>

              {{ paybackPayments }}
            </KWizardStep>

          </KWizard>
        </KPortletBody>

        <div class="kt-portlet__foot">
  				<div class="row align-items-center">
  					<div class="col-6 col-lg-2">
              <KButton v-if="(isWizardLoaded && $refs.wizard && $refs.wizard.currentStep !== 0)" variant="outline-focus" class="btn-md btn-block btn-tall btn-wide btn-bold btn-upper" @click.native.prevent="previousStep">Vorige</KButton>
  					</div>
  					<div class="col-6 offset-lg-8 col-lg-2 kt-align-right">
  						<KButton v-if="(isWizardLoaded && $refs.wizard && $refs.wizard.currentStep !== ($refs.wizard.steps.length - 1))" variant="focus" class="btn-md btn-block btn-tall btn-wide btn-bold btn-upper" @click.native.prevent="nextStep">Volgende</KButton>
              <KButton v-if="(isWizardLoaded && $refs.wizard && $refs.wizard.currentStep === ($refs.wizard.steps.length - 1))" variant="success" class="btn-md btn-block btn-tall btn-wide btn-bold btn-upper" @click.native.prevent="submitForm">Afronden</KButton>
  					</div>
  				</div>
  			</div>
      </KPortlet>
    </KContainer>
  </div>
</template>

<script>
// Libraries
import moment from 'moment-timezone';

// Classes
import SalesOrder from '@/libs/classes/salesorder';
import CashRegister from '@/libs/classes/cash_register';
import CreditReasons from '@/libs/classes/credit_reasons';

// Libs
import calculate from '@/libs/calculate';

export default {
  data () {
    return {
      isMounted: false,
      isWizardLoaded: false,

      creditReasons: [],

      productsToReturn: [],
      returnLines: {},
      salesOrderLineMapper: {},

      paybackPayments: [],
      newPayment: {
        method: 'card',
        amount: 0,
        giftcard_code: ''
      },

      salesorder: null,
      cash_register: null,

      moment
    };
  },
  components: {
  },

  async mounted () {
    try {
      await this.getSalesOrder();
    } catch (e) {
      console.error('Sales order not found:', e);
    }

    this.$nextTick(() => {
      this.isMounted = true;

      // Set wizard loaded on next tick after setting mounted
      this.$nextTick(() => {
        this.isWizardLoaded = true;
      })
    });
  },

  methods: {
    async getSalesOrder (options = {}) {
      this.$set(this, 'salesorder', null);

      const salesorder = await new SalesOrder(this.$route.params.guid).get(options);
      await salesorder.populate('get', options);

      const cash_register = await new CashRegister(salesorder.cash_register_guid).get(options);
      await cash_register.populate('get', options);

      this.creditReasons = await new CreditReasons().getAll(options);

      salesorder.lines.forEach((line) => {
        this.salesOrderLineMapper[line._meta.guid] = line;
        this.returnLines[line._meta.guid] = {
          quantity: 1,
          amount: this.$format.currencyToInput(line.total_price_incl),
          return_reason_guid: null
        };
      });

      this.$set(this, 'salesorder', salesorder);
      this.$set(this, 'cash_register', cash_register);
      return this;
    },

    updateAmount (salesOrderLine = {}) {
      const quantity = this.returnLines[salesOrderLine._meta.guid].quantity;
      this.$set(this.returnLines[salesOrderLine._meta.guid], 'amount', this.$format.currencyToInput(quantity * salesOrderLine.product_price_incl_vat));
    },

    addPaybackPayment () {
      if (!this.newPayment.method || this.newPayment.amount < 0) {
        return;
      }

      if (this.newPayment.method === 'giftcard' && this.newPayment.giftcard_code.length === 0) {
        return;
      }

      this.newPayment.amount = calculate.price(this.newPayment.amount, true);
      this.paybackPayments.push(Object.assign({}, this.newPayment));
      this.newPayment.method = null;
      this.newPayment.amount = 0;
      this.newPayment.giftcard_code = '';
    },

    validate () {
      if (this.$refs.wizard.currentStep === 0) {
        if (this.productsToReturn.length === 0) {
          return false;
        }

        let valid = true;
        this.productsToReturn.forEach((lineGuid) => {
          if (!this.returnLines[lineGuid].amount) {
            valid = false;
            return;
          }
          const amount = calculate.price(this.returnLines[lineGuid].amount.replace(',', '.'), true);
          const salesOrderLine = this.salesOrderLineMapper[lineGuid];
          if (amount <= 0) {
            valid = false;
          }
          const comparePrice = calculate.comparePrice(amount, salesOrderLine.total_price_incl);
          if (amount > salesOrderLine.total_price_incl && comparePrice !== 0) {
            valid = false;
          }
        });
        return valid;
      }

      if (this.$refs.wizard.currentStep === 1) {
        if (this.paybackPayments.length === 0) {
          return;
        }
      }

      return true;
    },

    previousStep () {
      if(this.$refs.wizard) {
        return this.$refs.wizard.previousStep();
      }
    },

    nextStep () {
      if (this.$refs.wizard) {
        if (this.validate() === true) {
          this.$refs.wizard.nextStep();
        }

        if (this.$refs.wizard.currentStep === 1) {
          const transactionMethods = this.salesorder.transactions.map((transaction) => {
            return transaction.payment_method_type;
          });

          if (transactionMethods.includes('card') === true) {
            this.newPayment.method = 'card';
          } else if (transactionMethods.includes('cash') === true) {
            this.newPayment.method = 'cash';
          } else if (transactionMethods.includes('on_account') === true) {
            this.newPayment.method = 'on_account';
          } else if (transactionMethods.includes('session_credits') === true) {
            this.newPayment.method = 'session_credits';
          }

          const totalPaybackAmount = Object.values(this.returnLines).reduce((totalAmount, returnLine) => {
            return calculate.price(returnLine.amount.replace(',', '.'), true) + totalAmount;
          }, 0);

          this.newPayment.amount = this.$format.currencyToInput(totalPaybackAmount);
        }
      }
    },

    async submitForm () {
      const returns = [];

      Object.keys(this.returnLines).forEach((guid) => {
        const returnLine = this.returnLines[guid];
        returns.push({
          salesorder_line_guid: guid,
          quantity: returnLine.quantity,
          amount: calculate.price(returnLine.amount, true),
          return_reason_guid: returnLine.return_reason_guid || null
        });
      });

      // TODO: Build loader and block the button to prevent duplicates / double clicks
      await this.salesorder.createCreditOrder({
        body: {
          cash_register_guid: this.cash_register._meta.guid,
          cash_register_opening_guid: this.cash_register.latest_opening._meta.guid,
          returns: returns,
          payback: this.paybackPayments
        }
      });

      this.$router.push(`/cash_registers/salesorders/view/${this.salesorder.guid}`);
    }
  }
};
</script>
