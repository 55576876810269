<template>
  <div>

    <div class="row mt-4">
      <div class="col-12">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i><font-awesome-icon :icon="['far', 'search']" /></i>
            </span>
          </div>
          <input type="text" ref="searchInput" class="form-control" placeholder="Zoeken..." v-model.trim="search.value" />
        </div>
      </div>
    </div>

    <div class="row mt-5">
      <router-view ref="routerView"
        :props="this.$props"

        :search="search.value"
      ></router-view>
    </div>

    <!-- Cart modals -->
    <modal-repay-on-account ref="modalRepayOnAccount"
      :sales_order="sales_order"
    ></modal-repay-on-account>

    <modal-initiate-giftcard ref="modalInitiateGiftcard"
      :sales_order="sales_order"
    ></modal-initiate-giftcard>

  </div>
</template>

<script>
// Mixins
import shop from '@/mixins/shop';

// Classes
import CashRegister from '@/libs/classes/cash_register';

// Components
import searchView from './search/index';

// Modals
import modalRepayOnAccount from '@/components/sales/shop/components/modals/repay-on-account';
import modalInitiateGiftcard from '@/components/sales/shop/components/modals/initiate-giftcard';

export default {
  mixins: [shop],
  props: {
    meta: Object,
    cash_register: CashRegister
  },
  data() {
    return {
      sales_order: null,

      search: {
        value: null
      }
    }
  },
  watch: {
    'search.value'(value) {
      if (typeof value === 'string' && value.length > 0) {
        const routeParams = Object.assign({}, this.$route.params, {
          search: value
        });

        if (this.$route.name === '/sales/shop/:cash_register/cart/search/:search') {
          if (this.$route.params.search !== value) {
            this.$router.replace({
              params: routeParams
            });
          }
        } else {
          this.$router.push({
            name: '/sales/shop/:cash_register/cart/search/:search',
            params: routeParams
          })
        }
      } else if (this.$route.name !== '/sales/shop/:cash_register/cart/overview') {
        this.$router.push({
          name: '/sales/shop/:cash_register/cart/overview'
        })
      }
    }
  },
  computed: {
    isSearching () {
      if (this.search.value && typeof this.search.value === 'string' && this.search.value.length > 0) return true;
      return false;
    }
  },
  components: {
    searchView,

    modalRepayOnAccount,
    modalInitiateGiftcard
  },

  async mounted() {
    // Set sales order of shop
    this.$set(this, 'sales_order', this.shop.sales_order);

    // Listeners
    this.$eventhub.on('shop:cart:updated', this.onCartUpdated);
    this.$eventhub.on('shop:cart:search:value', this.onCartSearchValue);
  },
  beforeDestroy() {
    // Remove listeners
    this.$eventhub.off('shop:cart:updated', this.onCartUpdated);
    this.$eventhub.off('shop:cart:search:value', this.onCartSearchValue);
  },

  methods: {
    onCartUpdated(sales_order) {
      // First set to null, so it can update properly
      this.$set(this, 'sales_order', null);

      // Set to real sales_order on next tick
      this.$nextTick(() => {
        this.$set(this, 'sales_order', sales_order);
      });
    },
    onCartSearchValue (value) {
      this.$set(this.search, 'value', value);
    }
  }
}
</script>
