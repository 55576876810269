<template>
  <!-- Loading -->
  <div
    v-if="isMounted === false"
    class="row"
  >
    <div class="col-12 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
      <div class="loading-container">
        <div class="loading-block">
          <div class="blockui ml-auto mr-auto w-100">
            <span>Gegevens ophalen...</span>
            <span class="kt-spinner kt-spinner--v2 kt-spinner--primary" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Finished loading -->
  <KPortlet v-else>
    <KPortletHead>
      <template v-slot:default>Tarievenlijsten</template>
    </KPortletHead>
    <KPortletBody>

      <pricelists-form
        ref="pricelistsForm"

        :equipment="equipmentCloned"
        :pricelists="pricelists"

        action="EDIT"
      />

    </KPortletBody>
    <div class="kt-portlet__foot">
      <div class="row align-items-center">
        <div class="col-6 offset-lg-10 col-lg-2 kt-align-right">
          <KButton
            variant="warning"
            class="btn-md btn-block btn-tall btn-wide btn-bold btn-upper"
            @click.native.prevent="submitForm"
          >
            Wijzigen
          </KButton>
        </div>
      </div>
    </div>
  </KPortlet>
</template>

<script>
// Libraries
import { cloneDeep } from 'lodash';
import mutationsLib from '@/libs/mutations';

// Classes
import Equipment from '@/libs/classes/equipment';
import SessionPricelist from '@/libs/classes/session_pricelist';

// Components
import pricelistsForm from '@/components/equipment/components/pricelists.form';

export default {
  components: {
    pricelistsForm
  },
  props: {
    equipment: {
      type: Equipment,
      default: null
    },

    meta: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      isMounted: false,

      equipmentCloned: cloneDeep(this.equipment),

      pricelists: []
    };
  },

  async mounted () {
    try {
      await Promise.all([this.getSessionPricelists()]);
    } catch (e) {
      console.error('Failed fetching information', e);
      return;
    }

    this.$nextTick(() => {
      this.isMounted = true;
    });
  },

  methods: {
    async validate () {
      const validationArray = [];

      if (validationArray.length > 0) {
        validationArray.forEach(v => v && v.$touch && v.$touch());
        if (validationArray.filter(v => v && v.$invalid).length !== 0) return false;
      }

      if (await this.$refs.pricelistsForm.validate() === false) return false;

      return true;
    },
    async submitForm () {
      if (await this.validate() === true) {
        // Then, update the fields of the equipment
        const fields = ['pricelists'];
        const mutations = mutationsLib.create(fields, this.equipment.formatBody(), this.equipmentCloned.formatBody());

        if (mutations.length) {
          await this.equipment.update(mutations);
          await this.equipment.populate('update');
          this.$set(this, 'equipmentCloned', cloneDeep(this.equipment));

          // Get pricelists again
          this.getSessionPricelists({
            query: {
              disable_cache: true
            }
          });
        }
      }
    },

    async getSessionPricelists (options = {}) {
      this.$set(this, 'isMounted', false);
      this.$set(this, 'pricelists', []);

      // Get pricelists
      let sessionPricelists = await this.$ws.get('v1', '/session_pricelists', options);
      sessionPricelists = sessionPricelists.map((row) => {
        return new SessionPricelist().mergeResponse(row);
      }, []);

      this.$set(this, 'pricelists', sessionPricelists);

      // Set active pricelists of equipment
      let activePricelists = this.pricelists.filter((pricelist) => {
        if (pricelist.equipment_guids && pricelist.equipment_guids.indexOf(this.equipment.guid) !== -1) return true;
        else return false;
      }, []).map((row) => {
        return row.guid;
      }, []);

      this.$set(this.equipment, 'pricelists', activePricelists);
      this.$set(this.equipmentCloned, 'pricelists', cloneDeep(activePricelists));

      this.$set(this, 'isMounted', true);
      return this.pricelists;
    }
  }
};
</script>
