// Classes
import BundleProduct from './bundle_product';

// Libraries
import { merge } from 'lodash';

class BundleProductTreatment extends BundleProduct {
  constructor (guid = null) {
    super(guid);
    this.type = 'treatment';

    let defaultObject = {

    };

    merge(this, defaultObject);
  }
};

export default BundleProductTreatment;
