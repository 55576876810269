<template>
  <div>

    <KSubheader
      :breadcrumbs="[{
        page: 'Apparatuur',
        route: null
      }, {
        page: 'Overzicht',
        route: null
      }]"

      title="Apparatuur"
    />

    <KContainer>
      <KPortlet>
        <KPortletHead>
          <template v-slot:default>Overzicht</template>

          <template v-slot:toolbar>
            <router-link
              v-if="$auth.hasPermission([['create_equipments']]) === true"
              to="/equipment/create"
            >
              <KButton
                tag="a"
                variant="outline-brand"
                icon="plus"
              >
                Apparatuur toevoegen
              </KButton>
            </router-link>
          </template>
        </KPortletHead>
        <KPortletBody>

          <div class="row">
            <div class="col-12">
              <div class="kt-input-icon kt-input-icon--left">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Search..."
                  @keyup.enter="$refs['datatable'].search"
                  @keyup="searchDebounced"
                >
                <span class="kt-input-icon__icon kt-input-icon__icon--left">
                  <span>
                    <i>
                      <font-awesome-icon
                        :icon="['far', 'search']"
                      />
                    </i>
                  </span>
                </span>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-12 datatable datatable-wrapper">
              <datatable
                ref="datatable"
                :url="datatable.url"
                :load-on-start="datatable.loadOnStart"
                :fields="datatable.fields"
                :search-fields="datatable.searchFields"
                :on-pagination-data="onPaginationData"
                :transform="transform"
              >
                <!-- Master -->
                <div
                  slot="master"
                  slot-scope="props"
                >
                  <template v-if="props.rowData.master">
                    <router-link
                      v-if="$auth.hasPermission([['get_masters']])"
                      :to="`/hardware/view/${props.rowData.master.guid}`"
                    >
                      {{ (typeof props.rowData.master.name === 'string' && props.rowData.master.name.length > 0 ? props.rowData.master.name : props.rowData.master.serial) }}
                    </router-link>

                    <span v-else>
                      {{ (typeof props.rowData.master.name === 'string' && props.rowData.master.name.length > 0 ? props.rowData.master.name : props.rowData.master.serial) }}
                    </span>
                  </template>

                  <template v-else>
                    <KButton
                      tag="span"
                      variant="label-focus"
                    >
                      Niet verbonden
                    </KButton>
                  </template>
                </div>
                <!-- Actions -->
                <div
                  slot="actions"
                  slot-scope="props"
                  class="datatable-actions"
                >
                  <router-link
                    v-if="$auth.hasPermission([['get_equipments']])"
                    :to="`/equipment/view/${props.rowData._meta.guid}`"
                  >
                    <KButton
                      :icon="['fad', 'eye']"
                      tag="a"
                      variant="label-brand"
                    />
                  </router-link>

                  <KButton
                    v-if="$auth.hasPermission([['update_equipments']])"
                    :icon="['fad', 'trash']"

                    tag="a"
                    variant="label-brand"

                    @click.native.prevent="openDeleteModal(props.rowData)"
                  />
                </div>
              </datatable>

              <div class="row pagination">
                <div class="col-xs-12 col-md-6">
                  <datatable-pagination
                    ref="pagination"
                    @vuetable-pagination:change-page="onChangePage"
                  />
                </div>

                <div class="col-xs-12 col-md-6">
                  <datatable-pagination-info
                    ref="pagination.info"
                    @vuetable-paginationinfo:change-rowcount="onChangeRowCount"
                  />
                </div>
              </div>
            </div>
          </div>

        </KPortletBody>
      </KPortlet>

    </KContainer>

  </div>
</template>

<script>
// Classes
import Equipment from '@/libs/classes/equipment';
import EquipmentGroup from '@/libs/classes/equipment_group';
import Master from '@/libs/classes/master';

// Mixins
import datatable from '@/mixins/datatable';

export default {
  mixins: [datatable],
  data () {
    return {
      datatable: {
        url: '/equipments',
        loadOnStart: false,

        fields: [{
          name: 'name',
          title: 'Naam',
          sortField: 'name',
          dataClass: 'w-1/8'
        }, {
          name: 'room',
          title: 'Kamer',
          sortField: 'room',
          dataClass: 'w-1/8'
        }, {
          name: 'group.name',
          title: 'Groep',
          sortField: 'group_guid',
          dataClass: 'w-1/8'
        }, {
          name: 'serial',
          title: 'CSID',
          sortField: 'serial',
          dataClass: 'w-2/10'
        }, {
          name: '__slot:master',
          title: 'Core',
          sortField: 'master_guid',
          dataClass: 'w-1/8'
        }, {
          title: '',
          name: '__slot:actions',
          titleClass: 'text-right',
          dataClass: 'text-right'
        }],

        searchFields: ['name', 'room', 'serial']
      },

      meta: {
        groups: [],
        masters: []
      }
    };
  },

  async mounted () {
    try {
      this.$refs.datatable.loading = true;

      const promises = [];

      // Get equipment groups
      if (this.$auth.hasPermission('get_equipment_groups')) {
        promises.push(this.getEquipmentGroups());
      }

      // Get hardware masters
      if (this.$auth.hasPermission('get_masters')) {
        promises.push(this.getHardwareMasters());
      }

      await Promise.all(promises);
      this.$refs.datatable.refresh();
    } catch (e) {
      console.error('Error fetching meta', e);
    }
  },

  methods: {
    transform (data) {
      if (data.data && Array.isArray(data.data)) {
        data.data.forEach((row, idx) => {
          row.group = this.meta.groups.find(g => g._meta.guid === row.group_guid);
          row.master = this.meta.masters.find(m => m._meta.guid === row.master_guid);

          data.data[idx] = new Equipment().mergeResponse(row);
        });
      }

      return data;
    },

    async getEquipmentGroups (options = {}) {
      this.$set(this.meta, 'groups', []);

      let groups = await this.$ws.get('v1', '/equipment_groups', options);
      groups = groups.map(group => {
        return new EquipmentGroup().mergeResponse(group);
      });

      this.$set(this.meta, 'groups', groups);
      return this.meta.groups;
    },
    async getHardwareMasters (options = {}) {
      this.$set(this.meta, 'masters', []);

      let masters = await this.$ws.get('v1', '/masters', options);
      masters = masters.map(master => {
        return new Master().mergeResponse(master);
      });

      this.$set(this.meta, 'masters', masters);
      return this.meta.masters;
    },

    openDeleteModal (resource = null) {
      return this.$eventhub.emit('modals:confirm:open', {
        resource: resource,

        title: 'Verwijder apparaat',
        text: `Weet je zeker dat je apparaat <strong>${resource.name}</strong> wilt verwijderen?`,
        submit: async () => {
          // Remove resource
          await resource.remove();

          // Refresh datatable
          this.$refs.datatable.refresh({
            query: {
              disable_cache: true
            }
          });
        }
      });
    }
  }
};
</script>
