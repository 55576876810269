<template>
  <KPortlet>
    <KPortletHead>
      Betaalmethoden
    </KPortletHead>
    <KPortletBody class="kt-portlet__body--fit">
      <table class="table mb-0">
        <thead class="thead-light thead-light--fit">
          <tr>
            <th>Betaalmethode</th>
            <th class="table-field-currency">Verwacht</th>
            <th class="table-field-currency">Geteld</th>
            <th class="table-field-currency">Verschil</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Contant</td>
            <td class="table-field-currency">&euro; {{ $format.currency(cashRegisterOpening.expectedCloseAmounts.cash) }}</td>
            <td class="table-field-currency">&euro; {{ $format.currency(cashRegisterOpening.close_amount_cash_counted) }}</td>
            <td class="table-field-currency">&euro; {{ $format.currency(cashRegisterOpening.expectedCloseAmounts.cash - cashRegisterOpening.close_amount_cash_counted) }}</td>
          </tr>
          <tr>
            <td>Pin</td>
            <td class="table-field-currency">&euro; {{ $format.currency(cashRegisterOpening.expectedCloseAmounts.card) }}</td>
            <td class="table-field-currency">&euro; {{ $format.currency(cashRegisterOpening.close_amount_card_counted) }}</td>
            <td class="table-field-currency">&euro; {{ $format.currency(cashRegisterOpening.expectedCloseAmounts.card - cashRegisterOpening.close_amount_card_counted) }}</td>
          </tr>
          <tr>
            <td>Op rekening</td>
            <td class="table-field-currency">&euro; {{ $format.currency(cashRegisterOpening.expectedCloseAmounts.on_account) }}</td>
            <td class="table-field-currency" />
            <td class="table-field-currency" />
          </tr>
          <tr>
            <td>Cadeaubon</td>
            <td class="table-field-currency">&euro; {{ $format.currency(cashRegisterOpening.expectedCloseAmounts.giftcard) }}</td>
            <td class="table-field-currency" />
            <td class="table-field-currency" />
          </tr>
        </tbody>
        <tfoot class="thead-light thead-light--fit">
          <tr>
            <th>Totalen</th>
            <th class="table-field-currency">&euro; {{ $format.currency(totalExpectedAmount) }}</th>
            <th class="table-field-currency">&euro; {{ $format.currency(totalCountedAmount) }}</th>
            <th class="table-field-currency">&euro; {{ $format.currency(totalDifferenceAmount) }}</th>
          </tr>
        </tfoot>
      </table>
    </KPortletBody>
  </KPortlet>
</template>

<script>
// Classes
import CashRegisterOpening from '@/libs/classes/cash_register.opening';

export default {
  props: {
    cashRegisterOpening: {
      type: CashRegisterOpening,
      default: null
    }
  },
  computed: {
    totalExpectedAmount: {
      get () {
        let amount = 0;

        // Cash
        amount = amount + this.cashRegisterOpening.expectedCloseAmounts.cash;

        // Calculate all expected amounts
        if (this.cashRegisterOpening && this.cashRegisterOpening.expectedCloseAmounts) {
          if (this.cashRegisterOpening.expectedCloseAmounts.card) amount = amount + this.cashRegisterOpening.expectedCloseAmounts.card;
          if (this.cashRegisterOpening.expectedCloseAmounts.on_account) amount = amount + this.cashRegisterOpening.expectedCloseAmounts.on_account;
          if (this.cashRegisterOpening.expectedCloseAmounts.giftcard) amount = amount + this.cashRegisterOpening.expectedCloseAmounts.giftcard;
        }

        return amount;
      }
    },
    totalCountedAmount: {
      get () {
        let amount = 0;

        // Calculate counted amount, if cash register opening is closed
        if (this.cashRegisterOpening && this.cashRegisterOpening.open === false) {
          amount = amount + this.cashRegisterOpening.close_amount_cash_counted;
          amount = amount + this.cashRegisterOpening.close_amount_card_counted;
        }

        return amount;
      }
    },
    totalDifferenceAmount: {
      get () {
        let amount = 0;

        // Calculate difference amount, if cash register opening is closed
        if (this.cashRegisterOpening && this.cashRegisterOpening.open === false) {
          // Cash
          amount = amount + this.cashRegisterOpening.expectedCloseAmounts.cash;
          amount = amount - this.cashRegisterOpening.close_amount_cash_counted;

          // Card
          if (this.cashRegisterOpening.expectedCloseAmounts.card) amount = amount + this.cashRegisterOpening.expectedCloseAmounts.card;
          amount = amount - this.cashRegisterOpening.close_amount_card_counted;
        }

        return amount;
      }
    }
  }
};
</script>
