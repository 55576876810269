// Classes
import Abstract from './abstract';

// Libraries
import { merge } from 'lodash';

class BundleProductSession extends Abstract {
  constructor (guid = null) {
    super(guid);

    // Random guid
    this.guid = Math.random();

    let defaultObject = {
      equipment_group_guids: [],
      available_times: [],
      session_time: {
        flexible: null,
        default: null,
        from: null,
        to: null
      },
      session_limit: {
        unlimited: false,
        limit: null,

        interval: {
          value: 0,
          type: 'minutes'
        }
      },
      requires_remark: false,

      // Contains the equipment groups classes of the equipment_group_guids
      equipment_groups: []
    };

    merge(this, defaultObject);
  }

  // Methods
  mergeResponse (response = {}) {
    super.mergeResponse(response);

    // Set equipment group guids to new response instead of merging
    if (response.equipment_group_guids && Array.isArray(response.equipment_group_guids)) this.equipment_group_guids = response.equipment_group_guids;
    if (response.equipment_groups && Array.isArray(response.equipment_groups)) this.equipment_groups = response.equipment_groups;
    if (response.available_times && Array.isArray(response.available_times)) this.available_times = response.available_times;

    // Set flexible time
    this.session_time.flexible = true;
    if (this.session_time.default === this.session_time.from && this.session_time.default === this.session_time.to) {
      this.session_time.flexible = false;
    }

    // Set session unlimited
    this.session_limit.unlimited = false;
    if (this.session_limit.limit === null || this.session_limit.limit === 0) {
      this.session_limit.unlimited = true;
    }

    return this;
  }

  formatBody (type = 'get', options = {}) {
    let body = super.formatBody(type, options);

    // Flexible session_time
    if (body.session_time.flexible === false) {
      body.session_time.from = body.session_time.default;
      body.session_time.to = body.session_time.default;
    }
    delete body.session_time.flexible;

    // Session limit
    if (body.session_limit.unlimited === true) {
      body.session_limit.limit = null;
    }
    delete body.session_limit.unlimited;

    // Equipment groups
    delete body.equipment_groups;

    return body;
  }

  // Getters
  get VUID () {
    return `bundle_product.session.${this.guid}`;
  }

  get default_time_minutes () {
    return Math.floor(this.session_time.default / 60);
  }
  set default_time_minutes (value) {
    this.session_time.default = (value * 60);
  }

  get from_time_minutes () {
    return Math.floor(this.session_time.from / 60);
  }
  set from_time_minutes (value) {
    this.session_time.from = (value * 60);
  }

  get to_time_minutes () {
    return Math.floor(this.session_time.to / 60);
  }
  set to_time_minutes (value) {
    return this.session_time.to = (value * 60);
  }
}

export default BundleProductSession;
