<template>
  <KPortlet>
    <KPortletHead>
      BTW
    </KPortletHead>
    <KPortletBody class="kt-portlet__body--fit position-relative">
      <!-- Loading block -->
      <template v-if="isLoading">
        <div class="loading-container absolute">
          <div class="loading-backdrop">
            <div class="loading-block">
              <div class="blockui">
                <span>Please wait...</span>
                <span class="kt-spinner kt-spinner--v2 kt-spinner--primary" />
              </div>
            </div>
          </div>
        </div>
      </template>

      <!-- Table -->
      <div class="table-responsive">
        <table class="table mb-0">
          <thead class="thead-light thead-light--fit">
            <tr>
              <th class="overflow-ellipsis">Omschrijving</th>
              <th class="table-field-currency overflow-ellipsis">Grondslag</th>
              <th class="table-field-currency overflow-ellipsis">BTW</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(vat) in vatsSorted"
              :key="`vat.${vat.vat_code}`"
            >
              <td class="overflow-ellipsis">{{ $format.percentage((vat.percentage) / 100) }}% BTW</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(vat.total_price_excl) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(vat.total_price_vat) }}</td>
            </tr>
          </tbody>
          <tfoot class="thead-light thead-light--fit">
            <tr>
              <th class="overflow-ellipsis">Totaal</th>
              <th class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency((vat ? vat.total_revenue_excl : 0)) }}</th>
              <th class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency((vat ? vat.total_vat_paid : 0)) }}</th>
            </tr>
          </tfoot>
        </table>
      </div>
    </KPortletBody>
  </KPortlet>
</template>

<script>
export default {
  props: {
    vat: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      isLoading: false
    };
  },
  computed: {
    vatsSorted: {
      get () {
        if (this.vat && this.vat.vats) {
          return this.vat.vats.slice().sort((a, b) => {
            if (a.percentage < b.percentage) return 1;
            if (a.percentage > b.percentage) return -1;
            return 0;
          });
        }
        return [];
      }
    }
  }
};
</script>
