var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('KSubheader',{attrs:{"breadcrumbs":[{
      page: 'Producten',
      route: '/products'
    }, {
      page: 'Sessiekrediet',
      route: '/products/credit'
    }, {
      page: 'Krediet aanmaken',
      route: null
    }],"title":"Sessiekrediet"}}),_vm._v(" "),_c('KContainer',[_c('KPortlet',[_c('KPortletHead',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_vm._v("Krediet aanmaken")]},proxy:true}])}),_vm._v(" "),_c('KPortletBody',[_c('informationForm',{ref:"informationForm",attrs:{"credit_product":_vm.creditProduct,"action":"CREATE"}})],1),_vm._v(" "),_c('div',{staticClass:"kt-portlet__foot"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"offset-6 col-6 offset-lg-10 col-lg-2 kt-align-right"},[_c('KButton',{staticClass:"btn-md btn-block btn-tall btn-wide btn-bold btn-upper",attrs:{"variant":"primary"},nativeOn:{"click":function($event){$event.preventDefault();return _vm.submitForm($event)}}},[_vm._v("\n              Toevoegen\n            ")])],1)])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }