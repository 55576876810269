<template>
  <div :class="['cs-circle progress-container w-100 h-100', `cs-state-${equipment.state}`]">
    <div class="circle-progress-block w-100 h-100">
      <svg
        :class="['circle', circleClass]"
        viewBox="0 0 220 220"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g class="circle-base">
          <circle
            r="100"
            class="circle-progress-base"
          />
          <g class="circle-stroke">
            <circle
              :style="[{'stroke-dasharray': 1000, 'stroke-dashoffset': circleOffset}]"
              r="100"
              class="circle-progress"
            />
          </g>

          <font-awesome-icon
            v-if="showTime === false"
            :icon="icon"
            width="40%"

            x="-45"
            y="-110"
            class="status status-icon"
          />

          <template v-else>
            <!-- Session time -->
            <text
              dominant-baseline="middle"
              text-anchor="middle"
              x="0"
              y="0"
              class="status status-text"
            >
              <!-- Full remaining time -->
              <template v-if="useFullTime === true">
                {{ (equipment.remainingSessionTime ? `${$format.secondsToTime(equipment.remainingSessionTime.session.remaining)}` : null) }}
              </template>

              <!-- State time -->
              <template v-else>
                {{ (equipment.remainingSessionTime ? `${$format.secondsToTime(equipment.remainingSessionTime.state.remaining)}` : null) }}
              </template>
            </text>

            <text
              v-if="useFullTime === true"
              :class="['status status-text status-text--small', `status-text--state-${equipment.state}`]"

              dominant-baseline="middle"
              text-anchor="middle"
              x="0"
              y="40"
            >
              {{ (equipment.remainingSessionTime ? `${$format.secondsToTime(equipment.remainingSessionTime.state.remaining)}` : null) }}
            </text>
          </template>
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
// Classes
import Equipment from '@/libs/classes/equipment';

export default {
  props: {
    equipment: {
      type: Equipment,
      default: null
    },

    useFullTime: {
      type: Boolean,
      default: false
    },

    circleClass: {
      type: [String, Array, Object],
      default: 'w-100 h-100'
    }
  },
  computed: {
    showTime () {
      if (this.equipment) {
        if (this.equipment.state === 'delay') return true;
        if (this.equipment.state === 'session') return true;
        if (this.equipment.state === 'cooldown') return true;
      }

      return false;
    },

    icon () {
      if (this.equipment) {
        if (this.equipment.state === 'powerup') return ['far', 'rocket'];
        else if (this.equipment.state === 'available') return ['far', 'check'];
        else if (this.equipment.state === 'cleaning') return ['far', 'exclamation-triangle'];
        else if (this.equipment.state === 'timeout') return ['fad', 'exclamation-triangle'];
      }

      return ['fad', 'question'];
    },

    circleOffset () {
      if (this.equipment) {
        if (this.equipment.inSession === true && this.equipment.remainingSessionTime !== null) {
          // Set offset to 0 when cleaning
          if (this.equipment.state === 'cleaning') return 0;

          // Calculate offset by percentage of current session, but with Math.PI calculations, because it's a circle
          return (-(Math.PI * 2 * 100) + (Math.PI * 2 * 100) * (this.equipment.remainingSessionTime.state.percentage));
        }
      }

      return 0;
    }
  }
};
</script>
