import { render, staticRenderFns } from "./salesorder-customer.row.vue?vue&type=template&id=8e8488c8&v-if=sales_order.customer&"
import script from "./salesorder-customer.row.vue?vue&type=script&lang=js&"
export * from "./salesorder-customer.row.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports