<template>
  <div>

    <div class="row mt-5">
      <div class="col-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
        <div class="row mt-3" v-if="sales_order && sales_order !== null">
          <div class="col-12">
            <KPortlet class="cs-shop-category-block kt-portlet--height-fluid">
              <KPortletBody class="pb-0">
                <div class="cs-shop-category-block--row">
                  <div class="cs-shop-category-block--icon">
                    <i class="kt-font-brand">
                      <font-awesome-icon :icon="['far', 'check-circle']" />
                    </i>
                  </div>
                  <div class="cs-shop-category-block--content">
                    <h4 class="category-title">Transactie succesvol afgerond</h4>
                  </div>
                </div>
              </KPortletBody>

              <KSeparator class="kt-separator--space-sm" />

              <KPortletBody class="pt-3 pb-3">

                <div class="row form-group mb-0">
                  <div class="col-12">
                    <label>Wisselgeld</label>
                    <div class="input-group cs-shop-payment-method-input">
                      <div class="input-group-prepend">
                        <span class="input-group-text">
                          <i><font-awesome-icon :icon="['fad', 'euro-sign']" /></i>
                        </span>
                      </div>
                      <input type="text" class="form-control" :value="$format.currency($format.cashChange(sales_order.change))" disabled />
                    </div>
                  </div>
                </div>

              </KPortletBody>

              <KSeparator class="kt-separator--space-sm" />

              <KPortletBody class="pt-3 pb-3">
                <div class="row">
                  <div class="col-6">
                    <KButton variant="focus" class="btn-block btn-lg" disabled>E-mail bon</KButton>
                  </div>
                  <div class="col-6">
                    <KButton variant="focus" class="btn-block btn-lg" disabled>Print bon</KButton>
                  </div>
                </div>
              </KPortletBody>
            </KPortlet>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
// Mixins
import shop from '@/mixins/shop';

// Classes
import CashRegister from '@/libs/classes/cash_register';

export default {
  mixins: [shop],
  props: {
    meta: Object,
    cash_register: CashRegister
  },
  data() {
    return {
      sales_order: null
    }
  },

  mounted() {
    // Set sales order of shop
    this.$set(this, 'sales_order', this.shop.sales_order);

    // Check if sales order is fine for payments
    this.checkSalesOrder();

    // Listeners
    this.$eventhub.on('shop:cart:updated', this.onCartUpdated);
  },
  beforeDestroy() {
    // Remove listeners
    this.$eventhub.off('shop:cart:updated', this.onCartUpdated);
  },

  methods: {
    onCartUpdated(sales_order) {
      // First set to null, so it can update properly
      this.$set(this, 'sales_order', null);

      // Set to real sales_order on next tick
      this.$nextTick(() => {
        this.$set(this, 'sales_order', sales_order);
        this.checkSalesOrder();
      });
    },

    checkSalesOrder() {
      if (this.sales_order && this.sales_order.paid === true) {
        return this.sales_order;
      }
      return this.navigateTo('/sales/shop/:cash_register/payments');
    }
  }
}
</script>
