<template>
  <b-modal
    ref="modal"
    size="lg"
    centered
  >
    <template v-slot:modal-title>
      {{ (options.title ? options.title : 'Fout') }}
    </template>

    <template v-slot:default>
      <KAlert
        :icon="(options.icon ? options.icon : ['fad', 'exclamation-triangle'])"
        :variant="(options.variant ? options.variant : 'outline-danger')"
        class="mb-0"
      >
        <span v-html="(options.error ? options.error : 'Undefined error')" />
      </KAlert>
    </template>

    <template v-slot:modal-footer>
      <KButton
        variant="secondary"
        @click.native.prevent="hide"
      >
        Sluiten
      </KButton>
    </template>
  </b-modal>
</template>

<script>
// Components
import Modal from '@/mixins/modal';

export default {
  mixins: [Modal],
  data () {
    return {
      options: null
    };
  },

  mounted () {
    // Listeners
    this.$eventhub.on('modals:error:open', this.show);
    this.$eventhub.on('modals:error:close', this.hide);
  },
  beforeDestroy () {
    // Remove listeners
    this.$eventhub.off('modals:error:open', this.show);
    this.$eventhub.off('modals:error:close', this.hide);
  },

  methods: {
    // General methods (like show & hide are in the modal mixin)
    show (options = {}) {
      // Set options to null again
      this.$set(this, 'options', null);

      // Check for options before showing modal
      if (typeof options === 'object') {
        // Set options to this.options, for future reference
        this.$set(this, 'options', options);

        // Show modal
        Modal.methods.show.call(this);
      }
    }
  }
};
</script>
