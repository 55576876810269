<template>
  <div>
    <div class="row mt-3">
      <div class="col-12">
        <div class="kt-input-icon kt-input-icon--left">
          <input
            type="text"
            class="form-control"
            placeholder="Zoeken..."
            @keyup.enter="$refs['datatable'].search"
            @keyup="searchDebounced"
          >
          <span class="kt-input-icon__icon kt-input-icon__icon--left">
            <span>
              <i>
                <font-awesome-icon
                  :icon="['far', 'search']"
                />
              </i>
            </span>
          </span>
        </div>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-12 datatable datatable-wrapper">
        <datatable
          ref="datatable"
          :url="datatable.url"
          :fields="datatable.fields"
          :search-fields="datatable.searchFields"
          :on-pagination-data="onPaginationData"
          :on-cell-clicked="onCellClicked"
          :transform="transform"
          :css="datatable.css"
        />

        <div class="row pagination">
          <div class="col-xs-12 col-md-6">
            <datatable-pagination
              ref="pagination"
              @vuetable-pagination:change-page="onChangePage"
            />
          </div>

          <div class="col-xs-12 col-md-6">
            <datatable-pagination-info
              ref="pagination.info"
              @vuetable-paginationinfo:change-rowcount="onChangeRowCount"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Libraries
import shop from '@/libs/shop';

// Classes
import Customer from '@/libs/classes/customer';

// Mixins
import datatable from '@/mixins/datatable';

export default {
  mixins: [datatable],
  props: {
    onCustomerSelected: {
      type: Function,
      default: null
    }
  },
  data () {
    return {
      datatable: {
        url: '/customers',
        fields: [{
          title: 'Naam',
          name: 'name',
          sortField: 'firstname',
          dataClass: 'cursor-pointer w-1/8'
        }],

        searchFields: ['firstname', 'lastname', 'address.country', 'address.postalCode', 'address.houseNumber', 'address.line1', 'address.city']
      }
    };
  },

  methods: {
    async onCellClicked (customer) {
      // Error
      let err = null;

      // Set customer
      if (this.$refs['datatable']) this.$refs['datatable'].loading = true;
      try {
        await shop.setCustomer(customer.guid);
      } catch (e) {
        console.error(e);
        err = e;
      }
      if (this.$refs['datatable']) this.$refs['datatable'].loading = false;

      if (err === null) {
        // Execute onCustomerSelected if set
        if (typeof this.onCustomerSelected === 'function') {
          await this.onCustomerSelected(customer);
        }
      }
    },

    transform (data) {
      if (data.data && Array.isArray(data.data)) {
        data.data = data.data.map((row) => {
          return new Customer().mergeResponse(row);
        });
      }

      return data;
    }
  }
};
</script>
