// Classes
import Abstract from './abstract';
import Master from './master';
import EquipmentGroup from './equipment_group';
import EquipmentHourcounter from './equipment.hourcounter';
import SessionProduct from './session_product';
import Session from './session';

// Libraries
import { merge } from 'lodash';
import { apiWS as ws } from '@/libs/ws';
import auth from '@/libs/auth';
import mutationsLib from '@/libs/mutations';

class Equipment extends Abstract {
  constructor (guid = null) {
    super(guid);
    this.uri = '/equipments';

    let defaultObject = {
      name: null,
      room: null,
      default_session_guid: null,

      group_guid: null,
      serial: null,
      master_guid: null,

      pricelists: [],

      brand: null,
      model: null,
      type: null,

      energy_consumption: 0,
      uv_type: null,
      radiation: null,

      notes: null,

      timers: [],

      // Only used when communicating with master
      communication: null
    };

    merge(this, defaultObject);
  }

  // Methods
  mergeResponse (response = {}) {
    super.mergeResponse(response);

    // Set timers to new response instead of merging
    if (response.timers && Array.isArray(response.timers)) this.timers = response.timers;

    // Map timers to EquipmentHourcounter
    this.timers = this.timers.map((timer) => new EquipmentHourcounter().merge(timer));

    return this;
  }
  formatBody (type = 'get', options = {}) {
    let body = super.formatBody(type, options);

    // Remove communication
    delete body.communication;

    return body;
  }

  async populate (type = 'get', options = {}) {
    try {
      await Promise.all([
        // Get group
        (async (options) => {
          if (auth.hasPermission('get_equipment_groups')) {
            this.group = await this.getGroup(options);
          }
        })(options),

        // Get default session
        (async (options) => {
          this.default_session = await this.getDefaultSession(options);
        })(options),

        // Get master
        (async (options) => {
          if (auth.hasPermission('get_masters')) {
            this.master = await this.getMaster(options);
          }
        })(options)
      ]);
    } catch (e) {}

    return this;
  }

  async getDefaultSession (options = {}) {
    if (typeof this.default_session_guid === 'string') {
      const session = await new SessionProduct(this.default_session_guid).get(options);
      this.default_session = session;
    }
    return this.default_session;
  }

  async getMaster (options = {}) {
    if (typeof this.master_guid === 'string') {
      const master = await new Master(this.master_guid).get(options);
      await master.populate('get', options);
      this.master = master;
    }
    return this.master;
  }

  async write (action = '', payload = {}, options = {}) {
    if (!this.master) {
      this.master = await this.getMaster(options);
      await this.master.getSocket();
    }

    // Always add the slave CSID to the payload
    if (typeof payload !== 'object') payload = {};
    payload.slave = this.serial;

    return this.master.socket.device(action, payload);
  }

  async getGroup (options = {}) {
    if (typeof this.group_guid === 'string') {
      const group = await new EquipmentGroup(this.group_guid).get(options);
      await group.populate('get', options);
      this.group = group;
    }
    return this.group;
  }

  async getSessionProducts (options = {}) {
    let products = await ws.get('v1', `/session_products/equipment/${this.guid}`, options);
    products = products.map(p => new SessionProduct().mergeResponse(p), []);
    return products;
  }

  async getSessionQueue (options = {}) {
    // Check for master
    if (!this.master) {
      this.master = await this.getMaster(options);
      await this.master.getSocket();
    }

    // If master is offline, do nothing for now
    // TODO: Get the session queue data from the Cloud API
    if (this.master.socket.online === false) {
      return [];
    }

    // Ask queue from master
    let sessions = await this.write('slaves:sessions:queue:get');
    if (Array.isArray(sessions) === false) {
      return [];
    }

    // Map to Session class
    sessions = sessions.map(session => {
      return new Session().mergeResponse(session);
    }, []);

    return sessions;
  }

  async connect (body = {}, options = {}) {
    // Connect
    let connectOptions = options;
    connectOptions.body = {
      master_guid: this.master_guid
    };

    // Set _meta and guid after connect, because a merge will overrule all changes
    let response = await ws.put('v1', `${this.uri}/${this.serial}/connect`, connectOptions);
    this._meta = response._meta;
    this.guid = response._meta.guid;

    // Connect equipment to master
    try {
      const master = await new Master(this.master_guid).get(options);
      await master.connectSlave(this.serial);
    } catch (e) {
      console.error('Failed to communicate directly to Core', e);
    }

    // Update
    const fields = ['name', 'room', 'default_session_guid', 'group_guid', 'brand', 'model', 'type', 'energy_consumption', 'uv_type', 'radiation'];
    const mutations = mutationsLib.create(fields, {}, this);
    response = await this.update(mutations);

    return this;
  }

  async disconnect (body = {}, options = {}) {
    // Disconnect
    // Set _meta and guid after connect, because a merge will overrule all changes
    let response = await ws.put('v1', `${this.uri}/${this.serial}/disconnect`);
    this._meta = response._meta;
    this.guid = response._meta.guid;

    // Disconnect equipment from master
    try {
      const master = await new Master(this.master_guid).get(options);
      await master.disconnectSlave(this.serial);
    } catch (e) {
      console.error('Failed to communicate directly to Core', e);
    }

    // Disconnect, so remove all fields
    const fields = ['name', 'room', 'default_session_guid', 'group_guid', 'brand', 'model', 'type', 'energy_consumption', 'uv_type', 'radiation'];
    const mutations = fields.map((field) => {
      return {
        action: 'remove_field',
        field: field
      };
    }, []);
    response = await this.update(mutations);

    return this;
  }

  async setCommunication (communication, options = {}) {
    // Set object if not defined
    if (!this.communication) this.communication = {};

    // Check current session
    if (communication.information && communication.information.session) {
      // When session is not active, set session to null
      if (communication.information.session.active === false) {
        this.communication.session = null;
      } else {
        // Check if session is the same, so we can just copy the session
        if (this.communication && this.communication.session && this.communication.session.id === communication.information.session.id) {
          communication.session = this.communication.session;
        } else {
          // Try to get session details because it's not set yet or id has changed
          try {
            const session = await ws.get('v1', '/sessions', {
              query: {
                limit: 1,
                'filter[equipment_guid]': this.guid,
                'filter[id]': communication.information.session.id
              }
            });

            if (session && session.length) {
              communication.session = new Session().mergeResponse(session[0]);
            }
          } catch (e) {
            console.error('Failed fetching session', e);
          }
        }
      }
    }

    // Set communication variable
    this.communication = communication;

    return this.communication;
  }

  // Getters & Setters
  get state () {
    if (this.communication && this.communication.communication) {
      // Check if communicated, enabled and not timed out
      if (this.communication.communication.communicated === true && this.communication.communication.enabled === true && this.communication.communication.timedOut === false) {
        if (this.communication.information.state !== null) {
          if (this.communication.information.state === 0) return 'powerup';
          else if (this.communication.information.state === 1) return 'available';
          else if (this.communication.information.state === 2) return 'delay';
          else if (this.communication.information.state === 3) return 'session';
          else if (this.communication.information.state === 4) return 'cooldown';
          else if (this.communication.information.state === 5) return 'cleaning';
        }
      } else if (this.communication.communication.timedOut === true) {
        return 'timeout';
      }
    }

    return 'unknown';
  }

  get inSession () {
    if (this.state === 'delay') return true;
    if (this.state === 'session') return true;
    if (this.state === 'cooldown') return true;
    if (this.state === 'cleaning') return true;
    return false;
  }

  get remainingSessionTime () {
    if (this.inSession === true) {
      if (this.communication && this.communication.information) {
        if (this.communication.information.session && this.communication.information.session.active === true) {
          const returnObject = {
            state: {
              value: 0,
              remaining: 0,
              percentage: 0
            },
            session: {
              value: 0,
              remaining: 0,
              percentage: 0
            },
            end_timestamp: 0
          };

          // Check if Date.now is a function, and set end_timestamp
          if (Date.now) {
            returnObject.end_timestamp = (Date.now() / 1000);
          } else {
            returnObject.end_timestamp = (new Date().getTime() / 1000);
          }

          // Fill returnObject
          returnObject.state.remaining = this.communication.information.session.remainingTime;
          returnObject.session.value = this.communication.information.session.config.delay + this.communication.information.session.config.time + this.communication.information.session.config.cooldown;
          if (this.state === 'delay') {
            returnObject.state.value = this.communication.information.session.config.delay;
            returnObject.session.remaining = this.communication.information.session.remainingTime + this.communication.information.session.config.time + this.communication.information.session.config.cooldown;
          } else if (this.state === 'session') {
            returnObject.state.value = this.communication.information.session.config.time;
            returnObject.session.remaining = this.communication.information.session.remainingTime + this.communication.information.session.config.cooldown;
          } else if (this.state === 'cooldown') {
            returnObject.state.value = this.communication.information.session.config.cooldown;
            returnObject.session.remaining = this.communication.information.session.remainingTime;
          }

          // Set percentages
          returnObject.state.percentage = returnObject.state.remaining / returnObject.state.value;
          returnObject.session.percentage = returnObject.session.remaining / returnObject.session.value;

          // Set end_timestamp to ms
          returnObject.end_timestamp = (returnObject.end_timestamp + returnObject.session.remaining) * 1000;

          return returnObject;
        }
      }
    }

    return null;
  }

  get hasTimerWarning () {
    return (this.timers && this.timers.some((timer) => timer.isAboveWarning === true));
  }
  get hasTimerAboveMax () {
    return (this.timers && this.timers.some((timer) => timer.isAboveWarning === true && (timer.percentage * 100) >= 100));
  }
}

export default Equipment;
