// Libraries
import i18n from '@/libs/i18n';
import eventhub from '@/libs/eventhub';
import { merge } from 'lodash';

class DomainError extends Error {
  constructor (type, data) {
    super(type);
    this.name = this.constructor.name;
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor);
    }
    console.log(this.name, type, data);
  }
}

export class FrontendError extends DomainError {
  constructor (options = {}) {
    super('frontendError', options);
  }
};

export class ApiError extends DomainError {
  constructor (apiError = {}) {
    super('apiError', apiError);

    this.apierror = apiError;
  }
}

export default class error {
  static getError (error) {
    const errorObject = {
      string: `errors.${error.error}.${error.info}`,
      title: `Unknown error - ${error.error}`,
      message: `An unknown error occured (${error.error} - ${error.info})<br />${error.message}`
    };

    // Check if title translation is found
    // If not, get fallback
    if (i18n.te(`${errorObject.string}.title`)) {
      errorObject.title = i18n.t(`${errorObject.string}.title`, error);
    } else if (i18n.te(`errors.fallback.title`)) {
      errorObject.title = i18n.t(`errors.fallback.title`, error);
    }

    // Check if title message is found
    // If not, get fallback
    if (i18n.te(`${errorObject.string}.message`)) {
      errorObject.message = i18n.t(`${errorObject.string}.message`, error);
    } else if (i18n.te(`errors.fallback.message`)) {
      errorObject.message = i18n.t(`errors.fallback.message`, error);
    }

    return errorObject;
  }

  static showModal (error) {
    const errorObject = this.getError(error);
    return eventhub.emit('modals:error:open', {
      title: errorObject.title,
      error: errorObject.message
    });
  }

  static createError (error, options = {}) {
    let returnError = new Error(error);

    // Add source
    returnError.source = 'frontend';

    // Merge options
    returnError = merge(returnError, options);

    return returnError;
  }
};
