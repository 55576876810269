// Classes
import Abstract from './abstract';
import Customer from './customer';
import Equipment from './equipment';

// Libraries
import { merge } from 'lodash';

class Session extends Abstract {
  constructor (guid = null) {
    super(guid);
    this.uri = '/sessions';

    let defaultObject = {

    };

    merge(this, defaultObject);
  }

  async populate (type = 'get', options = {}) {
    // Get customer
    if (this.customer_guid) {
      this.customer = await this.getCustomer(options);
    }

    return this;
  }

  async getCustomer (options = {}) {
    if (this.customer_guid) {
      this.customer = await new Customer(this.customer_guid).get(options);
    }
    return this.customer;
  }

  async getEquipment (options = {}) {
    if (this.equipment_guid) {
      this.equipment = await new Equipment(this.equipment_guid).get(options);
    }
    return this.equipment;
  }
};

export default Session;
