<template>
  <KPortlet>
    <KPortletHead>
      <template v-slot:default>
        {{ $format.capitalizeFirstLetter(moment(month, 'MM-YYYY', true).format('MMMM YYYY')) }}
      </template>
    </KPortletHead>
    <KPortletBody class="kt-portlet__body--fit position-relative">
      <!-- Loading block -->
      <template v-if="isLoading">
        <div class="loading-container absolute">
          <div class="loading-backdrop">
            <div class="loading-block">
              <div class="blockui">
                <span>Please wait...</span>
                <span class="kt-spinner kt-spinner--v2 kt-spinner--primary" />
              </div>
            </div>
          </div>
        </div>
      </template>

      <!-- Table -->
      <div class="table-responsive">
        <table class="table mb-0">
          <thead class="thead-light thead-light--fit">
            <tr>
              <th class="overflow-ellipsis">Datum</th>
              <th class="table-field-currency overflow-ellipsis">Transacties</th>
              <th class="table-field-currency overflow-ellipsis">Contant</th>
              <th class="table-field-currency overflow-ellipsis">PIN</th>
              <th class="table-field-currency overflow-ellipsis">Cadeaubon</th>
              <th class="table-field-currency overflow-ellipsis">Op rekening</th>
              <th class="table-field-currency overflow-ellipsis">BTW</th>
              <th class="table-field-currency overflow-ellipsis">Omzet</th>
              <th class="table-field-currency overflow-ellipsis">Sessiekrediet</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="day in daysOfMonth"
              :key="`day.row.${day.moment.format('DD-MM-YYYY')}`"
            >
              <td class="overflow-ellipsis">{{ day.moment.format('dddd D MMMM') }}</td>
              <td class="table-field-currency overflow-ellipsis">{{ (objectPath.get(day.totals, 'total_transactions') || 0) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(objectPath.get(day.totals, 'payment_methods.cash.sum')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(objectPath.get(day.totals, 'payment_methods.card.sum')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(objectPath.get(day.totals, 'payment_methods.giftcards.sum')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(objectPath.get(day.totals, 'payment_methods.on_account.sum')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(objectPath.get(day.totals, 'revenue.vat')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(objectPath.get(day.totals, 'revenue.incl_vat')) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(objectPath.get(day.totals, 'payment_methods.session_credits.sum')) }}</td>
            </tr>
          </tbody>
          <tfoot class="thead-light thead-light--fit">
            <tr>
              <th class="overflow-ellipsis">Totaal</th>
              <th class="table-field-currency overflow-ellipsis">{{ getTotal('total_transactions') }}</th>
              <th class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getTotal('payment_methods.cash.sum')) }}</th>
              <th class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getTotal('payment_methods.card.sum')) }}</th>
              <th class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getTotal('payment_methods.giftcards.sum')) }}</th>
              <th class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getTotal('payment_methods.on_account.sum')) }}</th>
              <th class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getTotal('revenue.vat')) }}</th>
              <th class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getTotal('revenue.incl_vat')) }}</th>
              <th class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getTotal('payment_methods.session_credits.sum')) }}</th>
            </tr>
          </tfoot>
        </table>
      </div>
    </KPortletBody>
  </KPortlet>
</template>

<script>
// Libraries
import moment from 'moment-timezone';
import objectPath from 'object-path';

export default {
  props: {
    month: {
      type: String,
      default: null
    },
    totals: {
      type: Array,
      default: null
    }
  },
  data () {
    return {
      isLoading: false,

      objectPath,
      moment
    };
  },
  computed: {
    daysOfMonth: {
      get () {
        let days = [];

        // Check if month is given
        if (this.month) {
          const month = moment(this.month, 'MM-YYYY', true);
          const currentMonth = moment();

          // Calculate days in month
          let daysInMonth = month.daysInMonth();
          if (month.format('MM-YYYY') === currentMonth.format('MM-YYYY')) {
            daysInMonth = currentMonth.date();
          }

          // Push all days to days Array
          for (let day = 1; day <= daysInMonth; day++) {
            let dayObject = {
              moment: moment(this.month, 'MM-YYYY').set({date: day}),
              totals: null
            };

            if (this.totals) {
              dayObject.totals = this.getDay(dayObject.moment.format('DD-MM-YYYY'));
            }

            days.push(dayObject);
          }
        }

        return days;
      }
    }
  },

  methods: {
    getDay (day = null) {
      // Check if day is given & totals are loaded
      if (day && this.totals) {
        // Format day
        const dayObject = moment(day, 'DD-MM-YYYY');

        // Check if day exists
        if (dayObject.isValid()) {
          return this.totals.find(m => m.interval === dayObject.format('DD-MM-YYYY [24:00]'));
        }
      }
      return null;
    },
    getTotal (key) {
      const days = this.daysOfMonth;
      if (days.length > 0) {
        return days.filter(day => typeof objectPath.get(day.totals, key) !== typeof undefined).map(day => objectPath.get(day.totals, key), []).reduce((a, b) => a + b, 0);
      }
      return 0;
    }
  }
};
</script>
