<template>
  <div>

    <div class="row form-group">
      <div class="col-12 offset-sm-0 col-xl-6 offset-xl-3">

        <div :class="['row form-group mb-3', {'border-bottom pb-3': session.session_limit.unlimited !== null}]">
          <div class="col-12">
            <KButtonGroup class="btn-block">
              <KButton
                :variant="(session.session_limit.unlimited === false ? 'primary' : 'outline-primary')"
                @click.native.prevent="session.session_limit.unlimited = false"
              >
                Gelimiteerd
              </KButton>

              <KButton
                :variant="(session.session_limit.unlimited === true ? 'primary' : 'outline-primary')"
                @click.native.prevent="session.session_limit.unlimited = true"
              >
                Ongelimiteerd
              </KButton>
            </KButtonGroup>
          </div>
        </div>

        <!-- Only show when sessions are limited -->
        <div
          v-if="session.session_limit.unlimited === false"
          class="row form-group border-bottom pb-3 pt-0 mb-3"
        >
          <div class="col-12">
            <label>Aantal sessies</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i><font-awesome-icon :icon="['fad', 'hashtag']" /></i>
                </span>
              </div>
              <input
                v-model.number="$v.session.session_limit.limit.$model"
                :class="['form-control', {'is-invalid': $v.session.session_limit.limit.$error}]"

                type="number"
              >
            </div>
          </div>
        </div>

        <!-- Interval -->
        <div class="row form-group border-bottom mt-0 pb-3">
          <div class="col-12 col-lg-6">
            <label>Interval tussen sessies</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i><font-awesome-icon :icon="['fad', 'hashtag']" /></i>
                </span>
              </div>
              <input
                v-model.number="$v.session.session_limit.interval.value.$model"
                :class="['form-control', {'is-invalid': $v.session.session_limit.interval.value.$error}]"

                type="number"
              >
            </div>
          </div>

          <div class="col-12 col-lg-6">
            <div class="input-group label-margin">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i><font-awesome-icon :icon="['fad', 'calendar']" /></i>
                </span>
              </div>
              <select
                v-model="session.session_limit.interval.type"
                class="form-control"
              >
                <option value="minutes">{{ (session.session_limit.interval.value === 1 ? `Minuut` : `Minuten`) }}</option>
                <option value="hours">{{ (session.session_limit.interval.value === 1 ? `Uur` : `Uren`) }}</option>
                <option value="days">{{ (session.session_limit.interval.value === 1 ? `Dag` : `Dagen`) }}</option>
                <option value="weeks">{{ (session.session_limit.interval.value === 1 ? `Week` : `Weken`) }}</option>
              </select>
            </div>
          </div>
        </div>

        <!-- Requires remark -->
        <div class="row form-group">
          <div class="col-12 text-center">
            <label :class="['kt-checkbox kt-checkbox--bold', {'kt-checkbox--success': session.requires_remark === true}]">
              <input
                v-model="session.requires_remark"
                type="checkbox"
              >
              Opmerking verplicht voor het starten
              <span />
            </label>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
// Classes
import BundleProductSession from '@/libs/classes/bundle_product.session';

// Validations
import { requiredIf, minValue } from 'vuelidate/lib/validators';

export default {
  props: {
    session: {
      type: BundleProductSession,
      default: null
    }
  },
  validations: {
    session: {
      session_limit: {
        limit: {
          required: requiredIf(function (value) {
            return (this.session.session_limit.unlimited === false);
          }),
          minValue: minValue(1)
        },
        interval: {
          value: {
            minValue: minValue(0)
          }
        }
      }
    }
  }
};
</script>
