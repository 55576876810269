<template>
  <div>
    <loader v-if="!done"></loader>
    <template v-if="done">
      <h3 v-if="update === true">Stores &raquo; Update &raquo; {{ store.name }}</h3>
      <h3 v-if="update === false">Stores &raquo; Create</h3>
      <hr />
      Name:<br />
      <input type="text" v-model="store.name" /><br /><br />

      <button v-if="update === true" v-click="save">Update store</button>
      <button v-if="update === false" v-click="create">Create store</button>
    </template>
  </div>
</template>

<script>
export default {
  data () {
    return {
      'update': false,
      'done': false,
      'store': {}
    };
  },

  'methods': {
    async getStore () {
      this.store = await this.$ws.get('v1', '/stores/' + this.$route.params.guid);
    },

    async save () {
      const mutations = [{
        'action': 'set_field',
        'field': 'name',
        'value': this.store.name
      }];

      this.store = await this.$ws.patch('v1', '/stores/' + this.$route.params.guid, {
        'body': mutations
      });

      this.$router.push('/stores/view/' + this.store._meta.guid);
    },

    async create () {
      this.store = await this.$ws.post('v1', '/stores', {
        'body': this.store
      });

      this.$router.push('/stores/view/' + this.store._meta.guid);
    }
  },

  async mounted () {
    if (this.$route.params.guid) {
      try {
        await this.getStore();
        this.update = true;
      } catch (e) {
        console.error('Store not found:', e);
      }
    }
    this.done = true;
  }
};
</script>
