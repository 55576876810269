<template>
  <KModal ref="modal" title="Opties" centered size="xl" scrollable v-keydown="{'escape': close }">
    <template v-slot:default>

      <div class="row">
        <div class="col-12 col-lg-4">
          <category-block :icon="['fad', 'trash-alt']" class="shadow-25" iconClass="kt-font-danger"
            v-on:click.native.prevent="deleteSalesOrder"
          >
            <h4 class="category-title">Leeg winkelwagen</h4>
          </category-block>
        </div>

        <div class="col-12 col-lg-4">
          <category-block :icon="['fad', 'cart-plus']" class="shadow-25" iconClass="kt-font-focus"
            v-on:click.native.prevent="parkSalesOrder"
          >
            <h4 class="category-title">Parkeer winkelwagen</h4>
          </category-block>
        </div>

        <div class="col-12 col-lg-4">
          <category-block :icon="['fad', 'cart-arrow-down']" class="shadow-25"
            v-on:click.native.prevent="openStoredSalesorders"
          >
            <h4 class="category-title">Ophalen winkelwagen</h4>
          </category-block>
        </div>
      </div>

    </template>

    <template v-slot:footer>
      <KButton variant="secondary" v-on:click.native.prevent="close">Annuleren</KButton>
    </template>
  </KModal>
</template>

<script>
// Libraries
import shop from '@/libs/shop';

// Components
import categoryBlock from '@/components/sales/shop/view/components/category.block';

export default {
  components: {
    categoryBlock
  },

  mounted () {
    // Listeners
    this.$eventhub.on('modals:shop:shop-options:open', this.open);
    this.$eventhub.on('modals:shop:shop-options:close', this.close);
  },
  beforeDestroy () {
    // Remove listeners
    this.$eventhub.off('modals:shop:shop-options:open', this.open);
    this.$eventhub.off('modals:shop:shop-options:close', this.close);
  },

  methods: {
    async deleteSalesOrder () {
      if (shop.sales_order && shop.sales_order._meta) {
        if (shop.sales_order.paid !== true) {
          await shop.deleteSalesOrder();
        } else {
          shop.removeSalesOrder();
        }
      }
      this.close();
    },
    parkSalesOrder () {
      shop.removeSalesOrder();
      this.close();
    },
    openStoredSalesorders () {
      this.close();
      this.$nextTick(() => {
        this.$eventhub.emit('modals:shop:stored-salesorders:open');
      })
    },

    open () {
      this.$refs.modal.show();
    },
    close () {
      if (this.$refs.modal !== typeof undefined) this.$refs.modal.hide();
    }
  }
}
</script>
