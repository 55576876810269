<template>
  <!-- Loading -->
  <KContainer v-if="isMounted === false">
    <div class="row mt-3">
      <div class="col-12 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
        <div class="loading-container">
          <div class="loading-block">
            <div class="blockui ml-auto mr-auto w-100">
              <span>Gegevens ophalen...</span>
              <span class="kt-spinner kt-spinner--v2 kt-spinner--primary" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </KContainer>

  <!-- Finished loading -->
  <div v-else>
    <KPortlet>
      <KPortletHead>
        <template v-slot:default>Tweede scherm "{{ secondScreen.name }}"</template>
      </KPortletHead>
      <KPortletBody>
        <div class="row form-group">
          <div class="col-12">
            <label>Naam <sup>Verplicht</sup></label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i><font-awesome-icon :icon="['fad', 'text']" /></i>
                </span>
              </div>
              <input
                v-model.trim="secondScreenClone.name"
                type="text"
                class="form-control"
              >
            </div>
          </div>
        </div>
      </KPortletBody>
      <div class="kt-portlet__foot">
        <div class="row align-items-center">
          <div class="offset-6 col-6 offset-lg-10 col-lg-2 kt-align-right">
            <KButton
              variant="warning"
              class="btn-md btn-block btn-tall btn-wide btn-bold btn-upper"
              @click.native.prevent="submitForm"
            >
              Wijzigen
            </KButton>
          </div>
        </div>
      </div>
    </KPortlet>

  </div>
</template>

<script>
// Libraries
import mutationsLib from '@/libs/mutations';
import { cloneDeep } from 'lodash';

// Classes
import SecondScreen from '@/libs/classes/second_screens';

export default {
  data () {
    return {
      isMounted: false,

      secondScreen: null,
      secondScreenClone: null
    };
  },

  async mounted () {
    try {
      await this.getSecondScreen();
    } catch (e) {
      console.error('second screen not found:', e);
    }

    this.$nextTick(() => {
      this.isMounted = true;
    });
  },

  methods: {
    async getSecondScreen (options = {}) {
      this.$set(this, 'secondScreen', null);
      const secondScreen = await new SecondScreen(this.$route.params.guid).get(options);
      this.$set(this, 'secondScreen', secondScreen);
      this.$set(this, 'secondScreenClone', cloneDeep(secondScreen));
      return this.secondScreen;
    },

    async submitForm () {
      const fields = ['name'];
      const mutations = mutationsLib.create(fields, this.secondScreen.formatBody(), this.secondScreenClone.formatBody());

      if (mutations.length) {
        await this.secondScreen.update(mutations);
        this.$set(this, 'secondScreenClone', cloneDeep(this.secondScreen));
      }
    }
  }
};
</script>
