<template>
  <!-- Loading -->
  <KContainer v-if="isMounted === false">
    <div class="row mt-3">
      <div class="col-12 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
        <div class="loading-container">
          <div class="loading-block">
            <div class="blockui ml-auto mr-auto w-100">
              <span>Gegevens ophalen...</span>
              <span class="kt-spinner kt-spinner--v2 kt-spinner--primary" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </KContainer>

  <!-- Finished loading -->
  <div v-else>
    <!-- Breadcrumbs when coming from the sales page -->
    <template v-if="routeSort === 'sales'">
      <KSubheader
        :breadcrumbs="[{
          page: 'Kassa',
          route: null
        }, {
          page: 'Kassa\'s',
          route: '/sales/cash_registers'
        },{
          page: sales.cashRegister.name
        }, {
          page: 'Transacties',
          route: `/sales/cash_registers/view/${sales.cashRegister.guid}/salesorders`
        }, {
          page: 'Transactie',
          route: null
        }]"

        title="Transacties"
      />
    </template>

    <!-- Breadcrumbs when coming from the cash_register page -->
    <template v-else>
      <KSubheader
        :breadcrumbs="[{
          page: 'Kassabeheer',
          route: '/cash_registers/overview'
        }, {
          page: 'Transacties',
          route: '/cash_registers/salesorders/overview'
        }, {
          page: 'Transactie'
        }]"

        title="Transacties"
      />
    </template>

    <KContainer>

      <div class="row">
        <div class="col-12 col-md-6">
          <information-portlet
            ref="informationPortlet"

            :salesorder="salesorder"
            :cash-register="cashRegister"

            class="kt-portlet--height-fluid"
          />
        </div>

        <div class="col-12 col-md-6">
          <customer-portlet
            ref="customerPortlet"

            :salesorder="salesorder"
            :customer="salesorder.customer"

            class="kt-portlet--height-fluid"
          />
        </div>
      </div>

      <!-- Remark -->
      <div
        v-if="salesorder.remark"
        class="row"
      >
        <div class="col-12">
          <KPortlet>
            <KPortletHead>
              <template v-slot:default>
                Opmerking
              </template>
            </KPortletHead>
            <KPortletBody
              style="white-space: pre-line; word-break: keep-all;"
              v-text="salesorder.remark.trim()"
            />
          </KPortlet>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <lines-portlet
            ref="linesPortlet"
            :salesorder="salesorder"
          />
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <payment-transactions-portlet
            ref="paymentTransactionsPortlet"
            :salesorder="salesorder"
          />
        </div>
      </div>

    </KContainer>
  </div>
</template>

<script>
// Libraries
import moment from 'moment-timezone';

// Components
import informationPortlet from './components/information';
import customerPortlet from './components/customer';
import linesPortlet from './components/lines';
import paymentTransactionsPortlet from './components/payment_transactions';

// Classes
import SalesOrder from '@/libs/classes/salesorder';
import CashRegister from '@/libs/classes/cash_register';

export default {
  components: {
    informationPortlet,
    customerPortlet,
    linesPortlet,
    paymentTransactionsPortlet
  },
  data () {
    return {
      isMounted: false,

      salesorder: null,
      cashRegister: null,

      sales: {
        cashRegister: null
      },

      moment
    };
  },
  computed: {
    routeSort () {
      if (this.$route.name === '/sales/cash_registers/view/:cash_register/salesorders/:guid') {
        return 'sales';
      }

      return 'cash_register';
    }
  },

  async mounted () {
    try {
      await this.getSalesOrder();

      // Get extra details
      await Promise.all([
        this.getCashRegister(this.salesorder.cash_register_guid),
        (async () => {
          // Get cash register from cash register params, when coming from sales
          if (this.routeSort === 'sales') {
            this.sales.cashRegister = await new CashRegister(this.$route.params.cash_register).get();
          }
        })()
      ]);
    } catch (e) {
      console.error('Sales order not found:', e);
      this.$error.showModal(e);
      return;
    }

    this.$nextTick(() => {
      this.isMounted = true;
    });
  },

  methods: {
    async getSalesOrder (options = {}) {
      this.$set(this, 'salesorder', null);

      const salesorder = await new SalesOrder(this.$route.params.guid).get(options);

      this.$set(this, 'salesorder', salesorder);
      return this.salesorder;
    },
    async getCashRegister (guid, options = {}) {
      this.$set(this, 'cashRegister', null);

      const cashRegister = await new CashRegister(guid).get(options);
      await cashRegister.populate('get', options);

      this.$set(this, 'cashRegister', cashRegister);
      return this.cashRegister;
    }
  }
};
</script>
