<template>
  <!-- Loading -->
  <KContainer v-if="isMounted === false || cash_register === null">
    <div class="row mt-3">
      <div class="col-12 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
        <div class="loading-container">
          <div class="loading-block">
            <div class="blockui ml-auto mr-auto w-100">
              <span>Gegevens ophalen...</span>
              <span class="kt-spinner kt-spinner--v2 kt-spinner--primary" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </KContainer>

  <!-- Finished loading -->
  <div v-else>
    <KSubheader
      :breadcrumbs="[{
        page: 'Kassa',
        route: null
      }, {
        page: 'Kassa\'s',
        route: '/sales/cash_registers'
      }, {
        page: cash_register.name,
        route: null
      }, {
        page: 'Transacties',
        route: null
      }]"

      title="Transacties"
    />

    <KContainer>

      <KPortlet>
        <KPortletHead>
          <template v-slot:default>Overzicht</template>
          <template v-slot:toolbar>
            <KButton
              :icon="['fad', 'cash-register']"
              variant="outline-focus"
              @click.native.prevent="$router.push({
                name: '/sales/cash_registers/overview',
                params: {
                  redirect: {
                    name: `/sales/cash_registers/view/:cash_register/salesorders`
                  }
                }
              })"
            >
              Kassa's
            </KButton>
          </template>
        </KPortletHead>
        <KPortletBody>

          <div class="row">
            <div class="col-12">
              <div class="kt-input-icon kt-input-icon--left">
                <input
                  id="generalSearch"
                  type="text"
                  class="form-control"
                  placeholder="Search..."
                  @keyup.enter="$refs['datatable'].search"
                  @keyup="searchDebounced"
                >
                <span class="kt-input-icon__icon kt-input-icon__icon--left">
                  <span>
                    <i>
                      <font-awesome-icon :icon="['far', 'search']" />
                    </i>
                  </span>
                </span>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-12 datatable datatable-wrapper">
              <datatable
                ref="datatable"
                :url="datatable.url"
                :filters="datatable.filters"
                :load-on-start="datatable.loadOnStart"
                :fields="datatable.fields"
                :search-fields="datatable.searchFields"
                :on-pagination-data="onPaginationData"
                :transform="transform"
              >
                <!-- Customer -->
                <div
                  slot="customer"
                  slot-scope="props"
                >
                  <template v-if="props.rowData.customer">
                    <router-link
                      v-if="$auth.hasPermission('get_customers')"
                      :to="`/customers/view/${props.rowData.customer.guid}`"
                      tag="a"
                    >
                      {{ props.rowData.customer.name }}
                    </router-link>

                    <template v-else>{{ props.rowData.customer.name }}</template>
                  </template>

                  <template v-else>Geen klant</template>
                </div>

                <!-- Actions -->
                <div
                  slot="actions"
                  slot-scope="props"
                  class="datatable-actions"
                >
                  <router-link
                    v-if="$auth.hasPermission('get_salesorders')"
                    :to="`/sales/cash_registers/view/${cash_register.guid}/salesorders/${props.rowData._meta.guid}`"
                  >
                    <KButton
                      :icon="['fad', 'eye']"
                      tag="a"
                      variant="label-brand"
                    />
                  </router-link>

                </div>

              </datatable>

              <div class="row pagination">
                <div class="col-xs-12 col-md-6">
                  <datatable-pagination
                    ref="pagination"
                    @vuetable-pagination:change-page="onChangePage"
                  />
                </div>

                <div class="col-xs-12 col-md-6">
                  <datatable-pagination-info
                    ref="pagination.info"
                    @vuetable-paginationinfo:change-rowcount="onChangeRowCount"
                  />
                </div>
              </div>

            </div>
          </div>

        </KPortletBody>
      </KPortlet>

    </KContainer>
  </div>
</template>

<script>
// Libraries
import moment from 'moment-timezone';

// Classes
import CashRegister from '@/libs/classes/cash_register';
import SalesOrder from '@/libs/classes/salesorder';

// Mixins
import datatable from '@/mixins/datatable';

export default {
  mixins: [datatable],
  data () {
    return {
      isMounted: false,

      cash_register: null,

      datatable: {
        url: '/salesorders',
        loadOnStart: false,

        fields: [{
          title: 'Klant',
          name: '__slot:customer',
          sortField: 'customer.firstname',
          dataClass: 'w-2/8'
        }, {
          title: 'Bedrag',
          name: 'total_incl_vat',
          sortField: 'total_incl_vat',
          dataClass: 'w-1/8',
          callback: (value) => `&euro; ${this.$format.currency(value)}`
        }, {
          title: 'Datum',
          name: 'finished_timestamp',
          sortField: 'finished_timestamp',
          dataClass: 'w-2/8',
          callback: (value) => moment(value).format('LLL')
        }, {
          title: 'Gekochte producten',
          name: 'summary_lines',
          dataClass: 'w-2/8'
        }, {
          title: 'Betaalmethodes',
          name: 'summary_payment_methods',
          dataClass: 'w-2/8'
        }, {
          title: '',
          name: '__slot:actions',
          titleClass: 'text-right',
          dataClass: 'text-right'
        }],

        searchFields: ['customer.firstname', 'customer.lastname', 'total_incl_vat'],
        filters: {
          paid: true
        }
      }
    };
  },

  async mounted () {
    try {
      await this.getCashRegister();
    } catch (e) {
      console.error('Failed fetching cash register', e);
    }

    this.$nextTick(() => {
      this.isMounted = true;

      this.$nextTick(() => {
        this.datatable.filters.cash_register_guid = this.cash_register.guid;
        this.$refs.datatable.refresh();
      });
    });
  },

  methods: {
    transform (data) {
      if (data.data && Array.isArray(data.data)) {
        data.data.forEach((row, idx) => {
          data.data[idx] = new SalesOrder().mergeResponse(data.data[idx]);
        });
      }

      return data;
    },

    async getCashRegister (options = {}) {
      this.$set(this, 'cash_register', null);

      let cashRegister = await new CashRegister(this.$route.params.cash_register).get(options);
      await cashRegister.populate('get', options);

      this.$set(this, 'cash_register', cashRegister);
      return this.cashRegister;
    }
  }
};
</script>
