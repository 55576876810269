<template>
  <div :class="['row', {'border-top pt-3': firstRow === false}]">
    <div class="col-12 col-xl-11">
      <!-- Row of data -->
      <div class="row">
        <!-- Equipment groups -->
        <div class="col-12 col-xl-4">
          <div class="form-group">
            <label for="groups">Apparaat groepen</label>
            <div class="row">
              <div
                v-for="equipment_group in session.equipment_groups"
                :class="['col-12', {'col-lg-6': session.equipment_groups.length > 1}]"
                :key="`session.${session.guid}.equipment_group.${equipment_group.guid}`"
              >
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i><font-awesome-icon :icon="['fad', 'link']" /></i>
                    </span>
                  </div>
                  <input
                    :value="equipment_group.name"

                    type="text"
                    class="form-control"
                    disabled
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Days -->
        <div class="col-12 col-xl-4">
          <div class="form-group">
            <label for="groups">Beschikbare tijden</label>

            <!-- All days (except for sunday, because it starts with 0) -->
            <template v-for="day in 6">
              <session-line-day
                :ref="`session.${session.guid}.sessionLineDay.${day}`"
                :key="`session.${session.guid}.sessionLineDay.${day}`"
                :day="day"
                :session="session"
              />
            </template>

            <!-- Sunday -->
            <session-line-day
              :ref="`session.${session.guid}.sessionLineDay.0`"
              :key="`session.${session.guid}.sessionLineDay.0`"
              :day="0"
              :session="session"
            />
          </div>
        </div>

        <!-- Session configuration -->
        <div class="col-12 col-xl-4">
          <div class="row">
            <!-- Default session time -->
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label>{{ (session.session_time.flexible === true ? 'Standaard tijd' : 'Sessietijd') }}</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i><font-awesome-icon :icon="['fad', 'stopwatch']" /></i>
                    </span>
                  </div>
                  <input
                    :value="session.default_time_minutes"

                    type="number"
                    class="form-control"
                    disabled
                  >
                  <div class="input-group-append">
                    <span class="input-group-text">minuten</span>
                  </div>
                </div>
              </div>
            </div>

            <!-- Number of sessions -->
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label>Aantal sessies</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i><font-awesome-icon :icon="['fad', 'hashtag']" /></i>
                    </span>
                  </div>
                  <input
                    :value="(session.session_limit.unlimited === true ? 'Ongelimiteerd' : session.session_limit.limit)"

                    type="text"
                    class="form-control"
                    disabled
                  >
                </div>
              </div>
            </div>
          </div>

          <!-- Only show when time is flexible -->
          <div
            v-if="session.session_time.flexible === true"
            class="row mt-0"
          >
            <!-- Minimum session time -->
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label>Minimale tijd</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i><font-awesome-icon :icon="['fad', 'stopwatch']" /></i>
                    </span>
                  </div>
                  <input
                    :value="session.from_time_minutes"

                    type="number"
                    class="form-control"
                    disabled
                  >
                  <div class="input-group-append">
                    <span class="input-group-text">minuten</span>
                  </div>
                </div>
              </div>
            </div>

            <!-- Maximum session time -->
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label>Maximale tijd</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i><font-awesome-icon :icon="['fad', 'stopwatch']" /></i>
                    </span>
                  </div>
                  <input
                    :value="session.to_time_minutes"

                    type="number"
                    class="form-control"
                    disabled
                  >
                  <div class="input-group-append">
                    <span class="input-group-text">minuten</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <KSeparator class="mt-0 mb-3" />

          <div class="row">
            <!-- Interval -->
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label>Interval</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i><font-awesome-icon :icon="['fad', 'hourglass-end']" /></i>
                    </span>
                  </div>
                  <input
                    :value="session.session_limit.interval.value"

                    type="number"
                    class="form-control"
                    disabled
                  >
                  <div class="input-group-append">
                    <span class="input-group-text">{{ intervalText(session.session_limit.interval.type, session.session_limit.interval.value) }}</span>
                  </div>
                </div>
              </div>
            </div>

            <!-- Requires remark -->
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label>Opmerking verplicht</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i><font-awesome-icon :icon="['fad', 'hourglass']" /></i>
                    </span>
                  </div>
                  <input
                    :value="(session.requires_remark === true ? 'Ja' : 'Nee')"

                    type="text"
                    class="form-control"
                    disabled
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div
      v-if="(action !== 'VIEW')"
      class="d-none d-xl-inline-block col-xl-1"
    >
      <div class="row label-margin">
        <div class="col-6 p-0">
          <KButton
            :disabled="disabled"

            variant="light"
            icon="edit"
            class="btn-block w-100"

            @click.native.prevent="editSession(session)"
          />
        </div>

        <div class="col-6 p-0">
          <KButton
            :disabled="disabled"

            variant="light"
            icon="trash"
            class="btn-block w-100"

            @click.native.prevent="removeSession(session)"
          />
        </div>
      </div>
    </div>

  </div>
</template>

<script>
// Classes
import BundleProductSession from '@/libs/classes/bundle_product.session';

// Components
import sessionLineDay from '@/components/bundles/components/session.line.day';

export default {
  components: {
    sessionLineDay
  },
  props: {
    session: {
      type: BundleProductSession,
      default: null
    },
    firstRow: {
      type: Boolean,
      default: false
    },

    action: {
      type: String,
      default: 'CREATE'
    },
    disabled: {
      type: Boolean,
      default: false
    },

    editSession: {
      type: Function,
      default: () => {}
    },
    removeSession: {
      type: Function,
      default: () => {}
    }
  },
  methods: {
    intervalText (type = 'minutes', value = 0) {
      // Minutes
      if (type === 'minutes') {
        if (value === 1) return 'minuut';
        return 'minuten';
      }

      // Hours
      if (type === 'hours') {
        if (value === 1) return 'uur';
        return 'uren';
      }

      // Days
      if (type === 'days') {
        if (value === 1) return 'dag';
        return 'dagen';
      }

      // Weeks
      if (type === 'weeks') {
        if (value === 1) return 'week';
        return 'werken';
      }
    }
  }
};
</script>
