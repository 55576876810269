// Libraries
import { merge } from 'lodash';
import eventhub from '@/libs/eventhub';
import equipment from '@/libs/equipment';
import stores from '@/libs/stores';

// Classes
import CashRegister from '@/libs/classes/cash_register';
import SalesOrder from '@/libs/classes/salesorder';
import Customer from '@/libs/classes/customer';

export default class shop {
  // Basic session
  static get sessionName () {
    return 'cs.shop.state';
  }

  static getSession () {
    let session = window.sessionStorage.getItem(shop.sessionName);

    try {
      session = JSON.parse(session);
    } catch (e) {
      session = null;
    }

    return session;
  }

  static removeSession () {
    shop.removeCashRegister();
    shop.removeSalesOrder();
    window.sessionStorage.removeItem(shop.sessionName);
  }

  // Cash register
  static async getCashRegister (options = {}) {
    let session = this.getSession();
    if (session === null) return null;
    if (typeof session.cash_register !== 'string') return null;

    let cashRegister = new CashRegister(session.cash_register);
    try {
      await cashRegister.get(options);
      await cashRegister.populate('get', options);
      shop.cash_register = cashRegister;
      eventhub.emit('shop:cash_register:updated', shop.cash_register);
    } catch (e) {
      console.error('Failed fetching cash register', e);
      shop.removeSession();
      return null;
    }

    return cashRegister;
  }

  static setCashRegister (guid = null) {
    window.sessionStorage.setItem(shop.sessionName, JSON.stringify({
      store: stores.activeStore._meta.guid,
      cash_register: guid
    }));

    return shop.getSession();
  }

  static removeCashRegister () {
    shop.cash_register = undefined;
    eventhub.emit('shop:cash_register:updated', shop.cash_register);

    const session = this.getSession();
    if (session !== null) {
      delete session.cash_register;
      window.sessionStorage.setItem(shop.sessionName, JSON.stringify(session));
    }
  }

  // Sales order line
  static async getSalesOrder (options = {}) {
    if (!shop.sales_order || !shop.sales_order.guid) {
      await shop.createSalesOrder(options);
    }

    shop.sales_order = await shop.sales_order.get(options);
    await shop.sales_order.populate('get', options);

    // Get current customer, if necessary
    if (typeof shop.sales_order.customer_guid === 'string' && shop.sales_order.customer_guid.length) {
      const customer = await new Customer(shop.sales_order.customer_guid).get(options);
      await customer.populate('get', options);
      shop.sales_order.customer = customer;
    }

    // Notify shop components
    eventhub.emit('shop:cart:updated', shop.sales_order);

    return shop.sales_order;
  }

  // Sales order
  static async createSalesOrder (options = {}) {
    if (!shop.cash_register) {
      await shop.getCashRegister();
    }

    let salesOrder = new SalesOrder();
    shop.sales_order = await salesOrder.create({
      cash_register_guid: shop.cash_register.guid,
      cash_register_opening_guid: shop.cash_register.latest_opening.guid
    }, options);

    // Save order
    shop.setSalesOrder(shop.sales_order.guid);

    // Notify shop components
    eventhub.emit('shop:cart:updated', shop.sales_order);

    return shop.sales_order;
  }

  static async clearSalesOrder (options = {}) {
    if (!shop.sales_order || !shop.sales_order._meta) {
      throw new Error('No sales order active');
    }

    // Clear sales order lines
    await shop.sales_order.clearSalesOrderLines(options);

    // Clear transactions
    await shop.sales_order.clearTransactions(options);

    // Notify shop components
    eventhub.emit('shop:cart:updated', shop.sales_order);

    return shop.sales_order;
  }

  static async deleteSalesOrder (options = {}) {
    if (!shop.sales_order || !shop.sales_order._meta) {
      throw new Error('No sales order active');
    }

    // Remove sales order
    await shop.sales_order.remove(options);

    // Remove sales order from session
    shop.removeSalesOrder();

    return shop.sales_order;
  }

  static setSalesOrder (guid = null) {
    const session = this.getSession();
    session.sales_order = guid;

    window.sessionStorage.setItem(shop.sessionName, JSON.stringify(session));

    return this.getSession();
  }

  static removeSalesOrder () {
    shop.sales_order = undefined;
    eventhub.emit('shop:cart:updated', shop.sales_order);

    const session = this.getSession();
    if (session !== null) {
      delete session.sales_order;
      window.sessionStorage.setItem(shop.sessionName, JSON.stringify(session));
    }
  }

  static async finishSalesOrder (options = {}) {
    if (!shop.sales_order || !shop.sales_order._meta) {
      throw new Error('No sales order active');
    }

    // Get latest opening
    if (!shop.cash_register) {
      await this.getCashRegister(options);
    }
    const opening = await this.cash_register.getLatestOpening(options);

    // Finish the sales order
    await shop.sales_order.finish(Object.assign(options, {
      body: {
        cash_register_guid: this.cash_register.guid,
        cash_register_opening_guid: opening.guid
      }
    }));

    // Get the sales order again
    let salesOrder = await shop.getSalesOrder({
      query: {
        disable_cache: true
      }
    });

    // Check if there are any sessions that have to be started
    if (salesOrder && salesOrder.sessions && salesOrder.sessions.length > 0) {
      // Group all sessions to master_guid
      let masters = {};
      salesOrder.sessions.forEach((session) => {
        if (session.guid && session.master_guid) {
          if (typeof masters[session.master_guid] === typeof undefined) masters[session.master_guid] = [];
          masters[session.master_guid].push(session.guid);
        }
      });

      // Loop through masters object to send start session message
      Object.keys(masters).forEach((masterGuid) => {
        let master = equipment.masters.find(m => m.guid === masterGuid);
        if (master.socket) {
          master.socket.device('slaves:sessions:queue:add', {
            sessions: (masters[masterGuid] || [])
          });
        }
      });
    }

    return shop.sales_order;
  }

  // Products
  static async addProductToCart (product, options = {}) {
    if (!shop.sales_order || !shop.sales_order._meta) {
      await shop.createSalesOrder(options);
    }

    // Add line to sales order
    const line = await shop.sales_order.addSalesOrderLine(product, options);

    // Get the new sales order
    await shop.getSalesOrder(merge({
      query: {
        disable_cache: true
      }
    }, options));

    return line;
  }

  static async removeProductFromCart (guid, options = {}) {
    if (!shop.sales_order || !shop.sales_order._meta) {
      throw new Error('No sales order active');
    }

    // Remove line from sales order
    await shop.sales_order.removeSalesOrderLine(guid, options);

    // Get the new sales order
    await shop.getSalesOrder(merge({
      query: {
        disable_cache: true
      }
    }, options));

    return shop.sales_order;
  }

  // Customer
  static async setCustomer (guid, options = {}) {
    if (!shop.sales_order || !shop.sales_order._meta) {
      await shop.createSalesOrder(options);
    }

    // Add customer to sales order
    await shop.sales_order.setCustomer(guid, options);

    // Get the new sales order
    await shop.getSalesOrder(options);

    return shop.sales_order;
  }

  static async removeCustomer (options = {}) {
    if (!shop.sales_order || !shop.sales_order._meta) {
      throw new Error('No sales order active');
    }

    // Add customer to sales order
    await shop.setCustomer(null, options);

    return shop.sales_order;
  }

  // Transactions
  static async addTransaction (paymentMethod, value, data = {}, options = {}) {
    if (!shop.sales_order || !shop.sales_order._meta) {
      throw new Error('No sales order active');
    }

    // Get latest opening of cash register
    await shop.cash_register.getLatestOpening();

    // Create transaction
    const transaction = Object.assign({
      payment_method_type: paymentMethod,
      amount: value,
      salesorder_guid: this.sales_order.guid,
      cash_register_guid: this.cash_register.guid,
      cash_register_opening_guid: this.cash_register.latest_opening.guid,
      customer_guid: this.sales_order.customer_guid
    }, data);

    // Add transaction
    await this.sales_order.addTransaction(transaction, options);

    // Get the new transactions
    await this.sales_order.getTransactions({
      query: {
        disable_cache: true
      }
    });

    // Notify shop components
    eventhub.emit('shop:cart:updated', shop.sales_order);

    return shop.sales_order;
  }

  static async removeTransaction (guid, options = {}) {
    if (!shop.sales_order || !shop.sales_order._meta) {
      throw new Error('No sales order active');
    }

    // Remove transaction
    await shop.sales_order.removeTransaction(guid, options);

    // Get the new sales order
    await shop.getSalesOrder({
      query: {
        disable_cache: true
      }
    });

    return shop.sales_order;
  }

  static async clearTransactions (options = {}) {
    if (!shop.sales_order || !shop.sales_order._meta) {
      throw new Error('No sales order active');
    }

    // Clear transactions
    await shop.sales_order.clearTransactions();

    // Notify shop components
    eventhub.emit('shop:cart:updated', shop.sales_order);

    return shop.sales_order;
  }

  static async setRemark (remark = null, options = {}) {
    if (!shop.sales_order || !shop.sales_order._meta) {
      throw new Error('No sales order active');
    }

    // Update salesorder
    await this.sales_order.update([{
      action: 'set_field',
      field: 'remark',
      value: remark
    }]);

    // Notify shop components
    eventhub.emit('shop:cart:updated', shop.sales_order);

    return shop.sales_order;
  }
};
