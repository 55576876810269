<template>
  <KPortlet>
    <KPortletHead>
      <template v-slot:default>Mutaties</template>
    </KPortletHead>
    <KPortletBody>

      <div class="row">
        <div class="col-12 datatable datatable-wrapper">
          <datatable ref="datatable"
            :url="datatable.url"
            :filters="datatable.filters"
            :loadOnStart="datatable.loadOnStart"
            :fields="datatable.fields"
            :onPaginationData="onPaginationData"
            :transform="transform"
          >
            <div class="datatable-actions" slot="actions" slot-scope="props">
              <router-link :to="`/products/view/${props.rowData.product._meta.guid}/mutations`">
                <KButton tag="a"
                  variant="label-brand"
                  :icon="['fad', 'eye']"
                ></KButton>
              </router-link>
            </div>
          </datatable>

          <div class="row pagination">
            <div class="col-xs-12 col-md-6">
              <datatable-pagination ref="pagination"
                v-on:vuetable-pagination:change-page="onChangePage"
              ></datatable-pagination>
            </div>

            <div class="col-xs-12 col-md-6">
              <datatable-pagination-info ref="pagination.info"
              v-on:vuetable-paginationinfo:change-rowcount="onChangeRowCount"
              ></datatable-pagination-info>
            </div>
          </div>

        </div>
      </div>

    </KPortletBody>
  </KPortlet>
</template>

<script>
// Classes
import StockBatch from '@/libs/classes/stock_batch';
import StockMutation from '@/libs/classes/stock_mutation';

// Mixins
import datatable from "@/mixins/datatable";

export default {
  mixins: [datatable],
  props: {
    stock_batch: StockBatch
  },
  data () {
    return {
      datatable: {
        url: null,
        loadOnStart: false,

        fields: [{
          title: 'Beschrijving',
          name: 'product.name',
          sortField: 'product.name',
          dataClass: 'w-5/8'
        }, {
          title: 'Oude voorraad',
          name: 'previous_stock',
          sortField: 'previous_stock',
          titleClass: 'text-center',
          dataClass: 'w-1/8 text-center'
        }, {
          title: 'Mutatie',
          name: 'quantity_mutation',
          sortField: 'quantity_mutation',
          titleClass: 'text-center',
          dataClass: 'w-1/8 text-center',
          callback: (value) => `${value < 0 ? '-' : '+'}${value}`
        }, {
          title: 'Nieuwe voorraad',
          name: '',
          titleClass: 'text-center',
          dataClass: 'w-1/8 text-center',
          callback: (mutation) => `${mutation.previous_stock + mutation.quantity_mutation}`
        }, {
          title: '',
          name: '__slot:actions',
          titleClass: 'text-right',
          dataClass: 'text-right'
        }],

        filters: {
          stock_batch_guid: ''
        }
      }
    }
  },

  mounted () {
    this.$set(this.datatable.filters, 'stock_batch_guid', this.stock_batch.guid);
    this.$set(this.datatable, 'url', `/stock_mutations`)
  },

  methods: {
    transform (data) {
      if (data.data && Array.isArray(data.data)) {
        data.data = data.data.map(r => new StockMutation().mergeResponse(r), []);
      }

      return data;
    }
  }
}
</script>
