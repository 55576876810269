<template>
  <KPortlet>
    <KPortletHead>
      <template v-slot:default>Klant</template>
      <template
        v-slot:toolbar
        v-if="canRemoveCustomer"
      >
        <i class="cursor-pointer">
          <font-awesome-icon
            :icon="['fad', 'times']"
            size="2x"
            @click.prevent="removeCustomer()"
          />
        </i>
      </template>
    </KPortletHead>
    <KPortletBody>

      <template v-if="isCustomerKnown && salesOrder.customer && salesOrder.customer.balance">
        <div class="kt-widget kt-widget--general-1">
          <div class="kt-media kt-media--brand kt-media--lg kt-media--circle">
            <span class="kt-badge kt-badge--username kt-badge--xl kt-badge--dark kt-badge--bold">{{ salesOrder.customer.initials }}</span>
          </div>
          <div class="kt-widget__wrapper">
            <div class="kt-widget__label">
              <div class="row">
                <div class="col mb-auto mt-auto">
                  <router-link
                    :to="`/customers/view/${salesOrder.customer.guid}`"
                    class="kt-widget__title"
                  >
                    {{ salesOrder.customer.name }}
                  </router-link>
                </div>
                <div class="col-2 text-right mb-auto mt-auto">
                  <i class="kt-font-focus">
                    <font-awesome-icon
                      v-if="salesOrder.customer.authenticated === true"
                      :icon="['fad', 'lock-open']"
                    />
                    <font-awesome-icon
                      v-else
                      :icon="['fad', 'lock']"
                      class="cursor-pointer"
                      @click.prevent="$eventhub.emit('modals:shop:identify-customer:open', {method: 'IDENTIFY'})"
                    />
                  </i>
                </div>
              </div>
              <span class="kt-widget__desc">
                <div class="row">
                  <div class="col-8">Sessiekrediet</div>
                  <div class="col-4 d-flex flex-row justify-content-between">
                    <div class="text-left">&euro;</div>
                    <div class="text-right">{{ $format.currency(salesOrder.customer.balance.session_credits) }}</div>
                  </div>
                </div>

                <div
                  :class="['row', {'kt-font-danger font-weight-bold': salesOrder.customer.balance.on_account < 0, 'cursor-pointer': (salesOrder.customer.balance.on_account < 0 && (meta && meta.shop && meta.shop.action === 'CART'))}]"
                  @click.prevent="onClickOnAccountBalance"
                >
                  <div class="col-8">Op rekening</div>
                  <div class="col-4 d-flex flex-row justify-content-between">
                    <div class="text-left">&euro;</div>
                    <div class="text-right">{{ $format.currency(salesOrder.customer.balance.on_account * -1) }}</div>
                  </div>
                </div>
              </span>
            </div>
          </div>
        </div>
      </template>

      <template v-else>
        <div class="row">
          <div class="col-4 text-center">
            <div
              class="cs-shop-icon-block"
              @click.prevent="$eventhub.emit('modals:shop:identify-customer:open', {method: 'SEARCH'})"
            >
              <div class="icon">
                <i><font-awesome-icon :icon="['fad', 'search']" /></i>
              </div>

              <div class="text">
                Zoeken
              </div>
            </div>
          </div>
          <div class="col-4 text-center">
            <div
              class="cs-shop-icon-block"
              @click.prevent="$eventhub.emit('modals:shop:identify-customer:open', {method: 'IDENTIFY'})"
            >
              <div class="icon">
                <i class="position-relative">
                  <font-awesome-icon :icon="['fad', 'fingerprint']" />
                </i>
              </div>

              <div class="text">
                Identificeren
              </div>
            </div>
          </div>
          <div class="col-4 text-center">
            <div
              class="cs-shop-icon-block"
              @click.prevent="$router.push({
                name: '/customers/create',
                params: {
                  redirect: $route.path
                }
              })"
            >
              <div class="icon">
                <i><font-awesome-icon :icon="['fad', 'user-plus']" /></i>
              </div>

              <div class="text">
                Toevoegen
              </div>
            </div>
          </div>
        </div>
      </template>

    </KPortletBody>
  </KPortlet>
</template>

<script>
// Mixins
import shop from '@/mixins/shop';

// Classes
import Customer from '@/libs/classes/customer';
import SalesOrder from '@/libs/classes/salesorder';

export default {
  mixins: [shop],
  props: {
    meta: {
      type: Object,
      default: null
    },
    salesOrder: {
      type: SalesOrder,
      default: null
    }
  },
  computed: {
    isCustomerKnown () {
      if (this.salesOrder && this.salesOrder !== null && this.salesOrder instanceof SalesOrder) {
        if (this.salesOrder.customer && this.salesOrder.customer !== null && this.salesOrder.customer instanceof Customer) {
          return true;
        }
      }

      return false;
    },

    canRemoveCustomer () {
      if (this.salesOrder && typeof this.salesOrder.customer === 'object' && this.salesOrder.customer !== null) {
        if (this.meta && this.meta.shop) {
          // Always allowed to remove customer when the shop action is CART
          if (this.meta.shop.action === 'CART') {
            return true;
          }

          // Only allowed to remove customer when the shop action is PAYMENTS and there are no items in the cart that requires a customer
          if (this.meta.shop.action === 'PAYMENTS' && this.salesOrder.lines.some(l => l.requires_customer === true) === false) {
            return true;
          }
        }
      }
      return false;
    }
  },
  methods: {
    async removeCustomer (options = {}) {
      await this.shop.removeCustomer(options);
    },

    onClickOnAccountBalance () {
      if (this.salesOrder && this.salesOrder.customer && this.salesOrder.customer.balance && this.salesOrder.customer.balance.on_account < 0) {
        if (this.meta && this.meta.shop && this.meta.shop.action === 'CART') {
          this.$eventhub.emit('modals:shop:repay-on-account:open');
        }
      }
    }
  }
};
</script>
