<template>
  <KPortlet>
    <KPortletHead>
      Transacties
    </KPortletHead>
    <KPortletBody class="kt-portlet__body--fit">
      <div class="row">
        <div class="col-12 datatable datatable-wrapper">
          <datatable
            ref="datatable"
            :url="datatable.url"
            :css="datatable.css"
            :load-on-start="datatable.loadOnStart"
            :fields="datatable.fields"
            :search-fields="datatable.searchFields"
            :on-pagination-data="onPaginationData"
            :filters="datatable.filters"
            :transform="transform"
          >
            <div
              slot="actions"
              slot-scope="props"
              class="datatable-actions"
            >

              <router-link
                v-if="$auth.hasPermission('get_salesorders')"
                :to="`/cash_registers/salesorders/view/${props.rowData._meta.guid}`"
              >
                <KButton
                  :icon="['fad', 'eye']"
                  tag="a"
                  variant="label-brand"
                />
              </router-link>
            </div>
          </datatable>

          <div class="row pagination pt-0 pb-3">
            <div class="col-xs-12 col-md-6">
              <datatable-pagination
                ref="pagination"
                @vuetable-pagination:change-page="onChangePage"
              />
            </div>

            <div class="col-xs-12 col-md-6">
              <datatable-pagination-info
                ref="pagination.info"
                @vuetable-paginationinfo:change-rowcount="onChangeRowCount"
              />
            </div>
          </div>
        </div>
      </div>
    </KPortletBody>
  </KPortlet>
</template>

<script>
// Libraries
import moment from 'moment-timezone';

// Classes
import SalesOrder from '@/libs/classes/salesorder';
import CashRegisterOpening from '@/libs/classes/cash_register.opening';

// Mixins
import datatable from '@/mixins/datatable';

export default {
  mixins: [datatable],
  props: {
    cashRegisterOpening: {
      type: CashRegisterOpening,
      default: null
    }
  },
  data () {
    return {
      datatable: {
        url: '/salesorders',
        css: {
          tableClass: 'table thead-light thead-light--fit'
        },

        fields: [{
          title: 'Klant',
          name: 'customer',
          sortField: 'customer.firstname',
          dataClass: 'w-2/8',
          callback: (customer) => (customer && customer.name ? customer.name : 'Geen klant')
        }, {
          title: 'Bedrag',
          name: 'total_incl_vat',
          sortField: 'total_incl_vat',
          dataClass: 'w-1/8',
          callback: (value) => `&euro; ${this.$format.currency(value)}`
        }, {
          title: 'Datum',
          name: '_meta.created',
          sortField: '_meta.created',
          dataClass: 'w-2/8',
          callback: (value) => moment(value).format('LLL')
        }, {
          title: 'Gekochte producten',
          name: 'summary_lines',
          dataClass: 'w-2/8'
        }, {
          title: 'Betaalmethodes',
          name: 'summary_payment_methods',
          dataClass: 'w-2/8'
        }, {
          title: '',
          name: '__slot:actions',
          titleClass: 'text-right',
          dataClass: 'text-right'
        }],

        searchFields: ['customer.firstname', 'customer.lastname', 'total_incl_vat'],
        filters: {
          cash_register_opening_guid: this.cashRegisterOpening.guid,
          paid: true
        }
      }
    };
  },

  methods: {
    transform (data) {
      if (data.data && Array.isArray(data.data)) {
        data.data.forEach((row, idx) => {
          data.data[idx] = new SalesOrder().mergeResponse(data.data[idx]);
        });
      }

      return data;
    }
  }
};
</script>
