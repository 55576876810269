<template>
  <div>
    <KSubheader
      :breadcrumbs="[{
        page: 'Apparatuur',
        route: '/equipment'
      }, {
        page: 'Tarieven',
        route: '/equipment/pricelists'
      }, {
        page: 'Tarievenlijst aanmaken',
        route: null
      }]"

      title="Tarieven"
    />
    <KContainer>
      <KPortlet>

        <KPortletBody class="kt-portlet__body-fit">
          <KWizard
            ref="wizard"
            :clickable="false"
          >
            <KWizardStep
              title="Informatie"
            >

              <informationForm
                ref="informationForm"

                :pricelist="pricelist"

                :VATCodes="meta.VATCodes"

                action="CREATE"
              />

            </KWizardStep>

            <KWizardStep
              title="Tarieven"
            >

              <pricesForm
                ref="pricesForm"

                :pricelist="pricelist"

                :VATCodes="meta.VATCodes"

                action="CREATE"
              />

            </KWizardStep>
            <KWizardStep
              title="Apparatuur"
            >

              <equipment-form
                ref="equipmentForm"

                :pricelist="pricelist"

                :equipment="meta.equipment"

                action="CREATE"
              />

            </KWizardStep>
          </KWizard>
        </KPortletBody>
        <div class="kt-portlet__foot">
          <div class="row align-items-center">
            <div class="col-6 col-lg-2">
              <KButton
                v-if="(isMounted && $refs.wizard && $refs.wizard.currentStep !== 0)"
                variant="outline-focus"
                class="btn-md btn-block btn-tall btn-wide btn-bold btn-upper"
                @click.native.prevent="previousStep"
              >
                Vorige
              </KButton>
            </div>
            <div class="col-6 offset-lg-8 col-lg-2 kt-align-right">
              <KButton
                v-if="(isMounted && $refs.wizard && $refs.wizard.currentStep !== ($refs.wizard.steps.length - 1))"
                variant="focus"
                class="btn-md btn-block btn-tall btn-wide btn-bold btn-upper"
                @click.native.prevent="nextStep"
              >
                Volgende
              </KButton>
              <KButton
                v-if="(isMounted && $refs.wizard && $refs.wizard.currentStep === ($refs.wizard.steps.length - 1))"
                variant="success"
                class="btn-md btn-block btn-tall btn-wide btn-bold btn-upper"
                @click.native.prevent="submitForm"
              >
                Aanmaken
              </KButton>
            </div>
          </div>
        </div>
      </KPortlet>
    </KContainer>

  </div>
</template>

<script>
// Import CSS for KWizard
import '@/assets/sass/caesium.wizard.scss';

// Libraries
import { cloneDeep } from 'lodash';

// Classes
import SessionPricelist from '@/libs/classes/session_pricelist';
import Equipment from '@/libs/classes/equipment';

// Components
import informationForm from '@/components/products/pricelists/components/information.form';
import pricesForm from '@/components/products/pricelists/components/prices.form';
import equipmentForm from '@/components/equipment/pricelists/components/equipment.form';

export default {
  components: {
    informationForm,
    pricesForm,
    equipmentForm
  },
  data () {
    return {
      isMounted: false,

      pricelist: new SessionPricelist().mergeResponse({
        active: true,

        vat_code: 'vat_nl_high'
      }),

      meta: {
        VATCodes: [],
        equipment: []
      }
    };
  },
  watch: {
    'pricelist.vat_code' (newVal, oldVal) {
      if (newVal !== null) {
        let VATCode = this.meta.VATCodes.find(c => c.code === newVal);
        if (typeof VATCode !== typeof undefined && VATCode !== null) {
          const prices = [];
          this.pricelist.prices.forEach((row, idx) => {
            row.product.price.vat_percentage = VATCode.value;
            row.credit_price = row.price_incl_vat;

            prices.push(row);
          });
          this.$set(this.pricelist, 'prices', prices);
        }
      }
    }
  },
  async mounted () {
    try {
      await Promise.all([this.getVATCodes(), this.getEquipment()]);
    } catch (e) {
      console.error('Failed catching all data', e);
      return;
    }

    this.$nextTick(() => {
      this.isMounted = true;
    });
  },
  methods: {
    async previousStep () {
      if (this.$refs.wizard) {
        return this.$refs.wizard.previousStep();
      }
    },
    async nextStep () {
      if (this.$refs.wizard) {
        if (await this.validate() === true) this.$refs.wizard.nextStep();
      }
    },

    async getVATCodes () {
      this.$set(this.meta, 'VATCodes', []);
      let VATCodes = await this.$ws.get('v1', '/vats');
      this.$set(this.meta, 'VATCodes', VATCodes);
      return this.meta.VATCodes;
    },
    async getEquipment () {
      this.$set(this.meta, 'equipment', []);
      let equipment = await this.$ws.get('v1', '/equipments');
      this.$set(this.meta, 'equipment', equipment.map(eq => new Equipment().mergeResponse(eq), []));
      return this.meta.equipment;
    },

    async validate () {
      const validationArray = [];

      // Information
      if (this.$refs.wizard.currentStep === 0) {
        validationArray.push(this.$refs.informationForm.$v);
      }

      // Prices
      if (this.$refs.wizard.currentStep === 1) {
        validationArray.push(this.$refs.pricesForm.$v);

        if (await this.$refs.pricesForm.validate() === false) return false;
      }

      // Execute validations
      if (validationArray.length > 0) {
        validationArray.forEach(v => v && v.$touch && v.$touch());
        if (validationArray.filter(v => v && v.$invalid).length !== 0) return false;
      }

      return true;
    },
    async submitForm () {
      if (await this.validate() === true) {
        // Clone pricelist, because it has to populate first for the Frontend
        let pricelist = cloneDeep(this.pricelist);
        await pricelist.create(pricelist.formatBody());
        this.$router.push(`/equipment/pricelists/view/${pricelist.guid}`);
      }
    }
  }
};
</script>
