<template>
  <!-- Settings -->
  <KPortlet>
    <KPortletHead>
      <template v-slot:default>Instellingen</template>
    </KPortletHead>
    <KPortletBody>
      <information-form
        ref="informationForm"

        :bundle-product="bundleProductClone"
        :disabled="isLoading"

        :action="($auth.hasPermission([['update_bundle_products']]) === true ? 'EDIT' : 'VIEW')"
      />
    </KPortletBody>
    <div
      v-if="$auth.hasPermission([['update_bundle_products']]) === true"
      class="kt-portlet__foot"
    >
      <div class="row align-items-center">
        <div class="col-6 offset-lg-9 col-lg-3 kt-align-right">
          <KButton
            :disabled="isLoading"

            variant="warning"
            class="btn-md btn-block btn-tall btn-wide btn-bold btn-upper"

            @click.native.prevent="submitForm"
          >
            Wijzigen
          </KButton>
        </div>
      </div>
    </div>
  </KPortlet>
</template>

<script>
// Libraries
import { cloneDeep } from 'lodash';
import mutationsLib from '@/libs/mutations';

// Classes
import BundleProduct from '@/libs/classes/bundle_product';

// Components
import informationForm from '@/components/bundles/components/information.form';

export default {
  components: {
    informationForm
  },
  props: {
    bundleProduct: {
      type: BundleProduct,
      default: null
    }
  },
  data () {
    return {
      isMounted: false,
      isLoading: false,

      bundleProductClone: cloneDeep(this.bundleProduct)
    };
  },

  async mounted () {
    this.$nextTick(() => {
      this.isMounted = true;
    });
  },

  methods: {
    async validate () {
      const validationArray = [];

      if (validationArray.length > 0) {
        validationArray.forEach(v => v && v.$touch && v.$touch());
        if (validationArray.filter(v => v && v.$invalid).length !== 0) return false;
      }

      // Validate form
      if (await this.$refs.informationForm.validate() === false) return false;

      return true;
    },
    async submitForm () {
      if (await this.validate() === true) {
        const fields = ['name', 'active', 'bundle_limit', 'article_number', 'EAN_code', 'minimum_permission_group_level', 'cost_price', 'price.price', 'price.vat_code', 'amount_changeable', 'requires_customer'];
        const mutations = mutationsLib.create(fields, this.bundleProduct.formatBody(), this.bundleProductClone.formatBody());
        if (mutations.length) {
          this.isLoading = true;

          try {
            await this.bundleProduct.update(mutations);
            await this.bundleProduct.populate('update');
            this.bundleProductClone = cloneDeep(this.bundleProduct);
          } catch (e) {
            this.$error.showModal(e);
          }

          this.isLoading = false;
        }
      }
    }
  }
};
</script>
