<template>
  <div>
    <KSubheader
      :breadcrumbs="[{
        page: 'Statistieken',
        route: '/statistics'
      }, {
        page: 'BTW overzicht',
        route: '/statistics/vat_overview'
      }]"

      title="BTW overzicht"
    />

    <KContainer>
      <!-- Portlet bar -->
      <KPortlet>
        <KPortletHead>
          <template v-slot:default>
            {{ $stores.activeStore.name }}
          </template>
          <template v-slot:toolbar>
            <KButton
              variant="secondary"
              tag="span"
              class="mr-2 d-print-none"

              @click.native.prevent="printPage"
            >
              <i class="kt-font-focus pr-0">
                <font-awesome-icon :icon="['fas', 'print']" />
              </i>
            </KButton>

            <KButtonGroup>
              <KButton
                variant="secondary"
                class="d-print-none"
                @click.native.prevent="setYear(year - 1)"
              >
                <i class="pr-0">
                  <font-awesome-icon :icon="['fas', 'chevron-left']" />
                </i>
              </KButton>

              <KButton
                variant="secondary"
                style="width:150px; font-weight:bold;"
                disabled
              >
                {{ year }}
              </KButton>

              <KButton
                variant="secondary"
                class="d-print-none"
                @click.native.prevent="setYear(year + 1)"
              >
                <i class="pr-0">
                  <font-awesome-icon :icon="['fas', 'chevron-right']" />
                </i>
              </KButton>
            </KButtonGroup>
          </template>
        </KPortletHead>
      </KPortlet>

      <!-- Data -->
      <div class="row">
        <!-- Monthly -->
        <div class="col-12">
          <monthly-portlet
            ref="monthlyPortlet"

            :year="year"
            :vat="vat"
          />
        </div>

        <!-- Quarterly -->
        <div class="col-12">
          <quarterly-portlet
            ref="quarterlyPortlet"

            :year="year"
            :vat="vat"
          />
        </div>
      </div>
    </KContainer>
  </div>
</template>

<script>
// Libraries
import moment from 'moment-timezone';

// Components
import monthlyPortlet from '@/components/statistics/vat_overview/monthly.portlet';
import quarterlyPortlet from '@/components/statistics/vat_overview/quarterly.portlet';

export default {
  components: {
    monthlyPortlet,
    quarterlyPortlet
  },
  data () {
    return {
      year: moment().year(),
      vat: null
    };
  },

  mounted () {
    this.setYear(Number(this.$route.query.year) || this.year);
  },

  methods: {
    setYear (year = null) {
      year = Number(year);
      if (year && isNaN(year) === false && moment(year, 'YYYY', true).isValid() && year <= moment().year()) {
        this.$set(this, 'year', year);

        // Set query
        if (Number(this.$route.query.year) !== year) {
          this.$router.replace({
            query: {
              year: year
            }
          });
        }

        this.getVAT(this.year);
      }
    },

    async getVAT (year = null) {
      // Set loading to refs
      if (this.$refs.monthlyPortlet) this.$refs.monthlyPortlet.isLoading = true;
      if (this.$refs.quarterlyPortlet) this.$refs.quarterlyPortlet.isLoading = true;

      // Set from and to dates
      const yearObject = {
        from: moment(year, 'YYYY', true).startOf('year'),
        to: moment(year, 'YYYY', true).endOf('year')
      };

      // Load VAT statistics
      const [monthly, quarterly] = await Promise.all([
        this.$ws.get('v1', `/stats/vat/${yearObject.from.valueOf()}/${yearObject.to.valueOf()}/month`),
        this.$ws.get('v1', `/stats/vat/${yearObject.from.valueOf()}/${yearObject.to.valueOf()}/quarter`)
      ]);

      // Set vat data
      this.vat = {
        monthly: monthly,
        quarterly: quarterly
      };

      // Set loading to refs
      if (this.$refs.monthlyPortlet) this.$refs.monthlyPortlet.isLoading = false;
      if (this.$refs.quarterlyPortlet) this.$refs.quarterlyPortlet.isLoading = false;

      return this.vat;
    },

    printPage () {
      window.print();
    }
  }
};
</script>
