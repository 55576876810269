<template>
  <KPortlet>
    <KPortletHead>
      Persoonlijke PIN code
    </KPortletHead>
    <KPortletBody>
      <authentication-pincode
        ref="authenticationPincode"

        :resource="userClone"

        :disabled="isLoading"
      />
    </KPortletBody>
    <div class="kt-portlet__foot">
      <div class="row align-items-center">
        <div class="offset-6 col-6 offset-lg-10 col-lg-2 kt-align-right">
          <KButton
            :disabled="isLoading"
            variant="warning"
            class="btn-md btn-block btn-tall btn-wide btn-bold btn-upper"
            @click.native.prevent="submitForm"
          >
            Wijzigen
          </KButton>
        </div>
      </div>
    </div>
  </KPortlet>
</template>

<script>
// Libraries
import { merge, cloneDeep } from 'lodash';
import mutationsLib from '@/libs/mutations';

// Classes
import User from '@/libs/classes/user';

// Components
import authenticationPincode from '@/components/authentication/pincode';

export default {
  components: {
    authenticationPincode
  },
  props: {
    user: {
      type: User,
      default: null
    }
  },
  data () {
    const userClone = cloneDeep(this.user);

    // Add pincode to cloned user
    userClone.pincode = null;

    return {
      userClone: userClone,

      isLoading: false
    };
  },

  methods: {
    async validate () {
      const validationArray = [];
      validationArray.push(this.$refs.authenticationPincode.$v);

      if (validationArray.length > 0) {
        validationArray.forEach(v => v && v.$touch && v.$touch());
        if (validationArray.filter(v => v && v.$invalid).length !== 0) return false;
      }

      return true;
    },

    async submitForm () {
      if (await this.validate() === true) {
        const fields = ['pincode'];
        const mutations = mutationsLib.create(fields, {}, this.userClone.formatBody());

        if (mutations.length) {
          this.$set(this, 'isLoading', true);

          try {
            await this.user.update(mutations);
            this.$set(this, 'userClone', merge(cloneDeep(this.user), {
              pincode: null
            }));
          } catch (e) {
            console.error(e);
          }

          this.$set(this, 'isLoading', false);

          // Reset pincode
          this.userClone.pincode = null;
          this.$refs['authenticationPincode'].pincode_confirm = null;
          this.$refs['authenticationPincode'].$v.$reset();
        }
      }
    }
  }
};
</script>
