import Abstract from './abstract';

import { merge } from 'lodash';
import format from '@/libs/format';
import calculate from '@/libs/calculate';

import ProductPrice from './product.price';

class Product extends Abstract {
  constructor (guid = null) {
    super(guid);
    this.uri = '/products';

    let defaultObject = {
      name: null,
      group_guid: null,
      vat_code: null,

      cost_price: null,
      stock: null,

      article_number: null,
      EAN_code: null,

      amount_changeable: false,
      requires_customer: false,

      minimum_permission_group_level: 0,

      price: new ProductPrice()
    };

    merge(this, defaultObject);
  }

  // Methods
  formatBody (type = 'get', options = {}) {
    let body = super.formatBody(type, options);

    // Remove price
    delete body.price;

    return body;
  }

  // Getters & Setters
  get cost_priceFormat () {
    if (this.cost_price === null) return null;
    return format.currencyToInput(this.cost_price);
  }
  set cost_priceFormat (value) {
    this.cost_price = calculate.price(value, true);
  }
}

export default Product;
