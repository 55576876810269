<template>
  <KPortlet>
    <KPortletHead>
      Betaalmethodes
    </KPortletHead>
    <KPortletBody class="kt-portlet__body--fit position-relative">
      <!-- Loading block -->
      <template v-if="isLoading">
        <div class="loading-container absolute">
          <div class="loading-backdrop">
            <div class="loading-block">
              <div class="blockui">
                <span>Please wait...</span>
                <span class="kt-spinner kt-spinner--v2 kt-spinner--primary" />
              </div>
            </div>
          </div>
        </div>
      </template>

      <!-- Table -->
      <div class="table-responsive">
        <table class="table mb-0">
          <thead class="thead-light thead-light--fit">
            <tr>
              <th>Omschrijving</th>
              <th class="table-field-currency">Bedrag</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Contant</td>
              <td class="table-field-currency">&euro; {{ $format.currency((totalsPeriod ? totalsPeriod.payment_methods.cash.sum : 0)) }}</td>
            </tr>
            <tr>
              <td>PIN</td>
              <td class="table-field-currency">&euro; {{ $format.currency((totalsPeriod ? totalsPeriod.payment_methods.card.sum : 0)) }}</td>
            </tr>
            <tr>
              <td>Cadeaubonnen</td>
              <td class="table-field-currency">&euro; {{ $format.currency((totalsPeriod ? totalsPeriod.payment_methods.giftcard.sum : 0)) }}</td>
            </tr>
            <tr>
              <td>Op rekening</td>
              <td class="table-field-currency">&euro; {{ $format.currency((totalsPeriod ? totalsPeriod.payment_methods.on_account.sum : 0)) }}</td>
            </tr>
          </tbody>
          <thead class="thead-light thead-light--fit">
            <tr>
              <th>Totaal</th>
              <th class="table-field-currency">&euro; {{ $format.currency(totalPaymentMethods) }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Sessiekrediet</td>
              <td class="table-field-currency">&euro; {{ $format.currency((totalsPeriod ? totalsPeriod.payment_methods.session_credits.sum : 0)) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </KPortletBody>
  </KPortlet>
</template>

<script>
export default {
  props: {
    totalsPeriod: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      isLoading: false
    };
  },
  computed: {
    totalPaymentMethods: {
      get () {
        let value = 0;

        // Add all payment methods to value
        if (this.totalsPeriod && this.totalsPeriod.payment_methods) {
          if (this.totalsPeriod.payment_methods.cash) value = value + this.totalsPeriod.payment_methods.cash.sum;
          if (this.totalsPeriod.payment_methods.card) value = value + this.totalsPeriod.payment_methods.card.sum;
          if (this.totalsPeriod.payment_methods.giftcard) value = value + this.totalsPeriod.payment_methods.giftcard.sum;
          if (this.totalsPeriod.payment_methods.on_account) value = value + this.totalsPeriod.payment_methods.on_account.sum;
        }

        return value;
      }
    }
  }
};
</script>
