var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.store !== null)?_c('div',[_c('KSubheader',{attrs:{"title":"Instellingen","breadcrumbs":[ {
      page: _vm.store.name,
      route: null
    }, {
      page: 'Instellingen',
      route: null
    }]}}),_vm._v(" "),_c('KContainer',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-portlet-menu"},[_c('KPortlet',[_c('KPortletBody',[_c('div',{staticClass:"kt-widget kt-widget--general-1"},[_c('div',{staticClass:"kt-widget__wrapper"},[_c('div',{staticClass:"kt-widget__label"},[_c('a',{staticClass:"kt-widget__title",attrs:{"href":"#"}},[_vm._v(_vm._s(_vm.store.name))])])])])]),_vm._v(" "),_c('div',{staticClass:"kt-portlet__separator"}),_vm._v(" "),_c('KPortletBody',[_c('ul',{staticClass:"kt-nav kt-nav--bolder kt-nav--fit-ver kt-nav--v4",attrs:{"role":"tablist"}},[_c('router-link',{attrs:{"to":"/settings/store/general"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var href = ref.href;
    var route = ref.route;
    var navigate = ref.navigate;
    var isActive = ref.isActive;
    var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"kt-nav__item",class:[{'active': isActive}]},[_c('a',{staticClass:"kt-nav__link",attrs:{"role":"tab"},on:{"click":function($event){$event.preventDefault();return _vm.$router.push(href)}}},[_c('span',{staticClass:"kt-nav__link-icon"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['fad', 'store']}})],1)]),_vm._v(" "),_c('span',{staticClass:"kt-nav__link-text"},[_vm._v("Winkel instellingen")])])])]}}],null,false,944203934)}),_vm._v(" "),_c('router-link',{attrs:{"to":"/settings/store/credit-reasons"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var href = ref.href;
    var route = ref.route;
    var navigate = ref.navigate;
    var isActive = ref.isActive;
    var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"kt-nav__item",class:[{'active': isActive}]},[_c('a',{staticClass:"kt-nav__link",attrs:{"role":"tab"},on:{"click":function($event){$event.preventDefault();return _vm.$router.push(href)}}},[_c('span',{staticClass:"kt-nav__link-icon"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['fad', 'undo-alt']}})],1)]),_vm._v(" "),_c('span',{staticClass:"kt-nav__link-text"},[_vm._v("Retour redenen")])])])]}}],null,false,1449922881)}),_vm._v(" "),_c('router-link',{attrs:{"to":"/settings/store/second-screens"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var href = ref.href;
    var route = ref.route;
    var navigate = ref.navigate;
    var isActive = ref.isActive;
    var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"kt-nav__item",class:[{'active': isActive}]},[_c('a',{staticClass:"kt-nav__link",attrs:{"role":"tab"},on:{"click":function($event){$event.preventDefault();return _vm.$router.push(href)}}},[_c('span',{staticClass:"kt-nav__link-icon"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['fad', 'tv']}})],1)]),_vm._v(" "),_c('span',{staticClass:"kt-nav__link-text"},[_vm._v("Tweede scherm")])])])]}}],null,false,1831444063)})],1)])],1)],1),_vm._v(" "),_c('div',{staticClass:"col-12 col-sm"},[_c('router-view',{key:_vm.$route.fullPath,attrs:{"store":_vm.store},on:{"update:store":function($event){_vm.store=$event}}})],1)])])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }