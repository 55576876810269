// Libraries
import { apiWS as ws } from '@/libs/ws';
import auth from '@/libs/auth';
import network from '@/libs/network';

export default [
  {
    type: 'mailchimp',
    name: 'Mailchimp',
    homepage: 'https://mailchimp.com',
    description: 'Synchroniseer je Caesium klantenbestand automatisch met een maillijst in Mailchimp',
    image: 'mailchimp.png',
    configurePath: '/settings/workspace/integrations/mailchimp',

    async connect (callback = null) {
      window.location.href = `${(network.secureProtocol ? 'https://' : 'http://')}${network.api}/api/${ws.workspace}/v1/integrations/mailchimp/authorize?access_token=${auth.getCurrentAuthData().access_token}&callback=${callback}`;
    }
  }
];
