<template>
  <div v-if="isMounted">

    <template v-if="missedProducts && missedProducts.length > 0">
      <div class="mutations missed-mutations">
        <mutation-line v-for="(mutation, idx) in missedProducts"
          type="MISSED"
          :ref="mutation.VUID"
          :key="mutation.VUID"
          :mutation="missedProducts[idx]"
          :firstRow="idx === 0"
          :lastRow="idx === (missedProducts.length - 1)"

          :save="save"
        ></mutation-line>
      </div>
    </template>

    <KAlert v-else variant="outline-success" :icon="['fad', 'check']">Geen gemiste producten</KAlert>

  </div>
</template>

<script>
// Classes
import StockBatch from '@/libs/classes/stock_batch';
import StockBatchMutation from '@/libs/classes/stock_batch.mutation';
import Product from '@/libs/classes/product';

// Components
import mutationLine from './mutation.line';

export default {
  props: {
    stock_batch: StockBatch,

    save: Function
  },
  data () {
    return {
      isMounted: false,

      missedProducts: []
    }
  },
  computed: {
    missedProductsToSave () {
      return this.missedProducts.filter(m => m.quantity !== null && typeof m.quantity === 'number');
    }
  },
  components: {
    mutationLine
  },

  async mounted () {
    try {
      await this.getMissedProducts();
    } catch (e) {
      console.error('Failed fetching missed products', e);
      return
    }

    this.$nextTick(() => {
      this.$set(this, 'isMounted', true);
    })
  },

  methods: {
    async validate () {
      const validationArray = [];

      this.missedProductsToSave.forEach((row, idx) => {
        if (this.$refs[row.VUID]) validationArray.push(this.$refs[row.VUID][0].$v);
      });

      // Execute validations
      if(validationArray.length > 0) {
        validationArray.forEach(v => v && v.$touch && v.$touch())
        if(validationArray.filter(v => v && v.$invalid).length !== 0) return false;
      }

      return true;
    },
    async onNextStep () {
      if (this.missedProductsToSave.length) {
        this.stock_batch.mutations = this.stock_batch.mutations.concat(this.missedProductsToSave);
        await this.save();
      }
    },

    async getMissedProducts (options = {}) {
      this.$set(this, 'missedProducts', []);

      // Get products which are not found in mutations
      const response = await this.$ws.get('v1', '/products', Object.assign(options, {
        query: {
          format: 'datatable',
          ignore_guids: this.stock_batch.mutations.map(m => m.product_guid, []).join(',')
        }
      }));

      // Filter out when stock is 0
      // TODO: Use endpoint that does this automatically
      if (response && response.data && response.data.length) {
        response.data = response.data.filter(p => p.stock !== 0);
      }

      // Set to Products class
      const data = response.data.map(p => new StockBatchMutation().mergeResponse({
        type: 'count',
        product_name: p.name,
        product_guid: p._meta.guid,
        quantity: null,
        stock: (p.stock || 0)
      }), []);

      this.$set(this, 'missedProducts', data);
      return this.missedProducts;
    }
  }
}
</script>
