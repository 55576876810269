// Components
import datatable from '@/components/datatable/table';
import datatablePagination from '@/components/datatable/pagination';
import datatablePaginationInfo from '@/components/datatable/pagination.info';

export default {
  components: {
    datatable,
    datatablePagination,
    datatablePaginationInfo
  },
  methods: {
    searchDebounced (value) {
      if (typeof this.$refs['datatable'] !== typeof undefined) this.$refs['datatable'].searchDebounced(value);
    },
    onPaginationData (paginationData) {
      this.$refs['pagination'].setPaginationData(paginationData);
      this.$refs['pagination.info'].setPaginationData(paginationData);
    },
    onChangePage (page) {
      this.$refs['datatable'].onChangePage(page);
    },
    onChangeRowCount (count) {
      this.$refs['datatable'].onChangeRowCount(count);
    }
  }
};
