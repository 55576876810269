<template>
  <KPortlet>
    <KPortletHead>
      <template v-slot:default>Integraties</template>
    </KPortletHead>
    <KPortletBody>
      <!-- Integrations list -->
      <template v-for="(integration, idx) in integrations">
        <div
          :key="`integration.${integration.type}`"
          :class="['row', {'border-bottom pb-3': (idx !== integrations.length - 1)}]"
        >
          <div class="col-12">
            <div class="kt-widget kt-widget--general-2">
              <div class="kt-portlet__body kt-portlet__body--fit">
                <div class="kt-widget__top p-1">
                  <div class="kt-media kt-media--lg kt-media--circle">
                    <img
                      :src="require(`@/assets/images/integrations/${integration.image}`)"
                      :alt="integration.name"
                    >
                  </div>
                  <div class="kt-widget__wrapper">
                    <div class="kt-widget__label">
                      <a
                        :href="integration.homepage"
                        target="_blank"
                        class="kt-widget__title"
                      >
                        {{ integration.name }}
                      </a>
                      <span class="kt-widget__desc">
                        {{ integration.description }}
                      </span>
                    </div>
                    <div class="kt-widget__toolbar">
                      <!-- Loading connected integrations -->
                      <template v-if="(isLoading || integration.isLoading)">
                        <div class="loading-container">
                          <div class="loading-block">
                            <div class="blockui">
                              <span class="kt-spinner kt-spinner--v2 kt-spinner--primary" />
                            </div>
                          </div>
                        </div>
                      </template>

                      <!-- Actions -->
                      <template v-else>
                        <!-- Integration not connected -->
                        <template v-if="!integration.details">
                          <KButton
                            v-if="(!integration.details)"

                            variant="focus"
                            class="btn-block"

                            @click.native.prevent="connectIntegration(integration)"
                          >
                            Integreer
                          </KButton>
                        </template>

                        <!-- Integration connected -->
                        <template v-else>
                          <KButton
                            variant="warning"

                            @click.native.prevent="$router.push(integration.configurePath)"
                          >
                            Configureer
                          </KButton>

                          <KButton
                            :icon="['fas', 'unlink']"
                            variant="secondary"

                            @click.native.prevent="disconnectIntegration(integration)"
                          />
                        </template>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </KPortletBody>
  </KPortlet>
</template>

<script>
// Classes
import Integration from '@/libs/classes/integration';

// Data
import integrations from './integrations';

export default {
  data () {
    return {
      isLoading: false,
      integrations: null
    };
  },

  mounted () {
    this.getIntegrations();
  },

  methods: {
    async getIntegrations (options = {}) {
      this.$set(this, 'isLoading', true);

      // Set available integrations
      this.$set(this, 'integrations', [...integrations]);

      // Get integrations
      const response = await this.$ws.get('v1', '/integrations', options);

      // Set integration details
      this.integrations.forEach((item) => {
        // Set loading per integration
        this.$set(item, 'isLoading', false);

        // Clear details
        item.details = undefined;

        // Get details
        const integrationDetails = response.find(i => i.type === item.type);
        if (integrationDetails) {
          item.details = new Integration().mergeResponse(integrationDetails);
        }
      });

      this.$set(this, 'isLoading', false);
      return this.integration;
    },

    async connectIntegration (integration = null) {
      if (!integration) {
        throw new Error('No integration given');
      }

      this.$set(this.integrations[this.integrations.indexOf(integration)], 'isLoading', true);
      return integration.connect((integration.configurePath ? window.location.origin + integration.configurePath : window.location));
    },

    async disconnectIntegration (integration = null) {
      if (!integration.details || (integration.details instanceof Integration) === false) {
        throw new Error('Invalid integration');
      }

      const vm = this;
      vm.$set(vm.integrations[vm.integrations.indexOf(integration)], 'isLoading', true);
      return this.$eventhub.emit('modals:confirm:open', {
        resource: integration,

        title: 'Integratie ontkoppelen',
        text: `Weet je zeker dat je de integratie met <strong>${integration.name}</strong> wilt ontkoppelen?`,
        async submit () {
          // Remove integration
          await integration.details.remove();

          // Refresh datatable
          vm.getIntegrations({
            query: {
              disable_cache: true
            }
          });
        }
      });
    }
  }
};
</script>
