<template>
  <div>
    <KSubheader
      :breadcrumbs="[{
        page: 'Producten',
        route: '/products/overview'
      }, {
        page: 'Product toevoegen',
        route: null
      }]"

      title="Producten"
    />

    <KContainer>
      <KPortlet>
        <KPortletHead>
          <template v-slot:default>Product toevoegen</template>
        </KPortletHead>
        <KPortletBody>

          <productForm
            ref="productForm"
            :product="product"

            :groups="meta.groups"
          />

        </KPortletBody>
        <div class="kt-portlet__foot">
          <div class="row align-items-center">
            <div class="offset-6 col-6 offset-lg-10 col-lg-2 kt-align-right">
              <KButton
                variant="primary"
                class="btn-md btn-block btn-tall btn-wide btn-bold btn-upper"
                @click.native.prevent="submitForm"
              >
                Toevoegen
              </KButton>
            </div>
          </div>
        </div>
      </KPortlet>
    </KContainer>

  </div>
</template>

<script>
// Classes
import Product from '@/libs/classes/product';
import ProductGroup from '@/libs/classes/product_group';

// Components
import productForm from './components/form';

export default {
  components: {
    productForm
  },
  data () {
    return {
      isMounted: false,

      product: new Product().mergeResponse({
        vat_code: null,

        stock: 0,

        amount_changeable: true
      }),

      meta: {
        groups: []
      }
    };
  },
  async mounted () {
    try {
      await Promise.all([this.getProductGroups()]);
    } catch (e) {
      console.error('Failed catching all data', e);
      return;
    }

    this.$nextTick(() => {
      this.isMounted = true;
    });
  },
  methods: {
    async getProductGroups () {
      this.$set(this.meta, 'groups', []);
      let groups = await this.$ws.get('v1', '/product_groups');
      groups = groups.map((row) => {
        return new ProductGroup().mergeResponse(row);
      });
      this.$set(this.meta, 'groups', groups);
      return this.meta.groups;
    },

    async validate () {
      const validationArray = [];

      // Execute validations
      if (validationArray.length > 0) {
        validationArray.forEach(v => v && v.$touch && v.$touch());
        if (validationArray.filter(v => v && v.$invalid).length !== 0) return false;
      }

      // Validate form
      if (await this.$refs.productForm.validate() === false) return false;

      return true;
    },
    async submitForm () {
      if (await this.validate() === true) {
        let response = await this.product.create(this.product.formatBody());
        this.$router.push(`/products/view/${response.guid}`);
      }
    }
  }
};
</script>
