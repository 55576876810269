<template>
  <div>

    <div class="row form-group">
      <div class="col-12 col-lg-6">
        <label>Naam <sup>Verplicht</sup></label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i><font-awesome-icon :icon="['fad', 'text']" /></i>
            </span>
          </div>
          <input type="text" class="form-control" :placeholder="(placeholder ? 'Sessie 1' : undefined)" v-model.trim="$v.session.name.$model" :class="[{'is-invalid': $v.session.name.$error}]" :disabled="disabled">
        </div>
      </div>

      <div class="col-12 mt-3 col-lg-6 mt-lg-0">
        <label>Duur van sessie <sup>Verplicht</sup></label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i><font-awesome-icon :icon="['fad', 'stopwatch']" /></i>
            </span>
          </div>
          <select class="form-control" v-model="$v.session.duration.$model" :class="[{'is-invalid': $v.session.duration.$error}]" :disabled="disabled">
            <option v-for="minute in 60" :value="(minute * 60)">{{ minute }}</option>
          </select>
          <div class="input-group-append">
            <span class="input-group-text">{{ ($v.session.duration.$model === 60 ? 'minuut' : 'minuten') }}</span>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { required, numeric, minValue } from 'vuelidate/lib/validators'

export default {
  props: {
    session: Object,

    disabled: Boolean,
    placeholder: {
      type: Boolean,
      default: true
    }
  },
  validations: {
    session: {
      name: {
        required
      },
      duration: {
        required,
        numeric,
        minValue: minValue(60)
      }
    }
  }
}
</script>
