// Dutch translation of Caesium

// Error translations
const errors = {
  fallback: {
    title: 'Onbekende fout - {error}',
    message: 'Een onbekende fout is opgetreden - ({error} - {info})'
  },

  notFoundError: {
    NotFoundError: {
      title: 'Niet gevonden',
      message: 'Het desbetreffende document is niet gevonden'
    },
    invalidUserCredentials: {
      title: 'Inloggen mislukt',
      message: 'De combinatie van de gebruikersnaam en het wachtwoord is niet bij ons bekend.'
    }
  },

  validationError: {
    // Default
    error: {
      title: 'Validatiefout opgetreden',
      message: 'Er is een onbekende validatiefout opgetreden. Probeer het opnieuw.'
    },

    // Based on error.info
    customerRequired: {
      title: 'Klant verplicht',
      message: 'Er dient een klant gekoppeld te worden om deze actie uit te voeren.'
    },
    transactionNotFullyPaid: {
      title: 'Onvoldoende betaald',
      message: 'Er is onvoldoende betaald om deze actie uit te voeren.'
    },
    invalidEquipment: {
      title: 'Apparatuur niet gevonden',
      message: 'De actie kan niet worden uitgevoerd om de apparatuur niet is gevonden.'
    },
    bundleSessionNotAvailable: {
      title: 'Pakket ontoereikend',
      message: 'Het gekozen pakket is niet toereikend om deze actie uit te voeren.'
    },
    giftcardZero: {
      title: 'Saldo cadeaubon ontoereikend',
      message: 'Het saldo van deze cadeaubon is niet toereikend om deze actie uit te voeren.'
    },
    giftcardCodeUsed: {
      title: 'Cadeauboncode reeds in gebruik',
      message: 'De gekozen cadeauboncode is reeds in gebruik.'
    },
    insufficientCreditBalance: {
      title: 'Sessiekrediet ontoereikend',
      message: 'Het sessiekrediet van deze klant is niet toereikend om deze actie uit te voeren.'
    },
    customerAuthenticationRequired: {
      title: 'Authenticatie klant verplicht',
      message: 'De klant dient geauthenticeerd te worden om deze actie uit te voeren.'
    }
  },

  permissionError: {
    error: {
      title: 'Permissie fout',
      message: 'Er is een onbekende permissie fout opgetreden. Probeer het opnieuw.'
    },

    salesorderAlreadyFinished: {
      title: 'Transactie reeds afgerond',
      message: 'Deze transactie is reeds afgerond.'
    }
  }
};

export default {
  errors: errors
};
