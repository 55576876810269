<template>
  <div v-if="store !== null">

    <KSubheader
      title="Instellingen"
      :breadcrumbs="[ {
        page: store.name,
        route: null
      }, {
        page: 'Instellingen',
        route: null
      }]"
    ></KSubheader>

    <KContainer>
      <div class="row">
        <div class="col-12 col-portlet-menu">
          <KPortlet>
            <KPortletBody>
              <div class="kt-widget kt-widget--general-1">
                <div class="kt-widget__wrapper">
                  <div class="kt-widget__label">
                    <a href="#" class="kt-widget__title">{{ store.name }}</a>
                  </div>
                </div>
              </div>
            </KPortletBody>
            <div class="kt-portlet__separator"></div>
            <KPortletBody>
              <ul class="kt-nav kt-nav--bolder kt-nav--fit-ver kt-nav--v4" role="tablist">
                <router-link to="/settings/store/general" v-slot="{ href, route, navigate, isActive, isExactActive }">
                  <li class="kt-nav__item" :class="[{'active': isActive}]">
                    <a class="kt-nav__link" role="tab" @click.prevent="$router.push(href)">
                      <span class="kt-nav__link-icon">
                        <i><font-awesome-icon :icon="['fad', 'store']" /></i>
                      </span>
                      <span class="kt-nav__link-text">Winkel instellingen</span>
                    </a>
                  </li>
                </router-link>

                <router-link to="/settings/store/credit-reasons" v-slot="{ href, route, navigate, isActive, isExactActive }">
                  <li class="kt-nav__item" :class="[{'active': isActive}]">
                    <a class="kt-nav__link" role="tab" @click.prevent="$router.push(href)">
                      <span class="kt-nav__link-icon">
                        <i><font-awesome-icon :icon="['fad', 'undo-alt']" /></i>
                      </span>
                      <span class="kt-nav__link-text">Retour redenen</span>
                    </a>
                  </li>
                </router-link>

                <router-link to="/settings/store/second-screens" v-slot="{ href, route, navigate, isActive, isExactActive }">
                  <li class="kt-nav__item" :class="[{'active': isActive}]">
                    <a class="kt-nav__link" role="tab" @click.prevent="$router.push(href)">
                      <span class="kt-nav__link-icon">
                        <i><font-awesome-icon :icon="['fad', 'tv']" /></i>
                      </span>
                      <span class="kt-nav__link-text">Tweede scherm</span>
                    </a>
                  </li>
                </router-link>
              </ul>
            </KPortletBody>
          </KPortlet>
        </div>

        <div class="col-12 col-sm">
          <router-view :key="$route.fullPath"
            :store.sync="store"
          ></router-view>
        </div>
      </div>

    </KContainer>

  </div>
</template>

<script>
// Store class
import Store from '@/libs/classes/store';

export default {
  data () {
    return {
      store: null
    };
  },

  async mounted () {
    try {
      await this.getStore();
    } catch (e) {
      console.error('Store not found:', e);
    }
  },

  methods: {
    async getStore (options = {}) {
      this.$set(this, 'store', null);
      const store = await new Store(this.$stores.activeStore._meta.guid).get(options);
      await store.populate('get', options);
      this.$set(this, 'store', store);
      return this.store;
    }
  }
};
</script>
