var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isMounted === false || _vm.stock_batch === null)?_c('KContainer',[_c('div',{staticClass:"row mt-3"},[_c('div',{staticClass:"col-12 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4"},[_c('div',{staticClass:"loading-container"},[_c('div',{staticClass:"loading-block"},[_c('div',{staticClass:"blockui ml-auto mr-auto w-100"},[_c('span',[_vm._v("Gegevens ophalen...")]),_vm._v(" "),_c('span',{staticClass:"kt-spinner kt-spinner--v2 kt-spinner--primary"})])])])])])]):_c('div',[_c('KSubheader',{attrs:{"breadcrumbs":[{
      page: 'Producten',
      route: '/products/overview'
    }, {
      page: 'Voorraadbeheer'
    }, {
      page: 'Mutaties',
      route: '/products/stock/mutations'
    }, {
      page: 'Mutatie',
      route: null
    }],"title":"Mutaties"}}),_vm._v(" "),_c('KContainer',[_c('KPortlet',[_c('KPortletHead',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_vm._v("Inventarisatie op "+_vm._s(_vm.moment(_vm.stock_batch.finished_timestamp).format('LLL')))]},proxy:true}])}),_vm._v(" "),_c('KPortletBody',[_c('div',{staticClass:"row form-group mb-0"},[_c('div',{staticClass:"col-12 col-lg-6"},[_c('label',[_vm._v("Datum & tijd")]),_vm._v(" "),_c('div',{staticClass:"input-group"},[_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['fad', 'clock']}})],1)])]),_vm._v(" "),_c('input',{staticClass:"form-control",attrs:{"type":"text","disabled":""},domProps:{"value":_vm.moment(_vm.stock_batch.finished_timestamp).format('LLL')}})])]),_vm._v(" "),_c('div',{staticClass:"col-12 col-lg-6"},[_c('label',[_vm._v("Medewerker")]),_vm._v(" "),_c('div',{staticClass:"input-group"},[_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['fad', 'user']}})],1)])]),_vm._v(" "),_c('div',{staticClass:"form-control",attrs:{"readonly":""}},[(_vm.stock_batch.finished_user)?_c('router-link',{attrs:{"to":(_vm.stock_batch.finished_user.guid === _vm.$auth.user.guid ? "/settings/personal" : ("/users/view/" + (_vm.stock_batch.finished_user.guid))),"tag":"a"}},[_vm._v("\n                  "+_vm._s(_vm.stock_batch.finished_user.name)+"\n                ")]):[_vm._v("Onbekend")]],2)])])]),_vm._v(" "),_c('div',{staticClass:"row form-group mb-0 mt-3"},[_c('div',{staticClass:"col-12"},[_c('label',[_vm._v("Opmerking")]),_vm._v(" "),_c('textarea',{staticClass:"form-control",attrs:{"rows":"3","cols":"6","disabled":""},domProps:{"value":_vm.stock_batch.remark}})])])])],1),_vm._v(" "),_c('mutations-portlet',{ref:"mutationsPortlet",attrs:{"stock_batch":_vm.stock_batch}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }