import Abstract from './abstract';

import { merge } from 'lodash';

class SalesOrderLine extends Abstract {
  constructor (guid = null) {
    super(guid);
    this.uri = null;

    let defaultObject = {
      type: null,
      discount_type: null,
      discount_value: null
    };

    merge(this, defaultObject);
  }

  // Getters & Setters
  get name () {
    if (this.type === 'on_account_topup') return `Bedrag aflossen`;
    if (this.type === 'giftcard') return `Cadeaubon`;
    if (this.type === 'session') return this.session_name;
    if (this.type === 'bundle') return this.bundle_name;

    return this.product_name;
  }
}

export default SalesOrderLine;
