<template>
  <div>

    <KSubheader
      :breadcrumbs="[{
        page: 'Pakketten',
        route: null
      }, {
        page: 'Bundels',
        route: '/bundles/overview'
      }, {
        page: 'Aanmaken',
        route: null
      }]"

      title="Bundels"
    />

    <KContainer>
      <KPortlet>
        <KPortletBody class="kt-portlet__body-fit">
          <KWizard
            ref="wizard"
            :clickable="false"
          >
            <KWizardStep
              title="Informatie"
            >
              <!-- Information form -->
              <information-form
                ref="informationForm"

                :bundle-product="bundleProduct"

                action="CREATE"
              />
            </KWizardStep>

            <KWizardStep
              v-if="hasSessions"
              title="Sessies"
            >
              <!-- Sessions -->
              <sessions-form
                ref="sessionsForm"

                :bundle-product="bundleProduct"

                action="CREATE"
              />
            </KWizardStep>

            <KWizardStep
              v-if="hasProducts"
              title="Producten"
            >
              <!-- Products -->
              <products-form
                ref="productsForm"

                :bundle-product="bundleProduct"

                action="CREATE"
              />
            </KWizardStep>
          </KWizard>
        </KPortletBody>
        <div class="kt-portlet__foot">
          <div class="row align-items-center">
            <div class="col-6 col-lg-2">
              <KButton
                v-if="(isMounted && $refs.wizard && $refs.wizard.currentStep !== 0)"
                variant="outline-focus"
                class="btn-md btn-block btn-tall btn-wide btn-bold btn-upper"
                @click.native.prevent="previousStep"
              >
                Vorige
              </KButton>
            </div>
            <div class="col-6 offset-lg-8 col-lg-2 kt-align-right">
              <KButton
                v-if="(isMounted && $refs.wizard && $refs.wizard.currentStep !== ($refs.wizard.steps.length - 1))"
                variant="focus"
                class="btn-md btn-block btn-tall btn-wide btn-bold btn-upper"
                @click.native.prevent="nextStep"
              >
                Volgende
              </KButton>

              <KButton
                v-if="(isMounted && $refs.wizard && $refs.wizard.currentStep === ($refs.wizard.steps.length - 1))"
                variant="success"
                class="btn-md btn-block btn-tall btn-wide btn-bold btn-upper"
                @click.native.prevent="submitForm"
              >
                Aanmaken
              </KButton>
            </div>
          </div>
        </div>
      </KPortlet>
    </KContainer>
  </div>
</template>

<script>
// Import CSS for KWizard
import '@/assets/sass/caesium.wizard.scss';

// Libraries
import { merge, cloneDeep } from 'lodash';

// Classes
import BundleProduct from '@/libs/classes/bundle_product';
import BundleProductSubscription from '@/libs/classes/bundle_product.subscription';
import BundleProductTreatment from '@/libs/classes/bundle_product.treatment';
import BundleProductProducts from '@/libs/classes/bundle_product.products';

// Components
import informationForm from '@/components/bundles/components/information.form';
import sessionsForm from '@/components/bundles/components/sessions.form';
import productsForm from '@/components/bundles/components/products.form';

export default {
  components: {
    informationForm,
    sessionsForm,
    productsForm
  },
  data () {
    return {
      isMounted: false,

      bundleProduct: this.getBundleByType()
    };
  },
  computed: {
    hasSessions () {
      if (this.bundleProduct) {
        const types = ['bundle', 'subscription', 'treatment'];
        return types.indexOf(this.bundleProduct.type) !== -1;
      }
      return false;
    },
    hasProducts () {
      if (this.bundleProduct) {
        const types = ['bundle', 'treatment', 'product_bundle'];
        return types.indexOf(this.bundleProduct.type) !== -1;
      }
      return false;
    }
  },

  async mounted () {
    this.$nextTick(() => {
      this.isMounted = true;
    });
  },

  methods: {
    getBundleByType () {
      // Combine routes
      let meta = {};
      let routes = [this.$route].concat(this.$route.matched);
      routes.forEach((route) => {
        if (route && route.meta) {
          meta = merge(meta, route.meta);
        }
      });

      if (meta && meta.bundle) {
        if (meta.bundle === 'subscription') {
          return new BundleProductSubscription();
        }

        if (meta.bundle === 'treatment') {
          return new BundleProductTreatment();
        }

        if (meta.bundle === 'product_bundle') {
          return new BundleProductProducts();
        }
      }

      return new BundleProduct();
    },

    async validate () {
      const validationArray = [];

      // Information
      if (this.$refs.wizard.currentStep === 0) {
        if (await this.$refs.informationForm.validate() === false) return false;
      }

      // Sessions
      if (this.hasSessions === true) {
        if (this.$refs.wizard.currentStep === 1) {

        }
      }

      // Products
      if (this.hasProducts) {
        if (this.$refs.wizard.currentStep === (this.hasSessions ? 2 : 1)) {
          validationArray.push(this.$refs['productsForm'].$v);
          if (await this.$refs['productsForm'].validate() === false) return false;
        }
      }

      // Execute validations
      if (validationArray.length > 0) {
        validationArray.forEach(v => v && v.$touch && v.$touch());
        if (validationArray.filter(v => v && v.$invalid).length !== 0) return false;
      }

      return true;
    },
    async submitForm () {
      if (await this.validate() === true) {
        // Clone pricelist, because it has to populate first for the Frontend
        let bundleProduct = cloneDeep(this.bundleProduct);
        try {
          await bundleProduct.create(bundleProduct.formatBody());
          await bundleProduct.populate('create');
        } catch (e) {
          this.$error.showModal(e);
          return;
        }

        this.$set(this, 'bundleProduct', bundleProduct);

        // Set redirect route
        let route = {
          name: null,
          params: {
            guid: this.bundleProduct.guid
          }
        };

        switch (bundleProduct.type) {
          case 'subscription':
            route.name = '/bundles/subscriptions/view/:guid';
            break;
          case 'treatment':
            route.name = '/bundles/treatments/view/:guid';
            break;
          case 'product_bundle':
            route.name = '/bundles/product/view/:guid';
            break;
          default:
            route.name = '/bundles/view/:guid';
            break;
        }

        this.$router.push(route);
      }
    },

    async previousStep () {
      if (this.$refs.wizard) {
        return this.$refs.wizard.previousStep();
      }
    },
    async nextStep () {
      if (this.$refs.wizard) {
        let valid = await this.validate();
        if (valid) this.$refs.wizard.nextStep();
      }
    }
  }
};
</script>
