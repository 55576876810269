<template>
  <div v-if="isMounted">
    <KPortlet>
      <KPortletHead>
        <template v-slot:default>Retour reden toevoegen</template>
      </KPortletHead>
      <KPortletBody>
        <creditReasonForm ref="creditReasonForm" action="CREATE" :credit_reason="credit_reason"></creditReasonForm>
      </KPortletBody>
      <div class="kt-portlet__foot">
        <div class="row align-items-center">
          <div class="offset-6 col-6 offset-lg-10 col-lg-2 kt-align-right">
            <KButton variant="warning" class="btn-md btn-block btn-tall btn-wide btn-bold btn-upper" @click.native.prevent="submitForm">Wijzigen</KButton>
          </div>
        </div>
      </div>
    </KPortlet>

  </div>
</template>

<script>
// Classes
import CreditReason from '@/libs/classes/credit_reasons';

// Components
import creditReasonForm from './components/form';

export default {
  data() {
    return {
      isMounted: false,

      credit_reason: new CreditReason()
    };
  },

  components: {
    creditReasonForm
  },

  async mounted() {
    this.$nextTick(() => {
      this.isMounted = true;
    })
  },
  methods: {
    validate () {
      const validationArray = [];
      validationArray.push(this.$refs.creditReasonForm.$v);

      // Execute validations
      if (validationArray.length > 0) {
        validationArray.forEach(v => v && v.$touch && v.$touch())
        if (validationArray.filter(v => v && v.$invalid).length !== 0) return false;
      }

      return true;
    },

    async submitForm() {
      if (this.validate() === true) {
        await this.credit_reason.create(this.credit_reason.formatBody());
        this.$router.push(`/settings/credit_reasons/view/${this.credit_reason.guid}`);
      }
    }
  }
}
</script>
