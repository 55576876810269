<template>
  <div class="row">
    <!-- Day button -->
    <div class="col-4 col-md-2">
      <KButton tag="span" :variant="dayButtonClass(day)" class="btn-block">{{ dayName(day) }}</KButton>
    </div>

    <div class="col-8 col-md-10">
      <div class="row">
        <template v-for="(time) in timesOfDay(day)">
          <div class="col-12 col-md-6">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i><font-awesome-icon :icon="['fad', 'clock']" /></i>
                </span>
              </div>
              <input type="text" class="form-control" :value="`${formatTime(time.start)} - ${formatTime(time.end)}`" disabled>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
// Libraries
import moment from 'moment-timezone';

// Classes
import BundleProductSession from '@/libs/classes/bundle_product.session';

export default {
  props: {
    day: Number,
    session: BundleProductSession
  },

  methods: {
    dayName (day = 0) {
      if (day === 0) return 'Zo';
      if (day === 1) return 'Ma';
      if (day === 2) return 'Di';
      if (day === 3) return 'Wo';
      if (day === 4) return 'Do';
      if (day === 5) return 'Vr';
      if (day === 6) return 'Za';
      return 'N/A';
    },

    timesOfDay (day = 0) {
      return this.session.available_times.filter(d => d.day === day);
    },
    dayButtonClass (day = 0) {
      // Check if day is found
      if (this.timesOfDay(day).length !== 0) return 'primary';

      // Not active
      return 'outline-primary';
    },

    formatTime (seconds = 0) {
      // 86400 is end of day, so set to 86399 so we get 23:59
      if (seconds >= 86400) seconds = 86399;
      return moment().startOf('day').seconds(seconds).format('HH:mm');
    }
  }
}
</script>
