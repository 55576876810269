<template>
  <KPortlet>
    <KPortletHead>
      <template v-slot:default>
        Klant
        <small>Gegevens op moment van betaling</small>
      </template>
    </KPortletHead>
    <KPortletBody>
      <!-- Customer known -->
      <template v-if="customer && customer._meta">
        <div class="row form-group mb-2">
          <label class="col-12 col-lg-3 col-form-label">Naam</label>
          <div class="col-12 col-lg-9">
            <div class="input-group">
              <div class="input-group-prepend">
                <span
                  class="input-group-text"
                  style="width:45px;"
                >
                  <i><font-awesome-icon :icon="['fad', 'user']" /></i>
                </span>
              </div>
              <div
                type="text"
                class="form-control"
                readonly
              >
                <router-link
                  :to="`/customers/view/${customer.guid}`"
                  tag="a"
                >
                  {{ customer.name }}
                </router-link>
              </div>
            </div>
          </div>
        </div>

        <div class="row form-group mb-2">
          <label class="col-12 col-lg-3 col-form-label">Geboortedatum</label>
          <div class="col-12 col-lg-9">
            <div class="input-group">
              <div class="input-group-prepend">
                <span
                  class="input-group-text"
                  style="width:45px;"
                >
                  <i><font-awesome-icon :icon="['fad', 'calendar']" /></i>
                </span>
              </div>
              <div
                type="text"
                class="form-control"
                readonly
              >
                {{ (typeof customer.date_of_birth === 'string' && customer.date_of_birth.length ? moment(customer.date_of_birth, 'YYYY-MM-DD').format('LL') : undefined) }}
              </div>
            </div>
          </div>
        </div>

        <KSeparator class="mt-3 mb-3" />

        <div class="row form-group mb-2">
          <label class="col-12 col-lg-3 col-form-label">E-mail adres</label>
          <div class="col-12 col-lg-9">
            <div class="input-group">
              <div class="input-group-prepend">
                <span
                  class="input-group-text"
                  style="width:45px;"
                >
                  <i><font-awesome-icon :icon="['fad', 'at']" /></i>
                </span>
              </div>
              <div
                type="text"
                class="form-control"
                readonly
              >
                <a
                  v-if="customer.email && customer.email.length"
                  :href="`mailto:${customer.email}`"
                >
                  {{ customer.email }}
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="row form-group mb-2">
          <label class="col-12 col-lg-3 col-form-label">Telefoonnummer</label>
          <div class="col-12 col-lg-9">
            <div class="input-group">
              <div class="input-group-prepend">
                <span
                  class="input-group-text"
                  style="width:45px;"
                >
                  <i><font-awesome-icon :icon="['fad', 'phone']" /></i>
                </span>
              </div>
              <div
                type="text"
                class="form-control"
                readonly
              >
                <a
                  v-if="customer.phone && customer.phone.length"
                  :href="`tel:${customer.phone}`"
                >
                  {{ customer.phone }}
                </a>
              </div>
            </div>
          </div>
        </div>

        <KSeparator class="mt-3 mb-3" />

        <div class="row form-group mb-2">
          <label class="col-12 col-lg-3 col-form-label">Land</label>
          <div class="col-12 col-lg-9">
            <div class="input-group">
              <div class="input-group-prepend">
                <span
                  class="input-group-text"
                  style="width:45px;"
                >
                  <i><font-awesome-icon :icon="['fad', 'globe']" /></i>
                </span>
              </div>
              <div
                type="text"
                class="form-control"
                readonly
              >
                {{ customer.address.country }}
              </div>
            </div>
          </div>
        </div>

        <div class="row form-group mb-2">
          <label class="col-12 col-lg-3 col-form-label">Postcode + huisnummer</label>
          <div class="col-8 col-lg-6">
            <div class="input-group">
              <div class="input-group-prepend">
                <span
                  class="input-group-text"
                  style="width:45px;"
                >
                  <i><font-awesome-icon :icon="['fad', 'home']" /></i>
                </span>
              </div>
              <div
                type="text"
                class="form-control"
                readonly
              >
                {{ customer.address.postalCode }}
              </div>
            </div>
          </div>
          <div class="col-4 col-lg-3">
            <div class="input-group">
              <div
                type="text"
                class="form-control"
                readonly
              >
                {{ customer.address.houseNumber }}
              </div>
            </div>
          </div>
        </div>

        <div class="row form-group mb-2">
          <label class="col-12 col-lg-3 col-form-label">Adres</label>
          <div class="col-12 col-lg-9">
            <div class="input-group">
              <div class="input-group-prepend">
                <span
                  class="input-group-text"
                  style="width:45px;"
                >
                  <i><font-awesome-icon :icon="['fad', 'home']" /></i>
                </span>
              </div>
              <div
                type="text"
                class="form-control"
                readonly
              >
                {{ customer.address.line1 }}
              </div>
            </div>
          </div>
        </div>

        <div class="row form-group mb-2">
          <label class="col-12 col-lg-3 col-form-label">Stad</label>
          <div class="col-12 col-lg-9">
            <div class="input-group">
              <div class="input-group-prepend">
                <span
                  class="input-group-text"
                  style="width:45px;"
                >
                  <i><font-awesome-icon :icon="['fad', 'home']" /></i>
                </span>
              </div>
              <div
                type="text"
                class="form-control"
                readonly
              >
                {{ customer.address.city }}
              </div>
            </div>
          </div>
        </div>

      </template>

      <!-- Customer unknown -->
      <template v-else>
        <KAlert
          :icon="['fad', 'info-circle']"
          variant="outline-focus"
        >
          Er is geen klant gekoppeld aan deze transactie
        </KAlert>
      </template>

    </KPortletBody>
  </KPortlet>
</template>

<script>
// Libraries
import moment from 'moment-timezone';

// Classes
import SalesOrder from '@/libs/classes/salesorder';
import Customer from '@/libs/classes/customer';

export default {
  props: {
    salesorder: {
      type: SalesOrder,
      default: null
    },
    customer: {
      type: Customer,
      default: null
    }
  },
  data () {
    return {
      moment
    };
  }
};
</script>
