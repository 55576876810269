<template>
  <KModal ref="modal" title="Ophalen winkelwagen" centered size="xl" scrollable v-keydown="{'escape': close }">
    <template v-slot:default>
      <div class="row">
        <div class="col-12">
          <div class="kt-input-icon kt-input-icon--left">
            <input type="text" class="form-control" placeholder="Zoeken..." @keyup.enter="$refs['datatable'].search" @keyup="searchDebounced" id="generalSearch">
            <span class="kt-input-icon__icon kt-input-icon__icon--left">
              <span>
                <i>
                  <font-awesome-icon
                  :icon="['far', 'search']"
                  ></font-awesome-icon>
                </i>
              </span>
            </span>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-12 datatable datatable-wrapper">
          <datatable ref="datatable"
            :url="datatable.url"
            :fields="datatable.fields"
            :searchFields="datatable.searchFields"
            :onPaginationData="onPaginationData"
            :onCellClicked="onCellClicked"
            :transform="transform"
          >

          </datatable>

          <div class="row pagination">
            <div class="col-xs-12 col-md-6">
              <datatable-pagination ref="pagination"
                v-on:vuetable-pagination:change-page="onChangePage"
              ></datatable-pagination>
            </div>

            <div class="col-xs-12 col-md-6">
              <datatable-pagination-info ref="pagination.info"
                v-on:vuetable-paginationinfo:change-rowcount="onChangeRowCount"
              ></datatable-pagination-info>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:footer>
      <KButton variant="secondary" v-on:click.native.prevent="close">Annuleren</KButton>
    </template>
  </KModal>
</template>

<script>
// Libraries
import shop from '@/libs/shop';
import moment from 'moment-timezone';

// Classes
import SalesOrder from '@/libs/classes/salesorder';

// Mixins
import datatable from '@/mixins/datatable';

export default {
  mixins: [datatable],
  data() {
    return {
      datatable: {
        url: "/salesorders/open",
        fields: [{
          title: 'Datum',
          name: '_meta.created',
          sortField: "_meta.created",
          dataClass: "cursor-pointer w-1/8",
          callback: (value) => moment(value).format('LLL')
        }, {
          title: 'Klant',
          name: 'customer',
          sortField: 'customer.firstname',
          dataClass: 'cursor-pointer w-1/8',
          callback: (customer) => (customer && customer.name ? customer.name : '')
        }, {
          title: 'Totale waarde',
          name: 'total_incl_vat',
          sortField: 'total_incl_vat',
          dataClass: 'cursor-pointer w-1/8',
          callback: (value) => `&euro; ${this.$format.currency(value)}`
        }],

        searchFields: ['customer.firstname', 'customer.lastname']
      }
    }
  },
  mounted () {
    // Listeners
    this.$eventhub.on('modals:shop:stored-salesorders:open', this.open);
    this.$eventhub.on('modals:shop:stored-salesorders:close', this.close);
  },
  beforeDestroy () {
    // Remove listeners
    this.$eventhub.off('modals:shop:stored-salesorders:open', this.open);
    this.$eventhub.off('modals:shop:stored-salesorders:close', this.close);
  },

  methods: {
    async onCellClicked(salesorder) {
      this.$refs['datatable'].loading = true;

      // Set sales_order and fetch it from API
      shop.sales_order = salesorder;
      await shop.getSalesOrder();
      shop.setSalesOrder(salesorder.guid);
      
      this.$refs['datatable'].loading = false;
      this.close();
    },

    transform (data) {
      if(data.data && Array.isArray(data.data)) {
        data.data.forEach((row, idx) => {
          data.data[idx] = new SalesOrder().mergeResponse(data.data[idx]);
        })
      }

      return data;
    },

    open () {
      this.$refs.modal.show();
    },
    close () {
      if (this.$refs.modal !== typeof undefined) this.$refs.modal.hide();
    }
  }
}
</script>
