<template>
  <KPortlet>
    <KPortletHead>
      Persoonlijke PIN code
    </KPortletHead>
    <KPortletBody>
      <authentication-pincode
        ref="authenticationPincode"
        :resource="customerClone"
      />
    </KPortletBody>
    <div class="kt-portlet__foot">
      <div class="row align-items-center">
        <div class="offset-6 col-6 offset-lg-10 col-lg-2 kt-align-right">
          <KButton
            variant="warning"
            class="btn-md btn-block btn-tall btn-wide btn-bold btn-upper"
            @click.native.prevent="submitForm"
          >
            Wijzigen
          </KButton>
        </div>
      </div>
    </div>
  </KPortlet>
</template>

<script>
// Libraries
import { merge, cloneDeep } from 'lodash';
import mutationsLib from '@/libs/mutations';

// Classes
import Customer from '@/libs/classes/customer';

// Components
import authenticationPincode from '@/components/authentication/pincode';

export default {
  components: {
    authenticationPincode
  },
  props: {
    customer: {
      type: Customer,
      default: null
    }
  },
  data () {
    let customerClone = cloneDeep(this.customer);

    // Add pincode to cloned customer
    customerClone.pincode = null;

    return {
      customerClone: customerClone
    };
  },

  methods: {
    async validate () {
      const validationArray = [];
      validationArray.push(this.$refs.authenticationPincode.$v);

      if (validationArray.length > 0) {
        validationArray.forEach(v => v && v.$touch && v.$touch());
        if (validationArray.filter(v => v && v.$invalid).length !== 0) return false;
      }

      return true;
    },

    async submitForm () {
      if (await this.validate() === true) {
        const fields = ['pincode'];
        const mutations = mutationsLib.create(fields, {}, this.customerClone.formatBody());

        if (mutations.length) {
          await this.customer.update(mutations);
          this.$set(this.$data, 'customerClone', merge(cloneDeep(this.customer), {
            pincode: null
          }));

          // Reset pincode
          this.$refs['authenticationPincode'].pincode_confirm = null;
          this.$refs['authenticationPincode'].$v.$reset();
        }
      }
    }
  }
};
</script>
