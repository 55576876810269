<template>
  <div class="col-12 mt-3 col-lg-4 mt-lg-0">
    <label>Kan verkocht worden door</label>
    <div class="input-group">
      <div class="input-group-prepend">
        <span class="input-group-text">
          <i>
            <font-awesome-icon :icon="['fad', 'users']" />
          </i>
        </span>
      </div>

      <template v-if="action === 'VIEW'">
        <input
          :value="(selectedGroup ? selectedGroup.name : 'Onbekend')"

          type="text"
          class="form-control"
          disabled
        >
      </template>

      <template v-else>
        <select
          v-model="product.minimum_permission_group_level"
          :class="['form-control']"
          :disabled="(filteredPermissionGroups.length === 0 || disabled)"
        >
          <option
            v-for="(group) in filteredPermissionGroups"
            :key="`permission_groups.${group.guid}`"
            :value="group.level"
          >
            {{ group.name }} en hoger
          </option>
        </select>
      </template>
    </div>
  </div>
</template>

<script>
// Classes
import Product from '@/libs/classes/product';
import CreditProduct from '@/libs/classes/credit_product';
import BundleProduct from '@/libs/classes/bundle_product';

import PermissionGroup from '@/libs/classes/permission_group';

export default {
  props: {
    action: {
      type: String,
      default: 'CREATE'
    },

    product: {
      type: [Product, CreditProduct, BundleProduct],
      default: null
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isMounted: false,

      groups: []
    };
  },
  computed: {
    filteredPermissionGroups () {
      if (this.groups) {
        return this.groups.filter(group => group.hasPermission([['shop']])).sort((a, b) => {
          return b.level - a.level;
        });
      }
      return [];
    },
    selectedGroup () {
      if (this.groups && this.groups.length > 0) {
        return this.groups.find(group => group.level === this.product.minimum_permission_group_level);
      }
      return null;
    }
  },

  async mounted () {
    // Only get permissions groups when permission is found
    if (this.$auth.hasPermission([['get_resource_permission_groups']])) {
      try {
        await this.getWorkspacePermissionGroups();
      } catch (e) {
        console.error(e);
        return e;
      }
    }

    this.$nextTick(() => {
      // Set default minimum_permission_group_level to lowest level, if not defined yet
      if (this.product && !this.product.minimum_permission_group_level && this.filteredPermissionGroups.length > 0) {
        this.product.minimum_permission_group_level = this.filteredPermissionGroups.reduce((min, p) => {
          return p.level < min ? p.level : min;
        }, this.filteredPermissionGroups[0].level);
      }

      this.isMounted = true;
    });
  },

  methods: {
    async getWorkspacePermissionGroups (options = {}) {
      this.$set(this, 'groups', []);

      let groups = await this.$ws.get('v2', '/workspace_permission_groups', options);
      groups = groups.map(group => {
        return new PermissionGroup().mergeResponse(group);
      }, []);

      this.$set(this, 'groups', groups);
      return this.groups;
    }
  }
};
</script>
