<template>
  <div>

    <KSubheader
      :breadcrumbs="[{
        page: 'Apparatuur',
        route: '/equipment'
      }, {
        page: 'Groepen',
        route: null
      }, {
        page: 'Overzicht',
        route: null
      }]"

      title="Groepen"
    />

    <KContainer>
      <KPortlet>
        <KPortletHead>
          <template v-slot:default>Overzicht</template>

          <template v-slot:toolbar>
            <router-link
              v-if="$auth.hasPermission('create_equipment_groups') === true"
              to="/equipment/groups/create"
            >
              <KButton
                tag="a"
                variant="outline-brand"
                icon="plus"
              >
                Groep aanmaken
              </KButton>
            </router-link>
          </template>
        </KPortletHead>
        <KPortletBody>

          <div class="row">
            <div class="col-12">
              <div class="kt-input-icon kt-input-icon--left">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Search..."
                  @keyup.enter="$refs['datatable'].search"
                  @keyup="searchDebounced"
                >
                <span class="kt-input-icon__icon kt-input-icon__icon--left">
                  <span>
                    <i>
                      <font-awesome-icon
                        :icon="['far', 'search']"
                      />
                    </i>
                  </span>
                </span>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-12 datatable datatable-wrapper">
              <datatable
                ref="datatable"
                :url="datatable.url"
                :fields="datatable.fields"
                :search-fields="datatable.searchFields"
                :on-pagination-data="onPaginationData"
                :transform="transform"
              >
                <!-- Actions -->
                <div
                  slot="actions"
                  slot-scope="props"
                  class="datatable-actions"
                >
                  <router-link
                    v-if="$auth.hasPermission('update_equipment_groups')"
                    :to="`/equipment/groups/view/${props.rowData._meta.guid}`"
                  >
                    <KButton
                      :icon="['fad', 'edit']"
                      tag="a"
                      variant="label-brand"
                    />
                  </router-link>

                  <KButton
                    v-if="$auth.hasPermission('remove_equipment_groups')"
                    :icon="['fad', 'trash']"

                    tag="a"
                    variant="label-brand"

                    @click.native.prevent="openDeleteModal(props.rowData)"
                  />
                </div>
              </datatable>

              <div class="row pagination">
                <div class="col-xs-12 col-md-6">
                  <datatable-pagination
                    ref="pagination"
                    @vuetable-pagination:change-page="onChangePage"
                  />
                </div>

                <div class="col-xs-12 col-md-6">
                  <datatable-pagination-info
                    ref="pagination.info"
                    @vuetable-paginationinfo:change-rowcount="onChangeRowCount"
                  />
                </div>
              </div>
            </div>
          </div>

        </KPortletBody>
      </KPortlet>

    </KContainer>
  </div>
</template>

<script>
// Classes
import EquipmentGroup from '@/libs/classes/equipment_group';

// Mixins
import datatable from '@/mixins/datatable';

export default {
  mixins: [datatable],
  data () {
    return {
      datatable: {
        url: '/equipment_groups',
        fields: [{
          name: 'name',
          title: 'Naam',
          sortField: 'name',
          dataClass: 'w-1/8'
        }, {
          name: '__slot:actions',
          title: '',
          titleClass: 'text-right',
          dataClass: 'text-right'
        }],

        searchFields: ['name']
      }
    };
  },
  methods: {
    transform (data) {
      if (data.data && Array.isArray(data.data)) {
        data.data = data.data.map((row) => {
          return new EquipmentGroup().mergeResponse(row);
        });
      }
      return data;
    },

    openDeleteModal (resource = null) {
      return this.$eventhub.emit('modals:confirm:open', {
        resource: resource,

        title: 'Verwijder groep',
        text: `Weet je zeker dat je groep <strong>${resource.name}</strong> wilt verwijderen?`,
        submit: async () => {
          // Remove resource
          await resource.remove();

          // Refresh datatable
          this.$refs.datatable.refresh({
            query: {
              disable_cache: true
            }
          });
        }
      });
    }
  }
};
</script>
