<template>
  <KPortlet>
    <KPortletHead>
      <template v-slot:default>Gebruikers</template>
    </KPortletHead>
    <KPortletBody class="kt-portlet__body--fit position-relative">
      <!-- Loading block -->
      <template v-if="isLoading">
        <div class="loading-container absolute">
          <div class="loading-backdrop">
            <div class="loading-block">
              <div class="blockui">
                <span>Please wait...</span>
                <span class="kt-spinner kt-spinner--v2 kt-spinner--primary" />
              </div>
            </div>
          </div>
        </div>
      </template>

      <!-- Table -->
      <div class="table-responsive">
        <table class="table mb-0">
          <thead class="thead-light thead-light--fit">
            <tr>
              <th />

              <th
                colspan="2"
                class="overflow-ellipsis text-center"
              >
                Transacties
              </th>

              <th
                colspan="2"
                class="overflow-ellipsis text-center"
              >
                Producten
              </th>

              <th
                colspan="2"
                class="overflow-ellipsis text-center"
              >
                Losse sessies
              </th>

              <th
                colspan="2"
                class="overflow-ellipsis text-center"
              >
                Sessiekrediet
              </th>

              <th
                colspan="2"
                class="overflow-ellipsis text-center"
              >
                Pakketten
              </th>
            </tr>
          </thead>
          <thead class="thead-light thead-light--fit">
            <tr>
              <th class="overflow-ellipsis">Gebruiker</th>

              <!-- Transactions -->
              <th class="table-field-currency overflow-ellipsis">Aantal</th>
              <th class="table-field-currency overflow-ellipsis">Bedrag</th>

              <!-- Products -->
              <th class="table-field-currency overflow-ellipsis">Aantal</th>
              <th class="table-field-currency overflow-ellipsis">Bedrag</th>

              <!-- Sessions -->
              <th class="table-field-currency overflow-ellipsis">Aantal</th>
              <th class="table-field-currency overflow-ellipsis">Bedrag</th>

              <!-- Session credits -->
              <th class="table-field-currency overflow-ellipsis">Aantal</th>
              <th class="table-field-currency overflow-ellipsis">Bedrag</th>

              <!-- Bundles -->
              <th class="table-field-currency overflow-ellipsis">Aantal</th>
              <th class="table-field-currency overflow-ellipsis">Bedrag</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="user in tableUsers"
              :key="`user.row.${user.guid}`"
            >
              <td class="overflow-ellipsis">{{ user.name }}</td>

              <!-- Transactions -->
              <td class="table-field-currency overflow-ellipsis">{{ (objectPath.get(user, 'sales.total_salesorders') || 0) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(objectPath.get(user, 'sales.total_revenue_incl') || 0) }}</td>

              <!-- Products -->
              <td class="table-field-currency overflow-ellipsis">{{ (objectPath.get(user, 'sales.types.products.total') || 0) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(objectPath.get(user, 'sales.types.products.revenue') || 0) }}</td>

              <!-- Sessions -->
              <td class="table-field-currency overflow-ellipsis">{{ (objectPath.get(user, 'sales.types.sessions.total') || 0) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(objectPath.get(user, 'sales.types.sessions.revenue') || 0) }}</td>

              <!-- Session credits -->
              <td class="table-field-currency overflow-ellipsis">{{ (objectPath.get(user, 'sales.types.credit_products.total') || 0) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(objectPath.get(user, 'sales.types.credit_products.revenue') || 0) }}</td>

              <!-- Bundles -->
              <td class="table-field-currency overflow-ellipsis">{{ ((objectPath.get(user, 'sales.types.bundles.total') + objectPath.get(user, 'sales.types.subscriptions.total') + objectPath.get(user, 'sales.types.treatments.total') + objectPath.get(user, 'sales.types.product_bundles.total')) || 0) }}</td>
              <td class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency((objectPath.get(user, 'sales.types.bundles.revenue') + objectPath.get(user, 'sales.types.subscriptions.revenue') + objectPath.get(user, 'sales.types.treatments.revenue') + objectPath.get(user, 'sales.types.product_bundles.revenue')) || 0) }}</td>
            </tr>
          </tbody>
          <tfoot class="thead-light thead-light--fit">
            <tr>
              <th class="overflow-ellipsis">Totaal</th>

              <!-- Transactions -->
              <th class="table-field-currency overflow-ellipsis">{{ getTotal('sales.total_salesorders') }}</th>
              <th class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getTotal('sales.total_revenue_incl')) }}</th>

              <!-- Products -->
              <th class="table-field-currency overflow-ellipsis">{{ getTotal('sales.types.products.total') }}</th>
              <th class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getTotal('sales.types.products.revenue')) }}</th>

              <!-- Sessions -->
              <th class="table-field-currency overflow-ellipsis">{{ getTotal('sales.types.sessions.total') }}</th>
              <th class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getTotal('sales.types.sessions.revenue')) }}</th>

              <!-- Session credits -->
              <th class="table-field-currency overflow-ellipsis">{{ getTotal('sales.types.credit_products.total') }}</th>
              <th class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getTotal('sales.types.credit_products.revenue')) }}</th>

              <!-- Bundles -->
              <th class="table-field-currency overflow-ellipsis">{{ (getTotal('sales.types.bundles.total') + getTotal('sales.types.subscriptions.total') + getTotal('sales.types.treatments.total') + getTotal('sales.types.product_bundles.total')) }}</th>
              <th class="table-field-currency overflow-ellipsis">&euro; {{ $format.currency(getTotal('sales.types.bundles.revenue') + getTotal('sales.types.subscriptions.revenue') + getTotal('sales.types.treatments.revenue') + getTotal('sales.types.product_bundles.revenue')) }}</th>
            </tr>
          </tfoot>
        </table>
      </div>
    </KPortletBody>
  </KPortlet>
</template>

<script>
// Libraries
import objectPath from 'object-path';

export default {
  props: {
    users: {
      type: Array,
      default: null
    },
    userSales: {
      type: Array,
      default: null
    }
  },
  data () {
    return {
      isLoading: false,

      objectPath
    };
  },
  computed: {
    tableUsers: {
      get () {
        let users = [];
        if (this.users && this.users.length > 0) {
          // Insert all users
          this.users.forEach(user => {
            // Get sales
            if (this.userSales) {
              user.sales = this.userSales.find(m => m.employee_guid === user.guid);
            }

            // Add user to array
            users.push(user);
          });
        }
        return users;
      }
    }
  },

  methods: {
    getTotal (key) {
      const users = this.tableUsers;
      if (users && users.length > 0) {
        return users.filter(user => typeof objectPath.get(user, key) !== typeof undefined).map(user => objectPath.get(user, key), []).reduce((a, b) => a + b, 0);
      }
      return 0;
    }
  }
};
</script>
