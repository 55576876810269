<template>
  <div>

    <div class="row form-group mb-0">
      <div class="col-12">
        <label>Opmerking <sup>Verplicht</sup></label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i><font-awesome-icon :icon="['fad', 'comment-dots']" /></i>
            </span>
          </div>
          <input type="text" class="form-control" v-model.trim="$v.stock_batch.remark.$model" v-on:change="save" :class="[{'is-invalid': $v.stock_batch.remark.$error}]" :disabled="disabled" />
        </div>
      </div>
    </div>

  </div>
</template>

<script>
// Classes
import StockBatch from '@/libs/classes/stock_batch';

// Validations
import { required } from 'vuelidate/lib/validators'

export default {
  props: {
    action: {
      type: String,
      default: 'CREATE'
    },

    stock_batch: StockBatch,
    save: Function,

    disabled: Boolean,
    placeholder: {
      type: Boolean,
      default: true
    }
  },
  validations() {
    let validations = {};

    if (this.action === 'CREATE') {
      validations.stock_batch = {
        remark: {
          required
        }
      }
    }

    return validations;
  }
}
</script>
