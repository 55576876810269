<template>
  <div>
    <div class="row">
      <div class="col-12 datatable datatable-wrapper">
        <datatable ref="datatable"
          :url="datatable.url"
          :fields="datatable.fields"
          :searchFields="datatable.searchFields"
          :onPaginationData="onPaginationData"
          :onRowClicked="onRowClicked"
          :transform="transform"
          :css="datatable.css"
        >
          <div class="datatable-checkbox" slot="checkbox" slot-scope="props">
            <label class="kt-checkbox kt-checkbox--success" :class="[{'kt-checkbox--danger': errors.indexOf('equipment_group_guids') !== -1}]">
							<input type="checkbox" :checked="(session.equipment_group_guids.indexOf(props.rowData.guid) !== -1)" v-on:click.prevent="onRowClicked(props.rowData)">
							<span></span>
						</label>
          </div>
        </datatable>

        <div class="row pagination">
          <div class="col-xs-12 col-md-6">
            <datatable-pagination ref="pagination"
              v-on:vuetable-pagination:change-page="onChangePage"
            ></datatable-pagination>
          </div>

          <div class="col-xs-12 col-md-6">
            <datatable-pagination-info ref="pagination.info"
              v-on:vuetable-paginationinfo:change-rowcount="onChangeRowCount"
            ></datatable-pagination-info>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Classes
import EquipmentGroup from '@/libs/classes/equipment_group';
import BundleProductSession from '@/libs/classes/bundle_product.session';

// Mixins
import datatable from '@/mixins/datatable';

export default {
  mixins: [datatable],
  props: {
    session: BundleProductSession
  },
  data() {
    return {
      options: null,
      errors: [],

      datatable: {
        url: "/equipment_groups",
        fields: [{
          title: 'Naam',
          name: 'name',
          sortField: "name",
          dataClass: "cursor-pointer w-1/8"
        }, {
          name: '__slot:checkbox',
          title: '',
          titleClass: 'text-right',
          dataClass: 'cursor-pointer text-right'
        }],

        searchFields: ['name']
      }
    }
  },

  methods: {
    async validate () {
      this.$set(this, 'errors', []);
      if (!this.session.equipment_group_guids) {
        this.errors.push('equipment_group_guids');
      }

      if(this.session.equipment_group_guids.length === 0) {
        this.errors.push('equipment_group_guids');
      }

      return (this.errors.length === 0);
    },

    onRowClicked (equipment_group) {
      this.$set(this, 'errors', []);
      if (!this.session.equipment_group_guids) this.session.equipment_group_guids = [];

      const groupIndex = this.session.equipment_group_guids.indexOf(equipment_group.guid);
      if (groupIndex !== -1) {
        this.session.equipment_group_guids.splice(groupIndex, 1);
        this.session.equipment_groups = this.session.equipment_groups.filter(g => g.guid !== equipment_group.guid);
      } else {
        this.session.equipment_group_guids.push(equipment_group.guid);
        this.session.equipment_groups.push(equipment_group);
      }
    },
    transform (data) {
      if(data.data && Array.isArray(data.data)) {
        data.data.forEach((row, idx) => data.data[idx] = new EquipmentGroup().mergeResponse(data.data[idx]));
      }

      return data;
    }
  }
}
</script>
