<template>
  <KAsideMenu>
    <router-link
      v-slot="{ href, route, navigate, isActive, isExactActive }"
      to="/dashboard"
    >
      <KAsideMenuItem
        :icon="['fad', 'home']"
        :route="href"
        :active="isItemActive(route)"
      >
        Dashboard
      </KAsideMenuItem>
    </router-link>

    <KAsideMenuSubmenu
      v-if="$auth.hasPermission([['shop']])"
      ref="submenu.sales"
      :title="'Kassa'"

      :icon="['fad', 'shopping-cart']"
      :active="isMenuActive('sales')"
    >

      <router-link
        v-slot="{ href, route, navigate, isActive, isExactActive }"
        to="/sales/shop"
      >
        <KAsideMenuItem
          :route="href"
          :active="isItemActive(route)"
          bullet
        >
          Verkopen
        </KAsideMenuItem>
      </router-link>

      <router-link
        v-slot="{ href, route, navigate, isActive, isExactActive }"
        to="/sales/salesorders"
      >
        <KAsideMenuItem
          :route="href"
          :active="isItemActive(route)"
          bullet
        >
          Transacties
        </KAsideMenuItem>
      </router-link>

    </KAsideMenuSubmenu>

    <router-link
      v-slot="{ href, route, navigate, isActive, isExactActive }"
      v-if="$auth.hasPermission([['get_customers']])"
      to="/customers/overview"
    >
      <KAsideMenuItem
        :icon="['fad', 'users']"
        :route="href"
        :active="isItemActive(route)"
      >
        Klanten
      </KAsideMenuItem>
    </router-link>

    <KAsideMenuSubmenu
      v-if="$auth.hasPermission([['get_products']])"
      ref="submenu.products"
      :title="'Producten'"

      :icon="['fad', 'tags']"
      :active="isMenuActive('products')"
    >

      <router-link
        v-slot="{ href, route, navigate, isActive, isExactActive }"
        v-if="$auth.hasPermission([['get_products']])"
        to="/products/overview"
      >
        <KAsideMenuItem
          :route="href"
          :active="isItemActive(route)"
          bullet
        >
          Overzicht
        </KAsideMenuItem>
      </router-link>

      <router-link
        v-slot="{ href, route, navigate, isActive, isExactActive }"
        v-if="$auth.hasPermission([['get_pricelists']])"
        to="/products/pricelists/overview"
      >
        <KAsideMenuItem
          :route="href"
          :active="isItemActive(route)"
          bullet
        >
          Prijslijsten
        </KAsideMenuItem>
      </router-link>

      <router-link
        v-slot="{ href, route, navigate, isActive, isExactActive }"
        v-if="$auth.hasPermission([['get_product_groups']])"
        to="/products/groups/overview"
      >
        <KAsideMenuItem
          :route="href"
          :active="isItemActive(route)"
          bullet
        >
          Groepen
        </KAsideMenuItem>
      </router-link>

      <router-link
        v-slot="{ href, route, navigate, isActive, isExactActive }"
        v-if="$auth.hasPermission([['get_credit_products']])"
        to="/products/credit/overview"
      >
        <KAsideMenuItem
          :route="href"
          :active="isItemActive(route)"
          bullet
        >
          Sessiekrediet
        </KAsideMenuItem>
      </router-link>

      <KAsideMenuSubmenu
        v-if="$auth.hasPermission([['get_stock_mutations']])"
        ref="submenu.stock"

        :title="'Voorraadbeheer'"
        :active="isMenuActive('stock')"

        bullet
      >
        <router-link
          v-slot="{ href, route, navigate, isActive, isExactActive }"
          to="/products/stock/count/overview"
        >
          <KAsideMenuItem
            :route="href"
            :active="isItemActive(route)"
            bullet
          >
            Inventarisatie
          </KAsideMenuItem>
        </router-link>

        <router-link
          v-slot="{ href, route, navigate, isActive, isExactActive }"
          to="/products/stock/mutations/overview"
        >
          <KAsideMenuItem
            :route="href"
            :active="isItemActive(route)"
            bullet
          >
            Mutaties
          </KAsideMenuItem>
        </router-link>

      </KAsideMenuSubmenu>

    </KAsideMenuSubmenu>

    <KAsideMenuSubmenu
      v-if="$auth.hasPermission([['get_bundle_products']])"
      ref="submenu.bundles"

      :title="'Pakketten'"
      :icon="['fad', 'boxes']"
      :active="isMenuActive('bundles')"
    >
      <router-link
        v-slot="{ href, route, navigate, isActive, isExactActive }"
        v-if="$auth.hasPermission([['get_bundle_products']])"
        to="/bundles/overview"
      >
        <KAsideMenuItem
          :route="href"
          :active="isItemActive(route)"
          bullet
        >
          Bundels
        </KAsideMenuItem>
      </router-link>

      <router-link
        v-slot="{ href, route, navigate, isActive, isExactActive }"
        v-if="$auth.hasPermission([['get_bundle_products']])"
        to="/bundles/subscriptions/overview"
      >
        <KAsideMenuItem
          :route="href"
          :active="isItemActive(route)"
          bullet
        >
          Abonnementen
        </KAsideMenuItem>
      </router-link>

      <router-link
        v-slot="{ href, route, navigate, isActive, isExactActive }"
        v-if="$auth.hasPermission([['get_bundle_products']])"
        to="/bundles/treatments/overview"
      >
        <KAsideMenuItem
          :route="href"
          :active="isItemActive(route)"
          bullet
        >
          Behandelingen
        </KAsideMenuItem>
      </router-link>

      <router-link
        v-slot="{ href, route, navigate, isActive, isExactActive }"
        v-if="$auth.hasPermission([['get_bundle_products']])"
        to="/bundles/product/overview"
      >
        <KAsideMenuItem
          :route="href"
          :active="isItemActive(route)"
          bullet
        >
          Product bundels
        </KAsideMenuItem>
      </router-link>
    </KAsideMenuSubmenu>

    <KAsideMenuSubmenu
      v-if="$auth.hasPermission([['get_equipments', 'get_session_products', 'get_pricelists', 'get_equipment_groups']])"
      ref="submenu.equipment"

      :title="'Apparatuur'"
      :icon="['fad', 'link']"

      :active="isMenuActive('equipment')"
    >
      <router-link
        v-slot="{ href, route, navigate, isActive, isExactActive }"
        v-if="$auth.hasPermission([['get_equipments']])"
        to="/equipment/overview"
      >
        <KAsideMenuItem
          :route="href"
          :active="isItemActive(route)"
          bullet
        >
          Overzicht
        </KAsideMenuItem>
      </router-link>

      <router-link
        v-slot="{ href, route, navigate, isActive, isExactActive }"
        v-if="$auth.hasPermission([['get_session_products']])"
        to="/equipment/sessions/overview"
      >
        <KAsideMenuItem
          :route="href"
          :active="isItemActive(route)"
          bullet
        >
          Sessies
        </KAsideMenuItem>
      </router-link>

      <router-link
        v-slot="{ href, route, navigate, isActive, isExactActive }"
        v-if="$auth.hasPermission([['get_pricelists']])"
        to="/equipment/pricelists/overview"
      >
        <KAsideMenuItem
          :route="href"
          :active="isItemActive(route)"
          bullet
        >
          Tarieven
        </KAsideMenuItem>
      </router-link>

      <router-link
        v-slot="{ href, route, navigate, isActive, isExactActive }"
        v-if="$auth.hasPermission([['get_equipment_groups']])"
        to="/equipment/groups/overview"
      >
        <KAsideMenuItem
          :route="href"
          :active="isItemActive(route)"
          bullet
        >
          Groepen
        </KAsideMenuItem>
      </router-link>
    </KAsideMenuSubmenu>

    <KAsideMenuSection :icon="undefined">Winkel</KAsideMenuSection>
    <KAsideMenuSubmenu
      v-if="$auth.hasPermission([['stats']])"
      ref="submenu.statistics"

      :title="'Statistieken'"
      :icon="['fad', 'analytics']"

      :active="isMenuActive('statistics')"
    >
      <router-link
        v-slot="{ href, route, navigate, isActive, isExactActive }"
        v-if="$auth.hasPermission([['stats']])"
        to="/statistics/totals"
      >
        <KAsideMenuItem
          :route="href"
          :active="isItemActive(route)"
          bullet
        >
          Totalen
        </KAsideMenuItem>
      </router-link>

      <router-link
        v-slot="{ href, route, navigate, isActive, isExactActive }"
        v-if="$auth.hasPermission([['stats']])"
        to="/statistics/daily"
      >
        <KAsideMenuItem
          :route="href"
          :active="isItemActive(route)"
          bullet
        >
          Dagelijks
        </KAsideMenuItem>
      </router-link>

      <router-link
        v-slot="{ href, route, navigate, isActive, isExactActive }"
        v-if="$auth.hasPermission([['stats']])"
        to="/statistics/monthly"
      >
        <KAsideMenuItem
          :route="href"
          :active="isItemActive(route)"
          bullet
        >
          Maandelijks
        </KAsideMenuItem>
      </router-link>

      <router-link
        v-slot="{ href, route, navigate, isActive, isExactActive }"
        v-if="$auth.hasPermission([['stats']])"
        to="/statistics/users"
      >
        <KAsideMenuItem
          :route="href"
          :active="isItemActive(route)"
          bullet
        >
          Gebruikers
        </KAsideMenuItem>
      </router-link>

      <router-link
        v-slot="{ href, route, navigate, isActive, isExactActive }"
        v-if="$auth.hasPermission([['stats']])"
        to="/statistics/vat_overview"
      >
        <KAsideMenuItem
          :route="href"
          :active="isItemActive(route)"
          bullet
        >
          BTW overzicht
        </KAsideMenuItem>
      </router-link>
    </KAsideMenuSubmenu>

    <router-link
      v-slot="{ href, route, navigate, isActive, isExactActive }"
      v-if="$auth.hasPermission([['get_users']])"
      to="/users"
    >
      <KAsideMenuItem
        :route="href"
        :active="isItemActive(route)"
        :icon="['fad', 'users']"
      >
        Gebruikers
      </KAsideMenuItem>
    </router-link>

    <KAsideMenuSubmenu
      v-if="$auth.hasPermission(['get_cash_registers', 'get_salesorders', 'get_cash_register_openings'])"
      ref="submenu.cash_registers"

      :title="'Kassabeheer'"
      :icon="['fad', 'cash-register']"
      :active="isMenuActive('cash_registers')"
    >
      <router-link
        v-slot="{ href, route, navigate, isActive, isExactActive }"
        v-if="$auth.hasPermission([['get_cash_registers']])"
        to="/cash_registers/overview"
      >
        <KAsideMenuItem
          :route="href"
          :active="isItemActive(route)"
          bullet
        >
          Overzicht
        </KAsideMenuItem>
      </router-link>

      <router-link
        v-slot="{ href, route, navigate, isActive, isExactActive }"
        v-if="$auth.hasPermission([['get_salesorders']])"
        to="/cash_registers/salesorders/overview"
      >
        <KAsideMenuItem
          :route="href"
          :active="isItemActive(route)"
          bullet
        >
          Transacties
        </KAsideMenuItem>
      </router-link>

      <router-link
        v-slot="{ href, route, navigate, isActive, isExactActive }"
        v-if="$auth.hasPermission([['get_cash_register_openings']])"
        to="/cash_registers/closures/overview"
      >
        <KAsideMenuItem
          :route="href"
          :active="isItemActive(route)"
          bullet
        >
          Afsluitingen
        </KAsideMenuItem>
      </router-link>
    </KAsideMenuSubmenu>

    <router-link
      v-slot="{ href, route, navigate, isActive, isExactActive }"
      v-if="$auth.hasPermission([['get_masters']])"
      to="/hardware/overview"
    >
      <KAsideMenuItem
        :route="href"
        :active="isItemActive(route)"
        :icon="['fad', 'microchip']"
      >
        Hardware
      </KAsideMenuItem>
    </router-link>

    <router-link
      v-slot="{ href, route, navigate, isActive, isExactActive }"
      v-if="$auth.hasPermission([['update_stores']])"
      to="/settings/store"
    >
      <KAsideMenuItem
        :icon="['fad', 'cogs']"
        :route="href"
        :active="isActive"
      >
        Instellingen
      </KAsideMenuItem>
    </router-link>

  </KAsideMenu>
</template>

<script>
export default {
  props: {
    isMenuActive: {
      type: Function,
      default: () => null
    },
    isItemActive: {
      type: Function,
      default: () => null
    }
  },
  methods: {
    closeSubmenus () {
      Object.keys(this.$refs).filter(k => k.includes('submenu.')).forEach((submenu) => {
        if (this.$refs[submenu] && this.$refs[submenu].toggleMenu) this.$refs[submenu].toggleMenu(false);
      });
    }
  }
};
</script>
