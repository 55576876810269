<template>
  <div>

    <div class="row form-group mb-0">
      <div class="col-12 col-md-6 offset-md-3">
        <label>Startbedrag <sup>Verplicht</sup></label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i><font-awesome-icon :icon="['fad', 'euro-sign']" /></i>
            </span>
          </div>
          <input type="number" class="form-control" v-model.number.lazy="$v.cash_register_opening.open_amount_cash_formatted.$model" :class="[{'is-invalid': $v.cash_register_opening.open_amount_cash_formatted.$error}]" />
          <div class="input-group-append">
            <span class="input-group-text cursor-pointer"
              v-on:click.prevent="$eventhub.emit('modals:calculator:open', {
                input: 'open_amount'
              })"
            >
              <i class="kt-font-focus"><font-awesome-icon :icon="['fad', 'calculator']" /></i>
            </span>
          </div>
        </div>
      </div>
    </div>

    <KSeparator class="mt-3 mb-3" />

    <div class="row form-group mb-0">
      <div class="col-12">
        <label>Opmerking</label>
        <textarea class="form-control" v-model.trim="cash_register_opening.open_remark"></textarea>
      </div>
    </div>

  </div>
</template>

<script>
// Classes
import CashRegister from '@/libs/classes/cash_register';
import CashRegisterOpening from '@/libs/classes/cash_register.opening';

// Validations
import { required, minValue } from 'vuelidate/lib/validators'
import { currency } from '@/libs/validators'

export default {
  props: {
    cash_register: CashRegister,
    cash_register_opening: CashRegisterOpening,
    meta: Object
  },
  validations: {
    cash_register_opening: {
      open_amount_cash_formatted: {
        required,
        currency,
        minValue: minValue(0)
      }
    }
  },

  mounted () {
    // Set open_amount_cash to cash_register.latest_opening.close_amount_cash, because that is expected
    if(this.cash_register.latest_opening && typeof this.cash_register.latest_opening.close_amount_cash === 'number') this.cash_register_opening.open_amount_cash = this.cash_register.latest_opening.close_amount_cash;

    // Listeners
    this.$eventhub.on('modals:calculator:submit', this.onCalculatorSubmit);
  },
  beforeDestroy () {
    // Remove listeners
    this.$eventhub.off('modals:calculator:submit', this.onCalculatorSubmit);
  },

  methods: {
    onCalculatorSubmit (options = {}) {
      if (options && options.input && options.value) {
        if (options.input === 'open_amount') this.cash_register_opening.open_amount = options.value;
      }
    }
  }
}
</script>
