import Abstract from './abstract';

class SecondScreens extends Abstract {
  constructor (guid = null) {
    super(guid);
    this.uri = '/second_screens';
  }
};

export default SecondScreens;
