// Libraries
import { merge } from 'lodash';
import { apiWS as ws } from '@/libs/ws';

// Classes
import Resource from './resource';
import PermissionDocument from './permission_document';

class User extends Resource {
  constructor (guid = null) {
    super(guid);
    this.uri = '/users';

    let defaultObject = {
      username: null,
      email: null,

      permissions: {},

      firstname: null,
      lastname: null,
      gender: null,

      date_of_birth: null,
      phone: null,
      employee_id: null,

      address: {
        line1: null,
        city: null,
        country: null,
        postalCode: null,
        houseNumber: null
      },

      settings: {
        session_lock_timeout: (1 * 60)
      },

      workspaces: [],
      stores: []
    };

    merge(this, defaultObject);
  }

  // Getters & Setters
  get name () {
    // Return username when firstname & lastname is not found
    if (!this.firstname && !this.lastname) return this.username;
    return [this.firstname, this.lastname].join(' ');
  }
  get initials () {
    let initials = this.name.match(/\b\w/g) || [];
    return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
  }

  // Methods
  async getPermissions (options = {}) {
    this.permissions = await ws.get('v1', `${this.uri}/${this.guid}/permissions`, options);
    return this.permissions;
  }

  // Super methods
  mergeResponse (response = {}) {
    super.mergeResponse(response);

    // Don't merge the stores and workspaces
    if (response.workspaces) this.workspaces = response.workspaces;
    if (response.stores) this.stores = response.stores;

    return this;
  }

  async create (body = {}, options = {}) {
    // Save permissions from body for later on
    const permissions = Object.assign({}, body.permissions);

    // Create user first
    delete body.permissions;
    const response = await super.create(body, options);
    response.permissions = permissions;

    // Create workspace permissions document
    const permissionDocument = new PermissionDocument().mergeResponse({
      resource_guid: response._meta.guid,
      resource_storage: 'users',
      inherit_from_system: response.permissions.permission_group_guid
    });
    const permissionDocumentResponse = await permissionDocument.create(permissionDocument.formatBody(), options);
    response.permissions.permission_guid = permissionDocumentResponse._meta.guid;

    return response;
  }

  async update (mutations = [], options = {}) {
    const promises = [];

    // Check if permissions.permission_group_guid is changed
    if (this.permissions && this.permissions.permission_guid) {
      let permissionGroupMutation = mutations.find(m => m.field === 'permissions.permission_group_guid');
      if (permissionGroupMutation && permissionGroupMutation.action === 'set_field') {
        // Update workspace permissions document
        promises.push(new PermissionDocument(this.permissions.permission_guid).update([{
          action: 'set_field',
          field: 'inherit_from_system',
          value: permissionGroupMutation.value
        }]));
      }
    }
    mutations = mutations.filter(m => m.field !== 'permissions.permission_group_guid');

    // Do normal update
    if (mutations.length) {
      promises.push(super.update(mutations, options));
    }

    // Wait for promises to finish
    await Promise.all(promises);

    return this;
  }

  async populate (type = 'get', options = {}) {
    // Get permissions
    await this.getPermissions(options);

    return this;
  }
};

export default User;
