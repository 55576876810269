var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isMounted === false)?_c('KContainer',[_c('div',{staticClass:"row mt-3"},[_c('div',{staticClass:"col-12 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4"},[_c('div',{staticClass:"loading-container"},[_c('div',{staticClass:"loading-block"},[_c('div',{staticClass:"blockui ml-auto mr-auto w-100"},[_c('span',[_vm._v("Gegevens ophalen...")]),_vm._v(" "),_c('span',{staticClass:"kt-spinner kt-spinner--v2 kt-spinner--primary"})])])])])])]):_c('div',[_c('KSubheader',{attrs:{"title":_vm.bundleProduct.name,"breadcrumbs":[{
      page: 'Pakketten',
      route: null
    }, {
      page: 'Bundels',
      route: '/bundles/overview'
    }, {
      page: _vm.bundleProduct.name,
      route: null
    }]}}),_vm._v(" "),_c('KContainer',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-portlet-menu"},[_c('KPortlet',[_c('KPortletBody',[_c('div',{staticClass:"kt-widget kt-widget--general-1"},[_c('div',{staticClass:"kt-media kt-media--brand kt-media--md kt-media--circle"},[_c('span',{staticClass:"kt-badge kt-badge--username kt-badge--lg kt-badge--dark kt-badge--bold"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":_vm.bundleIcon}})],1)])]),_vm._v(" "),_c('div',{staticClass:"kt-widget__wrapper"},[_c('div',{staticClass:"kt-widget__label"},[_c('span',{staticClass:"kt-widget__title"},[_vm._v(_vm._s(_vm.bundleProduct.name))])])])])]),_vm._v(" "),_c('div',{staticClass:"kt-portlet__separator"}),_vm._v(" "),_c('KPortletBody',[_c('ul',{staticClass:"kt-nav kt-nav--bolder kt-nav--fit-ver kt-nav--v4",attrs:{"role":"tablist"}},[_c('router-link',{attrs:{"to":"settings"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var href = ref.href;
    var route = ref.route;
    var navigate = ref.navigate;
    var isActive = ref.isActive;
    var isExactActive = ref.isExactActive;
return [_c('li',{class:['kt-nav__item', {'active': isActive}]},[_c('a',{staticClass:"kt-nav__link",attrs:{"role":"tab"},on:{"click":function($event){$event.preventDefault();return _vm.$router.push(href)}}},[_c('span',{staticClass:"kt-nav__link-icon"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['fad', 'cogs']}})],1)]),_vm._v(" "),_c('span',{staticClass:"kt-nav__link-text"},[_vm._v("Instellingen")])])])]}}])}),_vm._v(" "),(_vm.hasSessions)?_c('router-link',{attrs:{"to":"sessions"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var href = ref.href;
    var route = ref.route;
    var navigate = ref.navigate;
    var isActive = ref.isActive;
    var isExactActive = ref.isExactActive;
return [_c('li',{class:['kt-nav__item', {'active': isActive}]},[_c('a',{staticClass:"kt-nav__link",attrs:{"role":"tab"},on:{"click":function($event){$event.preventDefault();return _vm.$router.push(href)}}},[_c('span',{staticClass:"kt-nav__link-icon"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['fad', 'stopwatch']}})],1)]),_vm._v(" "),_c('span',{staticClass:"kt-nav__link-text"},[_vm._v("Sessies")])])])]}}],null,false,107396937)}):_vm._e(),_vm._v(" "),(_vm.hasProducts)?_c('router-link',{attrs:{"to":"products"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var href = ref.href;
    var route = ref.route;
    var navigate = ref.navigate;
    var isActive = ref.isActive;
    var isExactActive = ref.isExactActive;
return [_c('li',{class:['kt-nav__item', {'active': isActive}]},[_c('a',{staticClass:"kt-nav__link",attrs:{"role":"tab"},on:{"click":function($event){$event.preventDefault();return _vm.$router.push(href)}}},[_c('span',{staticClass:"kt-nav__link-icon"},[_c('i',[_c('font-awesome-icon',{attrs:{"icon":['fad', 'tags']}})],1)]),_vm._v(" "),_c('span',{staticClass:"kt-nav__link-text"},[_vm._v("Producten")])])])]}}],null,false,1932088272)}):_vm._e()],1)])],1)],1),_vm._v(" "),_c('div',{staticClass:"col-12 col-sm"},[_c('router-view',{key:_vm.$route.fullPath,attrs:{"bundle-product":_vm.bundleProduct},on:{"update:bundleProduct":function($event){_vm.bundleProduct=$event},"update:bundle-product":function($event){_vm.bundleProduct=$event}}})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }