import Abstract from './abstract';

import { merge } from 'lodash';

// Stock Batch Mutations is different from the Stock Mutations, because this is used to create a StockBatch
class StockBatchMutations extends Abstract {
  constructor (guid = null) {
    super(guid);

    let defaultObject = {
      type: null,
      product_name: null,
      product_guid: null,
      quantity: null
    };

    merge(this, defaultObject);
  }

  // Methods
  formatBody (type = 'get', options = {}) {
    let body = super.formatBody(type, options);

    // Set body to only the essentials
    body = {
      product_name: body.product_name,
      product_guid: body.product_guid,
      quantity: body.quantity
    };

    if (this.type === 'count') {
      body.stock = this.stock;
    }

    return body;
  }

  // Getters & Setters
  get VUID () {
    return `stock_batch.mutation.${this.product_guid}`;
  }
}

export default StockBatchMutations;
