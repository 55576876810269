<template>
  <KModal ref="modal" size="xl" v-keydown="{'escape': close}">
    <template v-slot:header>
      <h5 class="modal-title">
        Sessie aanmaken
      </h5>
      <KButton type="button" variant="light" class="close" icon="times" v-on:click.native.prevent="close" />
    </template>

    <template v-slot:default>

      <KWizard ref="wizard"
        :clickable="false"
      >
        <KWizardStep
          title="Groepen"
        >
          <!-- Select equipment groups -->
          <equipment-groups-step ref="equipmentGroupsStep"
            :session="session"
          ></equipment-groups-step>
        </KWizardStep>

        <KWizardStep
          title="Dagen"
        >
          <!-- Select days -->
          <days-step ref="daysStep"
            :session="session"
          ></days-step>
        </KWizardStep>

        <KWizardStep
          title="Sessie tijden"
        >
          <!-- Session time -->
          <session-time-step ref="sessionTimeStep"
            :session="session"
          ></session-time-step>
        </KWizardStep>

        <KWizardStep
          title="Aantal sessies"
        >
          <!-- Session amount -->
          <session-amount-step ref="sessionAmountStep"
            :session="session"
          ></session-amount-step>
        </KWizardStep>
      </KWizard>

    </template>

    <template v-slot:footer>
      <KButton v-if="(isMounted && $refs.wizard && $refs.wizard.currentStep !== 0)" variant="outline-focus mr-auto" v-on:click.native.prevent="previousStep">Vorige</KButton>

      <KButton variant="secondary" v-on:click.native.prevent="close">Annuleren</KButton>

      <KButton v-if="(isMounted && $refs.wizard && $refs.wizard.currentStep !== ($refs.wizard.steps.length - 1))" variant="focus" v-on:click.native.prevent="nextStep">Volgende</KButton>
      <KButton v-if="(isMounted && $refs.wizard && $refs.wizard.currentStep === ($refs.wizard.steps.length - 1))" variant="success" @click.native.prevent="submitForm">Toevoegen</KButton>
    </template>
  </KModal>
</template>

<script>
// Libraries
import { cloneDeep } from 'lodash';

// Classes
import BundleProductSession from '@/libs/classes/bundle_product.session';

// Components
import equipmentGroupsStep from '@/components/bundles/modals/configure-session/components/equipment_groups.step';
import daysStep from '@/components/bundles/modals/configure-session/components/days.step';
import sessionTimeStep from '@/components/bundles/modals/configure-session/components/session_time.step';
import sessionAmountStep from '@/components/bundles/modals/configure-session/components/session_amount.step';

export default {
  data () {
    return {
      isMounted: false,

      session: null,
      options: {}
    }
  },
  components: {
    equipmentGroupsStep,
    daysStep,
    sessionTimeStep,
    sessionAmountStep
  },

  mounted () {
    // Listeners
    this.$eventhub.on('modals:bundles:configure-session:open', this.open);
    this.$eventhub.on('modals:bundles:configure-session:close', this.close);
  },
  beforeDestroy () {
    // Remove listeners
    this.$eventhub.off('modals:bundles:configure-session:open', this.open);
    this.$eventhub.off('modals:bundles:configure-session:close', this.close);
  },

  methods: {
    async validate() {
      const validationArray = [];

      // Equipment groups
      if(this.$refs.wizard.currentStep === 0) {
        if (this.$refs['equipmentGroupsStep'] && await this.$refs['equipmentGroupsStep'].validate() === false) return false;
      }

      // Session time
      if (this.$refs.wizard.currentStep === 2) {
        validationArray.push(this.$refs['sessionTimeStep'].$v);
      }

      // Session amount
      if (this.$refs.wizard.currentStep === 3) {
        validationArray.push(this.$refs['sessionAmountStep'].$v);
      }

      // Execute validations
      if(validationArray.length > 0) {
        validationArray.forEach(v => v && v.$touch && v.$touch())
        if(validationArray.filter(v => v && v.$invalid).length !== 0) return false;
      }

      return true;
    },
    async submitForm () {
      if (await this.validate() === true) {
        // Submit
        this.$eventhub.emit('modals:bundles:configure-session:submit', Object.assign({}, {
          options: this.options
        }, {
          session: this.session
        }))

        this.close();
      }
    },

    async previousStep() {
      if(this.$refs.wizard) {
        return this.$refs.wizard.previousStep();
      }
    },
    async nextStep() {
      if(this.$refs.wizard) {
        let valid = await this.validate();
        if(valid) this.$refs.wizard.nextStep();
      }
    },

    open (options = {}) {
      this.$set(this, 'isMounted', false);

      // Set options
      this.$set(this, 'options', options);

      // Check if editing a session
      if (options.session && options.session instanceof BundleProductSession) {
        this.$set(this, 'session', cloneDeep(options.session));
      } else {
        const newBundle = new BundleProductSession();

        // Create random guid
        newBundle.guid = Math.random();

        // Add available days
        for (let day = 0; day <= 6; day++) {
          newBundle.available_times.push({
            day: day,
            start: 0,
            end: 86400
          });
        }

        this.$set(this, 'session', newBundle);
      }

      this.$refs['modal'].show();

      this.$nextTick(() => {
        this.$set(this, 'isMounted', true);
      });
    },
    close () {
      this.$refs['modal'].hide();
    }
  }
}
</script>
