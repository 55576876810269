<template>
  <KPortlet>
    <KPortletHead>
      <template v-slot:default>Mutaties</template>
    </KPortletHead>
    <KPortletBody>

      <div class="row">
        <div class="col-12">
          <div class="kt-input-icon kt-input-icon--left">
            <input type="text" class="form-control" placeholder="Search..." @keyup.enter="$refs['datatable'].search" @keyup="searchDebounced" id="generalSearch">
            <span class="kt-input-icon__icon kt-input-icon__icon--left">
              <span>
                <i>
                  <font-awesome-icon
                  :icon="['far', 'search']"
                  ></font-awesome-icon>
                </i>
              </span>
            </span>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-12 datatable datatable-wrapper">
          <datatable ref="datatable"
            :url="datatable.url"
            :fields="datatable.fields"
            :searchFields="datatable.searchFields"
            :onPaginationData="onPaginationData"
            :transform="transform"
          >
            <div class="mutation-label" slot="mutation-label" slot-scope="props">
              <KButton v-if="props.rowData.type === 'start'" tag="span" variant="label-focus" class="btn-block">Start</KButton>
              <KButton v-else-if="props.rowData.type === 'count'" tag="span" variant="label-focus" class="btn-block">Inventarisatie</KButton>
              <KButton v-else-if="props.rowData.type === 'manual'" tag="span" variant="label-focus" class="btn-block">Mutatie</KButton>
              <KButton v-else-if="props.rowData.type === 'salesorder'" tag="span" variant="label-brand" class="btn-block">Verkoop</KButton>
              <KButton v-else tag="span" variant="label-warning" class="btn-block btn-upper">Unknown</KButton>
            </div>
          </datatable>

          <div class="row pagination">
            <div class="col-xs-12 col-md-6">
              <datatable-pagination ref="pagination"
                v-on:vuetable-pagination:change-page="onChangePage"
              ></datatable-pagination>
            </div>

            <div class="col-xs-12 col-md-6">
              <datatable-pagination-info ref="pagination.info"
                v-on:vuetable-paginationinfo:change-rowcount="onChangeRowCount"
              ></datatable-pagination-info>
            </div>
          </div>
        </div>
      </div>

    </KPortletBody>
  </KPortlet>
</template>

<script>
// Libraries
import moment from 'moment-timezone';

// Classes
import Product from '@/libs/classes/product';
import StockMutation from '@/libs/classes/stock_mutation';

// Mixins
import datatable from '@/mixins/datatable';

export default {
  mixins: [datatable],
  props: {
    product: Product
  },
  data() {
    return {
      datatable: {
        url: `/stock_mutations/product/${this.$route.params.guid}`,
        fields: [{
          title: 'Type',
          name: '__slot:mutation-label',
          sortField: 'type',
          titleClass: 'text-center',
          dataClass: 'w-1/8'
        }, {
          title: 'Oude voorraad',
          name: 'previous_stock',
          sortField: 'previous_stock',
          titleClass: 'text-center',
          dataClass: 'w-2/8 text-center'
        }, {
          title: 'Mutatie',
          name: 'quantity_mutation',
          sortField: 'quantity_mutation',
          titleClass: 'text-center',
          dataClass: ' w-2/8 text-center'
        }, {
          title: 'Nieuwe voorraad',
          name: 'stock',
          sortField: 'stock',
          titleClass: 'text-center',
          dataClass: ' w-2/8 text-center'
        }, {
          title: 'Datum',
          name: '_meta.created',
          sortField: '_meta.created',
          titleClass: 'text-center',
          dataClass: 'w-1/8 text-center',
          callback: (value) => moment(value).format('LLL')
        }],

        searchFields: ['type']
      }
    }
  },
  methods: {
    transform (data) {
      if(data.data && Array.isArray(data.data)) {
        data.data.forEach((row, idx) => data.data[idx] = new StockMutation().mergeResponse(data.data[idx]));
      }

      return data;
    }
  }
}
</script>
