<template>
  <KModal
    v-keydown="{'escape': close }"
    ref="modal"

    :title="modal.title"

    centered
    size="lg"
    scrollable
  >
    <template v-slot:default>
      <div class="row">
        <div class="col-12 offset-lg-1 col-lg-10">

          <!-- Input -->
          <div class="row form-group mb-0">
            <div class="col-12">
              <label>Bedrag</label>
              <div class="input-group cs-shop-payment-method-input">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <i><font-awesome-icon :icon="['fad', 'euro-sign']" /></i>
                  </span>
                </div>
                <input
                  ref="paymentValueInput"

                  v-model.number.lazy="$v.payment_value_formatted.$model"
                  :class="['form-control', {'is-invalid': $v.payment_value_formatted.$error}]"

                  type="number"
                >
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-3">
              <KButton
                variant="focus"
                class="btn-block btn-lg"
                @click.native.prevent="setPaymentValue(5)"
              >
                &euro; 5,00
              </KButton>
            </div>
            <div class="col-3">
              <KButton
                variant="focus"
                class="btn-block btn-lg"
                @click.native.prevent="setPaymentValue(10)"
              >
                &euro; 10,00
              </KButton>
            </div>
            <div class="col-3">
              <KButton
                variant="focus"
                class="btn-block btn-lg"
                @click.native.prevent="setPaymentValue(20)"
              >
                &euro; 20,00
              </KButton>
            </div>
            <div class="col-3">
              <KButton
                variant="focus"
                class="btn-block btn-lg"
                @click.native.prevent="setPaymentValue(50)"
              >
                &euro; 50,00
              </KButton>
            </div>
          </div>

        </div>
      </div>
    </template>

    <template v-slot:footer>
      <KButton
        variant="secondary"
        @click.native.prevent="close"
      >
        Annuleren
      </KButton>

      <KButton
        v-if="payment_method && payment_method.transaction"
        variant="focus"
        @click.native.prevent="submitForm"
      >
        In rekening brengen
      </KButton>
    </template>
  </KModal>
</template>

<script>
// Libraries
import shop from '@/libs/shop';
import { required } from 'vuelidate/lib/validators';
import { currency } from '@/libs/validators';

// Classes
import SalesOrder from '@/libs/classes/salesorder';
import PaymentTransaction from '@/libs/classes/payment_transaction';

export default {
  props: {
    salesorder: SalesOrder
  },
  data () {
    return {
      payment_method: null,
      payment_methods: [{
        method: 'cash',
        title: 'Contant'
      }, {
        method: 'card',
        title: 'PIN'
      }, {
        method: 'on_account',
        title: 'Op rekening'
      }],

      form: {
        transaction: {
          value: null
        }
      },

      modal: {
        title: null
      }
    };
  },
  computed: {
    payment_value_formatted: {
      get () {
        if (this.form.transaction.value === null) return null;
        return this.$format.currencyToInput(this.form.transaction.value);
      },
      set (value) {
        if (typeof value === 'string') value = Number(value);
        this.form.transaction.value = this.$calculate.price(value, true);
      }
    }
  },
  validations: {
    payment_value_formatted: {
      required,
      currency,
      minValue: function (value) {
        return value > 0;
      },
      maxValue: function (value) {
        if (this.salesorder !== null && this.payment_method.transaction.guid !== null && this.payment_method.method === 'on_account') {
          if (this.form.transaction.value > (this.salesorder.outstanding_balance + this.payment_method.transaction.amount)) {
            return false;
          }
        }

        return true;
      }
    }
  },

  mounted () {
    // Listeners
    this.$eventhub.on('modals:shop:payment-method:open', this.open);
    this.$eventhub.on('modals:shop:payment-method:close', this.close);
  },
  beforeDestroy () {
    // Remove listeners
    this.$eventhub.off('modals:shop:payment-method:open', this.open);
    this.$eventhub.off('modals:shop:payment-method:close', this.close);
  },

  methods: {
    async validate () {
      const validationArray = [];
      validationArray.push(this.$v);

      // Execute validations
      if (validationArray.length > 0) {
        validationArray.forEach(v => v && v.$touch && v.$touch());
        if (validationArray.filter(v => v && v.$invalid).length !== 0) return false;
      }

      return true;
    },
    async submitForm () {
      if (await this.validate() === true) {
        if (this.payment_method && this.payment_method.transaction) {
          this.$eventhub.emit('shop:cart:set-loading', true);
          try {
            if (this.payment_method.transaction.guid !== null) {
              // Remove transaction when it already exists, because it's a new transaction (because of timestamp etc)
              await shop.removeTransaction(this.payment_method.transaction.guid);
            }
            await shop.addTransaction(this.payment_method.method, this.form.transaction.value);
          } catch (e) {
            console.error(e);
          }
          this.$eventhub.emit('shop:cart:set-loading', false);

          this.close();
        }
      }
    },

    setPaymentValue (value) {
      this.$set(this.form.transaction, 'value', this.$calculate.price(value, true));
    },

    open (options = {}) {
      if (typeof options === 'object' && options.method) {
        let method = this.payment_methods.find(m => m.method === options.method);
        if (typeof method === 'object' && method !== null) {
          // Set options
          this.$set(this, 'payment_method', method);
          this.$set(this.modal, 'title', method.title);

          // Check if payment transaction is added, because else it's going to be a new one
          if (options.transaction && options.transaction instanceof PaymentTransaction) {
            this.$set(this.payment_method, 'transaction', options.transaction);
            this.$set(this.form.transaction, 'value', options.transaction.amount);
          } else {
            this.$set(this.payment_method, 'transaction', new PaymentTransaction());
            this.$set(this.form.transaction, 'value', null);
          }

          this.$v.$reset();
          this.$refs.modal.show();

          this.$nextTick(() => {
            this.$refs['paymentValueInput'].focus();
          });
        }
      }
    },
    close () {
      if (typeof this.$refs.modal !== typeof undefined) this.$refs.modal.hide();
    }
  }
};
</script>
