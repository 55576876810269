<template>
  <div v-if="isMounted">
    <KPortlet>
      <KPortletHead>
        <template v-slot:default>Tweede scherm koppelen</template>
      </KPortletHead>
      <KPortletBody>
        <div class="row form-group">
          <div class="col-12">
            <label>Naam <sup>Verplicht</sup></label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i><font-awesome-icon :icon="['fad', 'text']" /></i>
                </span>
              </div>
              <input
                v-model.trim="$v.second_screen.name.$model"
                :class="['form-control', {'is-invalid': $v.second_screen.name.$error}]"
                type="text"
              >
            </div>
          </div>
        </div>

        <div class="row form-group">
          <div class="col-12">
            <label>Koppel code <sup>Verplicht</sup></label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i><font-awesome-icon :icon="['fad', 'link']" /></i>
                </span>
              </div>
              <input
                v-model.trim="$v.second_screen.code.$model"
                :class="['form-control', {'is-invalid': $v.second_screen.code.$error}]"
                type="text"
                maxlength="4"
                style="font-size: 30px; letter-spacing: 10px;"
              >
            </div>
          </div>
        </div>
      </KPortletBody>
      <div class="kt-portlet__foot">
        <div class="row align-items-center">
          <div class="offset-6 col-6 offset-lg-10 col-lg-2 kt-align-right">
            <KButton
              variant="success"
              class="btn-md btn-block btn-tall btn-wide btn-bold btn-upper"
              @click.native.prevent="submitForm"
            >
              Koppelen
            </KButton>
          </div>
        </div>
      </div>
    </KPortlet>

  </div>
</template>

<script>
// Classes
import SecondScreen from '@/libs/classes/second_screens';

// Validations
import { required } from 'vuelidate/lib/validators';

export default {
  data () {
    return {
      isMounted: false,

      second_screen: new SecondScreen()
    };
  },

  async mounted () {
    this.$nextTick(() => {
      this.isMounted = true;
    });
  },

  methods: {
    validate () {
      const validationArray = [];
      validationArray.push(this.$v);

      // Execute validations
      if (validationArray.length > 0) {
        validationArray.forEach(v => v && v.$touch && v.$touch());
        if (validationArray.filter(v => v && v.$invalid).length !== 0) return false;
      }

      return true;
    },

    async submitForm () {
      if (this.validate() === true) {
        await this.second_screen.create(this.second_screen.formatBody());
        this.$router.push(`/settings/store/second-screens/view/${this.second_screen.guid}`);
      }
    }
  },

  validations: {
    second_screen: {
      name: {
        required
      },
      code: {
        required
      }
    }
  }
};
</script>
