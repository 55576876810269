<template>
  <KAside
    @mouseenter.native="hoverOnAside(true)"
    @mouseleave.native="hoverOnAside(false)"
    @click.native.prevent="hoverOnAside(true)"
  >
    <!-- Aside brand -->
    <KAsideBrand
      :logo="require('@/assets/images/logo/caesium.white.full.svg')"
      href="#"
    />

    <!-- Aside menu (store or workspace) -->
    <template>
      <workspaceMenu
        v-if="$stores.activeStore._meta.guid === 'workspace_manager'"

        ref="workspaceMenu"

        :is-menu-active="isMenuActive"
        :is-item-active="isItemActive"
      />

      <storeMenu
        v-else

        ref="storeMenu"

        :is-menu-active="isMenuActive"
        :is-item-active="isItemActive"
      />
    </template>

  </KAside>
</template>

<script>
// Components
import storeMenu from './store.vue';
import workspaceMenu from './workspace.vue';

export default {
  components: {
    storeMenu,
    workspaceMenu
  },

  mounted () {
    this.$eventhub.on('caesium:menu:closeSubmenus', this.closeSubmenus);
    this.$eventhub.on('caesium:menu:minimize', this.minimizeMenu);
    this.$eventhub.on('caesium:menu:unminimize', this.unminimizeMenu);
  },
  beforeDestroy () {
    this.$eventhub.off('caesium:menu:closeSubmenus', this.closeSubmenus);
    this.$eventhub.off('caesium:menu:minimize', this.minimizeMenu);
    this.$eventhub.off('caesium:menu:unminimize', this.unminimizeMenu);
  },

  methods: {
    closeSubmenus () {
      let menu = null;
      if (this.$stores.activeStore._meta.guid === 'workspace_manager') menu = 'workspaceMenu';
      else menu = 'storeMenu';

      if (this.$refs[menu] && this.$refs[menu].closeSubmenus) this.$refs[menu].closeSubmenus();
    },

    hoverOnAside (hover = null) {
      if ((hover === true || hover === null) && document.body.classList.contains('kt-aside--minimize')) {
        document.body.classList.add('kt-aside--minimize-hover');
        document.body.classList.remove('kt-aside--minimize');
      } else if ((hover === false || hover === null) && document.body.classList.contains('kt-aside--minimize-hover')) {
        document.body.classList.remove('kt-aside--minimize-hover');
        document.body.classList.add('kt-aside--minimize');

        this.closeSubmenus();
      }
    },

    minimizeMenu () {
      document.body.classList.add('kt-aside--minimize');
    },
    unminimizeMenu () {
      document.body.classList.remove('kt-aside--minimize');
      document.body.classList.remove('kt-aside--minimize-hover');
    },

    isMenuActive (menu) {
      let activeMenus = [];
      if (typeof this.$route !== typeof undefined) {
        let routes = [this.$route];
        routes = routes.concat(this.$route.matched);

        routes.forEach(route => {
          if (typeof route.meta === 'object' && typeof route.meta.menu === 'object' && typeof route.meta.menu.submenu !== typeof undefined) {
            if (typeof route.meta.menu.submenu === 'string') activeMenus.push(route.meta.menu.submenu);
            else if (Array.isArray(this.$route.meta.menu.submenu)) activeMenus = activeMenus.concat(this.$route.meta.menu.submenu);
          }
        });
      }

      return activeMenus.indexOf(menu) !== -1;
    },
    isItemActive (route) {
      if (this.$route === route) {
        return true;
      }

      if (this.$route.matched.some(r => r.name === route.name)) {
        return true;
      }

      let routes = [this.$route];
      routes = routes.concat(this.$route.matched);

      let activeItems = [];
      routes.forEach(route => {
        if (typeof route.meta.menu === 'object' && typeof route.meta.menu.item !== typeof undefined) {
          if (typeof route.meta.menu.item === 'string') activeItems.push(route.meta.menu.item);
          else if (Array.isArray(route.meta.menu.item)) activeItems = activeItems.concat(route.meta.menu.item);
        }
      });

      return activeItems.indexOf(route.path) !== -1;
    }
  }
};
</script>

<style>
.kt-aside__brand .kt-aside__brand-logo img {
  width:90%;
  height:auto;
}
</style>
