<template v-if="sales_order.customer">
  <div class="row pb-1 pt-1">
    <div class="col-12 col-lg-6 col-xl-3">
      <div class="kt-widget kt-widget--general-1">
        <div class="kt-media kt-media--brand kt-media--lg kt-media--circle">
          <span class="kt-badge kt-badge--username kt-badge--xl kt-badge--dark kt-badge--bold">{{ sales_order.customer.initials }}</span>
        </div>
        <div class="kt-widget__wrapper">
          <div class="kt-widget__label">
            <div class="row">
              <div class="col mb-auto mt-auto">
                <router-link
                  :to="`/customers/view/${sales_order.customer.guid}`"
                  class="kt-widget__title"
                >
                  {{ sales_order.customer.name }}
                </router-link>
              </div>
              <div class="col-3 text-right mb-auto mt-auto">
                <i
                  class="cursor-pointer"
                  @click.prevent="removeCustomer"
                >
                  <font-awesome-icon
                    :icon="['fad', 'times']"
                    style="font-size:1.5rem;"
                  />
                </i>
              </div>
            </div>
            <span
              v-if="sales_order.customer.balance"
              class="kt-widget__desc"
            >
              <div class="row">
                <div class="col-8">Sessiekrediet</div>
                <div class="col-4 d-flex flex-row justify-content-between">
                  <div class="text-left">&euro;</div>
                  <div class="text-right">{{ $format.currency(sales_order.customer.balance.session_credits) }}</div>
                </div>
              </div>

              <div :class="['row', {'kt-font-danger font-weight-bold': sales_order.customer.balance.on_account > 0}]">
                <div class="col-8">Op rekening</div>
                <div class="col-4 d-flex flex-row justify-content-between">
                  <div class="text-left">&euro;</div>
                  <div class="text-right">{{ $format.currency(sales_order.customer.balance.on_account) }}</div>
                </div>
              </div>
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 mt-3 col-lg-6 mt-lg-0 col-xl-6 offset-xl-3">
      <div
        v-if="session_history.isLoading === true"
        class="loading-container mt-auto mb-auto"
      >
        <div class="loading-block">
          <div class="blockui">
            <span>Sessiegeschiedenis ophalen...</span>
            <span class="kt-spinner kt-spinner--v2 kt-spinner--primary" />
          </div>
        </div>
      </div>

      <div
        v-else
        class="kt-widget kt-widget--general-1"
      >
        <div class="kt-media kt-media--brand kt-media--lg kt-media--circle">
          <span class="kt-badge kt-badge--username kt-badge--xl kt-badge--dark kt-badge--bold">
            <i>
              <font-awesome-icon
                :icon="['far', 'history']"
                size="2x"
              />
            </i>
          </span>
        </div>
        <div class="kt-widget__wrapper">
          <div class="kt-widget__label">
            <div class="row">
              <div class="col-6 col-xl-4 mt-auto mb-auto">
                <span class="kt-widget__title">Laatste sessie</span>
              </div>
              <div class="col mb-auto mt-auto">
                <span v-if="session_history.data">{{ moment(session_history.data.started_timestamp).format('DD MMMM YYYY [om] HH:mm') }}</span>
              </div>
            </div>
            <span class="kt-widget__desc">
              <div class="row">
                <div class="col-6 col-xl-4 mt-auto mb-auto">Laatste sessieduur</div>
                <div class="col mt-auto mb-auto">{{ (session_history.data ? (`${(session_history.data.time / 60)} minuten`) : '-') }}</div>
              </div>

              <div class="row">
                <div class="col-6 col-xl-4 mt-auto mb-auto">Laatste cabine</div>
                <div class="col mt-auto mb-auto">{{ (session_history.data ? (`${session_history.data.equipment.name} (${session_history.data.equipment.room})`) : '-') }}</div>
              </div>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Libraries
import moment from 'moment-timezone';
import shop from '@/libs/shop';

// Classes
import Equipment from '@/libs/classes/equipment';
import SalesOrder from '@/libs/classes/salesorder';

export default {
  props: {
    equipment: {
      type: Equipment,
      default: null
    },
    sales_order: {
      type: SalesOrder,
      default: null
    }
  },
  data () {
    return {
      session_history: {
        isLoading: false,
        data: null
      },

      moment
    };
  },

  async mounted () {
    // Get session history of customer
    this.getLastSession();
  },

  methods: {
    async getLastSession (options = {}) {
      this.$set(this.session_history, 'data', null);

      if (this.sales_order && this.sales_order.customer) {
        this.$set(this.session_history, 'isLoading', true);

        const session = await this.sales_order.customer.getLastSession(options);
        this.$set(this.session_history, 'data', session);

        this.$set(this.session_history, 'isLoading', false);
      }

      return this.session_history.data;
    },

    async removeCustomer () {
      return shop.removeCustomer();
    }
  }
};
</script>
