<template>
  <KContainer>
    <div class="row mt-3">
      <div class="col-12 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
        <div class="loading-container">
          <div class="loading-block">
            <div class="blockui ml-auto mr-auto w-100">
              <span>Gegevens ophalen...</span>
              <span class="kt-spinner kt-spinner--v2 kt-spinner--primary" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </KContainer>
</template>

<script>
// Libraries
import shop from '@/libs/shop';

export default {
  async mounted () {
    // Check if shop session exists
    let cashRegister = await shop.getCashRegister();
    if (cashRegister) {
      return this.$router.replace(`/sales/cash_registers/view/${cashRegister.guid}/salesorders`);
    }

    shop.removeSession();
    return this.$router.push({
      name: '/sales/cash_registers/overview',
      params: {
        redirect: `/sales/salesorders`
      }
    });
  }
};
</script>
