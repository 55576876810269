<template>
  <div
    v-if="question"
    class="row"
  >
    <div class="col-12 text-center border-bottom pb-3">
      <h3>{{ question.question }}</h3>
    </div>

    <div
      class="mt-3"
      style="float:none; margin: 0 auto;"
    >
      <div class="kt-radio-list answers">
        <label
          v-for="(answer, idx) in question.answers"
          :key="`question.row.${idx}`"
          :class="['kt-radio', {
            'kt-radio--success': $v.question.answer.$model === answer.value,
            'kt-radio--danger': $v.question.answer.$error
          }]"
        >
          <input
            v-model="$v.question.answer.$model"
            :name="`question.answer`"
            :value="answer.value"
            type="radio"
          >
          {{ answer.answer }}
          <span />
        </label>
      </div>
    </div>
  </div>
</template>

<style scoped>
.answers .kt-radio {
  font-size: 1.5rem;
}

.answers .kt-radio > span {
  height: 26px;
  width: 26px;
  left: -10px;
}

.answers .kt-radio > span::after {
  width: 12px;
  height: 12px;
  margin-left: -6px;
  margin-top: -6px;
}
</style>

<script>
// Validations
import { required } from 'vuelidate/lib/validators';

export default {
  props: {
    question: {
      type: Object,
      default: null
    }
  },
  validations: {
    question: {
      answer: {
        required
      }
    }
  }
};
</script>
