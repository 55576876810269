<template>
  <div>
    <KSubheader
      :breadcrumbs="[{
        page: 'Producten',
        route: '/products/overview'
      }, {
        page: 'Voorraadbeheer'
      }, {
        page: 'Mutaties',
        route: '/products/stock/mutations'
      }, {
        page: 'Aanmaken',
        route: null
      }]"

      title="Mutaties"
    />

    <KContainer v-if="isMounted">
      <KPortlet>
        <KPortletHead>
          <template v-slot:default>Mutatie aanmaken</template>
        </KPortletHead>
        <KPortletBody>

          <information-form
            ref="informationForm"
            :stock_batch="stockbatch"
            :save="save"
          />

          <KSeparator class="mt-3 mb-3" />

          <div class="row form-group mb-0">
            <div class="col-12">
              <label>Producten</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <i><font-awesome-icon :icon="['fad', 'search']" /></i>
                  </span>
                </div>
                <v-select
                  ref="searchProducts"

                  v-model="search.product"
                  :options="search.products"
                  :filterable="false"
                  :min-input-length="1"

                  label="name"
                  class="form-control"

                  @search="fetchOptions"
                >
                  <template
                    slot="option"
                    slot-scope="option"
                  >
                    <span>{{ option.name }}</span>
                  </template>

                  <template
                    slot="spinner"
                    slot-scope="spinner"
                  >
                    <div
                      v-if="spinner.loading"
                      class=" kt-spinner kt-spinner--v2 kt-spinner--primary"
                    />
                  </template>
                </v-select>
              </div>
            </div>
          </div>

          <template v-if="stockbatch && stockbatch.mutations && stockbatch.mutations.length">
            <KSeparator class="mt-3 mb-3" />

            <div class="mutations">
              <mutation-line
                v-for="(mutation, idx) in stockbatch.mutations"
                :ref="mutation.VUID"
                :key="mutation.VUID"
                :mutation="mutation"
                :first-row="idx === 0"
                :last-row="idx === (stockbatch.mutations.length - 1)"

                :remove-mutation="removeMutation"
                :save="save"
              />
            </div>
          </template>

        </KPortletBody>
        <div class="kt-portlet__foot">
          <div class="row align-items-center">
            <div class="offset-6 col-6 offset-lg-10 col-lg-2 kt-align-right">
              <KButton
                variant="primary"
                class="btn-md btn-block btn-tall btn-wide btn-bold btn-upper"
                @click.native.prevent="submitForm"
              >
                Aanmaken
              </KButton>
            </div>
          </div>
        </div>
      </KPortlet>
    </KContainer>
  </div>
</template>

<script>
// Libraries
import mutationsLib from '@/libs/mutations';
import { cloneDeep } from 'lodash';

// Classes
import StockBatch from '@/libs/classes/stock_batch';
import StockBatchMutation from '@/libs/classes/stock_batch.mutation';
import Product from '@/libs/classes/product';

// Components
import informationForm from './components/information.form';
import mutationLine from './components/mutation.line';

import vSelect from 'vue-select';
import '@/assets/sass/vue-select/vue-select.scss';

export default {
  components: {
    informationForm,
    mutationLine,

    vSelect
  },
  data () {
    return {
      isMounted: false,

      stockbatch: null,
      stockbatchCloned: null,

      search: {
        product: null,
        products: [],

        timeout: null
      }
    };
  },

  watch: {
    'search.product' (product) {
      if (product && product !== null && product instanceof Product) {
        // Create & add mutation
        const mutation = new StockBatchMutation().mergeResponse({
          product_name: product.name,
          product_guid: product.guid,
          quantity: null
        });
        this.stockbatch.mutations.push(mutation);

        // Focus on mutation quantity
        this.$nextTick(() => {
          if (this.$refs[mutation.VUID]) this.$refs[mutation.VUID][0].focus();
        });

        this.search.product = null;
      }
    }
  },

  async mounted () {
    // Check if editing a concept
    if (typeof this.$route.params.guid === 'string' && this.$route.params.guid.length > 0) {
      this.stockbatch = await new StockBatch(this.$route.params.guid).get();

      if (this.stockbatch.type !== 'manual') this.$router.push('/products/stock/mutations/overview');
      if (this.stockbatch.status === 'final') this.$router.replace(`/products/stock/mutations/view/${this.stockbatch.guid}`);

      await this.stockbatch.populate('get');
    } else {
      this.stockbatch = new StockBatch().mergeResponse({type: 'manual'});
    }

    this.$data.stockbatchCloned = cloneDeep(this.stockbatch);

    this.$set(this, 'isMounted', true);
  },

  methods: {
    async validate () {
      const validationArray = [];
      validationArray.push(this.$refs.informationForm.$v);

      // Execute validations
      if (validationArray.length > 0) {
        validationArray.forEach(v => v && v.$touch && v.$touch());
        if (validationArray.filter(v => v && v.$invalid).length !== 0) return false;
      }

      return true;
    },

    async save () {
      if (await this.validate() === true) {
        // Create stock batch if not already created
        if (!this.stockbatch._meta) {
          await this.stockbatch.create(this.stockbatch.formatBody('post'));
          this.$set(this, 'stockbatchCloned', cloneDeep(this.stockbatch));
          this.$router.replace(`/products/stock/mutations/create/${this.stockbatch.guid}`);
        } else {
          // Create mutations
          const fields = ['remark'];
          let mutations = mutationsLib.create(fields, this.stockbatchCloned.formatBody('post'), this.stockbatch.formatBody('post'));

          // Create array mutations of field `mutations`
          const arrayMutations = mutationsLib.createArrayByKey('mutations', 'product_guid', this.stockbatchCloned.formatBody('post'), this.stockbatch.formatBody('post'));

          // Combine them together
          mutations = mutations.concat(arrayMutations);

          const stockBatch = await this.stockbatch.update(mutations);

          // First, set stockbatch to null and clone it again, because Vue doesn't update properly
          this.$set(this, 'stockbatch', null);
          this.$nextTick(() => {
            this.$set(this, 'stockbatch', cloneDeep(stockBatch));
            this.$set(this, 'stockbatchCloned', cloneDeep(this.stockbatch));
          });
        }
      }

      return this.stockbatch;
    },

    async submitForm () {
      if (await this.validate() === true) {
        // Save first
        await this.save();

        // Submit
        if (this.stockbatch) {
          await this.stockbatch.submit();
          this.$router.push(`/products/stock/mutations/view/${this.stockbatch.guid}`);
        }
      }
    },

    async removeMutation (mutation) {
      if (mutation instanceof StockBatchMutation) {
        this.stockbatch.mutations = this.stockbatch.mutations.filter(m => m.VUID !== mutation.VUID);
        await this.save();
      }
    },

    async searchProducts (search) {
      // Add wildcard
      search = this.$format.addWildcardPerWord(search);

      // Configure options
      const options = {
        query: Object.assign({}, {
          q: search,
          limit: 5
        })
      };

      // Ignore guid's
      if (this.stockbatch.mutations && this.stockbatch.mutations.length) {
        options.query.ignore_guids = this.stockbatch.mutations.map(m => m.product_guid, []).join(',');
      }

      // Request
      const response = await this.$ws.get('v1', '/products', options);

      // Set data
      const data = [];
      if (response !== null) {
        if (response && Array.isArray(response)) {
          response.forEach((row, idx) => {
            response[idx] = new Product().mergeResponse(response[idx]);
            data.push(response[idx]);
          });
        }
      }

      return data;
    },

    fetchOptions (search, loading) {
      clearTimeout(this.search.timeout);
      this.$set(this.search, 'products', []);

      if (typeof search === 'string' && search.length > 0) {
        loading(true);

        this.search.timeout = setTimeout(async () => {
          loading(true);
          try {
            let data = await this.searchProducts(search);
            this.search.products = data;
          } catch (e) {
            console.error(e);
          }
          loading(false);
        }, 600);
      }
    }
  }
};
</script>
