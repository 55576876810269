var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('KSubheader',{attrs:{"title":"Groepen","breadcrumbs":[{
      page: 'Apparatuur',
      route: '/equipment'
    }, {
      page: 'Groepen',
      route: '/equipment/groups/overview'
    }, {
      page: 'Groep aanmaken',
      route: null
    }]}}),_vm._v(" "),_c('KContainer',[_c('KPortlet',[_c('KPortletHead',[_vm._v("\n        Groep aanmaken\n      ")]),_vm._v(" "),_c('KPortletBody',[_c('equipmentGroupForm',{ref:"equipmentGroupForm",attrs:{"group":_vm.group}})],1),_vm._v(" "),_c('div',{staticClass:"kt-portlet__foot"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-6 offset-lg-10 col-lg-2 kt-align-right"},[_c('KButton',{staticClass:"btn-md btn-block btn-tall btn-wide btn-bold btn-upper",attrs:{"variant":"success"},nativeOn:{"click":function($event){$event.preventDefault();return _vm.submitForm($event)}}},[_vm._v("Aanmaken")])],1)])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }