<template>
  <div>
    <div class="product-row row">
      <div class="col-12 col-lg-11">

        <div class="row form-group">
          <div class="col-10 col-lg-6 col-xl-8">
            <label :class="[{'d-lg-none': firstRow === false}]">Naam</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i><font-awesome-icon :icon="['fad', 'tag']" /></i>
                </span>
              </div>
              <input
                :value="product.product.name"

                type="text"
                class="form-control"
                disabled="disabled"
              >
            </div>
          </div>

          <div
            v-if="action !== 'VIEW'"
            class="col-2 label-margin d-lg-none"
          >
            <KButton
              variant="light"
              class="btn-block"
              @click.native.prevent="removeProduct(product)"
            >
              <font-awesome-icon icon="trash" />
            </KButton>
          </div>

          <div class="col-12 mt-3 col-lg-3 mt-lg-0 col-xl-2">
            <label :class="[{'d-lg-none': firstRow === false}]">Prijs</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i><font-awesome-icon :icon="['fad', 'euro-sign']" /></i>
                </span>
              </div>
              <input
                :value="$format.currency(product.product.price.price_incl_vat)"

                type="text"
                class="form-control"
                disabled="disabled"
              >
            </div>
          </div>

          <div class="col-12 mt-3 col-lg-3 mt-lg-0 col-xl-2">
            <label :class="[{'d-lg-none': firstRow === false}]">Aantal</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i><font-awesome-icon :icon="['fad', 'hashtag']" /></i>
                </span>
              </div>
              <input
                ref="input.quantity"

                v-model.number="$v.product.quantity.$model"
                :class="['form-control', {'is-invalid': $v.product.quantity.$error}]"
                :disabled="disabled"

                type="text"
              >
            </div>
          </div>
        </div>

      </div>

      <div
        v-if="action !== 'VIEW'"
        class="d-none d-lg-block col-1"
      >
        <KButton
          :class="['btn-block', {'label-margin': firstRow === true}]"
          :disabled="disabled"

          variant="light"

          @click.native.prevent="removeProduct(product)"
        >
          <font-awesome-icon icon="trash" />
        </KButton>
      </div>

      <KSeparator
        v-if="lastRow === false"
        class="col-12 d-lg-none"
        large
      />
    </div>
  </div>
</template>

<script>
// Classes
import BundleProductProduct from '@/libs/classes/bundle_product.product';

// Validators
import { required, minValue } from 'vuelidate/lib/validators';

export default {
  props: {
    product: {
      type: BundleProductProduct,
      default: null
    },
    firstRow: {
      type: Boolean,
      default: false
    },
    lastRow: {
      type: Boolean,
      default: false
    },

    action: {
      type: String,
      default: 'CREATE'
    },
    disabled: {
      type: Boolean,
      default: false
    },

    removeProduct: {
      type: Function,
      default: () => null
    }
  },
  validations: {
    product: {
      quantity: {
        required,
        minValue: minValue(0)
      }
    }
  },
  methods: {
    focus (input = 'quantity') {
      if (typeof this.$refs[`input.${input}`] !== typeof undefined) {
        this.$refs[`input.${input}`].focus();
      }
    }
  }
};
</script>
