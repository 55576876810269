<template>
  <div class="flex">
    <KLogin>
      <KLoginHeader>
        <div class="kt-login-v2__logo">
          <a href="#">
            <img
              src="@/assets/images/logo/caesium.full.svg"
              width="200"
              alt=""
            >
          </a>
        </div>

        <div class="kt-login-v2__signup">
          <span>{{ workspace }}</span>
          <!-- <a
            href="#"
            class="kt-link kt-font-brand"
          >
            Not your workspace?
          </a> -->
        </div>
      </KLoginHeader>

      <KLoginBody :image="require('@/assets/images/logo/caesium.svg')">
        <div class="kt-login-v2__title">
          <h3>Inloggen</h3>
        </div>

        <form
          class="kt-login-v2__form kt-form"
          @submit.prevent="signin"
        >
          <div class="form-group">
            <input
              v-model="username"

              class="form-control"
              type="text"
              placeholder="Gebruikersnaam"
              name="username"
              autocomplete="off"
            >
          </div>
          <div class="form-group">
            <input
              v-model="password"

              class="form-control"
              type="password"
              placeholder="Wachtwoord"
              name="password"
              autocomplete="off"
            >
          </div>

          <div class="kt-login-v2__actions">
            <!-- <a
              href="#"
              class="kt-link kt-link--brand"
            >
              Forgot Password ?
            </a> -->

            <KButton
              type="submit"
              variant="brand"
              class="ml-auto"
              elevate-air
              pill
            >
              Log in
            </KButton>
          </div>
        </form>
      </KLoginBody>

      <KLoginFooter>
        <div class="kt-login-v2__info">
          <a
            href="https://www.vedicium.com/"
            target="_blank"
            class="kt-link"
          >
            &copy; {{ year }} VeDicium B.V.
          </a>
        </div>

        <div class="kt-login-v2__link">
          <a
            href="#"
            class="kt-link kt-font-brand"
          >
            Privacy
          </a>
          <a
            href="#"
            class="kt-link kt-font-brand"
          >
            Voorwaarden
          </a>
          <a
            href="#"
            class="kt-link kt-font-brand"
          >
            Contact
          </a>
        </div>
      </KLoginFooter>
    </KLogin>

    <modal-error
      ref="modalError"
    />
  </div>
</template>

<style lang="scss">
@import 'keen-vue/src/assets/sass/pages/login/login-v2.scss';
</style>

<script>
// Components
import modalError from '@/components/modals/error.modal';

export default {
  components: {
    modalError
  },
  data () {
    return {
      username: null,
      password: null,
      workspace: this.$ws.workspaceName,

      year: new Date().getFullYear()
    };
  },

  methods: {
    async signin () {
      try {
        await this.$auth.signin(this.username, this.password);
      } catch (e) {
        console.error('Error while signing in:', e.info);
        this.$error.showModal(e);
      }
    }
  }
};
</script>
