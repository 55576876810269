import objectPath from 'object-path';
import _ from 'lodash';

export default class Mutations {
  static create (fields, oldObject, newObject) {
    const mutations = [];
    fields.forEach((field) => {
      const mutation = Mutations.createMutation(field, oldObject, newObject);
      if (mutation) {
        mutations.push(mutation);
      }
    });
    return mutations;
  }

  static createMutation (field, oldObject, newObject) {
    const oldField = objectPath.get(oldObject, field);
    const newField = objectPath.get(newObject, field);
    if (oldField !== newField) {
      return {
        action: 'set_field',
        field: field,
        value: newField
      };
    }
    return null;
  }

  static createArrayByKey (field, keys, oldObject, newObject) {
    const oldField = objectPath.get(oldObject, field);
    const newField = objectPath.get(newObject, field);

    // If oldField is the same as newField, no mutations are going to be created
    if (oldField === newField) {
      return null;
    }

    // Check if types changed
    // If so, that's a real change of the whole value
    if (typeof oldField !== typeof newField) {
      return [{
        action: 'set_field',
        field: field,
        value: newField
      }];
    }

    // Mutations array
    const mutations = [];

    // Set keys to array, if it's a string
    if (typeof keys === 'string') keys = [keys];

    // Create remove mutations
    oldField.forEach((item, idx) => {
      if (!newField.find((row) => keys.every((key) => row[(key)] === item[(key)]))) {
        mutations.push({
          action: 'remove_from_array_by_multiple_key_values',
          field: field,
          key_values: keys.map((key) => {
            return {key: key, value: item[(key)]};
          })
        });
      }
    });

    // Create new mutations
    newField.forEach((item, idx) => {
      // Check if a new item
      const oldItem = oldField.find((row) => keys.every((key) => row[(key)] === item[(key)]));
      if (!oldItem) {
        return mutations.push({
          action: 'array_push',
          field: field,
          value: item
        });
      }

      // Check if difference in item
      if (_.isEqual(item, oldItem) === false) {
        return mutations.push({
          action: 'set_array_item_by_multiple_key_values',
          field: field,
          key_values: keys.map((key) => {
            return {key: key, value: item[(key)]};
          }),
          value: item
        });
      }
    });

    return mutations;
  }
}
