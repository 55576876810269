<template>
  <!-- Loading -->
  <KContainer v-if="isMounted === false || customer === null">
    <div class="row mt-3">
      <div class="col-12 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
        <div class="loading-container">
          <div class="loading-block">
            <div class="blockui ml-auto mr-auto w-100">
              <span>Gegevens ophalen...</span>
              <span class="kt-spinner kt-spinner--v2 kt-spinner--primary" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </KContainer>

  <!-- Finished loading -->
  <div v-else>

    <KSubheader
      :breadcrumbs="[{
        page: 'Klanten',
        route: '/customers/overview'
      }, {
        page: customer.name,
        route: null
      }]"

      title="Klanten"
    />

    <KContainer>
      <div class="row">
        <div class="col-12 col-portlet-menu">
          <KPortlet>
            <KPortletBody>
              <div class="kt-widget kt-widget--general-1">
                <div class="kt-media kt-media--brand kt-media--md kt-media--circle">
                  <span class="kt-badge kt-badge--username kt-badge--lg kt-badge--dark kt-badge--bold">{{ customer.initials }}</span>
                </div>
                <div class="kt-widget__wrapper">
                  <div class="kt-widget__label">
                    <span class="kt-widget__title">{{ customer.name }}</span>
                    <span class="kt-widget__desc">
                      <div
                        :class="['row', {'font-weight-bold kt-font-focus cursor-pointer': canGiveInitialSessionCredit === true}]"
                        @click.prevent="openInitialSessionCreditModal"
                      >
                        <div class="col-8">Sessiekrediet</div>
                        <div class="col-4 d-flex flex-row justify-content-between">
                          <div class="text-left">&euro;</div>
                          <div class="text-right">{{ $format.currency(customer.balance.session_credits) }}</div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-8">Op rekening</div>
                        <div class="col-4 d-flex flex-row justify-content-between">
                          <div class="text-left">&euro;</div>
                          <div class="text-right">{{ $format.currency(customer.balance.on_account) }}</div>
                        </div>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </KPortletBody>
            <div class="kt-portlet__separator" />
            <KPortletBody>
              <ul
                class="kt-nav kt-nav--bolder kt-nav--fit-ver kt-nav--v4"
                role="tablist"
              >
                <router-link
                  v-slot="{ href, route, navigate, isActive, isExactActive }"
                  to="personal_information"
                >
                  <li :class="['kt-nav__item', {'active': isActive}]">
                    <a
                      class="kt-nav__link"
                      role="tab"
                      @click.prevent="$router.push(href)"
                    >
                      <span class="kt-nav__link-icon">
                        <i>
                          <font-awesome-icon :icon="['fad', 'user']" />
                        </i>
                      </span>
                      <span class="kt-nav__link-text">Persoonlijke informatie</span>
                    </a>
                  </li>
                </router-link>

                <router-link
                  v-slot="{ href, route, navigate, isActive, isExactActive }"
                  to="authentication"
                >
                  <li :class="['kt-nav__item', {'active': isActive}]">
                    <a
                      class="kt-nav__link"
                      role="tab"
                      @click.prevent="$router.push(href)"
                    >
                      <span class="kt-nav__link-icon">
                        <i><font-awesome-icon :icon="['fad', 'fingerprint']" /></i>
                      </span>
                      <span class="kt-nav__link-text">Authenticatie</span>
                    </a>
                  </li>
                </router-link>

                <router-link
                  v-slot="{ href, route, navigate, isActive, isExactActive }"
                  to="salesorders"
                >
                  <li :class="['kt-nav__item', {'active': isActive}]">
                    <a
                      class="kt-nav__link"
                      role="tab"
                      @click.prevent="$router.push(href)"
                    >
                      <span class="kt-nav__link-icon">
                        <i><font-awesome-icon :icon="['fad', 'shopping-cart']" /></i>
                      </span>
                      <span class="kt-nav__link-text">Transacties</span>
                    </a>
                  </li>
                </router-link>
              </ul>
            </KPortletBody>
          </KPortlet>
        </div>

        <div class="col-12 col-sm">
          <router-view
            :key="$route.fullPath"
            :customer.sync="customer"
          />
        </div>
      </div>

    </KContainer>

    <initial-session-credit-modal
      v-if="canGiveInitialSessionCredit"
      ref="initialSessionCreditModal"
    />

  </div>
</template>

<script>
// Libraries
import moment from 'moment-timezone';

// Classes
import Customer from '@/libs/classes/customer';
import PaymentTransaction from '@/libs/classes/payment_transaction';

// Components
import initialSessionCreditModal from '@/components/customers/modals/initialSessionCredit.modal';

export default {
  components: {
    initialSessionCreditModal
  },
  data () {
    return {
      isMounted: false,

      customer: null
    };
  },
  computed: {
    canGiveInitialSessionCredit () {
      if (this.$auth.hasPermission([['give_session_credits_to_existing_customers']]) === false) {
        return false;
      }

      if (!this.customer) {
        return false;
      }

      if (this.customer.received_init_session_credits === true) {
        return false;
      }

      if (!this.customer.balance || this.customer.balance.session_credits !== 0) {
        return false;
      }

      return true;
    }
  },

  async mounted () {
    try {
      await this.getCustomer();
    } catch (e) {
      console.error('Customer not found:', e);
      return;
    }

    this.$nextTick(() => {
      this.isMounted = true;
    });
  },

  methods: {
    async getCustomer (options = {}) {
      this.$set(this, 'customer', null);
      let customer = await new Customer(this.$route.params.guid).get(options);
      await customer.populate('get', options);
      this.$set(this, 'customer', customer);
      return this.customer;
    },

    openInitialSessionCreditModal () {
      if (this.canGiveInitialSessionCredit === true) {
        const vm = this;
        this.$eventhub.emit('modals:customers:initialSessionCredit:open', {
          async onSubmit (value) {
            // Create transaction
            const transaction = new PaymentTransaction().mergeResponse({
              payment_method_type: 'session_credits',
              sub_type: 'initial_session_credit',
              amount: (value * -1),
              customer_guid: vm.customer.guid,
              finished_timestamp: moment().utc().valueOf(),
              pending: false,
              topup: true
            });
            await transaction.create(transaction.formatBody());

            // Update user
            await vm.customer.update([{
              action: 'set_field',
              field: 'received_init_session_credits',
              value: true
            }]);

            // Get customer
            await vm.getCustomer({
              query: {
                disable_cache: true
              }
            });
          }
        });
      }
    }
  }
};
</script>
