<template>
  <div>
    <KSubheader
      :breadcrumbs="[{
        page: 'Gebruikers',
        route: '/users/overview'
      }, {
        page: 'Gebruiker aanmaken',
        route: null
      }]"

      title="Gebruikers"
    />

    <KContainer>
      <KPortlet>
        <KPortletBody class="kt-portlet__body-fit">
          <KWizard
            ref="wizard"
            :clickable="false"
          >
            <KWizardStep title="Informatie">
              <!-- Personal information -->
              <personal-information-form
                ref="personalInformationForm"

                :user="user"

                action="CREATE"
              />
            </KWizardStep>

            <KWizardStep title="Authenticatie">
              <!-- Personal information -->
              <personal-pin-timeout
                ref="personalPinTimeout"

                :user="user"

                action="CREATE"
              />
            </KWizardStep>

            <KWizardStep title="Bevoegdheden">
              <!-- Permission groups -->
              <permission-groups-form
                ref="permissionGroupsForm"

                :user="user"

                action="CREATE"
              />
            </KWizardStep>

            <KWizardStep title="Winkels">
              <!-- Stores -->
              <stores-form
                ref="storesForm"

                :user="user"

                action="CREATE"
              />
            </KWizardStep>
          </KWizard>
        </KPortletBody>
        <div class="kt-portlet__foot">
          <div class="row align-items-center">
            <!-- Buttons left -->
            <div class="col-6 col-lg-2">
              <KButton
                v-if="(isMounted && $refs.wizard && $refs.wizard.currentStep !== 0)"
                variant="outline-focus"
                class="btn-md btn-block btn-tall btn-wide btn-bold btn-upper"
                @click.native.prevent="previousStep"
              >
                Vorige
              </KButton>
            </div>

            <!-- Buttons right -->
            <div class="col-6 offset-lg-8 col-lg-2 kt-align-right">
              <!-- Next button -->
              <KButton
                v-if="(isMounted && $refs.wizard && $refs.wizard.currentStep !== ($refs.wizard.steps.length - 1))"
                variant="focus"
                class="btn-md btn-block btn-tall btn-wide btn-bold btn-upper"
                @click.native.prevent="nextStep"
              >
                Volgende
              </KButton>

              <!-- Submit button -->
              <KButton
                v-if="(isMounted && $refs.wizard && $refs.wizard.currentStep === ($refs.wizard.steps.length - 1))"
                variant="success"
                class="btn-md btn-block btn-tall btn-wide btn-bold btn-upper"
                @click.native.prevent="submitForm"
              >
                Aanmaken
              </KButton>
            </div>
          </div>
        </div>
      </KPortlet>
    </KContainer>
  </div>
</template>

<style lang="scss">
@import '@/assets/sass/caesium.wizard.scss';
</style>

<script>
// Classes
import User from '@/libs/classes/user';

// Components
import personalInformationForm from '@/components/users/components/personal-information.form';
import personalPinTimeout from '@/components/users/components/authentication/personal-pin-timeout';
import permissionGroupsForm from '@/components/users/components/permission-groups.form';
import storesForm from '@/components/users/components/stores.form';

export default {
  components: {
    personalInformationForm,
    personalPinTimeout,
    permissionGroupsForm,
    storesForm
  },
  data () {
    return {
      isMounted: false,

      user: new User().mergeResponse({
        // Password default to 'caesium'
        // Pin default to 0000
        // TODO: Change this to BE e-mails
        password: 'caesium',
        pin: '0000',

        // Set country default to NL
        address: {
          country: 'NL'
        },

        // Only when creating a user, this is used
        permissions: {
          level: null,
          permission_group_guid: null,
          permission_group_name: null
        },

        // At least add the current store
        stores: [this.$stores.activeStore.guid]
      })
    };
  },

  mounted () {
    this.$nextTick(() => {
      this.isMounted = true;
    });
  },

  methods: {
    async validate () {
      const validationArray = [];

      // Personal information
      if (this.$refs.wizard.currentStep === 0) {
        validationArray.push(this.$refs.personalInformationForm.$v);
      }

      // Authentication
      if (this.$refs.wizard.currentStep === 1) {
        validationArray.push(this.$refs.personalPinTimeout.$v);
      }

      // Permission groups
      if (this.$refs.wizard.currentStep === 2) {
        validationArray.push(this.$refs.permissionGroupsForm.$v);
      }

      // Stores
      if (this.$refs.wizard.currentStep === 3) {
        validationArray.push(this.$refs.storesForm.$v);
      }

      // Execute validations
      if (validationArray.length > 0) {
        validationArray.forEach(v => v && v.$touch && v.$touch());
        if (validationArray.filter(v => v && v.$invalid).length !== 0) return false;
      }

      // Check if username exists
      if (this.$refs.wizard.currentStep === 0) {
        // TODO: Add check for username
      }

      return true;
    },

    async previousStep () {
      if (this.$refs.wizard) {
        return this.$refs.wizard.previousStep();
      }
    },
    async nextStep () {
      if (this.$refs.wizard && await this.validate() === true) {
        return this.$refs.wizard.nextStep();
      }
    },

    async submitForm () {
      if (await this.validate() === true) {
        let response = null;
        try {
          response = await this.user.create(this.user.formatBody());
        } catch (e) {
          console.error(e);
          return;
        }

        // Push to view
        this.$router.push(`/users/view/${response.guid}?disable_cache`);
      }
    }
  }
};
</script>
