<template>
  <KSection>
    <KSectionContent bordered>
      <div class="row border-bottom pb-3">
        <div class="col-12 text-center">
          <div class="icon">
            <i>
              <font-awesome-icon
                :icon="['far', 'fingerprint']"
                :class="[{'kt-font-success': meta.set === true}]"
                :style="{'opacity': (active === true ? 0.25 : 1)}"

                size="5x"
              />
            </i>
          </div>
          <div class="mt-3">
            <!-- When scanning -->
            <template v-if="active === true">
              <span v-if="state === 'LOADING'">Even geduld a.u.b.</span>

              <!-- In action -->
              <span v-else-if="state === 'PLACE_FINGER'">Plaats vinger...</span>
              <span v-else-if="state === 'REMOVE_FINGER'">Verwijder vinger...</span>

              <!-- Completed -->
              <span v-else-if="state === 'SAVING'">Vingerafdruk opslaan...</span>

              <!-- Errors -->
              <span v-else-if="state === 'QUALITY_TOO_LOW'">Te lage kwaliteit</span>
              <span v-else-if="state === 'UNKNOWN_ERROR'">Onbekende fout</span>
            </template>

            <!-- Not scanning -->
            <template v-else>
              <span v-if="meta.set === true">Ingesteld</span>
              <span v-else>Niet ingesteld</span>
            </template>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <!-- Finger -->
        <div class="col-12">
          <label>Vinger</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i><font-awesome-icon :icon="['fad', 'hand-point-up']" /></i>
              </span>
            </div>
            <select
              v-model.trim="meta.finger"
              :disabled="(disabled || active === true)"

              class="form-control"
            >
              <option value="left_index">Wijsvinger (L)</option>
              <option value="left_thumb">Duim (L)</option>
              <option value="right_index">Wijsvinger (R)</option>
              <option value="right_thumb">Duim (R)</option>
            </select>
          </div>
        </div>

        <!-- Action buttons -->
        <div class="col-12">
          <div class="row mt-3">
            <div class="col-6">
              <KButton
                :disabled="(disabled || active === true)"

                variant="outline-primary"
                class="btn-block"

                @click.native.prevent="startEnrollment"
              >
                Scan
              </KButton>
            </div>

            <div class="col-6">
              <KButton
                v-if="(active === true)"
                key="cancelButton"
                variant="outline-caution"
                class="btn-block"
                @click.native.prevent="stopEnrollment"
              >
                Annuleren
              </KButton>
              <KButton
                v-else
                key="removeButton"
                :disabled="(disabled || meta.set !== true)"

                variant="outline-danger"
                class="btn-block"

                @click.native.prevent="removeFingerprint(meta.key)"
              >
                Verwijder
              </KButton>
            </div>
          </div>
        </div>

      </div>
    </KSectionContent>
  </KSection>
</template>

<script>
// Libraries
import { cloneDeep } from 'lodash';
import mutationsLib from '@/libs/mutations';

// Classes
import Master from '@/libs/classes/master';
import Customer from '@/libs/classes/customer';

export default {
  props: {
    master: {
      type: Master,
      default: null
    },
    customer: {
      type: Customer,
      default: null
    },
    fingerprint: {
      type: Object,
      default: null
    },

    action: {
      type: String,
      default: 'VIEW'
    },

    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      customerCloned: cloneDeep(this.customer),
      meta: {
        set: false,
        finger: 'left_index'
      },

      listeners: [],

      active: false,
      state: null,
      timeout: null
    };
  },

  mounted () {
    // Listeners
    this.$eventhub.on('customers:fingerprint:enroll', this.onFingerprintEnrollMessage);

    // Check if fingerprint is configured
    if (this.fingerprint && typeof this.fingerprint === 'object') {
      this.meta.finger = this.fingerprint.finger;
      this.meta.key = this.fingerprint.key;
      this.meta.set = true;
    }
  },
  beforeDestroy () {
    // Remove listeners
    this.$eventhub.off('customers:fingerprint:enroll', this.onFingerprintEnrollMessage);

    // Remove master listeners
    this.listeners.forEach((master, idx) => {
      if (master && master.socket && master.socket.connection) {
        master.socket.connection.off('data', this.onMasterData);
      }
    });
  },

  methods: {
    onFingerprintEnrollMessage (vueUid) {
      if (vueUid !== this._uid) {
        this.stopEnrollment();
      }
    },

    stopEnrollment () {
      this.active = false;
      clearTimeout(this.timeout);
      this.removeListener(this.master);
    },

    async startEnrollment () {
      if (this.master && this.customer) {
        // Emit that we're going to start enrollment
        this.$eventhub.emit('customers:fingerprint:enroll', this._uid);

        // Stop enrollment
        this.stopEnrollment();

        // Start fingerprint enrollment
        await this.master.socket.device('auth:fingerprint:enroll', {
          customer_guid: this.customer.guid,
          finger: this.meta.finger
        });
        this.active = true;
        this.state = 'LOADING';
        this.addListeners(this.master);
      }
    },
    async onMasterData (message) {
      if (message && message.type) {
        // Fingerprint
        if (message.type === 'auth' && message.method === 'fingerprint') {
          // Place finger
          if (message.action === 'place_finger') {
            this.state = 'PLACE_FINGER';
          }

          // Remove finger
          if (message.action === 'remove_finger') {
            this.state = 'REMOVE_FINGER';
          }

          // Complete
          if (message.action === 'complete') {
            // Enroll success
            if (message.success === true) {
              this.state = 'SAVING';

              // Remove current finger if set
              if (this.meta.set === true) {
                await this.removeFingerprint(this.meta.key);
              }

              await this.saveFinger({
                finger: this.meta.finger,
                quality: message.quality,
                key: message.key
              });

              this.state = null;
              this.active = false;

              // Set active
              this.meta.set = true;
              this.meta.quality = message.quality;
              this.meta.key = message.key;
            } else {
              // If error defined
              if (message.error) {
                this.state = message.error;
              } else {
                this.state = 'UNKNOWN_ERROR';
              }

              this.timeout = setTimeout(() => {
                this.state = null;
                this.active = false;
              }, 2000);
            }
          }
        }
      }
    },
    async saveFinger (fingerObject = {}) {
      if (!fingerObject) {
        throw new Error('Finger object not found');
      }

      if (!fingerObject.finger || !fingerObject.quality || !fingerObject.key) {
        throw new Error('Missing fingerprint fields');
      }

      // Add finger
      this.customerCloned.fingerprint_keys.push({
        finger: fingerObject.finger,
        quality: fingerObject.quality,
        key: fingerObject.key,
        device_guid: this.master.device_guid
      });

      // Only initiate the API when editing
      if (this.action === 'EDIT') {
        await this.customer.update([{
          action: 'set_field',
          field: 'fingerprint_keys',
          value: this.customerCloned.fingerprint_keys
        }]);
        this.customerCloned = cloneDeep(this.customer);
      }

      // Set fingerprint_keys when CREATE
      if (this.action === 'CREATE') {
        this.customer.fingerprint_keys = this.customerCloned.fingerprint_keys;
      }
    },
    async removeFingerprint (key = null) {
      if (key) {
        // Remove key
        this.customerCloned.fingerprint_keys = this.customerCloned.fingerprint_keys.filter(f => f.key !== key);

        // Check differences
        const fields = ['fingerprint_keys'];
        const mutations = mutationsLib.create(fields, this.customer.formatBody(), this.customerCloned.formatBody());
        if (mutations.length) {
          // Only initiate the API when editing
          if (this.action === 'EDIT') {
            await this.customer.update(mutations);
            this.customerCloned = cloneDeep(this.customer);
          }

          // Set fingerprint_keys when CREATE
          if (this.action === 'CREATE') {
            this.customer.fingerprint_keys = this.customerCloned.fingerprint_keys;
          }

          // Remove active
          this.meta.set = false;
          this.meta.key = undefined;
        }
      }
    },

    // Listeners
    addListeners (master) {
      if (master && master.socket && master.socket.connection) {
        master.socket.connection.on('data', this.onMasterData);
        this.listeners.push(master);
      }
    },
    removeListener (master) {
      if (master && master.socket && master.socket.connection) {
        master.socket.connection.off('data', this.onMasterData);
        this.listeners = this.listeners.filter(m => m.guid !== master.guid);
      }
    }
  }
};
</script>
