<template>
  <div class="mutation-row row">
    <div class="col-12 col-lg-11">
      <div class="row form-group">
        <div class="col-6 col-lg-8 col-xl-9">
          <label :class="[{'d-xl-none': firstRow === false}]">Naam</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i><font-awesome-icon :icon="['fad', 'tag']" /></i>
              </span>
            </div>
            <input type="text" class="form-control" :value="mutation.product_name" disabled="disabled" />
          </div>
        </div>

        <div class="col-4 col-lg-4 col-xl-3">
          <label :class="[{'d-xl-none': firstRow === false}]">Aantal</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i><font-awesome-icon :icon="['fad', 'hashtag']" /></i>
              </span>
            </div>
            <input type="number" ref="input.quantity" class="form-control" v-model.number="$v.mutation.quantity.$model" v-on:change="save" :class="[{'is-invalid': $v.mutation.quantity.$error}]" />
          </div>
        </div>

        <div class="col-2 label-margin d-lg-none">
          <KButton variant="light" class="btn-block" v-on:click.native.prevent="removeMutation(mutation)">
            <font-awesome-icon icon="trash" />
          </KButton>
        </div>
      </div>
    </div>

    <div class="d-none d-lg-block d-xl-none col-1">
      <KButton variant="light" class="btn-block label-margin" v-on:click.native.prevent="removeMutation(mutation)">
        <font-awesome-icon icon="trash" />
      </KButton>
    </div>

    <div class="d-none d-xl-block col-1">
      <KButton variant="light" class="btn-block" :class="[{'label-margin': firstRow === true}]" v-on:click.native.prevent="removeMutation(mutation)">
        <font-awesome-icon icon="trash" />
      </KButton>
    </div>
  </div>
</template>

<script>
// Classes
import StockBatchMutation from '@/libs/classes/stock_batch.mutation';

// Validators
import { required } from 'vuelidate/lib/validators';

export default {
  props: {
    firstRow: Boolean,
    lastRow: Boolean,

    mutation: StockBatchMutation,

    removeMutation: Function,
    save: Function
  },
  validations: {
    mutation: {
      quantity: {
        required
      }
    }
  },
  methods: {
    focus (input = 'quantity') {
      if (typeof this.$refs[`input.${input}`] !== typeof undefined) {
        this.$refs[`input.${input}`].focus();
      }
    }
  }
}
</script>
