<template>
  <div>

    <KSubheader
      title="Sessies"
      :breadcrumbs="[{
        page: 'Apparatuur',
        route: '/equipment'
      }, {
        page: 'Sessies',
        route: '/equipment/sessions'
      }, {
        page: 'Sessie aanmaken',
        route: null
      }]"
    >

    </KSubheader>

    <KContainer>

      <KPortlet>
        <KPortletHead>
          Sessie aanmaken
        </KPortletHead>
        <KPortletBody>

          <sessionForm ref="sessionForm"
            :session="session"
          ></sessionForm>

        </KPortletBody>
        <div class="kt-portlet__foot">
  				<div class="row align-items-center">
  					<div class="col-6 offset-lg-10 col-lg-2 kt-align-right">
              <KButton variant="success" class="btn-md btn-block btn-tall btn-wide btn-bold btn-upper" @click.native.prevent="submitForm">Aanmaken</KButton>
  					</div>
  				</div>
  			</div>
      </KPortlet>

    </KContainer>

  </div>
</template>

<script>
// Classes
import SessionProduct from '@/libs/classes/session_product';

// Components
import sessionForm from './components/form'

export default {
  data() {
    return {
      session: new SessionProduct().mergeResponse({
        duration: (10 * 60) // Set default duration
      })
    }
  },
  components: {
    sessionForm
  },
  methods: {
    async validate() {
      const validationArray = [];

      validationArray.push(this.$refs.sessionForm.$v);

      if(validationArray.length > 0) {
        validationArray.forEach(v => v && v.$touch && v.$touch())
        if(validationArray.filter(v => v && v.$invalid).length !== 0) return false;
      }

      return true;
    },
    async submitForm() {
      if(await this.validate() === true) {
        await this.session.create(this.session.formatBody());
        this.$router.push(`/equipment/sessions/view/${this.session.guid}`);
      }
    }
  }
}
</script>
