// Classes
import Abstract from './abstract';
import CashRegister from '@/libs/classes/cash_register';

// Libraries
import { merge } from 'lodash';
import { apiWS as ws } from '@/libs/ws';
import format from '@/libs/format';
import calculate from '@/libs/calculate';

class CashRegisterOpening extends Abstract {
  constructor (guid = null) {
    super(guid);
    this.uri = '/cash_register_openings';

    let defaultObject = {
      cash_register: null,

      // Open
      open_amount_cash: null,
      open_timestamp: null,
      open_remark: null,
      open: false,
      opened_by_user: {},

      // Close
      closed_by_user: {},
      close_amount_cash_counted: null,
      cash_amount_in: null,
      close_amount_card_counted: null,
      close_timestamp: null,
      close_remark: null
    };

    merge(this, defaultObject);
  }

  // Methods
  formatBody (type = 'get', options = {}) {
    let body = super.formatBody(type, options);

    if (type === 'post') {
      if (options.action === 'OPEN') {
        body = {
          cash_register: this.cash_register,
          open_amount_cash: this.open_amount_cash,
          open_remark: this.open_remark
        };
      }
    }

    if (type === 'patch') {
      if (options.action === 'CLOSE') {
        body = {
          close_amount_cash_counted: this.close_amount_cash_counted,
          close_amount_card_counted: this.close_amount_card_counted,
          cash_amount_in: this.cash_amount_in,
          close_remark: this.close_remark
        };
      }
    }

    return body;
  }

  async getExpectedCloseAmounts (options = {}) {
    return ws.get('v1', `${this.uri}/${this.guid}/close_amounts`, options);
  }
  async getVATsRevenue (options = {}) {
    return ws.get('v1', `${this.uri}/${this.guid}/vats_revenue`, options);
  }
  async getCashRevenue (options = {}) {
    return ws.get('v1', `${this.uri}/${this.guid}/cash_revenue`, options);
  }

  async getCashRegister (options = {}) {
    return new CashRegister(this.cash_register).get(options);
  }

  // Getters & Setters
  get open_amount_cash_formatted () {
    if (this.open_amount_cash === null) return null;
    return format.currencyToInput(this.open_amount_cash);
  }
  set open_amount_cash_formatted (value) {
    this.open_amount_cash = calculate.price(value, true);
  }

  get close_amount_cash_counted_formatted () {
    if (this.close_amount_cash_counted === null) return null;
    return format.currencyToInput(this.close_amount_cash_counted);
  }
  set close_amount_cash_counted_formatted (value) {
    this.close_amount_cash_counted = calculate.price(value, true);
  }
  get cash_amount_in_formatted () {
    if (this.cash_amount_in === null) return null;
    return format.currencyToInput(this.cash_amount_in);
  }
  set cash_amount_in_formatted (value) {
    this.cash_amount_in = calculate.price(value, true);
  }
  get close_amount_card_counted_formatted () {
    if (this.close_amount_card_counted === null) return null;
    return format.currencyToInput(this.close_amount_card_counted);
  }
  set close_amount_card_counted_formatted (value) {
    this.close_amount_card_counted = calculate.price(value, true);
  }

  // Cash amount out (instead of in)
  get cash_amount_out () {
    if (this.cash_amount_in === null) return null;
    return (this.cash_amount_in * -1);
  }
  set cash_amount_out (value) {
    this.cash_amount_in = (value * -1);
  }
  get cash_amount_out_formatted () {
    if (this.cash_amount_out === null) return null;
    return format.currencyToInput(this.cash_amount_out);
  }
  set cash_amount_out_formatted (value) {
    this.cash_amount_out = calculate.price(value, true);
  }

};

export default CashRegisterOpening;
