<template>
  <KPortlet>
    <KPortletHead>
      Winkels
    </KPortletHead>
    <KPortletBody>
      <stores-form
        ref="storesForm"

        :user="userCloned"
        :disabled="isLoading"

        action="EDIT"
      />
    </KPortletBody>
    <div class="kt-portlet__foot">
      <div class="row align-items-center">
        <div class="offset-6 col-6 offset-lg-10 col-lg-2 kt-align-right">
          <KButton
            v-click="submitForm"
            :disabled="isLoading"
            variant="warning"
            class="btn-md btn-block btn-tall btn-wide btn-bold btn-upper"
          >
            Wijzigen
          </KButton>
        </div>
      </div>
    </div>
  </KPortlet>
</template>

<script>
// Libraries
import { cloneDeep } from 'lodash';
import mutationsLib from '@/libs/mutations';

// Classes
import User from '@/libs/classes/user';

// Components
import storesForm from '@/components/users/components/stores.form';

export default {
  components: {
    storesForm
  },
  props: {
    user: {
      type: User,
      default: null
    }
  },
  data () {
    return {
      userCloned: cloneDeep(this.user),

      isLoading: false
    };
  },
  methods: {
    async validate () {
      const validationArray = [];
      validationArray.push(this.$refs.storesForm.$v);

      if (validationArray.length > 0) {
        validationArray.forEach(v => v && v.$touch && v.$touch());
        if (validationArray.filter(v => v && v.$invalid).length !== 0) return false;
      }

      return true;
    },

    async submitForm () {
      if (await this.validate()) {
        const fields = ['stores'];
        const mutations = mutationsLib.create(fields, this.user.formatBody(), this.userCloned.formatBody());
        if (mutations.length) {
          this.$set(this, 'isLoading', true);

          try {
            await this.user.update(mutations);
            this.userCloned = cloneDeep(this.user);
          } catch (e) {
            console.error(e);
          }

          this.$set(this, 'isLoading', false);
        }
      }
    }
  }
};
</script>
