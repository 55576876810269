// Classes
import Abstract from './abstract';

// Libraries
import { merge } from 'lodash';
import { apiWS as ws } from '@/libs/ws';
import WSDevice from '@/libs/ws.device';

class Master extends Abstract {
  constructor (guid = null) {
    super(guid);
    this.uri = '/masters';

    let defaultObject = {
      name: null,
      serial: null,
      socket: null,

      eoc_enabled: false,

      peripherals: null
    };

    merge(this, defaultObject);
  }

  // Getters & setters
  get isConnected () {
    if (this.socket && typeof this.socket.online === 'boolean') return this.socket.online;
    return false;
  }

  // Methods
  formatBody (type = 'get', options = {}) {
    delete this.socket;
    let body = super.formatBody(type, options);

    // Remove peripherals
    delete body.peripherals;

    return body;
  }

  async update (mutations = [], options = {}) {
    const result = await super.update(mutations, options);

    this.getSocket().device('pins:eoc:set', {
      enable: result.eoc_enabled || false
    });

    return result;
  }

  getSocket () {
    if (!this.socket) {
      this.socket = WSDevice.createSocket(this);
    }
    return this.socket;
  }

  async getSlaves (payload = {}) {
    const socket = await this.getSocket();
    let response = await socket.device('slaves:status', payload);
    return response;
  }

  async connectSlave (serial = null, options = {}) {
    const socket = this.getSocket();
    if (await socket.isOnline() === true) {
      socket.device('slaves:connect', {
        slave: serial
      });
    } else {
      throw new Error('Master is unreachable');
    }
  }

  async disconnectSlave (serial = null, options = {}) {
    const socket = this.getSocket();
    if (await socket.isOnline() === true) {
      return socket.device('slaves:disconnect', {
        slave: serial
      });
    } else {
      throw new Error('Master is unreachable');
    }
  }
}

export default Master;
