<template>
  <KModal ref="modal" title="Openstaand bedrag aflossen" centered size="lg" v-keydown="{'escape': close}">
    <template v-slot:default>
      <div class="row form-group mb-0" v-if="sales_order && sales_order.customer && sales_order.customer !== null">

        <div class="col-12 col-lg-4">
          <label>Openstaand saldo</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i><font-awesome-icon :icon="['fad', 'euro-sign']" /></i>
              </span>
            </div>
            <input type="text" class="form-control" :value="$format.currency(sales_order.customer.balance.on_account * -1)" disabled />
          </div>
        </div>

        <div class="col-12 col-lg-4">
          <label>Aflossingsbedrag</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i><font-awesome-icon :icon="['fad', 'euro-sign']" /></i>
              </span>
            </div>
            <input type="number" ref="repayValueInput" class="form-control" v-model.number.lazy="$v.repay_value_formatted.$model" :class="[{'is-invalid': $v.repay_value_formatted.$error}]" />
          </div>
        </div>

        <div class="col-12 col-lg-4">
          <label>Resterend saldo</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i><font-awesome-icon :icon="['fad', 'euro-sign']" /></i>
              </span>
            </div>
            <input type="text" class="form-control" :value="$format.currency(remaining_balance)" disabled />
          </div>
        </div>
      </div>
    </template>

    <template v-slot:footer>
      <KButton variant="secondary" v-on:click.native.prevent="close">Annuleren</KButton>
      <KButton variant="primary" v-on:click.native.prevent="addRepaymentToCart">Bevestigen</KButton>
    </template>
  </KModal>
</template>

<script>
// Libraries
import shop from '@/libs/shop';
import { required, minValue } from 'vuelidate/lib/validators'
import { currency } from '@/libs/validators';

// Classes
import SalesOrder from "@/libs/classes/salesorder"
import Customer from "@/libs/classes/customer"

export default {
  props: {
    sales_order: SalesOrder
  },
  data() {
    return {
      form: {
        repay_value: 0
      }
    }
  },
  computed: {
    repay_value_formatted: {
      get () {
        if (this.form.repay_value === null) return null;
        return this.$format.currencyToInput(this.form.repay_value);
      },
      set (value) {
        if (typeof value === 'string') value = Number(value);
        this.form.repay_value = this.$calculate.price(value, true);
      }
    },
    remaining_balance: {
      get () {
        return this.$calculate.comparePrice((this.sales_order.customer.balance.on_account * -1), this.form.repay_value);
      }
    }
  },
  validations: {
    repay_value_formatted: {
      required,
      currency,
      minValue: minValue(0),
      maxValue: function(value) {
        if (this.sales_order && this.sales_order.customer && this.sales_order.customer !== null) {
          return this.remaining_balance >= 0;
        }

        return true;
      }
    }
  },

  mounted () {
    // Listeners
    this.$eventhub.on('modals:shop:repay-on-account:open', this.open);
    this.$eventhub.on('modals:shop:repay-on-account:close', this.close);
  },
  beforeDestroy () {
    // Remove listeners
    this.$eventhub.off('modals:shop:repay-on-account:open', this.open);
    this.$eventhub.off('modals:shop:repay-on-account:close', this.close);
  },

  methods: {
    async validate() {
      const validationArray = [];
      validationArray.push(this.$v);

      // Execute validations
      if(validationArray.length > 0) {
        validationArray.forEach(v => v && v.$touch && v.$touch())
        if(validationArray.filter(v => v && v.$invalid).length !== 0) return false;
      }

      return true;
    },

    async addRepaymentToCart () {
      if (await this.validate() === true) {

        this.$eventhub.emit('shop:cart:set-loading', true);

        const promises = [];
        this.sales_order.lines.forEach((line, idx) => {
          if (line.type === 'on_account_topup') {
            promises.push(shop.removeProductFromCart(line.guid))
          }
        });

        if (promises.length) await Promise.all(promises);

        try {
          await shop.addProductToCart({
            type: 'on_account_topup',
            product_guid: `on_account_topup:${this.form.repay_value}`,
            topup_amount: this.form.repay_value,
            quantity: 1,
            amount_changeable: false,
            requires_customer: true
          })
        } catch (e) {
          console.error(e);
        }
        this.$eventhub.emit('shop:cart:set-loading', false);

        this.$nextTick(() => this.close());
      }
    },

    open () {
      // Check prop
      if (this.sales_order && this.sales_order.customer && this.sales_order.customer !== null && this.sales_order.customer instanceof Customer) {
        this.form.repay_value = (this.sales_order.customer.balance.on_account * -1);
      } else {
        this.$nextTick(() => this.close());
      }

      this.$v.$reset();

      this.$refs.modal.show();

      this.$nextTick(() => {
        this.$refs['repayValueInput'].focus();
      })
    },
    close () {
      if (this.$refs.modal !== typeof undefined) this.$refs.modal.hide();
    }
  }
}
</script>
