<template>
  <div>

    <KSubheader
      :breadcrumbs="[ {
        page: 'Workspace',
        route: null
      }, {
        page: 'Instellingen',
        route: null
      }]"
      title="Instellingen"
    />

    <KContainer>
      <div class="row">
        <div class="col-12 col-portlet-menu">
          <KPortlet>
            <KPortletBody>
              <ul
                class="kt-nav kt-nav--bolder kt-nav--fit-ver kt-nav--v4"
                role="tablist"
              >
                <router-link
                  v-slot="{ href, route, navigate, isActive, isExactActive }"
                  to="/settings/workspace/integrations"
                >
                  <li :class="['kt-nav__item', {'active': isActive}]">
                    <a
                      class="kt-nav__link"
                      role="tab"
                      @click.prevent="$router.push(href)"
                    >
                      <span class="kt-nav__link-icon">
                        <i><font-awesome-icon :icon="['fad', 'network-wired']" /></i>
                      </span>
                      <span class="kt-nav__link-text">Integraties</span>
                    </a>
                  </li>
                </router-link>
              </ul>
            </KPortletBody>
          </KPortlet>
        </div>

        <div class="col-12 col-sm">
          <router-view
            :key="$route.fullPath"
          />
        </div>
      </div>

    </KContainer>

  </div>
</template>

<script>
export default {
  data () {
    return {
      store: null
    };
  }
};
</script>
