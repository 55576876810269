// Libraries
import { merge } from 'lodash';

// Classes
import Abstract from './abstract';

class PermissionDocument extends Abstract {
  constructor (guid = null) {
    super(guid);
    this.uri = '/workspace_permissions';

    let defaultObject = {
      resource_guid: null,
      resource_storage: null,
      inherit_from_system: null,
      inherit_from_group: null
    };

    merge(this, defaultObject);
  }
};

export default PermissionDocument;
