<template>
  <div>
    <search-customer-row ref="searchCustomerRow"

    ></search-customer-row>
  </div>
</template>

<script>
// Components
import searchCustomerRow from '@/components/sales/shop/components/modals/identify-customer/components/search-customer.row';

export default {
  components: {
    searchCustomerRow
  }
}
</script>
