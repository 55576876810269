// Classes
import Abstract from './abstract';
import Product from './product';

// Libraries
import { merge } from 'lodash';
import format from '@/libs/format';
import calculate from '@/libs/calculate';

class PricelistPrice extends Abstract {
  constructor (guid = null) {
    super(guid);

    let defaultObject = {
      type: 'product',
      product_name: null,
      product_guid: null,
      product: new Product(),
      price: null
    };

    merge(this, defaultObject);
  }

  // Methods
  formatBody (type = 'get', options = {}) {
    let body = super.formatBody(type, options);
    body = {
      product_name: body.product_name,
      product_guid: body.product_guid,
      price: body.price
    };

    return body;
  }

  // Getters
  get VUID () {
    return `product.price.${this.product_guid}`;
  }

  get price_formatted () {
    if (this.price === null) return null;
    return format.currencyToInput(this.price);
  }
  set price_formatted (value) {
    this.price = calculate.price(value, true);
  }

  get VAT () {
    if (typeof this.product.price.vat_percentage === typeof undefined || this.product.price.vat_percentage === null) return 0;
    return Number(this.product.price.vat_percentage);
  }

  get price_incl_vat () {
    if (this.price === null) return null;
    return calculate.priceWithVAT(this.price, this.VAT);
  }
  set price_incl_vat (value) {
    this.price = calculate.priceWithoutVAT(value, this.VAT);
  }
  get price_incl_vat_formatted () {
    if (this.price === null) return null;
    return format.currencyToInput(this.price_incl_vat);
  }
  set price_incl_vat_formatted (value) {
    if (typeof value === 'string') value = Number(value);
    this.price_incl_vat = calculate.price(value, true);
  }

  get margin () {
    if (this.type === 'session_product') return null;
    if (this.price === null || this.product.cost_price === null) return null;
    return format.percentage((this.price - this.product.cost_price) / this.product.cost_price);
  }
  set margin (value) {
    if (this.type === 'session_product') return null;
    if (typeof value === 'string') value = Number(value);
    this.price = Math.round(this.product.cost_price * (1 + (value / 100)));
  }
};

export default PricelistPrice;
